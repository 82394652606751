import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CRow, CCol, CButton } from '@coreui/react';
import FindAccountCondition from './FindAccountCondition';
import CompanyTableInfo from './CompanyTableInfo';
import CIcon from '@coreui/icons-react';
import { callTokenApi } from '../../../../../../apiCaller';
import { CLIENT_TARGET_ACCOUNT } from '../../../../../../constants';
import { useSelector } from 'react-redux';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { useOutsideHandlingForFindAccount } from '../utils';
export const FindAccountContext = React.createContext({});

const TargetAccountFindAccount = () => {
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const [showCondition, setShowCondition] = useState(false);
     const [conditionValue, setConditionValue] = useState({});
     const [offSet, setOffSet] = useState(0);
     const [activePage, setActivePage] = useState(1);
     const [add, setAdd] = useState([])
     const [addAll, setAddAll] = useState(false)
     const [filterEvent, setFilterEvent] = useState([
          {
               g0: {
                    id: '',
                    type: '',
                    operator: '',
                    value: '',
                    conjunction: '',
               },
          },
     ]);

     const [isLoading, setIsLoading] = useState(true);
     const [loadingCondition, setLoadingCondition] = useState(true);

     const toggleCondition = () => {
          if (!conditionValue.domain || !conditionValue.domain.length){
               return;
          }
          setShowCondition((prev) => !prev)
     }

     const clickOutside = useCallback(() => {
          setShowCondition(false);
     }, []);

     const wrapperRef = useRef(null);
     useOutsideHandlingForFindAccount(wrapperRef, clickOutside);

     useEffect(() => {
          setLoadingCondition(true)
          callTokenApi(`${CLIENT_TARGET_ACCOUNT}condition-value/${activeAccount.id}?isFindAccount=true`, 'GET').then((response) => {
               if (response.status !== 200) return;
               setConditionValue(response.data.data)
          }).finally(() => setLoadingCondition(false));
     }, []); // eslint-disable-line

     const contextValue = {
          conditionValue,
          setConditionValue,
          isLoading,
          filterEvent,
          setFilterEvent,
          setIsLoading,
          setShowCondition,
          offSet, 
          setOffSet,
          activePage, 
          setActivePage,
          addAll, 
          setAddAll,
          add, 
          setAdd
     }

     return (
          <div className="form-edit-lookup ta-find-account">
               <CRow>
                    <CCol md="12" className="form-edit-lookup-row">
                         <div className="form-edit-lookup-title trigger-rule">
                              <h1 title="full-target-account-list">Find Account</h1>
                         </div>
                    </CCol>
                    <CCol md="12" className="form-edit-lookup-description">
                         <p>
                              Manage your full list of Target Accounts below. Manually add new rows or bulk import updates. Accounts will be
                              processed against your Group Rules and will be updated with the correct Group tags after a few minutes.
                         </p>
                    </CCol>
               </CRow>

               {loadingCondition ? <CenterSpinner /> : (
                    <FindAccountContext.Provider value={contextValue}>
                         <div className='find-account-condition' ref={wrapperRef}>
                              <CButton onClick={() => toggleCondition()} className='btn-add-condition' disabled={!conditionValue.domain || !conditionValue.domain.length}>
                                   <CIcon icon="icon-filter-report" />
                                   Add a condition
                              </CButton>

                              {showCondition && (
                                   <div className='condition-content'>
                                        <FindAccountCondition />
                                   </div>
                              )}
                         </div>
                         <div className='report-table'>
                              <CompanyTableInfo />
                         </div>
                    </FindAccountContext.Provider>
               )}


          </div>
     );
}

export default TargetAccountFindAccount;
