import React, { useState, useCallback, useEffect } from 'react';
import { CForm, CButton } from '@coreui/react';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import Groups from './Groups';
// import { toast } from 'react-toastify';
import { API_CLIENT_CUSTOM_FORM_TARGET_RULES, STEPS_WIZARD_CATEGORIES } from '../../../../../../../constants';
import { callTokenApi } from '../../../../../../../apiCaller';
import { toastError } from '../../../../../../../utils';
import VideoPopup from '../../../../../../general/popup/VideoPopup';
import Waiting from '../../../../../../general/Loadings/Waiting';
import {
     handleUpdateTargetForm,
     setCustomFormTargetRules,
     setFormRequiringEdit,
     setStepFormsCategories,
} from '../../../../../../../actions/subscriber';

const initialGroup = {
     attribute: '',
     operator: '',
     value: '',
     conjunction: '',
};

const Rules = ({ onBack, data, listData }) => {
     const dispatch = useDispatch();
     const formRequiringEdit = useSelector((state) => state.subscriber.formRequiringEdit);
     const groups = useSelector((state) => state.subscriber.customFormTargetRules);
     const activeAccountId = useSelector((state) => state.subscriber.activeAccount.id);
     const { isRunningV2 } = useSelector((state) => state.subscriber.activeAccount);
     const CUSTOM_FORM_CODE = 'customForms';
     const listenerFormsEnabled = useSelector((state) => state.subscriber.listenerFormsEnabled);
     const customFormListenerEnabled = listenerFormsEnabled.find((listener) => listener.code === CUSTOM_FORM_CODE);
     const { id: activeListenerId, specialInstructions: listenerSpecialInstructions } = customFormListenerEnabled;

     const [isLoading, setIsLoading] = useState(false);
     const [showVideoPopup, setShowVideoPopup] = useState(false);
     const [errors, setErrors] = useState([]);
     const [newGroupsState, setNewGroupsState] = useState(groups);

     useEffect(() => {
          if (formRequiringEdit && formRequiringEdit.length > 0) {
               formRequiringEdit.forEach((form) => {
                    if (form.parentId && form.parentId === data.id) {
                         form.isUnknown = false;
                         dispatch(setFormRequiringEdit(form));
                    }
               });
          }
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     const validate = useCallback(() => {
          // check require field
          let validate = true;
          let newErrors = [];
          let requireErr = false;

          for (let index = 0; index < newGroupsState.length; index++) {
               const lvl1Group = newGroupsState[index];
               newErrors[index] = {};
               let workingLvl1Group = { ...lvl1Group };
               delete workingLvl1Group.conjunction;

               for (var lvl2GroupKey in workingLvl1Group) {
                    const { attribute, operator, value } = workingLvl1Group[lvl2GroupKey];
                    let lv2Errors = {};

                    // if operator = 1 of ['exav', 'nex', 'itp', 'itft'], value variable is empty, so don't need to check value
                    if (!attribute || !operator || (typeof value === 'string' && !value.trim())) {
                         validate = false;
                         requireErr = true;

                         if (!attribute) {
                              lv2Errors = { ...lv2Errors, attribute: 'This field is required.' };
                         }
                         if (!operator) {
                              lv2Errors = { ...lv2Errors, operator: 'This field is required.' };
                         }
                         if (!value) {
                              lv2Errors = { ...lv2Errors, value: 'This field is required.' };
                         }
                    } else {
                         requireErr = false;
                    }
                    newErrors[index][lvl2GroupKey] = lv2Errors;
               }
          }

          // check validate dupplicate groups
          let duplicateGroups = false;
          let groupsToValidate = newGroupsState.map((group) => {
               let newGroup = { ...group };
               delete newGroup.conjunction;

               return newGroup;
          });
          if (!requireErr) {
               for (let i = 0; i < groupsToValidate.length - 1; i++) {
                    for (let j = i + 1; j < groupsToValidate.length; j++) {
                         if (isEqual(groupsToValidate[i], groupsToValidate[j])) {
                              newErrors[j] = { group: 'There are duplicate group' };
                              duplicateGroups = true;
                              validate = false;
                              break;
                         }
                    }

                    if (duplicateGroups) {
                         break;
                    }
               }
          }

          if (!duplicateGroups) {
               for (let index = 0; index < groupsToValidate.length; index++) {
                    const lvl1Group = groupsToValidate[index];
                    const lvl1GroupLength = Object.keys(lvl1Group).length;

                    for (let i = 0; i < lvl1GroupLength; i++) {
                         let iGroup = { ...lvl1Group[`g${i}`] };
                         iGroup.conjunction = '';

                         for (let j = i + 1; j < lvl1GroupLength; j++) {
                              let jGroup = { ...lvl1Group[`g${j}`] };
                              jGroup.conjunction = '';

                              if (isEqual(iGroup, jGroup)) {
                                   if (!newErrors[index]) {
                                        newErrors[index] = {};
                                   }
                                   if (!newErrors[index][`g${j}`] || Object.keys(newErrors[index][`g${j}`]).length === 0) {
                                        newErrors[index][`g${j}`] = { row: "There are duplicate row's values in the same group" };
                                   }
                                   duplicateGroups = true;
                              }
                         }
                    }

                    if (duplicateGroups) {
                         validate = false;
                    }
               }
          } else {
               validate = false;
          }

          setErrors(newErrors);
          return validate;
     }, [newGroupsState]);

     const onSubmit = (e) => {
          e.preventDefault();
          if (validate()) {
               setIsLoading(true);
               const data = {
                    accountId: activeAccountId,
                    isRunningV2,
                    listenerId: activeListenerId,
                    targetRules: [...newGroupsState],
               };
               callTokenApi(API_CLIENT_CUSTOM_FORM_TARGET_RULES, 'POST', data)
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(handleUpdateTargetForm(newGroupsState));
                              dispatch(setCustomFormTargetRules(newGroupsState));
                              // toast.success('Updated!');
                              if (typeof onBack === 'function') {
                                   onBack();
                              }
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => {
                         setIsLoading(false);
                         if (listData && listData.length > 0) {
                              dispatch(setStepFormsCategories(STEPS_WIZARD_CATEGORIES.categorizeAFewForms));
                         }
                    });
          }
     };

     const addLvl2Conjunction = ({ lvl1GroupIndex, lvl2GroupIndex, conjunction }) => {
          let newGroups = [...groups];
          if (!newGroups[lvl1GroupIndex][`g0`].conjunction) {
               newGroups[lvl1GroupIndex][`g0`].conjunction = 'and';
          }
          if (!newGroups[lvl1GroupIndex][`g${lvl2GroupIndex}`].conjunction) {
               newGroups[lvl1GroupIndex][`g${lvl2GroupIndex}`].conjunction = conjunction;
          }
          newGroups[lvl1GroupIndex][`g${lvl2GroupIndex + 1}`] = { ...initialGroup };
          // dispatch(setCustomFormTargetRules(newGroups));
          setNewGroupsState(newGroups);
          setErrors([]);
     };

     useEffect(() => {
          if (data) {
               if (groups.length && Object.keys(groups[0]).length < 1) {
                    const lvl1GroupIndex = 0;
                    const lvl2GroupIndex = 1;
                    const conjunction = 'and';
                    addLvl2Conjunction({ lvl1GroupIndex, lvl2GroupIndex, conjunction });
               } else {
                    let newInitGroup = {
                         conjunction: '',
                    };
                    let newGroups = [...groups];
                    let valueForm = data.formId;

                    if (valueForm) {
                         newInitGroup['g0'] = {
                              attribute: 'CSS ID',
                              operator: 'ct',
                              value: valueForm,
                              conjunction: '',
                         };
                    } else if (data.formClass) {
                         valueForm = data.formClass;
                         valueForm = valueForm.split(' ');

                         valueForm.forEach((item, index) => {
                              const newInitRow = {
                                   attribute: 'CSS Class',
                                   operator: 'ct',
                                   value: item,
                                   conjunction: valueForm.length > 1 ? (index !== valueForm.length - 1 ? 'and' : '') : '',
                              };
                              newInitGroup[`g${index}`] = newInitRow;
                         });
                    }

                    if (groups[0]['g0'] && groups[0]['g0'].value) {
                         if (newGroups[newGroups.length - 1]) {
                              newGroups[newGroups.length - 1].conjunction = 'or';
                         }
                         newGroups.push(newInitGroup);
                    } else {
                         newGroups[0] = newInitGroup;
                    }
                    setNewGroupsState(newGroups);
               }
          } else {
               if (groups.length && Object.keys(groups[0]).length < 1) {
                    const lvl1GroupIndex = 0;
                    const lvl2GroupIndex = 1;
                    const conjunction = 'and';
                    addLvl2Conjunction({ lvl1GroupIndex, lvl2GroupIndex, conjunction });
               }
          }
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     const addListNewGroupState = () => {
          if (listData && listData.length > 0) {
               let newGroups = [...groups];
               listData.forEach((data) => {
                    if (groups.length && Object.keys(groups[0]).length < 1) {
                         const lvl1GroupIndex = 0;
                         const lvl2GroupIndex = 1;
                         const conjunction = 'and';
                         addLvl2Conjunction({ lvl1GroupIndex, lvl2GroupIndex, conjunction });
                    } else {
                         let newInitGroup = {
                              conjunction: '',
                         };
                         let valueForm = data.formId;

                         if (valueForm) {
                              newInitGroup['g0'] = {
                                   attribute: 'CSS ID',
                                   operator: 'ct',
                                   value: valueForm,
                                   conjunction: '',
                              };
                         } else if (data.formClass) {
                              valueForm = data.formClass;
                              valueForm = valueForm.split(' ');

                              valueForm.forEach((item, index) => {
                                   const newInitRow = {
                                        attribute: 'CSS Class',
                                        operator: 'ct',
                                        value: item,
                                        conjunction: valueForm.length > 1 ? (index !== valueForm.length - 1 ? 'and' : '') : '',
                                   };
                                   newInitGroup[`g${index}`] = newInitRow;
                              });
                         }

                         if (groups[0]['g0'] && groups[0]['g0'].value) {
                              if (newGroups[newGroups.length - 1]) {
                                   newGroups[newGroups.length - 1].conjunction = 'or';
                              }
                              newGroups.push(newInitGroup);
                         } else {
                              newGroups[0] = newInitGroup;
                         }
                    }
               });
               setNewGroupsState(newGroups);
          }
     };
     useEffect(() => {
          if (listData && listData.length > 0) {
               addListNewGroupState();
          }
     }, [listData]);// eslint-disable-line react-hooks/exhaustive-deps
     return (
          <div className=''>
               <div className='form-horizontal'>
                    <CForm onSubmit={onSubmit}>
                         <div className='list-rules-and-or'>
                              <Groups errors={errors} setErrors={setErrors} newGroupsState={newGroupsState} setNewGroupsState={setNewGroupsState} />
                         </div>
                         <CButton type='submit' className='btn-next--lg target-custom-form' color='primary' disabled={isLoading}>
                              <Waiting isLoading={isLoading}>SAVE</Waiting>
                         </CButton>
                    </CForm>
               </div>

               {listenerSpecialInstructions && (
                    <VideoPopup show={showVideoPopup} onClose={() => setShowVideoPopup(false)} {...listenerSpecialInstructions.popup} />
               )}
          </div>
     );
};

export default Rules;
