import React from 'react';
import {
    CModal,
    CModalBody,
    CButton,
} from '@coreui/react'
import CIcon from '@coreui/icons-react';
import PropTypes from 'prop-types';

const RemoveUserFromAccount = ({ show, isLoading, onClose, event, btnText, children }) => {
    return (
        <div className="remove-popup">
            <CModal
                visible={show}
                onClose={onClose}
                alignment="center"
                portal={false}
            >

                <CModalBody>
                    <CIcon
                        icon="cil-x"
                        onClick={onClose}
                        className="icon-close-popup"
                    ></CIcon>
                    <div className="remove-popup-icon"><CIcon icon="icon-delete" className="text-danger" height={38} alt="CoreUI Icons Delete" /></div>
                    <div className="remove-popup-description">{children}</div>
                    <div className="btn-accept mb-3">
                        {
                            <CButton color="danger" className="mr-3 text-uppercase d-inline-block" onClick={event} disabled={isLoading}>
                                {isLoading ? (<span className="dots-waiting">Waiting</span>) : btnText}
                            </CButton>
                        }
                    </div>
                    <div className="mb-3">
                        <CButton
                            className="d-inline-block btn-cancel"
                            onClick={onClose}
                        >
                            Cancel
                        </CButton>
                    </div>
                </CModalBody>
            </CModal>
        </div>

    )
}

RemoveUserFromAccount.propTypes = {
    show: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClose: PropTypes.func,
    event: PropTypes.func,
    btnText: PropTypes.string,
}

export default RemoveUserFromAccount
