import React, { useContext, useState, useRef, useEffect } from 'react';
import CIcon from '@coreui/icons-react';
import { CButton, CModal, CModalBody, CModalTitle, CProgress } from '@coreui/react';
import XLSX from 'xlsx';
import { FullListTableContext } from '.';
import CLabel from '../../../../../../migration/CLabel';
import { CInputFile } from '../../../../../../migration/CInput';
import CFormGroup from '../../../../../../migration/CFormGroup';
import { callTokenApi } from '../../../../../../../apiCaller';
import { CLIENT_TARGET_ACCOUNT } from '../../../../../../../constants';
import { useSelector } from 'react-redux';
import CenterSpinner from '../../../../../../general/Loadings/CenterSpinner';
import { toast } from 'react-toastify';

const ImportTableModal = ({ show, onClose, toggleModal, exportToFile }) => {
     const { groupId, setReloadData} = useContext(FullListTableContext);
     const [importedFileName, setImportedFileName] = useState('');
     const [importedValues, setImportedValues] = useState([]);
     const [isUploadedError, setIsUploadedError] = useState(false);
     const [isUploadSuccess, setIsUploadSuccess] = useState(false);
     const [isSelectFile, setIsSelectFile] = useState(false);

     // const [importData, setImportData] = useState([]);
     const inputFileRef = useRef(null);

     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountId = activeAccount.id;

     const [isLoading, setIsLoading] = useState(false)

     const resetState = () => {
          setImportedFileName('');
          setImportedValues([]);
          setIsUploadedError(false);
          setIsUploadSuccess(false);
     };

     const clickCancelBtn = () => {
          if (isUploadedError) {
               inputFileRef.current.value = ''; // Set input file value = '', so the user can upload the same file again
               resetState();
          }

          toggleModal();
     };

     const uploadFile = (e) => {
          const { files } = e.target;

          if (files && files.length > 0) {
               var reader = new FileReader();

               reader.onload = function (_) {
                    // Use reader.result
                    var workbook = XLSX.read(reader.result, {
                         type: 'binary',
                         raw: true, // Plain text parsing will not parse values ** (for example: Aug 2019 will not be parsed to 8/1/2019)
                    });

                    setImportedFileName(files[0].name);

                    // Range: 1 => Skip first row => Second row becomes the header
                    // defval: '' => All null and undefined points will be filled with defval
                    let jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { range: 0, raw: true, defval: '' });

                    if (jsonData.length > 0) {
                         setImportedValues(jsonData);
                    }
               };

               reader.readAsBinaryString(files[0]);
          }

          handleCancel();
     };

     const uploadTable = () => {
          setIsUploadedError(false);

          if (!importedValues[0]) {
               return setIsUploadedError(true);
          }

          let newImportedValues = [];
          let isConditionValueValid = true;

          importedValues.some((val) => {
               if (typeof val['Domain'] === 'undefined') {
                    isConditionValueValid = false;
                    return true;
               }

               newImportedValues.push({ domain: val['Domain'], isBO: val['BO'] === 'true' ? true : false });
               return false;
          });

          if (isConditionValueValid) {
               setImportedValues(newImportedValues);
               setIsUploadSuccess(true);
          } else {
               setIsUploadedError(true);
          }
     };



     const applyChanges = () => {
          setIsLoading(true)
          const data = {
               accountId,
               importData: importedValues,
               groupId
          }

          callTokenApi(`${CLIENT_TARGET_ACCOUNT}import`, 'POST', data).then((response) => {
               if (response.status === 200) {
                    toast.success(`Import file successfully.`);
               }
          }).finally(() => {
               setReloadData(prev => !prev)
               setIsLoading(false);
               inputFileRef.current.value = ''; // Set input file value = '', so the user can upload the same file again
               resetState();
               // setBtnSaveStatus(false); // Enable Save Changes button
               toggleModal();
          })

     };

     const cancelChange = () => {
          inputFileRef.current.value = ''; // Set input file value = '', so the user can upload the same file again
          resetState();
     };

     const handleCancel = () => {
          if (isSelectFile) {
               setTimeout(() => {
                    setIsSelectFile(false);
               }, 200);
          }
     };

     useEffect(() => {
          document.addEventListener('cancel', handleCancel);

          return () => {
               document.removeEventListener('cancel', handleCancel);
          };
     }, [isSelectFile]); // eslint-disable-line


     return (
          <div className="import-modal">
               <CModal
                    visible={show}
                    onClose={onClose}
                    alignment="center"
                    portal={false}
                    // closeOnBackdrop={false}
                    className={isSelectFile ? 'pe-none' : ''}
               >
                    <CModalBody>
                         <div className="import-modal-body">
                              <CIcon name="cil-x" onClick={onClose} className="icon-close-popup" />
                              <CModalTitle>Import Table Values</CModalTitle>
                              <p>
                                   Use this feature to import new values for your table. You can replace all existing values or add new values to the{' '}
                                   top or bottom of your table. To begin, export your Table Template below. Then populate the values and import it.
                              </p>
                              <div className={`import-modal-step${isUploadSuccess ? ' import-modal-step-disable' : ''}`}>
                                   <p>Step 1: Download my table's template:</p>
                                   <CButton color="light" onClick={() => exportToFile('csv', 'table-template', true)} disabled={isUploadSuccess}>
                                        DOWNLOAD
                                   </CButton>
                              </div>
                              <p>Upload my new values:</p>
                              <CFormGroup className={`col-12${isUploadSuccess ? ' import-modal-file-disable' : ''}`}>
                                   <CInputFile
                                        innerRef={inputFileRef}
                                        custom
                                        id="custom-file-input"
                                        onChange={uploadFile}
                                        onClick={() => setIsSelectFile(true)}
                                        disabled={isUploadSuccess}
                                   />
                                   <CLabel htmlFor="custom-file-input" variant="custom-file">
                                        {importedFileName || 'Choose file...'}
                                   </CLabel>
                              </CFormGroup>
                              {!isUploadSuccess && (
                                   <div className="import-modal-button">
                                        <CButton color="primary" disabled={!importedFileName} onClick={uploadTable}>
                                             UPLOAD
                                        </CButton>
                                        <CButton color="light" type="button" onClick={clickCancelBtn}>
                                             CANCEL
                                        </CButton>
                                   </div>
                              )}
                              {isUploadedError && (
                                   <div className="import-modal-error">
                                        <p>
                                             File upload error. Please make sure you are using your table template and saving the file as the same
                                             format it was downloaded in.
                                        </p>
                                   </div>
                              )}
                              {isUploadSuccess && (
                                   isLoading ? <CenterSpinner /> : (
                                        <div className="import-modal-progress">
                                             <CProgress color="success" value={100} size="md" />
                                             <p>File processed successfully. We found {importedValues.length} rows of data in your upload. </p>
                                             <div className="import-modal-button">
                                                  <CButton color="primary" disabled={!importedValues} onClick={applyChanges}>
                                                       APPLY CHANGES
                                                  </CButton>
                                                  <CButton color="light" type="button" onClick={cancelChange}>
                                                       CANCEL CHANGES
                                                  </CButton>
                                             </div>
                                        </div>
                                   )
                              )}
                         </div>
                    </CModalBody>
               </CModal>
          </div>
     );
};

export default ImportTableModal;
