import React, { useContext } from 'react';
import { CButton, CCard, CCardBody } from '@coreui/react';

import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';

const SkipPlacingScript = () => {
    const { setCurrentStep, account } = useContext(CreateAccountContext);
    const { completedSteps } = account
    const onBackBtnClicked = () => {
        setCurrentStep(null, CHANGE_STEP_TYPE.subStepPrev);
    };
    const skipCodePlacement = () => {
        if (completedSteps.includes(STEPS.chooseDestination) && completedSteps.includes(STEPS.configureMeasurement)) {
            setCurrentStep(STEPS.configuringAccount)
        }
        else
            setCurrentStep(STEPS.gtmConnection, CHANGE_STEP_TYPE.mainStepNext);
    };

    return (
        <CreateAccountBody onBackBtnClicked={onBackBtnClicked}>
            <CCard className="account-script skip">
                <CCardBody>
                    <div className="icon-warning-circle"></div>
                    <h3>Before you proceed</h3>
                    <p>ListenLayer won’t work until this script is deployed to your website successfully.</p>
                    <CButton className="btn-save" onClick={onBackBtnClicked}>
                        GO BACK
                    </CButton>
                    <CButton className="btn-navigation-step" onClick={skipCodePlacement}>
                        Skip the code placement<i className="fal fa-angle-right"></i>
                    </CButton>
                </CCardBody>
            </CCard>
        </CreateAccountBody>
    );
};

export default SkipPlacingScript;
