import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSidebar } from '@coreui/react';
import className from 'classnames';
import CIcon from '@coreui/icons-react';

import { setBuyOfficeHours, setShowMasterclassAside } from '../../../actions/subscriber';
import Payment from './Payment';
import { useFetchMasterclassInfo } from '../../../helpers/customHooks';
import CenterSpinner from '../../../components/general/Loadings/CenterSpinner';
import { dateDiffInseconds, formatUserDate, secondsToTime } from '../../../utils';
import CImg from '../../../components/migration/CImg';

const BuyMasterClass = ({ masterclassCourse }) => {
     const [timeLeft, setTimeLeft] = useState({
          h: '00',
          m: '00',
          s: '00',
          time: 0,
     });

     const receivedItems = [
          { name: 'The Masterclass', price: 2490 },
          { name: 'Measurement Framework Deliverables', price: 3490 },
          { name: '1 Free Office Hours Session', price: 299 },
          { name: 'Free ListenLayer Credits', price: 495 },
     ];

     const totalValue = receivedItems.reduce((sum, current) => {
          return sum + current.price;
     }, 0);

     const startTimer = () => {
          let seconds = 0;
          let timer = 0;
          const today = new Date();
          seconds = dateDiffInseconds(today, masterclassCourse.endDate);
          if (seconds > 0) {
               timer = setInterval(() => {
                    seconds = seconds - 1;
                    let timeLeft = secondsToTime(seconds);
                    setTimeLeft(timeLeft);

                    if (seconds <= 0) {
                         clearInterval(timer);
                    }
               }, 1000);
          }
     };

     useEffect(startTimer, []); // eslint-disable-line react-hooks/exhaustive-deps

     return (
          <div className="choose-how-to-pay aside-version masterclass-aside">
               <div className="text-center header-payment">
                    <CImg src="/assets/images/icon-payment-masterclass.svg" alt="payment-method" />
                    <h3>Gain Lifetime Access</h3>
                    <p>& complete the Masterclass at your own pace</p>
               </div>

               <ul>
                    {receivedItems.map((item) => (
                         <li key={item.name} className="d-flex align-items-center justify-content-between">
                              <span>{item.name}</span>
                              <span>${item.price.toLocaleString('en-US')}</span>
                         </li>
                    ))}
               </ul>
               <div className="total-payment d-flex align-items-center justify-content-end">
                    <p className="total">Total Value ${totalValue.toLocaleString('en-US')}</p>
               </div>

               <div className="masterclass-special-price">
                    <h4>
                         You pay only $
                         {(+(timeLeft.time > 0 ? masterclassCourse.stripeProdSalePrice : masterclassCourse.stripeProdPrice)).toLocaleString('en-US')}
                    </h4>
                    <p>
                         and{' '}
                         <span className="save">
                              save $
                              {(+(timeLeft.time > 0
                                   ? masterclassCourse.savedAmountSale
                                        ? masterclassCourse.savedAmountSale
                                        : totalValue - masterclassCourse.stripeProdSalePrice
                                   : masterclassCourse.savedAmount
                                     ? masterclassCourse.savedAmount
                                     : totalValue - masterclassCourse.stripeProdPrice)).toLocaleString('en-US')}
                         </span>{' '}
                         when you sign up
                         {timeLeft.time > 0 && (
                              <span className="time-end">
                                   {` `}by {formatUserDate(masterclassCourse.endDate)}
                              </span>
                         )}
                    </p>

                    {timeLeft.time > 0 && (
                         <div className="time-countdown sidebar-block">
                              <div className="time-block">
                                   <span className="time">{timeLeft.h}</span>
                                   <span className="label">hrs</span>
                              </div>
                              <div className="time-block">
                                   <span className="time">{timeLeft.m}</span>
                                   <span className="label">min</span>
                              </div>
                              <div className="time-block">
                                   <span className="time">{timeLeft.s}</span>
                                   <span className="label">sec</span>
                              </div>
                         </div>
                    )}
               </div>
               <Payment />
          </div>
     );
};

const BuyOfficeHours = () => {
     const officeHour = useSelector((state) => state.subscriber.officeHour);
     return (
          <div className="choose-how-to-pay aside-version masterclass-aside buy-office-hours">
               <div className="text-center header-payment">
                    <CImg src="/assets/images/icon-payment-masterclass.svg" alt="payment-method" />
                    <h3>Office Hours</h3>
                    <p>Purchase a Office Hours Credit for ${officeHour.stripeProdPrice}</p>
               </div>
               <div className="content">
                    <p>Each Credit can be redeemed for one 55 minute office hours session focused on your strategic needs.</p>
               </div>
               <Payment />
          </div>
     );
};

const TheMasterclassAside = () => {
     const dispatch = useDispatch();
     const asideShow = useSelector((state) => state.subscriber.showMasterclassAside);
     const buyOfficeHours = useSelector((state) => state.subscriber.buyOfficeHours);
     const [showOnce, setShowOnce] = useState(false);
     const { masterclassCourseLoading, masterclassCourse } = useFetchMasterclassInfo(showOnce);
     const sidebarClassNames = className('side-bar', 'master-class');

     const createBackdrop = () => {
          const backdrop = document.createElement('div');
          backdrop.className = 'sidebar-backdrop c-show';
          backdrop.id = 'sidebar-backdrop';
          document.body.appendChild(backdrop);
          backdrop.addEventListener('click', () => {
               toggleSidebar();
          });
     };

     const removeBackdrop = () => {
          const backdrop = document.getElementById('sidebar-backdrop');
          if (backdrop) {
               document.body.removeChild(backdrop);
          }
     };

     const toggleSidebar = () => {
          if (asideShow) {
               dispatch(setShowMasterclassAside(!asideShow));
               handleHide();
          } else if (buyOfficeHours) {
               dispatch(setBuyOfficeHours(!buyOfficeHours));
               handleHide();
          }
     };

     const handleShow = () => {
          createBackdrop();
     };

     const handleHide = () => {
          removeBackdrop();
     };

     const handleShowOnce = () => {
          if (asideShow && !showOnce) {
               setShowOnce(true);
          }
          if (buyOfficeHours && !showOnce) {
               setShowOnce(true);
          }
     };

     useEffect(handleShowOnce, [asideShow, buyOfficeHours]); // eslint-disable-line react-hooks/exhaustive-deps

     return (
          <CSidebar
               colorScheme="light"
               size="lg"
               overlaid
               visible={asideShow || buyOfficeHours}
               // onVisibleChange={toggleSidebar}
               className={sidebarClassNames}
               placement="end"
               onShow={handleShow}
               onHide={handleHide}
          >
               <CIcon icon="cil-x" onClick={toggleSidebar} className="icon-close-popup"></CIcon>
               {masterclassCourseLoading ? (
                    <CenterSpinner />
               ) : (
                    <>
                         {asideShow && <BuyMasterClass masterclassCourse={masterclassCourse} />}
                         {buyOfficeHours && <BuyOfficeHours />}
                    </>
               )}
          </CSidebar>
     );
};

export default React.memo(TheMasterclassAside);
