import {
     PUSHER_NEW_TICKET,
     GET_TICKETS,
     PUSHER_NEW_COMMENT,
     SET_TICKET_DETAIL,
     RESET_NUMBER_COMMENT,
     SET_NUMBER_OF_TICKETS,
     UPDATE_STATUS_TICKET
} from './types';

export const newTicket = (ticket) => {
     return {
          type: PUSHER_NEW_TICKET,
          payload: ticket
     }
}

export const setTickets = (tickets) => {
     return {
          type: GET_TICKETS,
          payload: tickets
     }
}

export const newComment = (cmt) => {
     return {
          type: PUSHER_NEW_COMMENT,
          payload: cmt
     }
}

export const setTicketDetail = (ticket) => {
     return {
          type: SET_TICKET_DETAIL,
          payload: ticket
     }
}

export const resetNumberOfCmt = (cmt) => {
     return {
          type: RESET_NUMBER_COMMENT,
          payload: cmt
     }
}

export const setNumberOfTickets = (num) => {
     return {
          type: SET_NUMBER_OF_TICKETS,
          payload: num
     }
}

export const updateStatusTicket = (pay) => {
     return {
          type: UPDATE_STATUS_TICKET,
          payload: pay
     }
}
