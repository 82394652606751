import React, { Suspense } from 'react'
import {
     Route,
     Switch,
     Redirect
} from 'react-router-dom';
import { CContainer} from '@coreui/react';

// routes config
import { adminRoutes } from '../../routes';
import CenterSpinner from '../../components/general/Loadings/CenterSpinner';
import CFade from '../../components/migration/CFade';

const AdminContent = () => {
     const routes = adminRoutes;

     return (
          <main className="c-main pt-5">
               <CContainer fluid>
                    <Suspense fallback={<CenterSpinner />}>
                         <Switch>
                              {routes.map((route, idx) => {
                                   return route.component && (
                                        <Route
                                             key={idx}
                                             path={route.path}
                                             exact={route.exact}
                                             name={route.name}
                                             render={props => (
                                                  <CFade>
                                                       <route.component {...props} />
                                                  </CFade>
                                             )} />
                                   )
                              })}
                              <Redirect to='/' />
                         </Switch>
                    </Suspense>
               </CContainer>
          </main>
     )
}

export default React.memo(AdminContent)
