import React, { useContext, useEffect } from 'react';
import { CCard, CCardBody } from '@coreui/react';
import { useDispatch } from 'react-redux';
import uniq from 'lodash/uniq';

import { CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
import { addFirstDomain } from '../../../../../../actions/subscriber';
import successAnimationData from '../../../../../../assets/lottie-files/success.json';
import CommonLottie from '../../../../../general/lottie/CommonLottie';

const DomainSuccess = () => {
    const dispatch = useDispatch();
    const { account, setAccount, stepsData, setCurrentStep } = useContext(CreateAccountContext);
    const time = new Date().getTime();
    const { domain, domainStatus } = stepsData;

    const handleFirstLoad = () => {
        const successNext = () => {
            const successTime = new Date().getTime();
            const timeout = 2000 - (successTime - time); // Wait at least 2s when entering this view and navigate to another step

            setTimeout(() => {
                const newAccount = {
                    ...account,
                    domains: [{ name: domain, scriptInstalled: false, status: domainStatus }],
                    completedSteps: uniq([...account.completedSteps, STEPS.domain]),
                    numberOfDomains: 1
                };

                setAccount(newAccount);
                setCurrentStep(STEPS.gtmConnection);
            }, Math.max(timeout, 0));
        };

        dispatch(addFirstDomain({ accountId: account.id, domain, domainStatus, successNext }));
    }

    useEffect(handleFirstLoad, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <CreateAccountBody>
            <CCard className="account-domain success">
                <p className="number-of-step">1 of 3 steps</p>
                <CCardBody>
                    <CommonLottie animationData={successAnimationData} />
                    <h3>Success!</h3>
                    <p>We can access your website</p>
                </CCardBody>
            </CCard>
        </CreateAccountBody>
    );
};

export default DomainSuccess;
