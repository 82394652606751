import CIcon from '@coreui/icons-react';
import React, { useState } from 'react';
import LearnVideoPopup from './popup/LearnVideoPopup';

const TipVideo = ({ contentText, videoLink, headlinePopup, descriptionPopup }) => {
     const [showVideo, setShowVideo] = useState(false);

     return (
          <>
               <div className='tip-video d-flex justify-content-between align-items-center'>
                    <div className='content-text d-flex justify-content-start flex-wrap align-items-center'>
                         <strong>Pro Tip:</strong>
                         <p>{contentText}</p>
                    </div>
                    <div className='watch-video-wrapper d-flex justify-content-center flex-wrap align-items-center' style={{gap: '10px'}}>
                         <div className='watch-video d-flex justify-content-center align-items-center'  onClick={() => setShowVideo(true)}>
                              <CIcon icon='icon-play' className='icon-play' />
                              <strong>Watch</strong>
                         </div>
                         <span>2-minute Video</span>
                    </div>
               </div>
               <LearnVideoPopup
                    show={showVideo}
                    onCancel={() => {
                         setShowVideo(false);
                    }}
                    videoLink={videoLink}
                    headline={headlinePopup}
                    description={descriptionPopup}
               />
          </>
     );
};

export default TipVideo;
