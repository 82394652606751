import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CCol, CButton } from '@coreui/react';
import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from './CreateAccount';
import PackageItem from '../upgrade-account/PackageItem';
import useSaveCurrentStep from './useSaveCurrentStep';
import uniq from 'lodash/uniq';
import { API_CLIENT_FIND_USER_ACCOUNT, AUTH_PATH, CUSTOM_PRICING_PACKAGE, LOCAL_SETUP_INVITED_ACCOUNT, LOCAL_SHOW_ONLY_ENABLED_DESTINATIONS, LOCAL_SHOW_ONLY_ENABLED_LISTENERS } from '../../../../../constants';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { fetchAnAccount, fetchMonitoringNotifications, resetSubscriberReducer, setActiveAccount, setActiveListener, setRoutes, setShowCreateAccountPopup } from '../../../../../actions/subscriber';
import { gtmTrackingChangeLLAccount } from '../../../../../utils';
import { useHistory } from "react-router-dom";
import { callTokenApi } from '../../../../../apiCaller';

const AccountPackage = () => {
     const dispatch = useDispatch()
     const history = useHistory()
     const { account, setAccount, setStepsData, setCurrentStep, scheduleUpgrade, setShowPopupLargeSandbox, userValue, setUserValue } = useContext(CreateAccountContext);
     const user = useSelector(state => state.theme.user);
     const packages = useSelector(state => state.subscriber.packages);
     const packagesToDisplay = [...packages.filter(packageData => (packageData.friendlyName === 'Sandbox' || packageData.numberOfVisitors === userValue || (userValue < 10000 && packageData.numberOfVisitors === 10000)) && packageData.version === 3), CUSTOM_PRICING_PACKAGE]
     const activeAccount = useSelector(state => state.subscriber.activeAccount)
     const isScheduleDemo = ((activeAccount.id === account.id) && activeAccount.isScheduleUpgrade) || scheduleUpgrade;
     const accounts = useSelector(state => state.subscriber.accounts);
     const accountsSort = accounts.sort((accountFirst, accountSecond) => new Date(accountFirst.accessAt) - new Date(accountSecond.accessAt)).filter((item) => item.reachTheEndOfSetup === true)
     const accountId = account.id
     const userId = user.id;
     const packageIsCustom = ((activeAccount.id === account.id) && activeAccount.packageIsCustom)

     useEffect(() => {
          const newAccount = {
               ...account,
               completedSteps: uniq([...account.completedSteps, STEPS.chooseDestination, STEPS.configureMeasurement]),
          };

          setAccount(newAccount);
     }, [])// eslint-disable-line

     const marks = {
          0: '< 10,000',
          10000: '10,000',
          25000: '25,000',
          50000: '50,000',
          75000: '75,000',
          100000: '100,000',
          150000: '150,000',
          200000: '200,000',
          210000: '> 200,000',
     }

     const handleSliderChange = newValue => {
          setUserValue(newValue)
     }

     useEffect(() => {
          const elements = document.querySelectorAll('.account-see-pricing .rc-slider-mark-text-active')
          const classText = document.querySelectorAll('.account-see-pricing .rc-slider-mark-text')
          if (elements) {
               for (let i = 0; i < elements.length; i++) {
                    elements[i].style.display = 'none'
               }

               const activeElementIndex = Math.min(userValue, elements.length - 1)
               elements[activeElementIndex].style.display = 'block'

               for (let i = 0; i < classText.length; i++) {
                    if (i === activeElementIndex) {
                         classText[i].style.display = 'block'
                    } else {
                         classText[i].style.display = 'none'
                    }
               }
          }
     }) //eslint-disable-line

     useEffect(() => {
          if (packageIsCustom) {
               setCurrentStep(STEPS.configuringAccount);
          }
     }, [activeAccount.id])// eslint-disable-line

     useSaveCurrentStep();

     const onBackBtnClicked = () => {
          if (isScheduleDemo && accounts.length > 1) {
               dispatch(setActiveAccount(account))
               changeAccount(accountsSort[(accountsSort.length - 1)])
          }
          else { setCurrentStep(null, CHANGE_STEP_TYPE.mainStepPrev); }
     }

     const onClickScheduleDemo = () => {
          if (isScheduleDemo) {
               setCurrentStep(STEPS.talkToSalesThankYou, CHANGE_STEP_TYPE.subStepNext)
          }
          else { setCurrentStep(STEPS.talkToSales, CHANGE_STEP_TYPE.subStepNext) }
     }

     const selectPaidPackage = (packageData) => {
          if (!packageData.id) {
               if (isScheduleDemo) {
                    setCurrentStep(STEPS.talkToSalesThankYou, CHANGE_STEP_TYPE.subStepNext)
               }
               else { setCurrentStep(STEPS.talkToSales, CHANGE_STEP_TYPE.subStepNext) }
          }
          else {
               if (packageData.pricePerMonth === 0) {
                    // const data = {
                    //      accountId: account.id,
                    //      packageId: packageData.id,
                    // };
                    // callTokenApi(API_CLIENT_UPGRADE_PACKAGE_FREE, 'POST', data)
                    //      .then((response) => {
                    //           if (response.status === 200) {
                    //                let foundAccIndex = accounts.findIndex(acc => acc.id === account.id);

                    //                if (foundAccIndex > -1) {
                    //                     let newAccounts = [...accounts];
                    //                     newAccounts[foundAccIndex] = setPackageToAccount(account, packageData);
                    //                     dispatch(setAccounts(newAccounts));
                    //                     setAccount(newAccounts[foundAccIndex]);
                    //                }

                    //                setCurrentStep(STEPS.configuringAccount);
                    //           } else {
                    //                toastError(response);
                    //           }
                    //      })

                    setCurrentStep(STEPS.configuringAccount);
               } else {
                    setStepsData({ package: packageData })
                    setCurrentStep(STEPS.accountPayment, CHANGE_STEP_TYPE.subStepNext);
               }
          }

     }

     let createAccountBodyAttrs = {
          onBackBtnClicked,
          hideStepProgress: true,
          mainContentClassName: 'full-width',
          accountPackage: true,
     };

     const changeAccount = (account) => {
          if (account.id !== accountId) {
               selectAccountID(account);
               dispatch(setShowCreateAccountPopup(false));
          }
     };

     const selectAccountID = (account) => {
          if (account.id !== accountId) {
               dispatch(setRoutes([]));
               dispatch(resetSubscriberReducer())
               dispatch(fetchAnAccount(account, accounts))
               dispatch(fetchMonitoringNotifications(account.id));
               dispatch(setActiveListener(null));
               // dispatch(setActiveDestination(null));

               localStorage.setItem('accountId', account.id);
               localStorage.removeItem(LOCAL_SHOW_ONLY_ENABLED_LISTENERS);
               localStorage.removeItem(LOCAL_SHOW_ONLY_ENABLED_DESTINATIONS);

               history.push('/');

               let localSetupInvitedAccount = localStorage.getItem(LOCAL_SETUP_INVITED_ACCOUNT);
               callTokenApi(`${API_CLIENT_FIND_USER_ACCOUNT}${userId}/${account.id}`, 'GET', null).then(response => {
                    if (response.status === 200) {
                         const { userAccount } = response.data;
                         // Tracking Change Account
                         const userDetails = {
                              userId: user.id,
                              email: user.email
                         };

                         const _activeAccount = accounts.find(acc => acc.id === userAccount.accountId);
                         const accountDetails = {
                              accountid: _activeAccount.id,
                              accountname: _activeAccount.name
                         };
                         gtmTrackingChangeLLAccount(userDetails, accountDetails);
                         if (localSetupInvitedAccount === 'yes' || (userAccount && !userAccount.firstLoginAccount && typeof userAccount.firstLoginAccount !== 'undefined' && userAccount.firstLoginAccount !== 'undefined')) {
                              localStorage.removeItem(LOCAL_SETUP_INVITED_ACCOUNT);
                              // callTokenApi(`${API_CLIENT_ACCOUNT}${activeAccount.accountId}`, 'GET', null);
                              const hash = history.location.hash;
                              if (!hash || hash !== '#payment-method') {
                                   history.push(AUTH_PATH.WELCOME_SCREEN);
                              }
                         }
                    }
               });
          }
     }

     return (
          <CreateAccountBody {...createAccountBodyAttrs}>
               <div className="full-screen-wrapper account-see-pricing upgrade-package">
                    <h1 className='selectpackage'>Plans & Pricing</h1>
                    <h6 className="selectdecription">
                         Built for enterprise. Available to everyone.
                         <br />
                         Open an account or <span className='link' onClick={() => onClickScheduleDemo()} >schedule a demo</span> to get started
                    </h6>
                    <div className="list-package">
                         <div className='user-package'>
                              <h4 className='title'>Pricing Scales with Your Website Traffic</h4>
                              <div className='user-package-main'>
                                   <div className='user-package-content'>
                                        <p>Estimated Monthly Users</p>
                                        {/* <p>Check with Google Analytics</p> */}
                                   </div>
                                   <div className='user-package-form'>
                                        <Slider min={0} max={210000} step={null} marks={marks} value={userValue} onChange={handleSliderChange} />
                                   </div>
                              </div>
                         </div>
                         {userValue > 200000 && (
                              <div className='custom-schedule'>
                                   <img src="/assets/icons/sliders-up-regular.png" alt="sliders-up-regular" />
                                   <h4 className='custom-schedule-title'>Need 200,00+ monthly users? Let's build a custom plan for you</h4>
                                   <CButton className='custom-schedule-btn' onClick={() => onClickScheduleDemo()} >
                                        SCHEDULE A DEMO
                                   </CButton>
                                   <div className="custom-schedule-info">
                                        <div className="info-img">
                                             <img src="/assets/icons/founder.png" alt="Founder-CEO" />
                                        </div>
                                        <p className='custom-schedule-desc'>“Schedule a call and I'll join to answer any questions you may have about the platform.”</p>
                                        <div className='custom-schedule-founder'>
                                             <h5>Zachary Randall</h5>
                                             <p>Founder & CEO, ListenLayer</p>
                                        </div>
                                   </div>
                              </div>
                         )}
                         {userValue <= 200000 && (
                              <div className="list-package row">
                                   {
                                        packagesToDisplay.map((packageData) => {
                                             const {
                                                  id: packageId,
                                                  pricePerMonth
                                             } = packageData;

                                             let btnText = '15 Day Free Trial';
                                             let isSchedule = false

                                             if (pricePerMonth === 0) {
                                                  btnText = 'Try Free'
                                             }

                                             if (!packageId) {
                                                  btnText = 'Schedule A Demo'
                                                  if (isScheduleDemo)
                                                       isSchedule = true
                                             }

                                             return (
                                                  <CCol key={packageId} lg="4" sm="6" className="package-col">
                                                       <PackageItem
                                                            packageData={packageData}
                                                            btnText={btnText}
                                                            btnClick={() => selectPaidPackage(packageData)}
                                                            isScheduleDemo={isSchedule}
                                                            setShowPopupLargeSandbox={setShowPopupLargeSandbox}
                                                       />
                                                  </CCol>
                                             )
                                        })
                                   }
                              </div>
                         )}

                         {/* {(isScheduleDemo && accounts.length > 1) && (
                              <div style={{ marginTop: '40px' }}>
                                   <CButton color="primary" onClick={() => {
                                        dispatch(setActiveAccount(account))
                                        changeAccount(accountsSort[(accountsSort.length - 1)])
                                   }} >
                                        Exist without selecting a plan
                                   </CButton>
                              </div>
                         )} */}

                         <div className='enhancement'>
                              <h4>Enhancements</h4>
                              <p className='enhancement-des'>Enhance your marketing & revenue analytics with custom data and service addons.</p>
                              <hr />
                              <div className='enhancement-content'>
                                   <p className='enhancement-content-title'>Revealed Companies*</p>
                                   <p className='enhancement-content-desc'>Identify companies interacting with your website. Build a list of target accounts, monitor activity, understand buying
                                        intent, and gain insights to drive marketing & sales.</p>
                              </div>
                              <div className='enhancement-content'>
                                   <p className='enhancement-content-title'>Implementation & Managed Services**</p>
                                   <p className='enhancement-content-desc'>Rely on our team for implementation, or collaborate in a fully-managed services arrangement that allows you to
                                        achieve your goals with minimal time-investment. Annual business plans include 2 Success Sessions, and can also
                                        be expanded.</p>
                              </div>
                              <div className='enhancement-content'>
                                   <p className='enhancement-content-title'>Enterprise Support</p>
                                   <p className='enhancement-content-desc'>Some of the worlds largest businesses rely on ListenLayer to power their marketing data. Schedule a demo to learn
                                        about our Enterprise offerings.</p>
                              </div>
                              <div className='enhancement-schedule'>
                                   <p className='enhancement-schedule-title'>Schedule a demo to learn more about these addons</p>
                                   <CButton color="primary" className='btn-select-upgrade primary' onClick={() => onClickScheduleDemo()} >
                                        SCHEDULE A DEMO
                                   </CButton>
                              </div>
                         </div>
                    </div>
                    {/* <div className="list-package">
                         <Slider {...settings}>
                              {
                                   packages.map((packageData) => {
                                        const {
                                             id: packageId
                                        } = packageData;

                                        return (
                                             <div key={packageId} >
                                                  <PackageItem
                                                       packageData={packageData}
                                                       btnText={"15 Day Free Trial"}
                                                       btnClick={() => selectPaidPackage(packageData)}
                                                       loading={loading}
                                                  />
                                             </div>
                                        )
                                   })
                              }
                         </Slider>
                    </div> */}
               </div>
          </CreateAccountBody>
     )
}

export default AccountPackage
