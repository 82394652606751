import React from 'react';
import PropTypes from 'prop-types';
import { CSelect } from '../../migration/CInput';

const filterOptions = [
     { value: '', text: 'Select an option' },
     { value: '1', text: 'Yes' },
     { value: '0', text: 'No' },
]

const BooleanFilter = ({ filterData, onChange }) => {
     return (
          <>
               {
                    <CSelect
                         name={filterData.key}
                         onChange={e => onChange(e.target.name, e.target.value)}
                         value={filterData.value}
                    >
                         {filterOptions.map(option => <option key={option.text} className="text-dark" value={option.value}>{option.text}</option>)}
                    </CSelect>
               }
          </>
     )
}

BooleanFilter.propTypes = {
     filterData: PropTypes.object,
     onChange: PropTypes.func
}

export default React.memo(BooleanFilter);
