import React, { useEffect } from 'react';
import {
     CModal,
     CModalBody,
     CButton,
} from '@coreui/react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';

import {
     setDomainsPreview,
     setDomainPreview
} from '../../../actions/subscriber';
import { tryParseJSON } from '../../../utils';

const SelectDomainModal = ({ show, onClose, onAccept }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const { domain: domainRedux, domains: initDomains, isDisable } = useSelector(state => state.subscriber.preview);
     const domainSelected = initDomains.find(d => d.value === domainRedux) || '';

     useEffect(() => {
          const newInitDomains = tryParseJSON(activeAccount.domains) || [];

          dispatch(setDomainsPreview(newInitDomains));

          if (newInitDomains.length === 1) {
               dispatch(setDomainPreview(newInitDomains[0].name));
          }
     }, [activeAccount]); // eslint-disable-line react-hooks/exhaustive-deps

     const onChangeDomain = (val) => {
          dispatch(setDomainPreview(val));
     };

     return (
          <div className="confirm-save-popup select-domain">
               <CModal
                    visible={show}
                    onClose={onClose}
                    color="primary"
                    alignment="center"
                    portal={false}
               >
                    <CModalBody className="text-center confirm-save">
                         <h1>Select Domain</h1>
                         <div className="confirm-save-content">
                              Select the domain from your account that you want to test.
                         </div>
                         <div className="mb-4">
                              {initDomains && (
                                   <Select classNamePrefix='react-select'
                                        name="domain-select"
                                        className="text-left"
                                        options={initDomains}
                                        value={domainSelected}
                                        onChange={(e) => onChangeDomain(e.value)}
                                   />
                              )}
                         </div>
                         <div className="position-relative d-inline-block">
                              <CButton
                                   className="btn-info mr-4"
                                   onClick={onAccept}
                                   disabled={isDisable}
                              >
                                   Preview Now
                              </CButton>
                              <CButton
                                   onClick={onClose}
                                   className=" btn btn-cancel rounded-pill cancel"
                              >
                                   Cancel
                              </CButton>
                         </div>
                    </CModalBody>
               </CModal>
          </div>
     )
}

SelectDomainModal.propTypes = {
     show: PropTypes.bool,
     onClose: PropTypes.func,
     onAccept: PropTypes.func
}

export default SelectDomainModal
