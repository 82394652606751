import React, { useState } from 'react';
import {
     CModal,
     CModalBody,
     CButton,
     CCollapse,
} from '@coreui/react';
import PropTypes from 'prop-types';
import { LOCAL_BEGIN_TEST } from '../../../constants';
import { CInput } from '../../../components/migration/CInput';
import CLabel from '../../../components/migration/CLabel';

const PopupBeginTest = ({ show, onClose, closePreview }) => {
     const [collapseShow, setShow] = useState('howItWork');
     const [dontShow, setDontShow] = useState(false);

     const toggle = (val) => {
          setShow(val);
     }

     const onAccept = () => {
          localStorage.setItem(LOCAL_BEGIN_TEST, dontShow);
          onClose();
     };

     const setShowAgain = (val) => {
          setDontShow(val);
     };

     return (
          <div className="confirm-save-popup begin-test">
               <CModal
                    visible={show}
                    onClose={onClose}
                    color="primary"
                    alignment="center"
                    portal={false}
               >
                    <CModalBody className="text-center confirm-save">
                         <h3>How to Test Your Data Layer</h3>
                         <p className='description'>Testing your data layer allows you to see and confirm how Listeners are generating data from your website.</p>
                         <div className="content">
                              <div className='collapse-row'>
                                   <button className={`event-toggle${collapseShow === 'howItWork' ? ' show' : ''}`} onClick={() => toggle(collapseShow === 'howItWork' ? null : 'howItWork')}>
                                        <strong>How it works</strong>
                                   </button>
                                   <CCollapse visible={collapseShow === 'howItWork'}>
                                        <div className="collapse-body">
                                             <div className="mb-3">
                                                  You'll visit and interact with your website on the right side of the screen.
                                                  On the left, you'll see live data layer events that are seen and collected from your website by ListenLayer.
                                                  Data from your testing sessions is not stored in your reports.
                                             </div>
                                             <div>Please note: If your website's security policy does not allow iframes, your website will open in a new tab.</div>
                                        </div>
                                   </CCollapse>
                              </div>
                              <div className='collapse-row'>
                                   <button className={`event-toggle${collapseShow === 'howToUseIt' ? ' show' : ''}`} onClick={() => toggle(collapseShow === 'howToUseIt' ? null : 'howToUseIt')}>
                                        <strong>How to use it</strong>
                                   </button>
                                   <CCollapse visible={collapseShow === 'howToUseIt'}>
                                        <div className="collapse-body">
                                             While testing, you can copy data layer elements, push triggers and variables to Google Tag manager, and create custom data rules for Variables and browser storage.
                                        </div>
                                   </CCollapse>
                              </div>
                         </div>
                         <div className="position-relative d-inline-block">
                              <CButton
                                   className="btn-info"
                                   onClick={() => onAccept()}
                              >
                                   Begin my test
                              </CButton>
                              <div className='mb-4'>
                                   <div className="btn-cancel" onClick={() => closePreview()}>Cancel Test</div>
                              </div>
                              <div className='show-again'>
                                   <CInput
                                        type="checkbox"
                                        id="showAgain"
                                        checked={dontShow}
                                        onChange={e => setShowAgain(e.target.checked)}
                                   />
                                   <CLabel htmlFor="showAgain">Don't show this notice again</CLabel>
                              </div>
                         </div>
                    </CModalBody>
               </CModal>
          </div>
     )
}

PopupBeginTest.propTypes = {
     show: PropTypes.bool,
     onClose: PropTypes.func
}

export default PopupBeginTest
