import React, { useContext } from 'react';
import { CCard, CCardBody, CButton } from '@coreui/react';

import { CreateAccountBody, CreateAccountContext, STEPS } from './CreateAccount';
import useSaveCurrentStep from './useSaveCurrentStep';

const AlmostDone = () => {
    const { setCurrentStep } = useContext(CreateAccountContext);

    const goNext = () => {
        setCurrentStep(STEPS.configureMeasurement);
    };

    useSaveCurrentStep()

    return (
        <CreateAccountBody>
            <CCard className="account-almost-done">
                <CCardBody>
                    <h3>You’re almost done!</h3>
                    <p className="des">
                        We’re almost ready to take you to your account, but there are a few important steps to complete so you can see ListenLayer
                        working.
                    </p>
                    <div className="notice">
                        <h4>What’s next?</h4>
                        <p>
                            We need you to turn on Listeners to automate measurement. A Listener is a sensor that identifies specific user activity that
                            you care about.
                        </p>
                        <p>
                            This process will be fast! We’ll suggest things you should enable and then we’ll take you to your account so you can see the
                            results.
                        </p>
                    </div>
                    <CButton className="btn-save" onClick={goNext}>
                        LET’S GO
                    </CButton>
                </CCardBody>
            </CCard>
        </CreateAccountBody>
    );
};

export default AlmostDone;
