import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { tagPropType } from '../../../utils';
import { setOverlay } from '../../../actions/common';

export const Context = React.createContext({})

//component - CoreUI / CDropdown

const Dropdown = props => {
     const dispatch = useDispatch();
     const {
          isHeaderDropdown, // isHeaderDropdown === true, it'll add overlay to body when dropdown is opening
          className,
          tag,
          //
          innerRef,
          inNav,
          focusInputSelector,
          forceOpen,
          ...attributes
     } = props;

     const [reference, setReference] = useState();
     const [isOpen, setIsOpen] = useState();
     const [split, setSplit] = useState();
     const [placement, setPlacement] = useState('');

     const carretClass = placement.includes('top') ? 'dropup' :
          placement.includes('right') ? 'dropright' :
               placement.includes('left') ? 'dropleft' : 'dropdown';

     const Tag = tag || (inNav ? 'li' : 'div');
     const classes = classNames(
          className,
          carretClass,
          { 'nav-item': inNav, 'btn-group': split, 'show': isOpen }
     );

     const addOverlayToBody = () => {
          if (isHeaderDropdown) {
               if (isOpen) {
                    if (focusInputSelector) {
                         setTimeout(() => {
                              const filterInput = document.querySelector(focusInputSelector);

                              if (filterInput) {
                                   filterInput.focus();
                              }
                         }, 0);
                    }

                    setTimeout(() => {
                         dispatch(setOverlay(true));
                    }, 50);
               } else {
                    dispatch(setOverlay(false));
               }
          }
     }
     useEffect(addOverlayToBody, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

     return (
          <Context.Provider
               value={{
                    isOpen: isOpen || forceOpen,
                    setIsOpen,
                    reference,
                    setReference,
                    inNav,
                    setSplit,
                    setPlacement
               }}
          >
               <Tag
                    className={classes}
                    {...attributes}
                    ref={innerRef}
               />
          </Context.Provider>
     )
}

Dropdown.propTypes = {
     isHeaderDropdown: PropTypes.bool,
     tag: tagPropType,
     children: PropTypes.node,
     className: PropTypes.string,
     //
     innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
     inNav: PropTypes.bool
}

export default Dropdown
