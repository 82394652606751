import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import Select from 'react-select';
import { RegionContext } from './SingleRow';

import dataCountriesState from '../../../../../../assets/consentStateProvinces.json';
import { CInvalidFeedback } from '../../../../../migration/CInvalidFeedback';

const countries = dataCountriesState.map(country => { return { value: country.name, label: country.name } });

const RowStateProvinces = () => {
     const { errors, setFieldValue } = useFormikContext();
     const { CONSENT_REGION_TYPES, row, index, onChangeRegionType, handleHasChange } = useContext(RegionContext);

     const countryRequired = errors.geographicRegions && errors.geographicRegions[index] && errors.geographicRegions[index].countryOfState;
     const stateRequired = errors.geographicRegions && errors.geographicRegions[index] && errors.geographicRegions[index].stateProvinces;
     const regionTypeRequired = errors.geographicRegions && errors.geographicRegions[index] && errors.geographicRegions[index].regionType;

     const valueRegionType = CONSENT_REGION_TYPES.filter(option => option.value === row.regionType);
     const valueCountry = countries.filter(option => option.value === row.countryOfState);

     let stateProvinces = [];

     if (valueCountry.length > 0) {
          const selected = dataCountriesState.find(item => item.name === row.countryOfState);

          if (selected) {
               stateProvinces = selected.provinces.map(p => { return { value: p, label: p } });
          }
     }

     const valueProvince = stateProvinces.length > 0 ? stateProvinces.filter(option => option.value === row.stateProvinces) : null;

     const onChangeCountry = (value) => {
          setFieldValue(`geographicRegions.${index}.countryOfState`, value);
          setFieldValue(`geographicRegions.${index}.stateProvinces`, '');
          handleHasChange();
     };

     const onChangeStateProvince = (value) => {
          setFieldValue(`geographicRegions.${index}.stateProvinces`, value);
          handleHasChange();
     };

     return (
          <>

               <div className='region-type'>
                    <Select classNamePrefix='react-select'
                         className={(regionTypeRequired) ? 'is-invalid' : null}
                         value={valueRegionType}
                         name={`regionsType.${index}`}
                         options={CONSENT_REGION_TYPES}
                         onChange={(e) => onChangeRegionType(e.value)}
                         placeholder="Select a region type"
                    />
                    {!!stateRequired && <CInvalidFeedback>{stateRequired}</CInvalidFeedback>}
               </div>

               <div className='region-type'>
                    <Select classNamePrefix='react-select'
                         className={(countryRequired) ? 'is-invalid' : null}
                         value={valueCountry}
                         isDisabled={!row.regionType}
                         name={`countryOfState.${index}`}
                         options={countries}
                         onChange={(e) => onChangeCountry(e.value)}
                         placeholder="Select a Country"
                    />
                    {!!stateRequired && <CInvalidFeedback>{stateRequired}</CInvalidFeedback>}
               </div>

               {
                    !!row.countryOfState && (<>
                         <div className='region-type'>
                              <Select classNamePrefix='react-select'
                                   className={(stateRequired) ? 'is-invalid' : null}
                                   value={valueProvince}
                                   isDisabled={!row.countryOfState}
                                   name={`stateProvinces.${index}`}
                                   options={stateProvinces}
                                   onChange={(e) => onChangeStateProvince(e.value)}
                                   placeholder="Select State/Province"
                              />
                              {!!stateRequired && <CInvalidFeedback>{stateRequired}</CInvalidFeedback>}
                         </div>
                    </>
                    )
               }
          </>
     )
}
export default RowStateProvinces;