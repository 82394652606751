import React from 'react';
// import CIcon from '@coreui/icons-react';
import ManageGoalItems from './ManageGoalItems';
import { useSelector } from 'react-redux';

const ManageGoal = () => {
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     // const activeListeners = useSelector((state) => state.subscriber.activeListeners);
     // const nativeECommerce = useSelector((state) => state.subscriber.nativeECommerce);


     // useEffect(() => {
     //      const checkEcommerceStatus = activeListeners.some((item) => ['Ecommerce', 'eCommerce'].includes(item.type)) || nativeECommerce;

     //      setGoalTypes((prevGoalTypes) =>
     //           prevGoalTypes.map((goal) => {
     //                if (goal.name === 'eCommerce') {
     //                     return { ...goal, status: checkEcommerceStatus };
     //                }
     //                return goal;
     //           })
     //      );
     // }, [activeListeners]); //eslint-disable-line

     return (
          <>
               <div className="listener-title headline-wrapper">
                    <h1>Goals</h1>
                    <div className="description">
                         Manage the types of goals you want to use inside your account. Goals allow you to define rules for important action,
                         characteristics, and KPIs you care about.
                    </div>
               </div>


               {activeAccount.id && (
                    <>
                         {/* {goalTypes.length > 0 ? ( */}
                              <ManageGoalItems />
                         {/* ) : ( */}
                              {/* <div className="no-filtering-result">
                                   <h2 className="d-flex justify-content-center align-items-center">
                                        No Goal Types <CIcon name="cil-ban" width="30" className="icon-cil-ban" />
                                   </h2>
                              </div>
                         )} */}
                    </>
               )}
          </>
     );
}

export default ManageGoal;
