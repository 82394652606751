import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'; // useSelector & useDispatch can only be used in functional component

import { AsideContext } from '../TheAside';
import { getUnlimitedNumber, roundTo2DecimalPlaces } from '../../../../utils';
import { useIsLatestPlan } from '../../../../helpers/customHooks';
import LegacyPlanRecommendation from './LegacyPlanRecommendation';

const SaveReportingValuesLimitationHit = () => {
     const { setPackageSelected } = useContext(AsideContext);
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const packages = useSelector(state => state.subscriber.packages);
     const recommendationPackage = packages.find(packageItem => packageItem.numberOfSaveReportingValues === -1 || packageItem.numberOfSaveReportingValues > activeAccount.packageNumberOfSaveReportingValues);
     const isLatestPlan = useIsLatestPlan();

     const setPackage = () => {
          if (recommendationPackage) {
               setPackageSelected(recommendationPackage);
          }
     }

     useEffect(setPackage, []); // eslint-disable-line react-hooks/exhaustive-deps

     return (
          <>
               <div className="description">
                    <p className="text-center fw-4">
                         You've hit a limitation of your current plan ({activeAccount.packageFriendlyName}).
                    </p>
               </div>
               <div className="sidebar-body">
                    <h6 className="text-danger text-center limitation">Limitation Hit</h6>
                    <p className="text-center limitation-text fw-4">
                         You've hit your limit of{" "}
                         <span className="underline">
                              {activeAccount.packageNumberOfSaveReportingValues}
                         </span>
                         {" "}"Save Reporting Values" for this listener.
                    </p>
                    {
                         isLatestPlan ? (
                              recommendationPackage && (
                                   <>
                                        <h6 className="text-success text-center recommendation">Recommendation</h6>
                                        <p className="text-center recommendation-text fw-4">
                                             Upgrade to the {recommendationPackage.friendlyName} plan for ${roundTo2DecimalPlaces(recommendationPackage.pricePerYear / 12).toLocaleString('en-US')}/month{" "}{'('}paid annually{')'}{" "}
                                             and you'll have access to <span className="underline">{getUnlimitedNumber(recommendationPackage.numberOfSaveReportingValues)}</span> "Save Reporting Values" along with other great features!
                                        </p>
                                   </>
                              )
                         ) : <LegacyPlanRecommendation />
                    }
               </div>
          </>
     )
}

export default SaveReportingValuesLimitationHit;