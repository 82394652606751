import CIcon from '@coreui/icons-react';
import { CButton, CForm, CModal, CModalBody } from '@coreui/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleSetAccordion } from '../../../../actions/subscriber';
import { useActiveListener } from '../../../../helpers/customHooks';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const ModalConfigurationRequired = ({ show, onCancel }) => {
     const history = useHistory();
     const dispatch = useDispatch();
     const showConfigurationRequired = useSelector((state) => state.subscriber.showConfigurationRequired);
     const activeListener = useActiveListener();
     const pathName = history.location.pathname

     const [formLoading, setFormLoading] = useState(false);

     let nameListener = '';
     switch (showConfigurationRequired.listener) {
          case 'nativeDataLayers':
               nameListener = 'Data Layer Target';
               break;

          case 'nativeBrowserStorage':
               nameListener = 'Browser Storage Target';
               break;

          default:
               break;
     }

     const handleSubmit = () => {
          setFormLoading(true);
          dispatch(handleSetAccordion({ accordion: activeListener.alias, pathName }));
          onCancel();
          setFormLoading(false);
     };

     return (
          <div className='confirm-save-popup select-domain popup-create-variable'>
               <CModal visible={show} onClose={onCancel} color='primary' alignment="center" portal={false}>
                    <CModalBody className='text-center confirm-save'>
                         <CIcon icon='cil-x' onClick={onCancel} className='icon-close-popup'></CIcon>
                         <div className='popup-publish-version-description'>
                              <h1>Configuration Required</h1>
                              <p className='confirm-save-content'>You need to define at least 1 {nameListener} before completing a test.</p>
                         </div>

                         <CForm onSubmit={handleSubmit} noValidate>
                              <div className='form-actions'>
                                   <CButton type='submit' className='mb-3 btn-info' color='primary' disabled={formLoading}>
                                        {formLoading ? (
                                             <>
                                                  Waiting<span className='dots-waiting'></span>
                                             </>
                                        ) : (
                                             'Go to Configuration'
                                        )}
                                   </CButton>
                              </div>
                         </CForm>
                    </CModalBody>
               </CModal>
          </div>
     );
};

ModalConfigurationRequired.propTypes = {
     show: PropTypes.bool,
     onCancel: PropTypes.func,
};

export default ModalConfigurationRequired;
