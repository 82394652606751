import CIcon from '@coreui/icons-react';
import { CButton, CCard, CCardBody, CForm } from '@coreui/react';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';

import ListenerRequest from '../../../../../general/popup/ListenerRequest';
import { CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
import { useScrollFaded } from '../../../../../../helpers/customHooks';
import Waiting from '../../../../../general/Loadings/Waiting';
import useListenerSubmit from './useListenerSubmit';
import useListenerBackBtnClicked from './useListenerBackBtnClicked';
import useSaveListenerProgress from './useSaveListenerProgress';
import { LISTENER_TYPE } from '../../../../../../constants';
import { CInput } from '../../../../../migration/CInput';
import CFormGroup from '../../../../../migration/CFormGroup';

const ChatListener = () => {
    const { account, stepsData } = useContext(CreateAccountContext);
    const [showListenerRequestPopup, setShowListenerRequestPopup] = useState(false);
    const { listeners, selectedListenerTopics, allSelectedListeners, totalConfigureMeasurementSteps } = stepsData;
    const [searchText, setSearchText] = useState('');
    let listenersToDisplay = listeners.filter(listener => listener.code !== 'siteStaffChat' && listener.type === LISTENER_TYPE.CHAT && listener.name.toLowerCase().includes(searchText.toLowerCase()));
    const { containerListClass, onScrollContainerList } = useScrollFaded({ hasScroll: listenersToDisplay.length > 9 });
    const currentListenerIndex = selectedListenerTopics.findIndex(topic => topic === STEPS.chatListener);
    const listenerType = 'chat';
    const handleListenerSubmit = useListenerSubmit(listenerType, currentListenerIndex);
    const handleBackBtnClicked = useListenerBackBtnClicked(listenerType, currentListenerIndex);
    useSaveListenerProgress();

    const initialValues = {
        selectedListeners: allSelectedListeners[listenerType] || []
    };

    const checkIfChecked = (selectedListeners, listener) => {
        return !!selectedListeners.find(selectedListener => selectedListener.id === listener.id);
    }

    const onBackBtnClicked = (selectedListeners) => {
        handleBackBtnClicked(selectedListeners);
    };

    const toggleRequestPopup = () => {
        setShowListenerRequestPopup(show => !show);
    };

    const closeRequestPopup = () => {
        setShowListenerRequestPopup(false);
    };

    const onSubmit = ({ selectedListeners }) => {
        handleListenerSubmit(selectedListeners);
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {({ values, handleSubmit, setFieldValue, isSubmitting }) => {
                const handleSelect = (listener) => {
                    let newSelectedListeners = [...values.selectedListeners];
                    const foundListenerIndex = values.selectedListeners.findIndex(selectedListener => selectedListener.id === listener.id);

                    if (foundListenerIndex > -1) {
                        newSelectedListeners.splice(foundListenerIndex, 1);
                    } else {
                        newSelectedListeners.push(listener);
                    }

                    setFieldValue('selectedListeners', newSelectedListeners);
                };

                return (
                    <CreateAccountBody onBackBtnClicked={() => onBackBtnClicked(values.selectedListeners)} backBtnDisabled={isSubmitting}>
                        <CCard className="account-form chat-listener">
                            {totalConfigureMeasurementSteps > 1 &&
                                (
                                    <p className="number-of-step">
                                        {currentListenerIndex} of {totalConfigureMeasurementSteps} steps
                                    </p>
                                )
                            }
                            <CCardBody>
                                <CForm onSubmit={handleSubmit}>
                                    <CFormGroup>
                                        <h3>Select Your Chat Listener</h3>
                                        <p>Search for the chat tool you use on your website.</p>
                                        <div className="filter-search">
                                            <CInput
                                                name="search"
                                                className="search"
                                                type="text"
                                                placeholder="Search for your chat tool…"
                                                value={searchText}
                                                onChange={(e) => setSearchText(e.target.value)}
                                                data-lpignore="true"
                                            />
                                            <CIcon icon="iconFilterSearch" height={16} />
                                        </div>
                                        <div className={`list-form ${containerListClass}`} onScroll={onScrollContainerList}>
                                            {listenersToDisplay.length > 0 ? (
                                                listenersToDisplay.map((listener) => (
                                                    <label key={listener.id}>
                                                        <input
                                                            type="checkbox"
                                                            checked={checkIfChecked(values.selectedListeners, listener)}
                                                            onChange={() => handleSelect(listener)}
                                                            disabled={isSubmitting}
                                                        />
                                                        <div className="icon-box">
                                                            <div className="tickmark"></div>
                                                            <img className="listener-logo" src={listener.logo} alt={`${listener.name} Icon`} />
                                                            <span>{listener.name}</span>
                                                        </div>
                                                    </label>
                                                ))
                                            ) : (
                                                <p className="item-not-found">No items found.</p>
                                            )}
                                        </div>
                                        <CButton className="btn-save" type="submit" disabled={values.selectedListeners.length === 0 || isSubmitting}>
                                            <Waiting isLoading={isSubmitting}>NEXT</Waiting>
                                        </CButton>
                                        <CButton className="foot-note" onClick={toggleRequestPopup}>
                                            I can’t find my chat tool
                                        </CButton>
                                    </CFormGroup>
                                </CForm>
                            </CCardBody>
                        </CCard>
                        <ListenerRequest
                            show={showListenerRequestPopup}
                            onClose={closeRequestPopup}
                            accountId={account.id}
                            listenerType="Chat"
                            next={() => onSubmit({ selectedListeners: [] })}
                            placeholder="Enter the name of your chat tool"
                        />
                    </CreateAccountBody>
                );
            }}
        </Formik>
    );
};

export default ChatListener;
