import React, { useState, useCallback, useEffect } from 'react';
import {
    CModal,
    CModalBody,
} from '@coreui/react';
import PropTypes from 'prop-types';
import CIcon from '@coreui/icons-react';
import AceEditor from "react-ace";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import CEmbed from '../../migration/CEmbed';

const ScriptCode = ({ codeBlock }) => {
    const [firstCopy, setFirstCopy] = useState(false);

    const copyFirstScript = useCallback(() => {
        setFirstCopy(true);
    }, [])

    useEffect(() => {
        if (firstCopy) {
            setTimeout(() => {
                setFirstCopy(false);
            }, 2000);
        }
    }, [firstCopy])

    return (
        <div className="script-code">
            <div className="float-right d-flex align-items-center pointer">
                <CopyToClipboard
                    text={codeBlock}
                    onCopy={copyFirstScript}
                >
                    <CIcon icon="icon-copy" height={24} width={24} />
                </CopyToClipboard>
                {firstCopy && <div className="icon-copy">Copied</div>}
            </div>

            <div className="script">
                <AceEditor
                    mode="html"
                    theme="monokai"
                    name="blah2"
                    fontSize={14}
                    height="300px"
                    showPrintMargin={false}
                    showGutter={true}
                    highlightActiveLine={false}
                    readOnly={true}
                    value={codeBlock}
                    focus
                    wrapEnabled={true}
                    setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: false,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2,
                        useWorker: false
                    }}
                />
            </div>
        </div>
    )
}

const InstructionPopup = ({ show, onClose, headline, someSmallAmountText,className, videoEmbed, moreText, codeBlock, button, sections }) => {
    return (
        <div className={`instruction-popup text-center ${className}`}>
            <CModal visible={show} onClose={onClose} alignment="center" portal={false} style={{ display: 'block' }} >
                {show && (
                    <CModalBody>
                        <CIcon icon="cil-x" onClick={onClose} className="icon-close-popup" />
                        {headline && <h4>{headline}</h4>}
                        {someSmallAmountText && <p className="some-small-amount-text">{someSmallAmountText}</p>}
                        {videoEmbed && (
                            <CEmbed className="instruction-video" ratio="16by9">
                                <iframe
                                    src={videoEmbed}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title={headline || 'instruction'}
                                />
                            </CEmbed>
                        )}
                        {sections && <>{sections.headline && <h5>{sections.headline}</h5>}</>}
                        {moreText && <p className="more-text">{moreText}</p>}
                        {codeBlock && <ScriptCode codeBlock={codeBlock} />}
                        {sections && (
                            <>
                                {sections.secondHeadline && <h5>{sections.secondHeadline}</h5>}
                                {sections.secondDescription && <p className="more-text">{sections.secondDescription}</p>}
                                {sections.secondCodeBlock && <ScriptCode codeBlock={sections.secondCodeBlock} />}
                            </>
                        )}
                        {button && button.text && button.link && (
                            <a className="button" href={button.link} target={button.openNewTab ? 'blank' : ''}>
                                {button.text}
                            </a>
                        )}
                    </CModalBody>
                )}
            </CModal>
        </div>
    );
}

InstructionPopup.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    headline: PropTypes.string,
    someSmallAmountText: PropTypes.string,
    videoEmbed: PropTypes.string,
    moreText: PropTypes.string,
    codeBlock: PropTypes.string,
    sections: PropTypes.object,
    button: PropTypes.shape({ text: PropTypes.string, link: PropTypes.string, openNewTab: PropTypes.bool })
}

export default InstructionPopup;
