import { CButton, CCard, CCardBody } from '@coreui/react';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { GTMWizardBody, GTMWizardContext } from '.';
import { setFlexibleModal } from '../../../../../actions/common';
import { setGTMWizard } from '../../../../../actions/subscriber';
import successAnimationData from '../../../../../assets/lottie-files/success.json';
import CommonLottie from '../../../../general/lottie/CommonLottie';

const GTMSuccess = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { stepsData } = useContext(GTMWizardContext);
    const { gtmHasUnpublishedChanges, workspaceName, doPublish, gtmHasLLWorkspace } = stepsData;
    const linkToRedirect = useSelector(state => state.subscriber.gtmWizard.linkToRedirect);
    const gtmWizard = useSelector(state => state.subscriber.gtmWizard);
    const goNext = () => {
        dispatch(setGTMWizard({ show: false }));

        if (linkToRedirect) {
            history.push(linkToRedirect);
        }
        dispatch(
            setFlexibleModal({
                 show: false,
                 ruleId: '',
                 component: '',
                 ruleIdLv2: '',
                 componentLv2: ''
            })
       );
       localStorage.removeItem('gtmHasChanges')
    };
    return (
        <GTMWizardBody>
            <CCard className="account-gtm success">
                <CCardBody>
                    <CommonLottie animationData={successAnimationData} />
                    <h3>Success!</h3>
                    {
                        doPublish ? (
                            gtmWizard.gtmPublicVariable ? <p>We noticed that the changes no longer need to be published, most likely because someone published them manually.  We’ve updated the status of the GTM element inside ListenLayer. </p>
                                : <p>We've published on your website.</p>
                        ) : (
                            (gtmHasUnpublishedChanges || gtmHasLLWorkspace) ? (
                                <p>
                                    We've completed all of the changes in your GTM account, but they have not been published.
                                    Please review the <strong>{workspaceName}</strong> Workspace and publish them when you are ready.
                                </p>
                            ) : (
                                <p>
                                    We've completed all of the changes in your GTM account, but they have not been published.
                                    Please review the <strong>Default Workspace</strong> and publish them when you are ready.
                                </p>
                            )
                        )
                    }
                    <CButton className="btn-save" onClick={goNext}>
                        Next
                    </CButton>
                </CCardBody>
            </CCard>
        </GTMWizardBody>
    );
};

export default GTMSuccess;
