import React from 'react';
import EditRule from './EditRule';
import CreateNewRule from './CreateNewRule';

const ConsentRule = ({ id }) => {
     return (
          <div className='rule-detail'>{id ? <EditRule id={id} /> : <CreateNewRule />}</div>
     );
};

export default ConsentRule;
