import React, { useContext } from 'react';
import { CCard, CCardBody, CButton } from '@coreui/react';

import { CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
import { removeHTMLFromString } from '../../../../../../utils';
import SimpleCopyButton from '../../../../../general/buttons/SimpleCopyButton';

const GTMNoPublishingRights = () => {
    const { stepsData, setCurrentStep, gtmWorkspaceName } = useContext(CreateAccountContext);
    const { selectedGTMContainer, domain } = stepsData;
    const { publicId, userPermissions } = selectedGTMContainer;
    let htmlMailMessage = `I have some changes in GTM that need to be published and I don't have publish access.`;
    htmlMailMessage += ` You'll find the changes in this container <b>${publicId}</b> and this workspace ${gtmWorkspaceName}.`;
    htmlMailMessage += ` We're just adding a simple tag to ${domain} so we can use ListenLayer to improve our measurement. Can you go ahead and publish these changes to make them live?`;
    let mailMessage = removeHTMLFromString(htmlMailMessage);
    const gtmContainerAdmins = Array.isArray(userPermissions) ? userPermissions.filter(permission => permission.accountAccess.permission === 'admin') : [];

    const goNext = () => {
        setCurrentStep(STEPS.cannotVerify);
    };

    return (
        <CreateAccountBody>
            <CCard className="account-gtm-no-publish">
                <p className="number-of-step">2 of 3 steps</p>
                <CCardBody>
                    <h3>Oh no!</h3>
                    <p>
                        <strong>You don't have access to publish this container,</strong> so we can't make the script live.
                    </p>
                    <p className="des">
                        We've updated Google Tag Manager with all of our changes, but they need to be published by an admin. Here is a list of admins
                        on the container. Ask them to publish our changes.
                    </p>
                    <div className="email">
                        {gtmContainerAdmins.map(({ emailAddress }) => (
                            <div key={emailAddress}>
                                <a href={`mailto:${emailAddress}?subject=Google Tag Manager Request&body=${mailMessage}`}>
                                    {emailAddress}
                                </a>
                            </div>
                        ))}
                    </div>
                    <div className="script-code">
                        <div className="float-right d-flex align-items-center">
                            <SimpleCopyButton textToCopy={mailMessage} iconNew={true} />
                        </div>
                        <div className="note-copy">
                            <p dangerouslySetInnerHTML={{ __html: htmlMailMessage }}></p>
                        </div>
                    </div>
                    <CButton className="btn-navigation-step" onClick={goNext}>
                        I've sent them a request to publish, NEXT <i className="fal fa-angle-right"></i>
                    </CButton>
                </CCardBody>
            </CCard>
        </CreateAccountBody>
    );
};

export default GTMNoPublishingRights;
