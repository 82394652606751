import React, { useContext, useEffect, useState } from 'react';
import { CButton, CCard, CCardBody } from '@coreui/react';

import { CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { Popup } from '../../../../../general/popup';
import { API_GTM_WORKSPACES, API_GTM_WORKSPACE_STATUS, LOCAL_GTM_ACCESS_TOKEN } from '../../../../../../constants';
import { makeRequest } from '../../../../../../apiCaller';

const GTMVerifyChanges = () => {
    const {
        stepsData,
        setStepsData,
        gtmWorkspaceName,
        setGTMWorkspaceName,
        gtmTagName,
        gtmTagTriggerName,
        gtmFolderName,
        setCurrentStep
    } = useContext(CreateAccountContext);
    const [isLoading, setIsLoading] = useState(true);
    const [showLearnMorePopup, setShowLearnMorePopup] = useState(false);
    const { selectedGTMContainer, gtmHasUnpublishedChanges } = stepsData;

    const toggleLearnMorePopup = () => {
        setShowLearnMorePopup(show => !show)
    }

    const closeLearnMorePopup = () => {
        setShowLearnMorePopup(false)
    }

    const handleNewWorkspaceName = (workspaces) => {
        let newWorkspaceName = gtmWorkspaceName;

        if (workspaces.find(ws => ws.name === gtmWorkspaceName)) {
            for (let i = 1; i <= 20; i++) {
                const newName = `${gtmWorkspaceName} - ${i}`;

                if (!workspaces.find(ws => ws.name === newName)) { // Didn't find that newName from current workspaces
                    newWorkspaceName = newName;
                    break;
                }
            }
        }

        setGTMWorkspaceName(newWorkspaceName);
    }

    const firstLoad = () => {
        const process = async () => {
            const accessToken = localStorage.getItem(LOCAL_GTM_ACCESS_TOKEN);

            if (accessToken) {
                let gtmHasUnpublishedChanges = false;
                let defaultWorkspace = null;
                let workspaceLength;
                const workspaceRes = await makeRequest(API_GTM_WORKSPACES.replace('+parent', selectedGTMContainer.path), 'GET', null, accessToken);

                if (workspaceRes.status === 200) {
                    const { workspace } = workspaceRes.data;

                    if (Array.isArray(workspace)) {
                        workspaceLength = workspace.length;
                        defaultWorkspace = workspace.find(ws => ws.name === 'Default Workspace');

                        if (defaultWorkspace) {
                            const workspaceStatusRes = await makeRequest(API_GTM_WORKSPACE_STATUS.replace('+path', defaultWorkspace.path), 'GET', null, accessToken);

                            if (workspaceStatusRes.status === 200) {
                                const { workspaceChange } = workspaceStatusRes.data;

                                gtmHasUnpublishedChanges = !!workspaceChange;
                            } else {
                                gtmHasUnpublishedChanges = true;
                            }
                        } else {
                            gtmHasUnpublishedChanges = true;
                        }

                        if (gtmHasUnpublishedChanges) {
                            handleNewWorkspaceName(workspace); // Find the right workspace name to create
                        }
                    } else {
                        gtmHasUnpublishedChanges = true;
                    }
                }

                setStepsData({ gtmWorkspace: defaultWorkspace, gtmHasUnpublishedChanges, workspaceLength });
            }

            setIsLoading(false);
        }

        process();
    };

    useEffect(firstLoad, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onBackBtnClicked = () => {
        setCurrentStep(STEPS.gtmSelectContainer);
    };

    const completeActions = () => {
        setCurrentStep(STEPS.gtmMakeChanges);
    };

    return (
        <CreateAccountBody onBackBtnClicked={onBackBtnClicked}>
            <CCard className="account-script verify-changes">
                <CCardBody>
                    {isLoading ? (
                        <CenterSpinner />
                    ) : (
                        <>
                            <h3>Verify Changes</h3>
                            <p className="mb-4">We're going to deploy your ListenLayer script to GTM. Here are the actions we'll take.</p>
                            <p className="mb-3">
                                Inside{' '}
                                <span className="inside-container">
                                    {selectedGTMContainer.name} ({selectedGTMContainer.publicId})
                                </span>{' '}
                                we will
                            </p>
                            <div className="list-action">
                                {gtmHasUnpublishedChanges && (
                                    <div className="action">
                                        Create a new workspace called <strong>{gtmWorkspaceName}</strong>
                                    </div>
                                )}
                                <div className="action">
                                    Create a tag called <strong>{gtmTagName}</strong>
                                </div>
                                <div className="action">
                                    Set the tag trigger to <strong>{gtmTagTriggerName}</strong>
                                </div>
                                <div className="action ">
                                    Place the Tag inside a new folder called <strong>{gtmFolderName}</strong>
                                </div>
                            </div>

                            <CButton type="button" className="btn-save" onClick={completeActions}>
                                COMPLETE THESE ACTIONS
                            </CButton>
                            <div className="foot-note dialog-content">
                                {gtmHasUnpublishedChanges ? (
                                    <span>
                                        These changes will be published in GTM. We're creating a new workspace because your existing workspace has
                                        unpublished changes. Those existing changes will not be published, and we will only publish our workspace
                                        changes.
                                    </span>
                                ) : (
                                    <span>These changes will be published in GTM.</span>
                                )}
                                <CButton type="button" className="btn-learn-more" style={{ marginTop: 'auto' }} onClick={toggleLearnMorePopup}>
                                    Learn more<i className="fal fa-angle-right"></i>
                                </CButton>
                            </div>
                        </>
                    )}
                </CCardBody>
            </CCard>
            <Popup className="learn-more account-script-popup" showHeader={true} show={showLearnMorePopup} onClose={closeLearnMorePopup}>
                {gtmHasUnpublishedChanges ? (
                    <>
                        <p>
                            We'll publish our changes to ensure that ListenLayer works. This will create a new version in GTM which will allow you to
                            easily revert if needed.
                        </p>
                        <p>
                            We did find that your container already has some unpublished changes, and we don't want to publish those. As a results,
                            we'll create a unique workspace so that only our changes will be published. You can then merge these changes into your
                            other workspace at some point in the future.
                        </p>
                    </>
                ) : (
                    <p>
                        We'll publish our changes to ensure that ListenLayer works. This will create a new version in GTM which will allow you to
                        easily revert if needed. We've checked your GTM account and found that there are no other unpublished changes in the
                        container, which means only our changes are going live.
                    </p>
                )}
            </Popup>
        </CreateAccountBody>
    );
};

export default GTMVerifyChanges;
