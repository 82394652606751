import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRuleHaveEditting } from '../../../../../../../actions/common';
import { callTokenApi } from '../../../../../../../apiCaller';
import { API_CLIENT_DIMENSION_DETAIL, API_CLIENT_GET_ALL_CONVERSION, TYPE_SHOW_UNSAVE_CHANGE } from '../../../../../../../constants';
import CenterSpinner from '../../../../../../general/Loadings/CenterSpinner';
import { toastError } from '../../../../../../../utils';

const Step1 = React.lazy(() => import(`./steps/Step1`));
const Step2 = React.lazy(() => import('./steps/Step2'));
const Step3 = React.lazy(() => import('./steps/Step3'));
const Step4 = React.lazy(() => import('./steps/Step4'));

export const DimensionsDetailContext = React.createContext({});

const DEFAULT_DIMENSION_DATA = {
     dataSource: '',
     eventObjectName: '',
     pathPropertyName: '',
     availableScopes: [
          {
               isWebConversion: false,
               id: '',
               eventScopeId: '',
               objectConversion: '', 
               getFromAllEvents: false,
               relatedName: '',
               conversionId: '',
               target: '',
          },
     ],
     name: '',
     description: '',
};

const DimensionDetail = ({ id }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountId = activeAccount.id;

     const [isLoading, setIsLoading] = useState(false);
     const [activeStep, setActiveStep] = useState(1);
     const [stepsData, setStepsData] = useState(cloneDeep(DEFAULT_DIMENSION_DATA));
     const [initStepsData, setInitStepsData] = useState(cloneDeep(DEFAULT_DIMENSION_DATA));
     const [optionConversion, setOptionConversion] = useState([]);

     const fetchDimensionDetail = () => {
          setIsLoading(true);
          callTokenApi(API_CLIENT_GET_ALL_CONVERSION.replace(':accountId', accountId), 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const { listConversion } = response.data;
                         setOptionConversion(listConversion);
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setIsLoading(false);
          });
          if (!id) return;

          callTokenApi(API_CLIENT_DIMENSION_DETAIL.replace(':accountId', accountId).replace(':id', id), 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const { dimension } = response.data;
                         setStepsData(dimension);
                         setInitStepsData(dimension);
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     };
     useEffect(fetchDimensionDetail, []); // eslint-disable-line react-hooks/exhaustive-deps

     const handleHasChange = () => {
          dispatch(setRuleHaveEditting({ show: true, type: TYPE_SHOW_UNSAVE_CHANGE.EDIT_SIMPLE }));
     };

     return (
          <React.Suspense fallback={<CenterSpinner />}>
               <div className='rule-detail dimension-detail'>
                    {isLoading ? (
                         <CenterSpinner />
                    ) : (
                         <div className='cvr-create-new'>
                              <h1>{id ? `Edit Dimension: ${stepsData.name}` : `Create a Dimension`}</h1>
                              <p>{id ? 'Use the steps below to configure your dimension.' : 'Create your dimension by following the steps below. '} </p>
                              <DimensionsDetailContext.Provider value={{ id, activeStep, setActiveStep, initStepsData, setInitStepsData, stepsData, setStepsData, handleHasChange, optionConversion }}>
                                   <Step1 />
                                   <Step2 />
                                   <Step3 />
                                   <Step4 />
                              </DimensionsDetailContext.Provider>
                         </div>
                    )}
               </div>
          </React.Suspense>
     );
};

export default DimensionDetail;
