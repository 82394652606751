import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow
} from '@coreui/react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { setFlexibleModal, setRuleHaveEditting } from '../../../../../../actions/common';
import { reloadUserSource } from '../../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../../apiCaller';
import { API_CLIENT_RULE_UPDATE, COMPONENT_NAME, TYPE_SHOW_UNSAVE_CHANGE, VARIABLE_OPERATORS } from '../../../../../../constants';
import { useEventAndVariableOptions } from '../../../../../../helpers/customHooks';
import { deepCopyArray, toastError } from '../../../../../../utils';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { ConfirmSaveChange } from '../../../../../general/popup';
import SelectInsertVariable from '../../SelectInsertVariable';
import { RuleContext } from '../EditLookupCustom';
import CLabel from '../../../../../migration/CLabel';

const validationSchema = () => {
    return Yup.object().shape({
        variables: Yup.array()
            .required("Please choose at least one option of these fields!")
            .nullable(true),
    })
}

const EditStep3 = ({ stepsData, setStepsData }) => {
    const dispatch = useDispatch();
    const { activeStep, setActiveStep } = useContext(RuleContext);
    const [updateModal, setUpdateModal] = useState(false);
    const { conditions } = stepsData;
    const [newConditions, setNewConditions] = useState([]);
    const [stepSaveLoading, setStepSaveLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const edittingRule = useSelector((state) => state.theme.edittingRule);
    const { showLv2 } = useSelector(state => state.theme.flexibleModal);
    const {
        fetchLoading,
        variables,
    } = useEventAndVariableOptions(activeStep === 3);
    const initialGroup = {
        type: '',
        id: '',
        key: '',
        operator: '',
        value: '',
        isRegex: false
    }

    const getVariableOption = (item) => {
        return {
            label: item.friendlyName || item.fullName,
            value: item.fullName,
            friendlyName: item.friendlyName,
            id: item.id
        }
    }

    const getVariableOptions = (variables) => {
        let variableData = [];
        if (variables && variables.length > 0) {
            variables.forEach(variable => {
                if (variable.status && variable.childs) {
                    if (variable.fullName.includes('sourceAutomaticValues.url.query.')) {
                        variableData.push(...getVariableOptions(variable.childs));
                    }
                } else {
                    if (variable.fullName.includes('sourceAutomaticValues.url.query.')) {
                        variableData.push(getVariableOption(variable));
                    }
                }
            })
        }
        return variableData;
    }

    const getConjunctions = (type, id, key) => {
        let newGroup = {
            conjunction: 'and',
            'g0': {
                ...initialGroup,
                operator: VARIABLE_OPERATORS[0].VALUE,
                id, type, key
            }
        }
        return newGroup;
    }

    const getInitialConditions = () => {
        let variableList = [];
        let variableOptions = getVariableOptions(variables);
        let initialValues = {
            variables: []
        };
        if (conditions && conditions.length > 0) {
            conditions[0].forEach(item => {
                variableList.push(item.g0.id);
            })

            const getLastChosenOptions = (arr, list, key) => {
                arr.forEach((item, i) => {
                    let index = list.find((i) => i === item.id);
                    if (index) {
                        initialValues[key][i] = { ...item }
                    }
                });
            }

            getLastChosenOptions(variableOptions, variableList, 'variables');
        }
        return initialValues;
    }

    const initialValues = getInitialConditions();

    const onSubmit = (values) => {
        let { variables } = values;
        let conditions = [];
        let condition = [];
        if (variables) {
            variables.forEach(variable => {
                if(variable && variable.id){
                    condition.push(getConjunctions('Variable', variable.id, variable.value));
                }
            })
        }

        conditions.push(condition);
        setNewConditions(conditions);

        if (compareTwoConditions(stepsData.conditions[0], conditions[0])) {
            dispatch(setRuleHaveEditting({ ...edittingRule, showLv2: false, typeLv2: '' }));
            setTimeout(() => {
                if (!showLv2) {
                    dispatch(
                        setFlexibleModal({
                            show: true,
                            showLv2: true,
                            ruleId: stepsData.id,
                            ruleIdLv2: stepsData.id,
                            component: COMPONENT_NAME.USER_SOURCE_RULE_CUSTOM_SETTING,
                            componentLv2: COMPONENT_NAME.USER_SOURCE_RULE_CUSTOM_TABLE
                        })
                    );
                } else {
                    dispatch(
                        setFlexibleModal({
                            show: true,
                            showLv2: false,
                            ruleId: stepsData.id,
                            ruleIdLv2: '',
                            component: COMPONENT_NAME.USER_SOURCE_RULE_CUSTOM_TABLE,
                            componentLv2: ''
                        })
                    );
                }
            }, 0);
        } else {
            setUpdateModal(true);
            setStepSaveLoading(true);
        }
    }

    const handleAcceptPopup = () => {
        let conditions = [...newConditions];
        let newStepsDataConditions = [...stepsData.conditions];
        let newList = [], newStepsData = {};
        newList = newStepsDataConditions.map((stepCondition) => {
            let newCons = [];
            newCons = conditions[0].map(condition => {
                let findIndex = checkInArray(condition.g0.id, stepCondition);
                if (findIndex !== -1) {
                    condition = stepCondition[findIndex];
                }
                return condition;
            });
            return deepCopyArray(newCons);
        });

        newList.forEach(condition => {
            condition.forEach(item => {
                if (item.conjunction === "") {
                    item.conjunction = "and"
                }
            });
            condition[condition.length - 1].conjunction = '';
        });

        newStepsData = { ...stepsData, conditions: newList };
        callTokenApi(API_CLIENT_RULE_UPDATE, 'POST', newStepsData)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(reloadUserSource());
                    toast.success('Successfully update rule');
                    dispatch(setRuleHaveEditting({ ...edittingRule, showLv2: false, typeLv2: '' }));
                    setTimeout(() => {
                        if (!showLv2) {
                            dispatch(
                                setFlexibleModal({
                                    show: true,
                                    showLv2: true,
                                    ruleId: stepsData.id,
                                    ruleIdLv2: stepsData.id,
                                    component: COMPONENT_NAME.USER_SOURCE_RULE_CUSTOM_SETTING,
                                    componentLv2: COMPONENT_NAME.USER_SOURCE_RULE_CUSTOM_TABLE
                                })
                            );
                        } else {
                            dispatch(
                                setFlexibleModal({
                                    show: true,
                                    showLv2: false,
                                    ruleId: stepsData.id,
                                    ruleIdLv2: '',
                                    component: COMPONENT_NAME.USER_SOURCE_RULE_CUSTOM_TABLE,
                                    componentLv2: ''
                                })
                            );
                        }
                    }, 0);
                } else {
                    toastError(response);
                }
            })
            .finally(() => {
                setSaveLoading(false);
                setStepSaveLoading(false);
                setStepsData(newStepsData);
                setUpdateModal(false);
                setActiveStep(5);
            })
    }

    const compareTwoConditions = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }

        for (let index in arr1) {
            if (arr1[index].g0.id !== arr2[index].g0.id)
                return false;
        }
        return true;
    }

    const checkInArray = (value, array) => {
        let index = -1;
        array.some((item, itemIndex) => {
            if (item.g0.id.indexOf(value) !== -1) {
                index = itemIndex;
                return true;
            }
            return false;
        });
        return index;
    };

    const handleHasChange = () => {
        dispatch(setRuleHaveEditting({ showLv2: true, typeLv2: TYPE_SHOW_UNSAVE_CHANGE.EDIT_LOOKUP }));
    };

    return (
        <>
            <CCard className={`cvr-step-3 ${activeStep !== 3 ? 'difference-step' : 'cvr-step-card'}`}>
                <CCardHeader>
                    {
                        activeStep !== 3 ? (
                            <div className="rule-step d-inline-flex justify-content-between align-items-center w-100">
                                <h5 className="mb-0 inactive">Step 2: Select Condition Fields</h5>
                                {
                                    (stepsData.conditions.length > 0) &&
                                    <CButton className="btn-edit" onClick={() => setActiveStep(3)}>
                                        Edit
                                    </CButton>
                                }
                            </div>
                        ) : (
                            <h5>Step 2: Select Condition Fields</h5>
                        )
                    }
                </CCardHeader>
                {
                    activeStep === 3 && (
                        <CCardBody>
                            <CRow>
                                <CCol md="7">
                                    {
                                        fetchLoading ? (
                                            <CenterSpinner />
                                        ) : (
                                            <Formik
                                                initialValues={initialValues}
                                                validationSchema={validationSchema}
                                                onSubmit={onSubmit}
                                                validateOnChange={false}
                                                validateOnBlur={false}
                                                enableReinitialize
                                            >
                                                {
                                                    ({
                                                        values,
                                                        errors,
                                                        touched,
                                                        setFieldValue,
                                                        setFieldTouched,
                                                        handleSubmit,
                                                        setErrors,
                                                    }) => (
                                                        <Form onSubmit={handleSubmit} className="rule-step">
                                                            <p>
                                                                Select the data layer events or variables you would
                                                                like to use as conditions to define other data layer values.
                                                            </p>
                                                            <div className="variables-multiselect mb-3">
                                                                <CLabel>Variables</CLabel>
                                                                <SelectInsertVariable
                                                                    name="variables"
                                                                    placeholder="Select Variables"
                                                                    options={getVariableOptions(variables)}
                                                                    value={values.variables}
                                                                    onChange={setFieldValue}
                                                                    hasChange={handleHasChange}
                                                                    isMulti={true}
                                                                    selectInputType={false}
                                                                    error={errors.variables}
                                                                />
                                                            </div>
                                                            <div className="text-left">
                                                                <CButton
                                                                    className="px-4"
                                                                    color="primary"
                                                                    type="submit"
                                                                    disabled={stepSaveLoading}
                                                                >
                                                                    {`Save & Manage Values`}
                                                                </CButton>
                                                            </div>
                                                        </Form>
                                                    )
                                                }
                                            </Formik>
                                        )
                                    }
                                </CCol>
                            </CRow>
                        </CCardBody>
                    )
                }
            </CCard>
            <ConfirmSaveChange
                show={updateModal}
                onClose={() => {
                    setUpdateModal(false);
                    setStepSaveLoading(false);
                }}
                onAccept={handleAcceptPopup}
                title="Warning! This Will Change Your Rule"
                isLoading={saveLoading}
            >
                You are changing Conditions. This will make some changes to this rule.
                You will need to review your lookup table settings and adjust them as needed.
            </ConfirmSaveChange>
        </>
    )
}

EditStep3.propTypes = {
    stepsData: PropTypes.object,
    setStepsData: PropTypes.func
}
export default EditStep3
