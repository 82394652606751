import React, { useContext } from 'react';
import { CButton, CCard, CCardBody } from '@coreui/react';

import { CustomDestinationBody, CustomDestinationContext } from '.';
import { CUSTOM_DESTINATION_MODAL_STEPS } from '../../../../../constants';

const Intro = () => {
     const { closeModal, setCurrentStep } = useContext(CustomDestinationContext);

     const goNext = () => {
          setCurrentStep(CUSTOM_DESTINATION_MODAL_STEPS.NAME_DESTINATION);
     };

     return (
          <CustomDestinationBody onBackBtnClicked={closeModal}>
               <CCard>
                    <CCardBody>
                         <h3>Create a Custom Destination</h3>
                         <p>
                              Build a custom destination if our library is missing a destination you need. This will allow you to{" "}
                              run any javascript tag on triggers, conversions, or page views that you choose while keeping your work organized.
                         </p>
                    </CCardBody>
               </CCard>
               <CButton className="btn-save" onClick={goNext}>NEXT</CButton>
          </CustomDestinationBody>
     );
};

export default Intro;
