import CIcon from '@coreui/icons-react';
import { CButton, CForm } from '@coreui/react';
import React, { Fragment, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import {
     handleFetchCountFormsInventory,
     handleFetchFormsInventory,
     setAllFormsInventory,
     setFilterEditFormCategories,
     setFilterFormCategories,
     setFormInventory,
     setFormInventoryEdit,
} from '../../../../../../actions/subscriber';
import { CInput } from '../../../../../migration/CInput';
import { CInvalidFeedback } from '../../../../../migration/CInvalidFeedback';
import CLabel from '../../../../../migration/CLabel';
import CFormGroup from '../../../../../migration/CFormGroup';

const validationSchema = () => {
     return Yup.array(
          Yup.object().shape({
               type: Yup.string().trim().required('This field is required!'),
          }),
     );
};
const FormFilter = ({ setShowTooltip }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const listCustomCategories = useSelector((state) => state.subscriber.customCategories);
     const filterFormCategories = useSelector((state) => state.subscriber.filterFormCategories);
     const filterEditFormCategories = useSelector((state) => state.subscriber.filterEditFormCategories);

     const categoryOptions = useMemo(() => {
          const newListCategories =
               listCustomCategories && listCustomCategories.length > 0
                    ? [{ id: '', name: 'Select a category', value: null }, ...listCustomCategories]
                    : [];

          return newListCategories ? newListCategories.map((category) => ({ value: category.id, label: category.name })) : [];
     }, [listCustomCategories]);

     const initialValue = [{ type: '', operator: 'ct', value: '' }];

     const filterOptions = [
          { label: 'Listener Name', value: 'listenerName' },
          { label: 'Form ID', value: 'formValue' },
          { label: 'Form Location', value: 'formLocation' },
          { label: 'Form Category', value: 'categoryId' },
          { label: 'Form Name', value: 'name' },
     ];

     const operatorOptions = [
          { value: 'ct', label: 'contains' },
          { value: 'nli', label: 'not contain' },
          { value: 'eq', label: 'equals to' },
          { value: 'neq', label: 'not equal to' },
          { value: 'nex', label: 'does not exist' },
     ];

     const handleSubmit = (values) => {
          const newValues = values.map((value) => {
               if (value.operator === 'nex') {
                    return { ...value, operator: 'eq' };
               }

               return value;
          });
          dispatch(setFilterFormCategories(newValues));
          dispatch(setFormInventory(null));
          dispatch(setAllFormsInventory(null));
          dispatch(setFormInventoryEdit(null));
          dispatch(handleFetchCountFormsInventory(null, activeAccount, newValues));
          dispatch(handleFetchFormsInventory(20, false, 0, newValues));
     };

     return (
          <div className="form-filter">
               <Formik
                    initialValues={filterEditFormCategories || initialValue}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validateOnBlur={false}
               >
                    {({ values, errors, handleSubmit, setErrors, setValues }) => {
                         const handleChangeFilter = ({ valueFilter, position, type, setValues, item }) => {
                              const { value } = valueFilter;
                              const newFilter = [...values];
                              if ((item.type === 'categoryId' && type === 'type') || (value === 'nex' && type === 'operator')) {
                                   newFilter[position] = { ...newFilter[position], [`${type}`]: value, value: '' };
                              } else {
                                   newFilter[position] = { ...newFilter[position], [`${type}`]: value };
                              }

                              if (value === 'categoryId' && (item.operator === 'ct' || item.operator === 'nli')) {
                                   newFilter[position] = { ...newFilter[position], type: 'categoryId', operator: 'eq', value: '' };
                              }

                              if (type === 'type' && value !== 'categoryId' && value !== 'name' && item.operator === 'nex') {
                                   newFilter[position] = { ...newFilter[position], operator: 'ct' };
                              }

                              setValues(newFilter);
                              dispatch(setFilterEditFormCategories(newFilter));
                              setErrors({});
                         };

                         const handleChangeValueFilter = (e, index, setValues) => {
                              const { name, value } = e.target;
                              const newFilter = [...values];
                              newFilter[index] = { ...newFilter[index], [name]: value };
                              setValues(newFilter);
                              dispatch(setFilterEditFormCategories(newFilter));
                         };

                         const handleChangeCategory = (e, index, setValues) => {
                              const { value } = e;
                              const newFilter = [...values];
                              newFilter[index] = { ...newFilter[index], value: value };
                              setValues(newFilter);
                              dispatch(setFilterEditFormCategories(newFilter));
                         };

                         const handleDeleteFilter = (index, setValues) => {
                              setTimeout(() => {
                                   const newFilter = [...values];
                                   newFilter.splice(index, 1);
                                   setValues(newFilter);
                                   dispatch(setFilterEditFormCategories(newFilter));
                                   setErrors({});
                              }, 100);
                         };

                         const handleResetAll = () => {
                              setValues(initialValue);
                              setErrors({});
                              dispatch(setFilterFormCategories([]));
                              dispatch(setFilterEditFormCategories(initialValue));

                              if (filterFormCategories && filterFormCategories.length > 0 && values.some((item) => item.type)) {
                                   dispatch(handleFetchCountFormsInventory(null, activeAccount));
                                   dispatch(handleFetchFormsInventory(20));
                              }
                         };

                         const getOperatorOptions = (item) => {
                              let newOperatorOptions = [];

                              if (item.type === 'categoryId') {
                                   newOperatorOptions = operatorOptions.filter(
                                        (option) => option.value === 'eq' || option.value === 'neq' || option.value === 'nex',
                                   );
                              } else if (item.type !== 'categoryId' && item.type !== 'name') {
                                   newOperatorOptions = operatorOptions.filter((option) => option.value !== 'nex');
                              } else {
                                   newOperatorOptions = [...operatorOptions];
                              }

                              return newOperatorOptions;
                         };

                         return (
                              <>
                                   <CForm onSubmit={handleSubmit} noValidate>
                                        <div className="d-flex align-items-center justify-content-end">
                                             <CButton onClick={handleResetAll}>
                                                  <CLabel>clear all</CLabel>
                                                  <CIcon icon="iconDeleteCircle" />
                                             </CButton>
                                             <CButton color="primary" onClick={handleSubmit} type="submit" className="filter-button">
                                                  <CLabel>Apply</CLabel>
                                             </CButton>
                                        </div>
                                        {values.map((item, index) => {
                                             const typeValid = get(errors, `[${index}].type`);
                                             return (
                                                  <Fragment key={index}>
                                                       <div className="d-flex align-items-top justify-content-end mt-2">
                                                            <div className="select-filter">
                                                                 <Select
                                                                      classNamePrefix="react-select"
                                                                      name="type"
                                                                      className={`${typeValid ? 'is-invalid' : ''}`}
                                                                      placeholder="Select..."
                                                                      options={filterOptions.filter((option) =>
                                                                           values.every((value) => value.type !== option.value),
                                                                      )}
                                                                      value={
                                                                           filterOptions.find((option) => option.value === item.type) || {
                                                                                value: '',
                                                                                label: 'Select...',
                                                                           }
                                                                      }
                                                                      onChange={(e) => {
                                                                           handleChangeFilter({
                                                                                valueFilter: e,
                                                                                position: index,
                                                                                type: 'type',
                                                                                setValues: setValues,
                                                                                item: item,
                                                                           });
                                                                           setShowTooltip(true);
                                                                      }}
                                                                      onMenuOpen={() => setShowTooltip(true)}
                                                                      onMenuClose={() => setShowTooltip(false)}
                                                                 />
                                                                 {!!typeValid ? (
                                                                      <CInvalidFeedback className="d-block mb-0">{typeValid}</CInvalidFeedback>
                                                                 ) : (
                                                                      ''
                                                                 )}
                                                            </div>
                                                            <div className="select-filter-operator">
                                                                 <Select
                                                                      classNamePrefix="react-select"
                                                                      name="operator"
                                                                      placeholder="Select..."
                                                                      options={getOperatorOptions(item)}
                                                                      value={
                                                                           getOperatorOptions(item).find(
                                                                                (option) => option.value === item.operator,
                                                                           ) || {
                                                                                value: '',
                                                                                label: 'Select...',
                                                                           }
                                                                      }
                                                                      onChange={(e) => {
                                                                           handleChangeFilter({
                                                                                valueFilter: e,
                                                                                position: index,
                                                                                type: 'operator',
                                                                                setValues: setValues,
                                                                                item: item,
                                                                           });
                                                                           setShowTooltip(true);
                                                                      }}
                                                                      onMenuOpen={() => setShowTooltip(true)}
                                                                      onMenuClose={() => setShowTooltip(false)}
                                                                 />
                                                            </div>
                                                            <div className="field-value-search">
                                                                 {item.type === 'categoryId' ? (
                                                                      <Select
                                                                           classNamePrefix="react-select"
                                                                           name="value"
                                                                           options={categoryOptions}
                                                                           value={categoryOptions.find((category) => category.value === item.value)}
                                                                           onChange={(e) => {
                                                                                handleChangeCategory(e, index, setValues);
                                                                                setShowTooltip(true);
                                                                           }}
                                                                           onMenuOpen={() => setShowTooltip(true)}
                                                                           onMenuClose={() => setShowTooltip(false)}
                                                                           isDisabled={item.operator === 'nex'}
                                                                      />
                                                                 ) : (
                                                                      <CInput
                                                                           value={item.value}
                                                                           name="value"
                                                                           onChange={(e) => handleChangeValueFilter(e, index, setValues)}
                                                                           disabled={item.operator === 'nex'}
                                                                      />
                                                                 )}
                                                            </div>
                                                            {values.length > 1 && (
                                                                 <CButton
                                                                      className="button-group field-parameter-button"
                                                                      onClick={() => {
                                                                           handleDeleteFilter(index, setValues);
                                                                      }}
                                                                      style={typeValid ? { marginBottom: '20px' } : {}}
                                                                 >
                                                                      <CIcon icon="iconDeleteField" className="icon-delete" />
                                                                 </CButton>
                                                            )}
                                                       </div>
                                                  </Fragment>
                                             );
                                        })}

                                        {values.length < 5 && (
                                             <CFormGroup className=" d-flex align-items-center justify-content-end mt-1 mb-0">
                                                  <CButton
                                                       className="btn-add-row"
                                                       onClick={() =>
                                                            setTimeout(() => {
                                                                 setValues([...values, { type: '', operator: 'ct', value: '' }]);
                                                                 dispatch(
                                                                      setFilterEditFormCategories([
                                                                           ...values,
                                                                           { type: '', operator: 'ct', value: '' },
                                                                      ]),
                                                                 );
                                                            }, 100)
                                                       }
                                                  >
                                                       <CIcon icon="iconAddField" className="icon-add" />
                                                       <CLabel className="add-row">ADD ROW</CLabel>
                                                  </CButton>
                                             </CFormGroup>
                                        )}
                                   </CForm>
                              </>
                         );
                    }}
               </Formik>
          </div>
     );
};

export default FormFilter;
