import React, { useContext, useState } from 'react';
import { CCard, CCardBody } from '@coreui/react';
import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from './CreateAccount';
import { useCalendlyEventListener, InlineWidget } from 'react-calendly';
import { toast } from 'react-toastify';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';
import { callTokenApi } from '../../../../../apiCaller';
import { API_UPDATE_SCHEDULE_DEMO } from '../../../../../constants';
import { useSelector } from 'react-redux';

const TalkToSales = () => {
     const { setCurrentStep, account, setScheduleUpgrade, scheduleUpgrade, setFirstSchedule, setAccount, setShowThank } = useContext(CreateAccountContext);
     const accountId = account.id
     const [isLoading, setIsLoading] = useState(true)
     const activeAccount = useSelector(state => state.subscriber.activeAccount)
     const isScheduleDemo = ((activeAccount.id === account.id) && activeAccount.isScheduleUpgrade) || scheduleUpgrade;
     useCalendlyEventListener({
          onEventTypeViewed: () => setIsLoading(false),
          onEventScheduled: (e) => {
               if (e.data.event) {
                    callTokenApi(API_UPDATE_SCHEDULE_DEMO, "PUT", { accountId, isScheduleUpgrade: true })
                    setScheduleUpgrade(true)
                    setAccount(preAcc => { return { ...preAcc, scheduleUpgrade: true } })
                    if (isScheduleDemo) {
                         setFirstSchedule(false)
                    }
                    else {
                         setFirstSchedule(true)
                    }
                    setShowThank(true)
                    setCurrentStep(STEPS.talkToSalesThankYou);
                    toast.success("Schedule a Demo Successful");
               }
          },
     });

     const onBackBtnClicked = () => {
          if (isScheduleDemo) {
               setCurrentStep(STEPS.talkToSalesThankYou, CHANGE_STEP_TYPE.subStepNext)
          }
          else { setCurrentStep(STEPS.accountPackage, CHANGE_STEP_TYPE.subStepNext) };
     }

     return (
          <CreateAccountBody onBackBtnClicked={onBackBtnClicked} hideStepProgress={true}>
               <div>
                    <CCard className='account-see-how-it-work'>
                         <CCardBody>
                              <h3>Schedule A Demo</h3>
                              <p className='des'>Looking to upgrade to a Business Plan? Grab a time below for an initial 15-minute discovery call. Then we'll schedule a time to review your custom pricing options</p>
                              {isLoading && <CenterSpinner />}
                              <InlineWidget url='https://calendly.com/listenlayer-rick/schedule-a-demo-custom-plan' styles={{
                                   height: '800px'
                              }} />
                         </CCardBody>
                    </CCard>
               </div>
          </CreateAccountBody>
     );
};

export default TalkToSales;
