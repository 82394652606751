import { SUPPORT_PATH } from "../../constants";

const supportNav = [
    {
        _tag: "CSidebarNavItem",
        name: "Dashboard",
        to: SUPPORT_PATH.DASHBOARD,
        icon: "cil-speedometer",
        badge: {
            color: "info",
            // text: 'NEW',
        },
    },
    {
        _tag: "CSidebarNavItem",
        id: "tickets-nav",
        name: "Tickets",
        to: SUPPORT_PATH.TICKETS,
        icon: "cil-inbox",
    },
    {
        _tag: "CSidebarNavItem",
        name: "Accounts",
        to: SUPPORT_PATH.ACCOUNTS,
        icon: "cil-browser",
    },
    {
        _tag: "CSidebarNavItem",
        name: "Data Request",
        to: SUPPORT_PATH.DATA_REQUEST,
        icon: "cil-browser",
    },
];

export default supportNav;
