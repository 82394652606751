import { CButton, CCard, CCardBody } from '@coreui/react';
import React, { useContext } from 'react';

import { useDispatch } from 'react-redux';
import { setSetupGuideRoadmap, setShowCreateAccountPopup, setShowSetupGuidePopup } from '../../../../../../actions/subscriber';
import successAnimationData from '../../../../../../assets/lottie-files/success.json';
import CommonLottie from '../../../../../general/lottie/CommonLottie';
import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';

const VerificationSuccess = () => {
    const { setCurrentStep, account } = useContext(CreateAccountContext);
    // const user = useSelector(state => state.theme.user);
    // const dataUser = (user && JSON.parse(user.registerUserInfo)) || JSON.parse(stepsData.dataUser)
    const { completedSteps } = account
    const dispatch = useDispatch()
    const isRoadmap = window.localStorage.getItem('isRoadmap' + account.id);
    const goNext = () => {
        if (isRoadmap) {
            dispatch(setShowCreateAccountPopup(false));
            dispatch(setSetupGuideRoadmap(1))
            dispatch(setShowSetupGuidePopup(true));
        } else {
            if (completedSteps.includes(STEPS.chooseDestination) && completedSteps.includes(STEPS.configureMeasurement)) {
                setCurrentStep(STEPS.configuringAccount)
            }
            else {
                setCurrentStep(STEPS.gtmConnection, CHANGE_STEP_TYPE.mainStepNext);
            }
        }
    };
    return (
        <CreateAccountBody>
            <CCard className="account-script success">
                <p className="number-of-step">3 of 3 steps</p>
                <CCardBody>
                    <CommonLottie animationData={successAnimationData} />
                    <h3>Success!</h3>
                    <p>The script appears to be running on your website. make sure it remains in place and fires on all pages.</p>
                    <CButton className="btn-save" onClick={goNext}>NEXT</CButton>
                </CCardBody>
            </CCard>
        </CreateAccountBody>
    );
};

export default VerificationSuccess;
