import React from 'react';
import Select from 'react-select';
import { NoOptionMenuList, NoOptionsMessage } from '../../../../../general/rules';
import { NO_OPTION_MESSAGE_TYPE } from '../../../../../../constants';

const formatOptionLabel = ({ label }) => (
     <div className='option-row'>
          <div>{label}</div>
     </div>
);

const SelectTakeAction = ({
     options,
     className,
     classNamePrefix,
     placeholder = 'Select a category',
     disabled,
     defaultValue,
     handleChangeTakeAction,
     index,
     onMenuOpen,
     onMenuClose,
     handleClickLinkNoOptions,
}) => {

     return options.length === 0 ? (
          <Select
               value={''}
               placeholder={placeholder}
               isSearchable={false}
               noOptionsMessage={() => <NoOptionsMessage type={NO_OPTION_MESSAGE_TYPE.FORM_CATEGORIES} options={options} handleClickLinkAction={handleClickLinkNoOptions} />}
               components={{ MenuList: NoOptionMenuList }}
               className='not-selected'
               classNamePrefix={classNamePrefix}
          />
     ) : (
          <Select
               placeholder={placeholder}
               options={options}
               formatOptionLabel={formatOptionLabel}
               value={defaultValue || null}
               className={className}
               classNamePrefix={classNamePrefix}
               isSearchable={false}
               onChange={(option) => handleChangeTakeAction(option.value, index)}
               onMenuOpen={onMenuOpen}
               onMenuClose={onMenuClose}
               isDisabled={disabled}
          />
     );
};

export default SelectTakeAction;
