import React, { useContext, useEffect } from 'react';
import { CCard, CCardBody, CSpinner } from '@coreui/react';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';
import { DESTINATION_CODE, SWITCH_TYPE_WIZARD_STEP } from '../../../../../../constants';
import { useSelector } from 'react-redux';

const MigrateMakeChanges = () => {
     const { isGTM, isEither, isServerSide, usingType, setCurrentStep, needConnectToGTM, handleUpdateSwitchType, nothingObjectTurnOn } = useContext(SwitchTypeWizardContext);
     const switchTypeWizard = useSelector((state) => state.subscriber.switchTypeWizard);
     const {sendTo} = switchTypeWizard
     const accountDestination = useSelector((state) => state.subscriber.accountDestination);
     const isGA4 = accountDestination && accountDestination.destinationCode === DESTINATION_CODE.GA4

     const goToNextStep = () => {
          setCurrentStep(SWITCH_TYPE_WIZARD_STEP.migrateSuccess);
     };

     const goToGTMConnection = () => {
          setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmConnection);
     }

     const doSuccess = () => {
          goToNextStep();
     };

     const handleFirstLoad = () => {
          let newInitStep = SWITCH_TYPE_WIZARD_STEP.gtmVerifyChanges;

          if (needConnectToGTM) {
               newInitStep = SWITCH_TYPE_WIZARD_STEP.gtmConnection;

               if (!isGTM && nothingObjectTurnOn) {
                    newInitStep = SWITCH_TYPE_WIZARD_STEP.getStarted;
               }
          }

          const data = {
               usingType,
               initStep: newInitStep,
          };

          if(isGA4 && sendTo) {
               delete data['usingType'];
          }

          setTimeout(() => {
               if(!(isEither && sendTo)) {
                    handleUpdateSwitchType(data, () => doSuccess());
               } else {
                    if(needConnectToGTM && !(!(sendTo === 'gtm') && nothingObjectTurnOn)) {
                         goToGTMConnection()
                    } else {
                         doSuccess()
                    }
               }
          }, 3000);
     };
     useEffect(handleFirstLoad, []); // eslint-disable-line react-hooks/exhaustive-deps

     const getContent = () => {
          let content = ''

          if(isGTM || (isEither && sendTo === 'gtm')) {
               content = 'Client Side' 
          }

          if(isServerSide || (isEither && sendTo === 'server')) {
               content = 'Server Side' 
          }

          if(isEither && sendTo === 'either') {
               content = 'Server Side or Client Site' 
          }

          if(!content){
               content = isGTM ? 'Client Side' : 'Server Side'
          }

          return content
     }

     return (
          <SwitchTypeWizardBody shouldHideBackBtn={true}>
               <CCard className='migrate-make-changes'>
                    <CCardBody>
                         <h3>Making your changes</h3>
                         <p className='des'>We're making changes to your Data Actions so they'll run {getContent()}. </p>
                         <div className='center-spinner text-center m-3'>
                              <CSpinner color='primary' />
                         </div>
                         <span className='foot-note'>Please be patient; this can take a couple of minutes. This screen will change once the process is complete.</span>
                    </CCardBody>
               </CCard>
          </SwitchTypeWizardBody>
     );
};

export default MigrateMakeChanges;
