import React, { isValidElement, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import { CLink, CBadge } from '../index'
import CIcon from '@coreui/icons-react';
import { DropdownContext, iconProps } from './CSidebarNavDropdown';
import { CBadge, CLink } from '@coreui/react';
import { NavLink } from 'react-router-dom';

//component - CoreUI / CSidebarNavItem
const CSidebarNavItem = (props) => {
     const {
          children,
          className,
          //
          innerRef,
          name,
          icon,
          fontIcon,
          badge,
          addLinkClass,
          label,
          color,
          ...rest
     } = props;

     const { isOpen } = useContext(DropdownContext);
     const classes = classNames('sidebar-nav-item', className);

     const linkClasses = classNames(label ? 'sidebar-nav-label' : 'sidebar-nav-link', color && `sidebar-nav-link-${color}`, addLinkClass);

     const routerLinkProps = rest.to && { exact: true, activeClassName: 'c-active' };

     const style = {
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          whiteSpace: 'nowrap',
          transition: 'background .1s, color .1s',
          listStyle: 'none',
     };
     return (
          <li className={classes} ref={innerRef}>
               {children || (
                    <CLink
                         {...(rest.to && { as: NavLink })}
                         className={linkClasses}
                         style={style}
                         {...routerLinkProps}
                         {...rest}
                         tabIndex={isOpen === false ? -1 : 0}
                    >
                         {icon && (isValidElement(icon) ? icon : <CIcon {...iconProps(icon)} style={{ maxHeight: '15px', flexShrink: 0 }} />)}
                         {fontIcon && <i className={`sidebar-nav-icon ${fontIcon}`} />}
                         <span>{name}</span>
                         {badge && (
                              <CBadge style={{ position: 'absolute', right: '18px' }} {...{ ...badge, text: null }}>
                                   {badge.text}
                              </CBadge>
                         )}
                    </CLink>
               )}
          </li>
     );
};

CSidebarNavItem.propTypes = {
     children: PropTypes.node,
     className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
     innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
     icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
     fontIcon: PropTypes.string,
     badge: PropTypes.object,
     addLinkClass: PropTypes.string,
     label: PropTypes.bool,
     name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
     color: PropTypes.string,
};

export default CSidebarNavItem;
