import React, { useContext } from 'react';
import CIcon from '@coreui/icons-react';
import {
     CButton,
     CCard
} from '@coreui/react';
import {
     AccountDormantBody,
     AccountDormantContext,
} from './AccountDormant';

const AccountCanceled = () => {
     const { accounts, handleCreateAccount, handleManagerAccount } = useContext(AccountDormantContext);

     return (
          <AccountDormantBody showBackBtn={accounts.length > 0} onBackBtnClicked={handleManagerAccount}>
               <CCard className="cancel-account-dormant">
                    <div className="confirm-save-icon"><CIcon icon="icon-delete" className="text-danger" height={38} alt="CoreUI Icons Delete" /></div>
                    <h2>Account Canceled</h2>
                    <div className="confirm-save-content">
                         <p>This account has been successfully canceled and removed. You can now create a new account or go back to manage your other accouns.</p>
                    </div>
                    <div className="button-group">
                         <CButton className="btn-light" onClick={handleCreateAccount}>CREATE NEW ACCOUNT</CButton>
                         {accounts.length > 0 && (
                              <CButton className="btn-success" onClick={handleManagerAccount}>MANAGE ACCOUNTS</CButton>
                         )}
                    </div>
               </CCard>
          </AccountDormantBody>
     );
};

export default AccountCanceled;
