import React from 'react';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';

const CreateNewPopulateField = React.lazy(() => import('./CreateNewPopulateField'));
const EditPopulateField = React.lazy(() => import('./EditPopulateField'));

const PopulateField = ({ id }) => {
     return (
          <div className='rule-detail'>
               <React.Suspense fallback={<CenterSpinner />}>{id ? <EditPopulateField id={id} /> : <CreateNewPopulateField />}</React.Suspense>
          </div>
     );
};

export default PopulateField;
