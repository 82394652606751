import React, { useEffect } from 'react';
import { handleFetchFormsIgnored, handleFetchFormsInventory, setSubscriberState } from '../../../../../../actions/subscriber';
import { useDispatch, useSelector } from 'react-redux';
import FormInventoryLayout from './FormInventoryLayout';
import FormInventoryIgnoredLayout from './FormInventoryIgnoredLayout';

const AllRowFormInventory = () => {
     const dispatch = useDispatch();
     const allFormsInventory = useSelector((state) => state.subscriber.allFormsInventory);
     const showIgnoredInventoryForm = useSelector((state) => state.subscriber.showIgnoredInventoryForm);
     const allFormsIgnored = useSelector((state) => state.subscriber.allFormsIgnored);
     const inventoryNumberPerpage = useSelector((state) => state.subscriber.inventoryNumberPerpage);
     const ignoredNumberPerpage = useSelector((state) => state.subscriber.ignoredNumberPerpage);
     const filterFormCategories = useSelector((state) => state.subscriber.filterFormCategories);
     const itemsPerPage = 20;

     const onLoadMoreIgnored = (numberPage) => {
          dispatch(handleFetchFormsIgnored(itemsPerPage, true, numberPage));
     };

     const onLoadMoreInventory = (numberPage) => {
          dispatch(handleFetchFormsInventory(itemsPerPage, true, numberPage, filterFormCategories));
     };

     const fetchData = () => {
          if (!allFormsInventory) {
               dispatch(handleFetchFormsInventory(itemsPerPage, false, 0));
          }
          if (!allFormsIgnored) {
               dispatch(handleFetchFormsIgnored(itemsPerPage, false, 0));
          }
     };

     useEffect(fetchData, [showIgnoredInventoryForm]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (allFormsInventory && allFormsInventory.length === 0) {
               let newNumberPage = 0;

               if (inventoryNumberPerpage > 0) {
                    newNumberPage = inventoryNumberPerpage - 1;
               }

               if (filterFormCategories && filterFormCategories.length === 0) {
                    dispatch(handleFetchFormsInventory(20, true, newNumberPage));
               }

               if (inventoryNumberPerpage === 1) {
                    dispatch(setSubscriberState({ inventoryShowPaging: false }));
               }
          }

          if (allFormsIgnored && allFormsIgnored.length === 0) {
               let newNumberPage = 0;

               if (ignoredNumberPerpage > 0) {
                    newNumberPage = ignoredNumberPerpage - 1;
               }
               dispatch(handleFetchFormsIgnored(20, true, newNumberPage));

               if (ignoredNumberPerpage === 1) {
                    dispatch(setSubscriberState({ ignoredShowPaging: false }));
               }
          }
     }, [allFormsInventory, allFormsIgnored]); // eslint-disable-line react-hooks/exhaustive-deps

     return (
          <>
               <div className='form-inventory-header mb-3'>
                    <p className='mb-0 subtitle'>Manage Form Categories:</p>
                    <h1>Form Inventory</h1>
                    <p>View and easily categorize all of the forms on your website.</p>
               </div>

               {showIgnoredInventoryForm ? (
                    <FormInventoryIgnoredLayout
                         className='viewAllInventory'
                         formIgnored={allFormsIgnored}
                         tableClassName='table-inventory-all mt-2'
                         onLoadMore={onLoadMoreIgnored}
                    />
               ) : (
                    <FormInventoryLayout
                         className='viewAllInventory'
                         formsInventory={allFormsInventory}
                         tableClassName='table-inventory-all mt-2'
                         onLoadMore={onLoadMoreInventory}
                    />
               )}
          </>
     );
};

export default AllRowFormInventory;
