import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'; // useSelector & useDispatch can only be used in functional component

import { AsideContext } from '../TheAside';
import { roundTo2DecimalPlaces } from '../../../../utils';
import { useIsLatestPlan } from '../../../../helpers/customHooks';
import LegacyPlanRecommendation from './LegacyPlanRecommendation';
import { LIMITATION_TYPE } from '../../../../constants';

const OtherTypeOfLimitationHit = () => {
     const { packageSelected, setPackageSelected } = useContext(AsideContext);
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const packages = useSelector(state => state.subscriber.packages);
     const limitationType = useSelector(state => state.subscriber.limitationType);
     const nextPackageIndex = useSelector(state => state.subscriber.nextPackageIndex);
     const nextPackage = packages[nextPackageIndex];
     const isLatestPlan = useIsLatestPlan();

     let currentMaxText = '';
     let recommendationMaxText = '';

     const {
          packageNumberOfListeners: currentMaxListeners,
          packageNumberOfPremium: currentMaxPremium,
          packageNumberOfDomains: currentMaxDomains,
          packageNumberOfCusDatalayerVariables: currentMaxCustomVariables,
          packageNumberOfCusVariablesRules: currentMaxCustomVariableRules,
          packageNumberOfCusBrowserVariables: currentMaxBrowserVariables,
          packageNumberOfCusBrowserRules: currentMaxBrowserVariableRules,
          packageNumberOfUsers: currentMaxUsers
     } = activeAccount;

     const {
          numberOfListeners: recommendationMaxListeners,
          numberOfPremium: recommendationMaxPremium,
          numberOfDomains: recommendationMaxDomains,
          numberOfCusDatalayerVariables: recommendationMaxCustomVariables,
          numberOfCusVariablesRules: recommendationMaxCustomVariableRules,
          numberOfCusBrowserVariables: recommendationMaxBrowserVariables,
          numberOfCusBrowserRules: recommendationMaxBrowserVariableRules,
          numberOfUsers: recommendationMaxUsers,
          friendlyName: recommendationFriendlyName,
          pricePerYear: recommendationPricePerYear,
     } = packageSelected || nextPackage;

     // Find another recommendation package if the current package's number of something === the next package's number of something
     // E.g: type Listener, currentMaxListeners === recommendationMaxListeners, find another package to recommend it to user
     const findRecommendationPackage = () => {
          let recommendationPackage;

          switch (limitationType) {
               case LIMITATION_TYPE.LISTENER:
                    if (currentMaxListeners === recommendationMaxListeners) {
                         recommendationPackage = packages.find(el => (el.numberOfListeners === -1 || el.numberOfListeners > currentMaxListeners));
                    }
                    break;
               case LIMITATION_TYPE.PREMIUM:
                    if (currentMaxPremium === recommendationMaxPremium) {
                         recommendationPackage = packages.find(el => (el.numberOfPremium === -1 || el.numberOfPremium > currentMaxPremium));
                    }
                    break;
               case LIMITATION_TYPE.DOMAIN:
                    if (currentMaxDomains === recommendationMaxDomains) {
                         recommendationPackage = packages.find(el => (el.numberOfDomains === -1 || el.numberOfDomains > currentMaxDomains));
                    }
                    break;
               case LIMITATION_TYPE.CUSTOM_DATALAYER_VARIABLE:
                    if (currentMaxCustomVariables === recommendationMaxCustomVariables) {
                         recommendationPackage = packages.find(el => (el.numberOfCusDatalayerVariables === -1 || el.numberOfCusDatalayerVariables > currentMaxCustomVariables));
                    }
                    break;
               case LIMITATION_TYPE.BROWSER_CUSTOM_VARIABLE:
                    if (currentMaxBrowserVariables === recommendationMaxBrowserVariables) {
                         recommendationPackage = packages.find(el => (el.numberOfCusBrowserVariables === -1 || el.numberOfCusBrowserVariables > currentMaxBrowserVariables));
                    }
                    break;
               case LIMITATION_TYPE.CUSTOM_VARIABLE_RULE:
                    if (currentMaxCustomVariableRules === recommendationMaxCustomVariableRules) {
                         recommendationPackage = packages.find(el => (el.numberOfCusVariablesRules === -1 || el.numberOfCusVariablesRules > currentMaxCustomVariableRules));
                    }
                    break;
               case LIMITATION_TYPE.BROWSER_RULE:
                    if (currentMaxBrowserVariableRules === recommendationMaxBrowserVariableRules) {
                         recommendationPackage = packages.find(el => (el.numberOfCusBrowserRules === -1 || el.numberOfCusBrowserRules > currentMaxBrowserVariableRules));
                    }
                    break;
               case LIMITATION_TYPE.USER:
                    if (currentMaxUsers === recommendationMaxUsers) {
                         recommendationPackage = packages.find(el => (el.numberOfUsers === -1 || el.numberOfUsers > currentMaxUsers));
                    }
                    break;
               default:
          }

          if (recommendationPackage) {
               setPackageSelected(recommendationPackage);
          }
     }

     useEffect(findRecommendationPackage, [limitationType]); // eslint-disable-line react-hooks/exhaustive-deps

     const getType = (maxNumber) => {
          const isSingular = (maxNumber === 0 || maxNumber === 1);

          switch (limitationType) {
               case LIMITATION_TYPE.LISTENER:
                    return isSingular ? 'Listener' : 'Listeners';
               case LIMITATION_TYPE.PREMIUM:
                    return isSingular ? 'Premium Listener & Feature' : 'Premium Listeners & Features';
               case LIMITATION_TYPE.DOMAIN:
                    return isSingular ? 'Website Domain' : 'Website Domains';
               case LIMITATION_TYPE.CUSTOM_DATALAYER_VARIABLE:
                    return isSingular ? 'Custom Data Layer Variable' : 'Custom Data Layer Variables';
               case LIMITATION_TYPE.BROWSER_CUSTOM_VARIABLE:
                    return isSingular ? 'Browser Storage Variable' : 'Browser Storage Variables';
               case LIMITATION_TYPE.CUSTOM_VARIABLE_RULE:
                    return isSingular ? 'Custom Variable Rule' : 'Custom Variable Rules';
               case LIMITATION_TYPE.BROWSER_RULE:
                    return isSingular ? 'Browser Variable Rule' : 'Browser Variable Rules';
               case LIMITATION_TYPE.USER:
                    return isSingular ? 'User' : 'Users';
               default:
                    return '';
          }
     }

     switch (limitationType) {
          case LIMITATION_TYPE.LISTENER:
               currentMaxText = `${currentMaxListeners} ${getType(currentMaxListeners)}`;
               recommendationMaxText = (recommendationMaxListeners === -1) ? 'unlimited' : `${recommendationMaxListeners} ${getType(recommendationMaxListeners)}`;
               break;
          case LIMITATION_TYPE.PREMIUM:
               currentMaxText = `${currentMaxPremium} ${getType(currentMaxPremium)}`;
               recommendationMaxText = (recommendationMaxPremium === -1) ? 'unlimited' : `${recommendationMaxPremium} ${getType(recommendationMaxPremium)}`;
               break;
          case LIMITATION_TYPE.DOMAIN:
               currentMaxText = `${currentMaxDomains} ${getType(currentMaxDomains)}`;
               recommendationMaxText = (recommendationMaxDomains === -1) ? 'unlimited' : `${recommendationMaxDomains} ${getType(recommendationMaxDomains)}`;
               break;
          case LIMITATION_TYPE.CUSTOM_DATALAYER_VARIABLE:
               currentMaxText = `${currentMaxCustomVariables} ${getType(currentMaxCustomVariables)}`;
               recommendationMaxText = (recommendationMaxCustomVariables === -1) ? 'unlimited' : `${recommendationMaxCustomVariables} ${getType(recommendationMaxCustomVariables)}`;
               break;
          case LIMITATION_TYPE.BROWSER_CUSTOM_VARIABLE:
               currentMaxText = `${currentMaxBrowserVariables} ${getType(currentMaxBrowserVariables)}`;
               recommendationMaxText = (recommendationMaxBrowserVariables === -1) ? 'unlimited' : `${recommendationMaxBrowserVariables} ${getType(recommendationMaxBrowserVariables)}`;
               break;
          case LIMITATION_TYPE.CUSTOM_VARIABLE_RULE:
               currentMaxText = `${currentMaxCustomVariableRules} ${getType(currentMaxCustomVariableRules)}`;
               recommendationMaxText = (recommendationMaxCustomVariableRules === -1) ? 'unlimited' : `${recommendationMaxCustomVariableRules} ${getType(recommendationMaxCustomVariableRules)}`;
               break;
          case LIMITATION_TYPE.BROWSER_RULE:
               currentMaxText = `${currentMaxBrowserVariableRules} ${getType(currentMaxBrowserVariableRules)}`;
               recommendationMaxText = (recommendationMaxBrowserVariableRules === -1) ? 'unlimited' : `${recommendationMaxBrowserVariableRules} ${getType(recommendationMaxBrowserVariableRules)}`;
               break;
          case LIMITATION_TYPE.USER:
               currentMaxText = `${currentMaxUsers} ${getType(currentMaxUsers)}`;
               recommendationMaxText = (recommendationMaxUsers === -1) ? 'unlimited' : `${recommendationMaxUsers} ${getType(recommendationMaxUsers)}`;
               break;
          default:
     }

     return (
          <>
               <div className="description">
                    <p className="text-center fw-4">
                         You've hit a limitation of your current account level ({activeAccount.packageFriendlyName}).
                    </p>
               </div>
               <div className="sidebar-body">
                    <h6 className="text-danger text-center limitation">Limitation Hit</h6>
                    <p className="text-center limitation-text fw-4">
                         You've hit your limit of{" "}
                         <span className="underline">
                              {currentMaxText}
                         </span>
                         {" "}try to use more
                    </p>
                    <h6 className="text-success text-center recommendation">Recommendation</h6>
                    {
                         isLatestPlan ? (
                              <p className="text-center recommendation-text fw-4">
                                   Upgrade to the {recommendationFriendlyName} plan for ${roundTo2DecimalPlaces(recommendationPricePerYear / 12).toLocaleString('en-US')}/month{" "}{'('}paid annually{')'}{" "}
                                   and you'll have access to <span className="underline">{recommendationMaxText}</span> and a number of other features.
                              </p>
                         ) : <LegacyPlanRecommendation />
                    }
               </div>
          </>
     )
}

export default OtherTypeOfLimitationHit;