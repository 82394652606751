import React from 'react';
import { formatNumber } from '../../../../../../utils/UtilReport';

export const CustomFooterCellDimensions = ({props, filterDimensions, filterProperties}) => {
     if (props.ariaColumnIndex === filterDimensions.length + filterProperties.length) {
          return (
               <>
                    <td
                         className={props.className}
                         style={{
                              ...props.style,
                              textAlign: 'right',
                              borderLeft: 'none',
                         }}
                    >
                         Totals
                    </td>
               </>
          );
     } else {
          return <td className={props.className} style={{ ...props.style, borderLeft: 'none' }}></td>;
     }
};

export const CustomFooterCellProperties = ({props, filterDimensions, filterProperties}) => {
    if (props.ariaColumnIndex === filterProperties.length + filterDimensions.length) {
         return (
              <td className={props.className} style={{ ...props.style, textAlign: 'right', borderLeft: 'none' }}>
                   Totals
              </td>
         );
    } else {
         return <td className={props.className} style={{ ...props.style, borderLeft: 'none' }}></td>;
    }
};

const getTotalCell = (field, reportData) => {
    if (reportData.totalsRow && reportData.totalsRow.length > 0) {
         const totalCell = reportData.totalsRow[0].metricCompoundValues.find((item) => item.key === field) || {};
         const value = totalCell && totalCell.value ? (isFinite(totalCell.value) ? Number(totalCell.value) : totalCell.value) : 0;
         if (totalCell) {
              if (typeof totalCell.value !== 'number' && !isFinite(totalCell.value)) {
                   return totalCell.value;
              } else if (field.includes('Rate')) {
                   return `${formatNumber(totalCell.value)}%`;
              } else {
                   return formatNumber(value);
              }
         }
    }
};

export const CustomFooterCellDefault = ({props, reportData}) => {
    const totalCell = getTotalCell(props.field, reportData);
    return (
         <td className={props.className} style={props.style} width={props.width}>
              {totalCell || totalCell === 0 ? totalCell : ''}
         </td>
    );
};