import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CIcon from '@coreui/icons-react';
import { CButton } from '@coreui/react';
import SupportTicketPopup from './SupportTicketPopup';
import DecisionStep1 from './Decision/DecisionStep1';
import DecisionStep2 from './Decision/DecisionStep2';
import DecisionStep3 from './Decision/DecisionStep3';
import StrategyRequest from './Decision/StrategyRequest';
import GettingListenLayer1 from './GettingListenLayer/GettingListenLayer1';
import GettingListenLayer2 from './GettingListenLayer/GettingListenLayer2';
import GettingListenLayer3 from './GettingListenLayer/GettingListenLayer3';
import IssueListener1 from './IssueListener/IssueListener1';
import IssueListener2 from './IssueListener/IssueListener2';
import IssueListener3 from './IssueListener/IssueListener3';
import IssueListener4 from './IssueListener/IssueListener4';
import IssueAccount1 from './IssueAccount/IssueAccount1';
import IssueAccount2 from './IssueAccount/IssueAccount2';
import IssueAccount3 from './IssueAccount/IssueAccount3';
import HelpDataLayers from './HelpDataLayers/HelpDataLayers';
import CreateSupportTicket from './CreateSupportTicket/CreateSupportTicket';
import TicketSuccessfullyCreate from './CreateSupportTicket/TicketSuccessfullyCreate';

import { toastError } from '../../../../utils';
import { callTokenApi } from '../../../../apiCaller';
import { setListenersInTicket, setShowGetHelpLabel, setTicketInit } from '../../../../actions/subscriber';
import { API_GET_DATA_LISTENERS_FEATURES } from '../../../../constants';
import { setFlexibleModal, setUnsavedChanges } from '../../../../actions/common';

export const SupportTicketPopupContext = React.createContext({});

export const DECISION_STEPS = {
     step1: 'Decision1',
     step2: 'Decision2',
     step3: 'Decision3',
     strategyRequest: 'StrategyRequest',
};

//Go 1
export const GETTING_LISTEN_LAYER = {
     step1: 'GettingListenLayer1',
     step2: 'GettingListenLayer2',
     step3: 'GettingListenLayer3',
};

//Go 2
export const ISSUE_LISTENER = {
     step1: 'IssueListener1',
     step2: 'IssueListener2',
     step3: 'IssueListener3',
     step4: 'IssueListener4',
};

//Go 3
export const ISSUE_ACCOUNT = {
     step1: 'IssueAccount1',
     step2: 'IssueAccount2',
     step3: 'IssueAccount3',
};

//Go 4
export const HELP_DATA_LAYERS = {
     step1: 'HelpDataLayers',
};

//CreateSupportTicket
export const CREATE_SUPPORT_TICKET = {
     step1: 'CreateSupportTicket',
     step2: 'TicketSuccessfullyCreated',
};

const GetHelpLabel = ({ color, iconName }) => {
     const dispatch = useDispatch();
     const edittingRule = useSelector((state) => state.theme.edittingRule);
     const { ticketStep } = useSelector((state) => state.subscriber.ticketInit);
     const showUnsavedChange = useSelector((state) => state.theme.showUnsavedChange);
     const listenerInTickets = useSelector((state) => state.subscriber.listenerInTickets);
     const showGetHelpLabel = useSelector((state) => state.subscriber.showGetHelpLabel);
     const showCreateAccountPopup = useSelector((state) => state.subscriber.showCreateAccountPopup);
     const showAccountDormantPopup = useSelector((state) => state.subscriber.showAccountDormantPopup);
     const isCustomerSupportLoginAs = useSelector((state) => state.subscriber.isCustomerSupportLoginAs);
     const flexibleModal = useSelector((state) => state.theme.flexibleModal);

     const showTicketPopup = !showUnsavedChange && !edittingRule.show && showGetHelpLabel;

     const [ticket, setTicket] = useState({});
     const [ticketType, setTicketType] = useState('');
     const [dataShowVideo, setDataShowVideo] = useState({
          listenerName: '',
          featureName: '',
          videoEmbed: '',
     });
     const [currentStep, setCurrentStep] = useState(DECISION_STEPS.step1);
     const [listenerFeature, setListenerFeature] = useState({
          listenerId: '',
          featureId: '',
     });

     const fetchTicketStep = () => {
          if (ticketStep) {
               setCurrentStep(ticketStep);
          }
     };
     useEffect(fetchTicketStep, [ticketStep]);

     useEffect(() => {
          if (showTicketPopup && flexibleModal.component !== 'form-requiring-action' && flexibleModal.component !== 'form-inventory') {
               dispatch(setFlexibleModal({ show: false, showLv2: false, component: '' }));
          }
     }, [showTicketPopup]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          let modalEl, backdropEl;
          const clickOutside = (e, backdropEl) => {
               if (backdropEl && !backdropEl.contains(e.target)) {
                    dispatch(setShowGetHelpLabel(false));
               }
          };
          const idTimeOut = setTimeout(() => {
               modalEl = document.querySelector('.support-ticket-popup .modal');
               backdropEl = document.querySelector('.support-ticket-popup .modal .modal-content');
               if (modalEl) {
                    modalEl.addEventListener('click', (e) => clickOutside(e, backdropEl));
               }
          }, 100);
          
          return () => {
               if (modalEl) {
                    modalEl.removeEventListener('click', clickOutside);
               }
               clearTimeout(idTimeOut)
          };
     }, [currentStep, showTicketPopup]); // eslint-disable-line react-hooks/exhaustive-deps

     const getDecisionComponent = () => {
          switch (currentStep) {
               case DECISION_STEPS.step1:
                    return <DecisionStep1 />;
               case DECISION_STEPS.step2:
                    return <DecisionStep2 />;
               case DECISION_STEPS.step3:
                    return <DecisionStep3 />;
               case DECISION_STEPS.strategyRequest:
                    return <StrategyRequest />;
               case GETTING_LISTEN_LAYER.step1:
                    return <GettingListenLayer1 />;
               case GETTING_LISTEN_LAYER.step2:
                    return <GettingListenLayer2 />;
               case GETTING_LISTEN_LAYER.step3:
                    return <GettingListenLayer3 />;
               case ISSUE_LISTENER.step1:
                    return <IssueListener1 />;
               case ISSUE_LISTENER.step2:
                    return <IssueListener2 />;
               case ISSUE_LISTENER.step3:
                    return <IssueListener3 />;
               case ISSUE_LISTENER.step4:
                    return <IssueListener4 />;
               case ISSUE_ACCOUNT.step1:
                    return <IssueAccount1 />;
               case ISSUE_ACCOUNT.step2:
                    return <IssueAccount2 />;
               case ISSUE_ACCOUNT.step3:
                    return <IssueAccount3 />;
               case HELP_DATA_LAYERS.step1:
                    return <HelpDataLayers />;
               case CREATE_SUPPORT_TICKET.step1:
                    return <CreateSupportTicket />;
               case CREATE_SUPPORT_TICKET.step2:
                    return <TicketSuccessfullyCreate />;
               default:
                    return <></>;
          }
     };

     const openTicketPopup = () => {
          if (edittingRule.show) {
               dispatch(setUnsavedChanges(true));
          }

          if (Object.keys(listenerInTickets).length === 0) {
               callTokenApi(API_GET_DATA_LISTENERS_FEATURES, 'GET').then((response) => {
                    if (response.status === 200) {
                         const { listeners, listenerFeatures } = response.data;
                         dispatch(setListenersInTicket({ listeners, listenerFeatures }));
                    } else {
                         toastError(response);
                    }
               });
          }

          // Show support ticket when sign up
          if (showCreateAccountPopup) {
               setCurrentStep(CREATE_SUPPORT_TICKET.step1);
          }
          dispatch(setShowGetHelpLabel(true));
     };

     const closePopup = () => {
          dispatch(setShowGetHelpLabel(false));
          setTimeout(() => {
               resetTicket();
          }, 200);
     };

     const resetTicket = () => {
          if (ticketStep) {
               setCurrentStep(DECISION_STEPS.step1);
               dispatch(setTicketInit({ ticketStep: null, ticketType: null, hideListenerSelect: false }));
          }
     };

     return (
          <>
               {!showAccountDormantPopup && !isCustomerSupportLoginAs && (
                    <div className={`get-help ${color ? color : 'yellow'}`} onClick={openTicketPopup}>
                         <CIcon icon={`${iconName ? iconName : 'icon-boltAlt'}`} height={14} />
                         <CButton>GET HELP</CButton>
                    </div>
               )}
               <SupportTicketPopupContext.Provider
                    value={{
                         currentStep,
                         setCurrentStep,
                         ticket,
                         setTicket,
                         ticketType,
                         setTicketType,
                         listenerFeature,
                         setListenerFeature,
                         dataShowVideo,
                         setDataShowVideo,
                         resetTicket,
                         closePopup,
                    }}
               >
                    <SupportTicketPopup show={showTicketPopup} onClose={closePopup}>
                         {getDecisionComponent()}
                    </SupportTicketPopup>
               </SupportTicketPopupContext.Provider>
          </>
     );
};

export default GetHelpLabel;
