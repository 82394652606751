import React, { useState } from 'react';

const DotsLoadingListenlayer = ({ className = '', show = true, style={} }) => {
     const [dotAnimationComplete, setDotAnimationComplete] = useState(show);

     const handleEndAnimation = () => {
          setTimeout(() => {
               setDotAnimationComplete(false);
          }, 300);
     };
     if (!dotAnimationComplete) return <></>;

     return (
          <div className={`dots-animation dots-listenlayer ${className}`} onAnimationEnd={handleEndAnimation} style={style}>
               <div className='dots-listenlayer-1'></div>
               <div className='dots-listenlayer-2'></div>
               <div className='dots-listenlayer-3'></div>
          </div>
     );
};

export default DotsLoadingListenlayer;
