import React, { useContext } from 'react';
import { CButton, CCard, CCardBody } from '@coreui/react';
import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';

const CannotVerifyScript = () => {
    const { setCurrentStep } = useContext(CreateAccountContext);

    const goNext = () => {
        setCurrentStep(STEPS.gtmConnection, CHANGE_STEP_TYPE.mainStepNext);
    };

    return (
        <CreateAccountBody>
            <CCard className="account-script cannot-verify">
                <p className="number-of-step">3 of 3 steps</p>
                <CCardBody>
                    <h3>We can’t verify your script</h3>
                    <p>
                        Since we aren't able to publish your GTM container, we can't verify your script right now. While you work on getting it published,
                        you can explore your account.
                    </p>
                    <CButton className="btn-save" onClick={goNext}>
                        LET’S GO
                    </CButton>
                </CCardBody>
            </CCard>
        </CreateAccountBody>
    );
};

export default CannotVerifyScript;
