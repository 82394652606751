import React, { useContext, useEffect } from 'react';
import { CCard, CCardBody } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import { callTokenApi } from '../../../../../../apiCaller';
import { API_CLIENT_DESTINATIONS_GTM_CHANGES, GTM_WIZARD_TYPE, SWITCH_TYPE_WIZARD_STEP } from '../../../../../../constants';
import { useCancelToken, useHasPublishGTMPermission } from '../../../../../../helpers/customHooks';
import CommonLottie from '../../../../../general/lottie/CommonLottie';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';
import { reloadConversionRules, reloadDataActions, reloadTriggerRules } from '../../../../../../actions/subscriber';
import { setSubscriberState } from '../../../../../../actions/admin';
import llConnectToGTMAnimationData from '../../../../../../assets/lottie-files/ll-connect-to-gtm.json';


const EachMakeChanges = () => {
     const dispatch = useDispatch();
     const { setCurrentStep, stepsData, setStepsData } =
          useContext(SwitchTypeWizardContext);

     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const { currentContainer } = activeAccount.gtmInfo;
     const { gtmHasUnpublishedChanges, workspaceName, workspacePath, doPublish, gtmHasLLWorkspace, skipRemove  } = stepsData;

     const switchTypeWizard = useSelector((state) => state.subscriber.switchTypeWizard);

     const hasPublishPermission = useHasPublishGTMPermission();

     const getCancelToken = useCancelToken();

     const handleSuccess = async () => {
          switch (switchTypeWizard.data.type) {
               case GTM_WIZARD_TYPE.TRIGGER:
                    dispatch(reloadTriggerRules());
                    break;
               case GTM_WIZARD_TYPE.TRIGGER_CONVERSION:
               // case GTM_WIZARD_TYPE.DESTINATION_CONVERSION:
                    dispatch(reloadConversionRules());
                    break;
               case GTM_WIZARD_TYPE.DATA_ACTION:
                    // Reload data actions to make them update with the new GTM status
                    dispatch(reloadDataActions());
                    break;
               default:
          }

          if (typeof switchTypeWizard.data.doNextSwitch === 'function') {
               switchTypeWizard.data.doNextSwitch();
          }

          if (!hasPublishPermission && doPublish) {
               setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmNoPublishingRights);
               return;
          }

          setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmSuccess);

     }

     const handleGeneralActions = () => {
          let postData = {
               accountId: activeAccount.id,
               container: {
                    containerId: currentContainer.containerId,
                    path: currentContainer.path,
                    publicId: currentContainer.publicId
               },
               name: switchTypeWizard.data.name,
               type: switchTypeWizard.data.type,
               action: 'Make Changes',
               gtmHasUnpublishedChanges,
               workspaceName,
               workspacePath,
               hasPublishPermission,
               doPublish,
               isUpdating: switchTypeWizard.data.isUpdating,
               gtmHasLLWorkspace,
               skipRemove
          };
          switch (switchTypeWizard.data.type) {
               case GTM_WIZARD_TYPE.VARIABLE:
                    postData.variableListenerId = switchTypeWizard.data.listenerId;
                    break;
               case GTM_WIZARD_TYPE.TRIGGER:
               case GTM_WIZARD_TYPE.TRIGGER_CONVERSION:
               case GTM_WIZARD_TYPE.DATA_ACTION:
                    if (switchTypeWizard.data.isLookupChild) {
                         postData.ruleId = switchTypeWizard.data.ruleId;
                         postData.isLookupChild = switchTypeWizard.data.isLookupChild;
                         postData.lookupChildId = switchTypeWizard.data.id;
                    } else {
                         postData.ruleId = switchTypeWizard.data.id;
                    }

                    break;
               case GTM_WIZARD_TYPE.DESTINATION_CONVERSION:
                    postData.destinationConversion = switchTypeWizard.data;
                    break;
               case GTM_WIZARD_TYPE.DESTINATION_ECOMMERCE:
                    postData.destinationConversion = switchTypeWizard.data;
                    break;
               default:
          }

          callTokenApi(API_CLIENT_DESTINATIONS_GTM_CHANGES, 'POST', postData, getCancelToken())
               .then(response => {
                    if (response) {
                         dispatch(setSubscriberState({ gtmVariablesInLL: null, gtmTriggersInLL: null, gtmTagsInLL: null, gtmChangelog: null }));

                         if (response.status === 200) {
                              handleSuccess();
                         } else {
                              if (response.data.isRunOutOfWorkspace) {
                                   setStepsData({ isRunOutOfWorkspace: true });
                              }
     
                              if (response.data.publishFailed) {
                                   handleSuccess();
                                   setStepsData({ publishFailed: true });
                              }
     
                              if (response.data.duplicateTriggerName) {
                                   setStepsData({ duplicateTriggerName: response.data.duplicateTriggerName });
                              }
                              if (response.data.duplicateTagName) {
                                   setStepsData({ duplicateTagName: response.data.duplicateTagName });
                              }
     
                              setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmUnknownFailure);
                         }
                    }
               })
     };

     const handleFirstLoad = () => {
          handleGeneralActions()
     };

     useEffect(handleFirstLoad, []); // eslint-disable-line

     return (
          <SwitchTypeWizardBody>
               <CCard className='account-gtm making-changes'>
                    <CCardBody>
                         <h3>Making changes in GTM</h3>
                         <CommonLottie animationData={llConnectToGTMAnimationData} />
                         <p>We're working on making these changes; please wait on this screen (it will only take a few seconds).</p>
                    </CCardBody>
               </CCard>
          </SwitchTypeWizardBody>
     );
};

export default EachMakeChanges;