import React, { useContext } from 'react';
import { CCard, CCardBody, CButton } from '@coreui/react';

import { CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
import CommonLottie from '../../../../../general/lottie/CommonLottie';
import successAnimationData from '../../../../../../assets/lottie-files/success.json';
import useSaveCurrentStep from '../useSaveCurrentStep';

const GTMPublishSuccess = () => {
    const { setCurrentStep } = useContext(CreateAccountContext);
    useSaveCurrentStep();

    const goNext = () => {
        setCurrentStep(STEPS.verifyingScript);
    };

    return (
        <CreateAccountBody>
            <CCard className="account-gtm success">
                <p className="number-of-step">2 of 3 steps</p>
                <CCardBody>
                    <CommonLottie animationData={successAnimationData} />
                    <h3>Success!</h3>
                    <p>We’ve published the script on your website.</p>
                    <CButton className="btn-save" onClick={goNext}>
                        Next
                    </CButton>
                </CCardBody>
            </CCard>
        </CreateAccountBody>
    );
};

export default GTMPublishSuccess;
