import React, { useContext } from 'react';
import { CButton, CCard, CCardBody } from '@coreui/react';
import { useSelector } from 'react-redux';

import { CHANGE_STEP_TYPE, GTMWizardBody, GTMWizardContext } from '.';
import { getDLVariableType } from '../../../../../helpers/cms/subscriber';
import { GTM_VARIABLE_IN_LL_TYPE, GTM_WIZARD_STEPS, GTM_WIZARD_TYPE } from '../../../../../constants';
import { CInput } from '../../../../migration/CInput';

const GTMCreateData = () => {
     const { setCurrentStep } = useContext(GTMWizardContext);
     const gtmWizard = useSelector(state => state.subscriber.gtmWizard);
     const { isUpdating, isLookup } = gtmWizard.data;

     const getDisplayData = () => {
          let displayData = {};

          switch (gtmWizard.type) {
               case GTM_WIZARD_TYPE.VARIABLE:
                    displayData = {
                         headline: 'Register a Data Layer Variable inside GTM',
                         subHeadline: 'We\'re going to create a variable inside GTM for:',
                         inputValues: [gtmWizard.data.name],
                         description: 'This is a General Variable, meaning it is not specific to a Listener and may appear in many data layer events.'
                    };

                    if (getDLVariableType(gtmWizard.data.name) === GTM_VARIABLE_IN_LL_TYPE.SPECIFIC_TO_A_LISTENER) {
                         displayData.description = 'This variable is Specific to a type of Listener meaning it may appear across data layer events generated from similar listeners.'
                    }

                    break;
               case GTM_WIZARD_TYPE.TRIGGER:
               case GTM_WIZARD_TYPE.TRIGGER_CONVERSION:
                    displayData = {
                         headline: `${isUpdating ? 'Update' : 'Create'} ${isLookup ? 'Triggers' : 'a Trigger'} inside GTM`,
                         subHeadline: `We're going to ${isUpdating ? 'update' : 'create'} ${isLookup ? 'triggers' : 'a trigger'} inside GTM for the following Trigger inside ListenLayer:`,
                         inputValues: [gtmWizard.data.name],
                         description: 'We may need to create or reorganize other GTM elements to support this trigger.'
                    };

                    if (gtmWizard.type === GTM_WIZARD_TYPE.TRIGGER_CONVERSION) {
                         displayData.subHeadline = displayData.subHeadline.replace('Trigger', 'Conversion');
                    }

                    break;
               case GTM_WIZARD_TYPE.DATA_ACTION:
                    displayData = {
                         headline: `${isUpdating ? 'Update' : 'Create'} a Tag inside GTM`,
                         subHeadline: `We're going to ${isUpdating ? 'update' : 'create'} a tag inside GTM for the following Data Action inside ListenLayer:`,
                         inputValues: [gtmWizard.data.name],
                         description: 'We may need to create or reorganize other GTM elements to support this tag.'
                    };

                    break;
               case GTM_WIZARD_TYPE.DESTINATION_CONVERSION:
                    const conversionNames = gtmWizard.data.conversions.map(el => el.name);

                    displayData = {
                         headline: `Syncing between ListenLayer and GTM`,
                         subHeadline: `We're going to create, update and delete tags inside GTM for the following ${conversionNames.length > 1 ? 'events' : 'the event'} inside ListenLayer:`,
                         inputValues: conversionNames,
                         description: `We may need to create or reorganize other GTM elements to support ${conversionNames.length > 1 ? 'these tags' : 'this tag'}.`
                    };

                    break;
               case GTM_WIZARD_TYPE.DESTINATION_ECOMMERCE:
                    const eventEcommerceName = gtmWizard.data.ecommerceEvent.map(el => el.name);

                    displayData = {
                         headline: `Syncing between ListenLayer and GTM`,
                         subHeadline: `We're going to create, update and delete tags inside GTM for the following ${eventEcommerceName.length > 1 ? 'events' : 'the event'} inside ListenLayer:`,
                         inputValues: eventEcommerceName,
                         description: `We may need to create or reorganize other GTM elements to support ${eventEcommerceName.length > 1 ? 'these tags' : 'this tag'}.`
                    };

                    break;
               default:
          }

          return displayData;
     };

     const displayData = getDisplayData();

     const onBackBtnClicked = () => {
          setCurrentStep(null, CHANGE_STEP_TYPE.PREV);
     };

     const onContinueBtnClicked = () => {
          setCurrentStep(GTM_WIZARD_STEPS.gtmVerifyChanges, CHANGE_STEP_TYPE.NEXT);
     };

     return (
          <GTMWizardBody onBackBtnClicked={onBackBtnClicked}>
               <CCard className="account-domain checking">
                    <CCardBody>
                         <h3>{displayData.headline}</h3>
                         <p className="sub-headline">{displayData.subHeadline}</p>
                         {
                              displayData.inputValues && displayData.inputValues.map(el => (
                                   <CInput key={el} className="mb-3" value={el} readOnly />
                              ))
                         }
                         <p className="mb-0">{displayData.description}</p>
                         <CButton className="btn-save mb-3" onClick={onContinueBtnClicked}>
                              Continue
                         </CButton>
                         <span className="note">
                              You'll have a chance to review all changes before they are pushed to GTM.
                              You can then choose to allow ListenLayer to publish the changes or publish them manually from GTM.
                         </span>
                    </CCardBody>
               </CCard>
          </GTMWizardBody>
     )
};

export default GTMCreateData;