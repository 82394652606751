import { CButton, CCard, CCardHeader, CCol, CRow } from '@coreui/react';
import PropTypes from 'prop-types';
import React from 'react';

const NewGroup = ({ lvl1GroupIndex, selectLvl1Conjunction }) => {
     return (
          <CCard className='mb-3 card-add-group'>
               <CCardHeader>
                    <CRow>
                         <CCol md='5'>
                              <div className='h-100 d-flex align-items-center'>
                                   <h6 className='mb-0'>Add new rule group</h6>
                                   <div className='button-new-rule'>
                                        <CButton
                                             className={`btn-add-or-rule `}
                                             color={'dark'}
                                             onClick={() => selectLvl1Conjunction(lvl1GroupIndex, 'and')}
                                        >
                                             AND
                                        </CButton>
                                        <CButton
                                             className={`btn-add-or-rule`}
                                             color={'dark'}
                                             onClick={() => selectLvl1Conjunction(lvl1GroupIndex, 'or')}
                                        >
                                             OR
                                        </CButton>
                                   </div>
                              </div>
                         </CCol>
                    </CRow>
               </CCardHeader>
          </CCard>
     )
}

NewGroup.propTypes = {
     lvl1GroupIndex: PropTypes.number,
     selectLvl1Conjunction: PropTypes.func
};

export default NewGroup;
