import React, { useContext } from 'react';
import { CButton, CCard, CCardBody } from '@coreui/react';
import { useSelector } from 'react-redux';

import { CHANGE_STEP_TYPE, GTMWizardBody, GTMWizardContext } from '.';
import { GTM_WIZARD_STEPS, GTM_WIZARD_TYPE } from '../../../../../constants';
import { CInput } from '../../../../migration/CInput';

const GTMRemoveData = () => {
     const { setCurrentStep } = useContext(GTMWizardContext);
     const gtmWizard = useSelector(state => state.subscriber.gtmWizard);
     const { isLookup } = gtmWizard.data;

     const getDisplayData = () => {
          let displayData = {};

          switch (gtmWizard.type) {
               case GTM_WIZARD_TYPE.VARIABLE:
                    break;
               case GTM_WIZARD_TYPE.TRIGGER:
               case GTM_WIZARD_TYPE.TRIGGER_CONVERSION:
                    displayData = {
                         headline: `Delete ${isLookup ? 'Triggers' : 'a Trigger'} inside GTM`,
                         subHeadline: `We're going to delete ${isLookup ? 'triggers' : 'a trigger'} inside GTM for the following Trigger inside ListenLayer:`,
                         inputValues: [gtmWizard.data.name]
                    };

                    if (gtmWizard.type === GTM_WIZARD_TYPE.TRIGGER_CONVERSION) {
                         displayData.subHeadline = displayData.subHeadline.replace('Trigger', 'Conversion');
                    }

                    break;
               case GTM_WIZARD_TYPE.DATA_ACTION:
                    displayData = {
                         headline: `Delete a Tag inside GTM`,
                         subHeadline: `We're going to delete a tag inside GTM for the following Data Action inside ListenLayer:`,
                         inputValues: [gtmWizard.data.name],
                    };
                    break;
               case GTM_WIZARD_TYPE.DESTINATION_CONVERSION:
                    break;
               default:
          }

          return displayData;
     };

     const displayData = getDisplayData();

     const onBackBtnClicked = () => {
          setCurrentStep(null, CHANGE_STEP_TYPE.PREV);
     };

     const onContinueBtnClicked = () => {
          setCurrentStep(GTM_WIZARD_STEPS.gtmVerifyChanges, CHANGE_STEP_TYPE.NEXT);
     };

     return (
          <GTMWizardBody onBackBtnClicked={onBackBtnClicked} >
               <CCard className="account-domain checking">
                    <CCardBody>
                         <h3>{displayData.headline}</h3>
                         <p className="sub-headline">{displayData.subHeadline}</p>
                         {
                              displayData.inputValues && displayData.inputValues.map(el => (
                                   <CInput key={el} className="mb-3" value={el} readOnly />
                              ))
                         }
                         <CButton className="btn-save mb-3" onClick={onContinueBtnClicked}>
                              Continue
                         </CButton>
                         <span className="note">
                              You'll have a chance to review all changes before they are pushed to GTM.
                         </span>
                    </CCardBody>
               </CCard>
          </GTMWizardBody>
     )
};

export default GTMRemoveData;