import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormRequiringTableRow from './FormRequiringTableRow';
import { handleFetchChildFormRequiring, setSubscriberState } from '../../../../../../actions/subscriber';
import { CButton, CSpinner } from '@coreui/react';

const MultiFormsRequiring = ({ errors, setErrors, index, formsData, applyChild, className, length, redirectTempFormData }) => {
     const dispatch = useDispatch();
     const [showAccordion, setShowAccordion] = useState(false);
     const [fetched, setFetched] = useState(false);
     const [loading, setLoading] = useState(false);
     const [isLoadingLoadMore, setisLoadingLoadMore] = useState(false);
     const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(true);
     const showIgnoredForm = useSelector((state) => state.subscriber.showIgnoredForm);
     const listChildRequiring = useSelector((state) => state.subscriber[`requiring${formsData.id}`]);
     const listChildIgnored = useSelector((state) => state.subscriber[`ignored${formsData.id}`]);
     const listFormsChild = !showIgnoredForm ? listChildRequiring : listChildIgnored;
     const showFormsChild = !showIgnoredForm ? typeof listChildRequiring !== 'undefined' : typeof listChildIgnored !== 'undefined';
     const formRequiringEdit = useSelector((state) => state.subscriber.formRequiringEdit);
     const formIgnoreEdit = useSelector((state) => state.subscriber.formIgnoreEdit);
     const loadMoreChild = useSelector((state) => state.subscriber.formChildId);
     const itemsPerPage = 12;
     const loadingFormIgnore = useSelector((state) => state.subscriber.loadingFormIgnore);

     useEffect(() => {
          if (listChildRequiring && listChildRequiring.length > 0) {
               const newFormRequiringEdit = [...formRequiringEdit];

               listChildRequiring.forEach((form) => {
                    if (form.issue === 'isDrafted' && formRequiringEdit.every((el) => el.id !== form.id)) {
                         newFormRequiringEdit.push(form);
                    }
               });

               if (newFormRequiringEdit.length !== formRequiringEdit.length) {
                    dispatch(setSubscriberState({ formRequiringEdit: newFormRequiringEdit }));
               }
          }
     }, [listChildRequiring]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleClickAccordion = (id) => {
          if (!fetched) {
               const nextDispatch = {
                    setFetched,
                    setLoading,
                    setShowLoadMoreBtn,
               };
               // fetch api get child
               dispatch(handleFetchChildFormRequiring(id, nextDispatch, itemsPerPage));
          }
          setShowAccordion(!showAccordion);
     };

     const onLoadMore = () => {
          const nextDispatch = {
               setisLoadingLoadMore,
               setShowLoadMoreBtn,
          };
          dispatch(handleFetchChildFormRequiring(formsData.id, nextDispatch, itemsPerPage, true));
     };

     const formEdit = !showIgnoredForm ? formRequiringEdit : formIgnoreEdit;
     const treeFamilyOfForms = listFormsChild
          ? listFormsChild.map((form) => {
                 const formExisted = formEdit.find((item) => item.id === form.id);

                 if (formExisted) {
                      return { ...form, ...formExisted };
                 }
                 return form;
            })
          : [];

     useEffect(() => {
          if (loadMoreChild) {
               const nextDispatch = {
                    setFetched,
                    setLoading,
                    setShowLoadMoreBtn,
               };
               // fetch api get child
               dispatch(handleFetchChildFormRequiring(loadMoreChild, nextDispatch, itemsPerPage));
          }
     }, [formsData]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (loadMoreChild === formsData.id) {
               const nextDispatch = {
                    setLoading,
                    setShowLoadMoreBtn,
               };
               // fetch api get child
               dispatch(handleFetchChildFormRequiring(loadMoreChild, nextDispatch, itemsPerPage));
          }
     }, [loadMoreChild]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (formsData.count <= itemsPerPage) {
               setShowLoadMoreBtn(false);
          }
     }, [formsData, formsData.count]); // eslint-disable-line react-hooks/exhaustive-deps
     
     return (
          <>
               <FormRequiringTableRow
                    className={className}
                    data={formsData}
                    redirect={true}
                    applyChild={false}
                    hasChild={true}
                    parentValue={formsData.categoryId}
                    parentName={formsData.name}
                    parentRedirect={formsData.isRedirect}
                    index={index}
                    errors={errors}
                    setErrors={setErrors}
                    length={length}
                    showAccordion={showAccordion}
                    toggleDetails={handleClickAccordion}
                    redirectTempFormData={redirectTempFormData}
               />
               {showAccordion && (
                    <>
                         {loading ? (
                              <div className={`css-table-row child-form ${(index + 1) % 2 === 0 ? 'even' : 'odd'}`}>
                                   <div className="child-form-loading">
                                        <div className="center-spinner text-left">
                                             <CSpinner color="primary" />
                                        </div>
                                   </div>
                                   <div></div>
                                   <div></div>
                                   <div></div>
                                   <div></div>
                                   <div></div>
                                   {/* <div></div> */}
                              </div>
                         ) : (
                              <>
                                   {showFormsChild && (
                                        <>
                                             {(treeFamilyOfForms && treeFamilyOfForms.length > 0) || showIgnoredForm ? (
                                                  <>
                                                       {treeFamilyOfForms.map((item) => {
                                                            return (
                                                                 <React.Fragment key={`requiring-child-${item.id}`}>
                                                                      <FormRequiringTableRow
                                                                           className={className}
                                                                           data={item}
                                                                           isChildForm={true}
                                                                           hasChild={false}
                                                                           parentValue={formsData.categoryId}
                                                                           parentName={formsData.name}
                                                                           parentRedirect={formsData.isRedirect}
                                                                           index={index}
                                                                           errors={errors}
                                                                           setErrors={setErrors}
                                                                           redirect={true}
                                                                           applyChild={applyChild}
                                                                           length={length}
                                                                           redirectTempFormData={redirectTempFormData}
                                                                      />
                                                                 </React.Fragment>
                                                            );
                                                       })}
                                                       <div className={`css-table-row ${(index + 1) % 2 === 0 ? 'even' : 'odd'}`}>
                                                            {isLoadingLoadMore ? (
                                                                 <>
                                                                      <div className="child-form-loading">
                                                                           <CSpinner color="primary" />
                                                                      </div>
                                                                      <div></div>
                                                                      <div></div>
                                                                      <div></div>
                                                                      <div></div>
                                                                      <div></div>
                                                                      {/* <div></div> */}
                                                                 </>
                                                            ) : (
                                                                 (showLoadMoreBtn || formsData.count > itemsPerPage) && (
                                                                      <>
                                                                           <div>
                                                                                <CButton
                                                                                     className="load-more-child"
                                                                                     color="link"
                                                                                     onClick={onLoadMore}
                                                                                >
                                                                                     Load More <i className="fal fa-chevron-right"></i>
                                                                                </CButton>
                                                                           </div>
                                                                           <div></div>
                                                                           <div></div>
                                                                           <div></div>
                                                                           <div></div>
                                                                           <div></div>
                                                                           {/* <div></div> */}
                                                                      </>
                                                                 )
                                                            )}
                                                       </div>
                                                  </>
                                             ) : (
                                                  <>
                                                  {loadingFormIgnore ? (
                                                                 <>
                                                                 <div className="child-form-loading">
                                                                      <CSpinner color="primary" />
                                                                 </div>
                                                                 <div></div>
                                                                 <div></div>
                                                                 <div></div>
                                                                 <div></div>
                                                                 <div></div>
                                                                 {/* <div></div> */}
                                                            </>
                                                  ) : (
                                                       <div className={`css-table-row ${(index + 1) % 2 === 0 ? 'even' : 'odd'}`}>
                                                            <div>No Forms!</div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            {/* <div></div> */}
                                                       </div>
                                                  )}
                                                  </>
                                             )}
                                        </>
                                   )}
                              </>
                         )}
                    </>
               )}
          </>
     );
};

export default MultiFormsRequiring;
