import React from 'react';
import {
     CCard,
     CCardBody,
     CButton,
     CRow,
     CCol,
     CCardHeader,
} from '@coreui/react';
import { RULE_TYPE } from '../../../../../constants';

const Step1 = ({ activeStep, setActiveStep, ruleType, setRuleType, actionType }) => {
     const isEditting = (actionType === 'edit');

     const handleSelectRule = (ruleType) => {
          setRuleType(ruleType);
          setActiveStep(2);
     }

     return (
          <>
               {
                    activeStep !== 5 && (
                         <CCard className={`cvr-step-1 ${activeStep !== 1 ? 'difference-step' : 'cvr-step-card'}`}>
                              <CCardHeader>
                                   {activeStep !== 1 ? (
                                        <div className="rule-step d-inline-flex justify-content-between align-items-center w-100">
                                             <h5 className="mb-0 inactive">Step 1: Rule Type</h5>
                                             <div className="d-inline-flex align-items-center">
                                                  <span className="given-data mr-3">{ruleType}</span>
                                                  {
                                                       !isEditting && (
                                                            <CButton className="btn-edit" onClick={() => setActiveStep(1)}>
                                                                 Edit
                                                            </CButton>
                                                       )
                                                  }
                                             </div>
                                        </div>
                                   ) : (
                                        <h5>Step 1: Select rule type</h5>

                                   )}
                              </CCardHeader>
                              {
                                   activeStep === 1 && (
                                        <CCardBody>
                                             <div className="rule-step">
                                                  <CRow className="mb-3">
                                                       <CCol>
                                                            <div className='d-flex align-items-center'>
                                                                 <CButton
                                                                      className=" f-13 mr-5"
                                                                      disabled={isEditting}
                                                                      color={ruleType === RULE_TYPE.SIMPLE_RULE ? "primary" : "dark"}
                                                                      onClick={() => handleSelectRule(RULE_TYPE.SIMPLE_RULE)}
                                                                      style={{ cursor: isEditting ? 'default' : '' }}
                                                                 >
                                                                      {RULE_TYPE.SIMPLE_RULE}
                                                                 </CButton>
                                                                 <p>Enter multiple conditions and define a few variables values as a result.</p>
                                                            </div>
                                                       </CCol>
                                                  </CRow>
                                                  <CRow className="mb-3">
                                                       <CCol>
                                                            <div className='d-flex align-items-center'>
                                                                 <CButton
                                                                      className=" f-13 mr-5"
                                                                      disabled={isEditting}
                                                                      color={ruleType === RULE_TYPE.LOOKUP_RULE ? "primary" : "dark"}
                                                                      onClick={() => handleSelectRule(RULE_TYPE.LOOKUP_RULE)}
                                                                      style={{ cursor: isEditting ? 'default' : '' }}
                                                                 >
                                                                      {RULE_TYPE.LOOKUP_RULE}
                                                                 </CButton>
                                                                 <p>Use a table to manage a large list of variable values based on one or many conditions.</p>
                                                            </div>
                                                       </CCol>
                                                  </CRow>
                                             </div>
                                        </CCardBody>
                                   )
                              }
                         </CCard>
                    )
               }
          </>
     )
}

export default Step1
