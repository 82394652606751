import { useLottie } from 'lottie-react';

const CommonLottie = ({ animationData }) => {
     const options = {
          animationData,
          loop: true,
          autoplay: true,
          className: 'lottie-animation'
     };

     const { View } = useLottie(options);

     return View;
};

export default CommonLottie;