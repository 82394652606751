import CIcon from '@coreui/icons-react';
import { CButton } from '@coreui/react';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { CInput } from '../../../../../migration/CInput';
import { escapeRegExp, useOutsideHandling } from '../../../../../../utils';

const Dropdown = ({ children, isOpen, target, onClose, fieldFull }) => (
     <div className="select-wrapper">
          {target}
          {fieldFull ? isOpen ? <Menu1>{children}</Menu1> : null : isOpen ? <Menu>{children}</Menu> : null}
          {isOpen ? <Blanket onClick={onClose} /> : null}
     </div>
);

const Menu1 = (props) => {
     return (
          <div
               style={{
                    // backgroundColor: 'white',
                    borderRadius: 4,
                    marginTop: 8,
                    position: 'absolute',
                    zIndex: 2,
                    cursor: 'default',
               }}
               {...props}
          />
     );
};
const Menu = (props) => {
     return (
          <div
               style={{
                    // backgroundColor: 'white',
                    borderRadius: 4,
                    marginTop: 8,
                    position: 'absolute',
                    zIndex: 2,
                    width: '100%',
                    cursor: 'default',
               }}
               {...props}
          />
     );
};

const Blanket = (props) => (
     <div
          style={{
               bottom: 0,
               left: 0,
               top: 0,
               right: 0,
               position: 'absolute',
               zIndex: 1,
          }}
          {...props}
     />
);
const SelectInsertVariable = (props) => {
     const {
          id,
          isBlur,
          placeholder,
          options,
          onChange,
          value,
          isDisabled,
          className,
          classNameWrapper,
          selectInputType = true,
          fieldFull,
          isMulti,
          error,
          dataVariable = true,
          setShowPopupCreateVariables,
          setStorageType = () => { },
          setVariableKeyId = () => { },
          valueFrom,
     } = props;
     const wrapperRef = useRef(null);
     const filterInputRef = useRef(null);
     const valueInputRef = useRef(null);
     const [isOpen, setIsOpen] = useState(false);
     const [initValue, setInitValue] = useState(value);
     const [inputValue, setInputValue] = useState(initValue && initValue.label ? initValue.label : '')
     const [isSelectedOptionMulti, setIsSelectedOptionMulti] = useState(null)
     // const inputValue = initValue && initValue.label && !displayPlaceholder ? initValue.label : placeholder;
     const [variableFilter, setVariableFilter] = useState(''); // Current text in the insert data layer variable box filter
     const [openTypeVariable, setOpenTypeVariable] = useState({});
     const [showScroll, setShowScroll] = useState(false);
     let variableFilterLowerCase = variableFilter.trim().toLowerCase();
     // Automatic variable options which are displayed in insert data layer variable box
     let variableOptionsFound = options.filter((option) => option.label !== '' && option.label.toLowerCase().includes(variableFilterLowerCase));
     const [variableOptions, setVariableOptions] = useState([]);
     const itemsLoadMore = 20;
     const valueField = value && value.label ? value.label : '';

     useEffect(() => {
          if (wrapperRef.current) {
               if (wrapperRef.current.scrollHeight > wrapperRef.current.clientHeight) {
                    setShowScroll(true);
               } else {
                    setShowScroll(false);
               }
          }
     }, [openTypeVariable]);

     useEffect(() => {
          if (value) {
               setInputValue(value.value)
               setInitValue(value);
          }
     }, [value]);

     const clickOutside = useCallback(() => {
          setIsOpen(false);
          setOpenTypeVariable({});
          setShowScroll(false);
          if (dataVariable) {
               setVariableOptions([]);
          }
          setIsSelectedOptionMulti(null);
          setVariableFilter('');
     }, [dataVariable]);

     useOutsideHandling(wrapperRef, clickOutside, 'mouseup');

     useEffect(() => {
          if (!isMulti) {
               if (isOpen && filterInputRef.current) {
                    setTimeout(() => {
                         filterInputRef.current.focus();
                    }, 0);
               }
               if (!isOpen && valueInputRef.current) valueInputRef.current.focus();

               setIsSelectedOptionMulti(null);
          }
     }, [isMulti, isOpen]);

     const toggleOpen = (e) => {
          const disableInput = e ? !e.target.classList.contains('disable-select') : true;
          if (disableInput)
               setTimeout(() => {
                    setIsOpen(!isOpen);
                    if (valueInputRef.current) valueInputRef.current.readOnly = false;
               }, 1);
          if (!dataVariable) {
               // eslint-disable-next-line
               setOpenTypeVariable((prevState) => ({ ...prevState, ['Data Layer Variable']: !prevState['Data Layer Variable'] }));
          }
     };

     const onSelectChange = (e) => {
          if (isMulti) {
     
               let newValue = [];
               if (value && value.length > 0) {
                    newValue = [...value, e];
               } else {
                    newValue = [e];
               }
               const { onChange, name, hasChange } = props;
               onChange(name, newValue);
               loadMoreItems(1);
               setIsSelectedOptionMulti(e);

               if (hasChange) {
                    hasChange();
               }
          } else {
               if (!selectInputType) {
                    valueInputRef.current.focus();
               }
               toggleOpen();
               setInitValue(e);
               setInputValue(e.value)
               onChange(e);
          }
     };

     const handleOpenTypeVariable = (id) => {
          setOpenTypeVariable((prevState) => ({ ...prevState, [id]: !prevState[id] }));
          if (openTypeVariable['Data Layer Variable']) {
               setVariableOptions([]);
          }
          loadMoreItems();

     };

     useEffect(() => {

          if (variableFilter) {
               searchItems(options, variableFilter);

               return () => {
                    searchItems.cancel();
               };
          } else if(!isSelectedOptionMulti) {
               const newVariableOptions = options.filter((item) => item.label !== '').slice(0, itemsLoadMore);
               setVariableOptions(newVariableOptions);
               if (wrapperRef.current) {
                    wrapperRef.current.scrollTo({ top: 0 });
               }
          }
     }, [variableFilter, options]); // eslint-disable-line

     const searchItems = useMemo(() => {
          return debounce((items, query) => {
               if (query) {
                    const filteredItems = items
                         .filter((item) => item.label !== '' && item.label.trim().toLowerCase().includes(query.trim().toLowerCase()))
                         .slice(0, itemsLoadMore);
                    setVariableOptions(filteredItems);
               }
          }, 200);
     }, []);

     const handleSearch = (e) => {
          setVariableFilter(e);
          if (selectInputType) {
               setInputValue(e)
               onChange({value: e, label: e})
          }
          const searchObject = {};

          const findDatalayerVariable = variableOptionsFound.find((i) => {
               if (i.label.toLowerCase().includes(e.trim().toLowerCase())) {
                    return true;
               }
               return false;
          });
          if (findDatalayerVariable) {
               searchObject['Data Layer Variable'] = true;
          }
          if (Object.keys(searchObject).length > 0) {
               setOpenTypeVariable(searchObject);
          }
     };

     const handleRemoveVariable = (i) => {
          const newValue = [...value];
          newValue.splice(i, 1);
          const { onChange, name, hasChange } = props;
          onChange(name, newValue);
          if (hasChange) {
               hasChange();
          }
          setTimeout(() => {
               setIsOpen(false);
          }, 2);
     };

     const handleCheckShowData = (val, option) => {
          const checkVal = val.find((item) => {
               if (item && item.label && item.label === option.label) {
                    return true;
               }
               return false;
          });
          return checkVal;
     };

     const handleCheckLength = (val, variableOptions) => {
          let length = 0;
          variableOptions.forEach((el) => {
               const checkVal = val.find((item) => {
                    if (item && item.label && item.label === el.label) {
                         return true;
                    }
                    return false;
               });
               if (!checkVal) {
                    length = length + 1;
               }
          });
          return length;
     };

     const handleClick = (option) => {
          if (option.isCustom) {
               setShowPopupCreateVariables(true);
               setStorageType(valueFrom);
               setVariableKeyId(id);
          } else {
               onSelectChange(option);
          }
     };

     const loadMoreItems = (optionNumberLoadItems) => {
          const currentLength = variableOptions.length;
          const numberLoadItems = optionNumberLoadItems ? optionNumberLoadItems : itemsLoadMore;
          const moreItems = variableOptionsFound && variableOptionsFound.slice(currentLength, currentLength + numberLoadItems);

          if (variableOptions.length + moreItems.length <= variableOptionsFound.length) {
               setVariableOptions((prevItems) =>{ 
                    return[...prevItems, ...moreItems]});
          }
     };

     const handleScroll = () => {
          if (wrapperRef.current && wrapperRef.current.scrollTop + wrapperRef.current.clientHeight >= wrapperRef.current.scrollHeight) {
               loadMoreItems();
          }
     };

     return (
          <div
               className={`select-insert-variable ${classNameWrapper} ${isBlur ? ' select-react-customize blur' : ' select-react-customize'}${!value || !value.value ? ' not-selected' : ''
                    }`}
               ref={wrapperRef}
          >
               <Dropdown
                    fieldFull={fieldFull}
                    className={className}
                    isOpen={isOpen}
                    onClose={toggleOpen}
                    target={
                         selectInputType ? (
                              <CInput
                                   onClick={() => toggleOpen()}
                                   value={inputValue}
                                   // className={`${isDisabled ? 'disable-select' : ''}`}
                                   onChange={(e) => handleSearch(e.target.value)}
                              />
                         ) : (
                              <>
                                   {isMulti ? (
                                        <>
                                             <div
                                                  className={`stretch-container select-multiple-variable-field form-control ${isDisabled ? 'disabled' : ''
                                                       } ${isDisabled ? 'disable-select' : ''} `}
                                                  onClick={toggleOpen}
                                             >
                                                  {value && value.length > 0 ? (
                                                       <ul>
                                                            {value.map((v, i) => {
                                                                 return (
                                                                      <Fragment key={i}>
                                                                           {v && v.value && (
                                                                                <li>
                                                                                     {v.value}{' '}
                                                                                     <CButton
                                                                                          onClick={() => handleRemoveVariable(i)}
                                                                                          className="icon-remove-multi"
                                                                                     >
                                                                                          <CIcon
                                                                                               icon="iconRemoveMultiSelect"
                                                                                               width={10}
                                                                                               height={10}
                                                                                          />
                                                                                     </CButton>{' '}
                                                                                </li>
                                                                           )}
                                                                      </Fragment>
                                                                 );
                                                            })}
                                                       </ul>
                                                  ) : (
                                                       <div className="place-holder-select">{placeholder}</div>
                                                  )}
                                             </div>
                                        </>
                                   ) : (
                                        <input
                                             type="text"
                                             className={`stretch-container form-control ${isDisabled ? 'disable-select' : ''} `}
                                             onClick={toggleOpen}
                                             ref={valueInputRef}
                                             value={inputValue}
                                             readOnly
                                        ></input>
                                   )}
                              </>
                         )
                    }
               >
                    <div className={`insert-variable-dropdown ${selectInputType && variableOptionsFound.length === 0 ? 'd-none' : ''}`} >
                         <ul
                              ref={wrapperRef}
                              className={`${options.length === 0 ? 'value-no-options-available no-options' : ''} ${showScroll ? 'have-scroll w-100' : ''}`}
                              onScroll={handleScroll}
                         >
                              {!selectInputType && (variableOptionsFound.length !== 0 || variableFilter) && (
                                   <li className="variable-filter">
                                        <CInput
                                             innerRef={filterInputRef}
                                             type="text"
                                             value={variableFilter}
                                             onChange={(e) => handleSearch(e.target.value)}
                                             placeholder="Select, or type to search"
                                        />
                                   </li>
                              )}
                              {!selectInputType && variableOptionsFound.length === 0 ? (
                                   <li className="no-options border-bottom d-flex align-items-center justify-content-center">No options</li>
                              ) : (
                                   dataVariable && (
                                        <li
                                             className="d-flex align-items-center justify-content-between border-bottom border-top type-variable"
                                             onClick={() => handleOpenTypeVariable('Data Layer Variable')}
                                        >
                                             <strong>Data Layer Variable</strong>
                                             {openTypeVariable['Data Layer Variable'] ? (
                                                  <strong>
                                                       {isMulti ? (
                                                            <>{handleCheckLength(value, variableOptionsFound)}</>
                                                       ) : (
                                                            <>{variableOptionsFound.length}</>
                                                       )}
                                                  </strong>
                                             ) : (
                                                  <CIcon icon="iconArrowDownStrong" />
                                             )}
                                        </li>
                                   )
                              )}

                              {(openTypeVariable['Data Layer Variable'] || !dataVariable) && (
                                   <>
                                        {variableOptions.length > 0 && (
                                             <>
                                                  {variableOptions.map((option, index) => {
                                                       const activeValue = valueField === option.label;
                                                       const re = new RegExp(escapeRegExp(variableFilter), 'gi');
                                                       let newVariable = option.label;
                                                       if (variableFilter) {
                                                            newVariable = newVariable.replace(re, (variable) => `<strong>${variable}</strong>`);
                                                       }
                                                       let checkShowValue = false;
                                                       if (isMulti) {
                                                            checkShowValue = handleCheckShowData(value, option);
                                                       }
                                                       return (
                                                            <React.Fragment key={index}>
                                                                 {!checkShowValue ? (
                                                                      <li
                                                                           className={`find-account-li border-bottom d-flex align-items-center ${activeValue ? 'active-variable' : ''
                                                                                } ${option.isCustom ? 'custom' : ''}`}
                                                                           key={option.label}
                                                                           onClick={() => handleClick(option)}
                                                                      >
                                                                           <span dangerouslySetInnerHTML={{ __html: `${newVariable}` }}></span>
                                                                      </li>
                                                                 ) : (
                                                                      ''
                                                                 )}
                                                            </React.Fragment>
                                                       );
                                                  })}
                                             </>
                                        )
                                             // : (
                                             //      <li className="no-options border-bottom d-flex align-items-center">No options found.</li>
                                             // )
                                        }
                                   </>
                              )}
                         </ul>
                    </div>
                    {/* <ul>
                         <li className='d-flex align-items-center justify-content-between border-bottom type-variable' onClick={() => handleOpenTypeVariable('Data Layer Variable')}>
                              <strong>
                                   Data Layer Variable
                              </strong>
                              {openTypeVariable['Data Layer Variable'] ?
                                   <strong>
                                        {options.length}
                                   </strong>
                                   :
                                   <CIcon icon='iconArrowDownStrong' />
                              }

                         </li>
                    </ul> */}
                    {/* <Select classNamePrefix='react-select'
                         id={id}
                         autoFocus
                         backspaceRemovesValue={false}
                         components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                         controlShouldRenderValue={false}
                         hideSelectedOptions={false}
                         isClearable={false}
                         menuIsOpen
                         onChange={(e) => onSelectChange(e)}
                         options={options}
                         filterOption={finalFilterOption}
                         placeholder="Type here to filter the list…"
                         styles={styles}
                         tabSelectsValue={false}
                         value={initValue}
                         className={className}
                         classNamePrefix={classNamePrefix}
                         menuShouldScrollIntoView
                    /> */}
               </Dropdown>
               {error && typeof error === 'string' ? <p style={{ color: '#e55353', fontSize: '80%' }}>{error}</p> : null}
          </div>
     );
};
SelectInsertVariable.propTypes = {
     id: PropTypes.string,
     isBlur: PropTypes.bool,
     placeholder: PropTypes.string,
     options: PropTypes.array,
     onChange: PropTypes.func,
     value: PropTypes.object,
     isDisabled: PropTypes.bool,
     className: PropTypes.string,
     classNamePrefix: PropTypes.string,
     classNameWrapper: PropTypes.string,
     selectInputType: PropTypes.bool,
     displayPlaceholder: PropTypes.bool,
     filterOption: PropTypes.func,
     selectStyles: PropTypes.object,
     isMulti: PropTypes.bool,
};

export default SelectInsertVariable;
