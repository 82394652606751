import {
     TOGGLE_THEME,
     SET_ASIDE_SHOW,
     SET_SIDEBAR_SHOW,
     SET_USER,
     SET_OVERLAY,
     SET_USER_ROLE,
     SET_FLEXIBLE_MODAL,
     SET_VIEWED_RULES,
     SET_UNSAVED_CHANGES_RULE,
     SET_RULE_HAVE_EDITTING,
     NEED_FETCH_RULE,
     SET_FLEXIBLE_MULTI_MODAL,
     SET_RULE_MULTI_HAVE_EDITING,
} from '../actions/types';

// const theme = JSON.parse(localStorage.getItem('theme'));

const INITIAL_STATE = {
     // darkMode: theme ? theme.darkMode : true,
     darkMode: false,
     asideShow: false,
     sidebarShow: 'responsive',
     overlay: false, // When is opening select account or profile, add overlay to main content
     user: null,
     userRole: '',
     flexibleModal: {
          show: false,
          component: '',
          category: '',
          ruleId: null,
          ruleIdLv2: null,
          ruleIdLv3: null,
          ruleIdLv4: null,
          showLv2: false,
          showLv3: false,
          showLv4: false,
     },
     flexibleMultiModal: [
          {
               show: false,
               component: '',
               attributes: {
                    id: '',
               },
          },
     ],
     viewedRules: {
          activeRule: {},
          listRules: [],
     },
     editingRuleReload: false,
     showUnsavedChange: false,
     editMultiRule: {},
     fetchRule: false,
     edittingRule: {
          show: false,
          showLv2: false,
          type: '',
          typeLv2: '',
     },
};

const theme = (state = INITIAL_STATE, action) => {
     switch (action.type) {
          case TOGGLE_THEME:
               return { ...state, darkMode: !state.darkMode };
          case SET_ASIDE_SHOW:
               return { ...state, asideShow: action.payload };
          case SET_SIDEBAR_SHOW:
               return { ...state, sidebarShow: action.payload };
          case SET_OVERLAY:
               return { ...state, overlay: action.payload };
          case SET_USER:
               return { ...state, user: action.payload };
          case SET_USER_ROLE:
               return { ...state, userRole: action.payload };
          case SET_FLEXIBLE_MODAL:
               return { ...state, flexibleModal: { ...state.flexibleModal, ...action.payload } };
          case SET_FLEXIBLE_MULTI_MODAL:
               const listModal = [...state.flexibleMultiModal];

               if (!!action.payload.isUpdateRedux) {
                    const currentModalIndex = listModal.findIndex((modal) => modal.component === action.payload.component);
                    if (currentModalIndex > -1) {
                         listModal[currentModalIndex] = {
                              ...action.payload,
                         };
                    }
               }
               
               if (!action.payload.isUpdateRedux){
                    listModal[listModal.length - 1] = action.payload;
                    listModal.push({ show: false, component: '', attributes: { id: '' } });
               }

               let newListModal = [];
               if (action.payload.show === false) {
                    newListModal = listModal.filter((item) => item.component !== action.payload.component);
               }

               return { ...state, flexibleMultiModal: newListModal.length > 0 ? newListModal : listModal };
          case SET_VIEWED_RULES:
               const newViewedRules = {
                    ...state.viewedRules,
                    activeRule: action.payload,
               };

               if (action.payload) {
                    const newListRules = state.viewedRules.listRules.filter((rule) => rule.id !== action.payload.id);

                    newViewedRules.listRules = [...newListRules, action.payload];
               }

               if (action.editting) return { ...state, viewedRules: newViewedRules, editingRuleReload: !state.editingRuleReload };

               return { ...state, viewedRules: newViewedRules };
          case SET_UNSAVED_CHANGES_RULE:
               return { ...state, showUnsavedChange: typeof action.payload !== 'undefined' ? action.payload : !state.showUnsavedChange };
          case SET_RULE_MULTI_HAVE_EDITING:
               return { ...state, editMultiRule: action.payload };
          case SET_RULE_HAVE_EDITTING:
               return { ...state, edittingRule: { ...state.edittingRule, ...action.payload } };
          case NEED_FETCH_RULE:
               if (state.flexibleModal.showLv2) {
                    return { ...state, fetchRule: !state.fetchRule };
               }
               return { ...state };
          default:
               return state;
     }
}

export default theme;
