import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setAsideShow } from '../../../actions/common';
import CToggler from '../../../components/migration/CToggler';

const UpgradeToggler = () => {
     const dispatch = useDispatch();
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const maxPackage = useSelector(state => state.subscriber.maxPackage);
     const showHamburgerIcon = useSelector(state => state.subscriber.showHamburgerIcon);
     const asideShow = useSelector(state => state.theme.asideShow);

     return (
          <>
               {
                    (activeAccount && !maxPackage && showHamburgerIcon) && (
                         <CToggler
                              inHeader
                              className="d-md-down-none"
                              onClick={() => dispatch(setAsideShow(!asideShow))}
                         >
                              <span className="header-toggler-icon"></span>
                         </CToggler>
                    )
               }
          </>
     )
}

export default UpgradeToggler
