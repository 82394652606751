import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callTokenApi } from '../../../../../../apiCaller';
import { API_CLIENT_SEGMENT_DETAil, TYPE_SHOW_UNSAVE_CHANGE } from '../../../../../../constants';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { toastError } from '../../../../../../utils';
import { setRuleHaveEditting } from '../../../../../../actions/common';

const Step1 = React.lazy(() => import(`./steps/Step1`));
const Step2 = React.lazy(() => import(`./steps/Step2`));

export const SegmentDetailContext = React.createContext({});

const DEFAULT_DATA_SEGMENT = {
     name: '',
     description: '',
     ruleGroups: [
          {
               conjunction: '',
               mainOperator: 'include',
               segmentObject: 'event',
               conditionFocus: 'property',
               dateRangeFilter: {
                    type: 'lifetime-of-the-object',
                    numberOfDaysPrevious: 0,
                    startDate: '',
                    endDate: '',
               },
               g0: {
                    key: '',
                    type: '',
                    condition: '',
                    value: '',
                    property: '',
                    conjunction: '',
               },
          },
     ],
};
const SegmentDetail = ({ id }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountId = activeAccount.id;

     const [isLoading, setIsLoading] = useState(false);
     const [activeStep, setActiveStep] = useState(1);
     const [stepsData, setStepsData] = useState(cloneDeep(DEFAULT_DATA_SEGMENT));
     const [initStepsData, setInitStepsData] = useState(cloneDeep(DEFAULT_DATA_SEGMENT));

     const fetchSegmentDetail = () => {
          if (!id) return;
          setIsLoading(true);
          callTokenApi(API_CLIENT_SEGMENT_DETAil.replace(':accountId', accountId).replace(':id', id), 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const { segment } = response.data;
                         setStepsData(segment);
                         setInitStepsData(segment);
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     };
     useEffect(fetchSegmentDetail, []); // eslint-disable-line react-hooks/exhaustive-deps

     const handleHasChange = () => {
          dispatch(setRuleHaveEditting({ show: true, type: TYPE_SHOW_UNSAVE_CHANGE.EDIT_SIMPLE }));
     };

     return (
          <React.Suspense fallback={<CenterSpinner />}>
               <div className="rule-detail segment-detail">
                    {isLoading ? (
                         <CenterSpinner />
                    ) : (
                         <div className="cvr-create-new">
                              <h1>{id ? `Edit Segment: ${stepsData.name}` : `Create a Segment`}</h1>
                              <p>{id ? 'Use the steps below to configure your segment.' : 'Create your segment by following the steps below. '} </p>
                              <SegmentDetailContext.Provider
                                   value={{
                                        id,
                                        activeStep,
                                        setActiveStep,
                                        initStepsData,
                                        setInitStepsData,
                                        stepsData,
                                        setStepsData,
                                        handleHasChange
                                   }}
                              >
                                   <Step1 />
                                   <Step2 />
                              </SegmentDetailContext.Provider>
                         </div>
                    )}
               </div>
          </React.Suspense>
     );
};

export default SegmentDetail;
