import React, { forwardRef, useEffect, useState } from 'react';
import _ from 'lodash';

const CustomDropdownItem = forwardRef((props, ref) => {
     const { listDropdownItem, itemActive, handleClickListDropdownItem, setShowDropDownItem, search } = props;
     const [searchDropDownItem, setSearchDropdownItem] = useState('');
     const [filterListDropdownItem, setFilterListDropdownItem] = useState(listDropdownItem);

     function filterItemsBySearch(items, searchInput, currentList) {
          const filteredItems = items.filter((item) => {
               const lowercaseLabel = item.toLowerCase();
               const isExcluded = currentList.some((excludedLabel) => lowercaseLabel === excludedLabel.toLowerCase());
               return lowercaseLabel.includes(searchInput.toLowerCase()) && !isExcluded;
          });
          return filteredItems;
     }

     useEffect(() => {
          if (search) {
               const debouncedFilter = _.debounce(() => {
                    const filteredItems = filterItemsBySearch(listDropdownItem, searchDropDownItem, itemActive);
                    setFilterListDropdownItem(filteredItems);
               }, 200);

               debouncedFilter();
               return () => {
                    debouncedFilter.cancel();
               };
          }
          //eslint-disable-next-line
     }, [searchDropDownItem, itemActive]);

     return (
          <div ref={ref} className='dropdownMenuItem'>
               {search && <input type='text' placeholder='Search' value={searchDropDownItem} onChange={(event) => setSearchDropdownItem(event.target.value)} />}
               <ul>
                    {filterListDropdownItem &&
                         filterListDropdownItem.map((item, index) => (
                              <li
                                   key={item + index}
                                   onClick={() => {
                                        handleClickListDropdownItem(item);
                                        setShowDropDownItem(false);
                                   }}
                              >
                                   {item}
                              </li>
                         ))}
               </ul>
          </div>
     );
});

export default CustomDropdownItem;
