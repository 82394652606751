import React, { useContext } from 'react';
import { CCard, CCardBody, CButton, CRow } from '@coreui/react';
import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from './CreateAccount';
import { API_CLIENT_FIND_USER_ACCOUNT, AUTH_PATH, LOCAL_SETUP_INVITED_ACCOUNT, LOCAL_SHOW_ONLY_ENABLED_DESTINATIONS, LOCAL_SHOW_ONLY_ENABLED_LISTENERS } from '../../../../../constants';
import { fetchAnAccount, fetchMonitoringNotifications, resetSubscriberReducer, setActiveAccount, setActiveListener, setRoutes, setShowCreateAccountPopup } from '../../../../../actions/subscriber';
import { gtmTrackingChangeLLAccount } from '../../../../../utils';
import { useHistory } from "react-router-dom";
import { callTokenApi } from '../../../../../apiCaller';
import { useDispatch, useSelector } from 'react-redux';

const ThankYouTalk = () => {
     const dispatch = useDispatch()
     const history = useHistory()
     const { account, setCurrentStep, scheduleUpgrade, firstSchedule, setAccount, showThank, setShowThank } = useContext(CreateAccountContext);
     const user = useSelector(state => state.theme.user);
     const activeAccount = useSelector(state => state.subscriber.activeAccount)
     const isScheduleDemo = ((activeAccount.id === account.id) && activeAccount.isScheduleUpgrade) || scheduleUpgrade;
     const accounts = useSelector(state => state.subscriber.accounts);
     const accountsSort = accounts.sort((accountFirst, accountSecond) => new Date(accountFirst.accessAt) - new Date(accountSecond.accessAt)).filter((item) => item.reachTheEndOfSetup === true)
     const accountId = account.id
     const userId = user.id;

     const changeAccount = (account) => {
          if (account.id !== accountId) {
               selectAccountID(account);
               dispatch(setShowCreateAccountPopup(false));
          }
     };

     const selectAccountID = (account) => {
          if (account.id !== accountId) {
               dispatch(setRoutes([]));
               dispatch(resetSubscriberReducer())
               dispatch(fetchAnAccount(account, accounts))
               dispatch(fetchMonitoringNotifications(account.id));
               dispatch(setActiveListener(null));
               // dispatch(setActiveDestination(null));

               localStorage.setItem('accountId', account.id);
               localStorage.removeItem(LOCAL_SHOW_ONLY_ENABLED_LISTENERS);
               localStorage.removeItem(LOCAL_SHOW_ONLY_ENABLED_DESTINATIONS);

               history.push('/');

               let localSetupInvitedAccount = localStorage.getItem(LOCAL_SETUP_INVITED_ACCOUNT);
               callTokenApi(`${API_CLIENT_FIND_USER_ACCOUNT}${userId}/${account.id}`, 'GET', null).then(response => {
                    if (response.status === 200) {
                         const { userAccount } = response.data;
                         // Tracking Change Account
                         const userDetails = {
                              userId: user.id,
                              email: user.email
                         };

                         const _activeAccount = accounts.find(acc => acc.id === userAccount.accountId);
                         const accountDetails = {
                              accountid: _activeAccount.id,
                              accountname: _activeAccount.name
                         };
                         gtmTrackingChangeLLAccount(userDetails, accountDetails);
                         if (localSetupInvitedAccount === 'yes' || (userAccount && !userAccount.firstLoginAccount && typeof userAccount.firstLoginAccount !== 'undefined' && userAccount.firstLoginAccount !== 'undefined')) {
                              localStorage.removeItem(LOCAL_SETUP_INVITED_ACCOUNT);
                              // callTokenApi(`${API_CLIENT_ACCOUNT}${activeAccount.accountId}`, 'GET', null);
                              const hash = history.location.hash;
                              if (!hash || hash !== '#payment-method') {
                                   history.push(AUTH_PATH.WELCOME_SCREEN);
                              }
                         }
                    }
               });
          }
     }

     const onClickContinue = () => {
          if (isScheduleDemo && accounts.length > 1) {
               dispatch(setActiveAccount(account))
               changeAccount(accountsSort[(accountsSort.length - 1)])
          }
          else {
               setCurrentStep(STEPS.accountPackage, CHANGE_STEP_TYPE.subStepNext);
               setAccount(preAcc => { return { ...preAcc, scheduleUpgrade: false } })
               setShowThank(false)
          }
     }

     const oncClickSchedule = () => {
          setCurrentStep(STEPS.talkToSales, CHANGE_STEP_TYPE.subStepNext)
     }

     const onClickBack = () => {
          dispatch(setActiveAccount(account))
          changeAccount(accountsSort[(accountsSort.length - 1)])
     }

     return (
          <CreateAccountBody onBackBtnClicked={isScheduleDemo && accounts.length > 1 ? () => onClickBack() : null} hideStepProgress={true}>
               <div>
                    {firstSchedule || showThank ? (
                         <CCard className='account-see-how-it-work'>
                              <CCardBody>
                                   <h3>Thank You!</h3>
                                   <p className='des'>
                                        Your meeting has been scheduled. Check your email, an invitation was sent with details and will be added to your calendar.
                                   </p>
                                   <CButton className="btn-save h-auto btn-green" onClick={() => onClickContinue()} style={{ maxWidth: '150px', marginTop: '0px', padding: '10px 0' }}>
                                        Continue
                                   </CButton>
                              </CCardBody>
                         </CCard>
                    ) :
                         (
                              <CCard className='account-see-how-it-work'>
                                   <CCardBody>
                                        <h3>You Previously Requested a Demo</h3>
                                        <p className='des'>
                                             It looks like you’ve submitted a demo request previously. If you’d like to schedule another one, click below.
                                        </p>
                                        <CRow style={{ justifyContent: 'center' }}>
                                             <CButton className="btn-save h-auto btn-white" onClick={onClickContinue} style={{ margin: '0 10px', width: '230px' }}>
                                                  Go back
                                             </CButton>
                                             <CButton className="btn-save h-auto btn-green" onClick={oncClickSchedule} style={{ margin: '0 10px', width: '230px' }}>
                                                  Submit Another Request
                                             </CButton>
                                        </CRow>
                                   </CCardBody>
                              </CCard>
                         )
                    }
               </div>
          </CreateAccountBody>
     );
};

export default ThankYouTalk;
