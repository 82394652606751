import React from 'react';
import {
	CButton,
	CModal,
	CModalBody,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useDispatch } from 'react-redux';
import { CREATE_SUPPORT_TICKET } from './support-ticket/GetHelpLabel';
import { setShowGetHelpLabel, setTicketInit } from '../../../actions/subscriber';

const PopupNotifyDataLayerChanges = ({ show, changePackageText, onClickCreateTicket, onAccept, onClose }) => {
	const dispatch = useDispatch();

	const handleCreateSupportTicket = () => {
		if (onClickCreateTicket) {
			onClickCreateTicket();
		}
		onClose();
		dispatch(setShowGetHelpLabel(true));
		dispatch(setTicketInit({ ticketStep: CREATE_SUPPORT_TICKET.step1, ticketType: 'Something else', hideListenerSelect: true }));
	}

	return (
		<div className="popup-notify remove-popup variable-in-rules publish-downgrade">
			<CModal visible={show} onClose={onClose} color="primary" alignment="center" portal={false}>
				<CModalBody>
					<CIcon icon="cil-x" onClick={onClose} className="icon-close-popup"></CIcon>
					<div className="popup-publish-version-description">
						<h2>This Upgrade Comes with Important Changes</h2>
						<p>We’ve recently released a large number of platform updates, which include an improved data layer structure.  As soon as you upgrade to a new plan, your account’s data layer structure may change. We recommend contacting support by submitting a ticket and we will set up a time to review your existing account so we can help to update any data layer references that will change.  We recommend that you not upgrade your account until we help you make these changes.</p>
					</div>
					<div className='group-button'>
						<CButton color="success" className="btn-upgrade" onClick={onAccept}>{changePackageText}</CButton>
						<CButton color="primary" onClick={handleCreateSupportTicket}>Create a Support Ticket</CButton>
					</div>
				</CModalBody>
			</CModal>
		</div>
	)
}

export default PopupNotifyDataLayerChanges;
