import React from 'react';

const Waiting = ({ isLoading, children }) => {
    return (
        <>
            {
                isLoading ? <span className="dots-waiting">Waiting</span> : children
            }
        </>
    )
}

export default Waiting;
