import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { CButton } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
    SupportTicketPopupContext,
    ISSUE_LISTENER,
    CREATE_SUPPORT_TICKET
} from "../GetHelpLabel";
import { STEP_PREVIOUS_CREATE_TICKET } from "../../../../../constants";

const IssueListener2 = () => {
    const listenerInTickets = useSelector((state) => state.subscriber.listenerInTickets);

    const { listeners, listenerFeatures } = listenerInTickets;

    const { setCurrentStep, listenerFeature, setDataShowVideo } = useContext(SupportTicketPopupContext);

    const setLoadVideoOverview = () => {
        const { listenerId, featureId } = listenerFeature;

        if (featureId) {
            const featureData = listenerFeatures[listenerId].find(feature => feature.fId === featureId);
            const listenerData = listeners.find(listener => listener.id === listenerId);

            let videoFeature = '';

            if (featureData.fspecialInstructions.isOn) {
                videoFeature = featureData.fspecialInstructions.videoEmbed;
            } else if (featureData.fLearnVideo.isOn) {
                videoFeature = featureData.fLearnVideo.videoEmbed;
            }
            if (videoFeature) {
                setDataShowVideo({listenerName: listenerData.name, featureName: featureData.fName, videoEmbed: videoFeature});
                setCurrentStep(ISSUE_LISTENER.step4)
            } else {
                let videoListener = '';

                if (listenerData.specialInstructions.isOn) {
                    videoListener = listenerData.specialInstructions.videoEmbed;
                } else if (listenerData.videoOverview.isOn) {
                    videoListener = listenerData.videoOverview.videoEmbed;
                }

                if (videoListener) {
                    setDataShowVideo({listenerName: listenerData.name, videoEmbed: videoListener});
                    setCurrentStep(ISSUE_LISTENER.step4)
                } else {
                    setCurrentStep(CREATE_SUPPORT_TICKET.step1)
                    localStorage.setItem(STEP_PREVIOUS_CREATE_TICKET, ISSUE_LISTENER.step2)
                }
            }
        } else if (listenerId) {
            const listenerData = listeners.find(listener => listener.id === listenerId);

            let videoListener = '';

            if (listenerData.specialInstructions.isOn) {
                videoListener = listenerData.specialInstructions.videoEmbed;
            } else if (listenerData.videoOverview.isOn) {
                videoListener = listenerData.videoOverview.videoEmbed;
            }

            if (videoListener) {
                setDataShowVideo({listenerName: listenerData.name, videoEmbed: videoListener});
                setCurrentStep(ISSUE_LISTENER.step4)
            } else {
                setCurrentStep(CREATE_SUPPORT_TICKET.step1)
                localStorage.setItem(STEP_PREVIOUS_CREATE_TICKET, ISSUE_LISTENER.step2)
            }
        }
    };

    return (
        <div className="ticket-popup-content">
            <div className="btn-back-ticket">
                <CIcon icon="icon-chevron-left" width={6} />
                <span onClick={() => setCurrentStep(ISSUE_LISTENER.step1)}>
                    Back
                </span>
            </div>
            <div className="ticket-popup-header">
                <h5>We're Here to Help!</h5>
                <p>Can you confirm that the Listener is running and functioning on your website using the diagnostic info in the browser's console?</p>
            </div>
            <div className="ticket-popup-body">
                <CButton
                    color="primary"
                    onClick={() => setCurrentStep(ISSUE_LISTENER.step3)}
                >
                    No {`or (unsure)`}
                </CButton>
                <CButton color="primary" onClick={() => setLoadVideoOverview()}>Yes</CButton>
            </div>
        </div>
    );
};

export default IssueListener2;
