import React, { useContext, useState } from 'react';
import CIcon from '@coreui/icons-react';
import { CButton, CCard, CCardBody, CForm } from '@coreui/react';
import { Formik } from 'formik';

import { CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
import { useScrollFaded } from '../../../../../../helpers/customHooks';
import Waiting from '../../../../../general/Loadings/Waiting';
import useListenerSubmit from './useListenerSubmit';
import useListenerBackBtnClicked from './useListenerBackBtnClicked';
import useSaveListenerProgress from './useSaveListenerProgress';
import { LISTENER_TYPE } from '../../../../../../constants';
import { CInput, CInputCheckbox } from '../../../../../migration/CInput';
import CLabel from '../../../../../migration/CLabel';
import CFormGroup from '../../../../../migration/CFormGroup';

const FormListener = () => {
    const { stepsData } = useContext(CreateAccountContext);
    const { listeners, selectedListenerTopics, allSelectedListeners, totalConfigureMeasurementSteps } = stepsData;
    const [searchText, setSearchText] = useState('');
    const formListeners = listeners.filter(listener => listener.type === LISTENER_TYPE.FORMS || listener.type === LISTENER_TYPE.SCHEDULING);
    const customFormIndex = formListeners.findIndex(listener => listener.code === 'customForms')
    let customForm = formListeners.splice(customFormIndex, 1)[0];
    let listenersToDisplay = formListeners.filter(listener => listener.name.toLowerCase().includes(searchText.toLowerCase()));
    const { containerListClass, onScrollContainerList } = useScrollFaded({ hasScroll: listenersToDisplay.length > 9 });
    const currentListenerIndex = selectedListenerTopics.findIndex(topic => topic === STEPS.formListener);
    const listenerType = 'form';
    const handleListenerSubmit = useListenerSubmit(listenerType, currentListenerIndex);
    const handleBackBtnClicked = useListenerBackBtnClicked(listenerType, currentListenerIndex);
    useSaveListenerProgress();

    const initialValues = {
        selectedListeners: allSelectedListeners[listenerType] || []
    };

    const checkIfChecked = (selectedListeners, listener) => {
        return !!selectedListeners.find(selectedListener => selectedListener.id === listener.id);
    }

    const onBackBtnClicked = (selectedListeners) => {
        handleBackBtnClicked(selectedListeners);
    };

    const onSubmit = ({ selectedListeners }) => {
        handleListenerSubmit(selectedListeners);
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {({ values, handleSubmit, setFieldValue, isSubmitting }) => {
                const handleSelect = (listener) => {
                    let newSelectedListeners = [...values.selectedListeners];
                    const foundListenerIndex = values.selectedListeners.findIndex(selectedListener => selectedListener.id === listener.id);

                    if (foundListenerIndex > -1) {
                        newSelectedListeners.splice(foundListenerIndex, 1);
                    } else {
                        newSelectedListeners.push(listener);
                    }

                    setFieldValue('selectedListeners', newSelectedListeners);
                };

                return (
                    <CreateAccountBody onBackBtnClicked={() => onBackBtnClicked(values.selectedListeners)} backBtnDisabled={isSubmitting}>
                        <CCard className="account-form">
                            {totalConfigureMeasurementSteps > 1 &&
                                (
                                    <p className="number-of-step">
                                        {currentListenerIndex} of {totalConfigureMeasurementSteps} steps
                                    </p>
                                )
                            }
                            <CCardBody>
                                <CForm onSubmit={handleSubmit}>
                                    <CFormGroup>
                                        <h3>Select Your Form Listener</h3>
                                        <p>Search for the platform you use to manage forms on your website. You can select multiple Listeners.</p>
                                        <div className="filter-search">
                                            <CInput
                                                name="search"
                                                className="search"
                                                type="text"
                                                placeholder="Enter your form system name to filter"
                                                value={searchText}
                                                onChange={(e) => setSearchText(e.target.value)}
                                                data-lpignore="true"
                                            />
                                            <CIcon icon="iconFilterSearch" height={16} />
                                        </div>
                                        <div className={`forms-listener ${containerListClass}`} onScroll={onScrollContainerList}>
                                            <div className="list-form">
                                                {listenersToDisplay.length > 0 ? (
                                                    listenersToDisplay.map((listener) => (
                                                        <label key={listener.id}>
                                                            <input
                                                                type="checkbox"
                                                                checked={checkIfChecked(values.selectedListeners, listener)}
                                                                onChange={() => handleSelect(listener)}
                                                                disabled={isSubmitting}
                                                            />
                                                            <div className="icon-box">
                                                                <div className="tickmark"></div>
                                                                <img className="listener-logo" src={listener.logo} alt={`${listener.name} Icon`} />
                                                                <span>{listener.name}</span>
                                                            </div>
                                                        </label>
                                                    ))
                                                ) : (
                                                    <p className="item-not-found">No items found.</p>
                                                )}
                                            </div>
                                        </div>
                                        <CButton className="btn-save" type="submit" disabled={!values.selectedListeners.length || isSubmitting}>
                                            <Waiting isLoading={isSubmitting}>NEXT</Waiting>
                                        </CButton>
                                        <div className="notification">
                                            <div className="custom-form-group">
                                                <h4>Don’t see your forms?</h4>
                                                <div>
                                                    <CLabel htmlFor="custom-form">Enable Custom Forms</CLabel>
                                                    <CInputCheckbox
                                                        id="custom-form"
                                                        checked={checkIfChecked(values.selectedListeners, customForm)}
                                                        onChange={() => handleSelect(customForm)}
                                                        disabled={isSubmitting}
                                                    />
                                                </div>
                                            </div>
                                            <p className="mb-4">
                                                If we don't support a direct integration with your forms, or you use Custom HTML Forms, enable the
                                                Custom Forms Listeners.
                                            </p>
                                        </div>
                                    </CFormGroup>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </CreateAccountBody>
                );
            }}
        </Formik>
    );
};

export default FormListener;
