import CIcon from '@coreui/icons-react';
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react';
import React, { useContext, useEffect, useState } from 'react';
import { components } from 'react-select';
import { REPORT_DATA_TYPES, REPORT_NAMES, REPORT_TAB_TYPES } from '../../../../constants';
import { EventExplorerContext } from './EventExplorer';
import ExportReport from './ExportReport';
import FilterReport from './FilterReport';
import CLabel from '../../../migration/CLabel';
import CTabs from '../../../migration/CTabs';
import ChildMenu from './ChildMenu';
import SavedView from './report-view/dropdown/SavedView';
// import RecordFilter from '../../../general/filters/RecordFilter';

const MenuList = (props) => {
     return (
          <components.MenuList {...props} className={`${!Array.isArray(props.children) ? 'no-options' : ''}`}>
               {props.children}
          </components.MenuList>
     );
};

const MenuReport = () => {
     const {
          reportName,
          filterMetric,
          setFilterMetric,
          handleGetOption,
          filterDimensions,
          setFilterDimensions,
          filterProperties,
          setFilterProperties,
          filterEvent,
          scoped,
          reportType,
          showChart,
          setShowChart,
          conversionNameMetric,
          recordProfile,
          metricsStepForm,
          newProperties,
          weight,
          setWeight,
          utmArray,
          eCoItemCategories,
          isDirect,
          dataParamValue,
     } = useContext(EventExplorerContext);
     const checkFilter = filterEvent.some((item) => item.value);
     // const disableChange = reportName === REPORT_NAMES.ECOMMERCE_PURCHASE_FLOW;
     let { dimensions, weights, metrics } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];

     if (reportName === REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT) {
          dimensions = [...dimensions, ...utmArray];
          metrics = handleGetOption({ option: metrics, type: 'weight', reportName, weight });
     }
     if (reportName === REPORT_NAMES.ECOMMERCE_ITEMS) {
          dimensions = [...dimensions, ...eCoItemCategories];
     }
     const [activeTab, setActiveTab] = useState(1);

     useEffect(() => {
          if (checkFilter && !recordProfile && dataParamValue) {
               setActiveTab('Filter');
          }
     }, [checkFilter, recordProfile]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleTabClick = (tabIndex) => {
          if (tabIndex !== activeTab) {
               setActiveTab(tabIndex);
          } else {
               setActiveTab('');
          }
     };

     const handleChangeWeight = (e) => {
          // setFilterMetric([...filterMetric, e]);
          // const oldFilter = filterMetric.filter((item) => !weights.find((el) => el.value === item.value));
          // setFilterMetric([...oldFilter, e]);
          setWeight({ ...e, noneDirect: isDirect });
     };

     return (
          <>
               <CTabs activeTab={activeTab} onActiveTabChange={handleTabClick} className="mb-2">
                    <div className="menu-report justify-content-end">
                         {/* <div className='search-field'>
                              <CInput placeholder="Search Key" />
                              <CIcon icon='cilSearch' width={16} height={16} />
                         </div> */}
                         {!recordProfile && (
                              <SavedView />
                         )}
                         <CNav variant="tabs" className="menu-report-list">
                              {reportName === REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT && (
                                   <CNavItem>
                                        <CNavLink
                                             className={activeTab === REPORT_TAB_TYPES.MODEL ? 'active' : ''}
                                             onClick={() => handleTabClick(REPORT_TAB_TYPES.MODEL)}
                                             data-tab={REPORT_TAB_TYPES.MODEL}
                                        >
                                             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 448 512">
                                                  <path d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z" />
                                             </svg>
                                             <CLabel>{REPORT_TAB_TYPES.MODEL}</CLabel>
                                        </CNavLink>
                                   </CNavItem>
                              )}
                              {dimensions.length > 0 && (
                                   <CNavItem>
                                        <CNavLink
                                             className={activeTab === REPORT_TAB_TYPES.DIMENSIONS ? 'active' : ''}
                                             onClick={() => handleTabClick(REPORT_TAB_TYPES.DIMENSIONS)}
                                             data-tab={REPORT_TAB_TYPES.DIMENSIONS}
                                        >
                                             <CIcon icon="icon-dimension" />
                                             <CLabel>{REPORT_TAB_TYPES.DIMENSIONS}</CLabel>
                                        </CNavLink>
                                   </CNavItem>
                              )}
                              {newProperties.length > 0 && (
                                   <CNavItem>
                                        <CNavLink
                                             className={activeTab === REPORT_TAB_TYPES.PROPERTIES ? 'active' : ''}
                                             onClick={() => handleTabClick(REPORT_TAB_TYPES.PROPERTIES)}
                                             data-tab={REPORT_TAB_TYPES.PROPERTIES}
                                        >
                                             <CIcon icon="icon-properties" />
                                             <CLabel>{REPORT_TAB_TYPES.PROPERTIES}</CLabel>
                                        </CNavLink>
                                   </CNavItem>
                              )}
                              <CNavItem>
                                   <CNavLink
                                        className={activeTab === REPORT_TAB_TYPES.METRICS ? 'active' : ''}
                                        onClick={() => handleTabClick(REPORT_TAB_TYPES.METRICS)}
                                        data-tab={REPORT_TAB_TYPES.METRICS}
                                   >
                                        <CIcon icon="icon-metrics" />
                                        <CLabel>{REPORT_TAB_TYPES.METRICS}</CLabel>
                                   </CNavLink>
                              </CNavItem>
                              <CNavItem>
                                   <CNavLink
                                        className={activeTab === REPORT_TAB_TYPES.FILTER ? 'active' : ''}
                                        onClick={() => handleTabClick(REPORT_TAB_TYPES.FILTER)}
                                        data-tab={REPORT_TAB_TYPES.FILTER}
                                   >
                                        <CIcon icon="icon-filter-report" />
                                        <CLabel>{REPORT_TAB_TYPES.FILTER}</CLabel>
                                   </CNavLink>
                              </CNavItem>
                              <CNavItem>
                                   <CNavLink
                                        className={activeTab === REPORT_TAB_TYPES.EXPORT ? 'active' : ''}
                                        onClick={() => handleTabClick(REPORT_TAB_TYPES.EXPORT)}
                                        data-tab={REPORT_TAB_TYPES.EXPORT}
                                   >
                                        <CIcon icon="icon-export" />
                                        <CLabel>{REPORT_TAB_TYPES.EXPORT}</CLabel>
                                   </CNavLink>
                              </CNavItem>
                         </CNav>
                         {!recordProfile && (
                              <div className="chartButton">
                                   <button
                                        className={`buttonChartIcon${showChart ? ' active' : ''}`}
                                        type="button"
                                        onClick={() => setShowChart((prev) => !prev)}
                                   >
                                        <CIcon icon="iconChart" style={{ color: `${showChart ? '#ffff' : '#20a8d8'}` }} />
                                   </button>
                              </div>
                         )}
                    </div>
                    <CTabContent>
                         {reportName === REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT && (
                              <CTabPane visible={activeTab === REPORT_TAB_TYPES.MODEL ? true : false} data-tab={REPORT_TAB_TYPES.MODEL}>
                                   <ChildMenu
                                        defaultValue={weight}
                                        MenuList={MenuList}
                                        handleChangeField={handleChangeWeight}
                                        type={REPORT_TAB_TYPES.MODEL}
                                        options={weights.filter((el) => el.value !== weight.value)}
                                        isMulti={false}
                                   />
                              </CTabPane>
                         )}
                         <CTabPane visible={activeTab === REPORT_TAB_TYPES.DIMENSIONS ? true : false} data-tab={REPORT_TAB_TYPES.DIMENSIONS}>
                              <ChildMenu
                                   defaultValue={filterDimensions}
                                   MenuList={MenuList}
                                   handleChangeField={setFilterDimensions}
                                   type={REPORT_TAB_TYPES.DIMENSIONS}
                                   options={handleGetOption({
                                        option: dimensions,
                                        type: 'option',
                                        filter: [],
                                        menuFilter: 'filterDimension',
                                   })}
                                   allOption={handleGetOption({ option: dimensions })}
                                   isMulti={
                                        [REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT, REPORT_NAMES.ECOMMERCE_ITEMS].includes(reportName)
                                             ? false
                                             : true
                                   }
                              />
                         </CTabPane>
                         <CTabPane visible={activeTab === REPORT_TAB_TYPES.PROPERTIES ? true : false} data-tab={REPORT_TAB_TYPES.PROPERTIES}>
                              <ChildMenu
                                   defaultValue={filterProperties}
                                   MenuList={MenuList}
                                   handleChangeField={setFilterProperties}
                                   type={REPORT_TAB_TYPES.PROPERTIES}
                                   options={handleGetOption({
                                        option: newProperties,
                                        type: 'option',
                                        filter: [],
                                   })}
                                   allOption={handleGetOption({ option: newProperties })}
                                   isMulti={true}
                              />
                         </CTabPane>
                         <CTabPane visible={activeTab === REPORT_TAB_TYPES.METRICS ? true : false} data-tab={REPORT_TAB_TYPES.METRICS}>
                              <ChildMenu
                                   defaultValue={filterMetric}
                                   MenuList={MenuList}
                                   handleChangeField={setFilterMetric}
                                   type={REPORT_TAB_TYPES.METRICS}
                                   options={handleGetOption({
                                        option: [...metrics, ...conversionNameMetric, ...metricsStepForm],
                                        type: 'option',
                                        filter: [],
                                   })}
                                   allOption={handleGetOption({ option: [...metrics, ...conversionNameMetric, ...metricsStepForm] })}
                                   isMulti={true}
                              />
                         </CTabPane>
                         <CTabPane visible={activeTab === REPORT_TAB_TYPES.FILTER ? true : false} data-tab={REPORT_TAB_TYPES.FILTER}>
                              {
                                   // recordProfile ? <RecordFilter /> :
                                   <FilterReport />
                              }
                         </CTabPane>
                         <CTabPane visible={activeTab === REPORT_TAB_TYPES.EXPORT ? true : false} data-tab={REPORT_TAB_TYPES.EXPORT}>
                              <ExportReport />
                         </CTabPane>
                    </CTabContent>
               </CTabs>
          </>
     );
};

export default MenuReport;
