import React, { useContext } from 'react';
import { CCard, CCardBody, CButton } from '@coreui/react';
import { CreateAccountBody, CreateAccountContext, STEPS } from './CreateAccount';
import useSaveCurrentStep from './useSaveCurrentStep';

const ThankYouDemo = () => {
     const { setCurrentStep, account } = useContext(CreateAccountContext);
     const isAccountSetup = account.completedSteps && account.completedSteps.length > 3
     const onClickCreateAccount = () => {
          if (isAccountSetup) {
               setCurrentStep(STEPS.accountSetup);
          }
          else { setCurrentStep(STEPS.createLogin) }
     }

     // const onBackBtnClicked = () => {
     //      setCurrentStep(STEPS.userReplace);
     // }

     useSaveCurrentStep()

     // const backBtn = prevSteps[0] === STEPS.userReplace || prevSteps[0] === STEPS.userWork || prevSteps[0] === STEPS.userCompliant || prevSteps[0] === STEPS.userIdentify || prevSteps[0] === STEPS.userSizeCompany || prevSteps[0] === STEPS.userDesRole || prevSteps[0] === STEPS.userRevenue


     return (
          <CreateAccountBody onBackBtnClicked={null} hideStepProgress={true}>
               <div>
                    <CCard className='account-see-how-it-work'>
                         <CCardBody>
                              <h3>Thank You!</h3>
                              <p className='des'>
                                   Your meeting has been scheduled. Check your email, an invitation was sent with details and will be added to your calendar.
                              </p>
                              {
                                   isAccountSetup ?
                                        (<CButton className="btn-save h-auto btn-green" style={{ maxWidth: '220px', marginTop: '0px', padding: '10px' }} onClick={() => onClickCreateAccount()}>
                                             <p>CONTINUE</p>
                                        </CButton>) :
                                        (<CButton className="btn-save h-auto btn-green" style={{ maxWidth: '220px', marginTop: '0px' }} onClick={() => onClickCreateAccount()}>
                                             <p>CREATE AN ACCOUNT</p>
                                             <span>Get started immediately</span>
                                        </CButton>)
                              }

                         </CCardBody>
                    </CCard>
               </div>
          </CreateAccountBody>
     );
};

export default ThankYouDemo;
