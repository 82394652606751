import React from 'react'
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { signout, isAuth } from '../../helpers/auth';

import SupportASide from './TheAside';
import SupportContent from './TheContent';
import SupportFooter from './TheFooter';
import SupportHeader from './TheHeader';
import SupportSidebar from './TheSidebar';
import SupportPusher from './ThePusher';

const SupportLayout = (props) => {
     const darkMode = useSelector(state => state.theme.darkMode);

     const classes = classNames(
          'admin c-app c-default-layout',
          { 'c-dark-theme': darkMode },
     )

     return (
          <div className={classes}>
               <SupportSidebar />
               <SupportASide />
               <div className="c-wrapper">
                    <SupportHeader {...props} />
                    <div className="c-body">
                         <SupportContent />
                    </div>
                    <SupportFooter />
               </div>
               <SupportPusher />
               {!isAuth() && signout(true)}
          </div>
     )
}

export default SupportLayout;
