import CIcon from '@coreui/icons-react';
import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react';
import React from 'react';
import { CUSTOM_FORM_TARGET_RULE_OPERATORS } from '../../../../../../../constants';
import NewGroup from '../../../custom-data-layer-variables/simple/NewGroup';
import { CUSTOM_FORM_INITIAL_RULE_ROW } from './TargetForm';
import { CInput, CSelect } from '../../../../../../migration/CInput';
import { CInvalidFeedback } from '../../../../../../migration/CInvalidFeedback';
import CLabel from '../../../../../../migration/CLabel';
const initialGroup = {
     attribute: '',
     operator: '',
     value: '',
     conjunction: '',
};
const Groups = ({ errors, setErrors, newGroupsState, setNewGroupsState }) => {

     const changeAValueOfGroup = ({ lvl1GroupIndex, lvl2GroupIndex, propName, propValue }) => {
          let newGroups = [...newGroupsState];
          newGroups[lvl1GroupIndex][`g${lvl2GroupIndex}`][propName] = propValue;

          let newErrors = [...errors];
          if (newErrors[lvl1GroupIndex]) {
               delete newErrors[lvl1GroupIndex].group;
               if (newErrors[lvl1GroupIndex][`g${lvl2GroupIndex}`]) {
                    delete newErrors[lvl1GroupIndex][`g${lvl2GroupIndex}`].row;
                    delete newErrors[lvl1GroupIndex][`g${lvl2GroupIndex}`][propName];
               }
          }

          setErrors(newErrors);
          setNewGroupsState(newGroups)
          // dispatch(setCustomFormTargetRules(newGroups));
          setNewGroupsState(newGroups)
     };

     const deleteLvl1Group = (lvl1GroupIndex) => {
          let newGroups = [...newGroupsState];

          // Remove the last group (last group's conjunction is empty), then set the previous group's conjunction to empty
          if (!newGroups[lvl1GroupIndex].conjunction) {
               newGroups[lvl1GroupIndex - 1].conjunction = '';
          } else if (newGroups[lvl1GroupIndex - 1]) {
               newGroups[lvl1GroupIndex - 1].conjunction = newGroups[lvl1GroupIndex].conjunction;
          }
          newGroups.splice(lvl1GroupIndex, 1);
          setNewGroupsState(newGroups)
          // dispatch(setCustomFormTargetRules(newGroups));
          setNewGroupsState(newGroups)
          setErrors(prev => prev.filter((_, index) => index !== lvl1GroupIndex));
     };

     const deleteLvl2Group = (lvl1GroupIndex, lvl2GroupIndex) => {
          let newGroups = [...newGroupsState];
          let newGroup = { ...newGroups[lvl1GroupIndex] };
          let newGroupConjunction = newGroup.conjunction;
          delete newGroup.conjunction;
          const newGroupLength = Object.keys(newGroup).length;

          // Remove the last group (last group's conjunction is empty), then set the previous group's conjunction to empty
          if (!newGroup[`g${lvl2GroupIndex}`].conjunction && lvl2GroupIndex !== 0) {
               newGroup[`g${lvl2GroupIndex - 1}`].conjunction = '';
          }

          for (let i = lvl2GroupIndex; i < newGroupLength; i++) {
               newGroup[`g${i}`] = { ...newGroup[`g${i + 1}`] };
          }

          delete newGroup[`g${newGroupLength - 1}`];
          newGroup.conjunction = newGroupConjunction;
          newGroups[lvl1GroupIndex] = { ...newGroup };

          // dispatch(setCustomFormTargetRules(newGroups));
          setNewGroupsState(newGroups)
          setErrors([]);
     };

     const updateLvl1Conjunction = (lvl1GroupIndex, conjunction) => {
          let newGroups = [...newGroupsState];

          newGroups[lvl1GroupIndex - 1].conjunction = conjunction;
          // dispatch(setCustomFormTargetRules(newGroups));
          setNewGroupsState(newGroups)
     };

     const updateLvl2Conjunction = ({ lvl1GroupIndex, conjunction }) => {
          const newGroups = [...newGroupsState];

          let newGroupsUpdate = { ...newGroups[lvl1GroupIndex] };

          delete newGroupsUpdate.conjunction;

          Object.keys(newGroupsUpdate).forEach((key, index) => {
               if (index < Object.keys(newGroupsUpdate).length - 1) {
                    newGroupsUpdate[key].conjunction = conjunction;
               }
          });

          newGroups[lvl1GroupIndex] = { ...newGroupsState[lvl1GroupIndex], ...newGroupsUpdate };

          // dispatch(setCustomFormTargetRules(newGroups));
          setNewGroupsState(newGroups)
     };

     const selectLvl1Conjunction = (lvl1GroupIndex, conjunction) => {
          let newGroups = [...newGroupsState];

          if (!newGroups[lvl1GroupIndex].conjunction) {
               newGroups.push({
                    conjunction: '',
                    g0: { ...CUSTOM_FORM_INITIAL_RULE_ROW },
               });
          }

          newGroups[lvl1GroupIndex].conjunction = conjunction;
          // dispatch(setCustomFormTargetRules(newGroups));
          setNewGroupsState(newGroups)
     };

     const addLvl2Conjunction = ({ lvl1GroupIndex, lvl2GroupIndex, conjunction }) => {
          let newGroups = [...newGroupsState];
          if (!newGroups[lvl1GroupIndex][`g0`].conjunction) {
               newGroups[lvl1GroupIndex][`g0`].conjunction = 'and';
          }
          if (!newGroups[lvl1GroupIndex][`g${lvl2GroupIndex}`].conjunction) {
               newGroups[lvl1GroupIndex][`g${lvl2GroupIndex}`].conjunction = conjunction;
          }
          newGroups[lvl1GroupIndex][`g${lvl2GroupIndex + 1}`] = { ...initialGroup };
          // dispatch(setCustomFormTargetRules(newGroups));
          setNewGroupsState(newGroups)
          setErrors([]);
     };

     return (
          <>
               {newGroupsState && newGroupsState.map((lvl1Group, lvl1GroupIndex) => {
                    let workingLvl1Group = { ...lvl1Group };
                    delete workingLvl1Group.conjunction;
                    const lvl1GroupConjunction = lvl1GroupIndex > 0 ? newGroupsState[lvl1GroupIndex - 1].conjunction : '';
                    const groupDupplicate = errors[lvl1GroupIndex] && errors[lvl1GroupIndex].group;

                    return (
                         <React.Fragment key={lvl1GroupIndex}>
                              <CCard className='conditions-group conditions-validate identify-target-custom-forms'>
                                   {/* {lvl1GroupIndex > 0 && ( */}
                                   <CCardHeader className='d-flex align-items-center justify-content-between'>
                                        <div className='d-flex align-items-center'>
                                             <h6>{`Rule Group ${lvl1GroupIndex + 1}`}</h6>
                                             {lvl1GroupIndex > 0 && (
                                                  <div className='d-flex align-items-center button-new-rule'>
                                                       <CButton
                                                            className={`btn-add-or-rule ${lvl1GroupConjunction === 'and' ? 'active' : ''}`}
                                                            onClick={() => updateLvl1Conjunction(lvl1GroupIndex, 'and')}
                                                       >
                                                            AND
                                                       </CButton>
                                                       <CButton
                                                            className={`btn-add-or-rule ${lvl1GroupConjunction === 'or' ? 'active' : ''}`}
                                                            onClick={() => updateLvl1Conjunction(lvl1GroupIndex, 'or')}
                                                       >
                                                            OR
                                                       </CButton>
                                                       match this group
                                                  </div>
                                             )}
                                        </div>
                                        {newGroupsState && newGroupsState.length > 1 && (
                                             <CButton className='remove-group' onClick={() => deleteLvl1Group(lvl1GroupIndex)}>
                                                  Remove Group
                                             </CButton>
                                        )}
                                   </CCardHeader>
                                   {/* )} */}

                                   <CCardBody>
                                        <div className='rules'>
                                             {Object.entries(workingLvl1Group).map(([key, lvl2Group], lvl2GroupIndex) => {
                                                  const lvl2GroupConjunction = lvl1Group[`g0`].conjunction || '';
                                                  const errorLvl2Group =
                                                       errors[lvl1GroupIndex] && errors[lvl1GroupIndex][key] ? errors[lvl1GroupIndex][key] : {};
                                                  const rowDupplicateValidate = errorLvl2Group.row;
                                                  const isLastRow = key === `g${Object.entries(workingLvl1Group).length - 1}`;
                                                  const isValidate = rowDupplicateValidate || (isLastRow && groupDupplicate);
                                                  // const checkHideGroupDefault =
                                                  //      (lvl2Group.attribute === 'CSS Class' && key === 'g0') ||
                                                  //      (lvl2Group.attribute === 'CSS ID' && key === 'g1');

                                                  return (
                                                       <React.Fragment key={key}>
                                                            {lvl2GroupIndex === 0 && (
                                                                 <div className='conditions-match-header d-flex align-items-center mb-2'>
                                                                      <CLabel className='mb-0'>Match</CLabel>
                                                                      <div className='button-new-rule ml-1'>
                                                                           <CButton
                                                                                className={`btn-all-or-any ${lvl2GroupConjunction === 'and' ? 'active' : ''
                                                                                     }`}
                                                                                onClick={() =>
                                                                                     updateLvl2Conjunction({
                                                                                          lvl1GroupIndex,
                                                                                          conjunction: 'and',
                                                                                     })
                                                                                }
                                                                           >
                                                                                All
                                                                           </CButton>
                                                                           <CButton
                                                                                className={`btn-all-or-any ${lvl2GroupConjunction === 'or' ? 'active' : ''
                                                                                     }`}
                                                                                onClick={() =>
                                                                                     updateLvl2Conjunction({
                                                                                          lvl1GroupIndex,
                                                                                          conjunction: 'or',
                                                                                     })
                                                                                }
                                                                           >
                                                                                Any
                                                                           </CButton>
                                                                      </div>
                                                                      <CLabel className='mb-0'>conditions in this group.</CLabel>
                                                                 </div>
                                                            )}
                                                            {/* {!checkHideGroupDefault && ( */}
                                                            <div
                                                                 className={`mb-3 conditions-match d-flex align-items-top${!!isValidate ? ' is-invalid' : ''
                                                                      }`}
                                                            >
                                                                 <div className='rule-col rule-col-attribute'>
                                                                      {lvl2GroupIndex === 0 && <CLabel>Attribute</CLabel>}
                                                                      <CSelect
                                                                           value={lvl2Group.attribute}
                                                                           onChange={(e) =>
                                                                                changeAValueOfGroup({
                                                                                     lvl1GroupIndex,
                                                                                     lvl2GroupIndex,
                                                                                     propName: 'attribute',
                                                                                     propValue: e.target.value,
                                                                                })
                                                                           }
                                                                           className={`${!lvl2Group.attribute ? 'not-selected' : ''}`}
                                                                           invalid={!!errorLvl2Group.attribute}
                                                                      >
                                                                           <option value='' disabled hidden>
                                                                                Select
                                                                           </option>
                                                                           <option value='CSS ID'>CSS ID</option>
                                                                           <option value='CSS Class'>CSS Class</option>
                                                                           <option value='Name'>Name</option>
                                                                      </CSelect>
                                                                      <CInvalidFeedback>{errorLvl2Group.attribute}</CInvalidFeedback>
                                                                 </div>
                                                                 <div className='rule-col rule-col-operator'>
                                                                      {lvl2GroupIndex === 0 && <CLabel>Operator</CLabel>}
                                                                      <CSelect
                                                                           value={lvl2Group.operator}
                                                                           onChange={(e) =>
                                                                                changeAValueOfGroup({
                                                                                     lvl1GroupIndex,
                                                                                     lvl2GroupIndex,
                                                                                     propName: 'operator',
                                                                                     propValue: e.target.value,
                                                                                })
                                                                           }
                                                                           invalid={!!errorLvl2Group.operator}
                                                                      >
                                                                           <option value='' disabled hidden>
                                                                                Select
                                                                           </option>
                                                                           {CUSTOM_FORM_TARGET_RULE_OPERATORS.map(({ VALUE, TEXT }) => (
                                                                                <option key={VALUE} value={VALUE}>
                                                                                     {TEXT}
                                                                                </option>
                                                                           ))}
                                                                      </CSelect>
                                                                      <CInvalidFeedback>{errorLvl2Group.operator}</CInvalidFeedback>
                                                                 </div>
                                                                 <div className='rule-col rule-col-value'>
                                                                      {lvl2GroupIndex === 0 && <CLabel>Value</CLabel>}
                                                                      <CInput
                                                                           placeholder='value'
                                                                           value={lvl2Group.value}
                                                                           onChange={(e) =>
                                                                                changeAValueOfGroup({
                                                                                     lvl1GroupIndex,
                                                                                     lvl2GroupIndex,
                                                                                     propName: 'value',
                                                                                     propValue: e.target.value,
                                                                                })
                                                                           }
                                                                           data-lpignore='true'
                                                                           invalid={!!errorLvl2Group.value}
                                                                      />
                                                                      <CInvalidFeedback>{errorLvl2Group.value}</CInvalidFeedback>
                                                                 </div>
                                                                 <div className={`button-delete-rule`}>
                                                                      {Object.keys(workingLvl1Group).length > 1 && (
                                                                           <CIcon
                                                                                icon='iconDeleteField'
                                                                                className='icon-delete'
                                                                                onClick={() => deleteLvl2Group(lvl1GroupIndex, lvl2GroupIndex)}
                                                                           />
                                                                      )}
                                                                 </div>
                                                            </div>
                                                            {/* )} */}

                                                            <CInvalidFeedback>{rowDupplicateValidate}</CInvalidFeedback>
                                                            {isLastRow && <CInvalidFeedback>{groupDupplicate}</CInvalidFeedback>}
                                                            {lvl2GroupIndex === Object.entries(workingLvl1Group).length - 1 && (
                                                                 <>
                                                                      <CButton
                                                                           className='btn-add-row'
                                                                           onClick={() =>
                                                                                addLvl2Conjunction({
                                                                                     lvl1GroupIndex,
                                                                                     lvl2GroupIndex,
                                                                                     conjunction: lvl2GroupConjunction,
                                                                                })
                                                                           }
                                                                      >
                                                                           <CIcon icon='iconAddField' className='icon-add' />
                                                                           <CLabel className='add-row'>ADD ROW</CLabel>
                                                                      </CButton>
                                                                 </>
                                                            )}
                                                       </React.Fragment>
                                                  );
                                             })}
                                        </div>
                                   </CCardBody>
                              </CCard>
                              {lvl1GroupIndex === newGroupsState.length - 1 && (
                                   <NewGroup col={7} lvl1GroupIndex={lvl1GroupIndex} selectLvl1Conjunction={selectLvl1Conjunction} />
                              )}
                         </React.Fragment>
                    );
               })}
          </>
     );
};

export default Groups;
