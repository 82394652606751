import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { tagPropType } from './utils/helper'

//component - CoreUI / CSubheader
const CSubheader = props => {

  const {
    tag= 'div',
    className,
    //
    innerRef,
    ...attributes
  } = props

  //render
  const classes = classNames(
    'c-subheader',
    className
  )

  const Tag = tag;

  return (
    <Tag className={classes} {...attributes} ref={innerRef}/>
  )
}

CSubheader.propTypes = {
  tag: tagPropType,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  //
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

export default CSubheader
