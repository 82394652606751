import React, { useContext } from 'react';

import { EventTableContext } from './EventTable';
import EventTableChildren from './EventTableChildren';

const EventTableLayout = () => {
    const { eventStatistics } = useContext(EventTableContext);

    return (
        <div className="datalayer-variables">
            <div className="css-table">
                <div className="css-table-header">
                    <div>Event Name</div>
                    {/* <div>Reporting Values</div> */}
                    <div>Variable Name</div>
                    <div>Event Count</div>
                    <div>Users Count</div>
                    <div>Sessions Count</div>
                    <div></div>
                </div>
                <div className="css-table-body">
                    <EventTableChildren
                        variables={eventStatistics}
                        level={0}
                    />
                </div>
            </div>
        </div>

    )
}
export default EventTableLayout