import CIcon from '@coreui/icons-react';
import { CButton, CProgress, CSpinner } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAccountPreview, setDomainPreview, setDomains, setDomainsPreview, setUsedItems, setSubscriberState, setActiveAccount, setListFeatureCustomForms } from '../../../actions/subscriber';
import { callTokenApi, makeRequest } from '../../../apiCaller';
import {
     API_VERSION_PUBLISH,
     API_VERSION_WAITING,
     BROWSER_ACTION,
     LOCAL_PREVIEW_DOMAIN,
     NOTICE_TYPE,
     UPDATE_DOMAIN_ACCOUNT,
} from '../../../constants';
import { getStaticJSFile, removeDuplicateValueInArray, toastError, tryParseJSON } from '../../../utils';
import NoticeWebsite from './NoticeWebsite';
import PopupHasAction from './PopupHasAction';
import { useIsPreviewDomainIframeBlocked } from '../../../helpers/customHooks';
import classNames from 'classnames';
import CLabel from '../../../components/migration/CLabel';

export const CreateTheWebsiteContext = React.createContext({});

const TheParameterWebsite = ({
     locationHref,
     handleUrlChange,
     handleInputEnter,
     reloadIframe,
     setNavigateType,
     urlHistory,
     urlIndex,
     setUrlIndex,
     // setDomainInIframe,
}) => {
     const { iframePageLoading } = useSelector((state) => state.subscriber.preview);
     const goBackDisabled = iframePageLoading || urlIndex <= 0;
     const goForwardDisabled = iframePageLoading || urlIndex === urlHistory.length - 1;
     const reloadDisabled = iframePageLoading;

     const handleKeyDown = (event) => {
          if (event.key === 'Enter') {
               handleInputEnter(event.target.value);
          }
     };

     const navigateIframe = ({ newUrlIndex, navigateType }) => {
          const MyIFrame = document.getElementById('llPreviewTheme');
          setNavigateType(navigateType);

          let currentLocation = new URL(urlHistory[urlIndex]);
          let newLocation = new URL(urlHistory[newUrlIndex]);

          setUrlIndex(newUrlIndex);
          handleUrlChange(urlHistory[newUrlIndex]);

          if (currentLocation.hostname === newLocation.hostname) {
               if (typeof MyIFrame.contentWindow.postMessage !== 'undefined') {
                    MyIFrame.contentWindow.postMessage({ type: BROWSER_ACTION.KEY, action: navigateType }, '*');
               }
          } else {
               MyIFrame.src = urlHistory[newUrlIndex];
               // setDomainInIframe(urlHistory[newUrlIndex]);
          }
     };

     const goBackIframe = () => {
          const newUrlIndex = urlIndex - 1;
          navigateIframe({ newUrlIndex, navigateType: BROWSER_ACTION.BACK });
     };

     const goForwardIframe = () => {
          const newUrlIndex = urlIndex + 1;
          navigateIframe({ newUrlIndex, navigateType: BROWSER_ACTION.FORWARD });
     };

     return (
          <div className='website-parameter'>
               <div
                    className={classNames('icon-back', { disable: goBackDisabled })}
                    title='Click to go back'
                    id='iconBack'
                    onClick={goBackDisabled ? null : goBackIframe}
               >
                    <i className='fa fa-arrow-left' aria-hidden='true'></i>
               </div>
               <div
                    className={classNames('icon-next', { disable: goForwardDisabled })}
                    title='Click to go forward'
                    id='iconNext'
                    onClick={goForwardDisabled ? null : goForwardIframe}
               >
                    <i className='fa fa-arrow-right' aria-hidden='true'></i>
               </div>
               <div
                    className={classNames('icon-reload', { disable: reloadDisabled })}
                    id='reloadPage'
                    title='Reload this page'
                    onClick={reloadDisabled ? null : reloadIframe}
               >
                    <CIcon icon='iconReload' />
                    <CIcon id='iconClose' icon='cil-x' />
               </div>
               <div className='urlBar-holster'>
                    <CLabel className='urlBar-inputWrapper'>
                         <span className='urlBar-lockIcon'>
                              <CIcon id='iconLock' icon='iconLock' />
                         </span>
                         <input
                              id='myInput'
                              className='urlBar-textInput'
                              type='text'
                              value={locationHref}
                              onChange={(e) => handleUrlChange(e.target.value)}
                              onKeyDown={handleKeyDown}
                              autoComplete='off'
                         />
                         <div className='urlBar-inputRender'>
                              <span className='urlBar-highlight'>{locationHref}</span>
                              <span className='urlBar-secondary'></span>
                         </div>
                    </CLabel>
               </div>
          </div>
     );
};

const TheWebsite = ({ keyIframe, domainInIframe, isSplitPaneDragging, handleAccountWarning }) => {
     const dispatch = useDispatch();
     const { domain, iframeLoading } = useSelector((state) => state.subscriber.preview);

     const [toggleIframeLoad, setToggleIframeLoad] = useState();

     const onIframeLoad = () => {
          setToggleIframeLoad((s) => !s);
          dispatch(setAccountPreview({ iframeLoading: false, iframePageLoading: false }));
     };

     useEffect(() => {
          if (typeof toggleIframeLoad !== 'undefined') {
               handleAccountWarning();
          }
     }, [toggleIframeLoad]); // eslint-disable-line

     return (
          <div className='the-website-preview'>
               {iframeLoading && domainInIframe && (
                    <div className='loading h-100 d-flex justify-content-center align-items-center'>
                         <CSpinner size='md' color='primary' />
                    </div>
               )}

               {domainInIframe ? (
                    <iframe
                         title='LL Preview'
                         id='llPreviewTheme'
                         key={keyIframe}
                         src={domainInIframe}
                         width='100%'
                         height='100vh'
                         display='initial'
                         position='relative'
                         frameBorder='0'
                         onLoad={onIframeLoad}
                         allow={`geolocation https://${domain}`}
                         style={{ pointerEvents: isSplitPaneDragging ? 'none' : 'auto' }}
                    />
               ) : (
                    <div className='content-reload'>
                         <h5>ListenLayer</h5>
                         <p>Preview My Website</p>
                         <CProgress animated color='dark' value={100} size='md' />
                    </div>
               )}
          </div>
     );
};

const HandleNewTab = ({ handleAccountWarning, openedWindow }) => {
     const dispatch = useDispatch();

     const handFirstLoad = () => {
          if (openedWindow) {
               dispatch(setAccountPreview({ iframeLoading: false, iframePageLoading: false }));
               handleAccountWarning();
          }
     };

     useEffect(handFirstLoad, [openedWindow]); // eslint-disable-line react-hooks/exhaustive-deps

     return <></>;
};

const usePreviewNavigate = ({ urlHistory, setUrlHistory, urlIndex, setUrlIndex, setLocationHref }) => {
     const dispatch = useDispatch();
     // const { iframePageLoading } = useSelector(state => state.subscriber.preview);
     const [navigateType, setNavigateType] = useState('');

     const handleListenToNavigateMessage = () => {
          const newSetEvent = (e) => {
               const data = tryParseJSON(e.data);

               if (data) {
                    if (data.type === 'llIframeLocation') {
                         switch (navigateType) {
                              case BROWSER_ACTION.BACK:
                              case BROWSER_ACTION.RELOAD:
                              case BROWSER_ACTION.FORWARD:
                                   // If click back, forward or reload button on url bar, break so it can't add url to history
                                   break;
                              default:
                                   // let newUrl = data.href.replace(`?${defaultUrlParams}`, '').replace(`&${defaultUrlParams}`, '');
                                   let newUrl = data.href;

                                   setLocationHref(newUrl);

                                   if (urlHistory.length > 0 && newUrl === urlHistory[urlHistory.length - 1]) {
                                        setUrlIndex(urlHistory.length - 1);
                                        break;
                                   }

                                   if (urlIndex === -1 || urlIndex === urlHistory.length - 1) {
                                        setUrlHistory([...urlHistory, newUrl]);
                                        setUrlIndex(urlIndex + 1);
                                   } else if (urlIndex < urlHistory.length - 1) {
                                        let newUrlHistory = [...urlHistory.slice(0, urlIndex + 1), newUrl];
                                        setUrlHistory(newUrlHistory);
                                        setUrlIndex(newUrlHistory.length - 1);
                                   }
                         }

                         dispatch(setAccountPreview({ iframePageLoading: false }));
                         setNavigateType('');
                    }

                    if (data.type === 'llIframeUnload') {
                         dispatch(setAccountPreview({ iframePageLoading: true }));
                    }
               }
          };

          window.addEventListener('message', newSetEvent);
          return () => window.removeEventListener('message', newSetEvent);
     };

     useEffect(handleListenToNavigateMessage, [navigateType, urlHistory, urlIndex]); // eslint-disable-line react-hooks/exhaustive-deps

     // If there's no other cases set iframePageLoading = false, need this
     // const handleIframePageLoading = () => {
     //      if (iframePageLoading) {
     //           const theTimeOut = setTimeout(() => {
     //                dispatch(setAccountPreview({ iframePageLoading: false }));
     //           }, 4000);

     //           return () => clearTimeout(theTimeOut);
     //      }
     // }

     // useEffect(handleIframePageLoading, [iframePageLoading]);

     return { navigateType, setNavigateType };
};

/**
 * listenerWarning
 * listenerComplete
 * domainWarning
 * warningScript
 * @returns  component notice
 */

const ThePreviewWebsite = (props) => {
     const dispatch = useDispatch();
     const {
          sessionId,
          keyIframe,
          reloadIframe,
          eventsPreview,
          setShowPopupEndTest,
          resetPreview,
          beginTest,
          openedWindow,
          setOpenedWindow,
          previewListener,
          isSplitPaneDragging,
     } = props;
     const { domains, domain, initDomains, listenerId, noticeType, iframeLoading, browserStorageKey } = useSelector((state) => state.subscriber.preview);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountListeners = useSelector((state) => state.subscriber.accountListeners);
     const activeListeners = useSelector((state) => state.subscriber.activeListeners);
     const activeFeatures = useSelector((state) => state.subscriber.activeFeatures);
     const nativeBrowserStorage = useSelector((state) => state.subscriber.nativeBrowserStorage);
     const eventsNative = useSelector((state) => state.subscriber.listenerEventsNative);
     const listenerEvents = useSelector((state) => state.subscriber.listenerEvents);
     const { initialEvents } = listenerEvents;

     const activeAccountId = activeAccount.id || JSON.parse(localStorage.getItem('activeAccount')).id;
     const isPreviewDomainIframeBlocked = useIsPreviewDomainIframeBlocked();
     const currentTestingListener = activeListeners.find((listener) => listener.id === listenerId);
     const numberOfFeatureEnable =
          (listenerId && activeFeatures.filter((feature) => feature.featureType === 'event' && feature.listenerId === listenerId).map((el) => el.code)) || [];
     const eventsPreviewOfTestListener = (listenerId && eventsPreview.filter((el) => el.listenerId === listenerId)) || [];

     const [domainSelected, setDomainSelected] = useState(domain);
     const [locationHref, setLocationHref] = useState('');
     const [domainInIframe, setDomainInIframe] = useState('');
     const [showPopup, setShowPopup] = useState(false);
     const [actionType, setActionType] = useState('');
     const [urlPath, setUrlPath] = useState(domain);
     const [previewFullURL, setPreviewFullURL] = useState('');
     const [showReopenMessage, setShowReopenMessage] = useState(false);
     const [urlHistory, setUrlHistory] = useState([]);
     const [urlIndex, setUrlIndex] = useState(-1);
     const [websitesHaveScript, setDomainsHaveScript] = useState([]);
     // const [defaultUrlParams, setDefaultUrlParams] = useState('');

     const { setNavigateType } = usePreviewNavigate({ locationHref, urlHistory, setUrlHistory, urlIndex, setUrlIndex, setLocationHref });

     const listenerCode = currentTestingListener ? currentTestingListener.code : null;
     const { initialEvents: initialEventsBrowserStorage } = nativeBrowserStorage;
     const { initialEventsNative } = eventsNative;
     const isNativeBrowserStorage = listenerCode && listenerCode === 'nativeBrowserStorage' ? true : false;
     const isNativeDataLayers = listenerCode && listenerCode === 'nativeDataLayers' ? true : false;
     const listFeatures = [];
     if (isNativeBrowserStorage && initialEventsBrowserStorage) {
          initialEventsBrowserStorage.forEach((event) => {
               if (event.nativeId) {
                    listFeatures.push({
                         name: event.name,
                         fired: eventsPreviewOfTestListener.find((e) => e.fullCode.nativeName === event.name) ? true : false,
                    });
               }
          });
     } else if (isNativeDataLayers && initialEventsNative) {
          initialEventsNative.forEach((event) => {
               if (event.value) {
                    listFeatures.push({
                         name: event.value,
                         fired: eventsPreviewOfTestListener.find((e) => e.fullCode.nativeEventName === event.value) ? true : false,
                    });
               }
          });
     } else {
          removeDuplicateValueInArray(numberOfFeatureEnable).forEach((event) => {
               const customEventName = initialEvents.find((ie) => ie.code === event) ? initialEvents.find((ie) => ie.code === event).customEventName : null;
               const listEventsFired = eventsPreviewOfTestListener.filter((e) => e.fullCode.eventOriginal === event || e.event === event);

               listFeatures.push({
                    name: customEventName || event,
                    fired: ['urlParameters', 'userSource'].includes(listenerCode) ? listEventsFired.length >= 2 : listEventsFired.length > 0,
               });
          });
     }

     const handleVerifyDomain = async () => {
          const scriptJsonRes = await makeRequest(getStaticJSFile(activeAccountId));

          if (scriptJsonRes.status !== 200) {
               dispatch(setAccountPreview({ noticeType: NOTICE_TYPE.SCRIPT_WARNING }));
          }
     };

     useEffect(() => {
          if (!eventsPreview.length) {
               setTimeout(() => {
                    const MyIFrame = document.getElementById('llPreviewTheme');
                    if (MyIFrame && typeof MyIFrame.contentWindow.postMessage !== 'undefined') {
                         MyIFrame.contentWindow.postMessage({ type: 'resetPreview', browserStorageKey }, '*');
                    }
               }, 100);
          }

          let newNoticeType = !!domains.find((d) => d.value === domainSelected) ? '' : NOTICE_TYPE.DOMAIN_WARNING;

          if (listenerId) {
               const listenerComplete = listFeatures.length > 0 && listFeatures.filter((el) => el.fired).length === listFeatures.length;

               if (listenerComplete) {
                    newNoticeType = NOTICE_TYPE.LISTENER_COMPLETED;
               } else if (noticeType !== NOTICE_TYPE.LISTENER_COMPLETED) {
                    newNoticeType = NOTICE_TYPE.LISTENER_WARNING;
               }

               if (listFeatures.filter(event => event.fired).length > 0) {
                    dispatch(setListFeatureCustomForms(listFeatures));
               } 
          }
          dispatch(setAccountPreview({ noticeType: newNoticeType }));
     }, [eventsPreview, previewListener]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          const llLocationOrigin = domain && domain.includes('.local') ? `http://${domain}` : `https://${domain}`;
          const slug = `previewLLSession=${sessionId}`;
          let url = `${llLocationOrigin}?${slug}`;

          // setLocationHref(llLocationOrigin);
          // setDefaultUrlParams(newDefaultUrlParams);
          setPreviewFullURL(url);
          dispatch(
               setSubscriberState({
                    previewUrl: url,
               })
          );
          handleVerifyDomain();

          const newSetEvent = (e) => {
               let newAccountPreview = {};

               if (iframeLoading) {
                    newAccountPreview.iframeLoading = false;
               }
               if (e.data.type === 'listenlayerListenerEventWarning') {
                    newAccountPreview.noticeType = NOTICE_TYPE.LISTENER_WARNING;
               }

               if (e.data.type === 'cross-domain-error') {
                    newAccountPreview.noticeType = NOTICE_TYPE.DOMAIN_WARNING;
                    setDomainSelected(e.data.domain);
               }

               if (e.data.type === 'pass-domain') {
                    newAccountPreview.noticeType = '';
                    setDomainSelected(e.data.domain);
               }

               if (Object.keys(newAccountPreview).length > 0) {
                    dispatch(setAccountPreview(newAccountPreview));
               }
          };

          window.addEventListener('message', newSetEvent);
          return () => window.removeEventListener('message', newSetEvent);
     }, [listenerId, activeAccountId, domains, accountListeners]); // eslint-disable-line react-hooks/exhaustive-deps

     const openPreviewOnNewTab = () => {
          if (previewFullURL) {
               setOpenedWindow(window.open(previewFullURL, 'LL Preview'));
          }
     };

     const handleURL = () => {
          if (isPreviewDomainIframeBlocked) {
               if (beginTest) {
                    openPreviewOnNewTab();
               }
          } else {
               setOpenedWindow(null);
               setLocationHref(previewFullURL);
               setDomainInIframe(previewFullURL);

               if (listenerId) {
                    dispatch(setAccountPreview({ noticeType: NOTICE_TYPE.LISTENER_WARNING }));
               }
          }
     };

     useEffect(handleURL, [beginTest, previewFullURL]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleUrlChange = (value) => {
          setLocationHref(value);
     };

     const handleInputEnter = (href) => {
          let _hostname = href;

          if (href.includes('https://') || href.includes('http://')) {
               const { hostname } = new URL(href);

               _hostname = hostname;
          }

          const llLocationPath = href.replace('http:', '').replace('https:', '').replace('//www.', '').replace('//', '');

          setUrlPath(llLocationPath);

          const noticeType = !!domains.find((d) => d.value === _hostname) ? '' : NOTICE_TYPE.DOMAIN_WARNING;

          dispatch(setAccountPreview({ noticeType }));

          const slug = `previewLLSession=${sessionId}`;
          const newPreviewFullURL = `${href}${href.includes('?') ? '&' : '?'}${slug}`;

          setPreviewFullURL(newPreviewFullURL);
          // setLocationHref(href);
          dispatch(setDomainPreview(_hostname));
          window.postMessage(JSON.stringify({ type: 'llIframeLocation', href: newPreviewFullURL })); // Add new url to iframe url history
          localStorage.setItem(LOCAL_PREVIEW_DOMAIN, _hostname);
     };

     const handleAddDomain = () => {
          setActionType('loading');

          let listDomain = initDomains.map((item) => ({
               name: item.name,
               scriptInstalled: item.scriptInstalled,
               verificationDisabled: item.verificationDisabled || false,
               isIframeBlocked: item.isIframeBlocked,
          }));

          listDomain.push({ name: domainSelected, scriptInstalled: false, verificationDisabled: false, isIframeBlocked: isPreviewDomainIframeBlocked, isCSPBlocked: false });

          let data = {
               accountId: activeAccount.id,
               domains: listDomain,
               actions: [
                    {
                         action: 'add',
                         domainName: domainSelected,
                         domain: domainSelected
                    },
               ],
          };

          callTokenApi(`${UPDATE_DOMAIN_ACCOUNT}`, 'POST', data)
               .then(async (response) => {
                    if (response.status === 200) {
                         let initialDomains = [],
                              listDisabled = [];

                         listDomain.forEach((item, index) => {
                              initialDomains.push({
                                   id: `savedDomain${index + 1}`,
                                   name: item.name,
                                   scriptInstalled: item.scriptInstalled,
                                   tempScriptInstalled: item.scriptInstalled,
                                   verificationDisabled: item.verificationDisabled,
                                   isIframeBlocked: item.isIframeBlocked,
                                   isCSPBlocked: item.isCSPBlocked,
                              });
                              listDisabled.push(true);
                         });

                         dispatch(
                              setDomains({
                                   ...listDomain,
                                   initialDomains,
                                   addedDomains: [],
                                   editedDomains: [],
                                   listDisabled,
                                   numOfDomains: listDomain.length,
                              })
                         );

                         let newActiveAccount = { ...activeAccount, domains: JSON.stringify(listDomain), numberOfDomains: listDomain.length };

                         dispatch(setDomainsPreview(listDomain));
                         dispatch(setAccountPreview({ noticeType: '' }));
                         dispatch(setActiveAccount(newActiveAccount));

                         const usedItems = {
                              itemName: 'numberOfDomains',
                              quantity: listDomain.length,
                         };

                         dispatch(setUsedItems(usedItems));

                         const versionPostData = {
                              accountId: activeAccount.id,
                              status: 'waiting',
                         };
                         let waitingVersion = await callTokenApi(`${API_VERSION_WAITING}`, 'POST', versionPostData);

                         if (waitingVersion.status === 200) {
                              const publishPostData = {
                                   accountId: activeAccount.id,
                                   versionId: waitingVersion.data.version.id,
                                   name: 'Add Domain',
                                   description: '',
                              };

                              const publishSuccess = await callTokenApi(API_VERSION_PUBLISH, 'POST', publishPostData);
                              window._noPublishToast = true; // Don't toast message in ThePusher
                              if (publishSuccess.status === 200) {
                                   setActionType('addDomainSuccess');
                              }
                         }
                    } else {
                         toastError(response);
                         if (response.status === 400) {
                              setActionType('upgrade');
                         }
                    }
               })
               .finally();
     };

     const handleCheckOpenedWindow = () => {
          const openedWindowInterval = setInterval(() => {
               if (openedWindow) {
                    setShowReopenMessage(openedWindow.closed);
               }
          }, 500);

          return () => {
               clearInterval(openedWindowInterval);
               setShowReopenMessage(false);
          };
     };

     useEffect(handleCheckOpenedWindow, [openedWindow]);

     const handleAccountWarning = () => {
          let accountWarningTimeout;
          let doesWebsiteHaveThisScript = websitesHaveScript.some((el) => locationHref.includes(el));

          if (doesWebsiteHaveThisScript) {
               dispatch(setAccountPreview({ accountWarning: false }));
          } else {
               // After x seconds when iframe is fully loaded, there's no response message with type = "listenlayerGetAccountWarning" from iframe,
               // display account warning message
               accountWarningTimeout = setTimeout(() => {
                    dispatch(setAccountPreview({ accountWarning: true }));
               }, 6000);
          }

          let messageReceived = false;

          const handleListenToMessage = (e) => {
               if (e.data.type === 'cross-domain-error') {
                    clearTimeout(accountWarningTimeout);
               }
               if (e.data.type === 'pass-domain') {
                    clearTimeout(accountWarningTimeout);
               }
               if (e.data.type === 'listenlayerGetAccountWarning' && e.data.accountId) {
                    clearTimeout(accountWarningTimeout);

                    if (!messageReceived) {
                         messageReceived = true;
                         dispatch(setAccountPreview({ accountWarning: e.data.accountId !== activeAccountId }));

                         if (e.data.accountId === activeAccountId) {
                              if (!doesWebsiteHaveThisScript) {
                                   // Push to doesWebsiteHaveThisScript to check for next times
                                   setDomainsHaveScript([...websitesHaveScript, e.origin]);
                              }
                         } else {
                              // Remove this origin from websitesHaveScript, so websitesHaveScript can't use this origin to check for next times
                              const foundIndex = websitesHaveScript.findIndex((el) => el === e.origin);

                              if (foundIndex > -1) {
                                   websitesHaveScript.splice(foundIndex, 1);
                              }
                         }
                    }
               }
          };

          window.addEventListener('message', handleListenToMessage);

          return () => {
               clearTimeout(accountWarningTimeout);
               window.removeEventListener('message', handleListenToMessage);
          };
     };

     const contextValue = {
          noticeType,
          showPopup,
          setShowPopup,
          setActionType,
          setShowPopupEndTest,
          iframeLoading,
          currentTestingListener,
          listFeatures,
     };

     return (
          <div>
               <CreateTheWebsiteContext.Provider value={contextValue}>
                    <NoticeWebsite />
               </CreateTheWebsiteContext.Provider>

               {isPreviewDomainIframeBlocked ? (
                    <>
                         <HandleNewTab handleAccountWarning={handleAccountWarning} openedWindow={openedWindow} />
                         {showReopenMessage && (
                              <div className='notice reopen-window-message' style={{ background: '#f0ce27' }}>
                                   <div className='d-flex justify-content-between align-items-center'>
                                        <div className='content'>
                                             <p>
                                                  <strong>Debug window closed</strong> - The connected debug window has been closed. To continue debugging, reopen the
                                                  window
                                             </p>
                                        </div>
                                        <CButton color='primary' onClick={openPreviewOnNewTab}>
                                             Reopen
                                        </CButton>
                                   </div>
                              </div>
                         )}
                    </>
               ) : (
                    <>
                         <TheParameterWebsite
                              locationHref={locationHref}
                              domain={urlPath}
                              handleUrlChange={handleUrlChange}
                              handleInputEnter={handleInputEnter}
                              reloadIframe={reloadIframe}
                              setNavigateType={setNavigateType}
                              urlHistory={urlHistory}
                              urlIndex={urlIndex}
                              setUrlIndex={setUrlIndex}
                              setDomainInIframe={setDomainInIframe}
                         />
                         {beginTest && (
                              <TheWebsite
                                   keyIframe={keyIframe}
                                   domainInIframe={domainInIframe}
                                   isSplitPaneDragging={isSplitPaneDragging}
                                   locationHref={locationHref}
                                   handleAccountWarning={handleAccountWarning}
                              />
                         )}
                    </>
               )}

               {showPopup && (
                    <PopupHasAction
                         onClose={() => setShowPopup(false)}
                         props={{
                              show: showPopup,
                              actionType: actionType,
                              domainSelected: domainSelected,
                              onAccept: () => handleAddDomain(),
                              onAcceptNewDomain: () => handleAddDomain(true),
                              onAcceptAddScriptPreview: () => resetPreview(),
                              setActionType,
                         }}
                    />
               )}
          </div>
     );
};

export default ThePreviewWebsite;
