import React, { useContext } from "react";
import CIcon from "@coreui/icons-react";
import {
    SupportTicketPopupContext,
    ISSUE_LISTENER,
    CREATE_SUPPORT_TICKET
} from "../GetHelpLabel";
import { STEP_PREVIOUS_CREATE_TICKET } from "../../../../../constants";

import TicketVideoIframe from "../TicketVideoIframe";

const IssueListener4 = () => {
     const { setCurrentStep, dataShowVideo } = useContext(SupportTicketPopupContext);

     const { listenerName, featureName, videoEmbed } = dataShowVideo;

     const dataVideoIframe = {
          title: `We're Here to Help!`,
          description: `Please watch the overview video for this ${featureName ? 'Feature' : 'Listener' } to ensure you are using it correctly`,
     }

     const buttonVideo = () => {
          setCurrentStep(CREATE_SUPPORT_TICKET.step1);
          localStorage.setItem(STEP_PREVIOUS_CREATE_TICKET, ISSUE_LISTENER.step4)
     }

     return (
          <div className="ticket-popup-content ticket-video-content">
               <div className="btn-back-ticket">
                    <CIcon icon="icon-chevron-left" width={6} />
                    <span onClick={() => setCurrentStep(ISSUE_LISTENER.step2)}>
                         Back
                    </span>
               </div>
               <TicketVideoIframe
                    title={dataVideoIframe.title}
                    description={dataVideoIframe.description}
                    urlIframe={videoEmbed}
                    buttonVideo={buttonVideo}
                    listenerName={listenerName}
                    featureName={featureName}
               />
          </div>
     );
};

export default IssueListener4;
