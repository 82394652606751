import React from 'react';
import CIcon from '@coreui/icons-react';
import CopyToClipboard from 'react-copy-to-clipboard';
import CImg from '../../migration/CImg';
import CTooltip from '../../migration/CTooltip';

const SimpleCopyButton = ({ textToCopy, disabled, iconNew }) => {
    return (
        <>
            {disabled ? (
                <CIcon className="simple-copy-button disabled" icon="icon-copy" height={28} width={28} />
            ) : (
                <CTooltip
                    content="Copied!"
                    advancedOptions={{
                        trigger: 'click',
                        hideOnClick: false,
                        duration: [null, 500], // show duration is the default, hide duration is 500ms
                        onShow(instance) {
                            setTimeout(() => {
                                instance.hide();
                            }, 1000);
                        },
                    }}
                >
                    <CopyToClipboard text={textToCopy}>
                        {iconNew ? (
                            <CImg src="/assets/images/icon-copy-v2.png" height={19} width={19} />
                        ) : (
                            <CIcon className="simple-copy-button" icon="icon-copy" height={28} width={28} />
                        )}
                    </CopyToClipboard>
                </CTooltip>
            )}
        </>
    );
}

export default SimpleCopyButton;
