import CIcon from '@coreui/icons-react'
import { CButton, CForm,  CModal, CModalBody } from '@coreui/react'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { fetchCustomVariables, setNewCustomVariable, setShowCreateVariable, setUsedItems } from '../../../../../actions/subscriber'
import { callTokenApi } from '../../../../../apiCaller'
import { API_UPDATE_CUSTOM_VARIABLES } from '../../../../../constants'
import { toastError, validateFormik } from '../../../../../utils'
import { CInput } from '../../../../migration/CInput'
import { CInvalidFeedback } from '../../../../migration/CInvalidFeedback'
import CFormGroup from '../../../../migration/CFormGroup'
const ModalCreateVarible = ({ show, onCancel, customVariableNext }) => {
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const dispatch = useDispatch()
     const [formLoading, setFormLoading] = useState(false);
     const customVariables = useSelector(state => state.subscriber.customVariables);
     const { initialVariables
     } = customVariables;
     const initialValues = {
          name: '',
     };
     const fetchVariables = () => {
          setFormLoading(true);
          dispatch(fetchCustomVariables(activeAccount.id, () => {
               setFormLoading(false);
          }));
     }
     const fetchData = () => {
          if (customVariables.numOfVariables === 0) {
               fetchVariables();
          }
     }
     useEffect(fetchData, []);// eslint-disable-line react-hooks/exhaustive-deps
     const handleUpdate = (newformData) => {
          setFormLoading(true);
          newformData.group = 'rule';
          callTokenApi(API_UPDATE_CUSTOM_VARIABLES, 'POST', newformData)
               .then(response => {
                    if (response.status === 200) {
                         const { customVariablesUpdated } = response.data;
                         const usedItems = {
                              itemName: 'numberOfCusDatalayerVariables',
                              quantity: response.data.count
                         }
                         dispatch(setUsedItems(usedItems));
                         fetchVariables();
                         toast.success("Add custom variable success");
                         if (customVariableNext) {
                              customVariableNext(customVariablesUpdated[customVariablesUpdated.length - 1])
                         }
                    } else {
                         toastError(response);

                    }
               })
               .finally(() => {
                    setFormLoading(false);
                    dispatch(setShowCreateVariable(false))
                    dispatch(setNewCustomVariable({ variable: newformData.listCustomVariables, typeVariable: newformData.group, show: true }))
               })
     }
     const onSubmit = (values, { setErrors }) => {
          let variable = values.name
          const checkVariable = initialVariables.find((item) => item.name === variable)
          if (checkVariable) {
               setErrors({ name: 'value already exists' });
          } else {

               if (values.name) {
                    let listCustomVariables = [values]
                    handleUpdate({ accountId: activeAccount.id, listCustomVariables, action: 'add', group: "rule" });
               }
          }

     }
     return (
          <div className="confirm-save-popup select-domain popup-create-variable">
               <CModal
                    visible={show}
                    onClose={onCancel}
                    color="primary"
                    alignment="center"
                    portal={false}
               >
                    <CModalBody className='text-center confirm-save'>
                         {/* <CIcon onClick={onCancel} icon="cil-x" className="icon-close-popup"></CIcon> */}
                         <div className="popup-publish-version-description">
                              <div className="confirm-save-icon"><CIcon icon="icon-save" height={46} width={46} /></div>
                              <h1>Create New Data Layer Variable</h1>
                              <p className='confirm-save-content'>Create a custom data layer variable to use with the rule you are writing.</p>
                         </div>
                         <Formik
                              initialValues={initialValues}
                              validate={validateFormik(validationSchema)}
                              validateOnChange={false}
                              validateOnBlur={false}
                              onSubmit={onSubmit}
                         >
                              {
                                   ({
                                        values,
                                        errors,
                                        handleChange,
                                        handleSubmit,
                                        handleBlur,
                                   }) => {
                                        return (
                                             <CForm onSubmit={handleSubmit} noValidate>
                                                  <CFormGroup className='mb-4'>
                                                       <CInput
                                                            name="name"
                                                            placeholder="enter custom name"
                                                            autoComplete="name"
                                                            invalid={!!errors.name}

                                                            value={values.name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                       />
                                                       <CInvalidFeedback style={{textAlign: 'left'}}>{errors.name}</CInvalidFeedback>
                                                  </CFormGroup>
                                                  <div className="form-actions">
                                                       <CButton type="submit" className='btn-primary' disabled={formLoading}>
                                                            {formLoading ? (<>Waiting<span className="dots-waiting"></span></>) : 'Save'}
                                                       </CButton>
                                                       <CButton
                                                            onClick={onCancel}
                                                            className=" btn btn-cancel rounded-pill cancel"
                                                       >
                                                            CANCEL
                                                       </CButton>
                                                  </div>
                                             </CForm>
                                        )
                                   }
                              }
                         </Formik>

                    </CModalBody>
               </CModal>

          </div>

     )
}
const validationSchema = () => {
     return Yup.object().shape({
          name: Yup.string()
               .max(120, "Variable Name cannot be more than 120 characters!")
               .matches(/^[a-zA-Z0-9_-]*$/g, 'Allows letters, numbers and - _')
               .required("Variable Name cannot be empty!")
     })
}
export default ModalCreateVarible