import React from 'react';
import { CCard, CCardBody, CButton } from '@coreui/react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setCustomDestinationModal } from '../../../../actions/subscriber';
import { setFlexibleModal } from '../../../../actions/common';

const DestinationCustomItem = () => {
     const dispatch = useDispatch();

     const onClickCreateButton = () => {
          dispatch(
               setFlexibleModal({
                    show: false,
                    component: '',
               })
          );
          dispatch(setCustomDestinationModal({ show: true }));
     };

     return (
          <div className='listener-item'>
               <CCard className='custom-destination'>
                    <CCardBody>
                         <div className='card-title d-flex justify-content-between w-100'>
                              <div className='d-flex'>
                                   <img src='/assets/icons/listenlayer-favicon.svg' alt='Icon' className='icon-20 mr-2' />
                                   <h4>Create Custom Destination</h4>
                              </div>
                         </div>
                         <div className='card-text h-100'>Create and manage a custom destination to organize tags you have for platforms not listed in our library.</div>
                         <div className={`card-icon d-flex justify-content-end`}>
                              <div className='card-header-actions'>
                                   <CButton className='addicon' color='primary' onClick={onClickCreateButton}>
                                        <i className='fal fa-plus mr-1'></i>
                                        Create
                                   </CButton>
                              </div>
                         </div>
                    </CCardBody>
               </CCard>
          </div>
     );
};

DestinationCustomItem.propTypes = {
     destinations: PropTypes.array,
};

export default DestinationCustomItem;
