import React, { useRef, useState, useCallback, useMemo, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CButton, CCollapse, CLink } from '@coreui/react';
import Select from 'react-select';
import AceEditor from 'react-ace';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import CIcon from '@coreui/icons-react';
import cloneDeep from 'lodash/cloneDeep';
import 'ace-builds/src-noconflict/theme-monokai';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import Items from './Items';
import CopyToClipboard from 'react-copy-to-clipboard';
import { API_CLIENT_DESTINATIONS_GTM_VARIABLES_IN_LL, COPY_TO_CLIPBOARD } from '../../../constants';
import { setGTMVariablesInLL, setTogglePreviewPopup } from '../../../actions/subscriber';
import { getListenerColorIcon } from '../../../helpers/cms/subscriber';
import { callTokenApi } from '../../../apiCaller';
import CFade from '../../../components/migration/CFade';

const PreviewFeatureChild = ({ datas, renderingCode = false, level }) => {
     const { listenerId, eventCode, setCustomValues, eventId, listenerChildShow, setListenerChildShow } = useContext(PreviewFeatureContext);
     const [viewCode, setViewCode] = useState(false);
     const [copyIndex, setCopyIndex] = useState(false);
     const [action, setAction] = useState('');
     const sortData = datas.sort((a, b) => {
          const titleA = a.title.toString().toLowerCase();
          const titleB = b.title.toString().toLowerCase();

          // Keep "event" attribute order of first variable level on the top
          if (level === 1) {
               if (titleA === 'event') {
                    return -1;
               }

               if (titleB === 'event') {
                    return 1;
               }

               if (titleA === 'eventid') {
                    return -2;
               }

               if (titleB === 'eventid') {
                    return 2;
               }
          }

          return titleA < titleB ? -1 : 1;
     });
     const newLevel = level + 1;

     const hasChildren = (childs) => childs.hasChild;

     const handleToggle = (flag, keyIndex) => {
          let newCollapseShow = cloneDeep(listenerChildShow);

          if (flag) {
               newCollapseShow = newCollapseShow.filter((item) => item.eventId !== eventId || item.keyIndex !== keyIndex);
          } else {
               newCollapseShow.push({ keyIndex, eventId });
          }

          setListenerChildShow(newCollapseShow);
     };

     const handleCopyData = (keyIndex, type) => {
          setCopyIndex(keyIndex);
          setAction(type);
          setTimeout(() => {
               setCopyIndex(false);
               setAction('');
          }, 1000);
     };

     // const handleStoreInBrowser = (action, data) => {
     //      setStoreBrowser({ type: STORE_IN_BROWSER.KEY, action, listenerId, data });
     // }

     const handleCreateCustomRule = (data) => {
          setCustomValues({ listenerId, data, eventCode });
     };

     return (
          <>
               {sortData.length > 0 &&
                    sortData.map((data, index) => {
                         const keyIndex = `${data.title}${index}`;
                         const flagShowCollapse = listenerChildShow.length
                              ? listenerChildShow.some((item) => {
                                     return item.eventId === eventId && item.keyIndex === keyIndex;
                                })
                              : false;

                         return (
                              <div
                                   key={keyIndex}
                                   className={`preview-event preview-event-child ${flagShowCollapse ? 'extend' : ''}`}
                                   style={{ zIndex: sortData.length - index }}
                              >
                                   {hasChildren(data) ? (
                                        <div className="hasChild">
                                             <div className={`event-toggle${flagShowCollapse ? ' show' : ''}`}>
                                                  <div className="d-flex align-items-center">
                                                       <div className="overlay-click" onClick={() => handleToggle(flagShowCollapse, keyIndex)}></div>
                                                       <span className="event-name">
                                                            <strong>{data.title}</strong>
                                                            <CFade
                                                                 timeout={300}
                                                                 in={flagShowCollapse}
                                                                 className={classNames('action-field', { 'd-none': !flagShowCollapse })}
                                                            >
                                                                 <span className="tooltip-container show-on-hover">
                                                                      <CIcon className="inverse" icon="iconClone" height={20} width={20} />
                                                                      <span className="block-tooltip">
                                                                           <CIcon icon="arrowTooltip" className="arrow-tooltip-preview" />
                                                                           <div className="block-tooltip-text">
                                                                                <CopyToClipboard
                                                                                     text={data.title}
                                                                                     onCopy={() => handleCopyData(keyIndex, COPY_TO_CLIPBOARD.NAME)}
                                                                                >
                                                                                     <p>
                                                                                          {copyIndex === keyIndex && action === COPY_TO_CLIPBOARD.NAME
                                                                                               ? COPY_TO_CLIPBOARD.COPIED
                                                                                               : COPY_TO_CLIPBOARD.NAME}
                                                                                     </p>
                                                                                </CopyToClipboard>
                                                                                <CopyToClipboard
                                                                                     text={data.path}
                                                                                     onCopy={() => handleCopyData(keyIndex, COPY_TO_CLIPBOARD.PATH)}
                                                                                >
                                                                                     <p>
                                                                                          {copyIndex === keyIndex && action === COPY_TO_CLIPBOARD.PATH
                                                                                               ? COPY_TO_CLIPBOARD.COPIED
                                                                                               : COPY_TO_CLIPBOARD.PATH}
                                                                                     </p>
                                                                                </CopyToClipboard>
                                                                                <CopyToClipboard
                                                                                     text={JSON.stringify(data.fullCode, null, '\t')}
                                                                                     onCopy={() => handleCopyData(keyIndex, COPY_TO_CLIPBOARD.OBJECT)}
                                                                                >
                                                                                     <p>
                                                                                          {copyIndex === keyIndex &&
                                                                                          action === COPY_TO_CLIPBOARD.OBJECT
                                                                                               ? COPY_TO_CLIPBOARD.COPIED
                                                                                               : COPY_TO_CLIPBOARD.OBJECT}
                                                                                     </p>
                                                                                </CopyToClipboard>
                                                                           </div>
                                                                      </span>
                                                                 </span>
                                                                 <span
                                                                      className={viewCode === keyIndex ? 'active' : ''}
                                                                      onClick={() => setViewCode(viewCode === keyIndex ? null : keyIndex)}
                                                                 >
                                                                      <span className="tooltip-container show-on-hover">
                                                                           <CIcon icon="iconCodeRegular" />
                                                                           <span className="block-tooltip">
                                                                                <CIcon icon="arrowTooltip" className="arrow-tooltip-preview" />
                                                                                <div className="block-tooltip-text">
                                                                                     <p>{viewCode === keyIndex ? 'Hide Code' : 'View Code'}</p>
                                                                                </div>
                                                                           </span>
                                                                      </span>
                                                                 </span>
                                                                 {/* <span className="tooltip-container show-on-hover">
                                                                 <CIcon icon="iconDatabase" width={15} height={17} />
                                                                 <span className="block-tooltip">
                                                                      <CIcon icon="arrowTooltip" className="arrow-tooltip-preview" />
                                                                      <div className="block-tooltip-text">
                                                                           <p onClick={() => handleStoreInBrowser(BROWSER_STORAGE_TYPE.COOKIE, data)}>{STORE_IN_BROWSER.COOKIE}</p>
                                                                           <p onClick={() => handleStoreInBrowser(BROWSER_STORAGE_TYPE.SESSION_STORAGE, data)}>{STORE_IN_BROWSER.SESSION}</p>
                                                                           <p onClick={() => handleStoreInBrowser(BROWSER_STORAGE_TYPE.LOCAL_STORAGE, data)}>{STORE_IN_BROWSER.LOCAL_STORAGE}</p>
                                                                      </div>
                                                                 </span>
                                                            </span> */}
                                                            </CFade>
                                                       </span>
                                                  </div>
                                             </div>
                                             <CCollapse visible={flagShowCollapse}>
                                                  <div className="preview-feature">
                                                       {viewCode === keyIndex && !renderingCode ? (
                                                            <div className="view-code-editor">
                                                                 <AceEditor
                                                                      placeholder="No Code Preview"
                                                                      mode="html"
                                                                      theme="monokai"
                                                                      name={keyIndex}
                                                                      fontSize={14}
                                                                      showPrintMargin={true}
                                                                      showGutter={true}
                                                                      highlightActiveLine={false}
                                                                      readOnly={true}
                                                                      value={JSON.stringify(data.fullCode, null, '\t')}
                                                                      focus
                                                                      wrapEnabled={true}
                                                                      setOptions={{
                                                                           enableBasicAutocompletion: false,
                                                                           enableLiveAutocompletion: false,
                                                                           enableSnippets: false,
                                                                           showLineNumbers: true,
                                                                           tabSize: 2,
                                                                           useWorker: false,
                                                                      }}
                                                                 />
                                                            </div>
                                                       ) : (
                                                            <PreviewFeatureChild datas={data.childs} renderingCode={renderingCode} level={newLevel} />
                                                       )}
                                                  </div>
                                             </CCollapse>
                                        </div>
                                   ) : (
                                        <div className="noChild">
                                             <div className={`event-toggle${flagShowCollapse ? ' show' : ''}`}>
                                                  <div className="event-toggle-headline d-flex align-items-center justify-content-between">
                                                       <div className="overlay-click" onClick={() => handleToggle(flagShowCollapse, keyIndex)}></div>
                                                       <span className="event-name">
                                                            <strong>{data.title}</strong>
                                                            <CFade
                                                                 timeout={300}
                                                                 in={flagShowCollapse}
                                                                 className={classNames('action-field', { 'd-none': !flagShowCollapse })}
                                                            >
                                                                 <span className="tooltip-container show-on-hover">
                                                                      <CIcon className="inverse" icon="iconClone" height={20} width={20} />
                                                                      <span className="block-tooltip">
                                                                           <CIcon icon="arrowTooltip" className="arrow-tooltip-preview" />
                                                                           <div className="block-tooltip-text">
                                                                                <CopyToClipboard
                                                                                     text={data.title}
                                                                                     onCopy={() => handleCopyData(keyIndex, COPY_TO_CLIPBOARD.NAME)}
                                                                                >
                                                                                     <p>
                                                                                          {copyIndex === keyIndex && action === COPY_TO_CLIPBOARD.NAME
                                                                                               ? COPY_TO_CLIPBOARD.COPIED
                                                                                               : COPY_TO_CLIPBOARD.NAME}
                                                                                     </p>
                                                                                </CopyToClipboard>
                                                                                <CopyToClipboard
                                                                                     text={data.path}
                                                                                     onCopy={() => handleCopyData(keyIndex, COPY_TO_CLIPBOARD.PATH)}
                                                                                >
                                                                                     <p>
                                                                                          {copyIndex === keyIndex && action === COPY_TO_CLIPBOARD.PATH
                                                                                               ? COPY_TO_CLIPBOARD.COPIED
                                                                                               : COPY_TO_CLIPBOARD.PATH}
                                                                                     </p>
                                                                                </CopyToClipboard>
                                                                                <CopyToClipboard
                                                                                     text={JSON.stringify(data.fullCode, null, '\t')}
                                                                                     onCopy={() => handleCopyData(keyIndex, COPY_TO_CLIPBOARD.OBJECT)}
                                                                                >
                                                                                     <p>
                                                                                          {copyIndex === keyIndex &&
                                                                                          action === COPY_TO_CLIPBOARD.OBJECT
                                                                                               ? COPY_TO_CLIPBOARD.COPIED
                                                                                               : COPY_TO_CLIPBOARD.OBJECT}
                                                                                     </p>
                                                                                </CopyToClipboard>
                                                                           </div>
                                                                      </span>
                                                                 </span>
                                                                 <span
                                                                      className={viewCode === keyIndex ? 'active' : ''}
                                                                      onClick={() => setViewCode(viewCode === keyIndex ? null : keyIndex)}
                                                                 >
                                                                      <span className="tooltip-container show-on-hover">
                                                                           <CIcon icon="iconCodeRegular" />
                                                                           <span className="block-tooltip">
                                                                                <CIcon icon="arrowTooltip" className="arrow-tooltip-preview" />
                                                                                <div className="block-tooltip-text">
                                                                                     <p>{viewCode === keyIndex ? 'Hide Code' : 'View Code'}</p>
                                                                                </div>
                                                                           </span>
                                                                      </span>
                                                                 </span>
                                                                 {/* <span className="tooltip-container show-on-hover">
                                                                 <CIcon icon="iconDatabase" width={15} height={17} />
                                                                 <span className="block-tooltip">
                                                                      <CIcon icon="arrowTooltip" className="arrow-tooltip-preview" />
                                                                      <div className="block-tooltip-text">
                                                                           <p onClick={() => handleStoreInBrowser(BROWSER_STORAGE_TYPE.COOKIE, data)}>{STORE_IN_BROWSER.COOKIE}</p>
                                                                           <p onClick={() => handleStoreInBrowser(BROWSER_STORAGE_TYPE.SESSION_STORAGE, data)}>{STORE_IN_BROWSER.SESSION}</p>
                                                                           <p onClick={() => handleStoreInBrowser(BROWSER_STORAGE_TYPE.LOCAL_STORAGE, data)}>{STORE_IN_BROWSER.LOCAL_STORAGE}</p>
                                                                      </div>
                                                                 </span>
                                                            </span> */}
                                                            </CFade>
                                                       </span>
                                                       {data.title === 'customValues' && (
                                                            <CButton className="create-rule-button" onClick={() => handleCreateCustomRule(data)}>
                                                                 + create custom value
                                                            </CButton>
                                                       )}
                                                  </div>
                                             </div>
                                             <CCollapse visible={flagShowCollapse}>
                                                  <div className="preview-feature">
                                                       {viewCode === keyIndex && !renderingCode ? (
                                                            <div className="view-code-editor">
                                                                 <AceEditor
                                                                      placeholder="No Code Preview"
                                                                      mode="html"
                                                                      theme="monokai"
                                                                      name={keyIndex}
                                                                      fontSize={14}
                                                                      showPrintMargin={true}
                                                                      showGutter={true}
                                                                      highlightActiveLine={false}
                                                                      readOnly={true}
                                                                      value={JSON.stringify(data.fullCode, null, '\t')}
                                                                      focus
                                                                      wrapEnabled={true}
                                                                      setOptions={{
                                                                           enableBasicAutocompletion: false,
                                                                           enableLiveAutocompletion: false,
                                                                           enableSnippets: false,
                                                                           showLineNumbers: true,
                                                                           tabSize: 2,
                                                                           useWorker: false,
                                                                      }}
                                                                 />
                                                            </div>
                                                       ) : (
                                                            <div className="preview-feature-content">
                                                                 {(!data.fullCode || data.fullCode.length === 0) && !!data.message ? (
                                                                      <p className="preview-feature-message">{data.message}</p>
                                                                 ) : (
                                                                      <Items datas={data.fullCode} path={data.path} show={flagShowCollapse} />
                                                                 )}
                                                            </div>
                                                       )}
                                                  </div>
                                             </CCollapse>
                                        </div>
                                   )}
                              </div>
                         );
                    })}
          </>
     );
};

export const PreviewFeatureContext = React.createContext({});

const PreviewFeature = ({
     uniqueId,
     previewEventAccordion,
     event,
     renderingCode,
     index,
     setStoreBrowser,
     setCustomValues,
     setLastLevelAccordionOpened,
     handleTogglePreviewEvent,
     listenerChildShow,
     setListenerChildShow,
}) => {
     const _destinations = event.fullCode.destinations;
     const collapseShow = previewEventAccordion.includes(uniqueId);

     const [viewCode, setViewCode] = useState(false);
     const [codeCopied, setCodeCopied] = useState(false);
     const [displayContent, setDisplayContent] = useState(false);

     const flagDestination =
          _destinations &&
          Object.keys(_destinations).some((key1) => {
               const elementDataActions = _destinations[key1].dataActions;
               const elementConversion = _destinations[key1].conversion;
               const elementECommerce = _destinations[key1].eCommerce;

               return (
                    typeof elementDataActions.match === 'undefined' ||
                    typeof elementConversion.match === 'undefined' ||
                    typeof elementECommerce.match === 'undefined'
               );
          });

     const toggle = () => {
          handleTogglePreviewEvent(uniqueId);
     };

     const handleAccordion = () => {
          let timeoutDisplayContent;

          if (displayContent) {
               // Keep the content of this accordion to display for x time to make close accordion smoothly when opening another accordion
               timeoutDisplayContent = setTimeout(() => {
                    setDisplayContent(false);
               }, 300);
          }

          if (collapseShow) {
               setDisplayContent(true);
          }

          return () => clearTimeout(timeoutDisplayContent);
     };

     useEffect(handleAccordion, [collapseShow]); // eslint-disable-line react-hooks/exhaustive-deps

     const toggleViewCode = () => {
          setViewCode((v) => !v);
     };

     const handleCopyData = useCallback( // eslint-disable-line react-hooks/exhaustive-deps
          throttle(
               () => {
                    setCodeCopied(true);
                    setTimeout(() => {
                         setCodeCopied(false);
                    }, 1000);
               },
               1000,
               { trailing: false }
          )
     , []); // trailing: false -> Don't invoke on the trailing edge of the timeout (if trailing true, when clicking twice, it will fire one at beginning and one after the waiting time (1s))

     return (
          <>
               {!event.conversion && (
                    <div className="preview-event" id={event.fullCode.eventID} style={{ zIndex: index }}>
                         <div className={`event-toggle${collapseShow ? ' show' : ''}`}>
                              <div className="overlay-click" onClick={toggle}></div>
                              <div className="event-and-tool">
                                   <span className="event-name text-left parent">
                                        <span className="event-index">{index} </span>
                                        event: <strong>{event.event}</strong>
                                        <CFade timeout={300} in={collapseShow} className={classNames('action-field', { 'd-none': !collapseShow })}>
                                             <CopyToClipboard text={JSON.stringify(event.fullCode, null, '\t')} onCopy={handleCopyData}>
                                                  <span className="tooltip-container show-on-hover">
                                                       <CIcon className="inverse" icon="iconClone" height={20} width={20} />
                                                       <span className="block-tooltip">
                                                            <CIcon icon="arrowTooltip" className="arrow-tooltip-preview" />
                                                            <div className="block-tooltip-text">
                                                                 <p>{codeCopied ? COPY_TO_CLIPBOARD.COPIED : COPY_TO_CLIPBOARD.OBJECT}</p>
                                                            </div>
                                                       </span>
                                                  </span>
                                             </CopyToClipboard>
                                             <span className={viewCode ? 'active' : ''} onClick={toggleViewCode}>
                                                  <span className="tooltip-container show-on-hover">
                                                       <CIcon icon="iconCodeRegular" />
                                                       <span className="block-tooltip">
                                                            <CIcon icon="arrowTooltip" className="arrow-tooltip-preview" />
                                                            <div className="block-tooltip-text">
                                                                 <p>{viewCode ? 'Hide Code' : 'View Code'}</p>
                                                            </div>
                                                       </span>
                                                  </span>
                                             </span>
                                        </CFade>
                                   </span>
                              </div>
                              <div className="event-meta">
                                   {event.fullCode && event.fullCode.triggers && <span className="badge trigger">trigger</span>}

                                   {event.fullCode && event.fullCode.conversion && event.fullCode.conversion.match === 'yes' && (
                                        <span className="badge conversion">conversion</span>
                                   )}

                                   {event.fullCode && event.fullCode.destinations && flagDestination && (
                                        <span className="badge destination">destination</span>
                                   )}

                                   {event.listener && !event.conversion && (
                                        <span className="listener-name text-left">Listener: {event.listener}</span>
                                   )}
                              </div>
                         </div>
                         <CCollapse visible={collapseShow}>
                              {displayContent && (
                                   <div className="preview-feature">
                                        {viewCode && !renderingCode ? (
                                             <div className="view-code-editor">
                                                  <AceEditor
                                                       placeholder="No Code Preview"
                                                       mode="html"
                                                       theme="monokai"
                                                       fontSize={14}
                                                       showPrintMargin={true}
                                                       showGutter={true}
                                                       highlightActiveLine={false}
                                                       readOnly={true}
                                                       value={JSON.stringify(event.fullCode, null, '\t')}
                                                       focus
                                                       wrapEnabled={true}
                                                       setOptions={{
                                                            enableBasicAutocompletion: false,
                                                            enableLiveAutocompletion: false,
                                                            enableSnippets: false,
                                                            showLineNumbers: true,
                                                            tabSize: 2,
                                                            useWorker: false,
                                                       }}
                                                  />
                                             </div>
                                        ) : (
                                             <div className="preview-feature">
                                                  <PreviewFeatureContext.Provider
                                                       value={{
                                                            listenerId: event.listenerId,
                                                            eventCode: event.event,
                                                            eventConversion: event.conversion,
                                                            setStoreBrowser,
                                                            setCustomValues,
                                                            setLastLevelAccordionOpened,
                                                            eventId: event.fullCode.eventID,
                                                            listenerChildShow,
                                                            setListenerChildShow,
                                                       }}
                                                  >
                                                       <PreviewFeatureChild datas={event.body} renderingCode={renderingCode} level={1} />
                                                  </PreviewFeatureContext.Provider>
                                             </div>
                                        )}
                                   </div>
                              )}
                         </CCollapse>
                    </div>
               )}
          </>
     );
};

const DestinationEventPreview = ({
     uniqueId,
     event,
     previewDestinationAccordion,
     handleTogglePreviewDestination,
     setLastLevelAccordionOpened,
     listEventsId,
     handleViewEventbyDestination,
     destinationChildShow,
     setDestinationChildShow,
}) => {
     const eventIndex = listEventsId.length - listEventsId.findIndex((id) => id === event.eventID);
     const collapseShow = previewDestinationAccordion.includes(uniqueId);
     const noResponse = 'Waiting for data... please be patient';

     const showTrigger = destinationChildShow.length
          ? destinationChildShow.some((item) => {
                 return item.uniqueId === uniqueId && item.eventName === 'trigger';
            })
          : false;
     const showInput = destinationChildShow.length
          ? destinationChildShow.some((item) => {
                 return item.uniqueId === uniqueId && item.eventName === 'input';
            })
          : false;
     const showOutput = destinationChildShow.length
          ? destinationChildShow.some((item) => {
                 return item.uniqueId === uniqueId && item.eventName === 'output';
            })
          : false;
     const showResponse = destinationChildShow.length
          ? destinationChildShow.some((item) => {
                 return item.uniqueId === uniqueId && item.eventName === 'response';
            })
          : false;

     const toggle = () => {
          handleTogglePreviewDestination(uniqueId);
     };

     const handleToggle = (flag, eventName) => {
          let newCollapseShow = cloneDeep(destinationChildShow);

          if (flag) {
               newCollapseShow = newCollapseShow.filter((item) => item.uniqueId !== uniqueId || item.eventName !== eventName);
          } else {
               newCollapseShow.push({ eventName, uniqueId });
          }

          setDestinationChildShow(newCollapseShow);
     };

     return (
          <div className="preview-event">
               <div className={`event-toggle${collapseShow ? ' show' : ''}`}>
                    <div className="overlay-click" onClick={toggle}></div>
                    <div className="event-and-tool">
                         <span className="event-name text-left parent">
                              <span className="event-index">{} </span>
                              {event.type}: <strong>{event.eventName}</strong>
                         </span>
                    </div>
                    <div className="event-meta">
                         <span className="badge destination">
                              {typeof event.usingGTM !== 'undefined' && !event.usingGTM ? 'server side' : 'client side'}
                         </span>
                         <span className="listener-name text-left">Destination: {event.destinationCode}</span>
                    </div>
               </div>
               <CCollapse visible={collapseShow}>
                    <div className="preview-feature">
                         <div className="description-destination-preview">
                              {`Triggered by Listener `}
                              <strong>{event.listenerName}</strong>
                              {` - ${event.event} event `}
                              <span onClick={() => handleViewEventbyDestination(eventIndex, event.eventID)}>{`(event ${eventIndex})`}</span>
                         </div>
                         {event.trigger && (
                              <div className={`preview-event preview-event-child ${collapseShow ? 'extend' : ''}`} style={{ zIndex: 15 }}>
                                   <div className="noChild">
                                        <div className={`event-toggle${showTrigger ? ' show' : ''}`}>
                                             <div className="event-toggle-headline d-flex align-items-center justify-content-between">
                                                  <div className="overlay-click" onClick={() => handleToggle(showTrigger, 'trigger')}></div>
                                                  <span className="event-name">
                                                       <strong>Trigger</strong>
                                                  </span>
                                             </div>
                                        </div>
                                        <CCollapse visible={showTrigger}>
                                             <div className="preview-feature">
                                                  <div className="preview-feature-content">
                                                       <PreviewFeatureContext.Provider
                                                            value={{ eventCode: event.eventName, setLastLevelAccordionOpened, disableAction: true }}
                                                       >
                                                            <Items datas={event.trigger} path={''} show={showTrigger} />
                                                       </PreviewFeatureContext.Provider>
                                                  </div>
                                             </div>
                                        </CCollapse>
                                   </div>
                              </div>
                         )}

                         <div className={`preview-event preview-event-child ${collapseShow ? 'extend' : ''}`} style={{ zIndex: 14 }}>
                              <div className="noChild">
                                   <div className={`event-toggle${showInput ? ' show' : ''}`}>
                                        <div className="event-toggle-headline d-flex align-items-center justify-content-between">
                                             <div className="overlay-click" onClick={() => handleToggle(showInput, 'input')}></div>
                                             <span className="event-name">
                                                  <strong>Data in</strong>
                                             </span>
                                        </div>
                                   </div>
                                   <CCollapse visible={showInput}>
                                        <div className="preview-feature">
                                             <div className="preview-feature-content">
                                                  <PreviewFeatureContext.Provider
                                                       value={{ eventCode: event.eventName, setLastLevelAccordionOpened, disableAction: true }}
                                                  >
                                                       <Items datas={event.input} path={''} show={showInput} />
                                                  </PreviewFeatureContext.Provider>
                                             </div>
                                        </div>
                                   </CCollapse>
                              </div>
                         </div>

                         <div className={`preview-event preview-event-child ${collapseShow ? 'extend' : ''}`} style={{ zIndex: 13 }}>
                              <div className="noChild">
                                   <div className={`event-toggle${showOutput ? ' show' : ''}`}>
                                        <div className="event-toggle-headline d-flex align-items-center justify-content-between">
                                             <div className="overlay-click" onClick={() => handleToggle(showOutput, 'output')}></div>
                                             <span className="event-name">
                                                  <strong>Data out</strong>
                                             </span>
                                        </div>
                                   </div>
                                   <CCollapse visible={showOutput}>
                                        <div className="preview-feature">
                                             {event.output ? (
                                                  <div className="preview-feature-content">
                                                       <PreviewFeatureContext.Provider
                                                            value={{ eventCode: event.eventName, setLastLevelAccordionOpened, disableAction: true }}
                                                       >
                                                            <Items datas={event.output} path={''} show={showOutput} />
                                                       </PreviewFeatureContext.Provider>
                                                  </div>
                                             ) : (
                                                  <div className="text-center">
                                                       <span>
                                                            This destination event is generated client side using Google Tag Manager. To validate the
                                                            data out to the destination, test using the Google Tag Manager preview tool.
                                                       </span>
                                                  </div>
                                             )}
                                        </div>
                                   </CCollapse>
                              </div>
                         </div>

                         {typeof event.usingGTM !== 'undefined' && !event.usingGTM && (
                              <div className={`preview-event preview-event-child ${collapseShow ? 'extend' : ''}`} style={{ zIndex: 12 }}>
                                   <div className="noChild">
                                        <div className={`event-toggle${showResponse ? ' show' : ''}`}>
                                             <div className="event-toggle-headline d-flex align-items-center justify-content-between">
                                                  <div className="overlay-click" onClick={() => handleToggle(showResponse, 'response')}></div>
                                                  <span className="event-name">
                                                       <strong>Response</strong>
                                                  </span>
                                             </div>
                                        </div>
                                        <CCollapse visible={showResponse}>
                                             <div className="preview-feature">
                                                  {event.response ? (
                                                       <div className="text-left">
                                                            <span>{event.response.message}</span>
                                                       </div>
                                                  ) : (
                                                       <div className="text-center">
                                                            <span>{noResponse}</span>
                                                       </div>
                                                  )}
                                             </div>
                                        </CCollapse>
                                   </div>
                              </div>
                         )}
                    </div>
               </CCollapse>
          </div>
     );
};

const ThePreviewFeature = ({
     eventsPreview,
     destinationsPreview,
     previewListener,
     setPreviewListener,
     selectedDestination,
     setSelectedDestination,
     renderingCode,
     previewWebsiteComponent,
     setStoreBrowser,
     setCustomValues,
     isPreviewDomainIframeBlocked,
     isPreviewDomainCSPBlocked,
     previewGroupAccordion,
     setPreviewGroupAccordion,
     previewGroupDestinationAccordion,
     setPreviewGroupDestinationAccordion,
     previewEventAccordion,
     setPreviewEventAccordion,
     previewDestinationAccordion,
     setPreviewDestinationAccordion,
     listenerChildShow,
     setListenerChildShow,
     destinationChildShow,
     setDestinationChildShow,
}) => {
     const dispatch = useDispatch();
     const domain = useSelector((state) => state.subscriber.preview.domain);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountListeners = useSelector((state) => state.subscriber.accountListeners);
     const accountDestinations = useSelector((state) => state.subscriber.accountDestinations);
     const gtmVariablesInLL = useSelector((state) => state.subscriber.gtmVariablesInLL);

     const containerId = get(activeAccount, 'gtmInfo.currentContainer.containerId');
     const listenerSelected =
          previewListener && previewListener.length === 1 && accountListeners
               ? accountListeners.find((item) => item.id === previewListener[0].value)
               : null;
     let selectRef = useRef(),
          selectDestinationRef = useRef();
     const listenersActive = [],
          destinationsActive = [];

     const [filterPlaceholder, setFilterPlaceholder] = useState('Filter by Listener');
     const [filterDestinationPlaceholder, setFilterDestinationPlaceholder] = useState('Filter by Destination');
     const [lastLevelAccordionOpened, setLastLevelAccordionOpened] = useState(false);
     const [showDestinations, setShowDestinations] = useState(false);
     // let listEventsId = eventsPreview.map(event => { return event.eventID });

     accountListeners.forEach((listener) => {
          if (listener.configStatus) {
               let iconSrc = getListenerColorIcon(listener);
               listenersActive.push({ value: listener.id, label: listener.name, iconSrc, name: listener.name, type: listener.type });
          }
     });
     listenersActive.sort((a, b) => a.name.localeCompare(b.name));

     accountDestinations.forEach((listener) => {
          if (listener.configStatus) {
               let iconSrc = getListenerColorIcon(listener);
               destinationsActive.push({ value: listener.id, label: listener.name, iconSrc, name: listener.name, type: listener.type });
          }
     });
     destinationsActive.sort((a, b) => a.name.localeCompare(b.name));

     // Hide "All Listener" option if user selects all preview listeners
     const listenersActiveOptions =
          Array.isArray(previewListener) && previewListener.length === listenersActive.length
               ? [...listenersActive]
               : [{ value: 'all', label: 'All Listeners' }, ...listenersActive];
     const destinationsActiveOptions = [
          { value: 'all-destination', label: 'All Destinations' },
          ...destinationsActive.filter((des) => des.type !== 'Other'),
     ];

     const onListenerFilterMenuClose = () => {
          selectRef.current.blur();
     };

     const onDestinationFilterMenuClose = () => {
          selectDestinationRef.current.blur();
     };

     const handleSelectPreviewListener = (selected) => {
          if (selected && selected.length) {
               const selectAllOption = selected.find((option) => option.value === 'all');
               if (selectAllOption) {
                    setPreviewListener(null);
                    setFilterPlaceholder(selectAllOption.label);
                    selectRef.current.blur();
               } else {
                    setPreviewListener(selected);
                    setFilterPlaceholder('Filter by Listener');
               }
          } else {
               setPreviewListener(null);
          }
     };

     const handleSelectPreviewDestination = (selected) => {
          if (selected && selected.length) {
               const selectAll = selected.find((option) => option.value === 'all-destination');

               if (selectAll) {
                    setSelectedDestination(null);
                    setFilterDestinationPlaceholder(selectAll.label);
                    selectDestinationRef.current.blur();
               } else {
                    setSelectedDestination(selected);
                    setFilterPlaceholder('Filter by Listener');
               }
          } else {
               setSelectedDestination(null);
          }
     };

     const handleTogglePreviewPopup = () => {
          dispatch(setTogglePreviewPopup(false));
     };

     const formatOptionLabel = ({ iconSrc, label }) => (
          <div className="option-row">
               {iconSrc && <img src={iconSrc} alt={label} className="icon" />}
               <div>{label}</div>
          </div>
     );

     const formatOptionDesLabel = ({ iconSrc, label }) => (
          <div className="option-row">
               {iconSrc && <img src={iconSrc} alt={label} className="icon" />}
               <div>{label}</div>
          </div>
     );

     const groupEventFeatures = useMemo(() => {
          let groupEventFeatures = [];
          eventsPreview.forEach((eventPreview) => {
               const originPathName = eventPreview.currentLocation;
               const lastGroupEventFeature = groupEventFeatures[groupEventFeatures.length - 1];

               // If eventPreview originPathName equals to the last groupFeatures originPathname, push to that last group, otherwise push a new group
               const eventLocationIndex =
                    lastGroupEventFeature && lastGroupEventFeature.originPathName === originPathName ? groupEventFeatures.length - 1 : -1;

               let conversionEvent = null;
               if (eventPreview.fullCode.conversion && eventPreview.fullCode.conversion.match === 'yes') {
                    const conversionData = eventPreview.fullCode.conversion;
                    conversionEvent = { ...eventPreview };
                    conversionEvent.conversion = conversionData.conversionName;
                    conversionEvent.id = `${eventPreview.id}-${conversionData.conversionName}`;
                    conversionEvent.fullCode = [{ conversion: conversionData }];
                    const conversionBodyData = [...eventPreview.body].find((item) => item.title === 'conversion');
                    conversionEvent.body = conversionBodyData ? [conversionBodyData] : [];
               }

               if (
                    eventPreview.event &&
                    !['newUser', 'consentSet', 'consentChange', 'newSession', 'pageRequested', 'identifyPerson'].includes(eventPreview.event) &&
                    !eventPreview.body.some((item) => item.title === 'customValues')
               ) {
                    eventPreview.body.push({
                         title: 'customValues',
                         path: 'customValues',
                         fullCode: [],
                         message: 'no custom values defined',
                         hasChild: false,
                    });
               }

               if (eventLocationIndex > -1) {
                    // if (!!conversionEvent) {
                    //      groupEventFeatures[eventLocationIndex].eventsPreview.push(conversionEvent);
                    // }
                    groupEventFeatures[eventLocationIndex].eventsPreview.push(eventPreview);
               } else {
                    const newEventsPreview = [eventPreview];
                    if (eventPreview.fullCode.conversion && eventPreview.fullCode.conversion.match === 'yes') {
                         newEventsPreview.unshift(conversionEvent);
                    }
                    groupEventFeatures.push({
                         originPathName,
                         eventsPreview: newEventsPreview,
                    });
               }
          });

          return groupEventFeatures;
     }, [eventsPreview]);

     const listEventsId = useMemo(() => {
          return eventsPreview.map((event) => {
               return event.fullCode.eventID;
          });
     }, [eventsPreview]);

     const groupDestinationFeatures = useMemo(() => {
          let groupDestinationFeatures = [];
          destinationsPreview.forEach((eventPreview) => {
               const originPathName = eventPreview.originPathName;
               const lastGroupEventFeature = groupDestinationFeatures[groupDestinationFeatures.length - 1];
               // If eventPreview originPathName equals to the last groupFeatures originPathname, push to that last group, otherwise push a new group
               const eventLocationIndex =
                    lastGroupEventFeature && lastGroupEventFeature.originPathName === originPathName ? groupDestinationFeatures.length - 1 : -1;
               let conversionEvent = null;

               if (eventLocationIndex > -1) {
                    if (!!conversionEvent) {
                         groupDestinationFeatures[eventLocationIndex].destinationsPreview.push(conversionEvent);
                    }
                    groupDestinationFeatures[eventLocationIndex].destinationsPreview.push(eventPreview);
               } else {
                    const newEventsPreview = [eventPreview];

                    groupDestinationFeatures.push({
                         originPathName,
                         destinationsPreview: newEventsPreview,
                    });
               }
          });

          return groupDestinationFeatures;
     }, [destinationsPreview]);

     const handleTogglePreviewGroup = (groupIndex) => {
          let newGroupAccordion = cloneDeep(previewGroupAccordion);
          if (newGroupAccordion.includes(groupIndex)) {
               newGroupAccordion = [...newGroupAccordion].filter((index) => index !== groupIndex);
          } else {
               newGroupAccordion = [...newGroupAccordion, groupIndex];
          }
          setPreviewGroupAccordion(newGroupAccordion);
     };

     const handleTogglePreviewGroupDestination = (groupIndex) => {
          let newGroupAccordion = cloneDeep(previewGroupDestinationAccordion);
          if (newGroupAccordion.includes(groupIndex)) {
               newGroupAccordion = [...newGroupAccordion].filter((index) => index !== groupIndex);
          } else {
               newGroupAccordion = [...newGroupAccordion, groupIndex];
          }
          setPreviewGroupDestinationAccordion(newGroupAccordion);
     };

     // Open new accordion and close all old accordion
     useEffect(() => {
          if (!isPreviewDomainIframeBlocked) {
               const newGroupAccordion = [...Array(groupEventFeatures.length + 1).keys()].slice(1);
               setPreviewGroupAccordion(newGroupAccordion);
          } else {
               const newGroupAccordion = [];
               previewGroupAccordion.forEach((i) => {
                    newGroupAccordion.push(i + 1);
               });
               setPreviewGroupAccordion(newGroupAccordion);
          }
     }, [groupEventFeatures.length, isPreviewDomainIframeBlocked]); // eslint-disable-line react-hooks/exhaustive-deps

     // useEffect(() => {
     //      const newPreviewGroupDestinationAccordion =
     //           groupDestinationFeatures.length > 1 ? groupDestinationFeatures.map((item, index) => index + 1) : [];

     //      setPreviewGroupDestinationAccordion(newPreviewGroupDestinationAccordion);
     // }, [groupDestinationFeatures]);

     const handleTogglePreviewEvent = (uniqueId) => {
          let newEventAccordion = cloneDeep(previewEventAccordion);
          if (newEventAccordion.includes(uniqueId)) {
               newEventAccordion = [...newEventAccordion].filter((i) => i !== uniqueId);
          } else {
               newEventAccordion = [...newEventAccordion, uniqueId];
          }
          setPreviewEventAccordion(newEventAccordion);
     };

     const fetchGTMVariables = () => {
          if (!gtmVariablesInLL && containerId && lastLevelAccordionOpened) {
               callTokenApi(`${API_CLIENT_DESTINATIONS_GTM_VARIABLES_IN_LL}/${activeAccount.id}?containerId=${containerId}`, 'GET').then(
                    (response) => {
                         if (response.status === 200) {
                              dispatch(setGTMVariablesInLL(response.data.gtmVariablesInLL));
                         }
                    }
               );
          }
     };
     useEffect(fetchGTMVariables, [gtmVariablesInLL, lastLevelAccordionOpened]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleTogglePreviewDestination = (uniqueId) => {
          let newDestinationAccordion = cloneDeep(previewDestinationAccordion);
          if (newDestinationAccordion.includes(uniqueId)) {
               newDestinationAccordion = [...newDestinationAccordion].filter((i) => i !== uniqueId);
          } else {
               newDestinationAccordion = [...newDestinationAccordion, uniqueId];
          }
          setPreviewDestinationAccordion(newDestinationAccordion);
     };

     const scrollToTarget = (id) => {
          const element = document.getElementById(id);

          if (element) {
               element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
               });
          }
     };

     const handleViewEventbyDestination = (uniqueId, eventId) => {
          handleTogglePreviewEvent(eventId);
          setShowDestinations(false);
          setTimeout(() => {
               scrollToTarget(eventId);
          }, 500);
     };

     return (
          <div className="preview_main_content preview_main_listenlayer">
               <div className="preview_main_content_header preview_main_listenlayer_header d-flex align-items-center justify-content-center">
                    <h4>Data Layer Events seen by ListenLayer</h4>
                    <div className="preview_main_tooltip">
                         <CIcon icon="icon-info" className="hover-show-tooltip" height={14}></CIcon>
                         <div className="block-tooltip">
                              <CIcon icon="arrow-preview" className="arrow-preview"></CIcon>
                              <div className="block-tooltip-text">
                                   <p>
                                        Data layer events will appear here a few seconds after they are generated from your preview activity on the
                                        right.
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>

               {isPreviewDomainCSPBlocked ? (
                    <div className="notice notice-warning fade show">
                         <div className="d-flex align-items-center justify-content-between">
                              <div className="content">
                                   Your website has a Content Security Policy that is blocking ListenLayer from working. You need to have your
                                   webmaster, IT, or firewall manager whitelist the domain *.listenlayer.com so ListenLayer will work. Please submit a
                                   support ticket if you continue to have issues.
                              </div>
                         </div>
                    </div>
               ) : (
                    isPreviewDomainIframeBlocked && (
                         <div className="notice notice-warning fade show">
                              <div className="d-flex align-items-center justify-content-between">
                                   <div className="content">
                                        Your website blocks iframes, so you are unable to test inside your website and view the data layer activity
                                        inside a single tab. We have opened your website in a separate tab. Interact with it there and view the data
                                        activity here. For an easier testing experience, have your IT whitelist app.listenlayer.com for
                                        Access-Control-Allow-Origin.
                                   </div>
                              </div>
                         </div>
                    )
               )}

               <div className="preview-select-option d-flex align-items-center">
                    <span>
                         <strong>Select your view:</strong>
                    </span>
                    <CButton onClick={() => setShowDestinations(false)} className={`ml-4 ${!showDestinations ? 'outline-dark' : ''}`} color="light">
                         Listener Events
                    </CButton>
                    <CButton onClick={() => setShowDestinations(true)} className={`ml-4 ${showDestinations ? 'outline-dark' : ''}`} color="light">
                         Destination Events
                    </CButton>
               </div>
               <div className="preview_main_content_body preview_main_listenlayer_body">
                    {previewWebsiteComponent}
                    {showDestinations ? (
                         <>
                              <div className="preview-listener-select-wrapper destination-select d-flex align-items-center justify-content-between">
                                   {selectedDestination ? (
                                        <p className="mb-0">
                                             Real-time {selectedDestination[0].name} events from {domain}
                                        </p>
                                   ) : (
                                        <p className="mb-0">Real-time destinations from {domain}</p>
                                   )}
                                   <div
                                        className={`select-wrap ${
                                             selectedDestination && selectedDestination.length > 0
                                                  ? `selected select-${selectedDestination.length}`
                                                  : ''
                                        }`}
                                   >
                                        <Select
                                             classNamePrefix="react-select"
                                             ref={selectDestinationRef}
                                             name="preview-destination-select"
                                             className="text-left preview-listener-select destination-select"
                                             options={destinationsActiveOptions}
                                             formatOptionLabel={formatOptionDesLabel}
                                             onChange={(selected) => handleSelectPreviewDestination(selected)}
                                             value={selectedDestination}
                                             placeholder={filterDestinationPlaceholder}
                                             onMenuClose={onDestinationFilterMenuClose}
                                             isMulti={true}
                                             closeMenuOnSelect={false}
                                             openMenuOnFocus
                                        />
                                        <span className="clear-filter" onClick={() => setSelectedDestination(null)}>
                                             <CIcon id="iconClose" icon="cil-x" />
                                        </span>
                                   </div>
                              </div>

                              <div className="preview-events">
                                   <p className="text-center mt-2">
                                        Please be patient. Data Layer destinations will take a few seconds to appear here.
                                   </p>
                                   {groupDestinationFeatures.length > 0 && (
                                        <>
                                             {groupDestinationFeatures.map((groupEvent, groupIndex) => {
                                                  const groupEventsPreview = groupEvent.destinationsPreview;
                                                  const groupOriginPathName = groupEvent.originPathName;
                                                  return (
                                                       <div key={groupIndex} className="group-event">
                                                            <div
                                                                 className={`group-headline ${
                                                                      !previewGroupDestinationAccordion.includes(groupIndex) ? 'show' : ''
                                                                 }`}
                                                                 onClick={() => handleTogglePreviewGroupDestination(groupIndex)}
                                                            >
                                                                 <span className="group-page-url">{groupOriginPathName}</span>
                                                                 <span className="line" />
                                                                 <span className="icon-toggle" />
                                                            </div>
                                                            <CCollapse visible={!previewGroupDestinationAccordion.includes(groupIndex)}>
                                                                 <div className="accordion-body">
                                                                      {groupEventsPreview.map((event, index) => {
                                                                           const indexEvent =
                                                                                groupDestinationFeatures.length *
                                                                                     groupEvent.destinationsPreview.length -
                                                                                groupIndex * groupEvent.destinationsPreview.length -
                                                                                index;

                                                                           return (
                                                                                <DestinationEventPreview
                                                                                     key={indexEvent}
                                                                                     groupIndex={groupIndex}
                                                                                     uniqueId={indexEvent}
                                                                                     event={event}
                                                                                     renderingCode={renderingCode}
                                                                                     index={indexEvent}
                                                                                     setStoreBrowser={setStoreBrowser}
                                                                                     setCustomValues={setCustomValues}
                                                                                     setLastLevelAccordionOpened={setLastLevelAccordionOpened}
                                                                                     handleTogglePreviewEvent={handleTogglePreviewEvent}
                                                                                     previewEventAccordion={previewEventAccordion}
                                                                                     handleTogglePreviewDestination={handleTogglePreviewDestination}
                                                                                     previewDestinationAccordion={previewDestinationAccordion}
                                                                                     listEventsId={listEventsId}
                                                                                     handleViewEventbyDestination={handleViewEventbyDestination}
                                                                                     destinationChildShow={destinationChildShow}
                                                                                     setDestinationChildShow={setDestinationChildShow}
                                                                                />
                                                                           );
                                                                      })}
                                                                 </div>
                                                            </CCollapse>
                                                       </div>
                                                  );
                                             })}
                                        </>
                                   )}
                              </div>
                         </>
                    ) : (
                         <>
                              <div className="preview-listener-select-wrapper d-flex align-items-center justify-content-between">
                                   {listenerSelected ? (
                                        <p className="mb-0">
                                             Real-time{' '}
                                             <CLink as={Link} to={`/${listenerSelected.alias}`} onClick={handleTogglePreviewPopup}>
                                                  {listenerSelected.name}
                                             </CLink>{' '}
                                             events from {domain}
                                        </p>
                                   ) : (
                                        <p className="mb-0">Real-time events from {domain}</p>
                                   )}
                                   <div
                                        className={`select-wrap ${
                                             previewListener && previewListener.length > 0 ? `selected select-${previewListener.length}` : ''
                                        }`}
                                   >
                                        <Select
                                             classNamePrefix="react-select"
                                             ref={selectRef}
                                             name="preview-listener-select"
                                             className="text-left preview-listener-select"
                                             options={listenersActiveOptions}
                                             formatOptionLabel={formatOptionLabel}
                                             onChange={(selected) => handleSelectPreviewListener(selected)}
                                             value={previewListener ? previewListener : null}
                                             placeholder={filterPlaceholder}
                                             onMenuClose={onListenerFilterMenuClose}
                                             isMulti={true}
                                             closeMenuOnSelect={false}
                                             openMenuOnFocus
                                        />
                                        <span className="clear-filter" onClick={() => setPreviewListener(null)}>
                                             <CIcon id="iconClose" icon="cil-x" />
                                        </span>
                                   </div>
                              </div>
                              <div className="preview-events">
                                   <p className="text-center mt-2">Please be patient. Data Layer events will take a few seconds to appear here.</p>
                                   {groupEventFeatures.length > 0 && (
                                        <>
                                             {groupEventFeatures.map((groupEvent, groupIndex) => {
                                                  const groupEventsPreview = groupEvent.eventsPreview;
                                                  const groupOriginPathName = groupEvent.originPathName;
                                                  return (
                                                       <div key={groupIndex} className="group-event">
                                                            <div
                                                                 className={`group-headline ${
                                                                      !previewGroupAccordion.includes(groupIndex) ? 'show' : ''
                                                                 }`}
                                                                 onClick={() => handleTogglePreviewGroup(groupIndex)}
                                                            >
                                                                 <span className="group-page-url">{groupOriginPathName}</span>
                                                                 <span className="line" />
                                                                 <span className="icon-toggle" />
                                                            </div>
                                                            <CCollapse visible={!previewGroupAccordion.includes(groupIndex)}>
                                                                 <div className="accordion-body">
                                                                      {groupEventsPreview.map((event, index) => {
                                                                           // const uniqueId = `${groupEventFeatures.length - groupIndex}-${groupEventsPreview.length - index}`;
                                                                           // const indexEvent = groupEventFeatures.length * groupEvent.eventsPreview.length - groupIndex * groupEvent.eventsPreview.length - index;

                                                                           const numberIndex = listEventsId.findIndex(
                                                                                (e) => e === event.fullCode.eventID
                                                                           );
                                                                           return (
                                                                                <>
                                                                                     {' '}
                                                                                     {event.fullCode.eventID && (
                                                                                          <PreviewFeature
                                                                                               key={numberIndex}
                                                                                               groupIndex={groupIndex}
                                                                                               uniqueId={event.fullCode.eventID}
                                                                                               event={event}
                                                                                               renderingCode={renderingCode}
                                                                                               index={listEventsId.length - numberIndex}
                                                                                               setStoreBrowser={setStoreBrowser}
                                                                                               setCustomValues={setCustomValues}
                                                                                               setLastLevelAccordionOpened={
                                                                                                    setLastLevelAccordionOpened
                                                                                               }
                                                                                               handleTogglePreviewEvent={handleTogglePreviewEvent}
                                                                                               previewEventAccordion={previewEventAccordion}
                                                                                               listenerChildShow={listenerChildShow}
                                                                                               setListenerChildShow={setListenerChildShow}
                                                                                          />
                                                                                     )}
                                                                                </>
                                                                           );
                                                                      })}
                                                                 </div>
                                                            </CCollapse>
                                                       </div>
                                                  );
                                             })}
                                        </>
                                   )}
                              </div>
                         </>
                    )}
               </div>
          </div>
     );
};

export default ThePreviewFeature;
