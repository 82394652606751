import {
     CButton,
     CModal,
     CModalBody,
     CModalFooter
} from '@coreui/react';
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { fetchAllRulesRequest, setActiveListener } from '../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../apiCaller';
import { API_CLIENT_RULE, RULE_GROUP } from '../../../../../constants';
import { getListenerColorIcon } from '../../../../../helpers/cms/subscriber.js';
import { toastError } from '../../../../../utils';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';
import Step1 from './Step1';
import LookupRuleStep2 from './lookup/EditStep2';
import LookupRuleStep3 from './lookup/EditStep3';
import LookupRuleStep4 from './lookup/EditStep4';

const EditLookupRule = ({ rule }) => {
     const [stepsData, setStepsData] = useState(cloneDeep(rule));
     return (
          <>
               <LookupRuleStep2
                    stepsData={stepsData}
                    setStepsData={setStepsData}
               />
               <LookupRuleStep3
                    stepsData={stepsData}
                    setStepsData={setStepsData}
               />
               <LookupRuleStep4
                    stepsData={stepsData}
                    setStepsData={setStepsData}
               />
          </>
     )
}

export const RuleSettingContext = React.createContext({});

const EditLookupSetting = ({ id }) => {
     const dispatch = useDispatch();
     const redirectRules = useSelector(state => state.subscriber.redirectRules);
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const accountListeners = useSelector((state) => state.subscriber.accountListeners);
     const editingRuleReload = useSelector(state => state.subscriber.editingRuleReload);
     const customVariableRules = useSelector(state => state.subscriber.customVariableRules);
     const activeListener = useSelector((state) => state.subscriber.activeListener);

     const [rule, setRule] = useState();
     const [modal, setModal] = useState(false);
     const [ruleType, setRuleType] = useState('');
     const [activeStep, setActiveStep] = useState(2);
     const [fetchRuleLoading, setFetchRuleLoading] = useState();

     const listenerIconSrc = activeListener ? getListenerColorIcon(activeListener) : '';

     const fetchRule = () => {
          setFetchRuleLoading(true);

          callTokenApi(`${API_CLIENT_RULE}${id}`, 'GET')
               .then(response => {
                    if (response.status === 200) {
                         const { rule } = response.data;

                         if (rule.accountId !== activeAccount.id) {
                              window.location.href = '/';
                         }
                         setRuleType(rule.type);
                         setRule(rule);

                         const currentListener = accountListeners ? accountListeners.find(listener => listener.id === rule.listenerId) : null;
                         if (currentListener) {
                              dispatch(setActiveListener(currentListener));
                         }
                    } else {
                         toastError(response)
                    }
               })
               .finally(() => {
                    setFetchRuleLoading(false);
               });
     }
     useEffect(fetchRule, [editingRuleReload, accountListeners]); // eslint-disable-line react-hooks/exhaustive-deps

     const fetchAllRules = () => {
          if (!customVariableRules) {
               dispatch(fetchAllRulesRequest(activeAccount.id, RULE_GROUP.DATA_LAYER));
          }
     };
     useEffect(fetchAllRules, []); // eslint-disable-line react-hooks/exhaustive-deps

     return (
          <>
               {fetchRuleLoading ? (
                    <CenterSpinner />
               ) : (
                    <div className='rule-detail'>
                         <div className="cvr-create-new custom-rule">
                              {redirectRules.status && <Redirect to={redirectRules.to} />}
                              {rule && ruleType && activeListener && (
                                   <>
                                        <h1 className="d-flex">
                                             {listenerIconSrc && <img className="listener-icon-headline" src={listenerIconSrc} alt={activeListener.name + ' icon'} />}
                                             Edit {ruleType}: {rule.name}
                                        </h1>
                                        {activeListener && <h3>Listener: {activeListener.name}</h3>}
                                        <p>Use the steps below to configure your rule.</p>
                                        <Step1
                                             activeStep={activeStep}
                                             setActiveStep={setActiveStep}
                                             ruleType={ruleType}
                                             setRuleType={setRuleType}
                                             actionType='edit'
                                        />
                                        <RuleSettingContext.Provider
                                             value={{
                                                  activeStep,
                                                  setRule,
                                                  setActiveStep,
                                                  fetchRule,
                                                  activeListener
                                             }}
                                        >
                                             <EditLookupRule
                                                  rule={rule}
                                             />
                                        </RuleSettingContext.Provider>
                                        <CModal
                                             visible={modal}
                                             alignment="center"
                                             portal={false}
                                             onClose={() => setModal(false)}
                                        >
                                             <CModalBody className="text-center">
                                                  <h3 className="mb-4">Warning! This Will Change Your Rule</h3>
                                                  <p>
                                                       You are about to change your rule type. Doing this will reset and remove all settings inside this rule other than the name and description.
                                                  </p>
                                                  <p>Are you sure you want to do this? You cannot undo this once you publish it!</p>
                                             </CModalBody>
                                             <CModalFooter className="text-center justify-content-center">
                                                  <CButton
                                                       color="primary"
                                                       className="mr-3 text-uppercase"
                                                       onClick={() => setModal(false)}>Cancel</CButton>
                                                  <CButton
                                                       color="secondary"
                                                       onClick={() => { }}
                                                  >Save Change and Reset Rule</CButton>
                                             </CModalFooter>
                                        </CModal>
                                   </>
                              )}
                         </div>
                    </div>
               )
               }
          </>
     )
}

export default EditLookupSetting;
