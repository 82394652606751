import React from 'react';
import {
    CModal,
    CModalBody,
    CButton,
} from '@coreui/react'
import CIcon from '@coreui/icons-react';
import PropTypes from 'prop-types';

const ConfirmRemovePopup = ({ show, onClose, isLoading, onAccept, children, title = "Are you sure?", acceptText = "PROCEED TO DELETE", rejectText = "Cancel", isIcon = true }) => {
    return (
        <div className="remove-popup">
            <CModal
                visible={show}
                onClose={onClose}
                color="primary"
                alignment='center'
                portal={false}
            >
                <CModalBody className="text-center">
                    <CIcon
                        icon="cil-x"
                        onClick={onClose}
                        className="icon-close-popup"
                    ></CIcon>
                    {isIcon && <div className="remove-popup-icon"><CIcon icon="icon-delete" className="text-danger" height={38} alt="CoreUI Icons Delete" /></div>}
                    <h2>{title}</h2>
                    <div className="remove-popup-description">{children}</div>
                    <div className="btn-accept position-relative mb-3">
                        <CButton color="danger" className="mr-3 text-uppercase d-inline-block" onClick={onAccept} disabled={isLoading}>
                            {isLoading ? (<span className="dots-waiting">Waiting</span>) : acceptText}
                        </CButton>
                    </div>
                    <div className="mb-3">
                        <CButton
                            className="d-inline-block btn-cancel"
                            onClick={onClose}
                        >
                            {rejectText}
                        </CButton>
                    </div>
                </CModalBody>
            </CModal>
        </div>
    )
}

ConfirmRemovePopup.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    isLoading: PropTypes.bool,
    onAccept: PropTypes.func,
}

export default ConfirmRemovePopup
