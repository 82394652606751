import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Context } from './Dropdown'
import { CLink } from '@coreui/react';
import { tagPropType } from '../../../utils';

//component - CoreUI / CDropdownItem
const DropdownItem = props => {
     let {
          tag,
          className,
          //
          ref,
          onClick,
          color,
          divider,
          header,
          active,
          disabled,
          clickDoesNotClose,
          ...rest
     } = props

     const { setIsOpen } = useContext(Context)

     const isItem = !(header || divider)

     const click = e => {
          if (disabled) {
               return
          }
          onClick && onClick(e)
          isItem && !clickDoesNotClose && setIsOpen(false)
     }

     //render
     const tabIndex = isItem && !disabled ? null : -1
     const role = tabIndex === null ? 'menuitem' : undefined
     const Tag = tag || (!isItem ? 'div' : CLink)
     // const ref = { [`${typeof Tag === 'string' ? 'ref' : 'innerRef'}`]: innerRef }

     const classes = classNames(
          className,
          'dropdown-' + (header ? 'header' : divider ? 'divider' : 'item'),
          { active },
          color && 'bg-' + color,
          disabled && Tag !== CLink && 'disabled'
     )

     return (
          <Tag
               className={classes}
               tabIndex={tabIndex}
               role={role}
               disabled={disabled}
               {...rest}
               onClick={click}
               // {...ref}
               ref={ref}
               {...(rest.to && {as:Link})}
          />
     )
}

DropdownItem.propTypes = {
     tag: tagPropType,
     className: PropTypes.string,
     //
     ref: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
     color: PropTypes.string,
     divider: PropTypes.bool,
     header: PropTypes.bool,
     disabled: PropTypes.bool,
     onClick: PropTypes.func,
     active: PropTypes.bool,
     clickDoesNotClose: PropTypes.bool
}

export default DropdownItem
