import React from 'react';
import { useSelector } from 'react-redux';
import { CSpinner } from '@coreui/react';
import Rules from './Rules';

export const CUSTOM_FORM_INITIAL_RULE_ROW = {
     attribute: '',
     operator: '',
     value: '',
     conjunction: '',
};

const TargetForms = ({ onBack, data, listData }) => {
     const customFormTargetRules = useSelector((state) => state.subscriber.customFormTargetRules);
     return (
          <div className='target-forms rule-detail'>
               {!customFormTargetRules ? (
                    <div className='mt-4 text-center'>
                         <CSpinner color='primary' />
                    </div>
               ) : (
                    <Rules onBack={onBack} data={data} listData={listData} />
               )}
          </div>
     );
};

export default TargetForms;
