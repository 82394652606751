import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';
// import CIcon from '@coreui/icons-react';

import { signout } from '../../helpers/auth';
// import { SUPPORT_PATH } from '../../constants';
import { setOverlay } from '../../actions/common';
import CImg from '../../components/migration/CImg';

const SupportAccountDropdown = () => {
     const dispatch = useDispatch();
     const [dropdownProps, setDropdownProps] = useState({ //eslint-disable-line
          onBlurEnabled: false,
          isDropdownItemFocusing: false,
     });
     const user = useSelector((state) => state.theme.user);

     // const toggleShow = () => {
     //      const dropdownClass = document.getElementsByClassName('user-nav-item')[0].className;

     //      if (dropdownClass.includes('show')) {
     //           setDropdownProps({ onBlurEnabled: false, isDropdownItemFocusing: false })
     //           dispatch(setOverlay(false));
     //      } else {
     //           setDropdownProps({ onBlurEnabled: true, isDropdownItemFocusing: false })
     //           dispatch(setOverlay(true));
     //      }
     // }

     const onFocusDropdownMenu = () => {
          setDropdownProps({ onBlurEnabled: true, isDropdownItemFocusing: true });
          dispatch(setOverlay(true));
     };

     return (
          <CDropdown inNav className="header-nav-items user-nav-item" direction="down">
               <CDropdownToggle
                    className="header-nav-link"
                    caret={false}
                    // onClick={toggleShow}
                    // onBlur={dropdownProps.onBlurEnabled ? toggleShow : null}
               >
                    <div className="c-avatar" style={{ padding: '10px 0' }}>
                         <CImg src="/user-default-avatar.png" className="c-avatar-img" alt="avatar" style={{ height: '36px' }} />
                    </div>
               </CDropdownToggle>
               <CDropdownMenu
                    className="pt-0 account-dropdown"
                    placement="bottom-end"
                    style={{ border: 'none', minWidth: '200px'}}
                    // onBlur={dropdownProps.isDropdownItemFocusing ? toggleShow : null}
               >
                    <CDropdownItem header tag="div" onFocus={onFocusDropdownMenu} style={{ display: 'flex', flexDirection: 'column', padding: '10px 15px' }}>
                         {/* <CIcon icon="arrow-account" className="arrow-account" /> */}
                         <span style={{ fontSize: '.875rem', color: '#18222b', fontWeight: '500' }}>My Account</span>
                         <p className="account-user-email m-0" style={{ color: '#c8ced3', fontSize: '11px' }}>{user ? user.email : ''}</p>
                    </CDropdownItem>
                    {/* <CDropdownItem to={SUPPORT_PATH.PROFILE}>
                         My Profile
                    </CDropdownItem> */}
                    <CDropdownItem className="sign-out text-danger" style={{padding: '0px 15px'}} onClick={signout}>
                         SIGN OUT
                    </CDropdownItem>
               </CDropdownMenu>
          </CDropdown>
     );
};

export default React.memo(SupportAccountDropdown);
