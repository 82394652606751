import React, { useContext, useMemo, useEffect, useState } from 'react';
import { EDIT_FULL_LIST_TABLE_COLUMNS } from '../../constants';
import CIcon from '@coreui/icons-react';
import { debounce } from 'lodash';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { CInput, CSelect } from '../../../../../../migration/CInput';
import { FullListTableContext } from '.';
import CTooltip from '../../../../../../migration/CTooltip';
import { useSelector } from 'react-redux';


const TableHeaderRow = () => {
     const targetAccountsReLoad = useSelector((state) => state.subscriber.targetAccountsReLoad);
     const { searchObject, setSearchObject, setPagination, setDataInPage, currentBO } = useContext(FullListTableContext);

     const [currentSearch, setCurrentSearch] = useState(searchObject)

     useEffect(() => {
          if (Object.keys(currentSearch).length > 0) {
               setCurrentSearch({})
          } 
     }, [targetAccountsReLoad]) // eslint-disable-line

     const searchItems = useMemo(() => {
          return debounce(() => {
               setSearchObject(currentSearch);
               setDataInPage({})
               setPagination(prev => {
                    return {
                         ...prev,
                         page: 1
                    }
               })
          }, 1000);
     }, [currentSearch, targetAccountsReLoad]); // eslint-disable-line

     useEffect(() => {
          searchItems();

          return () => {
               searchItems.cancel();
          };

     }, [currentSearch]); // eslint-disable-line

     const onChangeInput = (value, key) => {
          setCurrentSearch(prev => {
               return {
                    ...prev,
                    [key]: value
               }
          })
     }

     return (
          <>
               <tr className="header-row table-wrapper">
                    {EDIT_FULL_LIST_TABLE_COLUMNS.map((col) => {
                         return (
                              <td key={col.id} style={{ minWidth: `${col.minWidth}`, textAlign: `${col.textAlign}`}}>
                                   {col.name}

                                   {col.name === 'Profile' && (
                                        <span className="tooltip-wrapper ml-2">
                                             <Tippy
                                                  className='target-account'
                                                  content={
                                                       <div className='tooltip-content'>
                                                            <span> Please publish and wait a minute to view the profile! </span>
                                                       </div>
                                                  }
                                                  placement='top'
                                                  maxWidth='340px'
                                                  theme="segment"
                                             >
                                                  <span>
                                                       <CIcon icon='icon-info' height={12} width={12} />
                                                  </span>
                                             </Tippy>
                                        </span>
                                   )}
                              </td>
                         );
                    })}
               </tr>
               <tr className="data-row table-wrapper">
                    {EDIT_FULL_LIST_TABLE_COLUMNS.map((col) => {
                         return (
                              <td key={col.id} style={{ minWidth: `${col.minWidth}`, width: col.width }}>
                                   {col.name !== 'Profile' && col.name !== 'BO' && (
                                        <CInput
                                             value={currentSearch[col.id] ? currentSearch[col.id] : ''}
                                             onChange={(e) => onChangeInput(e.target.value, col.id)}
                                        />
                                   )}

                                   {col.name === 'BO' && !currentBO && (
                                        <div className='filter-bo'>
                                             <CSelect
                                                  name="type"
                                                  onChange={(e) => onChangeInput(e.target.value === 'none' ? '' : e.target.value === 'true' ? true : false, col.id)}
                                                  value={typeof currentSearch[col.id] === 'string' ? 'none' : `${currentSearch[col.id]}`}
                                                  placeholder='Select'
                                             >
                                                  <option value="">Select</option>
                                                  <option className="text-dark" value={"true"}>
                                                       True
                                                  </option>
                                                  <option className="text-dark" value={"false"}>
                                                       False
                                                  </option>
                                             </CSelect>
                                             {typeof currentSearch[col.id] === 'boolean' && (
                                                  <span className="btn-remove-row">
                                                       <CTooltip content="Remove filter" placement="bottom">
                                                            <i
                                                                 className="fal fa-times text-danger"
                                                                 onClick={() => onChangeInput('', col.id)}
                                                            ></i>
                                                       </CTooltip>
                                                  </span>
                                             )}

                                        </div>
                                   )}

                              </td>
                         );
                    })}
               </tr>
          </>
     );
};

export default TableHeaderRow;
