import React, { memo, useMemo } from 'react';
import Select from 'react-select';
import { CHECK_LIST_OPERATOR_OPTION } from '../../../../../../constants';
import { CInvalidFeedback } from '../../../../../migration/CInvalidFeedback';
import CLabel from '../../../../../migration/CLabel';
import SelectInsertVariable from './SelectInsertVariable';
import { CHECK_LIST_CONDITION } from '../constants';
import { CInput } from '../../../../../migration/CInput';

const Cfilters = (props) => {
     const {
          changeAValueOfGroup,
          lvl2Group,
          lvl1GroupIndex,
          lvl2GroupIndex,
          condition,
          filterEvent,
          handleKeyDown,
          error = {},
          checklistOptions,
          recordProfile,
          handleHasChange,
          isDisableEdit,
          isFindAccount
     } = props;

     const excludeTypes = ['Domain', 'Company Name']

     const newCondition = useMemo(() => {
          if (!isFindAccount && excludeTypes.includes(lvl2Group.type)) {
               return condition
          }

          return [...condition, CHECK_LIST_CONDITION]; 
     }, [lvl2Group.type]); // eslint-disable-line
     
     const selectOption = useMemo(
          () => newCondition && newCondition.find((option) => option.value === lvl2Group.operator),
          [newCondition, lvl2Group.operator],
     ); 

     return (
          <>
               <div className="select-type" style={{ width: '220px', flexShrink: 0 }}>
                    <CLabel className="text-decoration-none">Condition</CLabel>
                    <Select
                         classNamePrefix="react-select"
                         value={selectOption ? { label: selectOption.label } : { label: 'Select...' }}
                         onChange={(e) => {
                              changeAValueOfGroup({
                                   lvl1GroupIndex,
                                   lvl2GroupIndex,
                                   propName: 'operator',
                                   propValue: e.value,
                              });

                              if (typeof handleHasChange === 'function') {
                                   handleHasChange();
                              }
                         }}
                         className={`${error.operator ? 'is-invalid' : ''}`}
                         options={newCondition.length > 0 && newCondition}
                         onKeyDown={(e) => handleKeyDown && handleKeyDown(e, filterEvent)}
                         isDisabled={recordProfile && lvl1GroupIndex === 0 && lvl2GroupIndex === 0}
                    />
                    {error.operator && <CInvalidFeedback>{error.operator}</CInvalidFeedback>}
               </div>
               <div
                    className="input-value-rules field-value-search"
                    style={{ width: 'unset', maxWidth: '100%', minWidth: '300px', flex: 'unset' }}
               >
                    <CLabel className="label-holder"></CLabel>
                    <div>
                         { !isFindAccount && excludeTypes.includes(lvl2Group.type) ? (
                              <CInput
                                   value={lvl2Group.value}
                                   // className={`${isDisabled ? 'disable-select' : ''}`}
                                   onChange={(e) => {
                                        changeAValueOfGroup({
                                             lvl1GroupIndex,
                                             lvl2GroupIndex,
                                             propName: 'value',
                                             propValue: e.target.value,
                                        })
                                        if (typeof handleHasChange === 'function') {
                                             handleHasChange();
                                        }
                                   }}
                                   invalid={!!error.value}
                              />
                         ) : (
                              lvl2Group.operator === CHECK_LIST_OPERATOR_OPTION.value ? (
                              <SelectInsertVariable
                                   id={lvl2Group.id}
                                   options={checklistOptions[lvl2Group.id] || []}
                                   value={lvl2Group.value || []}
                                   onChange={(e, value) => {
                                        changeAValueOfGroup({
                                             lvl1GroupIndex,
                                             lvl2GroupIndex,
                                             propName: 'value',
                                             propValue: value,
                                        })
                                        if (typeof handleHasChange === 'function') {
                                             handleHasChange();
                                        }
                                   }}
                                   isMulti={true}
                                   dataVariable={false}
                                   selectInputType={false}
                                   error={error.checklist}
                                   isClearable={true}
                                   classNameWrapper={`${!!error.value
                                        ? 'is-invalid'
                                        : ''
                                        }`}
                                   isDisabled={isDisableEdit}

                              />
                         ) : (
                              <SelectInsertVariable
                                   id={lvl2Group.id}
                                   options={checklistOptions[lvl2Group.id] || []}
                                   value={(checklistOptions[lvl2Group.id] && checklistOptions[lvl2Group.id].find((item) => item.value === lvl2Group.value)) || { label: lvl2Group.label || '', value: lvl2Group.value || '' }}
                                   onChange={(value) => {
                                        changeAValueOfGroup({
                                             lvl1GroupIndex,
                                             lvl2GroupIndex,
                                             propName: 'value',
                                             propValue: value.value,
                                        })
                                        if (typeof handleHasChange === 'function') {
                                             handleHasChange();
                                        }
                                   }}
                                   dataVariable={false}
                                   selectInputType={isFindAccount ? false : true}
                                   error={error.checklist}
                                   isClearable={true}
                                   classNameWrapper={`${!!error.value
                                        ? 'is-invalid'
                                        : ''
                                        }`}
                                   isDisabled={isDisableEdit}
                              />
                         )
                         )}

                         {error.value && <CInvalidFeedback>{error.value}</CInvalidFeedback>}
                    </div>
               </div>
          </>
     );
};

export default memo(Cfilters);
