import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { TransitionPropTypeKeys, tagPropType } from './utils/helper.js'
import { Transition } from 'react-transition-group'

//component - CoreUI / CFade
const CFade = (props) => {
  const omitByKeys = (originalObject, keys) => {
    const newObj = {};
    const objKeys = Object.keys(originalObject);
    for (let i = 0; i < objKeys.length; i++) {
      if (!keys.includes(objKeys[i])) {
        newObj[objKeys[i]] = originalObject[objKeys[i]];
      }
    }
    return newObj;
  };

  const pickByKeys = (originalObject, keys) => {
    const newObj = {};
    for (let i = 0; i < keys.length; i++) {
      newObj[keys[i]] = originalObject[keys[i]];
    }
    return newObj;
  };

  const {
    tag= 'div',
    className,
    children,
    innerRef,
    baseClass,
    baseClassActive,
    ...rest
  } = props;

  const Tag = tag;
  const transitionProps = pickByKeys(rest, TransitionPropTypeKeys);
  const childProps = omitByKeys(rest, TransitionPropTypeKeys);

  if (!transitionProps.timeout) {
    transitionProps.timeout = 300;
  }
  
  const ref = useRef();
  if (typeof innerRef === 'function') {
    innerRef(ref);
  } else if (typeof innerRef === 'object') {
    ref.current = innerRef;
  }

  return (
    <Transition {...transitionProps} nodeRef={ref}>
      {(status) => {
        const isActive = status === 'entered';
        const classes = classNames(
          className,
          baseClass,
          isActive && baseClassActive
        );
        return (
          <Tag className={classes} {...childProps} ref={ref}>
            {children}
          </Tag>
        );
      }}
    </Transition>
  );
};

CFade.propTypes = {
  tag: tagPropType,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  baseClass: PropTypes.string,
  baseClassActive: PropTypes.string,
};

// CFade.defaultProps = {
//   tag: 'div',
//   baseClass: 'fade',
//   baseClassActive: 'show',
//   timeout: 150,
//   appear: true,
//   enter: true,
//   exit: true,
//   in: true,
// };


export default CFade
