import React, { useContext, useState } from 'react';
import { CCard, CCardBody, CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from './CreateAccount';
import { useDispatch, useSelector } from 'react-redux';
import { callTokenApi } from '../../../../../apiCaller';
import { API_CLIENT_USER_INFO } from '../../../../../constants';
import { setUser } from '../../../../../actions/common';
import { toastError } from '../../../../../utils';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';
import { setShowCreateAccountPopup } from '../../../../../actions/subscriber';

const UserCentralize = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.theme.user);
    const { stepsData, setStepsData, setCurrentStep, prevSteps, account } = useContext(CreateAccountContext);
    const [isLoading, setIsLoading] = useState(false);
    const dataUser = (user && JSON.parse(user.registerUserInfo)) || JSON.parse(stepsData.dataUser)
    const { completedSteps } = account

    const goToNextStep = () => {
        setCurrentStep(null, CHANGE_STEP_TYPE.mainStepNext);
    };


    const saveData = (dataUser) => {
        setIsLoading(true)
        callTokenApi(API_CLIENT_USER_INFO, 'PUT', { registerUserInfo: dataUser })
            .then(response => {
                if (response.status === 200) {
                    setStepsData({ ...stepsData, dataUser: JSON.stringify(dataUser) })
                    dispatch(setUser({ ...user, registerUserInfo: JSON.stringify(dataUser) }));
                    goToNextStep();
                } else {
                    setIsLoading(false)
                    toastError(response);
                }
            })
    };

    const goNext = (value) => {
        if (dataUser.centralize) {
            if (dataUser.centralize === value) {
                goToNextStep();
            } else {
                dataUser.centralize = value;
                saveData(dataUser);
            }
        } else {
            dataUser.centralize = value;
            saveData(dataUser);
        }
    };

    const onBackBtnClicked = () => {
        if (prevSteps.length > 0) {
            setCurrentStep(null, CHANGE_STEP_TYPE.subStepPrev);
        } else {
            if (completedSteps.includes(STEPS.chooseDestination) && completedSteps.includes(STEPS.configureMeasurement)) {
                dispatch(setShowCreateAccountPopup(false));
            }
            else
                setCurrentStep(STEPS.timeZone);
        }
    };

    return (
        <CreateAccountBody hideStepProgress={true} onBackBtnClicked={onBackBtnClicked}>
            <CCard className="account-almost-done account-dyn ">
                <CCardBody>
                    <h3>Did You Know?</h3>
                    <p className="des">
                        ListenLayer centralizes where and how you define conversions and other critical data...
                    </p>
                    {isLoading ? (
                        <CenterSpinner />
                    ) : (
                        <div>
                            <div className="notice">
                                <div className='d-flex'>
                                    <div className="icon-container">
                                        <CIcon icon="iconCheckCircleBlack" width={22} height={22} />
                                    </div>
                                    <p>And distributes it to advertising platforms and other destinations.</p>
                                </div>
                            </div>
                            <p className='des-revenue'>
                                Is that valuable to you?
                            </p>
                            <div>
                                <CButton className="btn-save" onClick={() => goNext(true)}>
                                    Yes
                                </CButton>
                            </div>
                            <CButton className="btn-navigation-step" onClick={() => goNext(false)}>
                                No, that's not valuable
                            </CButton>
                        </div>
                    )}
                </CCardBody>
            </CCard>
        </CreateAccountBody>
    );
};

export default UserCentralize;
