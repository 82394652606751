import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CIcon from '@coreui/icons-react';
import { signout } from '../../helpers/auth';
import { ADMIN_PATH } from '../../constants';
import { setOverlay } from '../../actions/common';
import CImg from '../../components/migration/CImg';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from '../../components/general/dropdown';

const AdminAccountDropdown = () => {
     const dispatch = useDispatch();
     const [dropdownProps, setDropdownProps] = useState({
          onBlurEnabled: false,
          isDropdownItemFocusing: false,
     });
     const user = useSelector((state) => state.theme.user);

     const toggleShow = () => {
          const dropdownClass = document.getElementsByClassName('user-nav-item')[0].className;

          if (dropdownClass.includes('show')) {
               setDropdownProps({ onBlurEnabled: false, isDropdownItemFocusing: false });
               dispatch(setOverlay(false));
          } else {
               setDropdownProps({ onBlurEnabled: true, isDropdownItemFocusing: false });
               dispatch(setOverlay(true));
          }
     };

     const onFocusDropdownMenu = () => {
          setDropdownProps({ onBlurEnabled: true, isDropdownItemFocusing: true });
          dispatch(setOverlay(true));
     };

     return (
          <Dropdown isHeaderDropdown inNav className="header-nav-items user-nav-item" direction="down">
               <DropdownToggle
                    className="header-nav-link"
                    caret={false}
                    onClick={toggleShow}
                    onBlur={dropdownProps.onBlurEnabled ? toggleShow : null}
               >
                    <div className="avatar" style={{ width: '36px', height: '36px' }}>
                         <CImg src="/user-default-avatar.png" className="avatar-img" alt="avatar" />
                    </div>
               </DropdownToggle>
               <DropdownMenu
                    className="pt-0 account-dropdown"
                    placement="bottom-end"
                    onBlur={dropdownProps.isDropdownItemFocusing ? toggleShow : null}
               >
                    <DropdownItem header tag="div" onFocus={onFocusDropdownMenu}>
                         <CIcon icon="arrow-account" className="arrow-account" />
                         <span>My Account</span>
                         <p className="account-user-email m-0">{user ? user.email : ''}</p>
                    </DropdownItem>
                    <DropdownItem to={ADMIN_PATH.PROFILE}>My Profile</DropdownItem>
                    <DropdownItem className="sign-out text-danger" onClick={signout}>
                         SIGN OUT
                    </DropdownItem>
               </DropdownMenu>
          </Dropdown>
     );
};

export default React.memo(AdminAccountDropdown);
