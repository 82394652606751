import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
     Link,
     // useHistory
} from 'react-router-dom';
import CIcon from '@coreui/icons-react';

import { SUBSCRIBER_PATH, LIMITATION_TYPE, COMPONENT_NAME } from '../../../constants';
import { CButton, CLink } from '@coreui/react';
import { setAsideShow, setFlexibleModal } from '../../../actions/common';
import { setLimitationType, toggleAsideLimitationHit, setUnsavedLookupTableModal, setAccountPastdue, setShowUpgradeAccountPopup } from '../../../actions/subscriber';
import { useMainInvoiceItems } from '../../../helpers/customHooks';

const GlobalMessage = () => {
     const dispatch = useDispatch();
     const [globalMessage, setGlobalMessage] = useState(false);
     const activeListeners = useSelector(state => state.subscriber.activeListeners);
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const activeFeatures = useSelector(state => state.subscriber.activeFeatures);
     const accountPastDue = useSelector(state => state.subscriber.accountPastDue);
     const { showNewLayout } = useSelector((state) => state.subscriber.newLayout);
     const isLookupTableUnsaved = useSelector(state => state.subscriber.unsavedLookupTableModal.unsaved);
     // const history = useHistory();
     var content = null;
     let messageType = '';
     const {
          isOwner,
          numberOfListeners,
          numberOfDomains,
          packageLowEventWarningThreshold,
          packageLowEventOutOfEvents,
          packageLowEventAfterOutOfEvents,
          packageIsPaused,
          id: accountId,
          confirmPayment,
          billingRight,
          isBlocked,
          secondId
     } = activeAccount;
     const { accountNumberOfInvoiceItemsPerMonth, packageNumberOfInvoiceItemsPerMonth } = useMainInvoiceItems();
     const showLowEventWarning = !activeAccount.packageIsCustom && (accountNumberOfInvoiceItemsPerMonth >= packageLowEventWarningThreshold);
     const checkShowMessage = packageNumberOfInvoiceItemsPerMonth !== 1 && (accountNumberOfInvoiceItemsPerMonth > packageNumberOfInvoiceItemsPerMonth);

     const setMessages = () => {
          if (!globalMessage) {
               setGlobalMessage(true);
          }
     }

     useEffect(setMessages, [activeListeners]); // eslint-disable-line react-hooks/exhaustive-deps

     if (accountPastDue.status === true) {
          messageType = 'accountPastDue';
     } else if (numberOfDomains === 0) {
          messageType = 'domains';
     } else if (numberOfListeners === 0) {
          messageType = 'listeners';
     } else if (!showNewLayout && activeFeatures.length === 0) {
          messageType = 'features';
     } else if (confirmPayment) {
          messageType = 'confirmPayment';
     }
     
     if (isBlocked && packageIsPaused) {
          messageType = 'accountIsBlocked';
     }

     const showModalUpdatePayment = () => {
          if (!accountPastDue.showUpdatePaymentPopup) {
               dispatch(setAccountPastdue({ showUpdatePaymentPopup: true }));
          }
     };

     const handleClickListenerLink = () => {
          dispatch(
               setFlexibleModal({
                    show: true,
                    component: COMPONENT_NAME.LISTENER_LIBRARY,
               })
          );
     };

     switch (messageType) {
          case 'listeners':
               content = (
                    <>
                         <div className="listener-message-text d-flex justify-content-between align-items-center mr-3">
                              <div className="icon-wrapper">
                                   <CIcon icon="cil-layers" className="mr-3" />
                              </div>
                              <span>Listeners must be enabled in order for ListenLayer to function as expected.</span>
                         </div>
                         <div className="listener-message-button"><CLink className="btn btn-outline-dark listener-button" onClick={handleClickListenerLink}>SETUP NOW</CLink></div>
                    </>
               );
               break;
          case 'domains':
               content = (
                    <>
                         <div className="listener-message-text d-flex justify-content-between align-items-center mr-3">
                              <div className="icon-wrapper">
                                   <CIcon icon="cil-code" className="mr-3" width={16} height={16} />
                              </div>
                              <span>You must define your website’s domain name(s) in order for ListenLayer to function as expected.</span>
                         </div>
                         <div className="listener-message-button"><Link className="btn btn-outline-dark listener-button" to={SUBSCRIBER_PATH.WEBSITE.replace(':secondId', secondId)}>SETUP NOW</Link></div>
                    </>
               );
               break;
          case 'features':
               content = (
                    <>
                         <div className="listener-message-text d-flex justify-content-between align-items-center mr-3">
                              <div className="icon-wrapper">
                                   <CIcon icon="cil-code" className="mr-3" />
                              </div>
                              <span>You must activate at least 1 Feature within your Listeners for them to work.</span>
                         </div>
                         <div className="listener-message-button">
                              {
                                   (activeListeners[0] && <Link className="btn btn-outline-dark listener-button" to={`${secondId}/${activeListeners[0].alias}/features`}>SETUP NOW</Link>)
                              }
                         </div>
                    </>
               );
               break;
          case 'accountPastDue':
               const clickBlockPopup = () => {
                    if (typeof accountPastDue.function !== 'undefined') {
                         switch (accountPastDue.function) {
                              case 'goToAccountSetting': {
                                   dispatch(setAccountPastdue({ showUpdatePaymentPopup: !accountPastDue.showUpdatePaymentPopup }));
                                   break;
                              }

                              case 'showModalUpdatePayment': {
                                   dispatch(setAccountPastdue({ showUpdatePaymentPopup: !accountPastDue.showUpdatePaymentPopup }));
                                   break;
                              }

                              case 'upgradeAccount': {
                                   dispatch(setShowUpgradeAccountPopup(true));
                                   break;
                              }

                              default:
                                   break;
                         }
                    }
               }

               content = (
                    <>
                         <div className="listener-message-text d-flex justify-content-between align-items-center mr-3">
                              <div className="icon-wrapper">
                                   <CIcon icon="cil-code" className="mr-3" />
                              </div>
                              <span>{accountPastDue.message}</span>
                         </div>
                         <div className="listener-message-button">
                              {((isOwner || billingRight) && <CButton
                                   className="btn btn-outline-dark listener-button"
                                   onClick={clickBlockPopup}
                              >{accountPastDue.buttonText}</CButton>
                              )}
                         </div>
                    </>
               );
               break;
          case 'confirmPayment':
               content = (
                    <>
                         <div className="listener-message-text d-flex justify-content-between align-items-center mr-3">
                              <div className="icon-wrapper">
                                   <CIcon icon="cil-code" className="mr-3" />
                              </div>
                              <span>Ownership of this account is being transferred to you. Update the payment method to accept the transfer.</span>
                         </div>
                         <div className="listener-message-button">
                              <Link
                                   className="btn btn-outline-dark listener-button"
                                   to={`${SUBSCRIBER_PATH.BILLING_INVOICES.replace(':secondId', secondId)}?accountID=${accountId}#payment-method`}
                                   onClick={showModalUpdatePayment}
                              >UPDATE PAYMENT METHOD</Link>
                         </div>
                    </>
               );
               break;
          case 'accountIsBlocked':
               content = (
                    <div className="listener-message-text d-flex justify-content-between align-items-center mr-3">
                         <div className="icon-wrapper">
                              <CIcon icon="cil-code" className="mr-3" />
                         </div>
                         <span>Your account is paused because your current package is paused.</span>
                    </div>
               );
               break;
          default:
               content = null;
     }

     // Handle fix the issue button when running out of event
     const fixTheIssue = () => {
          dispatch(toggleAsideLimitationHit());

          if (isLookupTableUnsaved) {
               dispatch(setUnsavedLookupTableModal({ show: true, onAccept: () => dispatch(setAsideShow(true)) }));
          } else {
               dispatch(setAsideShow(true));
          }

          if (checkShowMessage) {
               dispatch(setLimitationType(LIMITATION_TYPE.OUT_OF_EVENT), false);
          } else {
               dispatch(setLimitationType(LIMITATION_TYPE.EVENT), false);
          }
     }

     return (
          <>
               {
                    (globalMessage && content) && (
                         <div className="listener-message w-100 d-inline-flex justify-content-between align-items-center bg-listener-message">
                              <div className="listener-message-content d-flex justify-content-between align-items-center">
                                   {content}
                              </div>
                         </div>
                    )
               }
               {
                    (!content && showLowEventWarning) && (
                         <div className="listener-message w-100 d-inline-flex justify-content-between align-items-center bg-listener-message">
                              <div className="d-flex justify-content-between align-items-center">
                                   <CIcon icon="cil-graph" className="mr-2" />
                                   <span className="mr-3">
                                        {checkShowMessage ? (
                                             packageLowEventAfterOutOfEvents
                                        ) : (
                                             packageLowEventOutOfEvents
                                        )}
                                   </span>
                                   {/* {
                                        !maxPackage && (
                                             <CButton
                                                  className="listener-button"
                                                  variant="outline"
                                                  color="dark"
                                                  onClick={fixTheIssue}
                                             >
                                                  LEARN MORE
                                             </CButton>
                                        )
                                   } */}
                                   <CButton
                                        className="listener-button"
                                        variant="outline"
                                        color="dark"
                                        onClick={fixTheIssue}
                                   >LEARN MORE</CButton>
                              </div>
                         </div>
                    )
               }
          </>

     )
}

export default React.memo(GlobalMessage)
