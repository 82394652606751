import React from 'react';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';

const CreateNewMonitoring = React.lazy(() => import('./CreateNewMonitoring'));
const EditMonitoring = React.lazy(() => import('./EditMonitoring'));

const MonitoringRule = ({ id }) => {
     return (
          <div className='rule-detail'>
               <React.Suspense fallback={<CenterSpinner />}>{id ? <EditMonitoring id={id} /> : <CreateNewMonitoring />}</React.Suspense>
          </div>
     );
};

export default MonitoringRule;
