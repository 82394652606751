import React from 'react';
import {
    CModal,
    CModalBody,
    CButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import PropTypes from 'prop-types';

const ConfirmSaveChange = ({ show, onClose, isLoading, onAccept, children, title, btnTextSave, btnTextCancel, isIcon = true, dataForm = true }) => {
    return (
        <div className="confirm-save-popup">
            <CModal
                visible={show}
                onClose={onClose}
                color="primary"
                alignment='center'
                portal={false}
            >
                <CModalBody className="text-center confirm-save">
                    {isIcon && <div className="confirm-save-icon"><CIcon icon="icon-save" height={46} width={46} /></div>}
                    
                    <h1>{title}</h1>
                    <div className="confirm-save-content">
                        {children}
                    </div>
                    <div className="position-relative d-inline-block">
                        {   dataForm && (
                            <CButton
                                className="btn-primary"
                                onClick={onAccept}
                                disabled={isLoading}
                            >
                                {isLoading ? (<span className="dots-waiting">Waiting</span>) : (
                                    btnTextSave ? btnTextSave : "Save Now"
                                )}
                            </CButton>
                        )
                        }
                        <CButton
                            onClick={onClose}
                            className=" btn btn-cancel rounded-pill cancel" 
                        >
                            {btnTextCancel ? btnTextCancel : "Cancel"}
                        </CButton>
                    </div>
                </CModalBody>
            </CModal>
        </div>
    )
}

ConfirmSaveChange.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    isLoading: PropTypes.bool,
    onAccept: PropTypes.func,
    title: PropTypes.string
}

export default ConfirmSaveChange
