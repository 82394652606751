import React from 'react'

const TableHeaderRow = () => {
    return (
        <>
            <tr className="table-header">
                <td className="user-source-header-item">
                    <span>Click URL Element</span>
                </td>
                <td className="user-source-header-item">
                    <span>Match Type</span>
                </td >
                <td className="user-source-header-item">
                    <span>Value</span>
                </td>
                <td className="user-source-header-item">
                    <span>Yes/No</span>
                </td>
            </tr>
        </>
    )
}

export default TableHeaderRow
