import React from 'react';
import uniq from 'lodash/uniq';

export const useOutsideHandlingForFindAccount = (ref, clickOutside, eventListener = 'click', showPopupCreateCategories = false) => {
     React.useEffect(() => {

          if(!ref.current) return;

          const handleClickOutside = (event) => {
               const specificClasses = ['react-select__option--is-focused', 'remove-group', 'find-account-li'];

               const isIncludeChild = ref.current.contains(event.target) || ref.current.outerHTML.includes(event.target.classList.value);
               const isIncludeSpecificChild = specificClasses.some((className) => event.target.classList.value.includes(className));
               const isClickOutside = !isIncludeChild && !isIncludeSpecificChild;

               if (showPopupCreateCategories) {
                    return;
               } else {
                    if (ref.current && isClickOutside) {
                         clickOutside();
                    }
               }
          };

          const handlePressEsc = (event) => {
               if (ref.current && event.key === 'Escape') {
                    clickOutside();
               }
          };

          // Bind the event listener
          document.addEventListener(eventListener, handleClickOutside);
          document.addEventListener('keydown', handlePressEsc);
          return () => {
               // Unbind the event listener on clean up
               document.removeEventListener(eventListener, handleClickOutside);
               document.removeEventListener('keydown', handlePressEsc);
          };
     }, [ref, clickOutside]); // eslint-disable-line
};

export const rearrangeSuggestList = (key, suggestList) => {
     let newList = [...suggestList];

     if (suggestList.find((item) => item === 'None')) {
          newList = suggestList.filter((item) => item !== 'None');

          if (key === 'monthlyVisitors') {
               newList = uniq(newList.map((item) => item.trim().toUpperCase())) || [];
          }

          newList.unshift('None');
     }

     return newList;
};
