import React, { useContext, useEffect, useState } from 'react';
import { CButton, CForm } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useSelector } from 'react-redux';
import { SupportTicketPopupContext, DECISION_STEPS, CREATE_SUPPORT_TICKET } from '../GetHelpLabel';
import { Formik } from 'formik';
import { useDropzone } from 'react-dropzone';
import ReactQuill from 'react-quill';
import 'quill/dist/quill.snow.css';
import { STEP_PREVIOUS_CREATE_TICKET } from '../../../../../constants';
import { toastError } from '../../../../../utils';
import { callTokenApi } from '../../../../../apiCaller';
import { toast } from 'react-toastify';
import { CInput, CInputCheckbox, CSelect } from '../../../../migration/CInput';
import CLabel from '../../../../migration/CLabel';

const CreateSupportTicket = () => {
     const { setCurrentStep, setTicket, ticketType, setTicketType, listenerFeature } = useContext(SupportTicketPopupContext);
     const accounts = useSelector((state) => state.subscriber.accounts);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const createdAccount = useSelector((state) => state.subscriber.createdAccount);
     const listenerInTickets = useSelector((state) => state.subscriber.listenerInTickets);
     const showCreateAccountPopup = useSelector((state) => state.subscriber.showCreateAccountPopup);
     const { ticketType: initTicketType, hideListenerSelect } = useSelector((state) => state.subscriber.ticketInit);

     const { listeners, listenerFeatures } = listenerInTickets;
     // const [dataFeatures, setDataFeatures] = useState();
     // const [_disables, setDisables] = useState(false);
     const [loadings, setLoadings] = useState(false);
     const [valueTextEditor, setTextValueEditor] = useState('');
     const [lengthTextEditor, setLengthTextEditor] = useState(0);
     const [checkNotScreen, setCheckNotScreen] = useState(false);
     const [errorScreenShots, setErrorScreenShots] = useState(false);
     const [fileImages, setFileImages] = useState([]);

     const lengthCharacter = 25;
     // let hideTicketFeatures = localStorage.getItem(HIDE_TICKET_FEATURES) === 'true' ? true : false;

     const setDefaultTicketSupport = () => {
          if (initTicketType) {
               setTicketType(initTicketType);
          } else {
               setTicketType('Getting ListenLayer to work on my website');
          }
     }
     useEffect(setDefaultTicketSupport, [initTicketType]) // eslint-disable-line react-hooks/exhaustive-deps

     const { getRootProps, getInputProps } = useDropzone({
          accept: 'image/*',
          onDrop: (acceptedFiles) => {
               if (acceptedFiles.length > 0) {
                    setErrorScreenShots(false);
                    setCheckNotScreen(false);
               }

               let newAcceptedFiles = [...fileImages];
               let files = newAcceptedFiles.concat(acceptedFiles);
               setFileImages(files);
          },
          noClick: checkNotScreen,
     });

     useEffect(() => {
          if (listenerFeature.listenerId) {
               // setDataFeatures(listenerFeatures[listenerFeature.listenerId]);
               // setDisables(true);
          }
     }, [listenerFeature.listenerId, listenerFeatures]);

     const validationSchema = (values) => {
          let errors;
          if (fileImages.length < 1 && checkNotScreen === false) {
               errors = {
                    screenShots: 'Screenshots are required.',
               };
               setErrorScreenShots(true);
          }
          if (!values.name) {
               errors = { ...errors, name: 'Name is required.' };
          }

          if (!values.accountId) {
               errors = { ...errors, accountId: 'Account Id is required.' };
          }
          if (lengthTextEditor < lengthCharacter) {
               errors = {
                    ...errors,
                    description: 'Can you please describe your issue in more detail? We require 25 characters in the issue description.',
               };
          }
          return errors;
     };

     const removeFile = (key) => {
          let newFileImages = [...fileImages];
          newFileImages.splice(key, 1);
          setFileImages(newFileImages);
     };

     const files = fileImages.map((file, key) => {
          return (
               <li key={file.path}>
                    <span>
                         {file.path} - {file.size} bytes
                    </span>
                    <span className='remove-file-image' onClick={() => removeFile(key)}>
                         x
                    </span>
               </li>
          );
     });

     const modules = {
          toolbar: [['bold', 'italic', 'underline', 'strike', 'blockquote', 'link'], [{ list: 'ordered' }, { list: 'bullet' }], ['clean']],
     };

     const previousStep = () => {
          let step = DECISION_STEPS.step3;
          let stepLocal = localStorage.getItem(STEP_PREVIOUS_CREATE_TICKET);
          if (stepLocal) {
               step = localStorage.getItem(STEP_PREVIOUS_CREATE_TICKET);
          }
          setCurrentStep(step);
     };

     const ticketSubmit = (values) => {
          setLoadings(true);
          let newValues = {
               ...values,
               description: valueTextEditor,
               screenShots: [...fileImages],
          };

          const data = new FormData();
          fileImages.forEach((file) => {
               data.append('file', file);
          });

          data.append('type', ticketType);
          for (let key in newValues) {
               if (newValues[key]) {
                    data.append(key, newValues[key]);
               }
          }
          callTokenApi(`client/tickets`, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         setTicket(response.data.ticket);
                         toast.success(response.data.message);
                         setCurrentStep(CREATE_SUPPORT_TICKET.step2);
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => setLoadings(false));
     };

     return (
          <div className='ticket-popup-content strategy-request-content'>
               <div className='btn-back-ticket'>
                    <CIcon icon='icon-chevron-left' width={6} />
                    <span onClick={previousStep}>Back</span>
               </div>
               <div className='ticket-popup-header'>
                    <h5>Open a Support Ticket</h5>
                    <p>Create a support ticket detailing your issue. Please be thorough so that we can address the problem quickly.</p>
               </div>
               <div className='ticket-popup-body'>
                    <Formik
                         initialValues={{
                              accountId: createdAccount ? createdAccount.id : activeAccount.id,
                              listenerId: listenerFeature.listenerId || '',
                              featureId: listenerFeature.featureId || '',
                              name: '',
                              description: '',
                              screenShots: [],
                         }}
                         onSubmit={ticketSubmit}
                         validate={validationSchema}
                         validateOnChange={false}
                         validateOnBlur={false}
                    >
                         {({ values, handleSubmit, errors, setFieldValue, setFieldError }) => {
                              const handleChangeAccountId = (e) => {
                                   setFieldValue('accountId', e.target.value);
                                   setFieldError('accountId', undefined);
                              };

                              const handleChangeListenerId = (e) => {
                                   setFieldValue('listenerId', e.target.value);
                                   // setDataFeatures(listenerFeatures[e.target.value]);
                              };

                              // const handleChangeFeatureId = (e) => {
                              //     setFieldValue("featureId", e.target.value);
                              // };

                              const handleChangeName = (e) => {
                                   setFieldValue('name', e.target.value);
                                   setFieldError('name', undefined);
                              };

                              const handleNotScreenShots = () => {
                                   if (!checkNotScreen === true) {
                                        setFieldError('screenShots', undefined);
                                        setErrorScreenShots(false);
                                        setFileImages([]);
                                   }
                                   setCheckNotScreen(!checkNotScreen);
                              };

                              const changeTextEditor = (content, delta, source, editor) => {
                                   setTextValueEditor(content);
                                   const lengthEditor = editor.getLength() - 1;
                                   if (lengthEditor >= lengthCharacter) {
                                        setFieldError('description', undefined);
                                   }
                                   setLengthTextEditor(lengthEditor);
                              };

                              return (
                                   <CForm onSubmit={handleSubmit} noValidate>
                                        <div className='ticket-group-row'>
                                             <label>Select the Account</label>
                                             <div className='ticket-group-input'>
                                                  <CSelect name='accountId' onChange={handleChangeAccountId} value={values.accountId}>
                                                       {accounts.map((type) => (
                                                            <option className='text-dark' key={type.accountId} value={type.accountId}>
                                                                 {type.name}
                                                            </option>
                                                       ))}
                                                  </CSelect>
                                                  {errors.accountId && <span className='ticket-invalid-feedback'>{errors.accountId}</span>}
                                             </div>
                                        </div>
                                        {!showCreateAccountPopup && !hideListenerSelect && (
                                             <div className='ticket-group-row'>
                                                  <label>Select a Listener</label>
                                                  <div className='ticket-group-input'>
                                                       <CSelect
                                                            name='listenerId'
                                                            onChange={handleChangeListenerId}
                                                            value={values.listenerId}
                                                            // disabled={_disables}
                                                       >
                                                            <option value=''>None, I have another issue.</option>
                                                            {listeners &&
                                                                 listeners.map((listener) => (
                                                                      <option className='text-dark' key={listener.id} value={listener.id}>
                                                                           {listener.name}
                                                                      </option>
                                                                 ))}
                                                       </CSelect>
                                                  </div>
                                             </div>
                                        )}
                                        {/* {!hideTicketFeatures && dataFeatures && (
                                             <div className="ticket-group-row">
                                                  <label>Select a Feature</label>
                                                  <div className="ticket-group-input">
                                                  <CSelect
                                                       name="featureId"
                                                       onChange={handleChangeFeatureId}
                                                       value={values.featureId}
                                                  // disabled={_disables}
                                                  >
                                                       <option value="">
                                                            My issue is not specific to
                                                            a Feature in this Listener
                                                       </option>
                                                       {dataFeatures.map((type) => (
                                                            <option
                                                                 className="text-dark"
                                                                 key={type.fId}
                                                                 value={type.fId}
                                                            >
                                                                 {type.fName}
                                                            </option>
                                                       ))}
                                                  </CSelect>
                                                  </div>
                                             </div>
                                        )} */}
                                        <div className='ticket-group-row'>
                                             <label>Name your request</label>
                                             <div className='ticket-group-input'>
                                                  <CInput name='name' type='text' onChange={handleChangeName} />
                                                  {errors.name && <span className='ticket-invalid-feedback'>{errors.name}</span>}
                                             </div>
                                        </div>
                                        <div className='ticket-group-column'>
                                             <CLabel htmlFor='description'>Describe your needs</CLabel>
                                             <div className='ticket-group-input'>
                                                  <ReactQuill modules={modules} onChange={changeTextEditor} />
                                                  {errors.description && <span className='ticket-invalid-feedback'>{errors.description}</span>}
                                             </div>
                                        </div>
                                        <div className='ticket-group-drop-zone'>
                                             <CLabel>Please attach screenshots detailing your issues, so we can better understand them.</CLabel>
                                             <div className='ticket-group-input'>
                                                  <div
                                                       {...getRootProps({
                                                            className: `dropzone${checkNotScreen ? ' no-click-drop' : ''}`,
                                                       })}
                                                  >
                                                       <input {...getInputProps()} name='screenShots' />
                                                       <CIcon icon='cil-cloud-download' width={30} />
                                                       <p>Drag & drop screenshots here</p>
                                                  </div>
                                                  {errorScreenShots && <span className='ticket-invalid-feedback'>{errors.screenShots}</span>}
                                             </div>
                                             <aside className='drop-zone-list-file'>
                                                  <ul>{files}</ul>
                                             </aside>
                                        </div>
                                        <div className='ticket-group-checkbox'>
                                             <CInputCheckbox name='notScreenShots' id='notScreenShots' onChange={handleNotScreenShots} checked={checkNotScreen} />
                                             <label htmlFor='notScreenShots'>My issue cannot be shown through screenshots.</label>
                                        </div>

                                        <CButton disabled={loadings} color='primary' type='submit'>
                                             {loadings ? 'Sending...' : 'Open a Support Ticket'}
                                        </CButton>
                                   </CForm>
                              );
                         }}
                    </Formik>
               </div>
          </div>
     );
};

export default CreateSupportTicket;
