import React, { useState, useContext, useEffect, useCallback } from 'react';
import { CCard, CCardBody, CButton, CCardHeader, CCol, CRow } from '@coreui/react';
import PropTypes from 'prop-types';
import CIcon from '@coreui/icons-react';
import cloneDeep from 'lodash/cloneDeep';
import { RuleContext } from '../CreateNewRule';
import { API_CONSENT_RULE, CONSENT_IDENTIFICATION_BUTTON, CONSENT_IDENTIFICATION_LEVEL } from '../../../../../../constants';
import { callTokenApi } from '../../../../../../apiCaller';
import { setSubscriberState } from '../../../../../../actions/subscriber';
import { toastError } from '../../../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmSaveChange } from '../../../../../general/popup';
import { setFlexibleModal, setRuleHaveEditting } from '../../../../../../actions/common';
import Select from 'react-select';
import { CInput } from '../../../../../migration/CInput';
import CLabel from '../../../../../migration/CLabel';

const CreateStep4 = ({ stepsData, setStepsData }) => {
     const dispatch = useDispatch();
     const { activeStep, setActiveStep } = useContext(RuleContext);
     const [dataIdentification, setDataIdentification] = useState('');
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const rules = useSelector((state) => state.subscriber.consentRules);

     const [showLevel, setShowLevel] = useState(false);
     const [isDisable, setIsDisable] = useState(true);
     const [saveModal, setSaveModal] = useState(false);
     const [saveLoading, setSaveLoading] = useState(false);
     const { id: activeAccountId } = activeAccount;
     const setVisitorIdentification = () => {
          if (activeStep === 3) {
               const visitorIdentification = cloneDeep(stepsData.visitorIdentification);
               if(stepsData.consentMethod === 'explicitConsent') {
                    visitorIdentification.allow = 'anonymizeVisitors';
                    visitorIdentification.notAllow = 'anonymizeVisitors';
                    visitorIdentification.level = 'identifyStrong';
                    setShowLevel(false);
                    setIsDisable(false);
               } else if(stepsData.consentMethod === 'impliedConsent') {
                    visitorIdentification.allow = 'identifyVisitors';
                    visitorIdentification.notAllow = 'anonymizeVisitors';
                    visitorIdentification.level = 'identifyStrong';
                    setShowLevel(true);
                    setIsDisable(false);
               } else if(stepsData.consentMethod === 'openConsent') {
                    visitorIdentification.allow = 'identifyVisitors';
                    visitorIdentification.notAllow = 'identifyVisitors';
                    visitorIdentification.level = 'identifyStrong';
                    setShowLevel(true);
                    setIsDisable(false);
               }
               setDataIdentification(visitorIdentification);
          }
     };

     useEffect(setVisitorIdentification, [activeStep]); // eslint-disable-line react-hooks/exhaustive-deps

     const toggleSaveModal = useCallback(() => {
          setSaveModal(!saveModal);
     }, [saveModal]);

     const clickSaveRuleBtn = () => {
          toggleSaveModal();
     };
     const handleSave = () => {
          setSaveLoading(true);
          const data = {
               accountId: activeAccountId,
               data: {
                    ...stepsData,
                    visitorIdentification: dataIdentification,
               },
          };

          callTokenApi(`${API_CONSENT_RULE}create`, 'POST', data).then((response) => {
               setSaveLoading(false);
               if (response.status === 200) {
                    const { rule } = response.data;
                    const newRules = [...rules];

                    newRules.unshift(rule);
                    dispatch(
                         setSubscriberState({
                              consentRules: newRules,
                              initialConsentRules: newRules,
                         })
                    );

                    dispatch(setFlexibleModal({ show: false, component: '' }));
                    dispatch(setRuleHaveEditting({ show: false, type: '' }));
               } else {
                    toggleSaveModal();
                    toastError(response);
               }
          });
     };

     const handleSelectedIdentification = (value, key) => {
          const newDataIdentification = { ...dataIdentification };

          newDataIdentification[key] = value;

          setDataIdentification(newDataIdentification);

          checkPassStep(newDataIdentification);
     };

     const checkPassStep = (value) => {
          let flag = false;

          if (stepsData.consentMethod !== 'openConsent') {
               if (!value.notAllow) {
                    flag = true;
               }
          }

          if (!value.allow) {
               flag = true;
          }

          if (!value.level && value.allow !== 'dontTrackVisitors') {
               flag = true;
          }

          if (value.notAllow !== 'identifyVisitors' && value.allow !== '' && value.allow !== 'identifyVisitors') {
               flag = false;
          }

          if (value.notAllow === 'identifyVisitors' || value.allow === 'identifyVisitors') {
               setShowLevel(true);
          } else {
               setShowLevel(false);
          }

          setIsDisable(flag);
     };

     const checkStep4Passed = () => {
          let passed = true;

          if (!stepsData.visitorIdentification.allow) {
               passed = false;
          }

          return passed;
     };

     const CustomOption = ({ title, tooltip }) => {
          return <>
               {/* <CIcon icon='icon-info' className='hover-show-tooltip mr-3' height={14}></CIcon>
               <div className='block-tooltip'>
                    <CIcon icon='arrow-preview' className='arrow-preview'></CIcon>
                    <div className='block-tooltip-text'>
                         <p>{tooltip}</p>
                    </div>
               </div> */}
               {title}
          </>;
     };

     return (
          <CCard className={`cvr-step-2 ${activeStep !== 3 ? 'difference-step' : 'cvr-step-card'}`}>
               <CCardHeader>
                    {activeStep !== 3 ? (
                         <div className='rule-step d-inline-flex justify-content-between w-100'>
                              <h5 className='mb-0 inactive'>Step 3: Identification</h5>
                              {checkStep4Passed() && (
                                   <div className='d-inline-flex align-items-center'>
                                        <CButton className='btn-edit' onClick={() => setActiveStep(4)}>
                                             Edit
                                        </CButton>
                                   </div>
                              )}
                         </div>
                    ) : (
                         <h5>Step 3: Identification</h5>
                    )}
               </CCardHeader>
               {activeStep === 3 && (
                    <>
                         <CCardBody>
                              <p>
                                   ListenLayer must idenfity individual ”users” on your website in order to aggregate the event data for measurement
                                   and insights.
                              </p>
                              <CCard className='table-inden consent-type '>
                                   <CCardHeader className='table-inden-header'>
                                        <p>Tell ListenLayer how to indentify "users" in the region based on the Consent Type analystics_storage.</p>
                                   </CCardHeader>
                                   <CCardBody>
                                        <CRow className='table-inden-consent'>
                                             <CCol md='4' className='table-inden-col'>
                                                  <p>When this Consent Type</p>
                                             </CCol>
                                             <CCol md='4' className='table-inden-allow'>
                                                  <p>Is ALLOWED</p>
                                             </CCol>
                                             {stepsData.consentMethod !== 'openConsent' && (
                                                  <CCol md='4' className='table-inden-notallow'>
                                                       <p>Is NOT ALLOWED</p>
                                                  </CCol>
                                             )}
                                        </CRow>
                                        <CRow className='table-inden-row'>
                                             <CCol md='4' className='table-inden-title'>
                                                  <p>analytics_storage</p>
                                             </CCol>
                                             <CCol md='4'>
                                                  <Select classNamePrefix='react-select'
                                                       options={CONSENT_IDENTIFICATION_BUTTON}
                                                       className='table-inden-select'
                                                       component={Option}
                                                       formatOptionLabel={CustomOption}
                                                       disabled={(item) => dataIdentification.allow === item.value}
                                                       value={CONSENT_IDENTIFICATION_BUTTON.filter(
                                                            (option) => option.value === dataIdentification.allow
                                                       )}
                                                       onChange={(item) => handleSelectedIdentification(item.value, 'allow')}
                                                  />
                                             </CCol>
                                             {stepsData.consentMethod !== 'openConsent' && (
                                                  <CCol md='4'>
                                                  <Select classNamePrefix='react-select'
                                                       options={CONSENT_IDENTIFICATION_BUTTON}
                                                       className='table-inden-select'
                                                       component={Option}
                                                       formatOptionLabel={CustomOption}
                                                       disabled={(item) => dataIdentification.notAllow === item.value}
                                                       value={CONSENT_IDENTIFICATION_BUTTON.filter(
                                                            (option) => option.value === dataIdentification.notAllow
                                                       )}
                                                       onChange={(item) => handleSelectedIdentification(item.value, 'notAllow')}
                                                  />
                                             </CCol>
                                             )}
                                        </CRow>
                                   </CCardBody>
                              </CCard>
                              {showLevel && (
                                   <CCard className='table-inden bg-gray' style={{ background: '#f0f3f5' }}>
                                        <CCardHeader className='table-inden-header'>
                                             <p>What level of identification do you want to use when we identify users in this region?</p>
                                        </CCardHeader>
                                        <CCardBody>
                                             <CRow className='table-region'>
                                                  {CONSENT_IDENTIFICATION_LEVEL.map((item, index) => {
                                                       return (
                                                            <CCol key={index} md='2' className='d-flex align-items-center'>
                                                                 <CInput
                                                                      type='radio'
                                                                      name='level-identify'
                                                                      id={`Strong${index}`}
                                                                      checked={dataIdentification && dataIdentification.level === item.value}
                                                                      className='mb-0 customize-radio'
                                                                      onClick={() => handleSelectedIdentification(item.value, 'level')}
                                                                 />
                                                                 <CLabel htmlFor={`Strong${index}`} className='mb-0'>
                                                                      {item.title}
                                                                 </CLabel>
                                                                 <CIcon icon='icon-info' className='hover-show-tooltip ml-2' height={12}></CIcon>
                                                                 <div className='block-tooltip'>
                                                                      <CIcon icon='arrow-preview' className='arrow-preview'></CIcon>
                                                                      <div className='block-tooltip-text'>
                                                                           <p>{item.tooltip}</p>
                                                                      </div>
                                                                 </div>
                                                            </CCol>
                                                       );
                                                  })}
                                             </CRow>
                                        </CCardBody>
                                   </CCard>
                              )}
                              <CButton className='px-4' color='success' onClick={clickSaveRuleBtn} disabled={isDisable}>
                                   SAVE RULE
                              </CButton>
                         </CCardBody>
                    </>
               )}
               <ConfirmSaveChange
                    show={saveModal}
                    onClose={() => setSaveModal(false)}
                    isLoading={saveLoading}
                    onAccept={handleSave}
                    title={`Are you sure you want to save?`}
               >
                    <p>You are about to save this new rule. After doing this, publish your changes to see your rule working in your data layer.</p>
               </ConfirmSaveChange>
          </CCard>
     );
};

CreateStep4.propTypes = {
     stepsData: PropTypes.object,
     setStepsData: PropTypes.func,
};

export default CreateStep4;
