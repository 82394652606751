import classNames from 'classnames';
import React, { useContext, useMemo, } from 'react';
import { RuleContext, STEPS } from './CreatePreviewRule';

const StepProgress = () => {
    const { activeStep } = useContext(RuleContext);

    const handleCompletedSteps = ({ activeStep }) => {
        let progressSteps = 0;
        let passedSteps = 0;

        switch (activeStep) {
            case STEPS.Step1:
                progressSteps = 1;
                break;
            case STEPS.Step2:
                progressSteps = 2;
                passedSteps = 1;
                break;
            case STEPS.Step3:
                progressSteps = 3;
                passedSteps = 2;
                break;
            case STEPS.Step4:
                progressSteps = 4;
                passedSteps = 3;
                break;

            default:
                break;
        }
        return { progressSteps, passedSteps };
    };
    const { progressSteps, passedSteps } = useMemo(() => handleCompletedSteps({ activeStep }), [activeStep]);

    return (
        <div className="steps steps-vertical">
            <ol>
                <li className={classNames({ 'steps-selected': progressSteps > 0, 'steps-complete': passedSteps > 0 })}>
                    <div className="steps-circle"></div>
                    <span>{STEPS.Step1}</span>
                </li>
                <li className={classNames({ 'steps-selected': progressSteps > 1, 'steps-complete': passedSteps > 1 })}>
                    <div className="steps-circle"></div>
                    <span>{STEPS.Step2}</span>
                </li>
                <li className={classNames({ 'steps-selected': progressSteps > 2, 'steps-complete': passedSteps > 2 })}>
                    <div className="steps-circle"></div>
                    <span>{STEPS.Step3}</span>
                </li>
                <li className={classNames({ 'steps-selected': progressSteps > 3, 'steps-complete': passedSteps > 3 })}>
                    <div className="steps-circle"></div>
                    <span>{STEPS.Step4}</span>
                </li>
            </ol>
        </div>
    );
};

export default StepProgress;
