// ** React Imports
import React from 'react';

// ** CoreUI Imports
import { CButton, CModal, CModalBody, CForm } from '@coreui/react';
import CIcon from '@coreui/icons-react';

// ** Validations
import { Formik } from 'formik';

const Confirm = (props) => {
     const { show, data, onAccept, onCancel } = props;

     const initialValues = {
          name: '',
          description: '',
          isDefault: false,
     };

     return (
          <div className="confirm-save-popup popup-publish-version">
               <CModal visible={show} onClose={onCancel} color="primary" alignment="center" portal={false}> 
                    <CModalBody className='confirm-save modal-padding-37'>
                         <CIcon icon="cil-x" onClick={onCancel} className="icon-close-popup"></CIcon>

                         <div className="popup-publish-version-description">
                              <h1>{data.confirmTitle}</h1>
                              <p>{data.confirmDescription}</p>
                         </div>

                         <Formik initialValues={initialValues} onSubmit={onAccept} onClose={onCancel} validateOnChange={false} validateOnBlur={false}>
                              {({ handleSubmit, isSubmitting }) => {
                                   return (
                                        <CForm onSubmit={handleSubmit}>
                                             <div className="form-actions">
                                                  <CButton type="submit" color="primary" className="mr-3" disabled={isSubmitting}>
                                                       {isSubmitting ? (
                                                            <>
                                                                 Waiting<span className="dots-waiting"></span>
                                                            </>
                                                       ) : (
                                                            'Confirm'
                                                       )}
                                                  </CButton>

                                                  <CButton className="btn btn-light" onClick={onCancel}>
                                                       Cancel
                                                  </CButton>
                                             </div>
                                        </CForm>
                                   );
                              }}
                         </Formik>
                    </CModalBody>
               </CModal>
          </div>
     );
};

export default Confirm;
