import React, { useContext } from 'react';
import { LookupTableContext } from './FormRuleTable';
import { VARIABLE_OPERATORS_TRIGGERS } from '../../../../../../../constants';
import CLabel from '../../../../../../migration/CLabel';
import { CSelect } from '../../../../../../migration/CInput';
import CTooltip from '../../../../../../migration/CTooltip';

const TableHeaderRow = ({ onlyView }) => {
     const { conditions, handleSelectOperator } = useContext(LookupTableContext);

     const eventKey = (key) => {
          return (
               <>
                    <CTooltip content={key} placement='top' advancedOptions={{ delay: { hide: 0 } }}>
                         <span>{key}</span>
                    </CTooltip>
               </>
          );
     };

     return (
          <>
               <tr className='table-header'>
                    {conditions &&
                         conditions[0].map((condition, index) => {
                              let { conjunction } = condition;
                              let conditionValues = condition.g0;
                              let { type, operator, key } = conditionValues;
                              console.log('conditionValues', conditionValues);
                              
                              return (
                                   <React.Fragment key={index}>
                                        <td className={operator === null ? `no-condition variable-header` : `has-condition variable-header`}>
                                             <CLabel>{type}</CLabel>
                                             {<>{eventKey(key)}</>}
                                             {index < conditions[0].length - 1 && (
                                                  <CSelect
                                                       name='conjunction'
                                                       className='conditions'
                                                       value={conjunction ? conjunction : 'and'}
                                                       onChange={(e) => handleSelectOperator(e, index)}
                                                       disabled={onlyView}
                                                  >
                                                       <option value='and'>and</option>
                                                       <option value='or'>or</option>
                                                  </CSelect>
                                             )}
                                        </td>
                                   </React.Fragment>
                              );
                         })}
                    <td>
                         <CLabel>Variable</CLabel>
                         <CTooltip content={'formCategory'} placement='right-end' advancedOptions={{ duration: [200, 0] }}>
                              <span>formCategory</span>
                         </CTooltip>
                    </td>
                    <td>
                         <CLabel>Variable</CLabel>
                         <CTooltip content={'formName'} placement='right-end' advancedOptions={{ duration: [200, 0] }}>
                              <span>formName</span>
                         </CTooltip>
                    </td>
               </tr>
               <tr className='table-body conditions-row'>
                    {conditions &&
                         conditions[0].map((condition, index) => {
                              let conditionValues = condition.g0;
                              let { operator } = conditionValues;

                              return (
                                   <React.Fragment key={index}>
                                        <td>
                                             <CSelect
                                                  name='operator'
                                                  className='variable-conditions rounded-0'
                                                  value={operator}
                                                  onChange={(e) => handleSelectOperator(e, index)}
                                                  disabled={onlyView}
                                             >
                                                  <option value='' disabled hidden>
                                                       Select
                                                  </option>
                                                  {VARIABLE_OPERATORS_TRIGGERS.map(({ VALUE, TEXT }) => (
                                                       <option key={VALUE} value={VALUE}>
                                                            {TEXT}
                                                       </option>
                                                  ))}
                                             </CSelect>
                                        </td>
                                   </React.Fragment>
                              );
                         })}

                    <td className='custom-variable-conditions'></td>
                    <td className='custom-variable-conditions'></td>
               </tr>
          </>
     );
};

export default TableHeaderRow;
