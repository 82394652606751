import React, { useState, useContext, useCallback, useRef } from 'react';
import {
     CCard,
} from '@coreui/react';
import Select from 'react-select';
import { useFormikContext } from 'formik';
import { RegionContext } from './SingleRow';
import CIcon from '@coreui/icons-react';
import { useOutsideHandling } from '../../../../../../utils';
import { DATAS_COUNTRIES_REGION } from '../../../../../../constants';
import { CInvalidFeedback } from '../../../../../migration/CInvalidFeedback';

const defaultOptions = [
     {
          "label": "GDPR Countries",
          "value": "GDPRCountries"
     }
];

const RowRegulatoryRegion = () => {
     const { errors, setFieldValue } = useFormikContext();
     const [tooltipCountries, setTooltipCountries] = useState(false);
     const { CONSENT_REGION_TYPES, row, index, onChangeRegionType, handleHasChange } = useContext(RegionContext);

     const continentRequired = errors.geographicRegions && errors.geographicRegions[index] && errors.geographicRegions[index].continent;
     const regionTypeRequired = errors.geographicRegions && errors.geographicRegions[index] && errors.geographicRegions[index].regionType;

     const valueRegionType = CONSENT_REGION_TYPES.filter(option => option.value === row.regionType);

     const onChangeRegionChange = (value) => {
          setFieldValue(`geographicRegions.${index}.continent`, value);
          handleHasChange();
     };

     // setTooltipCountries
     const clickOutsideCountries = useCallback(() => {
          setTooltipCountries('');
     }, [])

     const statusRefCountries = useRef(null);
     useOutsideHandling(statusRefCountries, clickOutsideCountries);

     const handleClickBubbleCountries = () => {
          setTimeout(() => {
               if (!tooltipCountries) {
                    setTooltipCountries(true);
               }
          }, 100);
     }

     return (
          <>
               <div className='region-type'>
                    <Select classNamePrefix='react-select'
                         className={(regionTypeRequired) ? 'is-invalid' : null}
                         value={valueRegionType}
                         name={`regionsType.${index}`}
                         options={CONSENT_REGION_TYPES}
                         onChange={(e) => onChangeRegionType(e.value)}
                         placeholder="Select a region type"
                    />
                    <CInvalidFeedback>{regionTypeRequired}</CInvalidFeedback>
               </div>

               <div className='region-type'>
                    <Select classNamePrefix='react-select'
                         className={(continentRequired) ? 'is-invalid' : null}
                         value={defaultOptions[0]}
                         isDisabled={!row.regionType}
                         name={`continent.${index}`}
                         options={defaultOptions}
                         onChange={(type) => onChangeRegionChange(type.value)}
                         placeholder="Select a Continent"
                    />
                    <CInvalidFeedback>{continentRequired}</CInvalidFeedback>
               </div>
               <div className='tooltip-country'>
                    <CIcon
                         icon='icon-info'
                         cursor="pointer"
                         onClick={handleClickBubbleCountries}
                    />
                    {
                         tooltipCountries && (
                              <CCard
                                   className="block-tooltip"
                                   ref={statusRefCountries}
                              >
                                   <CIcon icon="arrow-preview" className="arrow-preview"></CIcon>
                                   <div className="block-tooltip-text">
                                        <ul>
                                             {
                                                  DATAS_COUNTRIES_REGION.map(country => {
                                                       return <li key={country}>{country}</li>
                                                  })
                                             }
                                        </ul>
                                   </div>
                              </CCard>
                         )
                    }
               </div>
          </>
     )
}
export default RowRegulatoryRegion;