import CIcon from '@coreui/icons-react';
import { CButton, CCard, CCol, CRow } from '@coreui/react';
import dayjs from 'dayjs';
// import cloneDeep from 'lodash/cloneDeep';
import throttle from 'lodash/throttle';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames';

// import { setDateRangeRecord } from '../../../../actions/subscriber';
// import { DATE_RANGE_PRESETS, PERIOD_TYPES } from '../../../../constants';
// import { useOutsideHandling } from '../../../../utils';
// import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from '../../../general/dropdown';
// import { EventExplorerContext } from './EventExplorer';
import { setDateRangeRecord } from '../../../../../actions/subscriber';
import { DATE_RANGE_PRESETS, PERIOD_TYPES } from '../../../../../constants';
import { useOutsideHandling } from '../../../../../utils';
// import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from '../../../../general/dropdown';
// import { EventExplorerContext } from '../EventExplorer';

const DatePickerBox = ({ toggleDateBox, placeShowDate }) => {
     // const { dateRangeConfig: globalDateRangeConfig, setDateRangeConfig: setGlobalDateRangeConfig, dateScopeOptions, setChangeData } = useContext(EventExplorerContext);
     let globalDateRangeConfig = {
          comparePeriod: false,
          periodType: 'Previous Period',
          datePreset: null,
          dateScope: 'Event activity',
     };

     let dateScopeOptions = [];
     //  let changeData = false;
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const { dateRangeRecord: globalDateRange } = useSelector((state) => state.subscriber);
     const today = dayjs().tz(activeAccount.timeZone || undefined);
     const maxDate = today['$d'];
     const presetsToDisplay = [
          [DATE_RANGE_PRESETS.LAST_7_DAYS, DATE_RANGE_PRESETS.LAST_WEEK],
          [DATE_RANGE_PRESETS.LAST_14_DAYS, DATE_RANGE_PRESETS.THIS_MONTH],
          [DATE_RANGE_PRESETS.LAST_30_DAYS, DATE_RANGE_PRESETS.LAST_MONTH],
     ];
     const initialDateRange = {
          selection: {
               startDate: globalDateRange.selection.startDate['$d'],
               endDate: globalDateRange.selection.endDate['$d'],
               key: 'selection',
          },
     };

     if (globalDateRangeConfig.comparePeriod && globalDateRange.compare) {
          initialDateRange.compare = {
               startDate: globalDateRange.compare.startDate['$d'],
               endDate: globalDateRange.compare.endDate['$d'],
               key: 'compare',
          };
     }

     const [focusedRange, setFocusedRange] = useState([0, 0]);
     // const [dropdownOpen, setDropdownOpen] = useState(false);
     const [configData, setConfigDataState] = useState(globalDateRangeConfig);
     const [calendarValue, setCalendarValue] = useState(initialDateRange);

     const ranges = Object.values(calendarValue).map((val) => val);

     const customDateWrapperRef = useRef(null);
     useOutsideHandling(customDateWrapperRef, toggleDateBox, 'mouseup');

     useEffect(() => {
          if (!configData.comparePeriod) return;

          const startDateSelection = dayjs(calendarValue.selection.startDate);
          const endDateSelection = dayjs(calendarValue.selection.endDate);
          const selectionDateSubtract = Math.ceil((endDateSelection - startDateSelection) / (1000 * 3600 * 24));
          let startDateCompare, endDateCompare;

          switch (configData.periodType) {
               case PERIOD_TYPES.PREVIOUS_PERIOD:
                    const endDate = startDateSelection.subtract(1, 'day');
                    startDateCompare = endDate.subtract(selectionDateSubtract, 'day')['$d'];
                    endDateCompare = endDate['$d'];
                    break;
               case PERIOD_TYPES.PREVIOUS_YEAR:
                    startDateCompare = startDateSelection.subtract(365, 'day')['$d'];
                    endDateCompare = endDateSelection.subtract(365, 'day')['$d'];
                    break;
               default:
                    return;
          }

          if (!startDateCompare || !endDateCompare) return;

          setCalendarValue({
               ...calendarValue,
               compare: {
                    startDate: startDateCompare,
                    endDate: endDateCompare,
                    key: 'compare',
               },
          });
     }, [calendarValue.selection, configData.comparePeriod, configData.periodType]); // eslint-disable-line react-hooks/exhaustive-deps

     const setConfigData = (values) => {
          setConfigDataState((state) => ({ ...state, ...values }));
     };

     const onChangeDateRange = (ranges) => {
          const { selection, compare } = ranges;
          let newRanges = ranges;

          if (selection) {
               if (selection.startDate > maxDate) {
                    newRanges = {
                         selection: {
                              ...selection,
                              startDate: maxDate,
                         },
                    };
               } else if (selection.endDate > maxDate) {
                    newRanges = {
                         selection: {
                              ...selection,
                              endDate: maxDate,
                         },
                    };
               }
          } else if (compare) {
               if (compare.startDate > maxDate) {
                    newRanges = {
                         compare: {
                              ...compare,
                              startDate: maxDate,
                         },
                    };
               } else if (compare.endDate > maxDate) {
                    newRanges = {
                         compare: {
                              ...compare,
                              endDate: maxDate,
                         },
                    };
               }
          }

          setCalendarValue({ ...calendarValue, ...newRanges });
          setConfigData({ datePreset: DATE_RANGE_PRESETS.CUSTOM });

          if (compare) {
               setConfigData({ periodType: PERIOD_TYPES.CUSTOM });
          }
     };

     // const handleSetComparePeriod = (compare) => {
     //      setConfigData({ comparePeriod: compare, periodType: PERIOD_TYPES.PREVIOUS_PERIOD });

     //      if (!compare) {
     //           const newDateRange = cloneDeep(calendarValue);
     //           delete newDateRange['compare'];
     //           setCalendarValue(newDateRange);

     //           if (focusedRange[0] === 1) {
     //                // Reset focus range
     //                setFocusedRange([0, 0]);
     //           }
     //      }
     // };

     const onRangeFocusChange = (range) => {
          setFocusedRange(range);
     };

     // const onChangePeriodType = (value) => {
     //      setConfigData({ periodType: value });
     // };

     const onClickDatePreset = (preset) => {
          let dateRange;

          switch (preset) {
               case DATE_RANGE_PRESETS.TODAY:
                    dateRange = {
                         startDate: today['$d'],
                         endDate: today['$d'],
                    };
                    break;
               case DATE_RANGE_PRESETS.LAST_7_DAYS:
                    dateRange = {
                         startDate: today.subtract(7, 'day')['$d'],
                         endDate: today.subtract(1, 'day')['$d'],
                    };
                    break;
               case DATE_RANGE_PRESETS.LAST_14_DAYS:
                    dateRange = {
                         startDate: today.subtract(14, 'day')['$d'],
                         endDate: today.subtract(1, 'day')['$d'],
                    };
                    break;
               case DATE_RANGE_PRESETS.LAST_30_DAYS:
                    dateRange = {
                         startDate: today.subtract(30, 'day')['$d'],
                         endDate: today.subtract(1, 'day')['$d'],
                    };
                    break;
               case DATE_RANGE_PRESETS.LAST_WEEK:
                    const lastWeek = today.subtract(1, 'week');
                    dateRange = {
                         startDate: lastWeek.startOf('week')['$d'],
                         endDate: lastWeek.endOf('week')['$d'],
                    };
                    break;
               case DATE_RANGE_PRESETS.THIS_MONTH:
                    dateRange = {
                         startDate: today.startOf('month')['$d'],
                         endDate: today.endOf('month')['$d'],
                    };
                    break;
               case DATE_RANGE_PRESETS.LAST_MONTH:
                    const lastMonth = today.subtract(1, 'month');
                    dateRange = {
                         startDate: lastMonth.startOf('month')['$d'],
                         endDate: lastMonth.endOf('month')['$d'],
                    };
                    break;
               default:
                    dateRange = calendarValue.selection;
          }

          setConfigData({ datePreset: preset });
          setCalendarValue({
               ...calendarValue,
               selection: {
                    ...dateRange,
                    key: 'selection',
               },
          });
     };

     const onApplyButtonClicked = () => {
          const { selection, compare } = calendarValue;

          const newDateRange = {
               selection: {
                    startDate: dayjs(selection.startDate),
                    endDate: selection.endDate ? dayjs(selection.endDate) : dayjs(selection.startDate),
               },
          };

          if (configData.comparePeriod && compare) {
               newDateRange.compare = {
                    startDate: dayjs(compare.startDate),
                    endDate: compare.endDate ? dayjs(compare.endDate) : dayjs(compare.startDate),
               };
          }
          // setChangeData(true)
          // changeData = true;
          dispatch(setDateRangeRecord(newDateRange));
          // setGlobalDateRange(newDateRange);
          // setGlobalDateRangeConfig(configData);
          globalDateRangeConfig = configData;
          toggleDateBox();
     };

     const handleChangeDateScope = (e) => {
          setConfigData({ dateScope: e.value });
     };

     const divClasses = classNames('custom-date-wrapper', {
          compare: configData.comparePeriod,
          'date-scope': dateScopeOptions.length > 0,
          // 'custom-date-wrapper-bottom': placeShowDate,
          'custom-date-wrapper-top': true,
     });

     return (
          <CCard
               className={divClasses}
               ref={customDateWrapperRef}
               // style={{ top: 'unset', zoom: '0.9', bottom: '0', right: '-18px', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}
          >
               {/* <div className='compare-period d-flex align-items-center justify-content-between'>
                    <CFormGroup variant='custom-checkbox' inline>
                         <CInputCheckbox custom id='comparePeriod' checked={configData.comparePeriod} onChange={(e) => handleSetComparePeriod(e.target.checked)} />
                         <CLabel variant='custom-checkbox' htmlFor='comparePeriod'>
                              Compare periods
                         </CLabel>
                    </CFormGroup>
                    {configData.comparePeriod && (
                         <Dropdown>
                              <DropdownToggle>
                                   <span>{dropdownOpen ? 'Select Period' : configData.periodType}</span>
                              </DropdownToggle>
                              <DropdownMenu setDropdownOpen={setDropdownOpen}>
                                   <CIcon icon='arrow-account' className='arrow-account arrow-setting' />
                                   {Object.entries(PERIOD_TYPES).map(([key, value]) => (
                                        <div key={key} onClick={() => onChangePeriodType(value)}>
                                             <DropdownItem>{value}</DropdownItem>
                                        </div>
                                   ))}
                              </DropdownMenu>
                         </Dropdown>
                    )}
               </div> */}
               {dateScopeOptions.length > 0 && (
                    <div className="select-date-scope-wrapper d-flex align-items-center justify-content-between">
                         <p className="label">Apply date range to</p>
                         <div className="select-date-scope">
                              <Select classNamePrefix='react-select'
                                   name="dateScope"
                                   // className={`${typeValid ? 'is-invalid' : ''}`}
                                   options={dateScopeOptions}
                                   value={dateScopeOptions.find((item) => item.value === configData.dateScope)}
                                   placeholder="Select..."
                                   onChange={(e) => handleChangeDateScope(e)}
                              />
                         </div>
                    </div>
               )}
               <DateRangePicker
                    months={1}
                    maxDate={maxDate}
                    direction="vertical"
                    color="#3c4b64"
                    rangeColors={['#E4E5FF', '#FFF5D6']}
                    // className={divClasses}
                    scroll={{ enabled: true }}
                    ranges={ranges}
                    staticRanges={[]}
                    inputRanges={[]}
                    weekdayDisplayFormat="EEEEEE"
                    monthDisplayFormat="MMMM"
                    editableDateInputs={true}
                    showMonthAndYearPickers={true}
                    retainEndDateOnFirstSelection={true}
                    startDatePlaceholder="Start Date"
                    endDatePlaceholder="End Date"
                    onChange={onChangeDateRange}
                    focusedRange={focusedRange}
                    onRangeFocusChange={onRangeFocusChange}
               />
               <div className="presets-wrapper">
                    <div className="easy-presets">
                         <p className="label">Easy presets</p>
                         <CButton
                              className={`${configData.datePreset === DATE_RANGE_PRESETS.TODAY ? 'active' : ''}`}
                              onClick={() => onClickDatePreset(DATE_RANGE_PRESETS.TODAY)}
                         >
                              Go to Today
                         </CButton>
                    </div>
                    <div className="presets">
                         {presetsToDisplay.map((presets, i) => {
                              return (
                                   <CRow key={i}>
                                        {presets.map((preset, presetIndex) => (
                                             <CCol key={presetIndex} sm={6} className="preset-col">
                                                  <CButton
                                                       className={`${preset === configData.datePreset ? 'active' : ''}`}
                                                       onClick={() => onClickDatePreset(preset)}
                                                  >
                                                       {preset}
                                                  </CButton>
                                             </CCol>
                                        ))}
                                   </CRow>
                              );
                         })}
                    </div>
               </div>
               <div className="bottom-buttons">
                    <CButton onClick={toggleDateBox}>Cancel</CButton>
                    <CButton color="primary" onClick={onApplyButtonClicked}>
                         Apply
                    </CButton>
               </div>
          </CCard>
     );
};

const CustomDateRange = ({ placeShowDate }) => {
     // const { dateRangeConfig, showData, enableFullAnalytics } = useContext(EventExplorerContext);
     let dateRangeConfig = {
          comparePeriod: false,
          periodType: 'Previous Period',
          datePreset: null,
          dateScope: 'Event activity',
     };
     let showData = false;
     let enableFullAnalytics = true;
     const dateRangeRecord = useSelector((state) => state.subscriber.dateRangeRecord);
     const [dateBoxOpened, setDateBoxOpened] = useState(false);
     const dateFormat = 'MMM D, YYYY';

     const throttledToggleDateBox = throttle(
          () => {
               if (!enableFullAnalytics && !showData) {
                    return;
               }
               setDateBoxOpened((s) => !s);
          },
          100,
          { trailing: false },
     );

     const toggleDateBox = useCallback(() => {
          throttledToggleDateBox();
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     return (
          <div className={`custom-date-picker`}>
               {dateBoxOpened && <div className="date-range-overlay" onClick={toggleDateBox}></div>}
               <CButton className="date-range-wrapper" onClick={toggleDateBox}>
                    <div className="date-range">
                         {dateRangeRecord.selection && (
                              <div className="d-flex align-items-center">
                                   {dateRangeConfig.datePreset && dateRangeConfig.datePreset !== DATE_RANGE_PRESETS.CUSTOM && (
                                        <span className="preset-date-range">{dateRangeConfig.datePreset}</span>
                                   )}
                                   <span className="selection-date-range">
                                        {dateRangeRecord.selection.startDate.format(dateFormat)} -{' '}
                                        {dateRangeRecord.selection.endDate.format(dateFormat)}
                                   </span>
                              </div>
                         )}
                         {dateRangeConfig.comparePeriod && dateRangeRecord.compare && (
                              <span className="compare-date-range">
                                   Compare: {dateRangeRecord.compare.startDate.format(dateFormat)} -{' '}
                                   {dateRangeRecord.compare.endDate.format(dateFormat)}
                              </span>
                         )}
                    </div>
                    <CIcon icon="icon-calendar-report" className="icon-calendar-report" alt="iconCalendar" style={{ marginLeft: '10px' }} />
               </CButton>
               {dateBoxOpened && <DatePickerBox toggleDateBox={toggleDateBox} placeShowDate={placeShowDate} />}
          </div>
     );
};

export default CustomDateRange;
