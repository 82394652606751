import React, { useContext } from "react";
import { CButton } from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { SupportTicketPopupContext, DECISION_STEPS } from '../GetHelpLabel';

const DecisionStep2 = () => {
    const { setCurrentStep, setTicketType } = useContext(SupportTicketPopupContext);

    const handleClick = () => {
        setCurrentStep(DECISION_STEPS.step3);
    }

    const handleClickStrategyRequest = () => {
        setTicketType('Tracking strategy or Google Tag Manager');
        setCurrentStep(DECISION_STEPS.strategyRequest);
    }

    return (
        <div className="ticket-popup-content">
            <div className="btn-back-ticket">
                <CIcon icon="icon-chevron-left" width={6} />
                <span onClick={() => setCurrentStep(DECISION_STEPS.step1)}>Back</span>
            </div>
            <div className="ticket-popup-header">
                <h5>We're Here to Help!</h5>
                <p>Tell us about your problem.</p>
            </div>
            <div className="ticket-popup-body">
                <CButton color="primary" onClick={() => handleClick()}>I need help with ListenLayer</CButton>
                <CButton color="primary" onClick={() => handleClickStrategyRequest()}>
                    I need help with my tracking strategy or<br />Google Tag Manager
                </CButton>
            </div>
        </div>
    );
};

export default DecisionStep2;
