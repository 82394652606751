import React, { useContext, useState } from 'react';
import { CButton, CCard, CCardBody, CForm } from '@coreui/react';
import { Formik } from 'formik';

import ListenerRequest from '../../../../../general/popup/ListenerRequest';
import { CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
import Waiting from '../../../../../general/Loadings/Waiting';
import useListenerSubmit from './useListenerSubmit';
import useListenerBackBtnClicked from './useListenerBackBtnClicked';
import useSaveListenerProgress from './useSaveListenerProgress';
import CFormGroup from '../../../../../migration/CFormGroup';

const EcommerceListener = () => {
    const { account, stepsData } = useContext(CreateAccountContext);
    const [showListenerRequestPopup, setShowListenerRequestPopup] = useState(false);
    const { listeners, selectedListenerTopics, allSelectedListeners, totalConfigureMeasurementSteps } = stepsData;
    let listenersToDisplay = listeners.filter(listener => listener.type === 'eCommerce');
    const currentListenerIndex = selectedListenerTopics.findIndex(topic => topic === STEPS.ecommerceListener);
    const listenerType = 'ecommerce';
    const handleListenerSubmit = useListenerSubmit(listenerType, currentListenerIndex);
    const handleBackBtnClicked = useListenerBackBtnClicked(listenerType, currentListenerIndex);
    useSaveListenerProgress();

    const initialValues = {
        selectedListeners: allSelectedListeners[listenerType] || []
    };

    const checkIfChecked = (selectedListeners, listener) => {
        return !!selectedListeners.find(selectedListener => selectedListener.id === listener.id);
    }

    const onBackBtnClicked = (selectedListeners) => {
        handleBackBtnClicked(selectedListeners);
    };

    const toggleRequestPopup = () => {
        setShowListenerRequestPopup(show => !show);
    };

    const closeRequestPopup = () => {
        setShowListenerRequestPopup(false);
    };

    const onSubmit = ({ selectedListeners }) => {
        handleListenerSubmit(selectedListeners);
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {({ values, handleSubmit, setFieldValue, isSubmitting }) => {
                const handleSelect = (listener) => {
                    let newSelectedListeners = [...values.selectedListeners];
                    const foundListenerIndex = values.selectedListeners.findIndex(selectedListener => selectedListener.id === listener.id);

                    if (foundListenerIndex > -1) {
                        newSelectedListeners.splice(foundListenerIndex, 1);
                    } else {
                        newSelectedListeners.push(listener);
                    }

                    setFieldValue('selectedListeners', newSelectedListeners);
                };

                return (
                    <CreateAccountBody onBackBtnClicked={() => onBackBtnClicked(values.selectedListeners)} backBtnDisabled={isSubmitting}>
                        <CCard className="account-form ecommerce-listener">
                            {totalConfigureMeasurementSteps > 1 &&
                                (
                                    <p className="number-of-step">
                                        {currentListenerIndex} of {totalConfigureMeasurementSteps} steps
                                    </p>
                                )
                            }
                            <CCardBody>
                                <CForm onSubmit={handleSubmit}>
                                    <CFormGroup>
                                        <h3>Select your eCommerce Listener</h3>
                                        <p className="des">Select your eCommerce platform</p>
                                        <div className="list-form ecommerce">
                                            {listenersToDisplay.length > 0 ? (
                                                listenersToDisplay.map((listener) => (
                                                    <label key={listener.id}>
                                                        <input
                                                            type="checkbox"
                                                            checked={checkIfChecked(values.selectedListeners, listener)}
                                                            onChange={() => handleSelect(listener)}
                                                            disabled={isSubmitting}
                                                        />
                                                        <div className="icon-box">
                                                            <div className="tickmark"></div>
                                                            <img className="listener-logo" src={listener.logo} alt={`${listener.name} Icon`} />
                                                            <span>{listener.name}</span>
                                                        </div>
                                                    </label>
                                                ))
                                            ) : (
                                                <p className="item-not-found">No items found.</p>
                                            )}
                                        </div>
                                        <CButton className="btn-save" type="submit" disabled={values.selectedListeners.length === 0 || isSubmitting}>
                                            <Waiting isLoading={isSubmitting}>NEXT</Waiting>
                                        </CButton>
                                        <CButton className="foot-note" onClick={toggleRequestPopup}>
                                            I can’t find my eCommerce platform
                                        </CButton>
                                    </CFormGroup>
                                </CForm>
                            </CCardBody>
                        </CCard>
                        <ListenerRequest
                            show={showListenerRequestPopup}
                            onClose={closeRequestPopup}
                            accountId={account.id}
                            listenerType="eCommerce"
                            next={() => onSubmit({ selectedListeners: [] })}
                            description="We may not have an integration with the eCommerce platform you use. Type the name here and we'll make sure it's on our development roadmap."
                            placeholder="Enter the name of your eCommerce tool"
                        />
                    </CreateAccountBody>
                );
            }}
        </Formik>
    );
};

export default EcommerceListener;
