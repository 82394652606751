import CIcon from '@coreui/icons-react';
import {
    CButton,
    CCol,
    CForm,
    CRow,
} from '@coreui/react';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setFlexibleModal, setRuleHaveEditting } from '../../../../../../actions/common';
import { updateRule } from '../../../../../../actions/pusher';
import { setCustomVariableOptions, setShowBlockAccountPopup, setUnsavedLookupTableModal } from '../../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../../apiCaller';
import {
    API_CLIENT_CUSTOM_VARIABLE,
    API_CLIENT_RULE_REVERT,
    API_CLIENT_RULE_REVERT_DEFAULT,
    API_CLIENT_RULE_UPDATE,
    TYPE_RULE,
    TYPE_SHOW_UNSAVE_CHANGE
} from '../../../../../../constants';
import { useActiveListener, useEventAndVariableOptions } from '../../../../../../helpers/customHooks';
import { toastError } from '../../../../../../utils';
import { ConfirmSaveChange, LeaveComponentChangePopup } from '../../../../../general/popup';
import RemovePopup from '../../../../../general/popup/RemovePopup';
import InsertVariableModal from './InsertVariableModalV2';
import ImportExportTable from './ImportExportTable';
import TableBodyRows from './TableBodyRows';
import TableHeaderRow from './TableHeaderRow';
import CLabel from '../../../../../migration/CLabel';

export const LookupTableContext = React.createContext({});

const LookupTable = ({ stepsData, initialStepsData }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const activeAccount = useSelector(state => state.subscriber.activeAccount);
    const [saveLoading, setSaveLoading] = useState(false);
    const [saveTableModal, setSaveTableModal] = useState(false);
    const [revertModal, setRevertModal] = useState(false);
    const [revertDefaultModal, setRevertDefaultModal] = useState(false);
    const [leaveComponentModal, setLeaveComponentModal] = useState(false);
    const [navigateTo, setNavigateTo] = useState();
    const [btnSaveStatus, setBtnSaveStatus] = useState(isEqual(stepsData, initialStepsData));
    const [removeRowModal, setRemoveRowModal] = useState({
        show: false,
        index: -1
    });
    const [variablePositionToInsert, setVariablePositionToInsert] = useState({ rowIndex: -1, colIndex: -1 });
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [hasError, setHasError] = useState([]);
    const [tableRow, setTableRow] = useState();
    const customVariableOptions = useSelector(state => state.subscriber.customVariableOptions);
    const { variables: variableOptions } = useEventAndVariableOptions(true);
    const activeListener = useActiveListener();
    const flexibleModal = useSelector(state => state.theme.flexibleModal);

    const { conditions, customVariables } = stepsData;
    const [showVariableModal, setShowVariableModal] = useState(false);
    const unsavedLookupTableModal = useSelector(state => state.subscriber.unsavedLookupTableModal);
    const [btnAbandon, setBtnAbandon] = useState(false)

    const [cursorPosition, setCursorPosition] = useState(-1);
    const {
        id: activeAccountId,
    } = activeAccount;

    const variableOptionsFilter = [];

    variableOptions.forEach(variable => {
        if (variable.fullName.includes('sourceAutomaticValues.url.query.')) {
            variableOptionsFilter.push(variable);
        }
    });

    const defaultRow = () => {
        const initialConditions = conditions.map(item => {
            let newItem = {
                urlParameter: item.urlParameter,
                matchType: item.matchType,
                parameterValue: item.value
            };
            return newItem;
        });
        const initialCustomVariables = customVariables.map(item => {
            let newItem = { id: item.id, value: "" };
            return newItem;
        });
        return { conditions: [...initialConditions], customVariables: [...initialCustomVariables] };
    }

    const getInitialTableRow = () => {
        let table = [];
        let conditionData = conditions.map(item => item);
        let customVariableData = customVariables.map(item => item);
        for (let i = 0; i < conditionData.length; i++) {
            table.push({ conditions: conditionData[i], customVariables: customVariableData[i] });
        }
        setTableRow(table);
    }

    const fetchCustomVariables = () => {
        if (customVariableOptions.length === 0) {
            const urlToCall = `${API_CLIENT_CUSTOM_VARIABLE}${activeAccountId}?group=userSource`;

            callTokenApi(urlToCall, 'GET', null)
                .then(response => {
                    if (response.status === 200) {
                        dispatch(setCustomVariableOptions(response.data.variables));
                    }
                })
        }
    }

    const fetchData = () => {
        getInitialTableRow();
        fetchCustomVariables();
    }

    useEffect(fetchData, []); // eslint-disable-line react-hooks/exhaustive-deps

    const beforeunload = function (e) {
        e.preventDefault();
        e.returnValue = '';
    }

    useEffect(() => {
        dispatch(setUnsavedLookupTableModal({ unsaved: !btnSaveStatus }));

        let unblock = history.block((location, action) => {
            let url = location.pathname;
            if (!btnSaveStatus) {
                setBtnSaveStatus(true)
                setNavigateTo(url)
                setLeaveComponentModal(true)
                return false
            }
            return true
        });

        if (!btnSaveStatus) {
            document.querySelector('.flexible-modal-body').addEventListener('beforeunload', beforeunload);
        }

        return () => {
            document.querySelector('.flexible-modal-body').removeEventListener('beforeunload', beforeunload);
            unblock();
        };
    }, [btnSaveStatus, history, dispatch])

    const handleAcceptLeaveComponent = () => {
        if (unsavedLookupTableModal.show) {
            let table = [];
            let conditionData = initialStepsData.conditions.map(item => item);
            let customVariableData = initialStepsData.customVariables.map(item => item);
            for (let i = 0; i < conditionData.length; i++) {
                table.push({ conditions: conditionData[i], customVariables: customVariableData[i] });
            }
            setTableRow(table);

            setBtnSaveStatus(true);
            unsavedLookupTableModal.onAccept();
            dispatch(setUnsavedLookupTableModal({ show: false, onAccept: null }));
        } else {
            setBtnAbandon(true)
            window.location.href = navigateTo
        }
    }

    const handleCloseLeaveComponent = () => {
        setLeaveComponentModal(false)
        setBtnSaveStatus(false)
        dispatch(setUnsavedLookupTableModal({ show: false }));
    }

    const handleAddRow = (rows = 1) => {
        let newTableRow = [...tableRow];
        for (let i = 0; i < rows; i++) {
            let newRow = {};

            newRow.conditions = {
                urlParameter: "",
                matchType: "ct",
                value: ""
            }
            if (stepsData.name === 'Referrer Rules') {
                newRow.conditions.urlParameter = 'hostname';
            }
            newRow.customVariables = {
                userSource: ""
            }
            newTableRow.push(newRow);
        }
        setTableRow(newTableRow);
        resetBtnSaveStatus();
    }

    const confirmRemoveRow = () => {
        let data = [...tableRow];
        let { index } = removeRowModal;
        if (data.length > 1) data.splice(index, 1);
        else data = [defaultRow()];
        setTableRow(data);
        setRemoveRowModal({ show: false, index: -1 });
        setDeleteLoading(false);
        resetBtnSaveStatus();
        setDeleteLoading(false);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        let { errorColumn, errorName } = validateSubmited();
        if (errorColumn.length > 0) {
            setHasError(errorColumn);
            if (errorName === 'empty') {
                toast.error(`Field cannot be empty.`);
            } else {
                toast.error(`Duplicate!`);
            }
        } else {
            setSaveTableModal(true);
        }
    }

    const validateSubmited = () => {
        let errorColumn = [], errorName = '';

        for (let i = 0; i < tableRow.length; i++) {
            for (let j = i + 1; j < tableRow.length - 1; j++) {
                if (tableRow[i].conditions.urlParameter === tableRow[j].conditions.urlParameter &&
                    tableRow[i].conditions.matchType === tableRow[j].conditions.matchType &&
                    tableRow[i].conditions.value === tableRow[j].conditions.value &&
                    tableRow[i].customVariables.userSource === tableRow[j].customVariables.userSource) {
                    errorColumn.push(i, j);
                    errorName = 'double';
                }
            }
        }

        tableRow.some((row, index) => {
            if (row.conditions.urlParameter === '' || (row.conditions.matchType !== 'exav' && row.conditions.matchType !== 'nex' && row.conditions.value === '') || row.customVariables.userSource === '') {
                errorColumn.push(index);
                errorName = 'empty';
                return true;
            }
            return false;
        });

        return { errorColumn, errorName };
    }

    const onAcceptSaveModal = () => {
        let data = { ...stepsData };
        let newConditions = [], newCustomVariables = [];

        tableRow.forEach((row) => {
            newConditions.push(row.conditions);
            newCustomVariables.push(row.customVariables);
        })

        handleSaveLookupTable({
            accountId: data.accountId,
            listenerId: data.listenerId,
            id: data.id,
            conditions: newConditions,
            customVariables: newCustomVariables,
            type: data.type,
            name: data.name,
            description: data.description
        });
        setBtnSaveStatus(true)
    }

    const handleSaveLookupTable = (data) => {
        setSaveLoading(true);
        callTokenApi(API_CLIENT_RULE_UPDATE, 'POST', data)
            .then((response) => {
                if (response.status !== 200) {
                    if (response.data.accountBlocked) {
                        dispatch(setShowBlockAccountPopup(true))
                    } else {
                        toastError(response)
                    }
                } else {
                    setSaveTableModal(false);
                    dispatch(setRuleHaveEditting({ show: false, type: '', showLv2: false, typeLv2: '' }));
                    dispatch(setFlexibleModal({
                        show: false,
                        showLv2: false,
                        component: '',
                    }));
                }
            }).finally(() => {
                setSaveLoading(false);
            });
    }

    const handleReverting = () => {
        let data = {
            accountId: activeAccountId,
            ruleId: stepsData.id
        }
        setSaveLoading(true);
        callTokenApi(API_CLIENT_RULE_REVERT, 'POST', data)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(updateRule(data));
                } else {
                    if (response.data.accountBlocked) {
                        dispatch(setShowBlockAccountPopup(true));
                    } else {
                        toastError(response);
                    }
                }
            })
            .finally(() => setSaveLoading(false));

    }

    const revertToDefault = () => {
        let data = {
            accountId: activeAccountId,
            ruleId: stepsData.id
        }
        setSaveLoading(true);
        callTokenApi(API_CLIENT_RULE_REVERT_DEFAULT, 'POST', data)
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Revert to default rules!');
                    dispatch(updateRule(data));
                } else {
                    if (response.data.accountBlocked) {
                        dispatch(setShowBlockAccountPopup(true));
                    } else {
                        toastError(response);
                    }
                }
            })
            .finally(() => setSaveLoading(false));
    }

    const resetBtnSaveStatus = () => {
        if (btnSaveStatus === true) {
            setBtnSaveStatus(false);
            dispatch(setRuleHaveEditting({ show: true, type: TYPE_SHOW_UNSAVE_CHANGE.EDIT_TABLE }));
        }
    }

    const handleChangeRowData = (value, arrayName, rowIndex) => {
        let data = [...tableRow];

        switch (arrayName) {
            case 'matchType':
                data[rowIndex].conditions.matchType = value;
                break;
            case 'urlParameter':
                data[rowIndex].conditions.urlParameter = value;
                break;
            case 'value':
                data[rowIndex].conditions.value = value;
                break;
            case 'userSource':
                data[rowIndex].customVariables.userSource = value;
                break;
            default:
                break;
        }

        setTableRow(data);
        resetBtnSaveStatus();
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const rows = reorder(
            tableRow,
            result.source.index,
            result.destination.index
        );
        resetBtnSaveStatus();
        setTableRow(rows);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const toggleVariableModal = useCallback(() => {
        setShowVariableModal(!showVariableModal);
    }, [showVariableModal])

    const closeVariableModal = useCallback(() => {
        setShowVariableModal(false);
    }, [showVariableModal]) // eslint-disable-line

    const bodyRowContext = {
        tableRow, setTableRow, resetBtnSaveStatus,
        setRemoveRowModal,
        handleChangeRowData,
        toggleVariableModal,
        setCursorPosition,
        setVariablePositionToInsert,
        hasError,
        name: stepsData.name
    }
    const importExportContext = {
        tableRow,
        setTableRow,
        setBtnSaveStatus
    }
    const observedElementsRef = useRef([]);
    useEffect(() => {
        // Handle lockdown table header Start
        let header = document.querySelectorAll('.flexible-modal-header');
        let body = document.querySelectorAll('.flexible-modal-body');
        let table = document.querySelector(".lookup-table-init");
        let stickyTable = document.querySelector(".lookup-table-sticky");

        if (header.length > 0) {
            header = header[!flexibleModal.showLv2 ? 0 : 1];
        }

        if (body.length > 0) {
            body = body[!flexibleModal.showLv2 ? 0 : 1];
        }

        let headerHeight = header.offsetHeight;

        const handleResize = () => {
            let tableHeaderTop = table ? table.offsetTop : 0;
            let headerBottom = headerHeight + body.scrollTop;
            let tableHeader = table ? table.childNodes[0] : [];
            let tableHeader2ndRow = tableHeader.childNodes[1];
            let tableHeaderColumns;
            if (tableHeader2ndRow && tableHeader2ndRow.childNodes) {
                tableHeaderColumns = tableHeader2ndRow.childNodes;
            }
            let stickyTableHeader = stickyTable.childNodes[0];
            headerHeight = header.offsetHeight;

            const updateStickyTable = () => {
                stickyTable.style.width = table.parentNode.offsetWidth + 'px';
                stickyTable.style.top = headerHeight + 'px';
                for (let i = 0; i < tableHeaderColumns.length; i++) {
                    stickyTableHeader.childNodes[1].childNodes[i].style.width = tableHeaderColumns[i].offsetWidth + 'px';
                }
            }

            if (headerBottom > tableHeaderTop + 10) {
                if (window.screen.width === 991) {
                    setTimeout(() => {
                        updateStickyTable();
                    }, 600);
                } else {
                    updateStickyTable();
                }
            }
        }
        let modalBody = document.querySelectorAll('.flexible-modal-body');

        if (modalBody.length > 0) {
            modalBody = modalBody[!flexibleModal.showLv2 ? 0 : 1];
        }

        const handleScroll = () => {
            let tableHeaderTop = table ? table.offsetTop : 0;
            let headerBottom = headerHeight + body.scrollTop;
            let tableHeader = table ? table.childNodes[0] : [];
            let tableHeader2ndRow = tableHeader.childNodes ? tableHeader.childNodes[1] : null;
            let tableHeaderColumns;
            if (tableHeader2ndRow && tableHeader2ndRow.childNodes) {
                tableHeaderColumns = tableHeader2ndRow.childNodes;
            }
            let stickyTableHeader = stickyTable.childNodes[0];
            if (headerBottom > tableHeaderTop + 15) {
                tableHeader.style.visibility = 'hidden';
                stickyTable.style.display = 'block';
                stickyTable.style.width = table.parentNode.offsetWidth + 'px';
                stickyTable.style.top = headerHeight + 'px';
                for (let i = 0; i < tableHeaderColumns.length; i++) {
                    stickyTableHeader.childNodes[1].childNodes[i].style.width = tableHeaderColumns[i].offsetWidth + 'px';
                }
            } else {
                table.children[0].style.visibility = 'visible';
                stickyTable.style.display = 'none';
            }
        }

        modalBody.addEventListener('scroll', handleScroll);
        const resizeObserver = new ResizeObserver(entries => {
            entries.forEach(() => {
                handleResize();
            });
        });

        const observedElements = document.querySelectorAll(`.ll-flexible-modal`);

        observedElements.forEach(element => {
            resizeObserver.observe(element);
        });

        observedElementsRef.current = Array.from(observedElements);
        return () => {
            resizeObserver.disconnect();
            modalBody.removeEventListener('scroll', handleScroll);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const tableWrapperOnScroll = (e) => {
        let stickyTable = document.querySelector(".lookup-table-sticky");
        let scrollLeft = e.target.scrollLeft;
        stickyTable.scrollLeft = scrollLeft;
    }

    useEffect(() => {
        if (leaveComponentModal || unsavedLookupTableModal.show) {
            setShowVariableModal(false);
        }
    }, [leaveComponentModal, unsavedLookupTableModal])

    return (
        <>
            <CForm className="form-edit-lookup default-lookup-rule" onSubmit={onSubmit}>
                <CRow>
                    <CCol md='12' className="form-edit-lookup-row">
                        <div className="form-edit-lookup-title">
                            <h1>Edit {stepsData.type}: {stepsData.name}</h1>
                        </div>
                        <div className="form-edit-lookup-button">
                            <CButton
                                color="primary"
                                onClick={() => { setRevertDefaultModal(true) }}
                                className="mr-3 mb-2 d-inline-block text-uppercase"
                            >
                                Revert to Default Rules
                            </CButton>
                            {
                                stepsData.hasOldVersion && (
                                    <CButton
                                        color="primary"
                                        onClick={() => { setRevertModal(true) }}
                                        className="mr-3 mb-2 d-inline-block text-uppercase"
                                    >
                                        Revert Recent Changes
                                    </CButton>
                                )
                            }
                            <CButton
                                color="success"
                                type="submit"
                                className="d-inline-block mb-2 mr-3 text-uppercase"
                                disabled={btnSaveStatus}
                            >
                                Save Changes
                            </CButton>
                        </div>
                    </CCol>
                    <CCol md='12' className="form-edit-lookup-description">
                        {
                            stepsData.name === 'Query String Rules' ? (
                                <p>The rules below use a single parameter value to determine the user source. Rules are read from top to bottom until a match is found. Insert your own rules or modify the default values here.</p>) : (
                                <p>The rules below use a the referring URL to define the user source. Rules are read from top to bottom until a match is found. Insert your own rules or modify the default values here.</p>
                            )
                        }

                    </CCol>
                </CRow>

                <div className="table-wrapper user-source mb-3" onScroll={(e) => tableWrapperOnScroll(e)}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="table">
                            {
                                provided => (
                                    <>
                                        <table className="table table-user-source lookup-table lookup-table-init" ref={provided.innerRef} >
                                            <thead>
                                                <tr className="rule-flow">
                                                    <td colSpan="3">If these conditions are true</td>
                                                    <td colSpan="1">Set User Source to this value in my data layer</td>
                                                </tr>
                                                <TableHeaderRow name={stepsData.name} />
                                            </thead>
                                            <tbody>
                                                <LookupTableContext.Provider innerRef={provided.innerRef} {...provided.droppableProps} value={bodyRowContext} >
                                                    <TableBodyRows />
                                                    {provided.placeholder}
                                                </LookupTableContext.Provider>
                                            </tbody>
                                        </table>
                                        <table className="table lookup-table lookup-table-sticky">
                                            <thead>
                                                <tr className="rule-flow">
                                                    <td colSpan="3">If these conditions are true</td>
                                                    <td colSpan="1">Set User Source to this value in my data layer</td>
                                                </tr>
                                                <TableHeaderRow name={stepsData.name} />
                                            </thead>
                                        </table>
                                    </>
                                )
                            }
                        </Droppable>
                    </DragDropContext>
                </div>
                <div className={`add-margin-cheat-sheet form-edit-lookup-row`}>
                    <div className={`add-row-button mb-4 d-flex align-items-center `}>
                        <div className='add-row-button-child d-flex'>
                            <CButton
                                className='btn-add-row'
                                onClick={() => handleAddRow(1)}
                            >
                                <CIcon icon='iconAddField' className='icon-add' />
                                <CLabel className='add-row mb-0'>Add Row</CLabel>
                            </CButton>
                            <CButton
                                className='btn-add-row'
                                onClick={() => handleAddRow(5)}
                            >
                                <CIcon icon='iconAddField' className='icon-add' />
                                <CLabel className='add-row mb-0'>Add 5 Rows</CLabel>
                            </CButton>
                        </div>
                        <LookupTableContext.Provider value={importExportContext}>
                            <ImportExportTable />
                        </LookupTableContext.Provider>
                    </div>
                    <div className="mb-3 text-right d-flex align-items-start flex-wrap justify-content-end">
                        <CButton
                            color="primary"
                            onClick={() => { setRevertDefaultModal(true) }}
                            className="mr-3 d-inline-block text-uppercase"
                        >
                            Revert to Default Rules
                        </CButton>
                        {
                            stepsData.hasOldVersion && (
                                <CButton
                                    color="primary"
                                    onClick={() => { setRevertModal(true) }}
                                    className="mr-3 d-inline-block text-uppercase"
                                >
                                    Revert Recent Changes
                                </CButton>
                            )
                        }
                        <CButton
                            color="success"
                            type="submit"
                            className="d-inline-block text-uppercase"
                            disabled={btnSaveStatus}
                        >
                            Save Changes
                        </CButton>
                    </div>
                </div>
            </CForm>
            <RemovePopup
                show={removeRowModal.show}
                onAccept={confirmRemoveRow}
                onClose={() => setRemoveRowModal({ show: false, index: -1 })}
                loading={deleteLoading}
            >
                <p>
                    Are you sure to remove this row?
                </p>
            </RemovePopup>
            <ConfirmSaveChange
                show={saveTableModal}
                onAccept={onAcceptSaveModal}
                onClose={() => setSaveTableModal(false)}
                title="Save Your Changes?"
                isLoading={saveLoading}
            >
                It looks like you have made changes to this lookup table but have not saved them. Would you like to save the changes before navigating away?
            </ConfirmSaveChange>
            <ConfirmSaveChange
                show={revertModal}
                onAccept={handleReverting}
                onClose={() => setRevertModal(false)}
                title="Revert This Rule?"
                isLoading={saveLoading}
            >
                This rule will be reverted to the last version. Would you like to revert it?
            </ConfirmSaveChange>
            <ConfirmSaveChange
                show={revertDefaultModal}
                onAccept={revertToDefault}
                onClose={() => setRevertDefaultModal(false)}
                title="Revert Default This Rule?"
                isLoading={saveLoading}
            >
                This rule will be reverted to default. Would you like to revert it?
            </ConfirmSaveChange>
            <LeaveComponentChangePopup
                show={leaveComponentModal || unsavedLookupTableModal.show}
                onAccept={handleAcceptLeaveComponent}
                onClose={handleCloseLeaveComponent}
                title="You Have Unsaved Changes"
                btnAbandon={btnAbandon ? (<span className="dots-waiting">Waiting</span>) : "Abandon My Changes & Leave"}
            >
                You haven’t saved the changes you started making to this lookup table. If you navigate away, you will lose the changes. What do you want to do?
            </LeaveComponentChangePopup>
            <InsertVariableModal
                showVariableModal={showVariableModal}
                toggleVariableModal={toggleVariableModal}
                closeVariableModal={closeVariableModal}
                handleChangeRowData={handleChangeRowData}
                variableOptions={variableOptions}
                variablePositionToInsert={variablePositionToInsert}
                cursorPosition={cursorPosition}
                tableRow={tableRow}
                listener={activeListener}
                typeRule={TYPE_RULE.USER_SOURCE_RULE}
            />
        </>
    )
}

LookupTable.propTypes = {
    stepsData: PropTypes.object,
    initialStepsData: PropTypes.object,
    setStepsData: PropTypes.func
}
export default LookupTable
