import React, { useContext, useCallback } from 'react';
import { CButton, CCard } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useDispatch, useSelector } from 'react-redux';

import { AsideContext, ASIDE_COMPONENTS } from './TheAside';
import { COMPONENT_NAME } from '../../../constants';
import { setFlexibleModal } from '../../../actions/common';

const UpgradeRequired = ({ onClose }) => {
    const dispatch = useDispatch();
    const { setCurrentComponents, resetPackageSelected, packageSelected } = useContext(AsideContext);
    const accountNumberOfPremium = useSelector(state => state.subscriber.activeAccount.numberOfPremium);
    const { numberOfPremium: packageNumberOfPremium } = packageSelected;
    const numberOfPremiumToDisable = accountNumberOfPremium - packageNumberOfPremium;

    const goBack = useCallback(() => {
        setCurrentComponents(ASIDE_COMPONENTS.COMPARE_PLANS);
    }, [setCurrentComponents])

    const onAccept = useCallback(() => {
        setCurrentComponents(ASIDE_COMPONENTS.LIMITATION_HIT);
    }, [setCurrentComponents])

    const onClosePopup = () => {
        resetPackageSelected();
        onAccept();
        onClose();
        dispatch(
            setFlexibleModal({
                 show: true,
                 component: COMPONENT_NAME.LISTENER_LIBRARY,
            })
        );
    }

    return (
        <div className='full-screen-wrapper'>
            <div className="action-required-popup">
                <div className="account-header">
                    <CButton className="btn-back btn-back-choose" onClick={goBack}>
                        <i className="fal fa-angle-left"></i>
                        Back
                    </CButton>
                    <CIcon
                        icon="logo-dark"
                        height={34}
                    />
                    <CCard className="action-required">
                        <div className="confirm-save-icon"><CIcon icon="icon-save" height={46} width={46} /></div>
                        <div className='action-required-title'>
                            <h2>Action is Required</h2>
                        </div>
                        <div className='action-required-description'>
                            <p>
                                It is not currently possible to upgrade to this plan because your account is using {accountNumberOfPremium} premium listeners, and this plan allows only {packageNumberOfPremium}.{" "}
                                Before you can upgrade, please disable {numberOfPremiumToDisable} premium {numberOfPremiumToDisable > 1 ? 'listeners' : 'listener'} and publish those changes or select another plan.
                            </p>
                        </div>
                        <div className='action-required-button'>
                            <CButton
                                className="btn-primary"
                                onClick={onClosePopup}
                            >
                                Go to Listeners page
                            </CButton>
                        </div>
                    </CCard>
                    <div className="footer text-center">
                        <CIcon
                            icon="logo-dark"
                            height={34}
                        />
                        <div className="d-flex justify-content-center">
                            <div className="text-footer">Support</div>
                            <div className="text-footer text-terms">Terms of Use</div>
                            <div className="text-footer text-privacy">Privacy & Data Policy</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpgradeRequired
