import React, { useContext, useState } from 'react';
import CIcon from '@coreui/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
     BROWSER_STORAGE_TYPE,
     COPY_TO_CLIPBOARD,
     GTM_STATUSES_IN_LL,
     GTM_WIZARD_STEPS,
     GTM_WIZARD_TYPE,
     STORE_IN_BROWSER
} from '../../../constants';
import { setGTMWizard } from '../../../actions/subscriber';
import { PreviewFeatureContext } from './ThePreviewFeature';
import { CLink } from '@coreui/react';
import classNames from 'classnames';

const GTMAction = ({ isGray, actionRequired, onClick, toolTipMessage, disabled }) => (
     <span className={classNames('tooltip-container', 'show-on-hover', { 'disabled': disabled })}>
          <CLink className='option' disabled={disabled} onClick={onClick}>
               <CIcon className={classNames('icon-action', { 'fillgrayscale': isGray })} icon="iconGTMv2" />
          </CLink>
          <div className="block-tooltip">
               <CIcon icon="arrowTooltip" className="arrow-tooltip-preview" />
               <div className="block-tooltip-text">
                    <p onClick={onClick}>{toolTipMessage}</p>
               </div>
          </div>
     </span>
);

const Item = ({ props, path }) => {
     const dispatch = useDispatch();
     const { listenerId, setStoreBrowser, eventConversion, eventCode, disableAction } = useContext(PreviewFeatureContext);
     const gtmVariablesInLL = useSelector(state => state.subscriber.gtmVariablesInLL);


     const [action, setAction] = useState('');

     const handleActionOnField = (type, action, data) => {
          if (type === STORE_IN_BROWSER.KEY) {
               setStoreBrowser({ type, action, listenerId, data: { ...data, path } });
          } else {
               setAction(action);
               setTimeout(() => {
                    setAction('');
               }, 1000);
          }
     }

     function toPlainString(num) {
          return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
               function (a, b, c, d, e) {
                    return e < 0
                         ? b + '0.' + Array(1 - e - c.length).join(0) + c + d
                         : b + c + d + Array(e - d.length + 1).join(0);
               });
     }

     const showValue = (value) => {
          switch (typeof value) {
               case 'string':
                    return `"${props.value || ' '}"`;

               case 'number':
                    return toPlainString(props.value);

               case 'undefined':
                    return "null";

               case 'object':
               case 'boolean':
               default:
                    return `${JSON.stringify(props.value)}`;
          }
     }

     const onClickRegister = (type, foundGTMVariable) => {
          switch (type) {
               case GTM_STATUSES_IN_LL.UNPUBLISHED:
                    return dispatch(setGTMWizard({
                         show: true,
                         initialStep: GTM_WIZARD_STEPS.gtmPublishData,
                         type: GTM_WIZARD_TYPE.VARIABLE,
                         data: { name: path, status: GTM_STATUSES_IN_LL.UNPUBLISHED, gtmObjInLLId: foundGTMVariable.id }
                    }));
               default:
                    return dispatch(setGTMWizard({
                         show: true,
                         initialStep: GTM_WIZARD_STEPS.gtmCreateData,
                         type: GTM_WIZARD_TYPE.VARIABLE,
                         data: { name: path, listenerId }
                    }));
          }
     };

     const generateGTMAction = () => {
          let foundGTMVariable = Array.isArray(gtmVariablesInLL) && gtmVariablesInLL.find(v => v.dataLayerPath === path);

          if (foundGTMVariable) {
               if (!foundGTMVariable.isPublished) {
                    return (
                         <GTMAction
                              isGray
                              actionRequired
                              onClick={() => onClickRegister(GTM_STATUSES_IN_LL.UNPUBLISHED, foundGTMVariable)}
                              toolTipMessage='You Need to Publish GTM'
                         />
                    );
               }

               if (foundGTMVariable.status === GTM_STATUSES_IN_LL.REGISTERED) {
                    return (
                         <GTMAction
                              toolTipMessage='Registered in GTM'
                              disabled
                         />
                    );
               }
          }

          return (
               <GTMAction
                    isGray
                    onClick={onClickRegister}
                    toolTipMessage='Register in GTM'
               />
          );
     };

     return (
          <li>
               <div className='key'>{props.item}:</div>
               <div className='value'>{showValue(props.value)}</div>
               <div className="action-on-field">
                    <span className="tooltip-container show-on-hover">
                         <CIcon className="inverse" icon="iconClone" height={14} />
                         <div className="block-tooltip">
                              <CIcon icon="arrowTooltip" className="arrow-tooltip-preview" />
                              <div className="block-tooltip-text copy-on-field">
                                   <CopyToClipboard text={props.item} onCopy={() => handleActionOnField(COPY_TO_CLIPBOARD.KEY, COPY_TO_CLIPBOARD.NAME)}>
                                        <p>{action === COPY_TO_CLIPBOARD.NAME ? COPY_TO_CLIPBOARD.COPIED : COPY_TO_CLIPBOARD.NAME}</p>
                                   </CopyToClipboard>
                                   <CopyToClipboard text={showValue(props.value)} onCopy={() => handleActionOnField(COPY_TO_CLIPBOARD.KEY, COPY_TO_CLIPBOARD.VALUE)}>
                                        <p>{action === COPY_TO_CLIPBOARD.VALUE ? COPY_TO_CLIPBOARD.COPIED : COPY_TO_CLIPBOARD.VALUE}</p>
                                   </CopyToClipboard>
                                   {
                                        !disableAction && (
                                             <CopyToClipboard text={path} onCopy={() => handleActionOnField(COPY_TO_CLIPBOARD.KEY, COPY_TO_CLIPBOARD.PATH)}>
                                                  <p>{action === COPY_TO_CLIPBOARD.PATH ? COPY_TO_CLIPBOARD.COPIED : COPY_TO_CLIPBOARD.PATH}</p>
                                             </CopyToClipboard>
                                        )
                                   }

                                   <CopyToClipboard text={JSON.stringify(props)} onCopy={() => handleActionOnField(COPY_TO_CLIPBOARD.KEY, COPY_TO_CLIPBOARD.OBJECT)}>
                                        <p>{action === COPY_TO_CLIPBOARD.OBJECT ? COPY_TO_CLIPBOARD.COPIED : COPY_TO_CLIPBOARD.OBJECT}</p>
                                   </CopyToClipboard>
                              </div>
                         </div>
                    </span>
                    {props.item !== 'event' && !eventConversion && listenerId && eventCode !== 'pageRequested' && (
                         <span className="tooltip-container show-on-hover">
                              <CIcon icon="iconDatabase" />
                              <div className="block-tooltip">
                                   <CIcon icon="arrowTooltip" className="arrow-tooltip-preview" />
                                   <div className="block-tooltip-text">
                                        <p onClick={() => handleActionOnField(STORE_IN_BROWSER.KEY, BROWSER_STORAGE_TYPE.COOKIE, props)}>{STORE_IN_BROWSER.COOKIE}</p>
                                        <p onClick={() => handleActionOnField(STORE_IN_BROWSER.KEY, BROWSER_STORAGE_TYPE.SESSION_STORAGE, props)}>{STORE_IN_BROWSER.SESSION}</p>
                                        <p onClick={() => handleActionOnField(STORE_IN_BROWSER.KEY, BROWSER_STORAGE_TYPE.LOCAL_STORAGE, props)}>{STORE_IN_BROWSER.LOCAL_STORAGE}</p>
                                   </div>
                              </div>
                         </span>
                    )}
                    {
                         !disableAction && (
                              <>{generateGTMAction()}</>
                         )
                    }
               </div>
          </li>
     )
}

export default Item;