import { CButton, CForm} from '@coreui/react';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { useActiveDestination } from '../../../../../../../helpers/customHooks';
import Waiting from '../../../../../../general/Loadings/Waiting';
import { CInput } from '../../../../../../migration/CInput';
import { CInvalidFeedback } from '../../../../../../migration/CInvalidFeedback';
import CLabel from '../../../../../../migration/CLabel';
import CFormGroup from '../../../../../../migration/CFormGroup';

const validationSchema = () =>
     Yup.object().shape({
          pixelId: Yup.string().required('Required!'),
          accessToken: Yup.string()
               .required('Required!')
               .test('is-accessTokenOverride', 'The value must be a string with no special characters.', (accessTokenOverride) => {
                    if (/^[a-zA-Z0-9]*$/.test(accessTokenOverride) !== true) {
                         return false;
                    }
                    return true;
               }),
     });

const FacebookAds = ({ onSubmit, initialData }) => {
     const activeDestination = useActiveDestination();
     const initialValues = {
          pixelId: initialData.pixelId || '',
          accessToken: initialData.accessToken || '',
     };

     return (
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnChange={false} validateOnBlur={false}>
               {({ values, errors, handleSubmit, isSubmitting, handleChange }) => {
                    return (
                         <CForm noValidate onSubmit={handleSubmit} className={`general-setting-form ${activeDestination.alias}`}>
                              <CFormGroup>
                                   {!initialData.pixelId && (
                                        <div className='form-group-general'>
                                             <div className='label-wrapper'>
                                                  <CLabel>
                                                       Pixel ID <span className='text-danger'>*</span>
                                                  </CLabel>
                                             </div>
                                             <div className='input-field'>
                                                  <CInput
                                                       name='pixelId'
                                                       value={values.pixelId}
                                                       onChange={handleChange}
                                                       invalid={!!errors.pixelId}
                                                       disabled={isSubmitting}
                                                  />
                                                  <CInvalidFeedback>{errors.pixelId}</CInvalidFeedback>
                                             </div>
                                        </div>
                                   )}
                                   {!initialData.accessToken && (
                                        <div className='form-group-general'>
                                             <div className='label-wrapper'>
                                                  <CLabel>
                                                       Access Code <span className='text-danger'>*</span>
                                                  </CLabel>
                                             </div>
                                             <div className='input-field'>
                                                  <CInput
                                                       name='accessToken'
                                                       value={values.accessToken}
                                                       onChange={handleChange}
                                                       invalid={!!errors.accessToken}
                                                       disabled={isSubmitting}
                                                  />
                                                  <CInvalidFeedback>{errors.accessToken}</CInvalidFeedback>
                                             </div>
                                        </div>
                                   )}
                                   <span className='foot-note'>
                                        <a
                                             className='question'
                                             href='https://developers.facebook.com/docs/meta-pixel/get-started'
                                             target='_blank'
                                             rel='noopener noreferrer'
                                        >
                                             How do I find this?
                                        </a>
                                   </span>
                              </CFormGroup>
                              <div className='save-changes-button-wrapper'>
                                   <CButton type='submit' color='primary' disabled={isSubmitting}>
                                        <Waiting isLoading={isSubmitting}>Continue</Waiting>
                                   </CButton>
                              </div>
                         </CForm>
                    );
               }}
          </Formik>
     );
};

export default FacebookAds;
