
import React from 'react';
import {
     CButton,
     CModal,
     CModalBody,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';

const PublishUpdatesModal = ({ show, onAccept, onCancel, acceptBtnDisabled }) => {
     return (
          <div className="popup-publish-version popup-publish-updates">
               <CModal visible={show} backdrop="static" onClose={onCancel} color="primary" alignment="center" portal={false}>
                    <CModalBody>
                         <CIcon icon="cil-x" onClick={onCancel} className="icon-close-popup"></CIcon>
                         <div className="popup-publish-version-description">
                              <h2>Publish to See Your Changes</h2>
                              <p>Your rule has been saved, but you won't see it working inside your testing session until you public your changes.</p>
                         </div>
                         <div className='group-button'>
                              <CButton color="primary" onClick={onAccept} disabled={acceptBtnDisabled}>Publish Changes</CButton>
                              <CButton color="primary" onClick={onCancel}>Continue Testing</CButton>
                         </div>
                    </CModalBody>
               </CModal>
          </div>
     )
}

export default PublishUpdatesModal;
