import React from 'react';
import CIcon from '@coreui/icons-react';
import { CModal, CContainer, CCard, CCardBody, CButton } from '@coreui/react';
import TargetForms from './TargetForm';
// import { useDispatch, useSelector } from 'react-redux';
// import { setCustomFormTargetRules } from '../../../../../../../actions/subscriber';

const TargetRulesModal = ({ show, onBack, data }) => {
     // const dispatch = useDispatch();
     // const groups = useSelector((state) => state.subscriber.customFormTargetRules);

     // const onClickBack = () => {
     //      let newGroups = [...groups];

     //      if (newGroups.length > 0) {
     //           newGroups.pop();
     //           if (newGroups.length > 0 && newGroups[newGroups.length - 1]) {
     //                newGroups[newGroups.length - 1].conjunction = '';
     //           } else {
     //                newGroups.push({
     //                     conjunction: '',
     //                     g0: {
     //                          attribute: '',
     //                          operator: '',
     //                          value: '',
     //                          conjunction: '',
     //                     },
     //                });
     //           }

     //           dispatch(setCustomFormTargetRules(newGroups));
     //      }

     //      onBack();
     // };

     return (
          <div className='form-categories'>
               <CModal visible={show} backdrop="static" id='form-categories--modal-content' portal={false}>
                    <div className='modal-body'>
                         <CContainer>
                              <div className='d-flex justify-content-center form-categories-header'>
                                   <CButton className='btn-back' onClick={onBack}>
                                        GO BACK
                                   </CButton>
                                   <div className='hero-logo'>
                                        <CIcon icon='logo-dark' height={23} />
                                   </div>
                              </div>
                              <CCard className='form-categories--card form-categories--card-lg form-categories--categorizing'>
                                   <CCardBody>
                                        <h3>Identify Your Forms</h3>
                                        <p>
                                             Now, let's write your rule. To keep this simple, we recommend writing a condition that will match if{' '}
                                             <b>ANY</b> of the values are found.
                                        </p>
                                        <div className='target-forms-wrap'>
                                             <TargetForms onBack={onBack} data={data} />
                                        </div>
                                        <p>
                                             The logic in your rule needs to match any and all of your forms. However, each form must only match the
                                             rule based on one attribute (id, class, or name).
                                        </p>
                                        <p>
                                             If you have forms that won’t match a rule and need better attribute values, you may need to edit them in
                                             your code.
                                        </p>
                                   </CCardBody>
                              </CCard>
                         </CContainer>
                    </div>
               </CModal>
          </div>
     );
};

export default TargetRulesModal;
