import React from 'react';
import { useFormikContext } from 'formik';
import RowContinent from './RowContinent';
import RowWorldWide from './RowWorldWide';
import RowDefault from './RowDefault';
import RowStateProvinces from './RowStateProvinces';
import RowCountry from './RowCountry';
import RowRegulatoryRegion from './RowRegulatoryRegion';

import { CONSENT_REGION_TYPES } from '../../../../../../constants';

export const RegionContext = React.createContext({});

const RowContent = ({ regionType }) => {
     let content = '';
     switch (regionType) {
          case 'worldWide':
               content = <RowWorldWide />;
               break;
          case 'stateProvinces':
               content = <RowStateProvinces />;
               break;
          case 'continent':
               content = <RowContinent />;
               break;
          case 'country':
               content = <RowCountry />;
               break;
          case 'regulatoryRegion':
               content = <RowRegulatoryRegion />;
               break;
          default:
               content = <RowDefault />;
               break;
     }
     return content;
};

const SingleRow = ({ props }) => {
     const { setFieldValue, setErrors } = useFormikContext();
     const { index, row, handleHasChange, setValidateOnChange } = props;

     const onChangeRegionType = (value) => {
          setErrors({});
          setValidateOnChange(false);
          setTimeout(() => {
               const initialItem = { regionType: '', continent: '', country: '', stateProvinces: '' };

               initialItem.regionType = value;

               if (value === 'regulatoryRegion') {
                    initialItem.continent = 'GDPRCountries';
               }
               setFieldValue(`geographicRegions.${index}`, initialItem);
          }, 0);
          if (handleHasChange) {
               handleHasChange();
          }
     };

     return (
          <RegionContext.Provider value={{ ...props, onChangeRegionType, CONSENT_REGION_TYPES }}>
               <RowContent regionType={row.regionType} setValidateOnChange={setValidateOnChange} />
          </RegionContext.Provider>
     );
};

export default SingleRow;
