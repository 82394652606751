import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CButton, CContainer, CModal } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Link } from 'react-router-dom';
import {
     reloadDataActions,
     setAccountDestination,
     setDataActionRules,
     setReloadDestinationConversions,
     setReloadDestinationEcommerce,
     setSwitchTypeWizard,
     setTriggerEventEcommerce,
} from '../../../../../actions/subscriber';
import { useActiveDestination } from '../../../../../helpers/customHooks';
import {
     API_CLIENT_ACCOUNT_DESTINATIONS,
     API_CLIENT_ACCOUNT_DESTINATION_SWITCH_TYPE,
     API_CLIENT_ACCOUNT_RULES,
     DESTINATION_CODE,
     GTM_STATUSES_IN_LL,
     GTM_WIZARD_TYPE,
     SWITCH_TYPE_WIZARD_STEP,
} from '../../../../../constants';
import { callTokenApi } from '../../../../../apiCaller';
import { deepCopyArray, toastError } from '../../../../../utils';

import GetStarted from './steps/GetStarted.js';
import Migrate from './steps/Migrate.js';
import MigrateMakeChanges from './steps/MigrateMakeChanges.js';
import MigrateSuccess from './steps/MigrateSuccess.js';
import GTMConnection from './steps/GTMConnection';
import GTMSelectAccount from './steps/GTMSelectAccount';
import GTMSelectContainer from './steps/GTMSelectContainer';
import GTMVerifyChanges from './steps/GTMVerifyChanges.js';
import GTMMakeChanges from './steps/GTMMakeChanges';
import GTMUnknownFailure from './steps/GTMUnknownFailure';
import GTMNoPublishingRights from './steps/GTMNoPublishingRights';
import GTMSuccess from './steps/GTMSuccess.js';
import Finally from './steps/Finally.js';
import { ConfirmSaveChange } from '../../../../general/popup';
import GTMSendObjects from './steps/GTMSendObjects.js';
import GTMRemoveObject from './steps/GTMRemoveObject.js';
import EachMakeChanges from './steps/EachMakeChanges.js';
import { useHistory } from 'react-router-dom';

export const SwitchTypeWizardContext = React.createContext({});

export const SwitchTypeWizardBody = (props) => {
     const { onBackBtnClicked, backBtnDisabled, shouldHideBackBtn, children, mainContentClassName = '', showCancelProcess = false } = props;
     const { isGTM, setShowCancelPopup } = useContext(SwitchTypeWizardContext);

     return (
          <CContainer className="main-container">
               <div className="d-flex align-items-center m-0 main-header">
                    <CButton
                         className={`btn-back m-0 ${onBackBtnClicked && !shouldHideBackBtn ? '' : 'invisible'}`}
                         onClick={onBackBtnClicked && !shouldHideBackBtn ? onBackBtnClicked : null}
                         disabled={backBtnDisabled}
                    >
                         Back
                    </CButton>
                    <div className="hero-logo">
                         <CIcon icon="logo-dark" height={23} />
                    </div>
               </div>
               <div className={`main-content switch-type-body ${mainContentClassName}`}>{children}</div>
               {showCancelProcess && (
                    <div className="cancel-process">
                         <CButton className="btn-navigation-step" onClick={() => setShowCancelPopup(true)}>
                              No thanks, go back
                         </CButton>
                         <span className="foot-note">
                              If you aren't ready to complete this process, you can cancel it and reactivate {isGTM ? 'Client' : 'Server'} Side at a
                              later time.
                         </span>
                    </div>
               )}
               <div className="footer-create-account">
                    <div className="logo-footer">
                         <CIcon icon="logo-dark" height={23} />
                    </div>
                    <div className="nav-link-footer">
                         <ul>
                              <li>
                                   <Link to="/">Support</Link>
                              </li>
                              <li>
                                   <Link to="">Terms of Use</Link>
                              </li>
                              <li>
                                   <Link to="/">Privacy & Data Policy</Link>
                              </li>
                         </ul>
                    </div>
               </div>
          </CContainer>
     );
};

const initialStepsData = {
     verifyChanges: [],
     gtmChanged: false,
};

const SwitchTypeWizard = () => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const switchTypeWizard = useSelector((state) => state.subscriber.switchTypeWizard);
     const accountDestination = useSelector((state) => state.subscriber.accountDestination);
     // const accountDestinations = useSelector((state) => state.subscriber.accountDestinations);
     const ecommerceEvents = useSelector((state) => state.subscriber.triggerEventEcommerce);
     const { rules: dataActionRules, reload: dataActionsReload } = useSelector((state) => state.subscriber.dataActions);
     const reloadDestinationEcommerce = useSelector((state) => state.subscriber.reloadDestinationEcommerce);

     const activeDestination = useActiveDestination();
     const { id: destinationId, name: destinationName, code: destinationCode, accountDestinationId } = activeDestination;
     const { show: showWizard, usingType } = switchTypeWizard;
     const isGTM = usingType === 'gtm';
     const isServerSide = usingType === 'server';
     const isEither = usingType === 'either';
     const initStep = accountDestination && accountDestination.initStep;
     const initUsingType = (accountDestination && accountDestination.usingType) || 'gtm';
     const objectsSync = accountDestination && accountDestination.objectsSync;
     const needConnectToGTM = !activeAccount.gtmInfo || !activeAccount.gtmInfo.currentContainer;
     const activeDataActionRules = dataActionRules ? dataActionRules.filter((item) => item.status) : [];
     const conversions = accountDestination && accountDestination.data ? accountDestination.data.conversions.filter((item) => item.isCustom) : [];
     const gtmChangesAreNotSuccess = [SWITCH_TYPE_WIZARD_STEP.gtmVerifyChanges, SWITCH_TYPE_WIZARD_STEP.gtmConnection].includes(initStep);
     const objectsData = [];
     const history = useHistory();
     let nothingObjectTurnOn = true;

     const textCancel = {
          gtm: 'Client Side',
          server: 'Server Side',
          either: 'Each Data Action'
     }

     // Get objectsData when switch from server to client
     if (showWizard) {
          if (activeDataActionRules && activeDataActionRules.length > 0) {
               activeDataActionRules.forEach((rule) => {
                    if ((initUsingType === 'either' && !rule.gtmStatus && rule.sendToServerSide) || !rule.gtmStatus) {
                         const dataActionData = {
                              type: GTM_WIZARD_TYPE.DATA_ACTION,
                              ruleId: rule.id,
                              name: rule.name,
                              isUpdating: rule.gtmStatus === GTM_STATUSES_IN_LL.MODIFIED_IN_LL,
                         };
                         objectsData.push(dataActionData);
                    }
               });
               nothingObjectTurnOn = false;
          }
          if (conversions && conversions.length > 0) {
               let newConversions = [...conversions];

               let conversionObjectTurnOn = false

               if (objectsSync && objectsSync.conversions) {
                    newConversions = conversions.map((item) => {
                         if (objectsSync.conversions.some((el) => el.id === item.id)) {
                              return { ...item, sendTo: true };
                         }
                         return item;
                    });
               }

               if (newConversions.length > 0 && newConversions.some((item) => item.sendTo)) {
                    nothingObjectTurnOn = false;
                    conversionObjectTurnOn = true
               }

               const conversionData = {
                    type: GTM_WIZARD_TYPE.DESTINATION_CONVERSION,
                    destinationConversion: {
                         conversions: newConversions,
                         destinationName,
                         destinationId,
                         destinationCode,
                         isUpdating: true,
                         status: GTM_STATUSES_IN_LL.MODIFIED_IN_LL,
                         createdAccountId: activeDestination.createdAccountId,
                    },
               };

               if (conversionObjectTurnOn) {
                    objectsData.push(conversionData);
               }
          }

          if (ecommerceEvents && ecommerceEvents.length > 0) {
               let newEcommerceEvent = [...ecommerceEvents];

               let eCommerceObjectTurnOn = false

               if (objectsSync && objectsSync.ecommerceEvent) {
                    newEcommerceEvent = ecommerceEvents.map((item) => {
                         if (objectsSync.ecommerceEvent.some((el) => el.name === item.name)) {
                              return { ...item, sendTo: true };
                         }
                         return item;
                    });
               }

               if (newEcommerceEvent.length > 0 && newEcommerceEvent.some((item) => item.sendTo)) {
                    nothingObjectTurnOn = false;
                    eCommerceObjectTurnOn = true
               }

               let sendEachTagPurchase = 'Update Tag Ecommerce';

               const purchaseEvent = ecommerceEvents && ecommerceEvents.find((item) => item.name === 'Purchase');

               if (purchaseEvent && purchaseEvent.sendTo && purchaseEvent.eventId !== '' && destinationCode === DESTINATION_CODE.GOOGLE_ADS) {
                    sendEachTagPurchase = 'Update Purchase';
               }

               const eCommerceData = {
                    type: GTM_WIZARD_TYPE.DESTINATION_ECOMMERCE,
                    destinationConversion: {
                         ecommerceEvent: newEcommerceEvent,
                         destinationName,
                         destinationId,
                         destinationCode,
                         isUpdating: true,
                         status: GTM_STATUSES_IN_LL.MODIFIED_IN_LL,
                         sendEachTagPurchase,
                         createdAccountId: activeDestination.createdAccountId,
                    },
               };

               if(eCommerceObjectTurnOn) {
                    objectsData.push(eCommerceData);
               }
          }
     }

     const [showCancelPopup, setShowCancelPopup] = useState(false);
     const [stepsData, setStepsDataState] = useState(initialStepsData);
     const [currentStep, setCurrentStep] = useState(() => {
          let initialStep = switchTypeWizard.initialStep || initStep || SWITCH_TYPE_WIZARD_STEP.getStarted;

          if (initialStep === SWITCH_TYPE_WIZARD_STEP.gtmConnection && !needConnectToGTM) {
               initialStep = SWITCH_TYPE_WIZARD_STEP.gtmVerifyChanges;
          } else if (initialStep === SWITCH_TYPE_WIZARD_STEP.gtmVerifyChanges && needConnectToGTM) {
               if (!isGTM && nothingObjectTurnOn) {
                    initialStep = switchTypeWizard.initialStep;
               } else {
                    initialStep = SWITCH_TYPE_WIZARD_STEP.gtmConnection;
               }
          }

          return initialStep;
     });

     // Fetch data actions
     const handleFetchDataActions = () => {
          if (!showWizard) return;

          const query = `?group=dataAction&destinationId=${destinationId}`;

          callTokenApi(`${API_CLIENT_ACCOUNT_RULES}/${activeAccount.id}${query}`, 'GET').then((response) => {
               if (response.status === 200) {
                    const { rules } = response.data;
                    dispatch(setDataActionRules({ rules, initialRules: deepCopyArray(rules), destinationId }));
               } else {
                    toastError(response);
               }
          });
     };
     useEffect(handleFetchDataActions, [destinationId, dataActionsReload]); // eslint-disable-line

     // Fetch eCommerce events
     const fetchECommerceEvents = () => {
          if (!showWizard) return;

          callTokenApi(`${API_CLIENT_ACCOUNT_DESTINATIONS}gtm/event-ecommerce/${activeAccount.id}?destinationId=${destinationId}`, 'GET').then(
               (response) => {
                    if (response.status === 200) {
                         const { eventEcommerceRemarketing } = response.data;
                         dispatch(setTriggerEventEcommerce(eventEcommerceRemarketing.sort((a, b) => a.name.localeCompare(b.name))));
                    } else {
                         toastError(response);
                    }
               },
          );
     };
     useEffect(fetchECommerceEvents, [destinationId, reloadDestinationEcommerce]); // eslint-disable-line

     const getCurrentComponent = useCallback(() => {
          switch (currentStep) {
               case SWITCH_TYPE_WIZARD_STEP.getStarted:
                    return <GetStarted />;
               case SWITCH_TYPE_WIZARD_STEP.migrate:
                    return <Migrate />;
               case SWITCH_TYPE_WIZARD_STEP.migrateMakeChanges:
                    return <MigrateMakeChanges />;
               case SWITCH_TYPE_WIZARD_STEP.migrateSuccess:
                    return <MigrateSuccess />;
               case SWITCH_TYPE_WIZARD_STEP.gtmConnection:
                    return <GTMConnection />;
               case SWITCH_TYPE_WIZARD_STEP.gtmSelectAccount:
                    return <GTMSelectAccount />;
               case SWITCH_TYPE_WIZARD_STEP.gtmSelectContainer:
                    return <GTMSelectContainer />;
               case SWITCH_TYPE_WIZARD_STEP.gtmVerifyChanges:
                    return <GTMVerifyChanges />;
               case SWITCH_TYPE_WIZARD_STEP.gtmSendObject:
                    return <GTMSendObjects />;
               case SWITCH_TYPE_WIZARD_STEP.gtmRemoveObject:
                    return <GTMRemoveObject />;
               case SWITCH_TYPE_WIZARD_STEP.eachMakeChanges:
                    return <EachMakeChanges />;
               case SWITCH_TYPE_WIZARD_STEP.gtmMakeChanges:
                    return <GTMMakeChanges />;
               case SWITCH_TYPE_WIZARD_STEP.gtmUnknownFailure:
                    return <GTMUnknownFailure />;
               case SWITCH_TYPE_WIZARD_STEP.gtmNoPublishingRights:
                    return <GTMNoPublishingRights />;
               case SWITCH_TYPE_WIZARD_STEP.gtmSuccess:
                    return <GTMSuccess />;
               case SWITCH_TYPE_WIZARD_STEP.finally:
                    return <Finally />;
               default:
                    return <></>;
          }
     }, [currentStep]); // eslint-disable-line

     const handleWhenHideAndShowPopup = () => {
          const popupShow = document.querySelector('.create-account-popup.switch-type-wizard .modal.show');

          if (showWizard) {
               setTimeout(() => {
                    document.body.classList.add('no-scroll');
                    if (popupShow) {
                         popupShow.classList.add('popup-transition');
                    }
               }, 0);
          } else {
               document.body.classList.remove('no-scroll');
          }
     };
     useEffect(handleWhenHideAndShowPopup, [showWizard]);

     const handleBackBrowserBtn = () => {
          if (showWizard) {
               const browserBackBtnClick = () => {
                    closeWizard();
                    history.go(1);
               };

               window.addEventListener('popstate', browserBackBtnClick, false); // Close create account popup on clicking back button of browser

               return () => window.removeEventListener('popstate', browserBackBtnClick);
          }
     };
     useEffect(handleBackBrowserBtn, []); // eslint-disable-line react-hooks/exhaustive-deps

     const handleUpdateSwitchType = (values, doNext) => {
          // Can not update step when switch to other type and gtm changes are not success
          if (
               initUsingType !== usingType &&
               gtmChangesAreNotSuccess &&
               [SWITCH_TYPE_WIZARD_STEP.getStarted, SWITCH_TYPE_WIZARD_STEP.migrate].includes(values.initStep)
          ) {
               delete values['initStep'];
          }

          if (!values || Object.keys(values).length === 0) return;

          callTokenApi(`${API_CLIENT_ACCOUNT_DESTINATION_SWITCH_TYPE}/${accountDestinationId}`, 'PUT', {...values, initUsingType})
               .then((response) => {
                    if (response.status === 200) {
                         const { newData } = response.data;

                         dispatch(setAccountDestination({ ...accountDestination, ...newData }));

                         if (values.usingType) {
                              dispatch(setSwitchTypeWizard({ usingType: values.usingType }));
                         }
                         // const newAccountDestinations = [...accountDestinations];
                         // newAccountDestinations.some((item) => {
                         //      if (item.id === newAccountDestination.destinationId && newAccountDestination.data && newAccountDestination.data.conversions) {
                         //           item.destinationConversions = newAccountDestination.data.conversions;
                         //           return true;
                         //      }
                         //      return false;
                         // });
                         // dispatch(
                         //      setSubscriberState({
                         //           accountDestinations: newAccountDestinations,
                         //      })
                         // );
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    if (typeof doNext === 'function') {
                         doNext();
                    }
               });
     };

     const setStepsData = (values) => {
          setStepsDataState((prevStepsData) => ({ ...prevStepsData, ...values }));
     };

     const closeWizard = () => {
          dispatch(setSwitchTypeWizard({ show: false }));
     };

     const handleAcceptCancelPopup = () => {
          dispatch(setSwitchTypeWizard({ show: false, usingType: initUsingType }));
     };

     const handleReloadData = () => {
          dispatch(reloadDataActions());
          dispatch(setReloadDestinationConversions());
          dispatch(setReloadDestinationEcommerce());
          dispatch(setAccountDestination(null));
     };

     const contextValue = {
          isGTM,
          isServerSide,
          isEither,
          setCurrentStep,
          stepsData,
          setStepsData,
          setShowCancelPopup,
          closeWizard,
          handleReloadData,
          handleUpdateSwitchType,
          needConnectToGTM,
          destinationName,
          usingType,
          objectsData,
          nothingObjectTurnOn,
          initUsingType,
     };

     return (
          <div className="create-account-popup switch-type-wizard">
               <SwitchTypeWizardContext.Provider value={contextValue}>
                    <CModal visible={showWizard} onClose={closeWizard} id="switch-type-wizard-content" backdrop="static" portal={false}>
                         <div className="modal-body">{getCurrentComponent()}</div>
                         <ConfirmSaveChange
                              show={showCancelPopup}
                              title="Are Your Sure?"
                              btnTextSave="Yes, cancel"
                              btnTextCancel="Go back"
                              onAccept={handleAcceptCancelPopup}
                              onClose={() => setShowCancelPopup(false)}
                         >
                              <p>
                                   We'll cancel your switch to {textCancel[usingType]}, and nothing will change in your account. You can
                                   always come back and start the process again.
                              </p>
                         </ConfirmSaveChange>
                    </CModal>
               </SwitchTypeWizardContext.Provider>
          </div>
     );
};

export default SwitchTypeWizard;
