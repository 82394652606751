import React, { useContext, useState } from 'react';
import { CButton, CCard, CCardBody } from '@coreui/react';
import { useDispatch } from 'react-redux';
import uniq from 'lodash/uniq';

import { CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
import { addFirstDomain } from '../../../../../../actions/subscriber';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';

const AccountCanAccessDomain = () => {
    const dispatch = useDispatch();
    const { account, setAccount, stepsData, setCurrentStep } = useContext(CreateAccountContext);
    const [isLoading, setIsLoading] = useState(false);
    const { domain, domainStatus } = stepsData;

    const onBackBtnClicked = () => {
        setCurrentStep(STEPS.domain);
    };

    const handleGoNext = () => {
        const successNext = () => {
            const newAccount = {
                ...account,
                domains: [{ name: domain, scriptInstalled: false, status: domainStatus }],
                completedSteps: uniq([...account.completedSteps, STEPS.domain]),
                numberOfDomains: 1
            };

            setAccount(newAccount);
            setCurrentStep(STEPS.gtmConnection);
        };

        setIsLoading(true);
        dispatch(addFirstDomain({ accountId: account.id, domain, domainStatus, successNext, setIsLoading }));
    };

    return (
        <CreateAccountBody onBackBtnClicked={onBackBtnClicked}>
            <CCard className="account-domain cannot-access">
                <p className="number-of-step">1 of 3 steps</p>
                <CCardBody>
                    {
                        isLoading ? (
                            <CenterSpinner />
                        ) : (
                            <>
                                <h3>Can’t access domain</h3>
                                <p>The domain you entered is inaccesible.</p>
                                <div className="notice">
                                    <div className="title">
                                        <p>
                                            You entered this: <span className="c-black">{stepsData.domain}</span>
                                        </p>
                                        <p>
                                            Which is returning a <span className="c-red">5xx error</span>
                                        </p>
                                    </div>
                                    <p>
                                        Note: If you are testing ListenLayer on a staging or dev website that is inaccessible, or a domain that doesn't have a homepage, our system may not see and verify the data. We recommend placing ListenLayer on your production website, just like you
                                        would do with a Google Tag Manager container.
                                    </p>
                                </div>
                                <p className="enter-new-domain">Enter a new domain or choose next if you are confident that this is the correct website.</p>
                                <CButton className="btn-save" onClick={onBackBtnClicked}>
                                    ENTER A NEW WEBSITE
                                </CButton>
                                <CButton className="btn-navigation-step" onClick={handleGoNext}>
                                    I’m confident this is correct, NEXT<i className="fal fa-angle-right"></i>
                                </CButton>
                            </>
                        )
                    }
                </CCardBody>
            </CCard>
        </CreateAccountBody>
    );
};

export default AccountCanAccessDomain;
