import React from 'react';
import { CFooter } from '@coreui/react';

const SubscriberFooter = () => {
     return (
          <CFooter
               style={{
                    '--cui-tertiary-bg': '#ffff',
                    '--cui-border-width': 0,
               }}
          ></CFooter>
     );
};

export default React.memo(SubscriberFooter);
