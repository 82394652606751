import { CButton, CModal, CModalBody, CModalFooter } from '@coreui/react';
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setViewedRule } from '../../../../../actions/common';
import { callApi } from '../../../../../apiCaller';
import { API_CLIENT_ACCOUNT_RULE } from '../../../../../constants';
import { getTokenCookie, toastError } from '../../../../../utils';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';
import ECommerceLookupTable from './lookup/ECommerceLookupTable';
import LookupRuleStep5 from './lookup/LookupTable';

const EditLookupRule = ({ rule, initialRule }) => {
     const initialStepsData = cloneDeep(initialRule);
     const [stepsData, setStepsData] = useState(rule);

     return (
          <>
               {
                    stepsData.name === 'Ecommerce Events' && !stepsData.isCustom ? (
                         <ECommerceLookupTable stepsData={stepsData} setStepsData={setStepsData} initialStepsData={initialStepsData} />
                    ) : (
                         <LookupRuleStep5 stepsData={stepsData} setStepsData={setStepsData} initialStepsData={initialStepsData} />
                    )
               }
          </>
     );
};

export const RuleContext = React.createContext({});

const EditRuleLookupTable = ({ id }) => {
     const dispatch = useDispatch();
     const token = getTokenCookie();
     const [rule, setRule] = useState();
     const [initialRule, setInitialRule] = useState();
     const [modal, setModal] = useState(false);
     const [ruleType, setRuleType] = useState('');
     const [activeStep, setActiveStep] = useState(2);
     const [fetchRuleLoading, setFetchRuleLoading] = useState();
     const redirectRules = useSelector((state) => state.subscriber.redirectRules);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const editingRuleReload = useSelector((state) => state.theme.editingRuleReload);

     const fetchRule = () => {
          setFetchRuleLoading(true);
          callApi(`${API_CLIENT_ACCOUNT_RULE}/${id}`, 'GET', null, token)
               .then((response) => {
                    if (response.status === 200) {
                         const { rule } = response.data;
                         if (rule.accountId !== activeAccount.id) {
                              window.location.href = '/';
                         }

                         setRuleType(rule.type);
                         setRule(rule);
                         setInitialRule(cloneDeep(rule));
                         dispatch(setViewedRule(cloneDeep(rule)));
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setFetchRuleLoading(false);
               });
     };
     // editingRuleReload meaning: Editing rule pusher will make editingRuleReload = !editingRuleReload,
     // so other users are editing the same rule will fetch rule again
     useEffect(fetchRule, [editingRuleReload]); // eslint-disable-line react-hooks/exhaustive-deps

     return (
          <>
               {fetchRuleLoading ? (
                    <CenterSpinner />
               ) : (
                    <div className='rule-detail'>
                         <div className='cvr-create-new custom-rule'>
                              {redirectRules.status && <Redirect to={redirectRules.to} />}
                              {rule && ruleType && (
                                   <>
                                        <RuleContext.Provider
                                             value={{
                                                  activeStep,
                                                  setRule,
                                                  setActiveStep,
                                                  fetchRule,
                                             }}
                                        >
                                             <EditLookupRule rule={rule} initialRule={initialRule} />
                                        </RuleContext.Provider>
                                        <CModal visible={modal} alignment="center" portal={false} onClose={() => setModal(false)}>
                                             <CModalBody className='text-center'>
                                                  <h3 className='mb-4'>Warning! This Will Change Your Rule</h3>
                                                  <p>
                                                       You are about to change your rule type. Doing this will reset and remove all settings inside this rule other than the
                                                       name and description.
                                                  </p>
                                                  <p>Are you sure you want to do this? You cannot undo this once you publish it!</p>
                                             </CModalBody>
                                             <CModalFooter className='text-center justify-content-center'>
                                                  <CButton color='primary' className='mr-3 text-uppercase' onClick={() => setModal(false)}>
                                                       Cancel
                                                  </CButton>
                                                  <CButton color='secondary' onClick={() => { }}>
                                                       Save Change and Reset Rule
                                                  </CButton>
                                             </CModalFooter>
                                        </CModal>
                                   </>
                              )}
                         </div>
                    </div>
               )}
          </>
     );
};

export default EditRuleLookupTable;
