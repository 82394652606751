import React, { useContext } from 'react';
import { CButton, CForm, CCard, CCardBody } from '@coreui/react';
import { Formik } from 'formik';

import { CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
import Waiting from '../../../../../general/Loadings/Waiting';
import useListenerSubmit from './useListenerSubmit';
import useListenerBackBtnClicked from './useListenerBackBtnClicked';
import useSaveListenerProgress from './useSaveListenerProgress';
import CLabel from '../../../../../migration/CLabel';
import CFormGroup from '../../../../../migration/CFormGroup';
import { CInputCheckbox } from '../../../../../migration/CInput';

const WeatherListener = () => {
    const { stepsData, setStepsData } = useContext(CreateAccountContext);
    const { listeners, selectedListenerTopics, allSelectedListeners, selectedWeatherFeatures, totalConfigureMeasurementSteps } = stepsData;
    const weatherListener = listeners.find(listener => listener.code === 'userWeather');
    const currentListenerIndex = selectedListenerTopics.findIndex(topic => topic === STEPS.weatherListener);
    const listenerType = 'weather';
    const handleListenerSubmit = useListenerSubmit(listenerType, currentListenerIndex);
    const handleBackBtnClicked = useListenerBackBtnClicked(listenerType, currentListenerIndex);
    useSaveListenerProgress();

    const getDefaultChecked = (value) => {
        return Array.isArray(selectedWeatherFeatures) ? !!selectedWeatherFeatures.find(el => el === value) : false;
    };

    let weatherOptions = [
        {
            value: 'currentWeather',
            label: 'User’s Current Weather',
        },
        {
            value: 'recentWeather',
            label: 'User’s Recent Weather',
        },
        {
            value: 'forecastWeather',
            label: 'User’s Forecast Weather',
        },
    ];

    weatherOptions = weatherOptions.map(option => ({ ...option, defaultChecked: getDefaultChecked(option.value) }));

    const onBackBtnClicked = (types) => {
        const newAllSelectedListeners = { ...allSelectedListeners, weather: [weatherListener] };
        setStepsData({ allSelectedListeners: newAllSelectedListeners, selectedWeatherFeatures: types });
        handleBackBtnClicked();
    };

    const onSubmit = ({ types }) => {
        const newAllSelectedListeners = { ...allSelectedListeners, weather: [weatherListener] };
        setStepsData({ allSelectedListeners: newAllSelectedListeners, selectedWeatherFeatures: types });

        handleListenerSubmit();
    };

    return (
        <Formik
            initialValues={{ types: selectedWeatherFeatures || [] }}
            onSubmit={onSubmit}
        >
            {({ values, handleSubmit, setFieldValue, isSubmitting }) => {
                const handleSelect = (type) => {
                    let newTypes = [...values.types];
                    const foundTypeIndex = values.types.indexOf(type);

                    if (foundTypeIndex > -1) {
                        newTypes.splice(foundTypeIndex, 1);
                    } else {
                        newTypes.push(type);
                    }

                    setFieldValue('types', newTypes);
                };

                return (
                    <CreateAccountBody onBackBtnClicked={() => onBackBtnClicked(values.types)} backBtnDisabled={isSubmitting}>
                        <CCard className="account-configure-measurement weather">
                            {totalConfigureMeasurementSteps > 1 &&
                                (
                                    <p className="number-of-step">
                                        {currentListenerIndex} of {totalConfigureMeasurementSteps} steps
                                    </p>
                                )
                            }
                            <CCardBody>
                                <CForm onSubmit={handleSubmit}>
                                    <CFormGroup>
                                        <h3>Configure the Weather Listener</h3>
                                        <p className="description">Select the type of weather information you would like to receive in your data.</p>
                                        <div className="list-measurement">
                                            {weatherOptions.map((weatherOption) => (
                                                <CLabel className="measurement" key={weatherOption.value} htmlFor={`_${weatherOption.value}`}>
                                                    <div className="mb-0 mr-3 label">
                                                        {weatherOption.label}
                                                    </div>
                                                    <CInputCheckbox
                                                        id={`_${weatherOption.value}`}
                                                        defaultChecked={weatherOption.defaultChecked}
                                                        onChange={() => handleSelect(weatherOption.value)}
                                                        disabled={isSubmitting}
                                                    />
                                                </CLabel>
                                            ))}
                                        </div>
                                        <CButton className="btn-save" type="submit" disabled={values.types.length === 0 || isSubmitting}>
                                            <Waiting isLoading={isSubmitting}>NEXT</Waiting>
                                        </CButton>
                                    </CFormGroup>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </CreateAccountBody>
                );
            }}
        </Formik>
    );
};

export default WeatherListener;
