import React from 'react'

const DestinationsLoading = () => {
     return (
          <div className="cardLoading">
               <div className="cardLoading__item">
                    <div className="cardLoading__animated">
                         <div className="item"></div>
                         <div className="item-padding"></div>
                         <div className="item-padding2"></div>
                    </div>
               </div>
          </div>
     )
}

export default DestinationsLoading;
