import React from 'react';
import PropTypes from 'prop-types';
import { CSelect } from '../../migration/CInput';

const DropdownFilter = ({ filterData, onChange, options }) => {
     const { key, value } = filterData;

     return (
          <>
               <CSelect
                    name={key}
                    onChange={e => onChange(e.target.name, e.target.value)}
                    value={value}
               >
                    {
                         options && options.map(option => <option key={option.value} className="text-dark" value={option.value}>{option.text}</option>)
                    }
               </CSelect>
          </>
     )
}

DropdownFilter.propTypes = {
     filterData: PropTypes.object,
     onChange: PropTypes.func,
     options: PropTypes.array
}

export default DropdownFilter
