import React from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { CCard, CRow, CCardBody, CButton, CForm, CCardTitle, CCol } from '@coreui/react';
import CIcon from '@coreui/icons-react';
// import { useDidMount } from '../../helpers/customHooks';
import { getTokenCookie, toastError } from '../../utils';
import { callApi } from '../../apiCaller';
import { signout } from '../../helpers/auth';
import { CInput } from '../../components/migration/CInput';
import { CInvalidFeedback } from '../../components/migration/CInvalidFeedback';
import CLabel from '../../components/migration/CLabel';
import CFormGroup from '../../components/migration/CFormGroup';

const PasswordUpdate = ({ user }) => {
     // const didMount = useDidMount();
     const token = getTokenCookie();

     // const [conditionsMet, setConditionsMet] = useState({
     //      containsLengthcase: null,
     //      containsLowercase: null,
     //      containsUppercase: null,
     //      containsSpecialCharacters: null,
     // });

     const formik = useFormik({
          initialValues: {
               password: '',
               confirmPassword: '',
          },
          validationSchema: Yup.object({
               password: Yup.string()
                    .required('Password Required')
                    .min(12, 'Password must be at least 12 characters')
                    .test('containsLowercase', 'Password must contain at least 1 lowercase letter', (value) => /[a-z]/.test(value))
                    .test('containsUppercase', 'Password must contain at least 1 uppercase letter', (value) => /[A-Z]/.test(value))
                    .test('containsSpecialCharacters', 'Password must contain at least 2 special characters (%,#,@,!,&,*, $)', (value) => {
                         const specialCharacters = /[%#@!&*$]/g;
                         const matches = value && value.match(specialCharacters);

                         return matches ? matches.length >= 2 : false;
                    }),
               confirmPassword: Yup.string()
                    .required('Confirm Password Required')
                    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
          }),
          onSubmit: (values, { setSubmitting }) => {
               const data = {
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    name: `${user.firstName} ${user.lastName}`,
                    password: values.password,
                    forceChangePassword: false,
               };

               callApi('/user/update', 'PUT', data, token)
                    .then((response) => {
                         if (response.status === 200) {
                              toast.success('Password updated successfully');
                              signout();
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => setSubmitting(false));
          },
     });

     // const keyNameCondition = {
     //      containsLengthcase: 'Password must be at least 12 characters',
     //      containsLowercase: 'Password must contain at least 1 lowercase letter',
     //      containsUppercase: 'Password must contain at least 1 uppercase letter',
     //      containsSpecialCharacters: 'Password must contain at least 2 special characters (%,#,@,!,&,*, $)',
     // };

     // useEffect(() => {
     //      const updateConditionsMet = () => {
     //           const { password } = formik.values;

     //           const checkSpecialCharacters = (password) => {
     //                const specialCharacters = /[%#@!&*$]/g;
     //                const matches = password && password.match(specialCharacters);

     //                return matches ? matches.length >= 2 : false;
     //           };

     //           setConditionsMet({
     //                containsLengthcase: password.length >= 12 ? true : false,
     //                containsLowercase: /[a-z]/.test(password),
     //                containsUppercase: /[A-Z]/.test(password),
     //                containsSpecialCharacters: checkSpecialCharacters(password),
     //           });
     //      };

     //      if (didMount) {
     //           updateConditionsMet();
     //      }
     // }, [formik.values.password]); //eslint-disable-line

     const handleBackToLogin = () => {
          signout();
     };

     return (
          <div className="passwordUpdate-wrapper">
               <CRow className="container">
                    <div className="passwordUpdate-header">
                         <div className="link-back-login" onClick={handleBackToLogin}>
                              <i className="fal fa-angle-left"></i>
                              <span>Back to Login</span>
                         </div>
                    </div>
                    <CCard className="passwordUpdate-card">
                         <CCardBody>
                              <CForm onSubmit={formik.handleSubmit} noValidate>
                                   <div className="hero-logo passwordUpdate-logo">
                                        <CIcon icon="logo-dark" height={23} />
                                   </div>
                                   <CCardTitle className="passwordUpdate-title">Password Update</CCardTitle>
                                   <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <p className="passwordUpdate-description">
                                             Keep your account secure by changing your password. We recommend using a unique and strong password to
                                             protect your personal information.
                                        </p>
                                   </div>
                                   <CRow>
                                        <CCol sm="12">
                                             <CFormGroup>
                                                  <CLabel htmlFor="password">Password</CLabel>
                                                  <CInput
                                                       type="password"
                                                       id="password"
                                                       placeholder="Password"
                                                       value={formik.values.password}
                                                       onChange={formik.handleChange}
                                                       onBlur={formik.handleBlur}
                                                       disabled={formik.isSubmitting ? true : false}
                                                  />
                                                  <CInvalidFeedback>
                                                       {formik.touched.password && formik.errors.password ? (
                                                            <p className="error">{formik.errors.password}</p>
                                                       ) : null}
                                                  </CInvalidFeedback>
                                             </CFormGroup>
                                        </CCol>
                                   </CRow>
                                   {
                                        <p style={{ color: 'black', fontSize: '12px' }}>
                                             Create a strong password with a mix of letters, numbers and symbols
                                        </p>
                                   }
                                   {/* {Object.entries(conditionsMet).map(([key, conditionMet]) => (
                                   <p
                                        key={key}
                                        className="passwordUpdate-conditionsMet"
                                        style={{ color: conditionMet === null ? '#afb5c0' : conditionMet ? '#1fd507' : 'red' }}
                                   >
                                        <CIcon
                                             icon="iconFaCircleCheck"
                                             width={15}
                                             height={15}
                                             style={{ color: conditionMet ? '#1fd507' : '#afb5c0' }}
                                        />
                                        {formik.errors.password && formik.errors.password.includes(key)
                                             ? formik.errors.password
                                             : keyNameCondition[key]}
                                   </p>
                              ))} */}
                                   <CRow>
                                        <CCol sm="12">
                                             <CFormGroup>
                                                  <CLabel htmlFor="confirmPassword">Confirm Password</CLabel>
                                                  <CInput
                                                       type="password"
                                                       id="confirmPassword"
                                                       placeholder="Confirm Password"
                                                       value={formik.values.confirmPassword}
                                                       onChange={formik.handleChange}
                                                       onBlur={formik.handleBlur}
                                                       disabled={formik.isSubmitting ? true : false}
                                                  />

                                                  {formik.touched.password && formik.errors.password ? (
                                                       <p className="passwordUpdate-error">{formik.errors.password}</p>
                                                  ) : formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                                       <p className="passwordUpdate-error">{formik.errors.confirmPassword}</p>
                                                  ) : null}
                                             </CFormGroup>
                                        </CCol>
                                   </CRow>

                                   <CButton type="submit" className="btn-primary passwordUpdate-submit">
                                        {formik.isSubmitting ? (
                                             <>
                                                  Waiting<span className="dots-waiting"></span>
                                             </>
                                        ) : (
                                             'Submit'
                                        )}
                                   </CButton>
                              </CForm>
                         </CCardBody>
                    </CCard>
               </CRow>
          </div>
     );
};

export default PasswordUpdate;
