import React from 'react';
import CIcon from '@coreui/icons-react';
import { useSelector } from 'react-redux';

import {
     Dropdown,
     DropdownMenu,
     DropdownToggle,
} from '../../../components/general/dropdown';
import { CButton } from '@coreui/react';
import { TOURSTEPS } from '../TheTour';

const PreviewPublishPopup = ({ togglePublishModal }) => {
     const { showTour, stepTour } = useSelector(state => state.subscriber.tour);

     return (
          <Dropdown
               isHeaderDropdown
               inNav
               className="header-nav-items publish-nav-item"
               direction="down"
          >
               <DropdownToggle
                    id="togglePopupPublish"
                    className="header-nav-link"
                    caret={false}
               >
                    <CButton className={`btn-datalayer${showTour && stepTour === TOURSTEPS.tourOver.orderNumber ? ' highlight-item' : ''}`}>
                         <CIcon icon="icon-expand" className="mr-2 p-0" /><span>TEST MY DATA LAYER</span>
                    </CButton>
               </DropdownToggle>
               <DropdownMenu
                    className="pt-0 setting-dropdown preview-dropdown"
                    placement="bottom-end"
               >
                    <CIcon icon="arrow-account" className="arrow-account arrow-setting" />
                    <div className='content'>
                         <h4>Publish Your Changes</h4>
                         <p>Your changes must be published before you can test your datalayer.</p>
                         <CButton className="btn-brand" color="primary" onClick={togglePublishModal}><span>PUBLISH Now</span><CIcon icon="cil-send" className="ml-2" /></CButton>
                    </div>
               </DropdownMenu>
          </Dropdown>
     )
}

export default React.memo(PreviewPublishPopup)
