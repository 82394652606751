import { useContext } from "react";

import { CHANGE_STEP_TYPE, CreateAccountContext, STEPS } from "../CreateAccount";

const useListenerSubmit = (listenerType, currentListenerIndex) => {
     const { stepsData, setStepsData, setCurrentStep, updateAccountCompletedSteps } = useContext(CreateAccountContext);
     const { selectedListenerTopics, allSelectedListeners } = stepsData;

     const handleListenerSubmit = (selectedListeners) => {
          if (listenerType !== 'weather') {
               const newAllSelectedListeners = { ...allSelectedListeners, [listenerType]: selectedListeners };
               setStepsData({ allSelectedListeners: newAllSelectedListeners });
          }

          if (currentListenerIndex === selectedListenerTopics.length - 1) {
               setCurrentStep(STEPS.chooseDestination, CHANGE_STEP_TYPE.subStepNext);
               updateAccountCompletedSteps(STEPS.configureMeasurement);
               // setCurrentStep(STEPS.accountPackage, CHANGE_STEP_TYPE.subStepNext);
               // setCurrentStep(STEPS.configuringAccount, CHANGE_STEP_TYPE.subStepNext);
               // select Destinations
          } else {
               setCurrentStep(selectedListenerTopics[currentListenerIndex + 1], CHANGE_STEP_TYPE.subStepNext);
          }
     };

     return handleListenerSubmit;
}

export default useListenerSubmit;