import React, { useState } from 'react';
import CIcon from '@coreui/icons-react';
import { CButton, CCard, CCardBody, CForm,  CModal, CSpinner } from '@coreui/react';
import { Formik } from 'formik';
import Waiting from '../../../components/general/Loadings/Waiting';
import { useDispatch, useSelector } from 'react-redux';
import { useScrollFaded } from '../../../helpers/customHooks';
import { getDestinationRoutes, getEnabledListeners, getListenerColorIcon } from '../../../helpers/cms/subscriber';
import { setChildSetupGuideDataCompleted, setSetupGuideRoadmap, setShowBlockAccountPopup, setShowSetupGuidePopup, setSubscriberState, setTour } from '../../../actions/subscriber';
import { API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, API_CLIENT_ACCOUNT_MULTI_DESTINATION } from '../../../constants';
import { callTokenApi } from '../../../apiCaller';
import { toast } from 'react-toastify';
import { toastError } from '../../../utils';
import { CInput } from '../../../components/migration/CInput';
import CFormGroup from '../../../components/migration/CFormGroup';

const ChooseDestination = ({ showDestination, setShowDestination }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountDestinations = useSelector((state) => state.subscriber.accountDestinations);
     const accountRoutes = useSelector((state) => state.subscriber.routes);
     const initialValues = {
          selectedDestinations: [],
     };

     const [listDesId, setListDesId] = useState([]);
     const [searchText, setSearchText] = useState('');
     const [isLoading, setIsLoading] = useState(false);

     const comingSoonDestinationAlias = ['zapier', 'slack', 's3', 'google-tag-manager'];
     let listDestinations = accountDestinations ? accountDestinations.filter((item) => !comingSoonDestinationAlias.includes(item.alias) && !item.configStatus) : [];

     let destinationsToDisplay = listDestinations.length > 0 && listDestinations.filter((destination) => typeof destination.name !== 'undefined' && destination.name.toLowerCase().includes(searchText.toLowerCase()));
     const { containerListClass, onScrollContainerList } = useScrollFaded({ hasScroll: destinationsToDisplay.length > 9 });
     const checkIfChecked = (selectedDestinations, destination) => {
          return !!selectedDestinations.find((selectedDestinations) => selectedDestinations.id === destination.id);
     };

     const handleGoToRoadMap = () => {
          const newData = {
               accountId: activeAccount.id,
               step: 'sendingData',
               data: {
                    learnAboutDestinations: true,
               },
          };
          callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_CHILD_SETUP_GUIDE, 'PUT', newData).then((response) => {
               if (response.status === 200) {
                    dispatch(
                         setChildSetupGuideDataCompleted({
                              key: 'sendingData',
                              data: {
                                   learnAboutDestinations: true,
                              },
                         })
                    );
               } else {
                    toastError(response);
               }
          });
          dispatch(setSetupGuideRoadmap(5));
          dispatch(setShowSetupGuidePopup(true));
          dispatch(setTour({ showTour: false }));
     };

     const onSubmit = () => {
          setIsLoading(true);

          const status = true;

          const data = {
               accountId: activeAccount.id,
               destinationId: listDesId,
               status,
          };

          callTokenApi(`${API_CLIENT_ACCOUNT_MULTI_DESTINATION}update`, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         const { accountDestination } = response.data;
                         for (let i = 0; i < accountDestination.length; i++) {
                              let newDestination = {};
                              let destinationIndex = 0;
                              accountDestinations.some((destination, index) => {
                                   if (accountDestination[i].destinationId === destination.id) {
                                        destinationIndex = index;
                                        newDestination = destination;
                                        newDestination.configStatus = !newDestination.configStatus;
                                        newDestination.accountDestinationId = accountDestination[i].id;

                                        return true;
                                   }

                                   return false;
                              });
                              let newAccountDestination = [...accountDestinations];
                              newAccountDestination[destinationIndex] = newDestination;

                              const enabledDestinations = getEnabledListeners(newAccountDestination);
                              const destinationRoutes = getDestinationRoutes(enabledDestinations, activeAccount.secondId);
                              const newAccountRoutes = [...accountRoutes].filter((route) => !destinationRoutes.some((destinationRoute) => destinationRoute.path === route.path));
                              const newRoutes = [...newAccountRoutes, ...destinationRoutes];

                              dispatch(
                                   setSubscriberState({
                                        routes: newRoutes,
                                        accountDestinations: newAccountDestination,
                                        activeDestinations: enabledDestinations,
                                   })
                              );

                              if (newDestination.configStatus === true) {
                                   dispatch(
                                        setSubscriberState({
                                             activeDestination: newDestination,
                                        })
                                   );
                              }

                              // High light destination nav in sidebar when turn on
                              dispatch(
                                   setSubscriberState({
                                        elementJustActivated: {
                                             id: newDestination.id,
                                             type: newDestination.type,
                                        },
                                   })
                              );
                              setTimeout(() => {
                                   dispatch(
                                        setSubscriberState({
                                             elementJustActivated: null,
                                        })
                                   );
                              }, 500);
                         }

                         setShowDestination(false);
                         // const nextStepTour = TOURSTEPS.mainMenuMonitoring.orderNumber;
                         // dispatch(setTour({ showTour: true, stepTour: TOURSTEPS.endDestinationWizard.orderNumber }));
                         // history.push(SUBSCRIBER_PATH.MONITORING_DASHBOARD);
                         // dispatch(setNewLayout({ activeMainNavItem: subscriberNavKey.MONITORING, forceShowNavChild: true }));
                         // cookie.set(`stepTour${activeAccount.id}`, nextStepTour, { expires: 365 });
                         // dispatch(setTour({ showTour: true, stepTour: nextStepTour }));
                         handleGoToRoadMap()
                         toast.success(response.data.message);
                    } else {
                         if (response.data.accountBlocked) {
                              dispatch(setShowBlockAccountPopup(true));
                         } else {
                              toastError(response);
                         }
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     };

     return (
          <CModal backdrop="static" visible={showDestination} alignment="center" portal={false} onClose={() => setShowDestination(false)}>
               <Formik initialValues={initialValues} onSubmit={onSubmit}>
                    {({ values, handleSubmit, setFieldValue, isSubmitting }) => {
                         const handleSelect = (destination) => {
                              let newSelectedDestinations = [...values.selectedDestinations];
                              const foundDestinationIndex = values.selectedDestinations.findIndex((selectedDestination) => selectedDestination.id === destination.id);

                              if (foundDestinationIndex > -1) {
                                   newSelectedDestinations.splice(foundDestinationIndex, 1);
                              } else {
                                   newSelectedDestinations.push(destination);
                              }

                              const destinationIds = newSelectedDestinations.map((item) => {
                                   return item.id;
                              });
                              setListDesId(destinationIds);
                              setFieldValue('selectedDestinations', newSelectedDestinations);
                         };

                         return (
                              <div className='create-account-popup tour-destination'>
                                   <CCard className='account-form'>
                                        <CCardBody>
                                             <CForm onSubmit={handleSubmit}>
                                                  <CFormGroup>
                                                       <h3>Choose Your Destinations</h3>
                                                       <p>Select the destinations you're interested in seeding data to. We'll add them to your implementation road map</p>
                                                       <div className='filter-search'>
                                                            <CInput name='search' className='search' type='text' placeholder='Enter destination name to filter' value={searchText} onChange={(e) => setSearchText(e.target.value)} data-lpignore='true' />
                                                            <CIcon icon='iconFilterSearch' height={16} />
                                                       </div>
                                                       <div className={`forms-listener `}>
                                                            <div className={`list-form ${containerListClass}`} onScroll={onScrollContainerList}>
                                                                 {destinationsToDisplay.length > 0 ? (
                                                                      destinationsToDisplay.map((destination) => {
                                                                           const iconSrc = getListenerColorIcon(destination);
                                                                           return (
                                                                                <label key={destination.id}>
                                                                                     <input type='checkbox' checked={checkIfChecked(values.selectedDestinations, destination)} onChange={() => handleSelect(destination)} disabled={isSubmitting} />
                                                                                     <div className='icon-box'>
                                                                                          <div className='tickmark'></div>
                                                                                          <img className='listener-logo w-auto' src={iconSrc} alt={`${destination.name} Icon`} />
                                                                                          <span>{destination.name}</span>
                                                                                     </div>
                                                                                </label>
                                                                           );
                                                                      })
                                                                 ) : isLoading ? (
                                                                      <CSpinner color='primary' />
                                                                 ) : (
                                                                      <p className='item-not-found'>No items found.</p>
                                                                 )}
                                                            </div>
                                                       </div>
                                                       <CButton className='btn-save' type='submit' disabled={isSubmitting || listDesId.length < 1}>
                                                            <Waiting isLoading={isSubmitting}>NEXT</Waiting>
                                                       </CButton>
                                                  </CFormGroup>
                                             </CForm>
                                        </CCardBody>
                                   </CCard>
                              </div>
                         );
                    }}
               </Formik>
          </CModal>
     );
};

export default ChooseDestination;
