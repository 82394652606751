import React, { useContext, useState } from 'react';
import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from './CreateAccount';
import { CButton, CCard, CCardBody, CForm } from '@coreui/react';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ACCOUNT_QUESTION_ARR, API_CLIENT_USER_INFO, DORMANT_STATUSES } from '../../../../../constants';
import { callTokenApi } from '../../../../../apiCaller';
import { toastError } from '../../../../../utils';
import { setUser } from '../../../../../actions/common';
import { setShowAccountDormantPopup, setShowCreateAccountPopup } from '../../../../../actions/subscriber';
import { urlParams } from '../../../../../helpers/cms/subscriber';
import CFormGroup from '../../../../migration/CFormGroup';

const UserWork = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const user = useSelector(state => state.theme.user);
	const { stepsData, setStepsData, setCurrentStep, AnsweredAccountInfo } = useContext(CreateAccountContext);
	const accounts = useSelector((state) => state.subscriber.accounts);
	const dormantAccount = useSelector((state) => state.subscriber.dormantAccount);
	const dataUser = (user && (user.registerUserInfo && JSON.parse(user.registerUserInfo))) || JSON.parse(stepsData.dataUser)

	const [isLoading, setIsLoading] = useState(false);
	const options = [
		'for an Agency',
		'In-house',
	];
	const defaultPackageIdParam = urlParams('defaultPackageId');
	const navigationLandingPage = urlParams('navigate');
	const _pathName = window.location.pathname.replace('/', '');

	const _arrSlug = [
		'masterclass',
		'certification',
		'office-hours',
		'become-a-partner',
		'support-tickets',
		'profile',
		'email-notifications',
		'payment-methods',
	];

	const activeAccounts = accounts ? accounts.filter(account => !DORMANT_STATUSES.includes(account.platformAccountStatus)) : [];
	const showBackBtn = (activeAccounts.length > 0 || dormantAccount || (user && user.registerMasterClass) || !!_arrSlug.find(slug => _pathName.includes(slug)));

	const onBackBtnClicked = () => {
		if (showBackBtn) {
			if (dormantAccount) {
				dispatch(setShowAccountDormantPopup(true));
			}
			dispatch(setShowCreateAccountPopup(false));
			if (defaultPackageIdParam) {
				history.push('/');
			}
		}
		else {
			if (navigationLandingPage) {
				history.goBack()
			}
			else {
				dispatch(setShowCreateAccountPopup(false));
				history.push('/login');
			}
		}

	};

	const goToNextStep = () => {
		setCurrentStep(null, CHANGE_STEP_TYPE.mainStepNext);
	};

	const saveData = (dataUser) => {
		setIsLoading(true);
		callTokenApi(API_CLIENT_USER_INFO, 'PUT', { registerUserInfo: dataUser })
			.then(response => {
				if (response.status === 200) {
					setStepsData({ ...stepsData, dataUser: JSON.stringify(dataUser) })
					dispatch(setUser({ ...user, registerUserInfo: JSON.stringify(dataUser) }));
					goToNextStep();
				} else {
					setIsLoading(false);
					toastError(response);
				}
			})
	};
	const onSubmit = (e) => {
		const value = e.target.textContent;
		if (user) {
			if (dataUser.work) {
				if (dataUser.work === value) {
					goToNextStep();
				} else {
					dataUser.work = value;
					saveData(dataUser);
				}
			} else {
				dataUser.work = value;
				saveData(dataUser);
			}
		} else {
			const dataUser = JSON.parse(stepsData.dataUser)
			dataUser.work = value
			setStepsData({ ...stepsData, dataUser: JSON.stringify(dataUser) });
			setCurrentStep(STEPS.userSizeCompany)
		}
	};

	return (
		<CreateAccountBody hideStepProgress={true} onBackBtnClicked={onBackBtnClicked}>
			<CCard className="account-brings">
				<AnsweredAccountInfo step={STEPS.userWork} arr={ACCOUNT_QUESTION_ARR} />
				<CCardBody>
					<CForm>
						{
							isLoading ? (
								<CenterSpinner />
							) : (
								<CFormGroup>
									<h3>Welcome to ListenLayer</h3>
									<p>Provide some information to start down the right path</p>
									<p>I work...</p>
									<div className="list-bring">
										{
											options.map((option) => {
												return (
													<CButton key={option} className="btn-select" onClick={onSubmit}>
														{option}
													</CButton>
												);
											})
										}
									</div>
								</CFormGroup>
							)
						}
					</CForm>
				</CCardBody>
			</CCard>
		</CreateAccountBody>
	)
}

export default UserWork;