import React, { useContext } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from '../../../general/dropdown';
import { EventExplorerContext, handleGetOption, handleGetSortSegment } from './EventExplorer';
import { REPORT_DATA_TYPES, VARIABLE_OPERATORS_REPORT} from '../../../../constants/analytics';
import { size, isEqual } from 'lodash';
import { BQ_JOB_STATUS } from '../../../../constants';
// import { CIcon } from '@coreui/icons-react';

function ScopeDropdown() {
     const { 
          scoped,
          reportName, 
          filterMetric,
          reportType, 
          showData, 
          enableRevealedCompanies, 
          isChangeScope, 
          listSegmentReport, 
          listSegmentReportApply, 
          setSort,
          setScoped,
          setFilterEvent, 
          setFilterMetric, 
          setFilterDimensions, 
          setFilterProperties,
          disableSort,
          setIsHandleParams
      } = useContext(EventExplorerContext);
     const defaultRow = [
          {
               type: '',
               value: '',
               id: '',
               operator: 'ct',
               optionsOperator: VARIABLE_OPERATORS_REPORT,
          },
     ];
     const companyText = enableRevealedCompanies ? '' : 'Company'
     const { dimensions, properties, metrics, isDefaultMetrics } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];
     const handleScoped = (value) => {
          if (!showData) {
               return
          }
          const { dimensions: dimensionsNew, properties: propertiesNew, metrics: metricsNew, defaultDimensions } = REPORT_DATA_TYPES[reportName][value];
          if (!isEqual(dimensions, dimensionsNew)) {
               setFilterDimensions(handleGetOption({ option: dimensionsNew, type: 'defaultDimension', reportName: reportName, defaultArr: defaultDimensions }));
          }
          if (!isEqual(properties, propertiesNew)) {
               setFilterProperties(handleGetOption({ option: propertiesNew }));
          }
          if (!isEqual(metrics, metricsNew)) {
               const defaultMetrics = metricsNew.filter((item) => item.isDefault);
               setFilterMetric(handleGetOption({ option: defaultMetrics, isDefaultMetrics, type: 'defaultMetric' }));
          }
          if(scoped !== value) {
               if(listSegmentReportApply && listSegmentReportApply.length > 0) {
                    setSort(
                         handleGetSortSegment({
                              listSegmentReportApply: listSegmentReport.filter(
                                   (item) => item.apply && item.queryStatus === BQ_JOB_STATUS.DONE
                              ),
                              filterMetric: metricsNew,
                         })
                    );
               } else 
               if(filterMetric[0] && !disableSort){
                    const defaultMetrics = metricsNew.filter((item) => item.isDefault);
                    setSort({
                         fieldName: defaultMetrics[0].key,
                         isDesc: true,
                    });
               }
          }
          setScoped(value);
          isChangeScope.current = true
          setFilterEvent(defaultRow);
          setIsHandleParams(false);
          
     };

     const isSingleReportType = size(REPORT_DATA_TYPES[reportName]) < 2;

     return (
          <div className={`scope-dropdown`}>
               <span>Scope: {isSingleReportType && <span style={{ textTransform: 'capitalize', fontWeight: 500, paddingLeft: '12px' }}>{scoped}</span>}</span>
               {size(REPORT_DATA_TYPES[reportName]) > 1 && (
                    <Dropdown>
                         <DropdownToggle>
                              <span style={{ textTransform: 'capitalize' }}>{scoped}</span>
                              {/* <CIcon icon="iconInformation" style={{ transform: 'translateY(-2px) scale(1.2)', margin: '0 4px' }} /> */}
                         </DropdownToggle>
                         <DropdownMenu className='top-7px'>
                              {Object.keys(REPORT_DATA_TYPES[reportName]).filter((item) => item !== companyText).map((value, key) => (
                                   <div key={key} onClick={() => handleScoped(value)}>
                                        <DropdownItem>{value}</DropdownItem>
                                   </div>
                              ))}
                         </DropdownMenu>
                    </Dropdown>
               )}
          </div>
     );
}

export default ScopeDropdown;
