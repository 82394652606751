import React, { useEffect } from 'react';
import { CModal, CModalBody, CModalHeader } from '@coreui/react';
import PropTypes from 'prop-types';

const Popup = ({ show, onClose, children, showHeader, className }) => {
    useEffect(() => {
        var elementHtml = document.getElementsByTagName('html')[0];
        if (show) {
            elementHtml.classList.add('no-scroll')
        } else {
            elementHtml.classList.remove('no-scroll')
        }

        return () => elementHtml.classList.remove('no-scroll');
    }, [show])
    return (
        <div className={className ? className : ''}>
            <CModal visible={show} onClose={onClose} alignment="center" portal={false}>
                {showHeader && <CModalHeader closeButton />}
                <CModalBody style={{ height: 'fit-content' }}>{children}</CModalBody>
            </CModal>
        </div>
    );
}

Popup.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    className: PropTypes.string
}

export default Popup
