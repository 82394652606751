// import { CButton, CModal, CModalBody, CModalFooter } from '@coreui/react';
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import { CLIENT_GET_ACCOUNT_GROUP, COMPONENT_NAME, TYPE_SHOW_UNSAVE_CHANGE} from '../../../../../../constants';
import { callTokenApi } from '../../../../../../apiCaller';
import { toastError } from '../../../../../../utils';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { TARGET_ACCOUNT_GROUP_TYPE } from '../constants';
import { setRuleMultiHaveEditing } from '../../../../../../actions/common';
import { useDispatch } from 'react-redux';
// import { setFlexibleModal, setRuleHaveEditting } from '../../../../../actions/common';

const Step1 = React.lazy(() => import('./Step1'));
const SimpleRuleStep2 = React.lazy(() => import('./simple/CreateStep2'));
const Step3Dynamic = React.lazy(() => import('./simple/Step3Dynamic'));
const Step3List = React.lazy(() => import('./simple/Step3List'));
const Step4Dynamic = React.lazy(() => import('./simple/Step4Dynamic'));

export const RuleContext = React.createContext({});

const DEFAULT_DATA = {
     name: '',
     description: '',
     conditions: [
          {
               conjunction: '',
               g0: {
                    type: 'Variable',
                    key: '',
                    operator: '',
                    value: '',
                    conjunction: '',
               },
          },
     ]
};

const AccountGroupDetail = ({ id }) => {
     // const [modal, setModal] = useState(false);

     // const [rule, setRule] = useState();
     // const [modal, setModal] = useState(false);
     const [ruleType, setRuleType] = useState('');
     const [activeStep, setActiveStep] = useState(id ? 2 : 1);
     const [isLoading, setIsLoading] = useState(false);
     // const editingRuleReload = useSelector(state => state.subscriber.editingRuleReload);
     const [stepsData, setStepsData] = useState(cloneDeep(DEFAULT_DATA));
     const [initialStepData, setInitStepsData] = useState(cloneDeep(DEFAULT_DATA));
     const dispatch = useDispatch();

     const fetchRule = () => {
          if (!id) return;

          setIsLoading(true);

          callTokenApi(`${CLIENT_GET_ACCOUNT_GROUP}${id}`, 'GET')
               .then(response => {
                    if (response.status === 200) {
                         const rule = response.data.data;
                         setRuleType(rule.type);
                         setStepsData(rule);
                         setInitStepsData(rule);
                    } else {
                         toastError(response)
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });

     }
     useEffect(fetchRule, []); // eslint-disable-line

     const handleHasChange = () => {
          dispatch(setRuleMultiHaveEditing({ show: true, type: TYPE_SHOW_UNSAVE_CHANGE.CREATE_SIMPLE, component:  COMPONENT_NAME.TARGET_ACCOUNTS_GROUPS}))
     }

     return (
          <div className='rule-detail ta-edit-groups'>
               <React.Suspense fallback={<CenterSpinner />}>
                    <div className='custom-variable-rules'>
                         {isLoading ? (
                              <CenterSpinner />
                         ) : (
                              <div className='cvr-create-new custom-rule'>
                                   <h1>{id ? `Edit Account Group: ${stepsData.name}` : `Create an Account Group`}</h1>
                                   <p>{id ? 'Use the steps below to configure your Account Group.' : 'Complete the steps to create a new Account Group. '} </p>

                                   <RuleContext.Provider value={{ activeStep, setActiveStep, ruleType, setRuleType, stepsData, setStepsData, handleHasChange, initialStepData }}>
                                        <Step1 activeStep={activeStep} setActiveStep={setActiveStep} ruleType={ruleType} setRuleType={setRuleType} />
                                        {ruleType && (
                                             <>
                                                  <SimpleRuleStep2 />
                                                  {ruleType === TARGET_ACCOUNT_GROUP_TYPE.LIST_OF_ACCOUNTS && (
                                                       <Step3List />
                                                  )}
                                                  {ruleType === TARGET_ACCOUNT_GROUP_TYPE.DYNAMIC_LIST && (
                                                       <>
                                                            <Step3Dynamic />
                                                            <Step4Dynamic />
                                                       </>
                                                  )}
                                             </>
                                        )}

                                   </RuleContext.Provider>
                              </div>
                         )}
                    </div>
               </React.Suspense>
          </div>

     );
};

export default AccountGroupDetail;
