import React from 'react';
import { useSelector } from 'react-redux';
import { CRow, CCol, CButton } from '@coreui/react';
import FullListTable from './table';
import { COMPONENT_NAME } from '../../../../../../constants';
import { setFlexibleMultiModal } from '../../../../../../actions/common';
import { useDispatch } from 'react-redux';

function TargetAccountEditFullList({ groupId }) {
     const dispatch = useDispatch();
     const targetAccountGroups = useSelector((state) => state.subscriber.targetAccountGroups) || [];

     const onClickFindAccount = () => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    component: COMPONENT_NAME.TARGET_ACCOUNTS_FIND_ACCOUNT,
               })
          );
     };

     const activeGroup = targetAccountGroups.length > 0 ? targetAccountGroups.find((group) => group.id === groupId) : null;
     const isBOGroup = !!activeGroup && activeGroup.isDefault;

     const title = groupId ? 'Included Accounts' : 'Full Target Accounts List';
     const description = groupId
          ? 'Add and remove Accounts from this Group using the table below. You can select Accounts from your Full Target Account List or add new Accounts (which will then be included in your Full Account List). '
          : 'Manage your full list of Target Accounts below. Manually add new rows or bulk import updates. Accounts will be processed against your Group Rules and will be updated with the correct Group tags after a few minutes.';

     return (
          <>
               <div className="form-edit-lookup trigger-rule ta-full-list">
                    <CRow>
                         <CCol md="12" className="form-edit-lookup-row">
                              <div className="form-edit-lookup-title trigger-rule">
                                   <h1 title="full-target-account-list">{title}</h1>
                              </div>

                              {!activeGroup && (
                                   <div className="form-edit-lookup-button text-right">
                                        <CButton color="success" className="d-inline-block text-uppercase" onClick={() => onClickFindAccount()}>
                                             Find Accounts
                                        </CButton>
                                   </div>
                              )}
                         </CCol>

                         {!isBOGroup && (
                              <CCol md="12" className="form-edit-lookup-description">
                                   <p>{description}</p>
                              </CCol>
                         )}
                    </CRow>

                    <FullListTable groupId={groupId} />
               </div>
          </>
     );
}

export default TargetAccountEditFullList;
