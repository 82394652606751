import React from 'react';
import { setShowUpgradeAccountPopup, setShowUpgradeAccountSchedulePopup } from '../../../../../actions/subscriber';
import { useDispatch } from 'react-redux';

function UpgradePlanText({ tableData }) {
     const dispatch = useDispatch();
     return (
          <div className={`contentTooltipLongLoading ${tableData && tableData.length < 5 ? 'analytics-package' : ''}`}>
               <div className="upgrade-content">
                    <div className="upgrade-content-icon">
                         <img src="/assets/icons/icon-lock-analytics.png" alt="icon-lock" />
                    </div>
                    <div className="upgrade-content-desc">
                         <p>UPGRADE REQUIRED</p>
                         <span
                              className="upgrade-link"
                              onClick={() => {
                                   dispatch(setShowUpgradeAccountPopup(true));
                                   dispatch(setShowUpgradeAccountSchedulePopup(true));
                              }}
                         >
                              Schedule a demo
                         </span>
                    </div>
               </div>
          </div>
     );
}

export default UpgradePlanText;
