import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CButton, CForm } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { toast } from 'react-toastify';

import { COMPANY_BLACKLIST, DEFAULT_PAGE_SIZE, DEFAULT_TEMP_DOMAINS, MAPPING_COMPANY_DATA, REGEX_DOMAIN, TARGET_ACCOUNT_GROUP_TYPE } from '../../constants';
import RemovePopup from '../../../../../../general/popup/RemovePopup';
import { ConfirmSaveChange, LeaveComponentChangePopup } from '../../../../../../general/popup';
import { setSubscriberState, setUnsavedLookupTableModal } from '../../../../../../../actions/subscriber';
import { setFlexibleMultiModal } from '../../../../../../../actions/common';
import { extractDomain, generateId, toastError } from '../../../../../../../utils';
import {
     fetchDomainsRequest,
     fetchTargetAccountsRequest,
     updateTargetAccountsRequest,
     deleteTargetAccountGroupRequest,
     deleteTargetAccountRequest
} from '../../actions';

import TableHeaderRow from './TableHeaderRow';
import TableBodyRows from './TableBodyRows';
import TablePagination from './TablePagination';
import TableImportExport from './TableImportExport';
import { CLIENT_GET_ACCOUNT_GROUPS, COMPONENT_NAME } from '../../../../../../../constants';
import { callApiCheckDomainCompany, callTokenApi } from '../../../../../../../apiCaller';
import { LoadingPanel } from '../../../../analytics/report-v2/LoadingTemplate';
import CLabel from '../../../../../../migration/CLabel';
import CFormGroup from '../../../../../../migration/CFormGroup';
import { CInputCheckbox } from '../../../../../../migration/CInput';
import TableSelectSearchV2 from './TableSelectSearchV2';

export const FullListTableContext = React.createContext({});

const FullListTable = ({ groupId }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const targetAccountGroups = useSelector((state) => state.subscriber.targetAccountGroups);
     const unsavedLookupTableModal = useSelector((state) => state.subscriber.unsavedLookupTableModal);
     const targetAccountsReLoad = useSelector((state) => state.subscriber.targetAccountsReLoad);
     const flexibleMultiModal = useSelector((state) => state.theme.flexibleMultiModal) || [];
     const targetAccountsList = useSelector((state) => state.subscriber.targetAccountsList);
     const targetAccountsPusher = useSelector((state) => state.subscriber.targetAccountsPusher);
     const targetAccountsDomain = useSelector((state) => state.subscriber.targetAccountsDomain);
     const [reloadData, setReloadData] = useState(false)

     const accountId = activeAccount.id;
     const activeGroup = targetAccountGroups && targetAccountGroups.length > 0 ? targetAccountGroups.find((group) => group.id === groupId) : null;
     const groupIncludeAllBO = activeGroup ? !!activeGroup.includeAllBO : false;
     const isEditableGroups =
          !activeGroup || (!activeGroup.isDefault && activeGroup.type !== TARGET_ACCOUNT_GROUP_TYPE.DYNAMIC_LIST);
     const BOGroup = targetAccountGroups && targetAccountGroups.length > 0 ? targetAccountGroups.find((group) => group.isDefault) : null;
     const currentBO = groupId === (BOGroup && BOGroup.id)
     // const isDisabledBO = BOGroup && !BOGroup.status;
     const [searchObject, setSearchObject] = useState({})

     const [searchValue, setSearchValue] = useState();
     const [isShowAddNew, setIsShowAddNew] = useState(false);
     const [domainOptions, setDomainOptions] = useState([]);
     const [currentEditingRow, setCurrentEditingRow] = useState();
     const [isIncludeAllBO, setIsIncludeAllBO] = useState(groupIncludeAllBO);
     const [includeAllBOChange, setIncludeAllBOChange] = useState(false);
     const [isTableFetching, setIsTableFetching] = useState(false);
     const [pagination, setPagination] = useState({ page: 1, pageSize: DEFAULT_PAGE_SIZE });
     const [nextPagination, setNextPagination] = useState({ page: 1, pageSize: DEFAULT_PAGE_SIZE });
     const [saveTableModal, setSaveTableModal] = useState(false);
     const [saveLoading, setSaveLoading] = useState(false);
     const [deleteLoading, setDeleteLoading] = useState(false);
     const [isAnyChanges, setIsAnyChanges] = useState(false);
     const [leaveComponentModal, setLeaveComponentModal] = useState(false);
     const [btnAbandon, setBtnAbandon] = useState(false);
     const [removeRowModal, setRemoveRowModal] = useState({ show: false, id: null });
     const [defaultDomainOptions, setDefaultDomainOptions] = useState([]);
     const [isLoadingDomains, setIsLoadingDomains] = useState(false);
     const [dataInPage, setDataInPage] = useState({});
     const [filterDomainOptions, setFilterDomainOptions] = useState([]);
     const [domains, setDomains] = useState([])

     const currentDomain = useMemo(() => {
          const currentDomain = [];
          let haveCurrentPage = false;
          Object.entries(dataInPage).forEach(([key, currentPage]) => {
               if (Number(key) === Number(pagination.page)) {
                    const domain = targetAccountsList.map((item) => {
                         return item.domain;
                    });
                    haveCurrentPage = true;
                    currentDomain.push(...domain);
               } else {
                    const domain = currentPage.map((item) => item.domain);
                    currentDomain.push(...domain);
               }
          });

          if (!haveCurrentPage) {
               const domain = targetAccountsList.map((item) => {
                    return item.domain;
               });
               currentDomain.push(...domain);
          }

          return currentDomain;
     }, [dataInPage, targetAccountsList, pagination]); // eslint-disable-line

     useEffect(() => {
          setFilterDomainOptions(() => {
               return defaultDomainOptions.filter((item) => !currentDomain.includes(item.domain));
          })
     }, [currentDomain]) // eslint-disable-line

     const setTargetAccountsCurrentPage = (newTargetAccounts) => {
          const finalTargetAccounts = newTargetAccounts.length ? newTargetAccounts : DEFAULT_TEMP_DOMAINS;
          dispatch(setSubscriberState({ targetAccountsList: finalTargetAccounts }));
     };

     const getFullList = () => {
          fetchTargetAccountsRequest({ accountId, pagination, setTargetAccountsCurrentPage, setIsTableFetching, groupId, setDataInPage, searchObject, setDomains });
     };

     const fetchDefaultDomains = () => {
          fetchDomainsRequest({ accountId, setIsLoadingDomains, groupId, fetchDefault: true, setDefaultDomainOptions, setFilterDomainOptions });
     };

     useEffect(() => {
          if (targetAccountsPusher && targetAccountsPusher.length) {
               const finalTargetAccounts = targetAccountsList.map((item) => {
                    const targetAccount = targetAccountsPusher.find((_data) => _data.id === item.id)
                    if (targetAccount) {
                         return {
                              ...item,
                              isSyncBigQuery: targetAccount.isSyncBigQuery
                         }
                    }
                    return item
               })

               let newInPage = {}

               Object.entries(dataInPage).forEach(([key, value]) => {
                    const newTargetInPage = value.map((item) => {
                         const targetAccount = targetAccountsPusher.find((_data) => _data.id === item.id)
                         if (targetAccount) {
                              return {
                                   ...item,
                                   isSyncBigQuery: targetAccount.isSyncBigQuery
                              }
                         }
                         return item
                    })
                    newInPage = { ...newInPage, [key]: newTargetInPage }
               })

               setDataInPage(newInPage)

               dispatch(setSubscriberState({ targetAccountsList: finalTargetAccounts }));
          }
     }, [targetAccountsPusher]) // eslint-disable-line

     useEffect(() => {
          if (targetAccountsDomain && targetAccountsDomain.length) {
               const finalTargetAccounts = targetAccountsList.map((item) => {
                    const targetAccount = targetAccountsDomain.find((_data) => _data.domain === item.domain)
                    if (targetAccount) {
                         return {
                              ...targetAccount
                         }
                    }
                    return item
               })

               let newInPage = {}

               Object.entries(dataInPage).forEach(([key, value]) => {
                    const newTargetInPage = value.map((item) => {
                         const targetAccount = targetAccountsDomain.find((_data) => _data.domain === item.domain)
                         if (targetAccount) {
                              return {
                                   ...targetAccount
                              }
                         }
                         return item
                    })
                    newInPage = { ...newInPage, [key]: newTargetInPage }
               })

               setDataInPage(newInPage)

               dispatch(setSubscriberState({ targetAccountsList: finalTargetAccounts }));
          }
     }, [targetAccountsDomain]) // eslint-disable-line

     useEffect(() => {
          fetchDefaultDomains();
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          getFullList();
     }, [searchObject, reloadData, targetAccountsReLoad]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (!targetAccountGroups) {
               callTokenApi(`${CLIENT_GET_ACCOUNT_GROUPS}${accountId}`, 'GET')
                    .then((response) => {
                         if (response.status === 200) {
                              const rules = response.data.data;

                              dispatch(
                                   setSubscriberState({
                                        targetAccountGroups: rules,
                                   })
                              );
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => { });
          }
     }, [accountId]); // eslint-disable-line react-hooks/exhaustive-deps

     const checkSaveStatus = useCallback(() => {
          if (!isEditableGroups) return;
          const isAnyEditingRows = targetAccountsList.some((row) => !!row.actionType) || Object.values(dataInPage).some((value) => value.some((row) => !!row.actionType));
          setIsAnyChanges(isAnyEditingRows);

          // Mark current modal is unsaved
          const newMultiModals = [...flexibleMultiModal];
          const currentModalIndex = newMultiModals.findIndex((modal) => modal.component === COMPONENT_NAME.TARGET_ACCOUNTS_FULL_LIST);
          if (currentModalIndex > -1) {
               newMultiModals[currentModalIndex] = {
                    ...newMultiModals[currentModalIndex],
                    isUnsaved: isAnyEditingRows,
                    isUpdateRedux: true,
               };

               dispatch(setFlexibleMultiModal(newMultiModals[currentModalIndex]));
          }
     }, [targetAccountsList]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          checkSaveStatus();
     }, [targetAccountsList, checkSaveStatus]);

     useEffect(() => {
          const tableEl = document.querySelector('.table-wrapper');
          targetAccountsList
               .filter((row) => row.isTemp)
               .forEach((row) => {
                    const floatSearchEl = document.querySelector(`.float-select-${row.id}`);
                    const cellEl = document.querySelector(`#row-${row.id}`);

                    if (!floatSearchEl || !cellEl) return;

                    const tableElTop = tableEl.getBoundingClientRect().top;
                    const cellElTop = cellEl.getBoundingClientRect().top;
                    const cellElWidth = cellEl.getBoundingClientRect().width;
                    const newFloatSearchTop = cellElTop - tableElTop;

                    floatSearchEl.style.top = `${newFloatSearchTop + 1}px`;
                    floatSearchEl.style.width = `${cellElWidth - 2}px`;
               });
     }, [targetAccountsList]); // eslint-disable-line react-hooks/exhaustive-deps

     //#region Handle Change Row
     const onToggleRow = (row) => {
          setCurrentEditingRow(row);
     };

     const handleAddRow = (rows = 1) => {
          const newTableRows = [...targetAccountsList];
          for (let i = 0; i < rows; i++) {
               let newRow = {};
               newRow = {
                    id: generateId(),
                    isTemp: true,
                    totalCount: (targetAccountsList[0] && targetAccountsList[0].totalCount) || 1
               };
               newTableRows.push(newRow);
          }

          setTargetAccountsCurrentPage(newTableRows);
     };

     const handleAddNewData = () => {
          const newSearchValue = extractDomain(searchValue).trim() || '';
          if (!newSearchValue) return;

          const newTableRows = [...targetAccountsList];
          const rowIndex = newTableRows.findIndex((row) => row.id === currentEditingRow.id);

          newTableRows[rowIndex] = {
               ...newTableRows[rowIndex],
               domain: newSearchValue,
               label: newSearchValue,
               value: newSearchValue,
               actionType: 'add',
               isProcessing: true,
               isBO: isIncludeAllBO || false
          };

          const finalTargetAccounts = newTableRows.length ? newTableRows : DEFAULT_TEMP_DOMAINS;
          dispatch(setSubscriberState({ targetAccountsList: finalTargetAccounts }));
          setDomainOptions([]);
          setSearchValue('');
          setIsShowAddNew(false);

          checkDomainCompany(newSearchValue, newTableRows)
     };

     const checkDomainCompany = (domain, newTableRows) => {
          callApiCheckDomainCompany({ accountId, domains: [domain] }).then((response) => {
               const domainIndex = newTableRows.findIndex((item) => item.domain === domain);

               if (response.status) {
                    const domainData = response.data.data[domain];

                    const companyData = domainData && domainData.data;
                    let newCompanyData = null;

                    if (companyData && Object.keys(companyData).length) {
                         MAPPING_COMPANY_DATA.forEach((item) => {
                              let itemData = companyData[item] && companyData[item] !== 'null' ? companyData[item] : null

                              if (item === 'monthlyVisitors' && itemData) {
                                   itemData = itemData.replace(' ', '-')
                              }

                              newCompanyData = { ...newCompanyData, [item]: itemData }
                         })
                    }

                    newTableRows[domainIndex] = { ...newTableRows[domainIndex], companyData: newCompanyData, isProcessing: false }

                    console.log('domain: ', domain, domainData.type)
               } else {
                    newTableRows[domainIndex] = { ...newTableRows[domainIndex], isProcessing: false }
                    console.log('failed', domain)
               }

               dispatch(setSubscriberState({ targetAccountsDomain: [newTableRows[domainIndex]] }));

          })
     }

     const handleChangeRowData = (selected, row) => {
          if (!selected) return;

          const newTableRows = [...targetAccountsList];
          const rowIndex = newTableRows.findIndex((tableRow) => tableRow.id === row.id);

          newTableRows[rowIndex] = {
               ...newTableRows[rowIndex],
               domain: selected.domain,
               companyId: selected.companyId,
               id: selected.targetAccountId || newTableRows[rowIndex].id,
               actionType: selected.targetAccountId ? 'edit' : 'add',
               isBO: isIncludeAllBO || selected.isBO || false,
               isProcessing: true,
          };

          const finalTargetAccounts = newTableRows.length ? newTableRows : DEFAULT_TEMP_DOMAINS;
          dispatch(setSubscriberState({ targetAccountsList: finalTargetAccounts }));
          setDomainOptions([]);
          setSearchValue('');

          checkDomainCompany(selected.domain, newTableRows)

     };

     const handleInputChange = (value) => {
          const newSearchValue = value.trim() || '';
          if (newSearchValue !== searchValue) {
               setSearchValue(newSearchValue);

               const isExisted =
                    !!newSearchValue &&
                    (targetAccountsList.find((targetAccount) => targetAccount.domain === newSearchValue) ||
                         currentDomain.find((item) => item === newSearchValue) || domains.find((item) => item === newSearchValue));

               const isShowAddNew =
                    !isLoadingDomains &&
                    !!newSearchValue &&
                    !COMPANY_BLACKLIST.includes(newSearchValue) &&
                    newSearchValue.match(REGEX_DOMAIN) &&
                    !isExisted;

               setIsShowAddNew(isShowAddNew);
          }
     };

     const handleRemoveRow = (row, isTemp, isImport) => {
          if (!isTemp && !isImport) {
               setRemoveRowModal({ show: true, id: row.id });
          } else if (targetAccountsList.length) {
               const newTableRows = [...targetAccountsList].filter((item) => item.id !== row.id);
               const finalTargetAccounts = newTableRows.length ? newTableRows : DEFAULT_TEMP_DOMAINS;
               dispatch(setSubscriberState({ targetAccountsList: finalTargetAccounts }));
          }
     };

     const confirmRemoveRow = (payload) => {
          const findRow = targetAccountsList.find((item) => item.id === payload.id);

          if (findRow.companyId && !filterDomainOptions.find((item => item.domain === findRow.domain))) {
               setFilterDomainOptions((prev) => {
                    prev.push({domain: findRow.domain, label: findRow.domain, value: findRow.domain, companyId: findRow.companyId })
                    return prev
               })
          }

          if (groupId) {
               deleteTargetAccountGroupRequest({
                    accountId,
                    targetAccountsList,
                    pagination,
                    groupId,
                    setPagination,
                    setTargetAccountsCurrentPage,
                    setDeleteLoading,
                    setRemoveRowModal,
                    searchObject,
                    setDataInPage,
                    ...payload,
               });
          } else {
               deleteTargetAccountRequest({
                    accountId,
                    targetAccountsList,
                    pagination,
                    setPagination,
                    setTargetAccountsCurrentPage,
                    setDeleteLoading,
                    setRemoveRowModal,
                    searchObject,
                    ...payload,
                    setDataInPage,
               });
          }

          if(findRow){
               setDomainOptions((prevDomains)=>[...prevDomains, findRow.domain])
          }
      
          
     };

     const setTargetAccountGroups = (newTargetAccountGroups) => {
          dispatch(
               setSubscriberState({
                    targetAccountGroups: newTargetAccountGroups,
               })
          )
     }

     const handleClickIncludeAllBO = (e) => {
          const isChecked = !!e.target.checked;
          setIsIncludeAllBO(isChecked);
          setIsAnyChanges(true);
          setIncludeAllBOChange(true);

          const newTableRows = [...targetAccountsList];
          const newTableRowsUpdated = newTableRows.map((row) => {
               return {
                    ...row,
                    actionType: row.actionType === 'add' ? 'add' : isChecked !== row.originIsBO ? 'edit' : null,
                    isBO: isChecked,
               };
          });

          let newDataInPage = {}

          Object.entries(dataInPage).forEach(([key, value]) => {
               const data = value.map((row) => {
                    return {
                         ...row,
                         actionType: row.actionType === 'add' ? 'add' : isChecked !== row.originIsBO ? 'edit' : null,
                         isBO: isChecked,
                    };
               })

               newDataInPage = { ...newDataInPage, [key]: data }
          })

          setDataInPage(newDataInPage);

          const finalTargetAccounts = newTableRowsUpdated.length ? newTableRowsUpdated : DEFAULT_TEMP_DOMAINS;
          dispatch(setSubscriberState({ targetAccountsList: finalTargetAccounts }));
     };
     //#endregion Handle Change Row

     //#region Handle Paginate
     const onPaginationChange = (i) => {
          const newPagination = { ...pagination, page: i };

          setDataInPage((prev) => {
               return { ...prev, [pagination.page]: targetAccountsList };
          });

          setPagination(newPagination);

          if (dataInPage[i]) {
               setTargetAccountsCurrentPage(dataInPage[i]);
          } else {
               fetchTargetAccountsRequest({ accountId, pagination: newPagination, setTargetAccountsCurrentPage, setIsTableFetching, groupId, isIncludeAllBO, includeAllBOChange, searchObject, setDomains });
          }
     };

     const onChangeItemsPerPage = (e) => {
          const newPagination = { page: 1, pageSize: +e.target.value };

          if (isAnyChanges) {
               setNextPagination(newPagination);
               setLeaveComponentModal(true);
               return;
          }

          setPagination(newPagination);
          fetchTargetAccountsRequest({ accountId, pagination: newPagination, setTargetAccountsCurrentPage, setIsTableFetching, groupId, searchObject, setDomains });
     };
     //#endregion Handle Paginate

     //#region Handle Leave Component
     const handleCloseLeaveComponent = () => {
          setLeaveComponentModal(false);
          setIsAnyChanges(true);
          dispatch(setUnsavedLookupTableModal({ show: false }));
     };

     const handleAcceptLeaveComponent = () => {
          if (unsavedLookupTableModal.show) {
               const newTableRows = [];

               for (let i = 0; i < targetAccountsList.length; i++) {
                    if (targetAccountsList[i].isTemp || targetAccountsList[i].actionType === 'add') continue;

                    newTableRows.push({ ...targetAccountsList[i], isBO: targetAccountsList[i].originIsBO, actionType: null });
               }

               setTargetAccountsCurrentPage(newTableRows);
               setIsAnyChanges(false);
               if (typeof unsavedLookupTableModal.onAccept === 'function') unsavedLookupTableModal.onAccept();
               dispatch(setUnsavedLookupTableModal({ show: false, onAccept: null }));
               dispatch(
                    setFlexibleMultiModal({
                         show: false,
                         component: COMPONENT_NAME.TARGET_ACCOUNTS_FULL_LIST,
                    })
               );
          } else if (leaveComponentModal) {
               //For change pagination
               setIsAnyChanges(false);
               setLeaveComponentModal(false);
               setPagination(nextPagination);
               fetchTargetAccountsRequest({ accountId, pagination: nextPagination, setTargetAccountsCurrentPage, setIsTableFetching, groupId, searchObject, setDomains });
          } else {
               setBtnAbandon(true);
          }
     };
     //#endregion Handle Leave Component

     //#region Handle Submit
     const onAcceptSaveModal = () => {
          const payload = {
               accountId,
               targetAccountsList,
               setTargetAccountsCurrentPage,
               pagination,
               setPagination,
               setSaveTableModal,
               setSaveLoading,
               groupId,
               includeAllBO: isIncludeAllBO,
               includeAllBOChange,
               setIncludeAllBOChange,
               dataInPage,
               setDataInPage,
               targetAccountGroups,
               setTargetAccountGroups,
               searchObject
          };

          updateTargetAccountsRequest(payload);
     };

     const validateSubmitted = () => {
          let errorColumn = [],
               errorName = '';

          // Check Duplicate
          for (let i = 0; i < targetAccountsList.length; i++) {
               for (let j = i + 1; j < targetAccountsList.length; j++) {
                    if (targetAccountsList[i].domain === targetAccountsList[j].domain) {
                         errorColumn.push(i, j);
                         errorName = 'duplicate';
                    }
               }
          }

          // Check Empty
          targetAccountsList.some((row, index) => {
               if (!row.domain) {
                    errorColumn.push(index);
                    errorName = 'empty';
                    return true;
               }
               return false;
          });

          return { errorColumn, errorName };
     };

     const onSubmit = (e) => {
          e.preventDefault();

          let { errorColumn, errorName } = validateSubmitted();
          if (errorColumn.length > 0) {
               // setHasError(errorColumn);
               if (errorName === 'empty') {
                    toast.error(`Domain cannot be empty.`);
               } else {
                    toast.error(`Duplicate!`);
               }
          } else {
               setSaveTableModal(true);
          }
     };
     //#endregion Handle Submit

     const isAnyProcessing = targetAccountsList && targetAccountsList.some((row) => row.isProcessing);

     return (
          <>
               <RemovePopup
                    show={removeRowModal.show}
                    onAccept={() => confirmRemoveRow({ id: removeRowModal.id })}
                    onClose={() => setRemoveRowModal({ show: false, id: null })}
                    loading={deleteLoading}
               >
                    <p>Are you sure to remove this row?</p>
               </RemovePopup>

               <LeaveComponentChangePopup
                    show={leaveComponentModal || unsavedLookupTableModal.show}
                    onAccept={handleAcceptLeaveComponent}
                    onClose={handleCloseLeaveComponent}
                    title="You Have Unsaved Changes"
                    btnAbandon={btnAbandon ? <span className="dots-waiting">Waiting</span> : 'Abandon My Changes & Leave'}
               >
                    You haven’t saved the changes you started making to this lookup table. If you navigate away, you will lose the changes. What do
                    you want to do?
               </LeaveComponentChangePopup>

               <ConfirmSaveChange
                    show={saveTableModal}
                    onAccept={onAcceptSaveModal}
                    onClose={() => setSaveTableModal(false)}
                    title="Save Your Changes?"
                    isLoading={saveLoading}
               >
                    It looks like you have made changes to this lookup table but have not saved them. Would you like to save the changes before
                    navigating away?
               </ConfirmSaveChange>

               {isTableFetching && <LoadingPanel />}

               <CForm onSubmit={onSubmit}>
                    <div className="table-wrapper mb-3">
                         {
                              isEditableGroups && targetAccountsList.filter((row) => row.isTemp)
                                   .map((row, index) => {
                                        const isOnlyOneRow = targetAccountsList.length === 1 && !row.domain;
                                        const isEditing = currentEditingRow && currentEditingRow.id === row.id;
                                        const selectedValue = domainOptions.find((option) => option.domain === row.domain);
                                        const inputAddValue = row.domain ? { label: row.domain, value: row.domain } : null;
                                        const correctValue = selectedValue || inputAddValue;

                                        return (
                                             <div>
                                                  <FullListTableContext.Provider value={{ isShowAddNew, setIsShowAddNew, handleAddNewData }}>
                                                       <TableSelectSearchV2
                                                            id={row.id}
                                                            customLoading={isLoadingDomains}
                                                            classNameWrapper={`float-select-search float-select-${row.id} ${isEditing ? 'editing' : ''}`}
                                                            placeholder="Select Domain"
                                                            options={filterDomainOptions}
                                                            value={correctValue}
                                                            onToggle={() => onToggleRow(row)}
                                                            onChange={(selected) => {
                                                                 handleChangeRowData(selected, row);
                                                            }}
                                                            onInputChange={(event) => handleInputChange(event)}
                                                            isShowRemove={!isOnlyOneRow}
                                                            onClickRemove={() => handleRemoveRow(row, row.isTemp, row.isImport)}
                                                            dataVariable={false}
                                                            selectInputType={false}
                                                            isClearable={true}
                                                            currentDomain={currentDomain}
                                                            isDisabled={!isEditableGroups}
                                                       />
                                                  </FullListTableContext.Provider>
                                             </div>
                                        );
                                   })}

                         <div className="table-banner">
                              <div className="left">Domain names</div>
                              <div className="right">Matched Organization</div>
                         </div>

                         <table className="table lookup-table lookup-table-init full-list-table thin-scrollbar">
                              <>
                                   <thead>
                                        {/* <tr className="rule-flow">
                                             <td colSpan={1}>Domain names</td>
                                             <td colSpan={13}>Matched Organizations</td>
                                        </tr> */}

                                        <FullListTableContext.Provider value={{ searchObject, setSearchObject, setDataInPage, setPagination, currentBO }}>
                                             <TableHeaderRow />
                                        </FullListTableContext.Provider>
                                   </thead>
                                   <tbody>
                                        <FullListTableContext.Provider
                                             value={{
                                                  searchValue,
                                                  setSearchValue,
                                                  setCurrentEditingRow,
                                                  domainOptions,
                                                  setDomainOptions,
                                                  setRemoveRowModal,
                                                  handleRemoveRow,
                                                  groupId,
                                                  currentDomain,
                                                  isIncludeAllBO,
                                                  setIsIncludeAllBO,
                                                  includeAllBOChange,
                                                  setIncludeAllBOChange
                                             }}
                                        >
                                             <TableBodyRows />
                                        </FullListTableContext.Provider>
                                   </tbody>
                              </>
                         </table>
                    </div>

                    <>
                         <TablePagination
                              targetAccountsList={targetAccountsList}
                              pagination={pagination}
                              onPaginationChange={onPaginationChange}
                              onChangeItemsPerPage={onChangeItemsPerPage}
                         />

                         <div className={`add-margin-cheat-sheet form-edit-lookup-row`}>
                              <div>
                                   <div className={`add-row-button d-flex align-items-center `}>
                                        {isEditableGroups && (
                                             <div className="add-row-button-child d-flex">
                                                  <CButton className="btn-add-row" onClick={() => handleAddRow(1)}>
                                                       <CIcon icon="iconAddField" className="icon-add" />
                                                       <CLabel className="add-row mb-0">Add Row</CLabel>
                                                  </CButton>
                                                  <CButton className="btn-add-row" onClick={() => handleAddRow(5)}>
                                                       <CIcon icon="iconAddField" className="icon-add" />
                                                       <CLabel className="add-row mb-0">Add 5 Rows</CLabel>
                                                  </CButton>
                                             </div>
                                        )}

                                        <FullListTableContext.Provider value={{ targetAccountsList, isEditableGroups, groupId, setIsAnyChanges, searchObject, setReloadData }}>
                                             <TableImportExport />
                                        </FullListTableContext.Provider>
                                   </div>

                                   {isEditableGroups && !!activeGroup && (
                                        <CFormGroup
                                             className="content-c include-data-tooltip parameter-checkbox mt-2"
                                             variant="custom-checkbox"
                                             inline
                                        >
                                             <CInputCheckbox
                                                  custom
                                                  id="bo-include-all"
                                                  checked={isIncludeAllBO}
                                                  onChange={(e) => handleClickIncludeAllBO(e)}
                                             />
                                             <CLabel variant="custom-checkbox" htmlFor={'bo-include-all'}>
                                                  Include all of these Accounts in Big Opportunities
                                             </CLabel>
                                        </CFormGroup>
                                   )}
                              </div>

                              {isEditableGroups && (
                                   <div className="form-edit-lookup-button text-right d-flex justify-content-end align-items-center">
                                        <div>
                                             <CButton
                                                  color="success"
                                                  type="submit"
                                                  className="d-inline-block text-uppercase"
                                                  disabled={!isAnyChanges || isAnyProcessing}
                                             >
                                                  Save Changes
                                             </CButton>
                                        </div>
                                   </div>
                              )}
                         </div>
                    </>
               </CForm>
          </>
     );
};

export default FullListTable;
