import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_RECORD_PROFILE_EVENT_E_COMMERCE, COMPONENT_NAME } from '../../../../../constants';
import { callTokenApi } from '../../../../../apiCaller';
import { toastError } from '../../../../../utils';
import { CCollapse } from '@coreui/react';
import { setFlexibleMultiModal } from '../../../../../actions/common';
import { UserSourceDetails, UserSourcePathDetails } from './TootleDetail';

const RecordProfileEventECommerce = ({ id }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const [initialValue, setInitialValue] = useState(null);
     const [isLoading, setIsLoading] = useState(true);
     const data = { id, accountId: activeAccount.id };
     const [defaultActive, setDefaultActive] = useState(true);

     useEffect(() => {
          callTokenApi(API_RECORD_PROFILE_EVENT_E_COMMERCE, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         setInitialValue(response.data.newDataEvent);
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleNavigateSession = (id) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: id },
                    component: COMPONENT_NAME.RECORD_PROFILE_SESSION,
               })
          );
     };

     const handleNavigateUser = (id) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: id },
                    component: COMPONENT_NAME.RECORD_PROFILE_USER,
               })
          );
     };

     const handleNavigatePerson = (id) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: id },
                    component: COMPONENT_NAME.RECORD_PROFILE_PERSON,
               })
          );
     };

     const handleNavigateCompany = (id) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: id },
                    component: COMPONENT_NAME.RECORD_PROFILE_COMPANY,
               })
          );
     };

     function formatNumberWithCommas(number) {
          return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
     }

     function formatNumber(value) {
          if (typeof value !== 'number') {
               return String(value);
          } else {
               return formatNumberWithCommas(Number(value));
          }
     }

     return (
          <>
               {isLoading ? (
                    <div className='record-profile loading-profile'>
                         <h1>Event Details</h1>
                         <div className='record-profile-info'>
                              <p className='event-profile-session'>Event ID: </p>
                              <p className='event-profile-session'>Event Type: </p>
                              <p className='event-profile-session'>Event Name: </p>
                              <p className='event-profile-session'>Date: </p>
                              <p className='event-profile-session'>
                                   Session: <span className='link-data'></span>
                              </p>
                              <p className='event-profile-session'>
                                   User: <span className='link-data'></span>
                              </p>
                              <p className='event-profile-session'>
                                   Person: <span className='link-data'></span>
                              </p>
                              <p className='event-profile-session'>
                                   Revealed Company: <span className='link-data'></span>
                              </p>
                              <p className='event-profile-session'>
                                   Transaction ID: <span className='link-data'></span>
                              </p>
                         </div>
                         <div className="list-count-data">
                              <div className="card box-count-data event-profile-session">
                                   <h4>Purchase Total</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Total Item Quantity</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Unique Items</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Shipping Total</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Tax Total</h4>
                                   <p className="item-name"></p>
                              </div>
                         </div>
                         <div className="page">
                              <div className="page-path-name event-profile-session">
                                   <p className="title">Source (first):</p>
                                   <p className="description"></p>
                              </div>
                              <div className="page-path-name event-profile-session">
                                   <p className="title">Source (last):</p>
                                   <p className="description"></p>
                              </div>
                              <div className="page-path-name event-profile-session">
                                   <p className="title">Source (path):</p>
                                   <p className="description source-path"></p>
                              </div>
                         </div>
                         <div className='accordion mt-4'>
                              <div className={`accordion-item${defaultActive ? ' show' : ''}`}>
                                   <button className={`accordion-header consent${defaultActive ? ' show' : ''}`} onClick={() => setDefaultActive(!defaultActive)}>
                                        <p className='title'>Item Details</p>
                                   </button>
                                   <CCollapse visible={defaultActive}>
                                        <div className='accordion-body event-profile-session'>
                                             {defaultActive && (
                                                  <>
                                                       <h4>Other Details</h4>
                                                       <p>Coming Soon!</p>
                                                  </>
                                             )}
                                        </div>
                                   </CCollapse>
                              </div>
                         </div>
                    </div>
               ) : (
                    <div className='record-profile'>
                         <h1>Event Details</h1>
                         {initialValue && (
                              <>
                                   <div className='record-profile-info'>
                                        <p>Event ID: {initialValue.eventId}</p>
                                        <p>Event Name: {initialValue.eventName}</p>
                                        <p>Event Type: {initialValue.eventType}</p>
                                        <p>Date: {initialValue.date}</p>
                                        <p>
                                             Session:{' '}
                                             <span className='link-data' onClick={() => handleNavigateSession(initialValue.session)}>
                                                  {initialValue.session}
                                             </span>
                                        </p>
                                        <p>
                                             User:{' '}
                                             <span className='link-data' onClick={() => handleNavigateUser(initialValue.user)}>
                                                  {initialValue.user}
                                             </span>
                                        </p>
                                        {initialValue.personId ? (
                                             <>                                        
                                             {initialValue.externalId ? (
                                                  <p>
                                                       External Id:{' '}
                                                       <span className='link-data' onClick={() => handleNavigatePerson(initialValue.personId)}>
                                                            {initialValue.externalId}
                                                       </span>
                                                  </p>
                                             ) : (
                                                  <p>
                                                       Person:{' '}
                                                       <span className='link-data' onClick={() => handleNavigatePerson(initialValue.personId)}>
                                                            {initialValue.person}
                                                       </span>
                                                  </p>
                                             )}
                                             </>
                                        ) : (
                                             <p>
                                                  Person:{' '}
                                             </p>
                                        )}
                                        <p>
                                             Revealed Company:{' '}
                                             {initialValue.companyList.map((item, index) => {
                                                  return (
                                                       <span key={index} className='link-data' onClick={() => handleNavigateCompany(item.companyId)}>
                                                            {index < initialValue.companyList.length - 1 ? <>{item.companyName} ,&nbsp;</> : <>{item.companyName}</>}
                                                       </span>
                                                  );
                                             })}
                                        </p>
                                        <p>Transaction ID: {initialValue.transactionId}</p>
                                   </div>

                                   <div className="list-count-data">
                                        <div className="card box-count-data">
                                             <h4>Purchase Total</h4>
                                             <p className="item-name">{formatNumber(initialValue.purchaseTotal)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Total Item Quantity</h4>
                                             <p className="item-name">{initialValue.itemQuantity}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Unique Items</h4>
                                             <p className="item-name">{initialValue.uniqueItem}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Shipping Total</h4>
                                             <p className="item-name">{initialValue.shippingTotal}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Tax Total</h4>
                                             <p className="item-name">{initialValue.taxTotal}</p>
                                        </div>
                                   </div>
                                   <div className="page">
                                        <div className="page-path-name">
                                             <p className="title">Source (first):</p>
                                             <div className="description">
                                                  <UserSourceDetails data={initialValue.sourceFirst} />
                                             </div>
                                        </div>
                                        <div className="page-path-name">
                                             <p className="title">Source (last):</p>
                                             <div className="description">
                                                  <UserSourceDetails data={initialValue.sourceLast} />
                                             </div>
                                        </div>
                                        <div className="page-path-name">
                                             <p className="title">Source (path):</p>
                                             <UserSourcePathDetails data={initialValue.sourcePath} />
                                        </div>
                                   </div>

                                   <div className='accordion mt-4'>
                                        <div className={`accordion-item${defaultActive ? ' show' : ''}`}>
                                             <button className={`accordion-header consent${defaultActive ? ' show' : ''}`} onClick={() => setDefaultActive(!defaultActive)}>
                                                  <p className='title'>Item Details</p>
                                             </button>
                                             <CCollapse visible={defaultActive}>
                                                  <div className='accordion-body event-profile-session'>
                                                       {defaultActive && (
                                                            <>
                                                                 <p className="title mb-2">Items:</p>
                                                                 <ul>
                                                                      {initialValue.items && initialValue.items.map((item, key) => {
                                                                           const itemCategories = item.itemCategories.map((_item => _item.value))
                                                                           return (
                                                                                <li key={key} className="mb-4">
                                                                                     <p>ItemID: {item.itemID}</p>
                                                                                     <p>Item Name: {item.itemName}</p>
                                                                                     {itemCategories.length > 0 && (
                                                                                          <p>Item Categories: {itemCategories.join(', ')}</p>
                                                                                     )}
                                                                                     <p>Item Price: {formatNumber(item.itemPrice)}</p>
                                                                                     <p>Item Quantity: {item.itemQuantity}</p>
                                                                                     {item.coupon ? <p>Item Coupon: {item.coupon}</p> : null}
                                                                                     {item.discount ? <p>Item Discount: {item.discount}</p> : null}
                                                                                     <p>Item Revenue: {formatNumber(item.itemRevenue)}</p>
                                                                                </li>
                                                                           );
                                                                      })}
                                                                 </ul>
                                                            </>
                                                       )}
                                                  </div>
                                             </CCollapse>
                                        </div>
                                   </div>
                              </>
                         )}
                    </div>
               )}
          </>
     );
};

export default RecordProfileEventECommerce;
