import React from 'react';
import { CModal, CModalBody } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import CEmbed from '../../migration/CEmbed';

const VideoPopup = ({ show, onClose, videoEmbed, headline, someSmallAmountText }) => {
     return (
          <div className={`instruction-popup text-center`}>
               <CModal visible={show} onClose={onClose} alignment="center" portal={false}>
                    {show && (
                         <CModalBody>
                              <CIcon icon='cil-x' onClick={onClose} className='icon-close-popup' />
                              {headline && <h4>{headline}</h4>}
                              {someSmallAmountText && <p className='some-small-amount-text'>{someSmallAmountText}</p>}
                              {videoEmbed && (
                                   <CEmbed className='instruction-video' ratio='16by9'>
                                        <iframe
                                             src={videoEmbed}
                                             frameBorder='0'
                                             allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                             allowFullScreen
                                             title={headline || 'instruction'}
                                        />
                                   </CEmbed>
                              )}
                         </CModalBody>
                    )}
               </CModal>
          </div>
     );
};

export default VideoPopup;
