import React, { useState, useCallback, useRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import CIcon from '@coreui/icons-react';
import XLSX from 'xlsx';

import { FullListTableContext } from './index';
import ImportTableModal from './ImportTableModal';
import { useOutsideHandling } from '../../../../../../../utils';
import { fetchTargetAccountsExportRequest } from '../../actions';

const TableImportExport = () => {
     const { isEditableGroups, groupId, searchObject } = useContext(FullListTableContext);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const [showImportModal, setShowImportModal] = useState(false);
     const [showImportExport, setShowImportExport] = useState(false);
     const targetAccountGroups = useSelector((state) => state.subscriber.targetAccountGroups) || [];
     const BOGroup = targetAccountGroups.length > 0 ? targetAccountGroups.find((group) => group.isDefault) : null;
     const isBOGroup = BOGroup && groupId === BOGroup.id;

     const accountId = activeAccount.id;

     const exportToFile = async (type = 'csv', fileName = 'table-import-export', onlyRow1And2) => {
          let dataToExport = [['Domain', 'BO'], ['example.com', true], ['example2.com', false]];

          const dataList = await fetchTargetAccountsExportRequest({ accountId, groupId, searchObject });

          if (!onlyRow1And2) {
               if (Array.isArray(dataList)) {
                    dataToExport = [
                         [
                              'Domain',
                              'Name',
                              'BO',
                              'Matched Groups',
                              'Revenue',
                              'Total Employee',
                              'Primary Industry',
                              'Product & Service Tags',
                              'Country',
                              'State',
                              'Business Type',
                              'Monthly Visitors',
                              'Year Founded',
                         ],
                    ];

                    dataList.forEach((targetAccount) => {
                         const textValue = (row) => !row.domain ||  (!row.companyData || Object.keys(row.companyData).length === 0 ? '' : 'None');
                      
                         const companyData = targetAccount.companyData || {}

                         const correctGroupNames = isBOGroup && targetAccount.groupName ? targetAccount.groupName.filter((name) => name !== BOGroup.name) : targetAccount.groupName;

                         const domainCell = targetAccount.domain || ' ';
                         const nameCell = companyData.companyName || ((!targetAccount.companyData || Object.keys(targetAccount.companyData).length === 0) ? 'No match found' : 'None');
                         const boCell = targetAccount.isBO ? 'true' : 'false';
                         const groupsCell = correctGroupNames || 'None';
                         const revenueCell = companyData.revenue || textValue(targetAccount);
                         const totalEmployeeCell = companyData.employees && companyData.employees !== 'none' ? companyData.employees + ' employees' :  textValue(targetAccount);
                         const primaryIndustryCell = companyData.primaryIndustry || textValue(targetAccount);
                         const productServiceTagCell = companyData.industries && companyData.industries.length ? companyData.industries : textValue(targetAccount);
                         const countryCell = ((companyData.countryData && companyData.countryData.name) || companyData.country) || textValue(targetAccount);
                         const stateCell = companyData.region || textValue(targetAccount);
                         const businessTypeCell = companyData.businessType || textValue(targetAccount);
                         const monthlyVisitorsCell = companyData.monthlyVisitors || textValue(targetAccount);
                         const yearFoundedCell = companyData.yearFounded || textValue(targetAccount);

                         dataToExport.push([
                              domainCell,
                              nameCell,
                              boCell,
                              groupsCell,
                              revenueCell,
                              totalEmployeeCell,
                              primaryIndustryCell,
                              productServiceTagCell,
                              countryCell,
                              stateCell,
                              businessTypeCell,
                              monthlyVisitorsCell,
                              yearFoundedCell,
                         ]);
                    });
               }
          }

          if (type === 'csv') {
               import('../../../../../../../utils').then((utils) => {
                    utils.exportToCSV(`${fileName}.${type}`, dataToExport);
               });
          } else {
               var workSheet = XLSX.utils.aoa_to_sheet(dataToExport);

               let workBook = XLSX.utils.book_new();
               XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');

               XLSX.writeFile(workBook, `${fileName}.${type}`);
          }
     };

     const handClickImportExport = useCallback(() => {
          setTimeout(() => {
               setShowImportExport(!showImportExport);
          }, 100);
     }, [showImportExport]);

     const handleClickOutside = useCallback(() => {
          if (!showImportModal) {
               setShowImportExport(false);
          }
     }, [showImportModal]);

     const wrapperRef = useRef(null);
     useOutsideHandling(wrapperRef, handleClickOutside);

     const toggleImportModal = useCallback(() => {
          if (!showImportModal) {
               setShowImportExport(false);
          }

          setShowImportModal(!showImportModal);
     }, [showImportModal]);

     return (
          <>
               <div className="import-export-wrapper">
                    <div className="import-export">
                         <CIcon icon="cil-chevron-double-down" height="14" />
                         {!isEditableGroups ? (
                              <button type="button" onClick={() => exportToFile('csv')}>
                                   Export existing table values
                              </button>
                         ) : (
                              <button type="button" onClick={handClickImportExport}>
                                   Import/Export
                              </button>
                         )}
                    </div>

                    {isEditableGroups && (
                         <ul ref={wrapperRef} className={showImportExport ? '' : 'hidden'}>
                              <li>
                                   <button color="primary" type="button" onClick={() => toggleImportModal()}>
                                        <CIcon icon="cil-cloud-upload" height="14"></CIcon>
                                        <span>Import new table values</span>
                                   </button>
                              </li>
                              <li>
                                   <button color="primary" type="button" onClick={() => exportToFile('csv')}>
                                        <CIcon icon="cil-cloud-download" height="14"></CIcon>
                                        <span>Export existing table values</span>
                                   </button>
                              </li>
                         </ul>
                    )}
               </div>

               <ImportTableModal
                    show={showImportModal}
                    onClose={() => setShowImportModal(false)}
                    toggleModal={toggleImportModal}
                    exportToFile={exportToFile}
               />
          </>
     );
};

export default TableImportExport;
