import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CButton, CContainer, CModal } from '@coreui/react';
import {
     API_CLIENT_CUSTOM_VARIABLE,
     API_CLIENT_DATALAYER_VARIABLE_RULE,
     BROWSER_STORAGE_TYPE,
     LIMITATION_TYPE,
     RULE_GROUP,
     VIEW_ALL,
} from '../../../../../../constants';
import {
     fetchAllRulesRequest,
     setBrowserVariables,
     setLimitationType,
     setLoadings,
     setPreviewBrowserRule,
     setPreviewOptions,
     setShowHamburgerIcon,
} from '../../../../../../actions/subscriber';
import { Link } from 'react-router-dom';
import CIcon from '@coreui/icons-react';
import StepProgress from './StepProgress';
import { toastError } from '../../../../../../utils';
import { callTokenApi } from '../../../../../../apiCaller';

const Step1 = React.lazy(() => import('./Step1'));
const Step2 = React.lazy(() => import('./Step2'));
const Step3 = React.lazy(() => import('./Step3'));
const Step4 = React.lazy(() => import('./Step4'));

export const RuleContext = React.createContext({});
export const STEPS = {
     Step1: 'Name your rule',
     Step2: 'Write your condition',
     Step3: 'Name your cookie/storage key',
     Step4: 'Save your value',
};
export const INIT_STEP_DATA = {
     name: '',
     description: '',
     conditions: [
          {
               conjunction: '',
               g0: {
                    type: 'Variable',
                    id: '',
                    key: '',
                    operator: '',
                    value: '',
                    conjunction: '',
               },
          },
     ],
     customVariables: [
          {
               action: '',
               id: '',
               value: '',
               existingValueAction: '',
          },
     ],
     group: 'browser',
};

export const CreateRuleBody = ({
     show = false,
     onBackBtnClicked,
     backBtnDisabled,
     shouldHideBackBtn,
     children,
     mainContentClassName = '',
     className = '',
}) => {
     return (
          <CContainer className={`main-container ${show ? 'show' : ''} ${className}`}>
               <div className='d-flex align-items-center m-0 main-header'>
                    <CButton
                         className={`btn-back m-0 ${onBackBtnClicked && !shouldHideBackBtn ? '' : 'invisible'}`}
                         onClick={onBackBtnClicked && !shouldHideBackBtn ? onBackBtnClicked : null}
                         disabled={backBtnDisabled}
                    >
                         Back
                    </CButton>
                    <div className='hero-logo'>
                         <CIcon icon='logo-dark' height={23} />
                    </div>
               </div>
               <div className={`main-content ${mainContentClassName}`}>
                    {show && children}
                    <StepProgress />
               </div>
               <div className='footer-create-account'>
                    <div className='logo-footer'>
                         <CIcon icon='logo-dark' height={23} />
                    </div>
                    <div className='nav-link-footer'>
                         <ul>
                              <li>
                                   <Link to='/'>Support</Link>
                              </li>
                              <li>
                                   <Link to=''>Terms of Use</Link>
                              </li>
                              <li>
                                   <Link to='/'>Privacy & Data Policy</Link>
                              </li>
                         </ul>
                    </div>
               </div>
          </CContainer>
     );
};

const CreatePreviewRule = () => {
     const dispatch = useDispatch();
     const { showPopup, selectedListener, storageType, storageData } = useSelector((state) => state.subscriber.previewBrowserRule);
     const browserRules = useSelector((state) => state.subscriber.browserRules);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const { variableOptions, browserVariableOptions: allBrowserVariableOptions } = useSelector((state) => state.subscriber.previewOptions);
     const accountListeners = useSelector((state) => state.subscriber.accountListeners);
     const customBrowserVariables = useSelector((state) => state.subscriber.customBrowserVariables);
     const { numberOfCusBrowserRules: usedCusBrowserRules, packageNumberOfCusBrowserRules: maxCusBrowserRules } = activeAccount;
     const activeListener = selectedListener !== VIEW_ALL && accountListeners && accountListeners.find((listener) => listener.id === selectedListener);
     const { listenerId: variableListenerId } = variableOptions;

     const [activeStep, setActiveStep] = useState(STEPS.Step1);
     const [stepsData, setStepsData] = useState({
          name: '',
          description: '',
          conditions: [
               {
                    conjunction: '',
                    g0: {
                         type: 'Variable',
                         id: '',
                         key: '',
                         operator: '',
                         value: '',
                         conjunction: '',
                    },
               },
          ],
          customVariables: [
               {
                    action: '',
                    id: '',
                    value: '',
                    existingValueAction: '',
               },
          ],
          group: 'browser',
     });

     const fetchStepData = () => {
          if (storageData && activeListener) {
               const newStepData = {
                    name: '',
                    description: '',
                    conditions: [
                         {
                              conjunction: '',
                              g0: {
                                   type: 'Variable',
                                   id: '',
                                   key: '',
                                   operator: '',
                                   value: '',
                                   conjunction: '',
                              },
                         },
                    ],
                    customVariables: [
                         {
                              action: '',
                              id: '',
                              value: '',
                              existingValueAction: '',
                         },
                    ],
                    group: 'browser',
               };
               setStepsData({
                    ...newStepData,
                    name: `Save ${storageData.path}`,
                    description: `Stores ${storageData.path} from the data layer inside a ${storageType} when a value exists. This applies to events from the ${activeListener.name}.`,
                    conditions: [
                         {
                              conjunction: '',
                              g0: {
                                   type: 'Variable',
                                   id: '',
                                   key: '',
                                   operator: 'exav',
                                   value: '',
                                   conjunction: '',
                              },
                         },
                    ],
               });
          }
     };
     useEffect(fetchStepData, [storageData, storageType, activeListener]);

     const fetchBrowserVariables = () => {
          if (allBrowserVariableOptions.length === 0) {
               dispatch(setLoadings({ browserVariablesLoading: true }));
               const urlToCall = `${API_CLIENT_CUSTOM_VARIABLE}${activeAccount.id}?group=browser`;
               callTokenApi(urlToCall, 'GET')
                    .then((response) => {
                         if (response.status === 200) {
                              dispatch(setPreviewOptions({ browserVariableOptions: response.data.variables }));
                         }
                    })
                    .finally(() => {
                         dispatch(setLoadings({ browserVariablesLoading: false }));
                    });
          }
     };
     useEffect(fetchBrowserVariables, [allBrowserVariableOptions]); // eslint-disable-line react-hooks/exhaustive-deps

     const fetchDatalayerVariables = () => {
          if (activeListener && (!variableListenerId || variableListenerId !== activeListener.id)) {
               callTokenApi(`${API_CLIENT_DATALAYER_VARIABLE_RULE}${activeAccount.id}/${activeListener.id}`, 'GET').then((response) => {
                    if (response.status === 200) {
                         dispatch(
                              setPreviewOptions({
                                   variableOptions: {
                                        listenerId: activeListener.id,
                                        variables: response.data.datalayerVariables,
                                   },
                              })
                         );
                    }
               });
          }
     };
     useEffect(fetchDatalayerVariables, [activeListener, variableListenerId]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleWhenHideAndShowPopup = () => {
          if (showPopup) {
               setTimeout(() => document.body.classList.add('no-scroll'), 0);
          } else {
               document.body.classList.remove('no-scroll');
          }
     };
     useEffect(handleWhenHideAndShowPopup, [showPopup]);

     const checkLimitation = () => {
          if (usedCusBrowserRules >= maxCusBrowserRules && maxCusBrowserRules !== -1) {
               dispatch(setLimitationType(LIMITATION_TYPE.BROWSER_RULE));
               dispatch(setShowHamburgerIcon(true));
          }

          return () => dispatch(setShowHamburgerIcon(false));
     };
     useEffect(checkLimitation, [usedCusBrowserRules, maxCusBrowserRules]); // eslint-disable-line react-hooks/exhaustive-deps

     const fetchRules = () => {
          if (!browserRules) {
               dispatch(fetchAllRulesRequest(activeAccount.id, RULE_GROUP.BROWSER_STORAGE));
          }
     };
     useEffect(fetchRules, []); // eslint-disable-line react-hooks/exhaustive-deps

     const fetchVariables = () => {
          if (customBrowserVariables.numOfVariables === 0) {
               callTokenApi(`${API_CLIENT_CUSTOM_VARIABLE}${activeAccount.id}?group=browser`, 'GET').then((response) => {
                    if (response.status === 200) {
                         const { count, variables } = response.data;
                         updateBrowserVariables(count, variables);
                    } else {
                         toastError(response);
                    }
               });
          }
     };
     useEffect(fetchVariables, []); // eslint-disable-line react-hooks/exhaustive-deps

     const updateBrowserVariables = (count, variables) => {
          let initialVariables = [],
               listDisabled = [];
          if (count >= 1) {
               variables.forEach((item) => {
                    initialVariables.push({ id: item.id, name: item.name, type: item.type });
                    listDisabled.push(true);
               });
               dispatch(
                    setBrowserVariables({
                         initialVariables,
                         addedVariables: [],
                         editedVariables: [],
                         listDisabled: [...listDisabled],
                         numOfVariables: count,
                    })
               );
               dispatch(setPreviewOptions({ browserVariableOptions: variables }));
          } else {
               dispatch(
                    setBrowserVariables({
                         initialVariables: [{ id: 'variable1', name: '', type: '' }],
                         addedVariables: [{ id: 'variable1', name: '', type: '' }],
                         editedVariables: [],
                         listDisabled: [false],
                         numOfVariables: 1,
                    })
               );
          }
     };

     const closeModal = () => {
          dispatch(setPreviewBrowserRule({ showPopup: false }));
     };

     const resetAllData = () => {
          setActiveStep(STEPS.Step1);
          setStepsData({
               name: '',
               description: '',
               conditions: [
                    {
                         conjunction: '',
                         g0: {
                              type: 'Variable',
                              id: '',
                              key: '',
                              operator: '',
                              value: '',
                              conjunction: '',
                         },
                    },
               ],
               customVariables: [
                    {
                         action: '',
                         id: '',
                         value: '',
                         existingValueAction: '',
                    },
               ],
               group: 'browser',
          });
          dispatch(setPreviewBrowserRule({ storageType: '', storageData: null }));
     };

     const getCurrentComponent = useCallback(() => {
          switch (activeStep) {
               case STEPS.Step1:
                    return <Step1 />;
               case STEPS.Step2:
                    return <Step2 />;
               case STEPS.Step3:
                    return <Step3 />;
               case STEPS.Step4:
                    return <Step4 />;
               default:
                    return <></>;
          }
     }, [activeStep]);

     const getStorageTypeName = (storageType) => {
          switch (storageType) {
               case BROWSER_STORAGE_TYPE.COOKIE:
                    return 'cookies';
               case BROWSER_STORAGE_TYPE.SESSION_STORAGE:
                    return 'session';
               case BROWSER_STORAGE_TYPE.LOCAL_STORAGE:
                    return 'local storage';
               default:
                    return '';
          }
     };
     const storageTypeName = useMemo(() => getStorageTypeName(storageType), [storageType]);

     return (
          <div className={`create-account-popup create-preview-rule cvr-create-new ${activeStep === STEPS.Step1 ? 'account-setup' : ''}`}>
               {storageType && storageData && activeListener && (
                    <RuleContext.Provider
                         value={{
                              activeStep,
                              setActiveStep,
                              stepsData,
                              setStepsData,
                              storageType,
                              storageTypeName,
                              storageData,
                              activeListener,
                              resetAllData,
                              updateBrowserVariables,
                         }}
                    >
                         <CModal visible={showPopup} onClose={closeModal} id='create-account-content' backdrop="static" portal={false}>
                              <div className='modal-body'>{getCurrentComponent()}</div>
                         </CModal>
                    </RuleContext.Provider>
               )}
          </div>
     );
};

export default CreatePreviewRule;
