import React from 'react';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';

const CreateNewRule = React.lazy(() => import('./CreateNewRule'));
const EditRule = React.lazy(() => import('./EditRule'));

const UserSourceRule = ({ id }) => {
     return (
          <div className='rule-detail'>
               <React.Suspense fallback={<CenterSpinner />}>
                    {
                         id ? <EditRule id={id} /> : <CreateNewRule />
                    }
               </ React.Suspense>
          </div>
     )
}

export default UserSourceRule
