import React from 'react';
import { useSelector } from 'react-redux';
import { VARIABLE_TABLE_LEVELS } from '../../../../../constants';

import { EventTableContext } from './EventTable';
import EventTableRow from './EventTableRow';

const EventTableChildren = ({ variables, level, listenerId, featureId, isParentAutomatic, isParentSelector, id }) => {
    const eventTableOfListeners = useSelector(state => state.subscriber.eventTableOfListeners);
    const { details = [], rowLoadingIds } = React.useContext(EventTableContext);

    return (
        <>
            {
                rowLoadingIds.includes(id) && level >= 1 ? (
                    <></>
                ) : (
                    <>
                        {
                            variables && variables.map((item) => {
                                let newListenerId = listenerId || item.listenerId;
                                let newFeatureId = level === 0 ? item.id : featureId;
                                let newItemId = item.id;
                                let newChilds = item.childs || item.variableValues;

                                // ID of Automatic variable for all features are the same so creates new id for them to toggle them correctly
                                if (level > 1) {
                                    newItemId = `${newFeatureId}-${item.id || item.variableValueId}`;
                                }

                                let count = (item.variableName === 'Custom Variables' || item.variableName === 'Selector Variables') ? '' : 0;
                                let countVisitors = item.eventName ? 0 : '';
                                let countSessions = item.eventName ? 0 : '';

                                let eventCanShowPlusIcon;
                                let selectorCanShowPlusIcon = true;
                                let automaticCanShowPlusIcon = true;
                                let featureCountData = eventTableOfListeners.countData[newFeatureId];

                                if (item.number) { // Variable value goes here
                                    count = item.number;
                                } else if (featureCountData) { // Automatic, custom and selector variable goes here
                                    if (level === 1) {
                                        if (item.variableName === 'Automatic Variables') {
                                            count = featureCountData.number;
                                        }
                                    } else {
                                        if (level === 0) {
                                            count = featureCountData.number;
                                            countVisitors = featureCountData.numberOfVisitors;
                                            countSessions = featureCountData.numberOfSessions;
                                        } else {
                                            count = featureCountData[item.id] ? featureCountData[item.id].number : 0;
                                        }
                                    }
                                }

                                if (item.variableName === 'Selector Variables') {
                                    selectorCanShowPlusIcon = Array.isArray(item.childs) && featureCountData;

                                    // if (Array.isArray(item.childs) && featureCountData) {
                                    //     // Show plus icon for "Selector Variable" if at lease 1 child count > 0
                                    //     selectorCanShowPlusIcon = item.childs.some(child => {
                                    //         let childCount = featureCountData[child.id] ? featureCountData[child.id].number : 0;

                                    //         return childCount > 0;
                                    //     })
                                    // } else {
                                    //     selectorCanShowPlusIcon = false;
                                    // }
                                }

                                if (item.variableName === 'Automatic Variables' || isParentAutomatic) {
                                    if (Array.isArray(item.childs) && featureCountData) {
                                        // Show plus icon for each automatic variable if at least 1 child count > 0 & child status is true
                                        automaticCanShowPlusIcon = count > 0 && item.childs.some(child => {
                                            // let childCount = featureCountData[child.id] ? featureCountData[child.id].number : 0;

                                            return child.status;
                                        })
                                    } else {
                                        automaticCanShowPlusIcon = false;
                                    }
                                }

                                if (level === 0 && item.id !== 'total' && count === 0) {
                                    eventCanShowPlusIcon = false;
                                }

                                // Check if match then hide this variable
                                if (
                                    // ((isParentAutomatic || isParentSelector) && count === 0) || // (parent is automatic variable or selector variable) and current variable's count === 0
                                    (item.type === 'automatic' && !item.status && level !== 1) // Current variable is automatic and its status is disabled from Variable Settings page
                                ) {
                                    return <></>;
                                }

                                return (
                                    <React.Fragment key={newItemId}>
                                        <div className={`${VARIABLE_TABLE_LEVELS[level]}-level-item ${item.status === false ? 'inactive css-table-row' : 'css-table-row'}`}>
                                            <EventTableRow
                                                currentVariable={item}
                                                level={level}
                                                count={count}
                                                countVisitors={countVisitors}
                                                countSessions={countSessions}
                                                id={newItemId}
                                                listenerId={newListenerId}
                                                featureId={newFeatureId}
                                                selectorCanShowPlusIcon={selectorCanShowPlusIcon}
                                                automaticCanShowPlusIcon={automaticCanShowPlusIcon}
                                                isParentAutomatic={isParentAutomatic}
                                                eventCanShowPlusIcon={eventCanShowPlusIcon}
                                            />
                                        </div>
                                        {
                                            newChilds && (
                                                <>
                                                    {
                                                        details.includes(newItemId) && (
                                                            <EventTableChildren
                                                                variables={newChilds}
                                                                level={level + 1}
                                                                listenerId={newListenerId}
                                                                featureId={newFeatureId}
                                                                isParentAutomatic={item.variableName === 'Automatic Variables' || isParentAutomatic}
                                                                isParentSelector={item.variableName === 'Selector Variables' || isParentSelector}
                                                                id={newItemId}
                                                            />
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </>
                )
            }
        </>
    )
}

export default EventTableChildren
