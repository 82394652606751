import { useContext, useEffect } from "react";

import { getSessionKeySignupCurrentStep } from "../../../../../utils";
import { CreateAccountContext } from "./CreateAccount";

const useSaveCurrentStep = () => {
     const { account, currentStep } = useContext(CreateAccountContext);

     const handleFirstLoad = () => {
          sessionStorage.setItem(getSessionKeySignupCurrentStep(account.id), currentStep);
     };

     useEffect(handleFirstLoad, []); // eslint-disable-line react-hooks/exhaustive-deps
}

export default useSaveCurrentStep;