import React from 'react';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';
import { generateId } from '../../../../../utils';

export const InnerTableSpinner = () => {
     return (
          <tbody className="table-spinner">
               <tr>
                    <td>
                         <CenterSpinner />
                    </td>
               </tr>
          </tbody>
     );
};

export const EDIT_FULL_LIST_TABLE_COLUMNS = [
     { id: 'domain', name: 'Domain', minWidth: '250px' },
     { id: 'companyName', name: 'Name', minWidth: '230px' },
     { id: 'profile', name: 'Profile', minWidth: '80px' },
     { id: 'bo', name: 'BO', minWidth: '120px', textAlign: 'center' },
     { id: 'matched_groups', name: 'Matched Groups', minWidth: '250px' },
     { id: 'revenue', name: 'Revenue', minWidth: '150px'},
     { id: 'employees', name: 'Total Employees', minWidth: '150px' },
     { id: 'primaryIndustry', name: 'Primary Industry', minWidth: '200px' },
     { id: 'productServiceTag', name: 'Product & Service Tags', minWidth: '250px' },
     { id: 'country', name: 'Country', minWidth: '200px' },
     { id: 'state', name: 'State', minWidth: '200px' },
     { id: 'businessType', name: 'Business Type', minWidth: '200px' },
     { id: 'monthlyVisitors', name: 'Monthly Visitors', minWidth: '200px' },
     { id: 'yearFounded', name: 'Year Founded', minWidth: '200px' },
];

export const DEFAULT_TEMP_DOMAINS = [{ id: generateId(), isTemp: true }]
export const DEFAULT_PAGE_SIZE = 10;
export const REGEX_DOMAIN = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;

export const FIND_ACCOUNT_CONDITION = [
     { value: 'ct', label: 'contains' },
     { value: 'nct', label: 'does not contain' },
     { value: 'eq', label: 'equals to' },
     { value: 'neq', label: 'not equal to' },
];

export const CHECK_LIST_CONDITION = { value: 'checklist', label: 'checklist' }

export const FIND_ACCOUNT_COMPANY_FIELD = [
     { key: 'add', label: 'Add' },
     { key: 'companyName', label: 'Company Name' },
     { key: 'domain', label: 'Domain' },
     { key: 'viewDetail', label: 'Profile' },
     { key: 'revenue', label: 'Revenue' },
     { key: 'employees', label: 'Total Employees' },
     { key: 'primaryIndustry', label: 'Primary Industry' },
     { key: 'productServiceTag', label: 'Products & Services Tags' },
     { key: 'country', label: 'Country' },
     { key: 'state', label: 'State' },
     { key: 'businessType', label: 'Business Type' },
     { key: 'monthlyVisitors', label: 'Monthly Visitors' },
     { key: 'yearFounded', label: 'Year Founded' },
];

export const FILTER_OPTIONS = [
     { value: 'domain', label: 'Domain' },
     { value: 'companyName', label: 'Company Name' },
     { value: 'revenue', label: 'Revenue' },
     { value: 'employees', label: 'Total Employees' },
     { value: 'primaryIndustry', label: 'Primary Industry' },
     { value: 'productServiceTag', label: 'Products & Services Tags' },
     { value: 'country', label: 'Country' },
     { value: 'state', label: 'State' },
     { value: 'businessType', label: 'Business Type' },
     { value: 'monthlyVisitors', label: 'Monthly Visitors' },
     { value: 'yearFounded', label: 'Year Founded' },
];

export const TARGET_ACCOUNT_GROUP_TYPE = {
     DYNAMIC_LIST: 'DYNAMIC LIST',
     LIST_OF_ACCOUNTS: 'LIST OF ACCOUNTS',
     BIG_OPPORTUNITIES: 'BIG OPPORTUNITIES',
};

export const MAPPING_COMPANY_DATA = [
     "companyName",
     "descriptionShort",
     "description",
     "yearFounded",
     "countryData",
     "countryShort",
     "region",
     "regionShort",
     "cityData",
     "city",
     "postal",
     "latitude",
     "longitude",
     "timeZoneId",
     "timeZoneName",
     "employees",
     "totalEmployeesExact",
     "revenue",
     "alexaRank",
     "monthlyVisitors",
     "businessType",
     "stockExchange",
     "stockSymbol",
     "primaryIndustry",
     "industries",
     "naicsCode",
     "naicsSector",
     "naicsSubsector",
     "naicsGroup",
     "naicsDesc",
     "sicCode",
     "sicGroup",
     "socialNetworks"
]

export const PERSONAL_MAIL = [
     'yahoo.com',
     'gmail.com',
     'comcast.com',
     'hotmail.com',
     'msn.com',
     'outlook.com',
     'aol.com',
     'icloud.com',
     'ymail.com',
     'protonmail.com',
     'zoho.com',
     'mail.com',
     'gmx.com',
     'inbox.com',
     'yandex.com',
     'me.com'
]

export const COMPANY_BLACKLIST = [
     ...PERSONAL_MAIL
];
