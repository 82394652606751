import React from 'react'
import ContentChild from './ContentChild';
import ContentChildLv2 from './ContentChildLV2';
import SidebarModal from './SidebarModal';

const FlexibleModal = () => {
     return (
          <SidebarModal componentLv1={<ContentChild />} componentLv2={<ContentChildLv2 />} />
     )
}

export default FlexibleModal;