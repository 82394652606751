import React from 'react';
import PropTypes from 'prop-types';
import { CInput, CSelect } from '../../migration/CInput';

const textFilterOptions = [
     { value: '', text: 'Select an option' },
     // { value: 'eq', text: 'is' },
     // { value: 'neq', text: 'is not' },
     { value: 'nli', text: `doesn't match` },
     { value: 'li', text: 'matches' }
]

const numberFilterOptions = [
     { value: '', text: 'Select an option' },
     { value: 'eq', text: 'is' },
     { value: 'neq', text: 'is not' },
     { value: 'lt', text: 'is less than' },
     { value: 'lte', text: 'is less than or equals to' },
     { value: 'gt', text: 'is greater than' },
     { value: 'gte', text: 'is greater than or equals to' },
]

const InputFilter = ({ type = 'text', filterData, onSelectOperatorChange, onInputFilterChange }) => {
     return (
          <>
               {
                    type === 'text' ? (
                         <CSelect
                              name={filterData.key}
                              className="mb-1"
                              onChange={onSelectOperatorChange}
                              value={filterData.operator}
                         >
                              {textFilterOptions.map(option => (
                                   <option key={option.text} className="text-dark" value={option.value}>{option.text}</option>
                              ))}
                         </CSelect>
                    ) : (
                              <CSelect
                                   name={filterData.key}
                                   className="mb-1"
                                   onChange={onSelectOperatorChange}
                                   value={filterData.operator}
                              >
                                   {numberFilterOptions.map(option => (
                                        <option key={option.text} className="text-dark" value={option.value}>{option.text}</option>
                                   ))}
                              </CSelect>
                         )
               }
               <CInput
                    name={filterData.key}
                    className="form-control-sm"
                    value={filterData.value}
                    onChange={e => onInputFilterChange(e.target.name, e.target.value)}
               />
          </>
     )
}

InputFilter.propTypes = {
     type: PropTypes.string,
     filterData: PropTypes.object,
     onSelectOperatorChange: PropTypes.func,
     onInputFilterChange: PropTypes.func
}

export default React.memo(InputFilter);
