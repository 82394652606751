import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { callTokenApi } from '../../../../apiCaller';
import { API_CLIENT_REPORTING_UTM_LIST, DEFAULT_DATE_FORMAT, REPORT_NAMES } from '../../../../constants';

const useUtmArray = (props) => {
     const { setUtmArray, dimensions, reportName } = props;
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);
     const [utmLoading, setUtmLoading] = useState(false);
     const newDateRange = {
          selection: {
               startDate: dateRangeReports.selection && dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT),
               endDate: dateRangeReports.selection && dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT),
          },
     };
     const bodyData = {
          accountId: activeAccount.id,
          dateRangeReports: newDateRange,
          dimensions,
     };
     const fetchData = () => {
          if (reportName === REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT) {
               setUtmLoading(true);
               callTokenApi(API_CLIENT_REPORTING_UTM_LIST, 'POST', bodyData)
                    .then((response) => {
                         if (response && response.status === 200) {
                              const data = response.data.utmList;
                              setUtmArray(data);
                         }
                    })
                    .finally(() => {
                         setUtmLoading(false);
                    });
          }
     };

     useEffect(fetchData, [dateRangeReports]); // eslint-disable-line react-hooks/exhaustive-deps

     return utmLoading;
};

export default useUtmArray;
