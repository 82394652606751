import React, { useCallback, useEffect, useState } from 'react';
import { CButton, CContainer, CModal } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useDispatch, useSelector } from 'react-redux';

import { CUSTOM_DESTINATION_MODAL_STEPS } from '../../../../../constants';
import Intro from './Intro';
import NameDestination from './NameDestination';
import { setCustomDestinationModal } from '../../../../../actions/subscriber';

export const CustomDestinationContext = React.createContext({});

export const CustomDestinationBody = ({ onBackBtnClicked, children, mainContentClassName = '' }) => {
     return (
          <CContainer className="main-container">
               <div className="d-flex align-items-center m-0 main-header">
                    <CButton
                         className={`btn-back m-0`}
                         onClick={onBackBtnClicked}
                    >
                         Back
                    </CButton>
                    <div className="hero-logo">
                         <CIcon icon="logo-dark" height={23} />
                    </div>
               </div>
               <div className={`main-content ${mainContentClassName}`}>
                    {children}
               </div>
          </CContainer>
     );
}

const CustomDestinationModal = () => {
     const dispatch = useDispatch();
     const customDestinationModal = useSelector((state) => state.subscriber.customDestinationModal);
     const [currentStep, setCurrentStep] = useState(customDestinationModal.initialStep || CUSTOM_DESTINATION_MODAL_STEPS.INTRO);
     const showPopup = customDestinationModal.show;

     const handleWhenHideAndShowPopup = () => {
          const popupShow = document.querySelector(".create-account-popup.custom-destination-modal .modal.show");

          if (showPopup) {
               setTimeout(() => {
                    document.body.classList.add('no-scroll');
                    if (popupShow) {
                         popupShow.classList.add('popup-transition');
                    }
               }, 0);
          } else {
               document.body.classList.remove('no-scroll');
          }
     };

     useEffect(handleWhenHideAndShowPopup, [showPopup]);

     const getCurrentComponent = useCallback(() => {
          switch (currentStep) {
               case CUSTOM_DESTINATION_MODAL_STEPS.INTRO:
                    return <Intro />;
               case CUSTOM_DESTINATION_MODAL_STEPS.NAME_DESTINATION:
                    return <NameDestination />;
               default:
                    return <></>;
          }
     }, [currentStep]);

     const closeModal = () => {
          dispatch(setCustomDestinationModal({ show: false }));
     }

     const contextValue = {
          currentStep, setCurrentStep,
          closeModal
     };

     return (
          <div className="create-account-popup custom-destination-modal">
               <CustomDestinationContext.Provider value={contextValue}>
                    <CModal visible={showPopup} onClose={closeModal} backdrop="static" portal={false}>
                         <div className="modal-body">
                              {getCurrentComponent()}
                         </div>
                    </CModal>
               </CustomDestinationContext.Provider>
          </div>
     );
};

// CustomDestinationModal.defaultProps = {
//      initialCurrentStep: CUSTOM_DESTINATION_MODAL_STEPS.INTRO,
//      isUpdating: false
// };

export default CustomDestinationModal;
