import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { COMPONENT_NAME } from '../../../constants';
import Listeners from '../../../components/cms/subscriber/listeners/Listeners';
import DataActionRule from '../../../components/cms/subscriber/destination-settings/dataActions/DataActionRule';
import EditLookupRule from '../../../components/cms/subscriber/listener-settings-v2/custom-data-layer-variables/EditLookupSetting';
import EditLookupTable from '../../../components/cms/subscriber/listener-settings-v2/custom-data-layer-variables/EditLookupTable';
import CustomDataLayerRule from '../../../components/cms/subscriber/listener-settings-v2/custom-data-layer-variables/CustomDataLayerRule';
import ClickRule from '../../../components/cms/subscriber/listener-settings-v2/click-settings/ClickRule';
import EditClickLookupTable from '../../../components/cms/subscriber/listener-settings-v2/click-settings/lookup/EditLookupTable';
import UserSourceRule from '../../../components/cms/subscriber/listener-settings-v2/user-source-rules/UserSourceRule';
import EditUserSourceSetting from '../../../components/cms/subscriber/listener-settings-v2/user-source-rules/EditLookupCustom';
import EditUserSourceTable from '../../../components/cms/subscriber/listener-settings-v2/user-source-rules/EditLookupCustomTable';
import TriggerLookupTable from '../../../components/cms/subscriber/trigger-conversions/triggers/EditLookupTable';
import TriggerLookupSetting from '../../../components/cms/subscriber/trigger-conversions/triggers/EditLookupSetting';
import ConversionLookupTable from '../../../components/cms/subscriber/trigger-conversions/conversions/EditLookupTable';
import ConversionLookupSetting from '../../../components/cms/subscriber/trigger-conversions/conversions/EditLookupSetting';
import EditFormRuleSetting from '../../../components/cms/subscriber/listener-settings-v2/custom-form-categories/form-categories-manage/form-rule-edit/EditFormRuleSetting';
import EditFormRuleTable from '../../../components/cms/subscriber/listener-settings-v2/custom-form-categories/form-categories-manage/form-rule-edit/EditFormRuleTable';

const ContentChildLv2 = () => {
     const { componentLv2, ruleIdLv2 } = useSelector((state) => state.theme.flexibleModal);

     const getComponentFlexibleModal = useCallback(() => {
          switch (componentLv2) {
               case COMPONENT_NAME.LISTENER_LIBRARY:
                    return <Listeners />;
               case COMPONENT_NAME.DATA_ACTION:
                    return <DataActionRule id={ruleIdLv2} />;
               case COMPONENT_NAME.CUSTOM_DATA_LAYER_RULE:
                    return <CustomDataLayerRule id={ruleIdLv2} />;
               case COMPONENT_NAME.CUSTOM_DATA_LOOKUP_SETTING:
                    return <EditLookupRule id={ruleIdLv2} />;
               case COMPONENT_NAME.CUSTOM_DATA_LOOKUP_TABLE:
                    return <EditLookupTable id={ruleIdLv2} />;
               case COMPONENT_NAME.CLICK_SETTING_RULE:
                    return <ClickRule id={ruleIdLv2} />;
               case COMPONENT_NAME.CLICK_LOOKUP_TABLE:
                    return <EditClickLookupTable id={ruleIdLv2} />;
               case COMPONENT_NAME.USER_SOURCE_RULE:
                    return <UserSourceRule id={ruleIdLv2} />;
               case COMPONENT_NAME.USER_SOURCE_RULE_CUSTOM_SETTING:
                    return <EditUserSourceSetting id={ruleIdLv2} />;
               case COMPONENT_NAME.USER_SOURCE_RULE_CUSTOM_TABLE:
                    return <EditUserSourceTable id={ruleIdLv2} />;
               case COMPONENT_NAME.TRIGGER_LOOKUP_TABLE:
                    return <TriggerLookupTable id={ruleIdLv2} />;
               case COMPONENT_NAME.TRIGGER_LOOKUP_SETTING:
                    return <TriggerLookupSetting id={ruleIdLv2} />;
               case COMPONENT_NAME.CONVERSION_LOOKUP_TABLE:
                    return <ConversionLookupTable id={ruleIdLv2} />;
               case COMPONENT_NAME.CONVERSION_LOOKUP_SETTING:
                    return <ConversionLookupSetting id={ruleIdLv2} />;
               case COMPONENT_NAME.FORM_RULE_SETTING:
                    return <EditFormRuleSetting id={ruleIdLv2} />;
               case COMPONENT_NAME.FORM_RULE_TABLE:
                    return <EditFormRuleTable id={ruleIdLv2} />;
               default:
                    return <></>;
          }
     }, [componentLv2, ruleIdLv2]);

     return <>{getComponentFlexibleModal()}</>;
};
export default ContentChildLv2;
