import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllRulesRequest } from '../../../../../../actions/subscriber';
import { RULE_GROUP } from '../../../../../../constants';
import CreateNewFormRule from './form-rule-create/CreateNewFormRule';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';

const FormRuleDetail = ({ id }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const formCategoriesRules = useSelector((state) => state.subscriber.formCategoriesRules);

     useEffect(() => {
          if (!formCategoriesRules) {
               dispatch(fetchAllRulesRequest(activeAccount.id, RULE_GROUP.FORM_CATEGORIES));
          }
     }, [formCategoriesRules]); // eslint-disable-line react-hooks/exhaustive-deps

     return (
          <React.Suspense fallback={<CenterSpinner />}>
               {id ? (
                    <></>
               ) : (
                    <>
                         <CreateNewFormRule />
                    </>
               )}
          </React.Suspense>
     );
};

export default FormRuleDetail;
