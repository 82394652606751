import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_RECORD_PROFILE_EVENT, COMPONENT_NAME } from '../../../../../constants';
import { callTokenApi } from '../../../../../apiCaller';
import { toastError } from '../../../../../utils';
import { CCollapse } from '@coreui/react';
import { setFlexibleMultiModal } from '../../../../../actions/common';
import { formatNumber } from '../../../../../utils/UtilReport';

const RecordProfileEvent = ({ id }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const [initialValue, setInitialValue] = useState(null);
     const [isLoading, setIsLoading] = useState(true);
     const data = { id, accountId: activeAccount.id };
     const [defaultActive, setDefaultActive] = useState(true);

     useEffect(() => {
          callTokenApi(API_RECORD_PROFILE_EVENT, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         setInitialValue(response.data.newDataEvent);
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleNavigateSession = (id) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: id },
                    component: COMPONENT_NAME.RECORD_PROFILE_SESSION,
               })
          );
     };

     const handleNavigateUser = (id) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: id },
                    component: COMPONENT_NAME.RECORD_PROFILE_USER,
               })
          );
     };

     const handleNavigatePerson = (id) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: id },
                    component: COMPONENT_NAME.RECORD_PROFILE_PERSON,
               })
          );
     };

     const handleNavigateCompany = (id) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: id },
                    component: COMPONENT_NAME.RECORD_PROFILE_COMPANY,
               })
          );
     };

     return (
          <>
               {isLoading ? (
                    <div className='record-profile loading-profile'>
                         <h1>Event Details</h1>
                         <div className='record-profile-info'>
                              <p className='event-profile-session'>Event ID: </p>
                              <p className='event-profile-session'>Date: </p>
                              <p className='event-profile-session'>
                                   Session: <span className='link-data'></span>
                              </p>
                              <p className='event-profile-session'>
                                   User: <span className='link-data'></span>
                              </p>
                              <p className='event-profile-session'>
                                   Person: <span className='link-data'></span>
                              </p>
                              <p className='event-profile-session'>
                                   Revealed Company: <span className='link-data'></span>
                              </p>
                         </div>
                         <div className='accordion'>
                              <div className={`accordion-item${defaultActive ? ' show' : ''}`}>
                                   <button className={`accordion-header consent${defaultActive ? ' show' : ''}`} onClick={() => setDefaultActive(!defaultActive)}>
                                        <p className='title'>Other Details</p>
                                   </button>
                                   <CCollapse visible={defaultActive}>
                                        <div className='accordion-body event-profile-session'>
                                             {defaultActive && (
                                                  <>
                                                       <h4>Other Details</h4>
                                                       <p>Coming Soon!</p>
                                                  </>
                                             )}
                                        </div>
                                   </CCollapse>
                              </div>
                         </div>
                    </div>
               ) : (
                    <div className='record-profile'>
                         <h1>Event Details</h1>
                         {initialValue && (
                              <div className='record-profile-info'>
                                   <p>Event ID: {initialValue.eventId}</p>
                                   <p>Date: {initialValue.date}</p>
                                   <p>
                                        Session:{' '}
                                        <span className='link-data' onClick={() => handleNavigateSession(initialValue.session)}>
                                             {formatNumber(initialValue.session)}
                                        </span>
                                   </p>
                                   <p>
                                        User:{' '}
                                        <span className='link-data' onClick={() => handleNavigateUser(initialValue.user)}>
                                             {formatNumber(initialValue.user)}
                                        </span>
                                   </p>
                                   {initialValue.personId ? (
                                        <>
                                             {initialValue.email && initialValue.email !== '' ? (
                                                  <p>
                                                       Person:{' '}
                                                       <span className='link-data' onClick={() => handleNavigatePerson(initialValue.personId)}>
                                                            {initialValue.email}
                                                       </span>
                                                  </p>
                                             ) : (
                                                  <p>
                                                       Person:{' '}
                                                       <span className='link-data' onClick={() => handleNavigatePerson(initialValue.personId)}>
                                                            {initialValue.externalId && initialValue.externalId !== '' ?  formatNumber(initialValue.externalId) : initialValue.personId}
                                                       </span>
                                                  </p>     
                                             )}
                                        </>
                                   ) : (
                                        <p>
                                             Person:{' '}
                                        </p>
                                   )}

                                   <p>
                                        Revealed Company:{' '}
                                        {initialValue.companyList.map((item, index) => {
                                             return (
                                                  <span key={index} className='link-data' onClick={() => handleNavigateCompany(item.companyId)}>
                                                       {index < initialValue.companyList.length - 1 ? <>{item.companyName} ,&nbsp;</> : <>{item.companyName}</>}
                                                  </span>
                                             );
                                        })}
                                   </p>
                              </div>
                         )}
                         <div className='accordion'>
                              <div className={`accordion-item${defaultActive ? ' show' : ''}`}>
                                   <button className={`accordion-header consent${defaultActive ? ' show' : ''}`} onClick={() => setDefaultActive(!defaultActive)}>
                                        <p className='title'>Other Details</p>
                                   </button>
                                   <CCollapse visible={defaultActive}>
                                        <div className='accordion-body'>
                                             {defaultActive && (
                                                  <>
                                                       <h4>Other Details</h4>
                                                       <p>Coming Soon!</p>
                                                  </>
                                             )}
                                        </div>
                                   </CCollapse>
                              </div>
                         </div>
                    </div>
               )}
          </>
     );
};

export default RecordProfileEvent;
