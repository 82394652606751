import { callTokenApi } from '../../../../../../apiCaller';
import { CLIENT_GET_ACCOUNT_GROUP } from '../../../../../../constants';

const CLIENT_TARGET_ACCOUNT = 'client/target-account';

export const TargetAccountAPI = {
     getAll: async (payload) => {
          const { accountId, groupId, pagination, searchObject, setDomains } = payload;
          const data ={
               page: pagination.page,
               pageSize: pagination.pageSize,
               accountId, 
               groupId,
               searchObject
          }
          return await callTokenApi(`${CLIENT_TARGET_ACCOUNT}/full-list`, 'POST', data).then((response) => {
               if (response.status !== 200) return;
               if (typeof setDomains === 'function') {
                    setDomains(response.data.currentDomains)
               }
               return response.data.data;
          });
     },
     getAllExport: async (payload) => {
          const { accountId, groupId, searchObject } = payload;
          const data ={
               page: 1,
               pageSize: 'all',
               accountId, 
               groupId,
               searchObject
          }

          return await callTokenApi(`${CLIENT_TARGET_ACCOUNT}/full-list`, 'POST', data).then((response) => {
               if (response.status !== 200) return;
               return response.data.data;
          });
     },
     update: async (payload) => {
          return await callTokenApi(CLIENT_TARGET_ACCOUNT, 'PUT', payload).then((response) => {
               if (response.status !== 200) return;
               return response.data.data;
          });
     },
     delete: async (payload) => {
          return await callTokenApi(CLIENT_TARGET_ACCOUNT, 'DELETE', payload).then((response) => {
               if (response.status !== 200) return;
               return true;
          });
     },
     deleteTargetAccountGroups: async (payload) => {
          return await callTokenApi(CLIENT_GET_ACCOUNT_GROUP, 'DELETE', payload).then((response) => {
               if (response.status !== 200) return;
               return true;
          });
     },
     findDomains: async (payload) => {
          return await callTokenApi(`${CLIENT_TARGET_ACCOUNT}/get-list-domain`, 'POST', payload).then((response) => {
               if (response.status !== 200) return;
               return response.data.data;
          });
     },
};
