import React, { useContext, useEffect, useState } from 'react';
import { CButton, CForm, CCard, CCardBody } from '@coreui/react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";

import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from './CreateAccount';
import { getTimeZoneOffset, toastError } from '../../../../../utils';
import timeZonesByCountriesJSON from '../../../../../assets/countries.json';
import SelectSearchField from '../../../../general/custom-search-select/SelectSearchField';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';
import { callTokenApi, makeRequest } from '../../../../../apiCaller';
import { API_CLIENT_ACCOUNT_INFO, API_CLIENT_LISTENERS, API_SSL_GEOPLUGIN_JSON, CLIENT_ADD_ACCOUNT, SCHEDULE_DEMO } from '../../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import Waiting from '../../../../general/Loadings/Waiting';
import { enableManyListeners, resetPreview, setAccounts } from '../../../../../actions/subscriber';
import { setPackageToAccount } from '../../../../../helpers/cms/subscriber';
import useSaveCurrentStep from './useSaveCurrentStep';
import { getDefaultListeners } from './listener-configuration/ConfigureMeasurement';
import CFormGroup from '../../../../migration/CFormGroup';

const validationSchema = Yup.object().shape({
    timeZone: Yup.string().required('Time zone is required'),
});

const useInitialValues = ({ timeZonesByCountries, getTimeZonesWithOffset }) => {
    const { stepsData, setStepsData } = useContext(CreateAccountContext);
    const [isLoading, setIsLoading] = useState(true);
    const [initialValues, setInitialValues] = useState();

    const getTimeZoneObj = (timeZone) => {
        const foundCountryTimeZone = timeZonesByCountries.find(country => country.timeZones.find(tz => tz === timeZone));

        if (foundCountryTimeZone) {
            const timeZonesWithOffset = getTimeZonesWithOffset(foundCountryTimeZone.timeZones);

            return {
                country: foundCountryTimeZone.value,
                countryTimeZones: timeZonesWithOffset,
                timeZone,
                timeZoneLabel: timeZonesWithOffset.find(el => el.value === timeZone).label
            };
        }

        return false;
    }

    const firstLoad = () => {
        const handleInitialValues = async () => {
            let newData = {
                country: stepsData.country,
                countryTimeZones: stepsData.countryTimeZones,
                timeZone: stepsData.timeZone,
                timeZoneLabel: stepsData.timeZoneLabel
            };

            if (stepsData.timeZone) {
                if (!stepsData.timeZoneLabel) {
                    const timeZoneObj = getTimeZoneObj(stepsData.timeZone);

                    if (timeZoneObj) {
                        newData = timeZoneObj;
                        setStepsData(newData);
                    }
                }
            } else {
                // Guess user time zone and populate it to time zone select element
                const geopluginRes = await makeRequest(API_SSL_GEOPLUGIN_JSON.replace('{{key}}', '1c0852809cd03846'), 'GET', null);

                if (geopluginRes && geopluginRes.status === 200) {
                    const { geoplugin_timezone: clientTimeZone } = geopluginRes.data;
                    const timeZoneObj = getTimeZoneObj(clientTimeZone);

                    if (timeZoneObj) {
                        newData = timeZoneObj;
                        setStepsData(newData);
                    }
                }
            }

            setInitialValues(newData);
            setIsLoading(false);
        }

        handleInitialValues();
    }

    useEffect(firstLoad, []); // eslint-disable-line react-hooks/exhaustive-deps


    return { isLoading, initialValues };
}

const TimeZone = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { account, setAccount, stepsData, setStepsData, setCurrentStep, updateAccountCompletedSteps } = useContext(CreateAccountContext);
    const packages = useSelector(state => state.subscriber.packages);
    const accounts = useSelector(state => state.subscriber.accounts);
    const accountListeners = useSelector(state => state.subscriber.accountListeners);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    const timeZonesByCountries = timeZonesByCountriesJSON.map((timezoneCountry) => ({
        timeZones: timezoneCountry.timezones,
        value: timezoneCountry.name,
        label: timezoneCountry.name,
    }));

    const getTimeZonesWithOffset = (timeZones) => {
        return timeZones.map((timeZone) => ({ value: timeZone, label: `${timeZone} (${getTimeZoneOffset(timeZone)})` }));
    };

    const { isLoading, initialValues } = useInitialValues({ timeZonesByCountries, getTimeZonesWithOffset });

    const onBackBtnClicked = () => {
        setCurrentStep(null, CHANGE_STEP_TYPE.mainStepPrev);
    };

    const goToNextStep = () => {
        updateAccountCompletedSteps(STEPS.timeZone);
        setCurrentStep(null, CHANGE_STEP_TYPE.mainStepNext);
    };

    const updateTimeZone = ({ country, countryTimeZones, timeZone, timeZoneLabel }) => {
        setIsFormSubmitting(true);

        callTokenApi(API_CLIENT_ACCOUNT_INFO, 'PUT', { accountId: account.id, timeZone })
            .then(response => {
                setIsFormSubmitting(false);
                localStorage.removeItem(SCHEDULE_DEMO)
                if (response.status === 200) {
                    setAccount({ ...account, timeZone });
                    setStepsData({ country, countryTimeZones, timeZone, timeZoneLabel });
                    goToNextStep();
                } else {
                    toastError(response);
                }
            });
    };

    const enableDefaultListeners = async (accountId) => {
        let listeners = stepsData.listeners || accountListeners;

        if (!listeners || (Array.isArray(listeners) && listeners.length === 0)) {
            await callTokenApi(API_CLIENT_LISTENERS, 'GET', null)
                .then(response => {
                    if (response.status === 200) {
                        listeners = response.data.listeners;
                    } else {
                        toastError(response);
                    }
                })
        }

        let defaultListeners = getDefaultListeners(listeners);
        const listenerIds = defaultListeners.map(item => item.id);

        dispatch(enableManyListeners({ accountId, listenerIds }));
    }

    const addAccount = ({ country, countryTimeZones, timeZone, timeZoneLabel }) => {
        const freePackage = packages.find(pk => pk.pricePerMonth === 0);

        const data = {
            name: stepsData.name,
            timeZone,
            packageId: freePackage.id,
        }

        setIsFormSubmitting(true);
        callTokenApi(CLIENT_ADD_ACCOUNT, 'POST', data)
            .then((response) => {
                setIsFormSubmitting(false);

                if (response.status === 200) {
                    const { account: resAcc } = response.data;
                    let newAccount = setPackageToAccount(resAcc, freePackage, true);
                    newAccount.accountId = resAcc.id;
                    let newAccounts = [...accounts, newAccount]; // Add new account to the end

                    enableDefaultListeners(resAcc.id);
                    localStorage.setItem('accountCreatedId', resAcc.id);
                    window._blockUpgradeDowngradePusher = true;
                    dispatch(setAccounts(newAccounts));
                    setAccount(newAccount);
                    setStepsData({ country, countryTimeZones, timeZone, timeZoneLabel });
                    history.push(`/${resAcc.secondId}`)
                    goToNextStep();
                    dispatch(resetPreview());
                } else {
                    toastError(response);
                }
            });
    }

    const onSubmit = (values) => {
        if (account.id) {
            if (account.timeZone === values.timeZone) {
                goToNextStep();
            } else {
                updateTimeZone(values);
            }
        } else {
            addAccount(values);
            localStorage.removeItem('LeaveStepCustomForms');
        }
    };

    const onChangeCountry = (e, setValues, values) => {
        if (values.country !== e.value) {
            setValues({
                country: e.value,
                countryTimeZones: getTimeZonesWithOffset(e.timeZones),
                timeZone: '',
                timeZoneLabel: '',
            });
        }
    };

    const onChangeTimeZone = (e, setValues, values) => {

        setValues({
            ...values,
            timeZone: e.value,
            timeZoneLabel: e.label,
        });
    };

    useSaveCurrentStep();

    return (
        <CreateAccountBody onBackBtnClicked={onBackBtnClicked} backBtnDisabled={isFormSubmitting}>
            <CCard className="account-time-zone">
                <p className="number-of-step">1 of 2 answered</p>
                <CCardBody>
                    {isLoading ? (
                        <CenterSpinner />
                    ) : (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            validateOnChange={false}
                            validateOnBlur={false}
                        >
                            {({ values, handleSubmit, setValues, isSubmitting }) => {
                                return (
                                    <CForm onSubmit={handleSubmit}>
                                        <CFormGroup>
                                            <h3>Select your time zone</h3>
                                            <p>This makes your data consistent.</p>
                                            <div className="select-group">
                                                <SelectSearchField
                                                    id="country"
                                                    placeholder="Select Country"
                                                    onChange={(e) => onChangeCountry(e, setValues, values)}
                                                    options={timeZonesByCountries}
                                                    value={values.country ? { value: values.country, label: values.country } : null}
                                                    className="select-react"
                                                    classNamePrefix="custom-select"
                                                />
                                                <SelectSearchField
                                                    id="timeZone"
                                                    placeholder="Select Time Zone"
                                                    onChange={(e) => onChangeTimeZone(e, setValues, values)}
                                                    options={values.countryTimeZones}
                                                    isDisabled={!values.country}
                                                    value={values.timeZoneLabel ? { value: values.timeZoneLabel, label: values.timeZoneLabel } : null}
                                                    displayPlaceholder={!values.timeZoneLabel}
                                                    className="select-react"
                                                    classNamePrefix="custom-select"
                                                    isBlur={!values.timeZoneLabel}
                                                />
                                            </div>

                                            <CButton className="btn-save" type="submit" disabled={isSubmitting || !values.timeZone}>
                                                <Waiting isLoading={isSubmitting}>NEXT</Waiting>
                                            </CButton>
                                        </CFormGroup>
                                    </CForm>
                                );
                            }}
                        </Formik>
                    )}
                </CCardBody>
            </CCard>
        </CreateAccountBody>
    );
};

export default TimeZone;
