import React, { useState } from 'react';
import { filterObjectValuesNotNull } from '../../../../../utils';
import Tippy from '@tippyjs/react';
import CIcon from '@coreui/icons-react';
import { debounce } from 'lodash';

const Tag = ({ item, onClick, tagUserSourceDetail }) => {
     return (
          <span
               className={`tag d-flex flex-wrap align-items-center ${typeof onClick !== 'function' && !tagUserSourceDetail ? 'no-click' : ''}`}
               onClick={onClick}
          >
               {item}
          </span>
     );
};

function transformObject(obj) {
     const result = {};

     for (const key in obj) {
          if (key !== 'name' && key !== 'createdAt') {
               const value = obj[key];

               if (Array.isArray(value)) {
                    value.forEach((nestedObj) => {
                         const nestedKey = Object.keys(nestedObj)[0];
                         const nestedValue = nestedObj[nestedKey];
                         result[nestedKey] = nestedValue;
                    });
               } else {
                    result[key] = value;
               }
          }
     }

     return result;
}

const changeNameKeyDetailUserSource = {
     referrer: 'Referrer:',
     landingPage: 'Landing Page:',
     channel: 'Channel:',
};

const renderTooltipUserSource = (item, key) => {
     const handleTrigger = (instance, event) => {
          const listTooltip = document.querySelectorAll('[id^="tippy-"]');
          for (let i = 0; i < listTooltip.length; i++) {
               if (instance.id !== listTooltip[i]._tippy.id) {
                    listTooltip[i].style.display = 'none';
               } else {
                    listTooltip[i].style.display = 'block';
               }
          }
     };

     return (
          <div className="tags-wrapper" key={key}>
               <Tippy
                    content={
                         <div className="tooltip-content">
                              <table>
                                   <tbody>
                                        {Object.keys(item).map(
                                             (key) =>
                                                  key !== 'name' &&
                                                  key !== 'createdAt' &&
                                                  (key !== 'sourceUTMs' && key !== 'clickIds' ? (
                                                       item[key] !== null && item[key] !== undefined ? (
                                                            <tr key={`${key}`}>
                                                                 <td className="title">
                                                                      {changeNameKeyDetailUserSource[key]
                                                                           ? changeNameKeyDetailUserSource[key]
                                                                           : key + ':'}
                                                                      &nbsp;
                                                                 </td>
                                                                 <td className="content">
                                                                      {`${item[key]}`}
                                                                      {key === 'landingPage' || key === 'referrer' ? (
                                                                           <a
                                                                                href={item[key]}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                style={{ paddingLeft: '10px' }}
                                                                           >
                                                                                <CIcon icon="icon-open-website-white" size="sm" />
                                                                           </a>
                                                                      ) : (
                                                                           ''
                                                                      )}
                                                                 </td>
                                                            </tr>
                                                       ) : null
                                                  ) : key === 'sourceUTMs' || key === 'clickIds' ? (
                                                       item[key] !== null && item[key].length > 0 ? (
                                                            item[key].map((i, index) => {
                                                                 return (
                                                                      i['value'] !== null && (
                                                                           <tr key={`${key}${index}`}>
                                                                                {Object.keys(i).map((key, keyIndex) => {
                                                                                     return keyIndex ? (
                                                                                          <td
                                                                                               key={`${key}${index}`}
                                                                                               className="content"
                                                                                          >{`${i[key]}`}</td>
                                                                                     ) : (
                                                                                          <td key={`${key}${index}`} className="title">
                                                                                               {i[key] + ':'}&nbsp;
                                                                                          </td>
                                                                                     );
                                                                                })}
                                                                           </tr>
                                                                      )
                                                                 );
                                                            })
                                                       ) : null
                                                  ) : item[key] !== null ? (
                                                       <tr key={`${key}`}>
                                                            {Object.keys(item[key]).map((i, index) => {
                                                                 return index ? (
                                                                      <td key={`${key}${index}`} className="content">{`${item[key][i]}`}</td>
                                                                 ) : (
                                                                      <td key={`${key}${index}`} className="title">
                                                                           {item[key][i] + ':'}&nbsp;
                                                                      </td>
                                                                 );
                                                            })}
                                                       </tr>
                                                  ) : null)
                                        )}
                                   </tbody>
                              </table>
                         </div>
                    }
                    placement="top"
                    maxWidth="850px"
                    theme="user-source"
                    hideOnClick="false"
                    delay={[200, null]}
                    interactive="true"
                    appendTo={() => document.body}
                    onTrigger={(instance, event) => handleTrigger(instance, event)}
               >
                    <span>
                         <Tag item={item.name} tagUserSourceDetail={true} />
                    </span>
               </Tippy>
          </div>
     );
};

const renderTagEmptyDetail = (label) => {
     return (
          <div className="tags-wrapper">
               <span>
                    <Tag item={label} />
               </span>
          </div>
     );
};

export const UserSourceDetails = ({ data }) => {
     if (data === null || data.length === 0 || typeof data === 'string') {
          return (
               <div className={`view-detail list-tags`} tabIndex={0}>
                    <span>{!data || data.length === 0 ? 'none' : data}</span>
               </div>
          );
     }

     return (
          <div className={`view-detail list-tags`} tabIndex={0}>
               {
                    <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                         {data && data.length
                              ? data.map((item, key) => {
                                   const newItem = filterObjectValuesNotNull(item);
                                   return Object.keys(newItem).length ? renderTooltipUserSource(newItem, key) : renderTagEmptyDetail(item.name);
                              })
                              : !Object.keys(transformObject(filterObjectValuesNotNull(data))).length
                                   ? renderTagEmptyDetail(data.name)
                                   : renderTooltipUserSource(data)}
                    </div>
               }
          </div>
     );
};

export const UserSourcePathDetails = ({ data }) => {
     const [page, setPage] = useState(1)
     if (data === null || data.length === 0 || typeof data === 'string') {
          return (
               <div className={`view-detail list-tags`} tabIndex={0}>
                    <span>{!data || data.length === 0 ? 'none' : data}</span>
               </div>
          );
     }
     let newData = []
     let limit = 60;
     const pathLoad = [];
     const totalPages = Math.ceil(Math.floor(data.length / limit)) || 0;

     if (data.length > 0) {
          for (let i = 0; i < data.length / limit; i++) {
               const start = i * limit;
               const end = start + limit;
               newData.push({ page: i, data: data.slice(start, end) });
          }
     }

     if (newData.length > 0) {
          for (let i = 0; i <= page; i++) {
               if (newData && newData[i] && newData[i].data) {
                    pathLoad.push(...newData[i].data);
               }
          }
     }

     const handleScrollTable = (event) => {
          const { scrollTop, scrollHeight, clientHeight } = event;
          if (scrollTop + 20 >= scrollHeight - clientHeight) {
               if (page < totalPages) {
                    setPage((activePageOld) => ++activePageOld);
               }
          }
     };

     const debouncedHandleScrollTable = debounce((event) => {
          handleScrollTable(event);
     }, 100);


     const removeElementTooltipScroll = () => {
          if ('ontouchstart' in window || navigator.maxTouchPoints) {
               const el = document.querySelector('[id^="tippy-"]');
               if (el) {
                    el.remove();
               }
          }
     };

     return (
          <div className="description source-path"
               onScroll={(event) => {
                    removeElementTooltipScroll();
                    const { scrollTop, scrollHeight, clientHeight } = event.target;
                    debouncedHandleScrollTable({ scrollTop, scrollHeight, clientHeight })
               }}
          >
               <div className={`view-detail list-tags`} tabIndex={0}>
                    {
                         <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                              {pathLoad && pathLoad.length > 0
                                   ? pathLoad.map((item, key) => {
                                        const newItem = filterObjectValuesNotNull(item);
                                        return Object.keys(newItem).length ? renderTooltipUserSource(newItem, key) : renderTagEmptyDetail(item.name);
                                   })
                                   : renderTagEmptyDetail(data.name)}
                         </div>
                    }
               </div>
          </div>
     );
};