import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'; // useSelector & useDispatch can only be used in functional component

import { AsideContext } from '../TheAside';
import { roundTo2DecimalPlaces } from '../../../../utils';
import { useIsLatestPlan } from '../../../../helpers/customHooks';
import LegacyPlanRecommendation from './LegacyPlanRecommendation';

const UpgradePopulate = () => {
     const { setPackageSelected } = useContext(AsideContext);
     const packages = useSelector(state => state.subscriber.packages);
     const packagePopulate = packages.find(packageItem => packageItem.eventPopulateFields);
     const isLatestPlan = useIsLatestPlan();

     useEffect(() => {
          setPackageSelected(packagePopulate);
     }, [setPackageSelected, packagePopulate])

     return (
          <>
               <div className="description">
                    <p className="text-center fw-4">
                         Upgrade your account to gain access to detailed event.{" "}
                         Build populate fields for specific listeners and view data and charts from your website activity over time.
                    </p>
               </div>
               <div className="sidebar-body">
                    <h6 className="text-success text-center recommendation">Recommendation</h6>
                    {
                         isLatestPlan ? (
                              <p className="text-center recommendation-text fw-4">
                                   Upgrade to the {packagePopulate.friendlyName} plan for ${roundTo2DecimalPlaces(packagePopulate.pricePerYear / 12).toLocaleString('en-US')}/month (paid annually) and gain access to unlimited populate fields, along with other great features!
                              </p>
                         ) : <LegacyPlanRecommendation />
                    }
               </div>
          </>
     )
}

export default UpgradePopulate;