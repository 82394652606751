import React, { useContext } from "react";
import CIcon from "@coreui/icons-react";
import {
    SupportTicketPopupContext,
    GETTING_LISTEN_LAYER,
    CREATE_SUPPORT_TICKET
} from "../GetHelpLabel";
import { INSTALLATION_OVERVIEW, STEP_PREVIOUS_CREATE_TICKET } from "../../../../../constants";

import TicketVideoIframe from "../TicketVideoIframe";

const GettingListenLayer2 = () => {
    const { setCurrentStep } = useContext(SupportTicketPopupContext);

    const dataVideoIframe = {
        title: `We're Here to Help!`,
        description: `Please watch the installation overview video. If you are
        having trouble implementing ListenLayer, it is probably due
        to a common problem that is easy to identify and resolve.`,
        urlIframe: INSTALLATION_OVERVIEW
    }

    return (
        <div className="ticket-popup-content ticket-video-content">
            <div className="btn-back-ticket">
                <CIcon icon="icon-chevron-left" width={6} />
                <span onClick={() => setCurrentStep(GETTING_LISTEN_LAYER.step1)}>
                    Back
                </span>
            </div>
            <TicketVideoIframe
                title={dataVideoIframe.title}
                description={dataVideoIframe.description}
                urlIframe={dataVideoIframe.urlIframe}
                buttonVideo={() => {
                    setCurrentStep(CREATE_SUPPORT_TICKET.step1)
                    localStorage.setItem(STEP_PREVIOUS_CREATE_TICKET, GETTING_LISTEN_LAYER.step2)
                }}
            />
        </div>
    );
};

export default GettingListenLayer2;
