import React from 'react';
import Tippy from '@tippyjs/react';
import CIcon from '@coreui/icons-react';
import { CProgress } from '@coreui/react';

export const LoadingPanel = () => (
     <div className="k-loading-mask">
          <span className="k-loading-text">Loading</span>
          {/* <div className='k-loading-image'></div> */}
          <div className="k-loading-color"></div>
     </div>
);

export const LongLoading = () => (
     <div className="contentTooltipLongLoading">
          <div
               style={{
                    width: 'max-content',
                    padding: '10px 20px',
                    background: '#f0f3f5',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    borderRadius: '10px',
               }}
          >
               <span>
                    Thank you for being patient. Your results will appear soon.
                    <Tippy
                         className="loadingTableTooltip"
                         content={
                              <div className="tooltip-content">
                                   <div>
                                        {
                                             'We do not use statistical sampling to optimize our queries, so the results you will see are true and accurate. Once you run a report, the results will be cached, making it easier to navigate around. Our team is also hard at work optimizing our reporting platform for speed.'
                                        }
                                   </div>
                              </div>
                         }
                         placement="left-end"
                         maxWidth="640px"
                         offset={[1, 4]}
                         arrow={
                              '<svg viewBox="0 0 6 13"><g id="b671136e-83cf-4fb9-b98e-be51e54c953e" data-name="Layer 2"><g id="b5fae9b2-5e0b-421a-8a35-db7330bc1b57" data-name="Layer 1"><g id="b24f8614-20ef-4cec-a50a-7ced35d16814" data-name="Layer 2"><g id="fa01feaf-579e-4c20-ba60-43d232f559d5" data-name="Layer 1-2"><path d="M6,0H4.14c-7,0-3,6.06-3,6.06L6,13V0Z" style="fill:#19222b"></path></g></g></g></g></svg>'
                         }
                    >
                         <span>
                              <CIcon icon="icon-info" style={{ cursor: 'pointer' }}></CIcon>
                         </span>
                    </Tippy>
               </span>
          </div>
     </div>
);

export const ChangeDataLoading = () => (
     <div className="filter-loading">
          <CProgress color="dark" variant="striped" animated value={100} />
     </div>
);

export const ReportIsCancel = ({ setReportIsCancel, setReloadTable }) => {
     const onReload = () => {
          if (setReportIsCancel && setReloadTable) {
               setReportIsCancel((prev) => !prev);
               setReloadTable((prev) => !prev);
          }
     };

     return (
          <div className="contentTooltipLongLoading">
               <div
                    style={{
                         width: 'max-content',
                         padding: '10px 20px',
                         background: '#f0f3f5',
                         boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                         borderRadius: '10px',
                    }}
               >
                    <p className="text-center mb-0" style={{ color: '#000', fontSize: '14px' }}>
                         The query is being built! We're crunching the numbers to get the best results. Please check back in a few minutes!
                         <span onClick={() => onReload()}>
                              <CIcon style={{ cursor: 'pointer' }} icon="iconReload" />
                         </span>
                    </p>
               </div>
          </div>
     );
};
