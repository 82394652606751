import classNames from 'classnames';
import React, { useContext, useMemo, } from 'react';
import { useSelector } from 'react-redux';

import { CREATE_ACCOUNT_MAIN_STEPS } from '../../../../../constants';
import { useCompletedPercentage } from '../../../../../helpers/customHooks';
import { getSessionKeySignupCurrentStep } from '../../../../../utils';
import { CreateAccountContext, STEPS } from './CreateAccount';

const StepProgress = () => {
    const { account, currentStep, incompleteSteps } = useContext(CreateAccountContext);
    const user = useSelector(state => state.theme.user);

    const handleCompletedSteps = ({ completedSteps, user, currentStep, incompleteSteps, accountId }) => {
        const checkCompletedStep = (step) => {
            return completedSteps.includes(step);
        }

        const removeSessionSignupCurrentStep = (steps) => {
            const sessionKey = getSessionKeySignupCurrentStep(accountId);
            const signupCurrentStep = sessionStorage.getItem(sessionKey);

            if (signupCurrentStep && steps.includes(signupCurrentStep)) {
                sessionStorage.removeItem(sessionKey);
            }
        }

        let progressSteps = {};
        let passedSteps = {};
        const accountSteps = [STEPS.name, STEPS.timeZone];
        const infoSteps = [STEPS.role, STEPS.gtmExperience, STEPS.job];
        const websiteSteps = [
            STEPS.gtmConnection, STEPS.placeScriptManually, STEPS.gtmMakeChanges, STEPS.gtmNoPublishingRights,
            STEPS.gtmPublishSuccess, STEPS.gtmSelectAccount, STEPS.gtmSelectContainer, STEPS.gtmUnknownFailure, STEPS.gtmVerifyChanges, STEPS.domain,
            STEPS.domainCannotAccess, STEPS.domainRedirect, STEPS.domainSuccess, STEPS.domainVerifying,
            STEPS.verifyingScript, STEPS.verifyWithInaccessibleDomain, STEPS.cannotVerify, STEPS.verificationSuccess, STEPS.verificationFailed,
            STEPS.skipPlacingScript
        ];
        const dataSteps = [
            STEPS.almostDone, STEPS.configureMeasurement,
            STEPS.chatListener, STEPS.formListener,
            STEPS.videoListener, STEPS.weatherListener,
            STEPS.ecommerceListener, STEPS.configuringAccount,
            STEPS.consent, STEPS.consentListener, STEPS.dontFindConsentListenerTool
        ];

        if (checkCompletedStep(CREATE_ACCOUNT_MAIN_STEPS.name) && checkCompletedStep(CREATE_ACCOUNT_MAIN_STEPS.timeZone)) {
            progressSteps.yourAccount = true;
            passedSteps.yourAccount = true;
        }

        if (user.roleInfo && user.gtmExperience && user.job) {
            progressSteps.yourInfo = true;
            passedSteps.yourInfo = true;
        }

        if (checkCompletedStep(CREATE_ACCOUNT_MAIN_STEPS.gtmConnection)) {
            progressSteps.yourWebsite = true;
            passedSteps.yourWebsite = true;
            removeSessionSignupCurrentStep(websiteSteps);
        }

        if (dataSteps.includes(currentStep)) {
            progressSteps.yourData = true;
        }

        if (checkCompletedStep(CREATE_ACCOUNT_MAIN_STEPS.configureMeasurement)) {
            progressSteps.yourData = true;
            passedSteps.yourData = true;
            // removeSessionSignupCurrentStep(dataSteps);
        }

        const yourWebsite = !incompleteSteps.includes(STEPS.gtmConnection);

        if (accountSteps.includes(currentStep)) {
            progressSteps = { ...progressSteps, yourAccount: true, yourInfo: false, yourWebsite };
            passedSteps = { ...passedSteps, yourAccount: false, yourInfo: false, yourWebsite };
        }

        if (infoSteps.includes(currentStep)) {
            progressSteps = { ...progressSteps, yourInfo: true, yourWebsite };
            passedSteps = { ...passedSteps, yourInfo: false, yourWebsite };
        }

        if (websiteSteps.includes(currentStep)) {
            progressSteps.yourWebsite = true;
        }

        return { progressSteps, passedSteps };
    };

    const { progressSteps, passedSteps } = useMemo(
        () => handleCompletedSteps({ completedSteps: account.completedSteps || [], user, currentStep, incompleteSteps, accountId: account.id }),
        [account.completedSteps, user, currentStep, incompleteSteps, account.id]
    );

    const percentage = useCompletedPercentage({ completedSteps: account.completedSteps });

    return (
        <div className="steps steps-vertical">
            <div className="headline">
                <p>Get Started: {percentage}%</p>
            </div>
            <ol>
                <li className={classNames({ 'steps-selected': progressSteps.yourAccount, 'steps-complete': passedSteps.yourAccount })}>
                    <div className="steps-circle"></div>
                    <span>Your Account</span>
                </li>
                <li className={classNames({ 'steps-selected': progressSteps.yourWebsite, 'steps-complete': passedSteps.yourWebsite })}>
                    <div className="steps-circle"></div>
                    <span>Your Website</span>
                </li>
                <li className={classNames({ 'steps-selected': progressSteps.yourData, 'steps-complete': passedSteps.yourData })}>
                    <div className="steps-circle"></div>
                    <span>Your Data</span>
                </li>
            </ol>
            {/* <div className="steps-responsive">
                <div className="steps-responsive-text">Your Account</div>
            </div> */}
        </div>
    );
};

export default StepProgress;
