import React, { useContext } from 'react';
import { CButton, CCard, CCardBody } from '@coreui/react';

import { CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';

const GTMUnknownFailure = () => {
    const { stepsData, setCurrentStep } = useContext(CreateAccountContext);
    const defaultMessages = {
        headline: 'An error occurred!',
        description: `We're having issues making these changes to your GTM account and the system isn't providing a specific reason. We recommend going back an re-authenticating your Google Account or installing the script manually.`
    };
    const messages = stepsData.gtmFailedMessages || defaultMessages;

    const onBackBtnClicked = () => {
        setCurrentStep(STEPS.gtmConnection);
    };

    return (
        <CreateAccountBody>
            <CCard className="account-script unknown-failure">
                <p className="number-of-step">2 of 3 steps</p>
                <CCardBody>
                    <h3>{messages.headline}</h3>
                    <p>{messages.description}</p>
                    <CButton className="btn-save" onClick={onBackBtnClicked}>
                        Go Back
                    </CButton>
                </CCardBody>
            </CCard>
        </CreateAccountBody>
    );
};

export default GTMUnknownFailure;
