import React from 'react';
import { useContext } from 'react';
import { LookupTableContext } from './LookupTable';
import { NATIVE_BROWSER_CONDITIONS, VARIABLE_OPERATORS_TRIGGERS } from '../../../../../../constants';
import CLabel from '../../../../../migration/CLabel';
import { CSelect } from '../../../../../migration/CInput';
import CTooltip from '../../../../../migration/CTooltip';

const TableHeaderRow = () => {
    const {
        pusherCondition,
        conditions, IsExisted, handleSelectOperator
    } = useContext(LookupTableContext);

    const eventTypes = ["Event"];

    function capitalizeFirstLetter(string) {
        return string[0].toUpperCase() + string.slice(1);
    }

    const getKey = (type, string) => {
        if (type === 'NativeBrowserStorage') {
            let obj = JSON.parse(string);
            let strReturn = `${capitalizeFirstLetter(obj.type)}: ${obj.name}, Format: ${capitalizeFirstLetter(obj.format)}`;
            return strReturn;
        }
        return string;
    }

    const eventKey = (find, type, key) => {
        let string = find && find.friendlyName ? find.friendlyName : getKey(type, key);

        return (
            <>
                <CTooltip
                    content={string}
                    placement="top"
                    advancedOptions={{ delay: { hide: 0 } }}
                >
                    <span>{string}</span>
                </CTooltip>
            </>
        )
    }

    const nativeKey = (find, type, key) => {
        let string = find ? getKey(type, JSON.stringify(find.value)) : getKey(type, key);

        return (
            <>
                <CTooltip
                    content={string}
                    placement="top"
                    advancedOptions={{ duration: [200, 0] }}
                >
                    <span>{string}</span>
                </CTooltip>
            </>
        )
    }

    return (
        <>
            <tr className="table-header">
                {
                    conditions && conditions[0].map((condition, index) => {
                        let { conjunction } = condition;
                        let conditionValues = condition.g0;
                        let { type, operator, key } = conditionValues;
                        let find = IsExisted(conditionValues.key, type);
                        const isNativeBrowserStorage = (type === 'NativeBrowserStorage');
                        return (
                            <React.Fragment key={index}>
                                {
                                    find && (
                                        <td
                                            className={
                                                operator === null ?
                                                    `no-condition ${(eventTypes.includes(type) && !isNativeBrowserStorage) ? "event-header" : "variable-header"}`
                                                    : `has-condition ${(eventTypes.includes(type) && !isNativeBrowserStorage) ? "event-header" : "variable-header"}`
                                            }
                                        >
                                            <CLabel>{type === eventTypes[1] ? 'NativeEvent' : type}</CLabel>
                                            {
                                                isNativeBrowserStorage ? (
                                                    <>
                                                        {nativeKey(find, type, key)}
                                                    </>
                                                ) : (
                                                    <>
                                                        {eventKey(find, type, key)}
                                                    </>
                                                )
                                            }
                                            {
                                                (type !== 'customVariable' && index < pusherCondition.length - 1) && (
                                                    <CSelect
                                                        name="conjunction"
                                                        className="conditions"
                                                        value={conjunction ? conjunction : "and"}
                                                        onChange={(e) => handleSelectOperator(e, index)}
                                                    >
                                                        <option value="and">and</option>
                                                        <option value="or">or</option>
                                                    </CSelect>
                                                )
                                            }
                                        </td>
                                    )
                                }
                            </React.Fragment>
                        )
                    })
                }
                <td>
                    <CLabel>Variable</CLabel>
                    <CTooltip
                        content={'Trigger'}
                        placement="right-end"
                        advancedOptions={{ duration: [200, 0] }}
                    >
                        <span>Trigger</span>
                    </CTooltip>
                </td>
            </tr>
            <tr className="table-body conditions-row">
                {
                    conditions && conditions[0].map((condition, index) => {
                        let conditionValues = condition.g0;
                        let { type, operator, value } = conditionValues;
                        let find = IsExisted(conditionValues.key, type);
                        const isNativeBrowserStorage = (type === 'NativeBrowserStorage');
                        const isNativeBrowserDateFunction = conditionValues.key.includes(`"format":"date function"`);
                        let nativeBrowserStorageOperator = operator;

                        if (isNativeBrowserStorage) {
                            if (operator === 'eq') {
                                if (value === 'true') {
                                    nativeBrowserStorageOperator = 'exav';
                                }

                                if (value === 'false') {
                                    nativeBrowserStorageOperator = 'nex';
                                }
                            }

                            if (operator === 'neq') {
                                if (value === 'true') {
                                    nativeBrowserStorageOperator = 'nex';
                                }

                                if (value === 'false') {
                                    nativeBrowserStorageOperator = 'exav';
                                }
                            }
                        }
                        return (
                            <React.Fragment key={index}>
                                {
                                    find && (
                                        <td>
                                            {
                                                (eventTypes.includes(type) && !isNativeBrowserStorage) ? (
                                                    <CSelect
                                                        name="operator"
                                                        className="event-conditions rounded-0"
                                                        value={operator}
                                                        onChange={(e) => handleSelectOperator(e, index)}
                                                    >
                                                        <option value="" disabled hidden>Select</option>
                                                        <option value="eq">equals to</option>
                                                        <option value="neq">not equal to</option>
                                                    </CSelect>
                                                ) : (
                                                    <CSelect
                                                        name="operator"
                                                        className="variable-conditions rounded-0"
                                                        value={isNativeBrowserStorage ? nativeBrowserStorageOperator : operator}
                                                        onChange={(e) => handleSelectOperator(e, index)}
                                                    >
                                                        <option value="" disabled hidden>Select</option>
                                                        {
                                                            isNativeBrowserStorage ? (
                                                                isNativeBrowserDateFunction ? (
                                                                    NATIVE_BROWSER_CONDITIONS.DATE_FUNCTION.map(({ VALUE, TEXT }) => (
                                                                        <option key={VALUE} value={VALUE}>{TEXT}</option>
                                                                    ))
                                                                ) : (
                                                                    NATIVE_BROWSER_CONDITIONS.DEFAULT.map(({ VALUE, TEXT }) => (
                                                                        <option key={VALUE} value={VALUE}>{TEXT}</option>
                                                                    ))
                                                                )
                                                            ) : (
                                                                VARIABLE_OPERATORS_TRIGGERS.map(({ VALUE, TEXT }) => (
                                                                    <option key={VALUE} value={VALUE}>
                                                                        {TEXT}
                                                                    </option>
                                                                ))
                                                            )
                                                        }
                                                    </CSelect>
                                                )
                                            }
                                        </td>
                                    )
                                }
                            </React.Fragment>
                        )
                    })
                }

                <td className="custom-variable-conditions">
                </td>
            </tr>
        </>
    )
}

export default TableHeaderRow
