import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Pusher from 'pusher-js';
import {
    CToaster,
    CToast,
    CToastHeader,
    CToastBody,
    CButton,
} from '@coreui/react';

import {
    newTicket,
    newComment,
    resetNumberOfCmt,
    updateStatusTicket
} from '../../actions/support';
import { getSupportRedirectChannelId } from '../../helpers/cms/support';

const ThePusher = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.theme.user);
    const [newToast, setNewToast] = useState([
        {
            id: '',
            type: '',
            show: false
        }
    ]);

    const handlePusher = () => {
        if (user.id) {
            const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
                cluster: process.env.REACT_APP_PUSHER_CLUSTER
            });
            var channel = pusher.subscribe(`devDataLayer-channelSupportCustomer-240297`);

            // Called when admin runs publishing for accounts
            channel.bind('sendNotificationToSupport', function (data) {
                dispatch(newTicket(data));
                const _newToasts = [{
                    id: data.id,
                    type: 'Ticket',
                    name: data.name,
                    show: true
                }];
                setNewToast(_newToasts);
            });

            channel.bind('sendNotificationNewComment', function (data) {
                dispatch(newComment(data));
                const _newToasts = [{
                    id: data.comment.ticketId,
                    name: `Comment by ${data.comment.userName}`,
                    type: 'Comment',
                    show: true
                }];
                setNewToast(_newToasts);
            });

            channel.bind('resetNumberCommentTicketCs', function (data) {
                dispatch(resetNumberOfCmt(data));
            });

            channel.bind('ticketHasUpdateStatus', function (data) {
                dispatch(updateStatusTicket(data));
            });

            const channel2 = pusher.subscribe(`channel-${getSupportRedirectChannelId()}`);

            channel2.bind('redirectSupportLoginAs', function () {
                setTimeout(() => {
                    window.location.replace('/');
                }, 500);
            })

            return () => {
                channel.unbind();
                channel2.unbind();
            };
        }
    }

    useEffect(handlePusher, [user.id]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <CToaster
                position={'bottom-right'}
                key={'toaster bottom-right'}
            >
                {
                    newToast.map((toast) => {
                        return (
                            <CToast
                                key={toast.id}
                                show={toast.show}
                                autohide={3000}
                                fade={true}
                            >
                                <CToastHeader closeButton={true}>
                                    One {toast.type} created
                                </CToastHeader>
                                <CToastBody>
                                    <p>{toast.name}</p>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <CButton variant="outline" color="dark" to={`/tickets/${toast.id}`}>Go Ticket</CButton>
                                        <CButton disabled shape="pill" color="success" to={`/tickets/${toast.id}`}>Follow</CButton>
                                    </div>
                                </CToastBody>
                            </CToast>
                        )
                    })
                }
            </CToaster>
        </>
    )
}

export default React.memo(ThePusher)
