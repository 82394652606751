import { useContext } from "react";

import { CreateAccountContext, STEPS } from "../CreateAccount";

const useListenerBackBtnClicked = (listenerType, currentListenerIndex) => {
     const { stepsData, setStepsData, setCurrentStep } = useContext(CreateAccountContext);
     const { selectedListenerTopics, allSelectedListeners } = stepsData;

     const handleBackBtnClicked = (selectedListeners) => {
          if (listenerType !== 'weather') {
               const newAllSelectedListeners = { ...allSelectedListeners, [listenerType]: selectedListeners };
               setStepsData({ allSelectedListeners: newAllSelectedListeners });
          }

          if (currentListenerIndex === 0) {
               setCurrentStep(STEPS.configureMeasurement);
          } else {
               setCurrentStep(selectedListenerTopics[currentListenerIndex - 1]);
          }
     };

     return handleBackBtnClicked;
}

export default useListenerBackBtnClicked;