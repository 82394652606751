import React, { useContext } from 'react';
import CIcon from '@coreui/icons-react';
import { useHistory } from 'react-router-dom';
import { CButton, CLink } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import { SUBSCRIBER_MY_ACCOUNT_PATH } from '../../../../../constants';
import { SupportTicketPopupContext, DECISION_STEPS } from '../GetHelpLabel';
import { resetAllPreview, setActiveAccount, setSignUpStep, setShowGetHelpLabel, setShowUpgradeAccountPopup } from '../../../../../actions/subscriber';
import { setFlexibleModal } from '../../../../../actions/common';

const DecisionStep1 = () => {
     const history = useHistory();
     const dispatch = useDispatch();
     const { setCurrentStep, resetTicket } = useContext(SupportTicketPopupContext);
     const signUpStep = useSelector((state) => state.subscriber.signUpStep);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const createdAccount = useSelector((state) => state.subscriber.createdAccount);
     const { running: previewRunning } = useSelector((state) => state.subscriber.preview);
     const showUpgradeAccountPopup = useSelector((state) => state.subscriber.showUpgradeAccountPopup);

     const handleClick = () => {
          setCurrentStep(DECISION_STEPS.step2);
     };

     const closeTicketSuccess = () => {
          dispatch(setShowGetHelpLabel(false));
          resetTicket();
     };

     const handleClickViewSupport = () => {
          dispatch(setFlexibleModal({ show: false, showLv2: false, component: '' }));
          dispatch(setShowGetHelpLabel(false));
          dispatch(
               setSignUpStep({
                    previousStep: signUpStep.currentStep,
               })
          );
          if (createdAccount && createdAccount.id) {
               dispatch(setActiveAccount(createdAccount));
          }
          if (previewRunning) {
               dispatch(resetAllPreview(activeAccount.id));
          }
          if (showUpgradeAccountPopup) {
               dispatch(setShowUpgradeAccountPopup(false));
          }
          history.push(SUBSCRIBER_MY_ACCOUNT_PATH.SUPPORT_TICKETS);
          setTimeout(() => {
               document.body.classList.remove('no-scroll');
          }, 500);
     };

     return (
          <div className='ticket-popup-content'>
               <div className='btn-close-ticket'>
                    <span onClick={() => closeTicketSuccess()}>
                         <CIcon icon='cil-x' width={12} />
                         Close
                    </span>
               </div>
               <div className='ticket-popup-header'>
                    <h5>We're Here to Help!</h5>
                    <p>Tell us about your problem.</p>
               </div>
               <div className='ticket-popup-body'>
                    <CButton color='primary' onClick={() => handleClick()}>
                         I am new to ListenLayer
                    </CButton>
                    <CButton color='primary' onClick={() => handleClick()}>
                         I'm an experienced ListenLayer user
                    </CButton>
               </div>
               <div className='ticket-popup-footer'>
                    <div className='ticket-popup-footer-link'>
                         <CLink onClick={handleClickViewSupport}>{`View my support cases ${'>'}`}</CLink>
                    </div>
               </div>
          </div>
     );
};

export default DecisionStep1;
