import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, useLocation, matchPath } from 'react-router-dom';

import Breadcrumb from './Breadcrumb';
import BreadcrumbItem from './BreadcrumbItem';
import { setReduxActiveDropdown } from '../../../actions/subscriber';
import { useDispatch, useSelector } from 'react-redux';

//component - CoreUI / CBreadcrumbRouter
const getPaths = (pathname) => {
     const paths = ['/'];
     if (pathname === '/') return paths;
     pathname.split('/').reduce((prev, curr) => {
          const currPath = `${prev}/${curr}`;
          paths.push(currPath);
          return currPath;
     });
     return paths;
};

const BreadcrumbRouteItem = ({ name, currPath, linkDisabled, notShow }, fullCurrPath, dispatch) => {

     if (notShow) {
          return <React.Fragment key={currPath}></React.Fragment>;
     }

     function transformString(inputString) {
          const parts = inputString.replace(/^\//, '').split('/');

          const transformedParts = parts.map((part) => {
               const words = part.split('-');
               const capitalizedWords = words.map((word) => {
                    const firstChar = word.charAt(0).toUpperCase();
                    const restOfString = word.slice(1);
                    return firstChar + restOfString;
               });
               return capitalizedWords.join(' ');
          });

          return transformedParts;
     }

     const handleActiveMainNav = (nameNav) => {
          dispatch(setReduxActiveDropdown(nameNav));
     };

     const arrCurrentPath = transformString(currPath);

     if ((currPath === fullCurrPath && fullCurrPath !== '/') || linkDisabled) {
          return (
               <BreadcrumbItem key={currPath} active>
                    {arrCurrentPath.map((item, index) => {
                         return index === 0 ? (
                              <span key={index} style={{ cursor: 'pointer' }} onClick={() => handleActiveMainNav(item)}>{`${item} `}</span>
                         ) : (
                              <span key={index}>
                                   {'\u00A0'}
                                   {`/ ${item}`}
                              </span>
                         );
                    })}
               </BreadcrumbItem>
          );
     } else {
          return (
               <BreadcrumbItem key={currPath}>
                    <Link to={currPath}>{name}</Link>
               </BreadcrumbItem>
          );
     }
};

const BreadcrumbRouter = (props) => {
     const dispatch = useDispatch();
     const { className, innerRef, routes, ...attributes } = props;

     let items = null;
     const location = useLocation();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);

     if (routes) {
          const removeDynamicPrefixes = (path, isRoute) => {
               if (!isRoute) {
                    const parts = path.split('/').filter(part => part !== '');
     
                    if (parts.length > 1) {
                         return `/${parts.slice(1).join('/')}`;
                    }
          
                    return path;
               }

               const pathSecondId = `/${activeAccount.secondId}`
               const checkRoutePath = path.includes(pathSecondId)

               return checkRoutePath ? path.replace(pathSecondId, '') : path.replace('/:secondId', '')
          };

          const currPath = removeDynamicPrefixes(location.pathname);
          const paths = getPaths(currPath);
          let currRoutes = paths
               .map((currPath) => {
                    const route = routes.find((route) =>
                         matchPath(currPath, {
                              path: removeDynamicPrefixes(route.path, true),
                              exact: route.exact,
                         })
                    );
                    if (routes) {
                         return { ...route, currPath };
                    }
                    return '';
               })
               .filter((route) => route);

          const getUniqueListBy = (arr, key) => {
               return [...new Map(arr.map((item) => [item[key], item])).values()];
          };

          currRoutes = getUniqueListBy(currRoutes, 'path');

          items = currRoutes.map((route) => {
               if (route.path && route.name && route.currPath) {
                    return BreadcrumbRouteItem(route, currPath, dispatch);
               }
               return '';
          });
     }

     //render
     const classes = classNames(className);

     return (
          <Breadcrumb className={classes} {...attributes} ref={innerRef}>
               {items}
          </Breadcrumb>
     );
};

BreadcrumbRouter.propTypes = {
     className: PropTypes.string,
     innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
     routes: PropTypes.array,
};

export default BreadcrumbRouter;
