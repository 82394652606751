export const SAVED_VIEW_TYPE = {
    SET_DEFAULT: 'set-default',
    DELETE: 'delete',
    RENAME: 'rename',
    SHARE_URL: 'share-url',
    OVERRIDE: 'override'
}

export const VIEW_TYPE = {
    VIEW: 'view',
    REPORT: 'report'
}
