import React from 'react'
import { CFooter } from '@coreui/react'

const AdminFooter = () => {
     return (
          <CFooter fixed={false}>
          </CFooter>
     )
}

export default React.memo(AdminFooter)
