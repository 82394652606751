import React, { useContext } from 'react';
import { CButton, CCard, CCardBody } from '@coreui/react';
import { useSelector } from 'react-redux';

import { CHANGE_STEP_TYPE, GTMWizardBody, GTMWizardContext } from '.';
import { GTM_WIZARD_STEPS, GTM_WIZARD_TYPE } from '../../../../../constants';
import { CInput } from '../../../../migration/CInput';

const GTMExists = () => {
     const { stepsData, setCurrentStep } = useContext(GTMWizardContext);
     const gtmWizard = useSelector(state => state.subscriber.gtmWizard);
     const { existentVariableNames, existentTriggerNames } = stepsData;

     const getDisplayData = () => {
          let displayData = {};

          switch (gtmWizard.type) {
               case GTM_WIZARD_TYPE.VARIABLE:
                    if (existentVariableNames.length === 1) {
                         displayData = {
                              headline: 'Variable Already Exists',
                              subHeadline: 'The variable you are trying to create already exists. The variable is currently called:',
                              inputValues: existentVariableNames,
                              description: 'We can rename and re-organize it in order to associate it inside ListenLayer.'
                         };
                    } else {
                         displayData = {
                              headline: 'Variables Already Exist',
                              subHeadline: 'The variables you are trying to create already exists. The variables are currently called:',
                              inputValues: existentVariableNames,
                              description: 'We can rename and re-organize them in order to associate them inside ListenLayer.'
                         };
                    }

                    break;
               case GTM_WIZARD_TYPE.TRIGGER:
                    if (existentTriggerNames.length === 1) {
                         displayData = {
                              headline: 'Trigger Already Exists',
                              subHeadline: 'The trigger you are trying to create already exists. The trigger is currently called:',
                              inputValues: existentTriggerNames,
                              description: 'We can rename and re-organize it in order to associate it inside ListenLayer.'
                         };
                    } else {
                         displayData = {
                              headline: 'Triggers Already Exist',
                              subHeadline: 'The triggers you are trying to create already exists. The triggers are currently called:',
                              inputValues: existentTriggerNames,
                              description: 'We can rename and re-organize them in order to associate them inside ListenLayer.'
                         };
                    }

                    break;
               case GTM_WIZARD_TYPE.DATA_ACTION:
                    // displayData = {
                    //      headline: 'Create a Tag inside GTM',
                    //      subHeadline: 'We\' going to create a Tag inside GTM for the following Data Action inside ListenLayer:',
                    //      inputValue: existentVariableNames,
                    //      description: 'We can rename and re-organize it in order to associate it inside ListenLayer.'
                    // };

                    break;
               default:
          }

          return displayData;
     };

     const displayData = getDisplayData();

     const onBackBtnClicked = () => {
          setCurrentStep(null, CHANGE_STEP_TYPE.PREV);
     };

     const handleProceed = () => {
          setCurrentStep(GTM_WIZARD_STEPS.gtmVerifyChanges, CHANGE_STEP_TYPE.NEXT);
     };

     return (
          <GTMWizardBody onBackBtnClicked={onBackBtnClicked}>
               <CCard className="account-domain checking">
                    <CCardBody>
                         <h3>{displayData.headline}</h3>
                         <p className="sub-headline">{displayData.subHeadline}</p>
                         {
                              displayData.inputValues && displayData.inputValues.map((val, i) => (
                                   <CInput key={i} className="mb-3" value={val} readOnly />
                              ))
                         }
                         <span>{displayData.description}</span>
                         <CButton className="btn-save mb-3" onClick={handleProceed}>
                              Yes, Let's Proceed
                         </CButton>
                         <span className="note">You'll have a chance to review all changes before they are pushed to GTM.</span>
                         <CButton className="btn-navigation-step" onClick={onBackBtnClicked}>
                              <span>Cancel and go back</span>
                              <i className="fal fa-angle-right"></i>
                         </CButton>
                    </CCardBody>
               </CCard>
          </GTMWizardBody>
     )
};

export default GTMExists;