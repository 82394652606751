import React from 'react';
import {
     CModal,
     CModalBody,
     CButton,
} from '@coreui/react';
import PropTypes from 'prop-types';

const EndSession = ({ show, onCancel, onAccept }) => {
     return (
          <div className="confirm-save-popup end-session">
               <CModal
                    visible={show}
                    onClose={onCancel}
                    color="primary"
                    alignment="center"
                    portal={false}
               >
                    <CModalBody className="text-center confirm-save">
                         <h3>You are previewing</h3>
                         <div className="confirm-save-content">
                              <p>This will end your active test session.</p>
                         </div>
                         <div className="position-relative d-inline-block">
                              <CButton
                                   className="btn-info mr-4"
                                   onClick={onAccept}
                              >
                                   Yes, end my test
                              </CButton>
                              <div className='d-block'>
                                   <div className="btn-cancel" onClick={onCancel}>No, go back</div>
                              </div>
                         </div>
                    </CModalBody>
               </CModal>
          </div>
     )
}

EndSession.propTypes = {
     show: PropTypes.bool,
     onCancel: PropTypes.func,
     onAccept: PropTypes.func
}

export default EndSession
