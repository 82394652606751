import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { callTokenApi } from '../../../../../apiCaller';
import { ACCORDION_TYPES, API_RECORD_PROFILE_TABLE_STEP_FORM, DEFAULT_DATE_FORMAT } from '../../../../../constants';
import { Skeleton } from '@progress/kendo-react-indicators';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import Accordion from '../../../../general/accordion';
import CIcon from '@coreui/icons-react';
import { formatNumber } from '../../../../../utils';
// import { CProgress } from '@coreui/react';

const useFetchData = (props) => {
     const { formID, accountId, setInitialValue, setIsLoading, dateRangeRecord, locationForm, sort } = props;

     const newDateRange = {
          selection: {
               startDate: dateRangeRecord.selection.startDate.format(DEFAULT_DATE_FORMAT),
               endDate: dateRangeRecord.selection.endDate.format(DEFAULT_DATE_FORMAT),
          },
     };

     const bodyData = {
          accountId,
          dateRanges: newDateRange,
          formID,
          locationForm,
          sort,
     };

     const fetchData = () => {
          setIsLoading(true);
          callTokenApi(API_RECORD_PROFILE_TABLE_STEP_FORM, 'POST', bodyData)
               .then((response) => {
                    if (response && response.status === 200) {
                         const { groupStepData } = response.data;

                         if (sort && sort.fieldName !== 'orderNumber') {
                              for (const key in groupStepData) {
                                   if (groupStepData.hasOwnProperty(key)) {
                                        const items = groupStepData[key];
                                        items.forEach((item, index) => {
                                             item.orderNumber = index + 1;
                                        });
                                   }
                              }
                         }

                         setInitialValue(groupStepData);
                    }
                    if (response && response.status) {
                         setIsLoading(false);
                    }
               })
               .finally(() => {});
     };
     const dependencies = [formID, dateRangeRecord, sort];

     useEffect(fetchData, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};

const TableRecordStepForm = ({ id, header, accordionShow, setAccordionShow, maxStepForm, locationForm }) => {
     const [initialValue, setInitialValue] = useState([]);
     const [isLoading, setIsLoading] = useState(true);
     const [sort, setSort] = useState({});
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const dateRangeRecord = useSelector((state) => state.subscriber.dateRangeRecord);
     const accountId = activeAccount.id;

     useFetchData({ formID: id, accountId, setInitialValue, setIsLoading, dateRangeRecord, locationForm, sort });

     const handleClickSort = (field, stepNumber) => {
          if (sort.fieldName !== field) {
               setSort({ fieldName: field, stepNumber: maxStepForm > 0 ? stepNumber : 'null', isDesc: true });
          } else {
               if (sort.isDesc) setSort({ fieldName: field, stepNumber: maxStepForm > 0 ? stepNumber : 'null', isDesc: false });
               else {
                    setSort({});
               }
          }
     };

     const customHeaderCell = (props, stepNumber) => {
          return (
               <span className="k-cell-inner table-head">
                    <span className="k-link">
                         <span className="k-column-title" onClick={() => handleClickSort(props.field, stepNumber)}>
                              {props.title}
                         </span>
                         {sort.fieldName === props.field && sort.stepNumber === props.stepNumber && (
                              <CIcon icon="iconDropdown" className={`icon-sort ${sort.isDesc ? 'desc' : 'asc'}`}></CIcon>
                         )}
                    </span>
               </span>
          );
     };

     function CustomCell(props) {
          const value = props.dataItem[props.field];
          const { style, className, id } = props;

          if (value === undefined) {
               return (
                    <td>
                         <Skeleton />
                    </td>
               );
          }

          if (value === null) {
               return <td>{0}</td>;
          }

          switch (props.field) {
               case 'falloutRate':
                    return (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              {`${value}%`}
                         </td>
                    );
               case 'countStart':
               case 'countCompleted':
                    return (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              {' '}
                              {formatNumber(value)}{' '}
                         </td>
                    )
               default:
                    return (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              {value}
                         </td>
                    );
          }
     }

     const loadingPanel = (
          <div className="k-loading-mask">
               <span className="k-loading-text">Loading</span>
               <div className="k-loading-color" style={{ opacity: '0.3' }}></div>
          </div>
     );

     // const changeDataLoading = (
     //      <div className="filter-loading">
     //           <CProgress color="dark" variant="striped" animated value={100} />
     //      </div>
     // );

     const renderTableStepForm = (data, index) => {
          let stepNumber = 'null';
          if (maxStepForm > 0) {
               stepNumber = index + 1;
          }
          return (
               <div className="table-step-form" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    {maxStepForm > 1 && <h5 style={{ marginBlock: '5px', textAlign: 'center' }}>{`Step ${index + 1}`}</h5>}
                    {isLoading && loadingPanel}
                    {/* {isLoading && sort && sort.stepNumber === index + 1 && changeDataLoading} */}
                    <Grid
                         key={index}
                         style={{ width: 'fit-content', maxWidth: '100%', maxHeight: '800px' }}
                         data={data.length === 0 && isLoading ? Array(5).fill({}) : data}
                         resizable={true}
                         reorderable={false}
                         navigatable={true}
                         scrollable="scrollable"
                    >
                         <GridNoRecords>No results found</GridNoRecords>
                         {header.length > 0 &&
                              header.map((item, index) => {
                                   return (
                                        <GridColumn
                                             orderIndex={index}
                                             title={item.label}
                                             field={item.value}
                                             resizable={true}
                                             headerCell={(props) => customHeaderCell({ ...props, stepNumber }, stepNumber)}
                                             cell={(props) => CustomCell(props)}
                                             key={index}
                                             width={item.minWidth}
                                        ></GridColumn>
                                   );
                              })}
                    </Grid>
               </div>
          );
     };
     return (
          <div className="accordion" style={{ display: 'flex', gap: '15px', flexDirection: 'column', marginTop: '10px' }}>
               <Accordion
                    accordionType={ACCORDION_TYPES.RECORD_PROFILE_FORM_STEP}
                    title={`Field Interactions`}
                    accordionShow={accordionShow}
                    setAccordionShow={setAccordionShow}
               >
                    {isLoading && isEmpty(initialValue) && !!maxStepForm && (
                         <div className="record-profile loading-profile" style={{ marginTop: '15px' }}>
                              <div className="record-profile-info form-profile">
                                   <p className="event-profile-session" style={{ height: '120px', borderRadius: '20px' }}>
                                        FormID:{' '}
                                   </p>
                              </div>
                         </div>
                    )}
                    {isEmpty(initialValue) && !isLoading ? (
                         <>
                              <p style={{ margin: 0, textAlign: 'center' }}>No data found</p>
                         </>
                    ) : (
                         Object.keys(initialValue).map((key, index) => {
                              return renderTableStepForm(initialValue[key], index);
                         })
                    )}
               </Accordion>
          </div>
     );
};

export default TableRecordStepForm;
