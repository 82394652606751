import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import { useActiveDestination } from '../../../../../../../helpers/customHooks';
import { API_CLIENT_ACCOUNT_DESTINATION, DESTINATION_CODE } from '../../../../../../../constants';
import { callTokenApi } from '../../../../../../../apiCaller';
import { setSubscriberState } from '../../../../../../../actions/subscriber';
import { useDispatch, useSelector } from 'react-redux';
import { toastError } from '../../../../../../../utils';
import GoogleAnalytics from './GoogleAnalytics';
import FacebookAds from './FacebookAds';
import { CCard, CCardBody } from '@coreui/react';

const RequiredValueInput = ({ requireFields }) => {
     const dispatch = useDispatch();
     const activeDestinations = useSelector((state) => state.subscriber.activeDestinations);
     const accountDestinations = useSelector((state) => state.subscriber.accountDestinations);
     const activeDestination = useActiveDestination();
     const initialData = useRef(activeDestination.generalSettings || {});

     const onSubmit = (values, { setSubmitting }) => {
          const newGeneralSettings = {
               ...activeDestination.generalSettings,
               ...values,
          };
          callTokenApi(`${API_CLIENT_ACCOUNT_DESTINATION}${activeDestination.accountDestinationId}`, 'PUT', { generalSettings: JSON.stringify(newGeneralSettings) })
               .then((response) => {
                    if (response.status === 200) {
                         const { accountDestination: accountDestinationRes } = response.data;
                         let newAccountDestinations = [...accountDestinations];
                         let foundIndex = accountDestinations.findIndex((el) => el.id === activeDestination.id);
                         newAccountDestinations[foundIndex].generalSettings = newGeneralSettings;
                         const newActiveDestinations = activeDestinations.map((destination) => {
                              if (destination.accountDestinationId === accountDestinationRes.id) {
                                   return { ...destination, generalSettings: accountDestinationRes.generalSettings };
                              }
                              return destination;
                         });

                         dispatch(setSubscriberState({ accountDestinations: newAccountDestinations, activeDestinations: newActiveDestinations }));
                         toast.success('save successfully');
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setSubmitting(false);
               });
     };

     const getTheComponent = () => {
          switch (activeDestination.code) {
               case DESTINATION_CODE.GA4:
                    return <GoogleAnalytics onSubmit={onSubmit} initialData={initialData.current} />;
               case DESTINATION_CODE.FACEBOOK_ADS:
                    return <FacebookAds onSubmit={onSubmit} initialData={initialData.current} />;
               default:
                    return <></>;
          }
     };

     return (
          <CCard className='require-value-input'>
               <CCardBody>
                    <h3>Wait, Something is Missing!</h3>
                    <p className='des'>
                         {activeDestination.name} Server Side requires {requireFields.join(', ')}. Please provide this value before proceeding.
                    </p>
                    {getTheComponent()}
               </CCardBody>
          </CCard>
     );
};

export default RequiredValueInput;
