import React from 'react'
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { signout, isAuth } from '../../helpers/auth';

import AdminASide from './TheAside';
import AdminContent from './TheContent';
import AdminFooter from './TheFooter';
import AdminHeader from './TheHeader';
import AdminSidebar from './TheSidebar';
import AdminPusher from './ThePusher';

const AdminLayout = (props) => {
     const darkMode = useSelector(state => state.theme.darkMode);

     const classes = classNames(
          'admin c-app c-default-layout',
          { 'c-dark-theme': darkMode },
     )

     return (
          <div className={classes}>
               <AdminSidebar />
               <AdminASide />
               <div className="c-wrapper">
                    <AdminHeader {...props} />
                    <div className="c-body">
                         <AdminContent />
                    </div>
                    <AdminFooter />
               </div>
               <AdminPusher />
               {!isAuth() && signout(true)}
          </div>
     )
}

export default AdminLayout;