import React, { useEffect, useState } from 'react';
import { Message } from './Message.js';
import { CButton, CRow, CCol } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useSelector, useDispatch } from 'react-redux';
import { callTokenApi } from '../../../../apiCaller.js';
import * as Yup from 'yup';
import {
     setManagers,
     setParentSetupGuideDataCompleted,
     setShowBlockAccountPopup,
     setUsedItems,
} from '../../../../actions/subscriber';
import { ADD_USER, API_CLIENT_ACCOUNT_COMPLETED_SETUP_GUIDE } from '../../../../constants/apiRest.js';
import { toastError } from '../../../../utils/index.js';
import { toast } from 'react-toastify';
import InviteUserPopup from '../InviteUserPopup.js';
import BoxFeature from './BoxFeature.js';
import CenterSpinner from '../../Loadings/CenterSpinner.js';
import { CInput } from '../../../migration/CInput.js';
import CImg from '../../../migration/CImg.js';
import { CInvalidFeedback } from '../../../migration/CInvalidFeedback.js';
import CLabel from '../../../migration/CLabel.js';

export const InstallGoals = (props) => {
     const { nextTour, installAndLearn, dataStepCompleted } = props;
     const [featureSuccess, setFeatureSuccess] = useState(false);
     const [isShowInvite, setIsShowInvite] = useState(false);
     const [modalInviteUser, setModalInviteUser] = useState(false);
     const [validateInviteEmail, setValidateInviteEmail] = useState('');
     const [render, setRender] = useState(0);
     const [newUserEmail, setNewUserEmail] = useState('');
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const managers = useSelector((state) => state.subscriber.managers);
     const dispatch = useDispatch();
     const activeListeners = useSelector((state) => state.subscriber.activeListeners);
     const stepRoadmap = useSelector((state) => state.subscriber.set_setup_guide_roadmap);
     const showRoadmap = useSelector((state) => state.subscriber.showBlockSetupGuidePopup);
     const showMinimize = useSelector((state) => state.subscriber.setShowRoadmapMininize);
     const stepPause = JSON.parse(localStorage.getItem(`roadMapWizard${activeAccount.id}`));

     const showAnimationRoadmap = localStorage.getItem('showAnimationRoadmap');
     const notShowStepAnimation = localStorage.getItem('installGoals');

     useEffect(() => {
          setTimeout(() => {
               localStorage.setItem('installGoals', true)
               localStorage.removeItem('showAnimationRoadmap')
          }, 2000)
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     const numberStepCompleted = installAndLearn.feature.filter((feature) => feature.status);
     const installCompleted = installAndLearn.feature
          ? installAndLearn.feature.some((feature) => feature.code === 'installListenlayer' && feature.status)
          : false;
     useEffect(() => {
          if (numberStepCompleted.length === installAndLearn.feature.length) {
               setFeatureSuccess(true);
          } else {
               if (
                    numberStepCompleted.length === 2 &&
                    !installAndLearn.feature.some((feature) => feature.title === 'Install ListenLayer' && feature.status)
               ) {
                    setIsShowInvite(true);
               }
          }

          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [installAndLearn]);

     useEffect(() => {
          if (stepRoadmap === 1 && showRoadmap) {
               setRender((prev) => prev + 1);
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [showRoadmap, showMinimize]);

     const toggleModalInviteUser = () => {
          setModalInviteUser(!modalInviteUser);
          setValidateInviteEmail('');
     };

     const closeModalInviteUser = () => {
          setModalInviteUser(false);
          setValidateInviteEmail('');
     };

     const inviteUser = () => {
          let validateSchema = Yup.object().shape({
               newUserEmail: Yup.string()
                    .required('Email is required')
                    .email('Email invalid')
                    .test('checkSpecialCharacters', `Email invalid`, (value) => {
                         return value && /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
                    }),
          });
          validateSchema
               .validate({ newUserEmail })
               .then(function () {
                    setValidateInviteEmail('');

                    if (activeAccount) {
                         const data = {
                              email: newUserEmail,
                              accountId: activeAccount.id,
                         };

                         callTokenApi(`${ADD_USER}`, 'POST', data).then((response) => {
                              if (response.status === 200) {
                                   if (response.data.useracc) {
                                        managers.push(response.data.user);
                                        dispatch(setManagers(managers));
                                        const usedItems = {
                                             itemName: 'numberOfUsers',
                                             quantity: activeAccount.usedUsers + 1,
                                        };
                                        dispatch(setUsedItems(usedItems));
                                   }
                                   toast.success(response.data.message);
                                   setNewUserEmail('');
                                   toggleModalInviteUser();
                              } else {
                                   if (response.data.accountBlocked) {
                                        dispatch(setShowBlockAccountPopup(true));
                                   } else {
                                        toastError(response);
                                   }
                                   toggleModalInviteUser();
                              }
                         });
                    }
               })
               .catch((error) => {
                    setValidateInviteEmail(error.message);
               });
     };
     const handleNewUserEmail = (e) => {
          setNewUserEmail(e.target.value);
          setValidateInviteEmail('');
     };
     const submitNewUserEmail = (e) => {
          if (e.keyCode === 13) {
               inviteUser();
          }
     };
     const isCustomForm = activeListeners.find((item) => item.alias === 'custom-html-forms');

     const onClickNextStep = () => {
          if (isCustomForm) {
               nextTour();
          } else {
               nextTour(2);
          }
          localStorage.setItem('installGoals', true)
          localStorage.setItem('showAnimationRoadmap', true);
          const data = {
               accountId: activeAccount.id,
               data: { installAndLearn: true },
          };
          callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_SETUP_GUIDE, 'PUT', data).then((response) => {
               if (response.status === 200) {
                    dispatch(setParentSetupGuideDataCompleted({ installAndLearn: true }));
               }
          });
     };

     const completeStep = dataStepCompleted ? (dataStepCompleted.completedSteps ? dataStepCompleted.completedSteps.installAndLearn : false) : false;
     const isShowAnimation = !!(!notShowStepAnimation && !completeStep && showAnimationRoadmap);

     const isMessageProgress = (stepPause && stepPause.stepPause === 1 && !featureSuccess) || (!featureSuccess && render >= 2)

     if (!dataStepCompleted) return <CenterSpinner />
     return (
          <CRow className='guide-row'>
               <CCol md='1'>
                    <CImg
                         src='/assets/images/avtAdSetupGuide.png'
                         height='60'
                         width='60'
                         className={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-1s' : ''}
                    />
               </CCol>
               <CCol md='11'>
                    <div className={`tour-guide show setup-guide`}>
                         <div className='tour-guide-content content'>
                              {installCompleted ? (
                                   <Message
                                        text={
                                             "I have three simple steps for you. As you complete these, you'll learn about the ListenLayer platform."
                                        }
                                        hasLoading={isShowAnimation}
                                        animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-1s animate__faster-2s' : ''}
                                        animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-4s' : ''}
                                   />
                              ) : (
                                   <Message
                                        text={
                                             "We'll start with three simple steps. As you complete these, you'll learn about the platform and interface."
                                        }
                                        iconLink={'/assets/images/setupGuide-icon.png'}
                                        hasLoading={isShowAnimation}
                                        animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-1s animate__faster-2s' : ''}
                                        animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-4s' : ''}
                                   />
                              )}

                              {isMessageProgress && (
                                   <Message
                                        text={"You're making progress. Complete all 3 steps to continue."}
                                        hasLoading={isShowAnimation}
                                        animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-5s' : ''}
                                        animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-7s' : ''}
                                   />
                              )}
                              <div className={isShowAnimation ? `animate__animated animate__fadeIn ${isMessageProgress ? 'animate__delay-7s' : 'animate__delay-5s'}` : ''}>
                                   {installAndLearn.feature.map((feature, index) => (
                                        <BoxFeature key={index} feature={feature} />
                                   ))}
                              </div>
                              {isShowInvite && (
                                   <CButton className='invite-member animate__animated animate__fadeIn animate__delay-1s' onClick={toggleModalInviteUser}>
                                        <CIcon className='invite-setup-icon' icon='icon-add-user' />
                                        Invite team member to my account
                                   </CButton>
                              )}

                              <InviteUserPopup
                                   show={modalInviteUser}
                                   onClose={closeModalInviteUser}
                                   event={inviteUser}
                                   btnText={'Add User'}
                                   className={'invite-roadmap'}
                              >
                                   <h2>Invite New User</h2>
                                   <p>
                                        Add a new user to {activeAccount.name}. If they already have a ListenLayer login, we'll automatically grant
                                        them access and send them an email notifucation. If they do not yet have a ListenLayer account, we will invite
                                        them to create one.
                                   </p>
                                   <div className='invite-user-form'>
                                        <CLabel>New User's Email:</CLabel>
                                        <CInput
                                             value={newUserEmail}
                                             onChange={handleNewUserEmail}
                                             onKeyDown={submitNewUserEmail}
                                             data-lpignore='true'
                                             invalid={!!validateInviteEmail}
                                        />
                                        <CInvalidFeedback>{validateInviteEmail}</CInvalidFeedback>
                                   </div>
                              </InviteUserPopup>
                              {featureSuccess && (
                                   <>
                                        <Message
                                             text={"Nice work! Select NEXT when you're ready to move forward."}
                                             hasLoading={isShowAnimation}
                                             animationDots={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-8s' : ''}
                                             animationMessage={isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-10s' : ''}
                                        />
                                        <div className={`buttons justify-content-end ${isShowAnimation ? 'animate__animated animate__fadeIn animate__delay-11s' : ''}`}>
                                             <CButton className='next-button roadmap' onClick={() => onClickNextStep()}>
                                                  Next
                                             </CButton>
                                        </div>
                                   </>
                              )}
                         </div>
                    </div>
               </CCol>
          </CRow>
     );
};
