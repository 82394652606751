import React from "react";
import { CButton, CSpinner } from "@coreui/react";
import { INSTALLATION_VIDEO } from "../../../../constants";
import CEmbed from "../../../migration/CEmbed";

const TicketVideoIframe = ({
    title,
    description,
    urlIframe,
    buttonVideo,
    buttonText,
    listenerName,
    featureName,
}) => {
    return (
        <>
            <div className="ticket-popup-header">
                {title && <h5>{title}</h5>}
                {description && <p>{description}</p>}
            </div>
            <div>
                {listenerName && (
                    <p>
                        <strong className="mr-1">Listener Name: </strong>{" "}
                        {listenerName}
                    </p>
                )}
                {featureName && (
                    <p>
                        <strong className="mr-1">Feature Name: </strong>
                        {featureName}
                    </p>
                )}
            </div>
            <div className="ticket-popup-body">
                <div className="ticket-video-wrapper">
                    <CEmbed ratio="16by9">
                        <iframe
                            src={urlIframe ? urlIframe : INSTALLATION_VIDEO}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Video"
                        />
                        <div className="text-center ticket-loading-video">
                            <CSpinner color="primary" />
                        </div>
                    </CEmbed>
                </div>
                <div className="ticket-text-below-video">
                    <span>Still need help after watching the video?</span>
                </div>
                <CButton color="primary" onClick={buttonVideo}>
                    {buttonText ? buttonText : "Open a Support Ticket"}
                </CButton>
            </div>
        </>
    );
};

export default TicketVideoIframe;
