import React from 'react'
import {
    CButton,
    CSpinner
} from '@coreui/react';
import { EventTableContext } from './EventTable';
// import ReportingValueSwitch from './ReportingValuesSwitch';

const repeatedVariables = [
    'clickIDAutomaticValues', 'ctaFieldValues', 'customValues', 'CustomValues', '.dataActions',
    'ecomAutomaticValues.ecommerce.click.products', 'ecomAutomaticValues.ecommerce.detail.products', 'ecomAutomaticValues.ecommerce.add.products',
    'ecomAutomaticValues.ecommerce.remove.products', 'ecomAutomaticValues.ecommerce.checkout.products', 'ecomAutomaticValues.ecommerce.purchase.products',
    'ecomAutomaticValues.ecommerce.items', 'ecomAutomaticValues.ecommerce.impressions', 'formFieldValues', 'formFieldValidationErrors', 'unbounceValues',
    'formSelectorValues', 'Location.parameters', 'page.parameters', 'urlAutomaticValues.parameters',
    'url.query', 'triggers.triggerArray', 'userDetails.clickIDs', 'uwAutomaticValues.forecastDays', 'weatherAutomaticValues.forecastDays',
    'uwAutomaticValues.recentDays', 'weatherAutomaticValues.recentDays'
];

const specialVariables = ['nativebrowserstorage', 'nativeDatalayer'];

const variablesToGetAllChildren = [...repeatedVariables, ...specialVariables];

const EventTableRow = (props) => {
    const {
        currentVariable, level, count,
        id, listenerId, featureId, selectorCanShowPlusIcon,
        automaticCanShowPlusIcon, isParentAutomatic,
        countVisitors, countSessions, eventCanShowPlusIcon
    } = props;
    const {
        details,
        setDetails,
        setCanFetchVariables,
        // setCanFetchAllCountData,
        firstLevelListenerIds,
        setFirstLevelListenerIds,
        // secondLevelListenerIds,
        // setSecondLevelListenerIds,
        // fetchVariableValueListenerIds,
        // setFetchVariableValueListenerIds,
        setVariableValueToShow,
        rowLoadingIds,
        addRowLoadingId,
        allVariableCount,
        setFetchVariable
    } = React.useContext(EventTableContext);
    const {
        childs,
        // variableValues
    } = currentVariable;

    const toggleDetails = () => {
        let position = details.indexOf(id);
        let newDetails = details.slice();

        if (position !== -1) {
            newDetails.splice(position, 1);
        } else {
            newDetails = [...details, id];
        }

        // level = 0 => toggle an event
        // Click on plus icon, start to fetch automatic and custom variables
        if (level === 0) {
            if (firstLevelListenerIds.indexOf(listenerId) === -1) {
                setFirstLevelListenerIds([...firstLevelListenerIds, listenerId]);
                addRowLoadingId(id);
            }

            setCanFetchVariables(true);
        } else {
            const findFunc = (el) => {
                switch (currentVariable.type) {
                    case 'automatic':
                        return el.automaticVariableId === childs[0].id;
                    case 'custom':
                        return el.customVariableId === childs[0].id;
                    case 'selector':
                        return el.selectorVariableId === childs[0].id;
                    default:
                        return false;
                }
            }

            if (!allVariableCount[featureId] || !allVariableCount[featureId].find(findFunc)) {
                addRowLoadingId(id);
                let repeatedVariable;

                switch (currentVariable.type) {
                    case 'automatic':
                        repeatedVariable = currentVariable.fullName && variablesToGetAllChildren.find(el => currentVariable.fullName.endsWith(el));
                        break;
                    case 'custom':
                        repeatedVariable = 'customValues';
                        break;
                    case 'selector':
                        repeatedVariable = 'formSelectorValues';
                        break;
                    default:
                }

                const getChildVariables = (children) => {
                    return children.reduce((acc, el) => {
                        let item = {
                            id: el.id,
                            code: el.code,
                            fullName: el.fullName || el.name
                        };

                        if (item.fullName && item.fullName.startsWith('weatherAutomaticValues')) {
                            item.code = item.code.replace('weatherAutomaticValues', 'uwAutomaticValues')
                            item.fullName = item.fullName.replace('weatherAutomaticValues', 'uwAutomaticValues');

                            if(repeatedVariable) {
                                repeatedVariable = repeatedVariable.replace('weatherAutomaticValues', 'uwAutomaticValues')
                            }
                        }

                        if (el.fullName && repeatedVariables.find(variable => el.fullName.endsWith(variable))) {
                            item.isRepeatedField = true;
                        }

                        acc.push(item)

                        if (el.childs && repeatedVariable) {
                            acc.push(...getChildVariables(el.childs));
                        }

                        return acc;
                    }, []);
                };

                let variableToOpen = { name: currentVariable.fullName };

                switch (currentVariable.type) {
                    case 'automatic':
                        variableToOpen.code = currentVariable.code;

                        if (variableToOpen.name && variableToOpen.name.startsWith('weatherAutomaticValues')) {
                            variableToOpen.name = variableToOpen.name.replace('weatherAutomaticValues', 'uwAutomaticValues');
                            variableToOpen.code = variableToOpen.code.replace('weatherAutomaticValues', 'uwAutomaticValues')
                        }

                        break;
                    case 'custom':
                        variableToOpen.name = 'customValues';
                        break;
                    case 'selector':
                        variableToOpen.name = 'formSelectorValues';
                        break;
                    default:
                }

                setFetchVariable({
                    variableToOpen,
                    childVariables: getChildVariables(currentVariable.childs),
                    featureId,
                    variableType: currentVariable.type,
                    level,
                    repeatedVariable
                });
            }
        }

        // level = 1 => Variable Name = "Automatic Variables" or "Custom Variables" or "Selector Variables"
        // Click on plus icon of "Automatic Variables", start to fetch count data
        if (level === 1 && currentVariable.variableName === 'Automatic Variables') {
            // Because when toggling an event, it already fetch custom and selector variable counts,
            // so only fetch count if first toggle "Automatic Variables" of an event of a new listener,
            // Add that listenerId to secondLevelListenerIds to trigger fetch automatic and custom variable counts
            // if (secondLevelListenerIds.indexOf(listenerId) === -1) {
            //     setSecondLevelListenerIds([...secondLevelListenerIds, listenerId]);
            //     addRowLoadingId(id);
            // }

            // setCanFetchAllCountData(true);
        }

        // Click on plus icon of a variable has "variableValues" value && the listener which
        // this variable belongs to has not been fetched -> fetch save reporting values for this listener
        // if (Array.isArray(currentVariable.childs) && !fetchVariableValueListenerIds.includes(listenerId)) {
        //     let isChildSaveValueExisted = currentVariable.childs.some(child => !!child.variableValues);

        //     if (isChildSaveValueExisted) {
        //         addRowLoadingId(id);
        //         setFetchVariableValueListenerIds([...fetchVariableValueListenerIds, listenerId]);
        //     }
        // }

        setDetails(newDetails);
    }

    let showIcon = (childs && childs.length > 0);
    let actualVariableValue = currentVariable.variableValue === null ? 'null' : currentVariable.variableValue;

    if (currentVariable.variableName === 'Automatic Variables' || isParentAutomatic) {
        showIcon = automaticCanShowPlusIcon;
    }

    if (currentVariable.variableName === 'Selector Variables') {
        showIcon = selectorCanShowPlusIcon;
    }

    if (typeof eventCanShowPlusIcon !== 'undefined') {
        showIcon = eventCanShowPlusIcon;
    }

    // if (variableValues && variableValues.length > 0 && currentVariable.saveValuesEnabled) { // Current variable has variable values and save reporting values config for it is enabled
    //     showIcon = true;
    // }

    let countVisitorsText = '';
    let countSessionsText = '';

    if (typeof countVisitors === 'number') {
        countVisitorsText = (currentVariable.id === 'total' && level === 0) ? `${countVisitors.toLocaleString('en-US')}(unique)` : countVisitors.toLocaleString('en-US');
    }

    if (typeof countSessions === 'number') {
        countSessionsText = (currentVariable.id === 'total' && level === 0) ? `${countSessions.toLocaleString('en-US')}(unique)` : countSessions.toLocaleString('en-US');
    }

    return (
        <>
            <div className="mb-3 variable-name">
                <span>{currentVariable.eventName}</span>
            </div>
            {/* <div className="reporting-values">
                {!!currentVariable.variableValues && (
                    <ReportingValueSwitch
                        currentVariable={currentVariable}
                        listenerId={listenerId}
                        featureId={featureId}
                        id={id}
                    />
                )}
            </div> */}
            <div className="variable-friendlyname left-indent">
                {currentVariable.variableName || currentVariable.code || currentVariable.name || actualVariableValue || currentVariable.fullName ? (
                    <span>{currentVariable.variableName || currentVariable.code || currentVariable.name || actualVariableValue || currentVariable.fullName}</span>
                ) : ''}
                {
                    typeof actualVariableValue !== 'undefined' && (
                        <i
                            className="far fa-chart-line variable-value-check"
                            onClick={() => setVariableValueToShow({ id: currentVariable.variableValueId, value: actualVariableValue })}
                        >
                        </i>
                    )
                }
            </div>
            <div className="mb-3 variable-path">
                {count.toLocaleString('en-US')}
            </div>
            <div className="mb-3 variable-path">
                {countVisitorsText}
            </div>
            <div className="mb-3 variable-path">
                {countSessionsText}
            </div>
            <div className="mb-3 text-right variables-children">
                {
                    showIcon && (
                        rowLoadingIds.includes(id) ? (
                            <div className="btn-sm">
                                <CSpinner color="primary" size="sm"></CSpinner>
                            </div>
                        ) : (
                            <CButton
                                color="light"
                                size="sm"
                                onClick={toggleDetails}
                                className="btn-plus"
                                disabled={rowLoadingIds.length > 0}
                            >
                                {
                                    details.includes(id) ? (
                                        <i className="fal fa-minus text-primary"></i>
                                    ) : (
                                        <i className="fal fa-plus text-primary"></i>
                                    )
                                }
                            </CButton>
                        )
                    )
                }
            </div>
        </>
    )
}

export default EventTableRow