import React, { useRef, useState } from 'react';
import { useOutsideHandling } from '../../../utils';

function CustomDropdown({ children }) {
     const [showDropdownItem, setShowDropDownItem] = useState(false);
     const dropDownMenuRef = useRef(null);

     const toggleShowMenu = () => {
          setShowDropDownItem(!showDropdownItem);
     };

     const clickOutside = () => {
          setTimeout(() => {
               setShowDropDownItem(false);
          }, 200);
     };

     const handleShowMenu = () => {
          toggleShowMenu();
     };

     useOutsideHandling(dropDownMenuRef, clickOutside, 'mousedown');

     const childrenWithOnClick = React.cloneElement(children[0], {
          onClick: handleShowMenu,
     });

     const childrenWithRef = React.cloneElement(children[1], {
          ref: dropDownMenuRef,
          setShowDropDownItem: setShowDropDownItem,
     });

     return (
          <div className={'containerCustomDropDown'}>
               {childrenWithOnClick}
               {showDropdownItem && childrenWithRef}
          </div>
     );
}

export default CustomDropdown;
