import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Pusher from 'pusher-js';

import {
    updatePublisedAccounts,
} from '../../actions/pusher';
import { setAccountAdmin, setPusherChannelName } from '../../actions/admin';

const ThePusher = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.theme.user);
    const accountAdmin = useSelector(state => state.admin.accountAdmin)

    const handlePusher = () => {
        if (user.id) {
            const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
                cluster: process.env.REACT_APP_PUSHER_CLUSTER
            });
            const timestamp = (new Date()).getTime();
            var channel = pusher.subscribe(`channel-${user.id}-${timestamp}`);

            dispatch(setPusherChannelName(channel.name));

            // Called when admin runs publishing for accounts
            channel.bind('publishAccounts', function (data) {
                dispatch(updatePublisedAccounts(data));
            })

            return () => channel.unbind();
        }
    }

    useEffect(handlePusher, [user.id]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleAccountPusher = () => {
        if (accountAdmin) {
            const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
                cluster: process.env.REACT_APP_PUSHER_CLUSTER
            });

            const channel = pusher.subscribe(`channel-${accountAdmin.id}`);

            channel.bind('updateAccount', function (data) {
                if (data) {
                    const newData = { ...accountAdmin, ...data }
                    dispatch(setAccountAdmin(newData));
                }
            });
        }
    }

    useEffect(handleAccountPusher, [accountAdmin]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
        </>
    )
}

export default React.memo(ThePusher)
