import { CSpinner } from '@coreui/react';
import React from 'react';

const CenterSpinner = () => {
     return (
          <div className='center-spinner text-center'>
               <CSpinner color='primary' />
          </div>
     );
};

export default CenterSpinner;
