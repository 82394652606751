import React, { useContext, useState, useEffect } from "react";
import { CButton, CForm } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useSelector } from "react-redux";
import {
    SupportTicketPopupContext,
    DECISION_STEPS,
    ISSUE_LISTENER,
} from "../GetHelpLabel";
import { Formik } from "formik";
import { CSelect } from "../../../../migration/CInput";

const IssueListener1 = () => {
    const { setCurrentStep, setListenerFeature, listenerFeature } = useContext(SupportTicketPopupContext);
    const listenerInTickets = useSelector((state) => state.subscriber.listenerInTickets);

    const { listeners, listenerFeatures } = listenerInTickets;
    const [dataFeatures, setDataFeatures] = useState([]);

    useEffect(() => {
        if (listenerFeature.listenerId) {
            setDataFeatures(listenerFeatures[listenerFeature.listenerId]);
        }
    }, [listenerFeature.listenerId, listenerFeatures]);

    const validationSchema = (values) => {
        let errors;

        if (!values.listenerId) {
            errors = { ...errors, name: "Listener is required." };
        }

        return errors;
    };

    return (
        <div className="ticket-popup-content">
            <div className="btn-back-ticket">
                <CIcon icon="icon-chevron-left" width={6} />
                <span onClick={() => {
                    setCurrentStep(DECISION_STEPS.step3)
                    setListenerFeature({
                        listenerId: '',
                        featureId: ''
                    })
                }}>
                    Back
                </span>
            </div>
            <div className="ticket-popup-header">
                <h5>We're Here to Help!</h5>
            </div>
            <div className="ticket-popup-body">
                <Formik
                    initialValues={{
                        listenerId: listenerFeature.listenerId || '',
                        featureId: listenerFeature.featureId || ''
                    }}
                    onSubmit={(values) => {
                        let newValues = {
                            ...values,
                        };
                        setListenerFeature(newValues);
                        setCurrentStep(ISSUE_LISTENER.step2);
                    }}
                    validate={validationSchema}
                >
                    {({
                        values,
                        handleChange,
                        handleSubmit,
                        errors,
                        setFieldValue,
                        setValues,
                        setFieldError,
                    }) => {
                        const handleChangeListenerId = (e) => {
                            setValues({listenerId: e.target.value, featureId: ''});
                            if (e.target.value) {
                                setDataFeatures(listenerFeatures[e.target.value]);
                            } else {
                                setDataFeatures([]);
                            }
                        };

                        const handleChangeFeatureId = (e) => {
                            setFieldValue("featureId", e.target.value);
                        };
                        return (
                            <CForm className="form-listener-feature" onSubmit={handleSubmit} noValidate>
                                <div className="ticket-group-column">
                                    <p>What Listener are you having issues with?</p>
                                    <CSelect
                                        name="listenerId"
                                        onChange={handleChangeListenerId}
                                        value={values.listenerId}
                                    >
                                        <option value=''>
                                            Select a Listener
                                        </option>
                                        {listeners && listeners.map((type) => (
                                            <option
                                                className="text-dark"
                                                key={type.id}
                                                value={type.id}
                                            >
                                                {type.name}
                                            </option>
                                        ))}
                                    </CSelect>
                                    {errors.name && (
                                        <span className="ticket-invalid-feedback">
                                            {errors.name}
                                        </span>
                                    )}
                                </div>

                                <div className="ticket-group-column">
                                    <p>What Feature are you struggling with?</p>
                                    <CSelect
                                        name="featureId"
                                        onChange={handleChangeFeatureId}
                                        value={values.featureId}
                                    >
                                        <option value="">
                                            None, this is a general Listener issue
                                        </option>
                                        {dataFeatures.map((type) => (
                                            <option
                                                className="text-dark"
                                                key={type.fId}
                                                value={type.fId}
                                            >
                                                {type.fName}
                                            </option>
                                        ))}
                                    </CSelect>
                                </div>

                                <CButton color="primary" type="submit">
                                    Next
                                </CButton>
                            </CForm>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default IssueListener1;
