import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { tagPropType } from './utils/helper'

//component - CoreUI / CEmbed

const CEmbed = props => {

  const {
    tag = 'div',
    className,
    innerRef,
    //
    ratio = '16by9',
    ...attributes
  } = props

  //render
  const Tag = tag;
  const classes = classNames(
    className, 'embed-responsive', `embed-responsive-${ratio}`
  )

  return (
    <Tag className={classes} {...attributes} ref={innerRef}/>
  )

}

CEmbed.propTypes = {
  tag: tagPropType,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  //
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  ratio: PropTypes.oneOf(['21by9', '16by9', '4by3', '1by1']),
};

export default CEmbed
