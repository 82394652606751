import React from 'react'

const TableHeaderRow = ({ name }) => {
    return (
        <>
            <tr className="table-header">
                <td className="user-source-header-item">
                    <span>{name === 'Referrer Rules' ? 'URL Element' : "URL Parameter"}</span>
                </td>
                <td className="user-source-header-item">
                    <span>{"Match Type"}</span>
                </td>
                <td className="user-source-header-item">
                    <span>{"Parameter Value"}</span>
                </td>
                <td className="user-source-header-item">
                    <span>{"User Source"}</span>
                </td>
            </tr>
        </>
    )
}

export default TableHeaderRow
