import React, { useEffect, useRef, memo } from 'react';
import { CButton, CModal, CModalBody, CForm, CFormInput } from '@coreui/react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CIcon from '@coreui/icons-react';
import { CTextarea } from '../../../components/migration/CInput';
import { CInvalidFeedback } from '../../../components/migration/CInvalidFeedback';
import CLabel from '../../../components/migration/CLabel';
import CFormGroup from '../../../components/migration/CFormGroup';

const validationSchema = () => {
     return Yup.object().shape({
          name: Yup.string()
               .min(2, `Name has to be at least 2 characters`)
               .max(50, `Name contains at most 50 characters`)
               .required('Name is required'),
     });
};

const PublishVersionModal = ({ show, onAccept, onCancel }) => {
     const initialValues = {
          name: '',
          description: '',
     };
     const nameInputRef = useRef();

     useEffect(() => {
          if (show) {
               setTimeout(() => {
                    nameInputRef.current?.focus()
               }, 500);
          }
     }, [show]);

     return (
          <div className="popup-publish-version">
               <CModal style={{display: 'block', '--cui-modal-bg': '#ffff'}} visible={show} onClose={onCancel} color="primary" portal={false}>
                    <CModalBody>
                         <CIcon icon="cil-x" onClick={onCancel} className="icon-close-popup"></CIcon>
                         <div className="popup-publish-version-description">
                              <h2>Publish Your Changes</h2>
                              <p>Changes that affect your data layer activity must be published before they will appear on your website.</p>
                         </div>
                         <Formik
                              initialValues={initialValues}
                              validationSchema={validationSchema}
                              onSubmit={onAccept}
                              onClose={onCancel}
                              validateOnChange={false}
                              validateOnBlur={false}
                         >
                              {({ values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue, setFieldError }) => {
                                   const handleChangeName = (e) => {
                                        setFieldValue('name', e.target.value);
                                        setFieldError('name', undefined);
                                   };
                                   return (
                                        <CForm onSubmit={handleSubmit} noValidate>
                                             <CFormGroup>
                                                  <CLabel htmlFor="name">
                                                       Name<span className="text-danger">*</span>
                                                  </CLabel>
                                                  <CFormInput
                                                       ref={nameInputRef}
                                                       id="name"
                                                       placeholder="Name"
                                                       invalid={!!errors.name}
                                                       required
                                                       disabled={isSubmitting}
                                                       value={values.name}
                                                       onChange={handleChangeName}
                                                       style={{fontSize: '.875rem', '--cui-body-bg': '#ffff'}}
                                                  />
                                                  <CInvalidFeedback style={{fontSize: '.775em'}}>{errors.name}</CInvalidFeedback>
                                             </CFormGroup>
                                             <CFormGroup>
                                                  <CLabel htmlFor="description">Description</CLabel>
                                                  <CTextarea
                                                       id="description"
                                                       placeholder="Description"
                                                       required
                                                       disabled={isSubmitting}
                                                       value={values.description}
                                                       onChange={handleChange}
                                                       style={{fontSize: '.875rem', '--cui-body-bg': '#ffff'}}
                                                  />
                                             </CFormGroup>
                                             <div className="form-actions">
                                                  <CButton type="submit" color="primary" className="mr-3" disabled={isSubmitting} shape="rounded-pill">
                                                       {isSubmitting ? (
                                                            <>
                                                                 Waiting<span className="dots-waiting"></span>
                                                            </>
                                                       ) : (
                                                            'Publish'
                                                       )}
                                                  </CButton>
                                                  <CButton className="btn btn-light" onClick={onCancel} shape="rounded-pill" style={{backgroundColor: '#f0f3f5'}}>
                                                       Cancel
                                                  </CButton>
                                             </div>
                                        </CForm>
                                   );
                              }}
                         </Formik>
                    </CModalBody>
               </CModal>
          </div>
     );
};

export default memo(PublishVersionModal);
