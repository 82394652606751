import React from 'react'

const TableLoading = () => {
     return (
          <div className='tableLoading'>
               <div className="tableLoading__header"></div>
               <div className="tableLoading__main">
                    <div className="tableLoading__item">
                         <div className="tableLoading__animated">
                              <div className="row1"></div>
                              <div className="row2"></div>
                              <div className="row1"></div>
                              <div className="row2"></div>
                              <div className="row1"></div>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default TableLoading;
