import React, { useContext } from "react";
import CIcon from "@coreui/icons-react";
import {
    SupportTicketPopupContext,
    ISSUE_ACCOUNT,
    CREATE_SUPPORT_TICKET,
} from "../GetHelpLabel";
import { INVOICES_CREDIT_CARD_CHARGES, STEP_PREVIOUS_CREATE_TICKET } from "../../../../../constants";

import TicketVideoIframe from "../TicketVideoIframe";

const IssueAccount3 = () => {
    const { setCurrentStep } = useContext(SupportTicketPopupContext);

    const dataVideoIframe = {
        title: `We're Here to Help!`,
        description: `Learn about invoices and credit card charges in this video. Perhaps it can solve your problem quickly.`,
        urlIframe: INVOICES_CREDIT_CARD_CHARGES
    }

    const buttonVideo = () => {
        setCurrentStep(CREATE_SUPPORT_TICKET.step1);
        localStorage.setItem(STEP_PREVIOUS_CREATE_TICKET, ISSUE_ACCOUNT.step3)
    }

    return (
        <div className="ticket-popup-content ticket-video-content">
            <div className="btn-back-ticket">
                <CIcon icon="icon-chevron-left" width={6} />
                <span onClick={() => setCurrentStep(ISSUE_ACCOUNT.step1)}>
                    Back
                </span>
            </div>
            <TicketVideoIframe
                title={dataVideoIframe.title}
                description={dataVideoIframe.description}
                urlIframe={dataVideoIframe.urlIframe}
                buttonVideo={buttonVideo}
            />
        </div>
    );
};

export default IssueAccount3;
