import React from 'react';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';

const CreateRule = React.lazy(() => import('./CreateRule'));
const EditRule = React.lazy(() => import('./EditRule'));

const EngagementRule = ({ id }) => {
     return <div className='rule-detail engagement-rule'><React.Suspense fallback={<CenterSpinner />}>{id ? <EditRule id={id} /> : <CreateRule />}</React.Suspense></div>;
};

export default EngagementRule;
