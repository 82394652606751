import React from 'react';

const Headline = ({ iconSrc, alt, title, children, style }) => {
     return (
          <div className='headline-wrapper'>
               <div className='d-flex headline-title'>
                    {iconSrc && <img className='listener-icon-headline' src={iconSrc} alt={alt ? alt : 'icon'} />}
                    <h1 style={style}>{title}</h1>
               </div>
               {children && (
                    <div className='description'>
                         {children}
                    </div>
               )}
          </div>
     );
};

export default Headline;
