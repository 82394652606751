import {
     CButton,
     CCard,
     CCardBody,
     CCardHeader,
     CCol,
     CForm,
     CLink,
     CRow,
} from '@coreui/react';
import { FieldArray, Formik } from 'formik';
import findIndex from 'lodash/findIndex';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import CIcon from '@coreui/icons-react';
import { setRuleHaveEditting, setViewedRule } from '../../../../../../actions/common';
import { fetchAllDataActionRules, fetchConversionRules, fetchTriggerRules, setLoadings, setTriggerRules } from '../../../../../../actions/subscriber';
import { callTokenApi } from '../../../../../../apiCaller';
import { API_CLIENT_ACCOUNT_RULE, TYPE_SHOW_UNSAVE_CHANGE } from '../../../../../../constants';
import { clearConversionDraft, toastError } from '../../../../../../utils';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { ConfirmSaveChange } from '../../../../../general/popup';
import VariableInRules from '../../../../../general/popup/VariableInRules';
import { RuleSettingContext } from '../EditLookupSetting';
import { CInput, CTextarea } from '../../../../../migration/CInput';
import { CInvalidFeedback } from '../../../../../migration/CInvalidFeedback';
import CLabel from '../../../../../migration/CLabel';
import CFormGroup from '../../../../../migration/CFormGroup';

const EditStep2 = ({ stepsData, setStepsData }) => {
     const dispatch = useDispatch();
     const { activeStep, setActiveStep, setRule } = useContext(RuleSettingContext);
     const [saveChangeModal, setSaveChangeModal] = useState(false);
     const [tempData, setTempData] = useState(false);
     const [saveLoading, setSaveLoading] = useState(false);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const rules = useSelector((state) => state.subscriber.triggerRules);
     const conversionRules = useSelector((state) => state.subscriber.conversionRules);
     const dataActionRules = useSelector((state) => state.subscriber.dataActionRules);
     const accountDestinations = useSelector((state) => state.subscriber.accountDestinations);
     const rulesLoading = useSelector((state) => state.subscriber.loadings.triggerRules);
     const [validateOnChange, setValidateOnChange] = useState(false);
     const [existedInRules, setExistedInRules] = useState(false);
     const [ruleTemp, setRuleTemp] = useState({});
     const initialValues = {
          name: stepsData.name,
          description: stepsData.description,
          triggers: stepsData.data.triggers,
     };
     const initialItem = { id: '', name: '' };

     const fetchRules = () => {
          if (activeStep === 2) {
               if (rules.length === 0) {
                    dispatch(fetchTriggerRules(activeAccount.id));
               } else {
                    dispatch(setLoadings({ triggerRules: false }));
               }

               if (conversionRules.length === 0) {
                    dispatch(fetchConversionRules(activeAccount.id));
               }

               if (dataActionRules.length === 0) {
                    dispatch(fetchAllDataActionRules(activeAccount.id));
               }
          }
     };

     useEffect(fetchRules, [activeStep]); // eslint-disable-line react-hooks/exhaustive-deps

     const validationSchema = useCallback(() => {
          if (!validateOnChange) {
               setValidateOnChange(true);
          }
          let ruleNames = rules.map((rule) => rule.name);

          // stepsData.name has value when editting rule
          if (stepsData.name) {
               // Find stepsData.name in ruleNames and remove it from ruleNames
               let ruleNameIndex = -1;

               ruleNames.some((ruleName, index) => {
                    if (stepsData.name === ruleName) {
                         ruleNameIndex = index;
                         return true;
                    }

                    return false;
               });

               if (ruleNameIndex !== -1) {
                    ruleNames.splice(ruleNameIndex, 1);
               }
          }

          return Yup.object().shape({
               name: Yup.string()
                    .trim()
                    .required('Name is required')
                    .max(200, 'Name cannot be more than 200 characters')
                    .transform(value => value.toLowerCase()) 
                    .notOneOf(ruleNames.map((item)=>item.toLowerCase()), 'Existed name, please choose another name'),
               triggers: Yup.array()
                    .of(
                         Yup.object().shape({
                              name: Yup.string().trim().required('Required').max(200, 'Name cannot be more than 200 characters'),
                         })
                    )
                    .required('Field is required!'),
          });
     }, [rules, stepsData.name, validateOnChange]);

     const getTriggerNames = () => {
          const _ruleNames = [];

          rules.forEach((rule) => {
               if (rule.id !== stepsData.id) {
                    if (rule.type === 'simple') {
                         _ruleNames.push(rule.name);
                    } else {
                         if (rule.data && Array.isArray(rule.data.triggers)) {
                              rule.data.triggers.forEach((r) => {
                                   _ruleNames.push(r.name);
                              });
                         }
                    }
               }
          });

          return _ruleNames;
     };

     const checkDup = (triggers) => {
          let errors = [],
               triggerNames = getTriggerNames();

          for (let i = 0; i < triggers.length; i++) {
               if (triggerNames.includes(triggers[i].name)) {
                    errors[i] = { name: 'Existed trigger!' };
               }
          }

          if (errors.length > 0) return errors;

          for (let i = 0; i < triggers.length - 1; i++) {
               for (let j = i + 1; j < triggers.length; j++) {
                    if (triggers[i].name.trim() === triggers[j].name.trim()) {
                         errors[i] = { name: 'Trigger must be unique!' };
                         errors[j] = { name: 'Trigger must be unique!' };
                    }
               }
          }

          return errors;
     };

     const onSubmit = ({ name, description, triggers }, { setSubmitting, setErrors }) => {
          setSubmitting(false);

          const flag = checkDup(triggers);

          if (flag.length > 0) {
               setErrors({ triggers: flag });
               return;
          }

          const newData = {
               name: name.trim(),
               description: description.trim(),
               triggers: triggers,
          };

          if (JSON.stringify(initialValues) === JSON.stringify(newData)) {
               dispatch(setRuleHaveEditting({ showLv2: false, typeLv2: '' }));
               setActiveStep(3);
          } else {
               setTempData({
                    name: name.trim(),
                    description: description.trim(),
                    data: { ...stepsData.data, triggers },
               });
               setSaveChangeModal(true);
          }
     };

     const onAccept = () => {
          setSaveLoading(true);
          const newStepsData = { ...stepsData, ...tempData };

          callTokenApi(API_CLIENT_ACCOUNT_RULE, 'PUT', newStepsData).then((response) => {
               setSaveLoading(false);
               setSaveChangeModal(false);

               if (response.status === 200) {
                    const { rule: newRuleUpdated } = response.data;
                    let newRules = [...rules];
                    let editedRuleIndex = findIndex(rules, { id: newStepsData.id });

                    if (newRules[editedRuleIndex].name !== newStepsData.name || newRules[editedRuleIndex].description !== newStepsData.description) {
                         // Update name for custom rules so we can validate rule name correctly
                         newRules[editedRuleIndex].name = newStepsData.name;
                         newRules[editedRuleIndex].description = newStepsData.description;
                    }

                    if (JSON.stringify(newRules[editedRuleIndex].data) !== JSON.stringify(newStepsData.data)) {
                         // Update name for custom rules so we can validate rule name correctly
                         newRules[editedRuleIndex].data = newRuleUpdated.data;
                    }

                    setTriggerRules(newRules);
                    setRule({ ...newRuleUpdated });
                    setStepsData({ ...newRuleUpdated });
                    toast.success('Successfully update rule');
                    dispatch(setViewedRule(null, true));
                    dispatch(setRuleHaveEditting({ showLv2: false, typeLv2: '' }));
                    setActiveStep(3);
                    if (activeAccount.id) {
                         clearConversionDraft(activeAccount.id);
                    }
               } else {
                    toastError(response);
               }
          });
     };

     const hanldeRemoveTrigger = ({ triggers, index, remove }) => {
          const flag = conversionRules.some((conversion) => {
               if (JSON.stringify(conversion.conditions) && JSON.stringify(conversion.conditions).includes(triggers[index].id) && triggers[index].id !== '') {
                    setRuleTemp(conversion);
                    return true;
               }
               return false;
          });
          if (flag) {
               setExistedInRules(true);
          } else {
               const flagDestination = dataActionRules.some((item) => {
                    if (JSON.stringify(item.customVariables.triggersName) && JSON.stringify(item.customVariables.triggersName).includes(triggers[index].id) && triggers[index].id !== '') {
                         let _ruleTemp = accountDestinations.find((accDestination) => accDestination.id === item.destinationId);

                         if (_ruleTemp) {
                              item.alias = _ruleTemp.alias;
                         }
                         setRuleTemp(item);
                         return true;
                    }
                    return false;
               });
               if (flagDestination) {
                    setExistedInRules(true);
               } else {
                    remove(index);
               }
          }
          handleHasChange();
     };
     const handleHasChange = () => {
          dispatch(setRuleHaveEditting({ showLv2: true, typeLv2: TYPE_SHOW_UNSAVE_CHANGE.EDIT_LOOKUP }));
     };
     return (
          <CCard className={`cvr-step-2 ${activeStep !== 2 ? 'difference-step' : 'cvr-step-card'}`}>
               <CCardHeader>
                    {activeStep !== 2 ? (
                         <div className="rule-step d-inline-flex justify-content-between w-100">
                              <h5 className="mb-0 inactive">Step 2: Trigger Name & Description</h5>
                              {stepsData.name && (
                                   <div className="d-inline-flex align-items-center">
                                        <span className="given-data mr-3">{stepsData.name}</span>
                                        <CButton className="btn-edit" onClick={() => setActiveStep(2)}>
                                             Edit
                                        </CButton>
                                   </div>
                              )}
                         </div>
                    ) : (
                         <h5>Step 2: Trigger Name & Description</h5>
                    )}
               </CCardHeader>
               {activeStep === 2 ? (
                    <CCardBody>
                         {rulesLoading ? (
                              <CenterSpinner />
                         ) : (
                              <Formik
                                   initialValues={initialValues}
                                   validationSchema={validationSchema}
                                   onSubmit={onSubmit}
                                   validateOnChange={false}
                                   validateOnBlur={false}
                              >
                                   {({ values, errors, handleChange, handleSubmit, setErrors, isSubmitting }) => (
                                        <CForm onSubmit={handleSubmit} noValidate>
                                             <CRow>
                                                  <CCol md="7">
                                                       <CFormGroup>
                                                            <CLabel htmlFor="name">Name this rule set</CLabel>
                                                            <CInput
                                                                 id="name"
                                                                 placeholder="Rule set name"
                                                                 invalid={!!errors.name}
                                                                 disabled={isSubmitting}
                                                                 value={values.name}
                                                                 onChange={(e) => {
                                                                      handleChange(e);
                                                                      if (errors) {
                                                                           setErrors({});
                                                                      }
                                                                      handleHasChange();
                                                                 }}
                                                                 data-lpignore="true"
                                                            />
                                                            <CInvalidFeedback>{errors.name}</CInvalidFeedback>
                                                       </CFormGroup>
                                                  </CCol>
                                                  <CCol md="12">
                                                       <CFormGroup>
                                                            <CLabel htmlFor="description">Describe this rule set</CLabel>
                                                            <CTextarea
                                                                 id="description"
                                                                 placeholder="Enter a description for your own reference..."
                                                                 disabled={isSubmitting}
                                                                 value={values.description}
                                                                 onChange={(e) => {
                                                                      handleChange(e);
                                                                      if (errors) {
                                                                           setErrors({});
                                                                      }
                                                                      handleHasChange();
                                                                 }}
                                                                 data-lpignore="true"
                                                            />
                                                       </CFormGroup>
                                                  </CCol>
                                             </CRow>
                                             <CLabel htmlFor="description">List all of the Triggers you want to manage in this rule.</CLabel>
                                             <FieldArray name="triggers">
                                                  {({ remove, push }) => (
                                                       <>
                                                            {values.triggers.map((row, index) => {
                                                                 const fieldValid =
                                                                      errors.triggers && errors.triggers[index] && errors.triggers[index].name;

                                                                 return (
                                                                      <div key={index}>
                                                                           <div>
                                                                                <CLabel>{`Trigger Name ${index + 1}`}</CLabel>
                                                                           </div>
                                                                           <CRow className="row-region">
                                                                                <CCol md="3" lg="4" xl="4">
                                                                                     <CFormGroup>
                                                                                          <CInput
                                                                                               id={`triggers.${index}.name`}
                                                                                               name={`triggers.${index}.name`}
                                                                                               placeholder="Trigger name"
                                                                                               value={row.name}
                                                                                               invalid={!!fieldValid}
                                                                                               onChange={(e) => {
                                                                                                    handleChange(e);
                                                                                                    if (errors) {
                                                                                                         setErrors({});
                                                                                                         handleHasChange();
                                                                                                    }
                                                                                               }}
                                                                                          />
                                                                                          <CInvalidFeedback>{fieldValid}</CInvalidFeedback>
                                                                                     </CFormGroup>
                                                                                </CCol>
                                                                                <CCol className="d-flex align-items-center field-button-add-delete">
                                                                                     {values.triggers.length > 1 && (
                                                                                          <CButton
                                                                                               onClick={() => {
                                                                                                    hanldeRemoveTrigger({
                                                                                                         triggers: values.triggers,
                                                                                                         index,
                                                                                                         remove,
                                                                                                    });
                                                                                               }}
                                                                                          >
                                                                                               <CIcon
                                                                                                    icon="iconDeleteField"
                                                                                                    className="icon-delete"
                                                                                               />
                                                                                          </CButton>
                                                                                     )}
                                                                                </CCol>
                                                                           </CRow>
                                                                      </div>
                                                                 );
                                                            })}
                                                            <CButton
                                                                 className="btn-add-row mb-3"
                                                                 onClick={() => {
                                                                      push(initialItem);
                                                                      setValidateOnChange(false);
                                                                      handleHasChange();
                                                                 }}
                                                            >
                                                                 <CIcon icon="iconAddField" className="icon-add" />
                                                                 <CLabel className="add-row">ADD ROW</CLabel>
                                                            </CButton>
                                                       </>
                                                  )}
                                             </FieldArray>
                                             <CButton type="submit" className="px-4" color="primary">
                                                  {`SAVE & NEXT`}
                                             </CButton>
                                        </CForm>
                                   )}
                              </Formik>
                         )}
                         {/* <CCol md="1"></CCol>
                            <CCol md="4">
                                <CCard className="justify-content-center align-items-center media-block">
                                    <div className="icon-play"><CIcon icon="arrow-play" className="text-primary" height={30} /></div>
                                </CCard>
                            </CCol> */}
                    </CCardBody>
               ) : (
                    ''
               )}
               <ConfirmSaveChange
                    show={saveChangeModal}
                    onClose={() => setSaveChangeModal(false)}
                    onAccept={onAccept}
                    title="Are You Sure You Want to Save This Change?"
                    isLoading={saveLoading}
               >
                    You are about to update the name/description of this rule. This will not effect any other rule settings.
               </ConfirmSaveChange>
               <VariableInRules show={existedInRules} onClose={() => setExistedInRules(false)} isLoading={saveLoading}>
                    <p>This trigger is used in other rules. You must remove it from all existing rules before you can delete it.</p>
                    {ruleTemp.group === 'conversions' && <CLink as={Link} to={`/triggers-and-conversions/conversion/${ruleTemp.id}`}>View rule</CLink>}
                    {ruleTemp.group === 'dataAction' && <CLink as={Link} to={`/destinations/${ruleTemp.alias}/data-action/${ruleTemp.id}`}>View rule</CLink>}
               </VariableInRules>
          </CCard>
     );
};

EditStep2.propTypes = {
     stepsData: PropTypes.object,
     setStepsData: PropTypes.func,
};

export default EditStep2;
