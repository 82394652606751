import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import Select from 'react-select';
import { RegionContext } from './SingleRow';
import { CInvalidFeedback } from '../../../../../migration/CInvalidFeedback';

const RowWorldWide = () => {
     const { errors } = useFormikContext();
     const { CONSENT_REGION_TYPES, row, index, onChangeRegionType } = useContext(RegionContext);

     const regionTypeRequired = errors.geographicRegions && errors.geographicRegions[index] && errors.geographicRegions[index].regionType;
     const valueRegionType = CONSENT_REGION_TYPES.filter(option => option.value === row.regionType);

     return (
          <>
               <div className='region-type'>
                    <div className='row-block-region'>
                         <Select classNamePrefix='react-select'
                              className={(regionTypeRequired) ? 'is-invalid' : null}
                              value={valueRegionType}
                              name={`regionsType.${index}`}
                              options={CONSENT_REGION_TYPES}
                              onChange={(e) => onChangeRegionType(e.value)}
                         />
                         <CInvalidFeedback>{regionTypeRequired}</CInvalidFeedback>
                    </div>
               </div>
          </>
     )
}
export default RowWorldWide;