exports.blackListCrawl = [
     'google.com',
     'youtube.com',
     'facebook.com',
     'baidu.com',
     'wikipedia.org',
     'qq.com',
     'taobao.com',
     'yahoo.com',
     'tmall.com',
     'amazon.com',
     'twitter.com',
     'sohu.com',
     'live.com',
     'jd.com',
     'vk.com',
     'instagram.com',
     'sina.com.cn',
     'weibo.com',
     'reddit.com',
     'login.tmall.com',
     '360.cn',
     'yandex.ru',
     'linkedin.com',
     'blogspot.com',
     'netflix.com',
     'twitch.tv',
     'whatsapp.com',
     'yahoo.co.jp',
     'csdn.net',
     'alipay.com',
     'microsoftonline.com',
     'naver.com',
     'pages.tmall.com',
     'microsoft.com',
     'livejasmin.com',
     'aliexpress.com',
     'bing.com',
     'ebay.com',
     'github.com',
     'tribunnews.com',
     'google.com.hk',
     'amazon.co.jp',
     'stackoverflow.com',
     'mail.ru',
     'okezone.com',
     'google.co.in',
     'office.com',
     'msn.com',
     'paypal.com',
     'bilibili.com',
     'hao123.com',
     'imdb.com',
     't.co',
     'fandom.com',
     'imgur.com',
     'xhamster.com',
     '163.com',
     'wordpress.com',
     'apple.com',
     'soso.com',
     'google.com.br',
     'booking.com',
     'xinhuanet.com',
     'adobe.com',
     'pinterest.com',
     'amazon.de',
     'amazon.in',
     'dropbox.com',
     'bongacams.com',
     'google.co.jp',
     'babytree.com',
     'detail.tmall.com',
     'tumblr.com',
     'google.ru',
     'google.fr',
     'google.de',
     'so.com',
     'cnblogs.com',
     'quora.com',
     'amazon.co.uk',
     'detik.com',
     'google.cn',
     'bbc.com',
     'force.com',
     'deloplen.com',
     'salesforce.com',
     'pixnet.net',
     'ettoday.net',
     'cnn.com',
     'onlinesbi.com',
     'roblox.com',
     'aparat.com',
     'thestartmagazine.com',
     'bbc.co.uk',
     'google.es',
     'amazonaws.com',
     'google.it',
     'tianya.cn',
     'rakuten.co.jp',
     'chase.com',
     'spotify.com',
     'espn.com',
     'craigslist.org',
     'zhihu.com',
     'soundcloud.com',
     'discordapp.com',
     'jianshu.com',
     'nicovideo.jp',
     'daum.net',
     'medium.com',
     'nytimes.com',
     'cricbuzz.com',
     '1688.com',
     'google.co.uk',
     'stackexchange.com',
     'popads.net',
     'nih.gov',
     'mediafire.com',
     'globo.com',
     'indeed.com',
     'sogou.com',
     'researchgate.net',
     'wetransfer.com',
     'sindonews.com',
     'avito.ru',
     'vimeo.com',
     'hotstar.com',
     'butheptesitrew.pro',
     'uol.com.br',
     'trello.com',
     'google.com.mx',
     'w3schools.com',
     'zhanqi.tv',
     'china.com.cn',
     'gmw.cn',
     'chaturbate.com',
     'zoom.us',
     'godaddy.com',
     'panda.tv',
     'etsy.com',
     'mercadolivre.com.br',
     'slideshare.net',
     'gloyah.net',
     'fc2.com',
     'theguardian.com',
     'walmart.com',
     'alibaba.com',
     'caijing.com.cn',
     'ok.ru',
     'flipkart.com',
     'shutterstock.com',
     'ebay.de',
     'duckduckgo.com',
     'canva.com',
     'ebay.co.uk',
     'bukalapak.com',
     'google.com.tw',
     'freepik.com',
     'amazon.fr',
     'onlinevideoconverter.com',
     'slack.com',
     'amazon.it',
     'google.com.tr',
     'huanqiu.com',
     'dailymotion.com',
     'bankofamerica.com',
     'aliyun.com',
     'steamcommunity.com',
     'espncricinfo.com',
     'speedtest.net',
     'kompas.com',
     'liputan6.com',
     'ltn.com.tw',
     'indiatimes.com',
     'yelp.com',
     'hulu.com',
     'blogger.com',
     'spankbang.com',
     'mama.cn',
     'sciencedirect.com',
     'eastday.com',
     'myshopify.com',
     'twimg.com',
     'gamepedia.com',
     'softonic.com',
     'digikala.com',
     'deviantart.com',
     'vice.com',
     'cnet.com',
     'yts.lt',
     'wellsfargo.com',
     'steampowered.com',
     'wikihow.com',
     'weather.com',
     'okta.com',
     'douban.com',
     'zillow.com',
     'youm7.com',
     'instructure.com',
     'dspmulti.com',
     'mega.nz',
     'iqiyi.com',
     'scribd.com',
     'rednet.cn',
     'cbbp1.com',
     'google.ca',
     'amazon.es',
     'messenger.com',
     'thepiratebay.org',
     'udemy.com',
     'tripadvisor.com',
     'redd.it',
     'mozilla.org',
     'foxnews.com',
     'savefrom.net',
     'archive.org',
     'google.pl',
     'patria.org.ve',
     'livedoor.jp',
     'airbnb.com',
     'gfycat.com',
     'office365.com',
     'dailymail.co.uk',
     'rambler.ru',
     'amazon.ca',
     'google.com.sa',
     'zendesk.com',
     'tistory.com',
     'line.me',
     'setn.com',
     'grid.id',
     'toutiao.com',
     'google.co.th',
     'bet9ja.com',
     'allegro.pl',
     'exoclick.com',
     'google.com.ar',
     'ebay-kleinanzeigen.de',
     'google.com.eg',
     'yy.com',
     'google.co.kr',
     'zoho.com',
     'abs-cbn.com',
     'ikea.com',
     'phengung.net',
     'intuit.com',
     'shopify.com',
     'atlassian.net',
     'gamersky.com',
     'ibps.in',
     'douyu.com',
     'homedepot.com',
     'patreon.com',
     'pixabay.com',
     'sarkariresult.com',
     'google.co.id',
     'bestbuy.com',
     '3dmgame.com',
     'dmm.co.jp',
     'behance.net',
     'youdao.com',
     'kinopoisk.ru',
     '9gag.com',
     '52pojie.cn',
     'varzesh3.com',
     'ilovepdf.com',
     'manoramaonline.com',
     'usps.com',
     'telegram.org',
     'hubspot.com',
     'washingtonpost.com',
     'fiverr.com',
     'breitbart.com',
     'rt.com',
     'momoshop.com.tw',
     'hdfcbank.com',
     'mailchimp.com',
     'xfinity.com',
     'hp.com',
     'livejournal.com',
     'hespress.com',
     'otvfoco.com.br',
     'primevideo.com',
     'pinimg.com',
     'academia.edu',
     'americanexpress.com',
     'elbalad.news',
     'rutracker.org',
     'zol.com.cn',
     'jrj.com.cn',
     'capitalone.com',
     'openload.co',
     'chouftv.ma',
     'smallpdf.com',
     'pixiv.net',
     'box.com',
     'ask.com',
     'reverso.net',
     'ebc.net.tw',
     'bet365.com',
     'forbes.com',
     'ouo.io',
     'wordpress.org',
     'doubleclick.net',
     'suara.com',
     'ups.com',
     'wikimedia.org',
     'cdstm.cn',
     'buzzfeed.com',
     'ameblo.jp',
     'mercadolibre.com.ar',
     'kakaku.com',
     'genius.com',
     '17ok.com',
     'oracle.com',
     '1337x.to',
     'fedex.com',
     'elsevier.com',
     'op.gg',
     'kissanime.ru',
     'mercadolibre.com.mx',
     'namu.wiki',
     'redtube.com',
     'dell.com',
     'patch.com',
     'gismeteo.ru',
     'kumparan.com',
     'healthline.com',
     'youku.com',
     'mi.com',
     'cjl58f3agc.com',
     'bnsjb1ab1e.com',
     'susm0q6jys.com',
     'aol.com',
     'cdninstagram.com',
     'google.com.au',
     'outbrain.com',
     'coinmarketcap.com',
     'taboola.com',
     'namnak.com',
     'ctrip.com',
     'google.com.sg',
     'theepochtimes.com',
     'samsung.com',
     '65vk1fba34.com',
     'dkn.tv',
     '51sole.com',
     'shaparak.ir',
     'baike.com',
     'yr9n47004g.com',
     'ladbible.com',
     'bitly.com',
     'marca.com',
     'upwork.com',
     'accuweather.com',
     'tencent.com',
     '0byv9mgbn0.com',
     'sberbank.ru',
     'playstation.com',
     'alnigeringcer.pro',
     'feedly.com',
     'l6b587txj1.com',
     'siteadvisor.com',
     'cloudfront.net',
     'list.tmall.com',
     'flickr.com',
     'slickdeals.net',
     'businessinsider.com',
     'myanimelist.net',
     'investing.com',
     'wix.com',
     'gamespot.com',
     'zippyshare.com',
     'biobiochile.cl',
     'geeksforgeeks.org',
     'evernote.com',
     'myornamenti.com',
     'divar.ir',
     'asos.com',
     'ensonhaber.com',
     'springer.com',
     'rarbg.to',
     'glassdoor.com',
     'idntimes.com',
     'amazon.cn',
     'uptodown.com',
     'sahibinden.com',
     'leboncoin.fr',
     'sourceforge.net',
     'namasha.com',
     'grammarly.com',
     '104.com.tw',
     'friv.com',
     'livedoor.com',
     'cambridge.org',
     'icicibank.com',
     'yao.tmall.com',
     'gearbest.com',
     'bp.blogspot.com',
     'billdesk.com',
     'sdpnoticias.com',
     'jf71qh5v14.com',
     'theverge.com',
     'icloud.com',
     '360doc.com',
     'wp.pl',
     'citi.com',
     'myway.com',
     'webex.com',
     'elpais.com',
     'onet.pl',
     'uidai.gov.in',
     'irctc.co.in',
     'themeforest.net',
     'pulzo.com',
     'weebly.com',
     'giphy.com',
     'gov.ao',
     'blog.jp',
     'qj.com.cn',
     'goodreads.com',
     'wordreference.com',
     'adp.com',
     'google.com.ua',
     'inquirer.net',
     'ria.ru',
     'goo.ne.jp',
     'userapi.com',
     'alicdn.com',
     'ndtv.com',
     'target.com',
     'usatoday.com',
     'mercari.com',
     'thesaurus.com',
     'googlevideo.com',
     'banggood.com',
     'unsplash.com',
     'ign.com',
     'animeflv.net',
     'olx.pl',
     'weblio.jp',
     'lenta.ru',
     'ptt.cc',
     'google.gr',
     '360.com',
     'albawabhnews.com',
     'verystream.com',
     'repubblica.it',
     'tradingview.com',
     'ecosia.org',
     'metropoles.com',
     'huffpost.com',
     'rediff.com',
     'olx.ua',
     'mlb.com',
     'taleo.net',
     'ouedkniss.com',
     'huawei.com',
     'quizlet.com',
     'mgid.com',
     'nur.kz',
     'fidelity.com',
     'downdetector.com',
     'kaskus.co.id',
     'skype.com',
     'pikabu.ru',
     'crptgate.com',
     'kapanlagi.com',
     'alwafd.news',
     'webmd.com',
     'hatenablog.com',
     'ultimate-guitar.com',
     'tutorialspoint.com',
     'freejobalert.com',
     'y2mate.com',
     'norton.com',
     'asus.com',
     'wowhead.com',
     'wiley.com',
     'mathrubhumi.com',
     'bzw315.com',
     'trendingnow.video',
     'prothomalo.com',
     'bancodevenezuela.com',
     'cnbc.com',
     'kakao.com',
     'discogs.com',
     'wixsite.com',
     'gmx.net',
     '6.cn',
     'surveymonkey.com',
     'artstation.com',
     'asana.com',
     'runoob.com',
     'duba.com',
     'files.wordpress.com',
     'wiktionary.org',
     'memurlar.net',
     'nike.com',
     'autodesk.com',
     'urdupoint.com',
     'gmarket.co.kr',
     'segmentfault.com',
     'google.com.vn',
     'free.fr',
     'ninisite.com',
     'ca.gov',
     'cnzz.com',
     'popcash.net',
     'emol.com',
     'wsj.com',
     'youth.cn',
     'naukri.com',
     'dianping.com',
     'subscene.com',
     'wattpad.com',
     'jw.org',
     'spiegel.de',
     'japanpost.jp',
     'trustpilot.com',
     'gosuslugi.ru',
     'list-manage.com',
     'expedia.com',
     'squarespace.com',
     'telewebion.com',
     'web.de',
     'leagueoflegends.com',
     '3c.tmall.com',
     'pexels.com',
     'hotels.com',
     'gazeta.ru',
     'ebay.it',
     'google.com.pk',
     'sonyliv.com',
     'gmanetwork.com',
     'best2019-games-web1.com',
     'independent.co.uk',
     'crunchyroll.com',
     'as.com',
     'hm.com',
     'incometaxindiaefiling.gov.in',
     'gamer.com.tw',
     'cinecalidad.to',
     'bild.de',
     'bloomberg.com',
     '91jm.com',
     'seznam.cz',
     'flvto.biz',
     'myworkday.com',
     'impress.co.jp',
     'engadget.com',
     'myanmarload.com',
     'nhk.or.jp',
     'ifeng.com',
     'smzdm.com',
     'heavy.com',
     'lun.com',
     'nexusmods.com',
     'internetdownloadmanager.com',
     'tokopedia.com',
     'nyaa.si',
     'alodokter.com',
     'aksam.com.tr',
     'gizmodo.com',
     'dmm.com',
     'gome.com.cn',
     'blog.me',
     'visualstudio.com',
     'heroesofrpg.com',
     'chess.com',
     'v2ex.com',
     't66y.com',
     'google.co.ve',
     'reuters.com',
     'lowes.com',
     'orange.fr',
     'eventbrite.com',
     'nhentai.net',
     'tenki.jp',
     'ebay.com.au',
     'ibm.com',
     'google.co.za',
     '5ch.net',
     'udn.com',
     'stremanp.com',
     'ea.com',
     'viva.co.id',
     'lordsfilm.tv',
     'b3z29k1uxb.com',
     'kooora.com',
     'bandcamp.com',
     'google.cl',
     'lifo.gr',
     'eksisozluk.com',
     'europa.eu',
     'infobae.com',
     'souq.com',
     'justdial.com',
     'blackboard.com',
     'merdeka.com',
     'jb51.net',
     'minecraft.net',
     'quintag.com',
     'wish.com',
     'att.com',
     'oschina.net',
     'agoda.com',
     'indoxxi.studio',
     'jiameng.com',
     'td.com',
     'discover.com',
     'bitbucket.org',
     'issuu.com',
     'cloudflare.com',
     'indiamart.com',
     'beeg.com',
     'drom.ru',
     '126.com',
     'mileroticos.com',
     'costco.com',
     'archiveofourown.org',
     'www.gov.uk',
     'rakuten.com',
     'envato.com',
     'auction.co.kr',
     'chinaz.com',
     'nikkei.com',
     'dcard.tw',
     'interia.pl',
     'mit.edu',
     'wunderground.com',
     'go.com',
     'adaranth.com',
     'google.ro',
     'politico.com',
     'google.com.pe',
     'azure.com',
     'macys.com',
     'merriam-webster.com',
     'n11.com',
     'epfindia.gov.in',
     'storiespace.com',
     'uniqlo.tmall.com',
     'vidio.com',
     'olx.com.br',
     'intel.com',
     'fbsbx.com',
     'kickstarter.com',
     'kknews.cc',
     'ozon.ru',
     'zhaopin.com',
     'filehippo.com',
     'service-now.com',
     'e-hentai.org',
     'thesun.co.uk',
     'kerumal.com',
     'hh.ru',
     'urbandictionary.com',
     'zhiding.cn',
     'sxyprn.com',
     '2movierulz.ac',
     'cisco.com',
     'dcinside.com',
     'ieee.org',
     'rbc.ru',
     'cnnindonesia.com',
     'libero.it',
     'gstatic.com',
     'banesconline.com',
     'pchome.com.tw',
     'realtor.com',
     'joins.com',
     'hotnewhiphop.com',
     'cdiscount.com',
     'naver.jp',
     'newegg.com',
     'rapidgator.net',
     'google.com.co',
     't.me',
     'kijiji.ca',
     'drive2.ru',
     'asahi.com',
     'afreecatv.com',
     'klix.ba',
     'iqoption.com',
     'uptobox.com',
     'duolingo.com',
     'getawesome2.com',
     'in.gr',
     'sputniknews.com',
     'investopedia.com',
     'nba.com',
     'bolasport.com',
     '11st.co.kr',
     'thefreedictionary.com',
     'uzone.id',
     'techradar.com',
     'gsmarena.com',
     'marriott.com',
     'dribbble.com',
     'donga.com',
     'brilio.net',
     'hepsiburada.com',
     'techtudo.com.br',
     'nianhuo.tmall.com',
     'coursera.org',
     'hamariweb.com',
     'npr.org',
     'google.ch',
     'americanas.com.br',
     'vnexpress.net',
     'avgle.com',
     'ecleneue.com',
     'lenovo.com',
     'drudgereport.com',
     'codepen.io',
     'labanquepostale.fr',
     'state.gov',
     'khanacademy.org',
     'canalrcn.com',
     'shopee.tw',
     'dawmal.com',
     'coinbase.com',
     'chatwork.com',
     'ivi.ru',
     'semanticscholar.org',
     'google.co.ao',
     'dytt8.net',
     't-online.de',
     'sozcu.com.tr',
     'hurriyet.com.tr',
     'cctv.com',
     'appledaily.com',
     'huya.com',
     'doc88.com',
     'nownews.com',
     'flaticon.com',
     'subject.tmall.com',
     'apkpure.com',
     'scol.com.cn',
     'ntralpenedhy.pro',
     'motorsport.com',
     'yandex.kz',
     'elmundo.es',
     'arxiv.org',
     'kizlarsoruyor.com',
     'concursolutions.com',
     'fast.com',
     'nature.com',
     '4399.com',
     'binance.com',
     'rutor.info',
     'itmedia.co.jp',
     'google.pt',
     '4pda.ru',
     'blibli.com',
     'storm.mg',
     'verizonwireless.com',
     'express.co.uk',
     'legit.ng',
     'pantip.com',
     'royalbank.com',
     'delta.com',
     'utorrent.com',
     'superuser.com',
     'wildberries.ru',
     'thewhizmarketing.com',
     'feng.com',
     'lifewire.com',
     'corriere.it',
     'turkiye.gov.tr',
     'ebay.fr',
     'mercadolibre.com.ve',
     'akoam.net',
     'abola.pt',
     'doorblog.jp',
     '51cto.com',
     'canada.ca',
     'rarbgprx.org',
     'southwest.com',
     'teamviewer.com',
     'chron.com',
     'caixa.gov.br',
     '58.com',
     'amazon.com.mx',
     'creditonebank.com',
     'sci-hub.tw',
     'cnbeta.com',
     'seasonvar.ru',
     'redfin.com',
     'kompasiana.com',
     'xe.com',
     'coupang.com',
     'shimo.im',
     'cryptobrowser.site',
     'inven.co.kr',
     'pelisplus.co',
     'discuss.com.hk',
     'google.be',
     'paytm.com',
     'thingiverse.com',
     'dafont.com',
     'strava.com',
     'news18.com',
     'getpocket.com',
     'payu.in',
     'setare.com',
     'eyny.com',
     'andhrajyothy.com',
     'timeanddate.com',
     'chiphell.com',
     'qualtrics.com',
     'indiatoday.in',
     'goal.com',
     'bleacherreport.com',
     'hesport.com',
     'gyazo.com',
     'ytimg.com',
     'chip.de',
     'bicentenariobu.com',
     'ccleaner.com',
     'hatena.ne.jp',
     'square-enix.com',
     'pole-emploi.fr',
     'bol.uol.com.br',
     '4chan.org',
     'getbootstrap.com',
     'uniqlo.com',
     'exhentai.org',
     'hupu.com',
     '17track.net',
     'wysasys.com',
     'kayak.com',
     'zara.com',
     'nga.cn',
     'gtarcade.com',
     'food.tmall.com',
     'howtogeek.com',
     'weibo.cn',
     'nypost.com',
     'bankmellat.ir',
     'nvzhuang.tmall.com',
     'cbssports.com',
     'atlassian.com',
     'blizzard.com',
     'axisbank.co.in',
     'polygon.com',
     'moneycontrol.com',
     'prnt.sc',
     'cimaclub.com',
     'lefigaro.fr',
     'vtv.vn',
     'groupon.com',
     'dawn.com',
     'pandora.com',
     'istockphoto.com',
     'myfreecams.com',
     'docusign.net',
     'shopee.co.id',
     'jooble.org',
     'pngtree.com',
     'google.dz',
     'meetup.com',
     'motor1.com',
     'live.net',
     'harvard.edu',
     'kotaku.com',
     'nbcnews.com',
     'wayfair.com',
     'clarin.com',
     'mynet.com',
     'secureserver.net',
     'chegg.com',
     'punchng.com',
     'xbombo.com',
     'westernjournal.com',
     'usaa.com',
     'nate.com',
     'caliente.mx',
     'ruliweb.com',
     'beytoote.com',
     'gogoanime.io',
     'yandex.com',
     'bhphotovideo.com',
     'reallifecam.com',
     'mawdoo3.com',
     'kp.ru',
     'cima4u.tv',
     'telegraph.co.uk',
     'google.at',
     'ccm.net',
     'shein.com',
     'tabelog.com',
     'google.nl',
     'python.org',
     'google.az',
     'err.tmall.com',
     'mayoclinic.org',
     'indoxxi.show',
     'notifications.website',
     'docusign.com',
     'uber.com',
     'propdfconverter.com',
     'fmovies.to',
     'wondershare.com',
     'reclameaqui.com.br',
     'nvidia.com',
     'tandfonline.com',
     'sakshi.com',
     'aa.com',
     'narcity.com',
     'milliyet.com.tr',
     'mptentry.com',
     'bellesa.co',
     '178.com',
     'ytmp3.cc',
     'nextdoor.com',
     'rottentomatoes.com',
     'constantcontact.com',
     'audible.com',
     'epicgames.com',
     'mobile.de',
     'pcgamer.com',
     'jma.go.jp',
     'cam4.com',
     'dw.com',
     'media.tumblr.com',
     'moocauby.com',
     'spectrum.net',
     'elmogaz.com',
     'provincial.com',
     'curseforge.com',
     'subito.it',
     'dictionary.com',
     'marketwatch.com',
     'dailypost.ng',
     'intentmedia.net',
     'prom.ua',
     'eshkol.io',
     'youjizz.com',
     'jumia.com.ng',
     'livescore.com',
     'searchdimension.com',
     'myntra.com',
     'yaplakal.com',
     'dhl.de',
     'xda-developers.com',
     'thehindu.com',
     'hootsuite.com',
     'poste.it',
     'ntdtv.kr',
     'nexon.com',
     'cbsnews.com',
     'fullhdfilmizlesene.net',
     'biblegateway.com',
     'intoday.in',
     'yadi.sk',
     'wp.com',
     'tebyan.net',
     'fazenda.gov.br',
     'ukr.net',
     'bookmyshow.com',
     'basecamp.com',
     'expressvpn.com',
     'makeuseof.com',
     'google.sk',
     'crunchbase.com',
     'jugantor.com',
     'tsinghua.edu.cn',
     'elwatannews.com',
     'neobux.com',
     'socialblade.com',
     'depositphotos.com',
     'ctfile.com',
     'usbank.com',
     'lemonde.fr',
     'lazada.sg',
     'indosport.com',
     'farfetch.com',
     'gamib.com',
     'gitlab.com',
     'fanfiction.net',
     'anjuke.com',
     'battle.net',
     'yallakora.com',
     'creditkarma.com',
     'codecanyon.net',
     'allocine.fr',
     'ithome.com',
     'ruten.com.tw',
];
