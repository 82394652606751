import React, { useState, useContext, useEffect } from 'react';
import { CCard, CCardBody, CButton, CCardHeader } from '@coreui/react';
import PropTypes from 'prop-types';
import CIcon from '@coreui/icons-react';
import cloneDeep from 'lodash/cloneDeep';
import { RuleContext } from '../CreateNewRule';
import { ALLOW_OPTIONS, CONSENT_METHOD } from '../../../../../../constants';
import Select from 'react-select';
import { HoverInfoIconTooltip } from '../../../../../general/custom-tooltips';
// import { useSelector } from 'react-redux';
import { overrideDefaultData1, overrideDefaultData2, overrideDefaultData3 } from './EditStep2';
import { CInput, CInputCheckbox } from '../../../../../migration/CInput';
import CLabel from '../../../../../migration/CLabel';
import CFormGroup from '../../../../../migration/CFormGroup';

const CreateStep2 = ({ stepsData, setStepsData }) => {
     const { activeStep, setActiveStep } = useContext(RuleContext);
     // const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const initialOverrideData = {
          overrideMethod: false,
          overrideMethodData: overrideDefaultData2,
          overrideGPC: false,
          overrideGPCData: overrideDefaultData1,
     };

     const [method, setMethod] = useState('');
     const [isDisable, setIsDisable] = useState(true);
     const [overrideData, setOverrideData] = useState(initialOverrideData);

     useEffect(() => {
          if (!method || (method && method.value === 'explicitConsent')) {
               setOverrideData((data) => ({ ...data, overrideMethodData: overrideDefaultData2 }));
          } else {
               setOverrideData((data) => ({ ...data, overrideMethodData: overrideDefaultData3 }));
          }
     }, [method.value]); // eslint-disable-line react-hooks/exhaustive-deps

     const setConsentMethod = () => {
          if (activeStep === 2 && !method) {
               let consentMethod = cloneDeep(stepsData.consentMethod);

               if (consentMethod) {
                    consentMethod = CONSENT_METHOD.find((c) => c.value === consentMethod);
               }
               setMethod(consentMethod);
          }
     };

     useEffect(setConsentMethod, [activeStep]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleSave = () => {
          setStepsData({
               ...stepsData,
               consentMethod: method.value,
               overrideMethod: overrideData.overrideMethod,
               overrideMethodData: overrideData.overrideMethodData,
               overrideGPC: overrideData.overrideGPC,
               overrideGPCData: overrideData.overrideGPCData,
          });

          setActiveStep(3);
     };

     const handleSelectConsentMethod = (value) => {
          setMethod(value);

          setIsDisable(false);
     };

     const handleChangeOverride = (type, value) => {
          if (type === 'overrideMethod') {
               setOverrideData((data) => ({ ...data, overrideMethod: value }));
          } else {
               setOverrideData((data) => ({ ...data, overrideGPC: value }));
          }
     };

     const handleChangeOverrideData = (type, key, value) => {
          if (type === 'overrideMethodData') {
               setOverrideData((data) => {
                    const newOverrideMethodData = { ...data.overrideMethodData };
                    newOverrideMethodData[key] = value;
                    return { ...data, overrideMethodData: newOverrideMethodData };
               });
          } else {
               setOverrideData((data) => {
                    const newOverrideGPCData = { ...data.overrideGPCData };
                    newOverrideGPCData[key] = value;
                    return { ...data, overrideGPCData: newOverrideGPCData };
               });
          }
     };

     return (
          <CCard className={`cvr-step-2 ${activeStep !== 2 ? 'difference-step' : 'cvr-step-card'}`}>
               <CCardHeader>
                    {activeStep !== 2 ? (
                         <div className='rule-step d-inline-flex justify-content-between w-100'>
                              <h5 className='mb-0 inactive'>Step 2: Consent Method</h5>
                              {stepsData.consentMethod && (
                                   <div className='d-inline-flex align-items-center'>
                                        <CButton className='btn-edit' onClick={() => setActiveStep(2)}>
                                             Edit
                                        </CButton>
                                   </div>
                              )}
                         </div>
                    ) : (
                         <h5>Step 2: Consent Method</h5>
                    )}
               </CCardHeader>
               {activeStep === 2 && (
                    <>
                         <CCardBody>
                              <p>
                                   Select how users must provide consent when this rule applies to them. In the following step you will assign this
                                   rule to specific regions across the world.
                              </p>
                              <div className='d-flex align-items-center mb-4 button-group'>
                                   {CONSENT_METHOD.map((data, index) => {
                                        return (
                                             <div key={index} className='button-wrapper'>
                                                  {/* <CButton
                                                       className={`mr-4 btn-method ${method && method.value === data.value ? 'active' : ''}`}
                                                       variant='outline'
                                                       onClick={() => handleSelectConsentMethod(data)}
                                                       disabled={method.value === data.value}
                                                  >
                                                       {data.title}
                                                       <CIcon icon='icon-info' className='hover-show-tooltip' height={14}></CIcon>
                                                       <div className='block-tooltip'>
                                                            <CIcon icon='arrow-preview' className='arrow-preview'></CIcon>
                                                            <div className='block-tooltip-text'>
                                                                 <p>{data.tooltip}</p>
                                                            </div>
                                                       </div>
                                                  </CButton> */}
                                                  <CFormGroup
                                                       className='create-rule-tab content-c mb-3 include-data-tooltip checkbox-send-data pt-0'
                                                       variant='custom-checkbox'
                                                       inline
                                                  >
                                                       <CInputCheckbox
                                                            custom
                                                            className={`mr-4 radio-method ${method && method.value === data.value ? 'radio-method-active' : ''
                                                                 }`}
                                                            id={data.value}
                                                            checked={method.value === data.value}
                                                            onChange={() => handleSelectConsentMethod(data)}
                                                       // disabled={initialValues.find((item) => item.required === true) ? true : false}
                                                       />
                                                       <CLabel
                                                            className={`${method && method.value === data.value ? 'label-active' : ''}`}
                                                            variant='custom-checkbox'
                                                            htmlFor={data.value}
                                                       >
                                                            {data.title}
                                                       </CLabel>
                                                       <CIcon icon='icon-info' className='hover-show-tooltip' height={14}></CIcon>
                                                       <div className='block-tooltip'>
                                                            <CIcon icon='arrow-preview' className='arrow-preview'></CIcon>
                                                            <div className='block-tooltip-text'>
                                                                 <p>{data.tooltip}</p>
                                                            </div>
                                                       </div>
                                                  </CFormGroup>
                                             </div>
                                        );
                                   })}
                              </div>
                              {method && (
                                   <div className='box-content-description mb-4'>
                                        <p className='mb-0'>{method.description}</p>
                                   </div>
                              )}
                              {/* {!activeAccount.isFree && ( */}
                              <>
                                   {/* Override method */}
                                   <div className='listener-for-gpc'>
                                        <CFormGroup className='pgc-option'>
                                             <CInput
                                                  className='step-2'
                                                  type='checkbox'
                                                  id='overrideMethod'
                                                  checked={overrideData.overrideMethod ? overrideData.overrideMethod : false}
                                                  onChange={(e) => handleChangeOverride('overrideMethod', e.target.checked)}
                                             />
                                             <CLabel className='step-2' htmlFor='overrideMethod'>Override the default Consent Types for this Method</CLabel>
                                             <div className='tooltip-wrapper'>
                                                  <HoverInfoIconTooltip>
                                                       <p>
                                                            By default, each Content Method applies a set of default Consent Types to a user.
                                                            Select this option to view and override the defaults for this rule.
                                                       </p>
                                                  </HoverInfoIconTooltip>
                                             </div>
                                        </CFormGroup>
                                        {overrideData.overrideMethod && (
                                             <div className='listener-for-gpc-open mb-4'>
                                                  <div className='content'>
                                                       <p>
                                                            Use the settings below to change the default Consent Types that are applied within
                                                            this Consent Method for this Regional Rule. These apply to the user as soon as they
                                                            enter your website and only change when they update their consent.
                                                       </p>
                                                  </div>
                                                  <table className='table-box box table-data'>
                                                       <tbody className='css-table-body'>
                                                            {overrideData.overrideMethodData &&
                                                                 Object.keys(overrideData.overrideMethodData).map((key) => {
                                                                      return (
                                                                           <tr key={key} className='css-table-row'>
                                                                                <td className='mb-2'>
                                                                                     <p>{key}</p>
                                                                                     <div className='select-option'>
                                                                                          <Select classNamePrefix='react-select'
                                                                                               options={ALLOW_OPTIONS}
                                                                                               value={ALLOW_OPTIONS.filter(
                                                                                                    (option) =>
                                                                                                         option.value ===
                                                                                                         overrideData.overrideMethodData[key]
                                                                                               )}
                                                                                               onChange={(item) =>
                                                                                                    handleChangeOverrideData(
                                                                                                         'overrideMethodData',
                                                                                                         key,
                                                                                                         item.value
                                                                                                    )
                                                                                               }
                                                                                          />
                                                                                     </div>
                                                                                </td>
                                                                           </tr>
                                                                      );
                                                                 })}
                                                       </tbody>
                                                  </table>
                                             </div>
                                        )}
                                   </div>

                                   {/* Override GPC */}
                                   <div className='listener-for-gpc'>
                                        <CFormGroup className='pgc-option'>
                                             <CInput
                                                  className='step-2'
                                                  type='checkbox'
                                                  id='overrideGPC'
                                                  checked={overrideData.overrideGPC ? overrideData.overrideGPC : false}
                                                  onChange={(e) => handleChangeOverride('overrideGPC', e.target.checked)}
                                             />
                                             <CLabel className='step-2' htmlFor='overrideGPC'>Override Global Privacy Control default settings</CLabel>
                                             <div className='tooltip-wrapper'>
                                                  <HoverInfoIconTooltip>
                                                       <p>
                                                            Some users on your website will have global privacy settings in their browsers. Once
                                                            enabled, we'll listen for Global Privacy Settings and allow you to define how each
                                                            consent type should be treated.
                                                       </p>
                                                  </HoverInfoIconTooltip>
                                             </div>
                                        </CFormGroup>
                                        {overrideData.overrideGPC && (
                                             <div className='listener-for-gpc-open mb-3'>
                                                  <div className='content'>
                                                       <p>
                                                            When GPC is true inside a user's browser, we will set their Consent Types based on
                                                            settings inside your ListenLayer account. Different regions may treat GPC
                                                            differently, so we allow you to overried your default GPC rules based on the region a
                                                            user comes from.
                                                       </p>
                                                       <p>
                                                            Select the allowed/not allowed values you want to apply to a user who visits your
                                                            website, matches this Regional Rule, and have GPC enabled. These settings will
                                                            overried the default Consent Types for this Method (which are shown immediately
                                                            above).
                                                       </p>
                                                  </div>
                                                  <table className='table-box box table-data'>
                                                       <tbody className='css-table-body'>
                                                            {overrideData.overrideGPCData &&
                                                                 Object.keys(overrideData.overrideGPCData).map((key) => {
                                                                      return (
                                                                           <tr key={key} className='css-table-row'>
                                                                                <td className='mb-2'>
                                                                                     <p>{key}</p>
                                                                                     <div className='select-option'>
                                                                                          <Select classNamePrefix='react-select'
                                                                                               options={ALLOW_OPTIONS}
                                                                                               value={ALLOW_OPTIONS.filter(
                                                                                                    (option) =>
                                                                                                         option.value === overrideData.overrideGPCData[key]
                                                                                               )}
                                                                                               onChange={(item) =>
                                                                                                    handleChangeOverrideData(
                                                                                                         'overrideGPCData',
                                                                                                         key,
                                                                                                         item.value
                                                                                                    )
                                                                                               }
                                                                                          />
                                                                                     </div>
                                                                                </td>
                                                                           </tr>
                                                                      );
                                                                 })}
                                                       </tbody>
                                                  </table>
                                             </div>
                                        )}
                                   </div>
                              </>
                              {/* )} */}
                              <CButton className='px-4' color='primary' onClick={handleSave} disabled={isDisable}>
                                   SAVE & NEXT
                              </CButton>
                         </CCardBody>
                    </>
               )
               }
          </CCard>
     );
};

CreateStep2.propTypes = {
     stepsData: PropTypes.object,
     setStepsData: PropTypes.func,
};

export default CreateStep2;
