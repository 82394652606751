
import React from 'react';
import { toast } from 'react-toastify';
import { callTokenApi } from '../../apiCaller';
import { toastError } from '../../utils';
import { InputFilter, BooleanFilter, DateFilter, DropdownFilter } from '../../components/general/filters';
import findIndex from 'lodash/findIndex';

export const enableDisable = ({ endpoint, itemId, enable, next = f => f }) => {
     callTokenApi(`${endpoint}${enable ? 'enable' : 'disable'}`, 'POST', { id: itemId })
          .then(response => {
               if (response.status === 200) {
                    toast.success(`${enable ? 'Enable' : 'Disable'} successfully`);
                    next();
               } else {
                    toastError(response);
                    next();
               }
          })
}

export const deleteItem = ({ endpoint, itemId, next = f => f }) => {
     callTokenApi(`${endpoint}`, 'DELETE', { id: itemId })
          .then(response => {
               if (response.status === 200) {
                    toast.success(`Delete successfully`);
                    next();
               } else {
                    toastError(response);
                    next();
               }
          })
}

export const getColumnFilterSlot = ({ filters, fields, onSelectOperatorChange, onFilterValueChange }) => {
     const getFilterType = (field) => {
          if (typeof filters[field.key] === 'undefined') {
               const theme = JSON.parse(localStorage.getItem('theme'));
               const user = JSON.parse(localStorage.getItem('user'));

               localStorage.clear();
               localStorage.setItem('theme', JSON.stringify(theme));
               localStorage.setItem('user', JSON.stringify(user));
               window.location.reload();
          }

          switch (field.type) {
               case 'text':
                    return (
                         <InputFilter // Operator default is text type
                              filterData={filters[field.key]}
                              onSelectOperatorChange={onSelectOperatorChange}
                              onInputFilterChange={onFilterValueChange}
                         />
                    )
               case 'number':
                    return (
                         <InputFilter
                              type='number'
                              filterData={filters[field.key]}
                              onSelectOperatorChange={onSelectOperatorChange}
                              onInputFilterChange={onFilterValueChange}
                         />
                    )
               case 'boolean':
                    return (
                         <BooleanFilter
                              filterData={filters[field.key]}
                              onChange={onFilterValueChange}
                         />
                    )
               case 'dropdown':
                    return (
                         <DropdownFilter
                              filterData={filters[field.key]}
                              options={field.options}
                              onChange={onFilterValueChange}
                         />
                    )
               case 'date':
                    return (
                         <DateFilter
                              filterData={filters[field.key]}
                              onSelectOperatorChange={onSelectOperatorChange}
                              onDateFilterChange={onFilterValueChange}
                         />
                    )
               default:
                    return (
                         <InputFilter
                              filterData={filters[field.key]}
                              onSelectOperatorChange={onSelectOperatorChange}
                              onInputFilterChange={onFilterValueChange}
                         />
                    )
          }
     }

     const columnFilterSlot = {};

     fields.forEach(field => {
          if (typeof field.filter === 'undefined' || field.filter) {
               columnFilterSlot[field.key] = getFilterType(field)
          }
     })

     return columnFilterSlot;
}

export const adminPusher = {
     updatePublishedAccounts: (publishedAccounts, data) => {
          const { accountId, status } = data;
          const index = findIndex(publishedAccounts, { id: accountId });

          if (index > -1) {
               publishedAccounts[index].status = status;
          }

          return [...publishedAccounts];
     },
}