import { REPORT_DATA_TYPES, REPORT_NAMES, SCOPED } from './analytics';

const getDateFunctionVariables = () => {
     const baseDateFunctionVariables = [
          {
               value: 'LL:fullDateTimestamp',
               description: 'full date and timestamp: Wed Aug 04 2021 21:59:14 GMT+0700 (Indochina Time)',
               hasTimeZone: true,
          },
          { value: 'LL:date', description: 'date: Aug 04 2021', hasTimeZone: true },
          { value: 'LL:dayOfWeek', description: 'day of week: Wed', hasTimeZone: true },
          { value: 'LL:timestamp', description: 'timestamp: 21:59:14', hasTimeZone: true },
          { value: 'LL:timezone', description: 'timezone: GMT+0700', hasTimeZone: true },
          { value: 'LL:timezoneName', description: 'timezone name: (Indochina Time)', hasTimeZone: true },
          { value: 'LL:fullDateTimestampISO', description: '' },
          { value: "LL:fullDateTimestampEpoch (User's Time Zone)", description: '' },
          { value: 'LL:fullDateTimestampEpoch (GMT)', description: '' },
          { value: 'LL:MM/dd/yyyy hh:mm:ss aa (GMT)', description: '' },
          { value: 'LL:yyyy-MM-dd HH:mm:ss (GMT)', description: '' },
          { value: 'LL:yyyy-MM-ddTHH:mm:ss (GMT)', description: '' },
          { value: "LL:yyyy-MM-ddTHH:mm:ss+z (User's Time Zone)", description: '' },
     ];
     let dateFunctionVariables = [];

     baseDateFunctionVariables.forEach((el) => {
          dateFunctionVariables.push(el);

          // if(el.hasTimeZone) {
          //      for(let i = -7; i <= 7; i++) {
          //           dateFunctionVariables.push({...el, value: `${el.value}(timezone:${i.toString()})`});
          //      }
          // }
     });

     return dateFunctionVariables;
};

export * from './apiRest';
export * from './path';
export * from './analytics';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const ADMIN_USER_FILTER = 'adminUserFilter';
export const ADMIN_EVENT_FILTER = 'adminEventFilter';
export const ADMIN_ACTIVITY_FILTER = 'adminActivityFilter';
export const ADMIN_NOTIFICATION_FILTER = 'adminNotificationFilter';
export const ADMIN_ACCOUNT_FILTER = 'adminAccountFilter';
export const ADMIN_LISTENER_FILTER = 'adminListenerFilter';
export const ADMIN_DESTINATION_FILTER = 'adminListenerFilter';
export const TODAY = new Date();

export const LISTENER_TYPE = {
     CONTENT: 'Content',
     USERS_AND_SOURCES: 'Users',
     CLICKS: 'Clicks',
     FORMS: 'Forms',
     CHAT: 'Chats',
     VIDEO: 'Video',
     ECOMMERCE: 'eCommerce',
     SCHEDULING: 'Scheduling',
     NATIVE_DATA: 'Native Data',
};

export const LISTENER_TYPES = Object.entries(LISTENER_TYPE).map(([_, value]) => value);
// export const TIME_ZONES = [
//     { VALUE: "Pacific/Honolulu", NAME: "Honolulu Time" }, // UTC offset: -10:00, UTC DST offset: -10:00
//     { VALUE: "America/Anchorage", NAME: "Anchorage Time" }, // UTC offset: -9:00, UTC DST offset: -8:00
//     { VALUE: "America/Los_Angeles", NAME: "Los Angeles Time" }, // UTC offset: -8:00, UTC DST offset: -7:00
//     { VALUE: "America/Boise", NAME: "Boise Time" }, // UTC offset: -7:00, UTC DST offset: -6:00
//     { VALUE: "America/Denver", NAME: "Denver Time" }, // UTC offset: -7:00, UTC DST offset: -6:00
//     { VALUE: "America/Phoenix", NAME: "Phoenix Time" }, // UTC offset: -7:00, UTC DST offset: -7:00
//     { VALUE: "America/Chicago", NAME: "Chicago Time" }, // UTC offset: -6:00, UTC DST offset: -5:00
//     { VALUE: "America/Detroit", NAME: "Detroit Time" }, // UTC offset: -5:00, UTC DST offset: -4:00
//     { VALUE: "America/New_York", NAME: "New York Time" } // UTC offset: -5:00, UTC DST offset: -4:00
// ]
export const DEFAULT_TIME_ZONE = 'EDT';
export const CARD_TYPES = {
     VISA: {
          NAME: 'Visa',
          REGEX_PREFIX: /^4[0-9]*$/,
          INPUT_MASK: '9999 9999 9999 9999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/visa-card.svg',
     },
     MASTERCARD: {
          NAME: 'MasterCard',
          REGEX_PREFIX: /^(?:5[1-5]|222[1-9]|22[3-9][0-9]|2[3-6]|27[01][0-9]|2720)[0-9]*$/,
          INPUT_MASK: '9999 9999 9999 9999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/card-master-card.svg',
     },
     AMERICAN_EXPRESS: {
          NAME: 'American Express',
          REGEX_PREFIX: /^3[47][0-9]*$/,
          INPUT_MASK: '9999 999999 99999',
          CVC_MASK: '9999',
          CVC_PLACEHOLDER: '0000',
          IMAGE_SRC: '/assets/images/card-amex.svg',
     },
     DISCOVER: {
          NAME: 'Discover',
          REGEX_PREFIX: /^6(?:01[13]|5)[0-9]*$/,
          INPUT_MASK: '9999 9999 9999 9999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/card-amex.svg',
     },
     DINERS_CLUB: {
          NAME: 'Diners Club',
          REGEX_PREFIX: /^3(?:0(?:[0-5]|95)|[8-9])[0-9]*$/,
          INPUT_MASK: '99999 9999 9999999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/card-diners.svg',
     },
     DINERS_CLUB_ENROUTE: {
          NAME: 'Diners Club',
          REGEX_PREFIX: /^2(?:014|149)[0-9]*$/,
          INPUT_MASK: '99999 9999 999999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/card-diners.svg',
     },
     DINERS_CLUB_INTERNATIONAL: {
          NAME: 'Diners Club',
          REGEX_PREFIX: /^36[0-9]*$/,
          INPUT_MASK: '99999 99999 9999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/card-diners.svg',
     },
     DINERS_CLUB_USA_CANADA: {
          NAME: 'Diners Club',
          REGEX_PREFIX: /^5[4-5][0-9]*$/,
          INPUT_MASK: '9999 9999 9999 9999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/card-diners.svg',
     },
     JCB: {
          NAME: 'JCB',
          REGEX_PREFIX: /^(?:2131|1800|35)[0-9]*$/,
          INPUT_MASK: '9999 9999 9999 9999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/card-jcb.svg',
     },
     UNIONPAY: {
          NAME: 'UnionPay',
          REGEX_PREFIX: /^62[0-9]*$/,
          INPUT_MASK: '9999 9999 9999 9999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/card-unionpay.svg',
     },
     MAESTRO: {
          NAME: 'Maestro',
          REGEX_PREFIX: /^(?:5(?:0|[6-8])|6(?:1|[8-9]))[0-9]*$/,
          INPUT_MASK: '9999 9999 9999 9999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
          IMAGE_SRC: '/assets/images/card-maestro.svg',
     },
     OTHER: {
          INPUT_MASK: '9999 9999 9999 9999',
          CVC_MASK: '999',
          CVC_PLACEHOLDER: '000',
     },
};

export const VARIABLE_OPERATORS_NAME = {
     EXISTS_WITH_ANY_VALUE: 'exav',
     DOES_NOT_EXIST: 'nex'
};

export const VARIABLE_OPERATORS = [
     { VALUE: 'ct', TEXT: 'contains' },
     { VALUE: 'nct', TEXT: 'not contain' },
     { VALUE: 'eq', TEXT: 'equals to' },
     { VALUE: 'neq', TEXT: 'not equal to' },
     { VALUE: 'gt', TEXT: 'greater than' },
     { VALUE: 'lt', TEXT: 'less than' },
     { VALUE: 'exav', TEXT: 'exists with any value' },
     { VALUE: 'nex', TEXT: 'does not exist' },
]; // Variable operators of rules

export const VARIABLE_OPERATORS_TRIGGERS = [
     { VALUE: 'ct', TEXT: 'contains' },
     { VALUE: 'nct', TEXT: 'not contain' },
     { VALUE: 'eq', TEXT: 'equals to' },
     { VALUE: 'neq', TEXT: 'not equal to' },
     { VALUE: 'gt', TEXT: 'greater than' },
     { VALUE: 'lt', TEXT: 'less than' },
     { VALUE: 'exav', TEXT: 'exists with any value' },
     { VALUE: 'nex', TEXT: 'does not exist' },
]; // Variable operators of rules
export const VARIABLE_OPERATORS_REPORT = [
     { VALUE: 'ct', TEXT: 'contains' },
     { VALUE: 'nct', TEXT: 'not contain' },
     { VALUE: 'eq', TEXT: 'equals to' },
     { VALUE: 'neq', TEXT: 'not equal to' },
]; // Variable operators of rules
export const OPERATORS_REQUIRE_VALUE = ['ct', 'nct', 'eq', 'neq', 'gt', 'lt']; // Lookup table operators that require the user not to leave empty
export const TRIAL_DAYS = 15;
export const LIMITATION_TYPE = {
     LISTENER: 'listener',
     PREMIUM: 'premium', // Premium listeners & features
     DOMAIN: 'domain',
     CUSTOM_DATALAYER_VARIABLE: 'customDataLayerVariable',
     CUSTOM_VARIABLE_RULE: 'customVariableRule',
     BROWSER_CUSTOM_VARIABLE: 'browserCustomVariable',
     BROWSER_RULE: 'browserRule',
     USER: 'user',
     EVENT: 'event',
     OUT_OF_EVENT: 'outOfEvent',
     MONITORING_RULE: 'monitoringRule',
     UPGRADE_REPORTING: 'reporting',
     SAVE_REPORTING_VALUES: 'save_reporting_values',
     POPULATE_RULE: 'populateRule',
};
export const LOCAL_SHOW_SETUP_ACCOUNTS = 'showSetupAccounts';
export const LOCAL_DIRECT_PATH = 'directPath';
export const LOCAL_TEMP_LIMITATION = 'tempLimitation';
export const LOCAL_SETUP_INVITED_ACCOUNT = 'setupInvitedAccount';
export const LOCAL_SHOW_ONLY_ENABLED_LISTENERS = 'showOnlyEnabledListeners'; // Save filter show only enabled listeners on listener page
export const LOCAL_SHOW_ONLY_ENABLED_DESTINATIONS = 'showOnlyEnabledDestinations'; // Save filter show only enabled listeners on listener page
export const LOCAL_ACCORDION_SHOW = 'accordionShow';
export const BROWSER_STORAGE_TYPE = {
     COOKIE: 'Cookie Storage',
     LOCAL_STORAGE: 'Local Storage',
     SESSION_STORAGE: 'Session Storage',
}; // Used for creating or editing a Browser Storage
export const EXISTING_VALUE_ACTION_OPTIONS = [
     { VALUE: 'overwrite', TEXT: 'Overwrite the existing value' },
     { VALUE: 'do not overwrite', TEXT: 'Do not overwrite existing value' },
     { VALUE: 'add', TEXT: 'Add the new value to the existing value (comma delimited)' },
]; // Used for creating or editing a Browser Storage step 4
export const NOTIFY_ME_OPTIONS = [
     { VALUE: 'every time true', TEXT: 'Every time this Condition is True' },
     { VALUE: 'has not been met', TEXT: 'If the Condition has not been met for a time period' },
]; // Used for creating or editing a Event Monitoring step 2
export const INITIAL_VARIABLE_ROW = {
     type: 'Variable',
     id: '',
     key: '',
     operator: '',
     value: '',
     conjunction: '',
}; // Used for creating or editing conditions of a rule
export const INITIAL_EVENT_ROW = {
     type: 'Event',
     id: '',
     key: '',
     operator: 'eq',
     value: '',
     conjunction: '',
}; // Used for creating or editing conditions of a rule
export const INITIAL_EVENT_NATIVE_ROW = {
     type: 'MyEventNative',
     id: '',
     key: '',
     operator: 'eq',
     value: '',
     conjunction: '',
}; // Used for creating or editing conditions of a rule
export const REGEX_PATTERNS = [
     { PATTERN: `(.*?)`, DESCRIPTION: 'Wildcard - matches any or no value' },
     {
          PATTERN: `^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$`,
          DESCRIPTION: 'Requires at least 1 number and 1 alphabetic character (no special characters, no spaces, no line breaks)',
     },
     { PATTERN: `^[0-9]+$`, DESCRIPTION: 'Only digits' },
     { PATTERN: `^[a-zA-Z]+$`, DESCRIPTION: 'Only alphabetic characters' },
     { PATTERN: `^[a-zA-Z ]+$`, DESCRIPTION: 'Only alphabetic characters and spaces' },
     { PATTERN: `^.{1,}$`, DESCRIPTION: 'Requires 1 or more any characters but line breaks' },
     { PATTERN: `^.{6,7}$`, DESCRIPTION: 'Requires 6 or 7 characters but line breaks' },
     // { PATTERN: `(?=.*\\W])`, DESCRIPTION: 'Requires at least one non-word character exists' },
     { PATTERN: `(?=^.{0,5}$)`, DESCRIPTION: 'String is between 0 and 5 characters but line breaks' },
     { PATTERN: `(?=^.{1,}$)`, DESCRIPTION: 'Matches any character but requires at least 1 character to exist, not including line breaks' },
     {
          PATTERN: `[^\\x00-\\x7F]+\\ *(?:[^\\x00-\\x7F]| )*`,
          DESCRIPTION: 'Matches all non-ASCII characters including spaces until reaching an ASCII character',
     },
];
export const SUN_EDITOR_OPTIONS = {
     mode: 'classic',
     katex: 'window.katex',
     height: 200,
     fontSize: [8, 10, 14, 18, 24, 36],
     fontSizeUnit: 'px',
     formats: ['p', 'blockquote', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
     lineHeights: [
          { text: 'Single', value: 1 },
          { text: 'Double', value: 2 },
     ],
     textStyles: [
          {
               name: 'Code',
               tag: 'code',
          },
     ],
     buttonList: [
          [
               'undo',
               'redo',
               'fontSize',
               'formatBlock',
               'bold',
               'underline',
               'italic',
               'subscript',
               'superscript',
               'fontColor',
               'hiliteColor',
               'textStyle',
               'outdent',
               'indent',
               'align',
               'list',
               'link',
               'fullScreen',
               'codeView',
          ],
     ],
};
export const SUN_EDITOR_ADVANCED_OPTIONS = {
     defaultTag: 'p',
     textTags: {
          bold: 'b',
          underline: 'u',
          italic: 'i',
          strike: 's',
     },
     value: '',
     height: 300,
     mode: 'classic',
     rtl: false,
     toolbarContainer: '#custom_toolbar',
     katex: 'window.katex',
     mathFontSize: [
          {
               text: '1',
               value: '1em',
          },
          {
               text: '2',
               value: '2em',
               default: true,
          },
     ],
     charCounter: true,
     charCounterType: 'char',
     charCounterLabel: 'Characters',
     font: ['Arial', 'tahoma', 'Courier New,Courier'],
     fontSize: [8, 10, 14, 18, 24, 36],
     fontSizeUnit: 'px',
     formats: ['p', 'blockquote', 'div', 'h1', 'h2', 'h3', 'h4', 'h5'],
     videoResizing: false,
     videoFileInput: false,
     tabDisable: false,
     lineHeights: [
          {
               text: 'Single',
               value: 1,
          },
          {
               text: 'Double',
               value: 2,
          },
     ],
     paragraphStyles: [
          'spaced',
          {
               name: 'Box',
               class: '__se__customClass',
          },
     ],
     textStyles: [
          'translucent',
          {
               name: 'Emphasis',
               style: '-webkit-text-emphasis: filled;',
               tag: 'span',
          },
          {
               name: 'Code',
               tag: 'code',
          },
     ],
     linkRelDefault: {},
     buttonList: [
          [
               'undo',
               'redo',
               'font',
               'fontSize',
               'formatBlock',
               'paragraphStyle',
               'blockquote',
               'bold',
               'underline',
               'italic',
               'strike',
               'subscript',
               'superscript',
               'fontColor',
               'hiliteColor',
               'textStyle',
               'removeFormat',
               'outdent',
               'indent',
               'align',
               'horizontalRule',
               'list',
               'lineHeight',
               'table',
               'link',
               'fullScreen',
               'showBlocks',
               'codeView',
               'preview',
          ],
     ],
};

export const INITIAL_INSTRUCTION_POPUP = {
     headline: '',
     text: '',
     videoEmbed: '',
     codeBlock: '',
     button: {
          text: '',
          link: '',
          openNewTab: false,
     },
};
export const NO_OPTION_MESSAGE_TYPE = {
     EVENT: 'event',
     AUTOMATIC_VARIABLES: 'automatic variable',
     CUSTOM_VARIABLE: 'custom variable',
     NATIVE_EVENT: 'native event',
     CUSTOM_BROWSER_VARIABLE: 'custom browser variable',
     NATIVE_BROWSER_STORAGE: 'native browser storage',
     TRIGGERS: 'trigger',
     VARIABLES_TRIGGERS: 'variables-triggers-conversions',
     FORM_CATEGORIES: 'form-categories',
};
export const CHART_COLORS = ['#008FFB', '#FF4560', '#775DD0', '#4CAF50', '#81D4FA', '#546E7A', '#A300D6', '#C7F464', '#2B908F', '#F9A3A4', '#F9C80E'];
export const CHART_VISITOR_SESSION_COLORS = ['#F86624', '#FEB019', '#00E396', '#90EE7E'];
export const VARIABLE_TABLE_LEVELS = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth'];

export const SEND_US_DATA_NOTIFICATION_VIDEO = {
     headline: 'Send Us Data',
     description: 'Watch this video to learn more about how our system receives data from your website.',
     videoLink: 'https://www.youtube.com/embed/gC68-bun4t4/?rel=0',
};

export const NEED_VARIABLE_NOTIFICATION_VIDEO = {
     headline: 'Define Your Global Variables',
     description: 'Watch this video to learn how to create global variables so that you can write Custom Variable Rules and Browser Storage Rules.',
     videoLink: 'https://www.youtube.com/embed/38wbWpnZk7c/?rel=0',
};
export const LOWERCASE_CLICK_FEATURES = ['outboundclick', 'documentlinkclick', 'internallinkclick'];
export const SPECIAL_FEATURE_LIST = [
     'myvalues',
     'customvalues',
     'browserstorage',
     'monitoring',
     'reporting',
     'populate',
     'selector',
     'usersource',
     ...LOWERCASE_CLICK_FEATURES,
];

export const INSTALLATION_SCRIPT_VIDEO = 'https://www.youtube.com/embed/SJlWCARpu5w/?rel=0';
export const INSTALLATION_VIDEO = 'https://player.vimeo.com/video/571501894';
export const INSTALLATION_OVERVIEW = 'https://www.youtube.com/embed/MSz9rDZ8PcY?rel=0&start=20';
export const PUBLISHING_VERSIONS = 'https://www.youtube.com/embed/zSn3qCgZFJE?rel=0';
export const TESTING_YOUR_LISTENER = 'https://www.youtube.com/embed/NZwAhcXy_f4?rel=0';
export const USER_ACCESS_OVERVIEW_VIDEO = 'https://www.youtube.com/embed/meFs7UTKcGY?rel=0';
export const INVOICES_CREDIT_CARD_CHARGES = 'https://www.youtube.com/embed/_Q9OuSUhYDI?rel=0';
export const INTRO_TO_DATA_LAYERS = 'https://www.youtube.com/embed/vVDVKW-p9ic?rel=0';

export const ROADMAP_LEARN_ABOUT_GOAL = 'https://player.vimeo.com/video/871860124';
export const ROADMAP_LEARN_ABOUT_ANALYTICS = 'https://player.vimeo.com/video/871676199';
export const ROADMAP_WEB_CONVERSIONS = 'https://player.vimeo.com/video/871859621';
export const ROADMAP_REVIEW_VISUALIZE_DATA = 'https://player.vimeo.com/video/872028350';
export const ROADMAP_REVEAL_PEOPLE = 'https://player.vimeo.com/video/872134597';
export const ROADMAP_CUSTOM_WEB_DATA = 'https://player.vimeo.com/video/872134569';
export const ROADMAP_ABOUT_CONSENT = 'https://player.vimeo.com/video/872416160';

export const WELCOME_VIDEO = 'https://player.vimeo.com/video/571722098';
export const WELCOME_VIDEO_NEW = 'https://player.vimeo.com/video/871666888?h=85504c7e3f';
export const SEE_HOW_IT_WORKS_VIDEO_LINK =
     'https://player.vimeo.com/video/872026006?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479';
export const DATE_FUNCTION_VARIABLES = getDateFunctionVariables();
export const GVP_FUNCTION_VARIABLES = [
     { value: 'LL:gvp-className', description: ':[Replace with css class of element]', showDes: true },
     { value: 'LL:gvp-id', description: ':[Replace with css id of element]', showDes: true },
     { value: 'LL:gvp-xpath', description: ':"replace with xpath value"', showDes: true },
     { value: 'LL:gvp-className:number', description: ':[Replace with css class of element]', showDes: true },
     { value: 'LL:gvp-id:number', description: ':[Replace with css id of element]', showDes: true },
     { value: 'LL:gvp-xpath:number', description: ':"replace with xpath value"', showDes: true },
];
export const NATIVE_BROWSER_CONDITIONS = {
     DATE_FUNCTION: [
          { VALUE: 'exav', TEXT: 'exists with any value' },
          { VALUE: 'nex', TEXT: 'does not exist' },
          { VALUE: 'itp', TEXT: 'is in the past' },
          { VALUE: 'itft', TEXT: 'is in the future' },
     ],
     DEFAULT: [
          // { VALUE: 'true', TEXT: 'true' },
          // { VALUE: 'false', TEXT: 'false' },
          { VALUE: 'ct', TEXT: 'contains' },
          { VALUE: 'nct', TEXT: 'not contain' },
          { VALUE: 'eq', TEXT: 'equals to' },
          { VALUE: 'neq', TEXT: 'not equal to' },
          { VALUE: 'exav', TEXT: 'exists with any value' },
          { VALUE: 'nex', TEXT: 'does not exist' },
     ],
};
export const SESSION_STORAGE_SESSION_EXPIRED = 'session_expired';
export const SESSION_STORAGE_GOOGLE_INFO = 'session_google_info';
export const SESSION_STORAGE_GOOGLE_REDIRECT_LOGIN = 'session_google_redirect_login';
export const SESSION_STORAGE_GOOGLE_REDIRECT_DATA_USER = 'session_google_redirect_data_user';
export const STEP_PREVIOUS_CREATE_TICKET = 'stepPreviousCreateTicket';
export const HIDE_TICKET_FEATURES = 'hideTicketFeatures';
export const TICKET_STATUSES = ['Open', 'Open', 'Response', 'Reopened', 'Closed'];
export const ACCOUNTS_TRIAL_STATUS = ['No', 'Yes', 'Past'];
export const LOCAL_SUPPORT_PUSHER_REDIRECT = 'support_pusher_redirect';
export const PARTNER_STATUSES = ['Waiting', 'Accepted', 'Rejected'];
export const USER_COURSE_STATUSES = ['In Progress', 'Completed'];
export const SUN_EDITOR_EMPTY_CONTENT = '<p><br></p>';
export const CUSTOM_FORM_TARGET_RULE_OPERATORS = [
     { VALUE: 'nct', TEXT: 'does not contain' },
     { VALUE: 'ct', TEXT: 'contains' },
     { VALUE: 'bw', TEXT: 'begins with' },
     { VALUE: 'em', TEXT: 'exactly matches' },
     { VALUE: 'ew', TEXT: 'ends with' },
];
export const CUSTOM_FORM_DEFAULT_RULES = [
     {
          conjunction: '',
          g0: {
               attribute: 'CSS Class',
               operator: 'ct',
               value: 'listenlayer',
               conjunction: 'or',
          },
          g1: {
               attribute: 'CSS ID',
               operator: 'ct',
               value: 'listenlayer',
               conjunction: '',
          },
     },
];

export const DEFAULT_TARGET_RULES = [
     {
          conjunction: '',
          g0: {
               attribute: '',
               operator: '',
               value: '',
               conjunction: '',
          },
     },
];

export const NOTIFICATION_STATUS = {
     READ: 'Read',
     UNREAD: 'Unread',
};

export const NOTIFICATION_CONFIG = {
     SAVE_NOTIFICATION: 'Save Notification',
     SEND_VIA_EMAIL: 'Send Via Email',
     SEND_VIA_SLACK: 'Send Via Slack',
};

export const LOCAL_GTM_ACCESS_TOKEN = 'gtmAccessToken';
export const LOCAL_GTM_USER_EMAIL = 'gtmUserEmail';

export const ACCOUNT_ANSWER_QUESTION = {
     userWork: 'User Work',
     userSizeCompany: 'User Size Company',
     userDesRole: 'User Describes Role',
};

export const ACCOUNT_DYN_ANSWER_QUESTION = {
     userRevenue: 'User Revenue',
     userIdentify: 'User Identify',
     userCompliant: 'User Compliant',
     userReplace: 'User Replace',
};

export const ACCOUNT_DYN = {
     userAutomates: 'User Automates',
     userCentralize: 'User Centralize',
     userAnalytics: 'User Analytics',
};

export const ACCOUNT_DATA_DYN = {
     userSessionReplay: 'User Session Replay',
     userConsent: 'User Consent',
};

export const ACCOUNT_INFO_STEPS = {
     userWork: 'User Work',
     userSizeCompany: 'User Size Company',
     userDesRole: 'User Describes Role',
     userRevenue: 'User Revenue',
     userIdentify: 'User Identify',
     userCompliant: 'User Compliant',
     userReplace: 'User Replace',
     userAutomates: 'User Automates',
     userCentralize: 'User Centralize',
     userAnalytics: 'User Analytics',
     // userSessionReplay: 'User Session Replay',
     // userConsent: 'User Consent',
};

export const ACCOUNT_DYN_QUESTION = {
     userRevenue: 'User Revenue',
     userIdentify: 'User Identify',
     userCompliant: 'User Compliant',
     userReplace: 'User Replace',
     userAutomates: 'User Automates',
     userCentralize: 'User Centralize',
     userAnalytics: 'User Analytics',
     // userSessionReplay: 'User Session Replay',
     // userConsent: 'User Consent',
};

export const CREATE_ACCOUNT_MAIN_STEPS = {
     ...ACCOUNT_ANSWER_QUESTION,
     ...ACCOUNT_DYN_ANSWER_QUESTION,
     name: 'Name',
     timeZone: 'Time Zone',
     ...ACCOUNT_DYN,
     domain: 'Domain',
     gtmConnection: 'GTM Connection',
     configureMeasurement: 'Configure Measurement',
     chooseDestination: 'Choose Destinations',
     // ...ACCOUNT_DATA_DYN,
     accountPackage: 'Account Package',
};
export const ACCOUNT_INFO_STEPS_ARR = Object.entries(ACCOUNT_INFO_STEPS).map(([_, value]) => value);
export const ACCOUNT_QUESTION_ARR = Object.entries(ACCOUNT_ANSWER_QUESTION).map(([_, value]) => value);
export const ACCOUNT_DYN_QUESTION_ARR = Object.entries(ACCOUNT_DYN_QUESTION).map(([_, value]) => value);
export const CREATE_ACCOUNT_MAIN_STEPS_ARR = Object.entries(CREATE_ACCOUNT_MAIN_STEPS).map(([_, value]) => value);

export const DEFAULT_FILTER_METRICS = [
     {
          label: 'New Users',
          value: 'visitorsStart',
     },
     // {
     //     label: "New Sessions",
     //     value: "sessionsStart"
     // },
     {
          label: 'Active Users',
          value: 'visitors',
     },
     {
          label: 'Sessions',
          value: 'sessions',
     },
];

export const CONSENT_METHOD = [
     {
          title: 'Explicit Consent',
          value: 'explicitConsent',
          description:
               "Explicit Consent means that users must grant you certain rights to track them or collect data. We can listen for a user's consent to be defined or changed - configure the settings under Consent Listener.",
          tooltip: "User's must provide explicit consent for analytics storage when they visit your website.",
     },
     {
          title: 'Implied Consent',
          value: 'impliedConsent',
          description:
               "Implied consent means that users who use your website grant implied permission for you to track their activity. In most cases, you must still give them the ability to remove their consent. We can listen for a user's consent to be defined or changed - configure the settings under Consent Listener.",
          tooltip: 'Consent for analytics storage is implied based on a user visiting and engaging with your website; however, you may be required to provide them with notice and a way to opt out.',
     },
     {
          title: 'Open Consent',
          value: 'openConsent',
          description:
               "Open Consent means that you're free to track user behavior and collect data without adhering to regional consent and data requirements. We encourage you to respect all user's privacy, which is why we provide flexibility in your configuration.",
          tooltip: "User's may be tracked freely without consent requirements.",
     },
];

export const CONSENT_IDENTIFICATION_BUTTON = [
     {
          title: 'Identify Users',
          value: 'identifyVisitors',
          // tooltip: "ListenLayer will identify each unique user, assign them a user ID, and use cookies and other identifiers to measure their activity over time."
     },
     {
          title: 'Anonymize Users',
          value: 'anonymizeVisitors',
          // tooltip: 'Cookies will not be used to identify users over time and all Personal Data features will be disabled. A time-limited hash of characteristics will be used to group multiple events and sessions anonymously against a "user." Each identified "user" will last for no more than 48 hours. All data collected will be anonymous and cannot be traced back to a real person.'
     },
     {
          title: "Don't Track Users",
          value: 'dontTrackVisitors',
          // tooltip: "ListenLayer will not track individual users and will not use cookies or identifiers. Data layer events will fire in the user's browser and events will be counted for aggregate reporting; however some Listeners' data layer activity will generate excessive events. We’ll calculate website users based on the average events from tracked individuals on your website. We do not recommend this setting because we believe privacy compliance can be achieved by using anonymous tracking."
     },
];

export const CONSENT_IDENTIFICATION_LEVEL = [
     {
          title: 'Strong',
          value: 'identifyStrong',
          tooltip: "ListenLayer will identify users based on a first-party cookie made redundant through unique identifiers from the user's device. This allows you to identify users more accurately over time, even in some situations where cookies are not present.",
     },
     {
          title: 'Moderate',
          value: 'identifyModerate',
          tooltip: 'ListenLayer will idenfity users using cookies. If the cookie is removed the user will be assigned a new user ID.',
     },
];

export const CONSENT_REGION_TYPE = [
     { label: 'Country Subregion', value: 'countrySubregion' },
     { label: 'Country', value: 'country' },
     { label: 'Economic Region', value: 'economicRegion' },
     { label: 'Continent', value: 'continent' },
     { label: 'World Wide', value: 'worldWide' },
];

export const CONSENT_TIME_REGIONS = [
     { label: 'United States', value: 'unitedStates' },
     { label: 'North America', value: 'northAmerica' },
     { label: 'Europe', value: 'europe' },
     { label: 'Canada', value: 'canada' },
     { label: 'Australia', value: 'australia' },
];

export const CONSENT_REGION_TYPES = [
     {
          label: 'State/Province',
          value: 'stateProvinces',
     },
     {
          label: 'Country',
          value: 'country',
     },
     {
          label: 'Regulatory Region',
          value: 'regulatoryRegion',
     },
     {
          label: 'Continent',
          value: 'continent',
     },
     {
          label: 'Worldwide',
          value: 'worldWide',
     },
];

export const DATAS_CONTINENT = [
     {
          label: 'Asia',
          value: 'Asia',
     },
     {
          label: 'Africa',
          value: 'Africa',
     },
     {
          label: 'Antarctica',
          value: 'Antarctica',
     },
     {
          label: 'Australia',
          value: 'Australia',
     },
     {
          label: 'Europe',
          value: 'Europe',
     },
     {
          label: 'North America',
          value: 'North America',
     },
     {
          label: 'South America',
          value: 'South America',
     },
];

export const DATAS_COUNTRIES_REGION = [
     'Austria',
     'Belgium',
     'Bulgaria',
     'Croatia',
     'Cyprus',
     'Czechia (Czech Republic)',
     'Denmark',
     'Estonia',
     'Finland',
     'France',
     'Germany',
     'Greece',
     'Hungary',
     'Ireland',
     'Italy',
     'Latvia',
     'Lithuania',
     'Luxembourg',
     'Malta',
     'Netherlands',
     'Poland',
     'Portugal',
     'Romania',
     'Slovakia',
     'Slovenia',
     'Spain',
     'Sweden',
     'United Kingdom',
];
export const LATEST_PACKAGE_VERSION = 3;
export const CONSENT_LISTENER_OPTIONS = [
     // { label: 'Complianz', value: 'complianz' },
     { label: 'Consent Manager', value: 'consentManager' },
     { label: 'Cookiebot', value: 'cookiebot' },
     { label: 'CookieYes', value: 'cookieYes' },
     { label: 'Cookie Notice', value: 'cookieNotice' },
     // { label: 'Ketch', value: 'ketch' },
     { label: 'OneTrust', value: 'oneTrust' },
     // { label: 'TrustArc', value: 'trustArc' },
     // { label: 'Osana', value: 'osana' },
     // { label: 'UserCentrics', value: 'userCentrics' },
     // { label: 'EU Cookie Law', value: 'euCookieLaw' },
];

export const LOCAL_PREVIEW = 'llPreview';
export const LOCAL_BEGIN_TEST = 'llBeginTest';
export const LOCAL_PREVIEW_DOMAIN = 'previewDomain';
export const SCHEDULE_DEMO = 'scheduleDemo';
export const STORE_IN_BROWSER = {
     KEY: 'Store in Browser',
     STORED: 'Stored',
     COOKIE: 'Store in Cookie',
     SESSION: 'Store in Session',
     LOCAL_STORAGE: 'Store in Local Storage',
};
export const COPY_TO_CLIPBOARD = {
     KEY: 'Copy to Clipboard',
     COPIED: 'Copied',
     NAME: 'Copy Name',
     VALUE: 'Copy Value',
     PATH: 'Copy Path',
     OBJECT: 'Copy Object',
};
export const BROWSER_ACTION = {
     KEY: 'browserAction',
     BACK: 'back',
     FORWARD: 'forward',
};

export const CONSENT_TYPE_INIT = [
     { value: 'ad_storage', label: 'ad_storage' },
     { value: 'analytics_storage', label: 'analytics_storage' },
     { value: 'functionality_storage', label: 'functionality_storage' },
     { value: 'personalization_storage', label: 'personalization_storage' },
     { value: 'security_storage', label: 'security_storage' },
];

export const POPUP_TYPE = {
     ENABLE_FEATURE: 'enable-feature',
     DISABLE_FEATURE: 'disable-feature',
};

export const OPTION = {
     FIRST_OPTION: 'first-option',
     SECOND_OPTION: 'second-option',
};

export const ACCORDION_TYPES = {
     CONSENT_LISTENER: 'consent-listener',
     CONSENT_RULES: 'consent-rules',
     CONSENT_PRIVACY: 'consent-privacy',
     CONSENT_SELL: 'consent-sell',
     DATA_DELETION_REQUESTS: 'data-deletion-requests',
     TARGET_FORMS: 'target-forms',
     TARGET_ELEMENT_CLICK: 'target-element-click',
     IFRAME_FORMS: 'iframe-forms',
     GEOGRAPHIC_ACCURACY: 'geographic-accuracy',
     USER_SOURCE_RULES: 'user-source-rules',
     CUSTOM_USER_SOURCES: 'custom-user-sources',
     ADDITIONAL_CODE_REQUIRED: 'additional-code-required',
     NATIVE_DATA_LAYER: 'native-data-layers',
     NATIVE_BROWSER_STORAGE: 'native-browser-storage',
     CLICK_SETTINGS: 'click-settings',
     WEATHER_SETTINGS: 'weather-settings',
     VIDEO_SETTINGS: 'video-settings',
     EVENTS: 'events',
     VARIABLES: 'variables',
     SELECTOR_VARIABLES: 'selector-variables',
     POPULATE_FIELDS: 'populate-fields',
     SELECTOR_VALUES: 'selector-values',
     CUSTOM_VARIABLES: 'custom-variables',
     CUSTOM_VALUE_RULES: 'custom-value-rules',
     BROWSER_STORAGE_VARIABLES: 'browser-storage-variables',
     CONVERSIONS: 'conversions',
     TRIGGERS: 'triggers',
     CUSTOM_MONITORING_RULES: 'custom-monitoring-rules',
     CATEGORIZE_PAGES: 'categorize-pages',
     TRACK_YOUR_ORDER_STATUS_PAGE: 'track-your-order-status-page',
     CLICK_ID_SETTINGS: 'click-id-settings',
     PERSONAL_DATA: 'personal-data',
     ENGAGEMENT_SETTINGS: 'engagement-settings',
     SCROLL_DEPTH_SETTINGS: 'scroll-depth-settings',
     DESTINATION_SEND_DATA_USING: 'destination-send-data-using',
     DESTINATION_GENERAL_SETTINGS: 'destination-general-settings',
     DESTINATION_DATA_ACTIONS: 'destination-data-actions',
     DESTINATION_CONVERSIONS: 'destination-conversions',
     DESTINATION_ECOMMERCE: 'destination-eCommerce',
     GTM_SETTINGS: 'gtm-settings',
     GTM_VARIABLES: 'gtm-variables',
     GTM_TRIGGERS: 'gtm-triggers',
     GTM_TAGS: 'gtm-tags',
     GTM_CHANGELOG: 'gtm-changelog',
     PERSONAL_DATA_GENERAL_SETTINGS: 'personal-data-general-settings',
     PERSONAL_DATA_DATA_ELEMENTS: 'personal-data-data-elements',
     FORM_REQUIRING_ACTION: 'form-requiring-action',
     FORM_CATEGORIES: 'form-categories',
     FORM_INVENTORY: 'form-inventory',
     FORM_RULES: 'form-rules',
     FORM_DEFAULT: 'form-default',
     OTHER_DETAIL_EVENT: 'record-profile-other-detail-event',
     OTHER_DETAIL_PERSON: 'record-profile-other-detail-person',
     REVEAL_PEOPLE: 'reveal-people',
     REVEAL_COMPANIES: 'reveal-companies',
     DIMENSIONS: 'dimensions',
     METRICS: 'metrics',
     COMPANY_DESCRIPTION: 'company-description',
     COMPANY_SIZE: 'company_size',
     COMPANY_INDUSTRY_DETAILS: 'company_industry_details',
     COMPANY_NAICS_DETAILS: 'company-naics-details',
     COMPANY_LOCATION: 'company_location',
     COMPANY_SOCIAL: 'company_social',
     COMPANY_OTHER_DATA: 'company_other_data',
     RECORD_PROFILE_TABLE_COMPANY: 'record_profile_table_company',
     RECORD_PROFILE_TABLE_ALL_PAGE: 'record_profile_table_all_page',
     RECORD_PROFILE_TABLE_SOURCE: 'record_profile_table_source',
     RECORD_PROFILE_TABLE_CONVERSION: 'record_profile_table_conversion',
     RECORD_PROFILE_TABLE_REVEAL_PEOPLE: 'record_profile_table_reveal_people',
     RECORD_PROFILE_TABLE_FORM_LOCATION: 'record_profile_table_form_location',
     PEOPLE_OTHER_DATA: 'people_other_data',
     RECORD_PROFILE_TABLE_EVENT: 'record_profile_table_event',
     RECORD_PROFILE_TABLE_SESSION: 'record_profile_table_session',
     RECORD_PROFILE_TABLE_USER: 'record_profile_table_user',
     RECORD_PROFILE_TABLE_PEOPLE: 'record_profile_table_people',
     RECORD_PROFILE_CHART: 'record_profile_chart',
     RECORD_PROFILE_FORM_STEP: 'record_profile_form_step',
     SEGMENT: 'segment',
     TARGET_ACCOUNTS_FULL_LIST: 'target_accounts_full_list',
     TARGET_ACCOUNTS_ACCOUNT_GROUPS: 'target_accounts_account_groups'
};

export const GTM_WIZARD_STEPS = {
     gtmConnection: 'GTM Connection',
     gtmSelectAccount: 'GTM Select Account',
     gtmSelectContainer: 'GTM Select Container',
     gtmCreateData: 'GTM Create Data', // Inform user we're about to creating variable, trigger or tag in GTM
     gtmRemoveData: 'GTM Remove Data',
     gtmPublishData: 'GTM Publish Data',
     gtmVerifyChanges: 'GTM Verify Changes',
     gtmMakeChanges: 'GTM Make Changes',
     gtmExists: 'GTM Exists',
     gtmSuccess: 'GTM Success',
     gtmUnknownFailure: 'GTM Unknown Failure',
     gtmNoPublishingRights: 'GTM No Publishing Rights',
     gtmPauseTag: 'GTM Pause Tag',
};

export const GTM_WIZARD_TYPE = {
     VARIABLE: 'Variable',
     TRIGGER: 'Trigger',
     TRIGGER_CONVERSION: 'Trigger Conversion',
     DATA_ACTION: 'Data Action',
     EACH_DATA_ACTION: 'Each Data Action',
     DESTINATION_CONVERSION: 'Destination Conversion',
     DESTINATION_ECOMMERCE: 'Destination eCommerce',
};

export const GTM_VARIABLE_IN_LL_TYPE = {
     EVENT: 'Event',
     SPECIFIC_TO_A_LISTENER: 'Specific to a Listener',
     CUSTOM: 'Custom',
     USER_PERSON: 'User Person',
     TRIGGER: 'Trigger',
};
export const DATA_ACTION_TEMPLATES = {
     LINKEDIN_INSIGHTS_TAG: 'Linkedin Insights Tag',
     LINKEDIN_CONVERSION_EVENT: 'Linkedin Conversion Event',
     CUSTOM_SCRIPT: 'Custom Script',
     GADS_REMARKETING_TAG: 'GAds Remarketing Tag',
     CONVERSION_LINKER: 'Conversion Linker',
     GADS_CONVERSION_TRACKING_TAG: 'GAds Conversion Tracking Tag',
     GOOGLE_ANALYTICS_GA4_CONFIGURATION: 'Google Analytics GA4 Configuration',
     GOOGLE_ANALYTICS_GA4_EVENT: 'Google Analytics GA4 Event',
     FACEBOOK_PIXEL: 'Facebook Pixel',
     FACEBOOK_EVENT: 'Facebook Event',
     FACEBOOK_PURCHASE_EVENT: 'Facebook Purchase Event',
     MICROSOFT_UET_TAG: 'MsAds UET Tag',
     MICROSOFT_EVENT_TAG: 'MsAds Event Tag',
     MICROSOFT_PURCHASE_EVENT_TAG: 'MsAds Purchase Event Tag',
};
export const TEMPLATE_DATA_ACTION_OPTIONS = {
     linkedInAds: [
          { value: DATA_ACTION_TEMPLATES.LINKEDIN_INSIGHTS_TAG, label: DATA_ACTION_TEMPLATES.LINKEDIN_INSIGHTS_TAG },
          { value: DATA_ACTION_TEMPLATES.LINKEDIN_CONVERSION_EVENT, label: DATA_ACTION_TEMPLATES.LINKEDIN_CONVERSION_EVENT },
          { value: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT, label: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT },
     ],
     googleAds: [
          { value: DATA_ACTION_TEMPLATES.GADS_REMARKETING_TAG, label: DATA_ACTION_TEMPLATES.GADS_REMARKETING_TAG },
          { value: DATA_ACTION_TEMPLATES.CONVERSION_LINKER, label: DATA_ACTION_TEMPLATES.CONVERSION_LINKER },
          { value: DATA_ACTION_TEMPLATES.GADS_CONVERSION_TRACKING_TAG, label: DATA_ACTION_TEMPLATES.GADS_CONVERSION_TRACKING_TAG },
          { value: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT, label: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT },
     ],
     googleAnalytics4: [
          { value: DATA_ACTION_TEMPLATES.GOOGLE_ANALYTICS_GA4_CONFIGURATION, label: DATA_ACTION_TEMPLATES.GOOGLE_ANALYTICS_GA4_CONFIGURATION },
          { value: DATA_ACTION_TEMPLATES.GOOGLE_ANALYTICS_GA4_EVENT, label: DATA_ACTION_TEMPLATES.GOOGLE_ANALYTICS_GA4_EVENT },
          { value: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT, label: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT },
     ],
     facebookAds: [
          { value: DATA_ACTION_TEMPLATES.FACEBOOK_PIXEL, label: DATA_ACTION_TEMPLATES.FACEBOOK_PIXEL },
          { value: DATA_ACTION_TEMPLATES.FACEBOOK_EVENT, label: DATA_ACTION_TEMPLATES.FACEBOOK_EVENT },
          { value: DATA_ACTION_TEMPLATES.FACEBOOK_PURCHASE_EVENT, label: DATA_ACTION_TEMPLATES.FACEBOOK_PURCHASE_EVENT },
     ],
     microsoftAds: [
          { value: DATA_ACTION_TEMPLATES.MICROSOFT_UET_TAG, label: DATA_ACTION_TEMPLATES.MICROSOFT_UET_TAG },
          { value: DATA_ACTION_TEMPLATES.MICROSOFT_EVENT_TAG, label: DATA_ACTION_TEMPLATES.MICROSOFT_EVENT_TAG },
          { value: DATA_ACTION_TEMPLATES.MICROSOFT_PURCHASE_EVENT_TAG, label: DATA_ACTION_TEMPLATES.MICROSOFT_PURCHASE_EVENT_TAG },
          { value: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT, label: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT },
     ],
     S3: [{ value: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT, label: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT }],
     slack: [{ value: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT, label: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT }],
     Zapier: [{ value: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT, label: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT }],
};
export const CUSTOM_DESTINATION_TEMPLATES = [{ value: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT, label: DATA_ACTION_TEMPLATES.CUSTOM_SCRIPT }];

export const SCOPES_OBJ_REPORT = [
     {
          value: 'Event',
          label: 'Event',
     },
     {
          value: 'Session',
          label: 'Session',
     },
     {
          value: 'User',
          label: 'User',
     },
     {
          value: 'People',
          label: 'People',
     },
];

export const SCOPES_OBJ_DATA_SETTINGS = [
     {
          value: 'Event',
          label: 'Event',
     },
     {
          value: 'Session',
          label: 'Session',
     },
     {
          value: 'User',
          label: 'User',
     },
     {
          value: 'People',
          label: 'People',
     },
     {
          value: 'Eternal Object',
          label: 'Eternal Object',
     },
     {
          value: 'Revealed Companies',
          label: 'Revealed Companies',
     },
];

export const REPORT_TARGET_OPTIONS = [
     {
          value: 'FOL',
          label: "First value in object's life",
     },
     {
          value: 'LOL',
          label: "Latest value in object's life",
     },
     {
          value: 'POL',
          label: "Path of all values in object's life",
     },
     {
          value: 'AOL',
          label: "All of the values in object's life",
     },
];

export const REPORT_TARGET_CONVERSION_OPTION = [
     {
          value: 'LOL_EVENT',
          label: "The Value when the Conversion occurs",
     },
     {
          value: 'FOL',
          label: "The First Value Leading to the Conversion",
     },
     {
          value: 'LOL',
          label: "The Last Value Leading to the Conversions",
     },
     {
          value: 'POL',
          label: "All Values Leading to the Conversion",
     },
];

export const TEMPLATE_GA4_EVENT = [{ value: 'none', label: 'None-Manually Set ID' }];

export const TEMPLATE_FACEBOOK_EVENT = [
     {
          value: 'AddPaymentInfo',
          label: 'AddPaymentInfo',
          parameters: [
               { value: 'content_category', label: 'content_category' },
               { value: 'content_ids', label: 'content_ids' },
               { value: 'contents', label: 'contents' },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
          ],
     },
     {
          value: 'AddToCart',
          label: 'AddToCart',
          parameters: [
               { value: 'content_ids', label: 'content_ids' },
               { value: 'content_name', label: 'content_name' },
               { value: 'content_type', label: 'content_type' },
               { value: 'contents', label: 'contents' },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
          ],
     },
     {
          value: 'AddToWishlist',
          label: 'AddToWishlist',
          parameters: [
               { value: 'content_category', label: 'content_category' },
               { value: 'content_name', label: 'content_name' },
               { value: 'content_ids', label: 'content_ids' },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
          ],
     },
     {
          value: 'CompleteRegistration',
          label: 'CompleteRegistration',
          parameters: [
               { value: 'content_name', label: 'content_name' },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
               { value: 'status', label: 'status' },
          ],
     },
     {
          value: 'Contact',
          label: 'Contact',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
     {
          value: 'CustomizeProduct',
          label: 'CustomizeProduct',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
     { value: 'Donate', label: 'Donate', parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }] },
     {
          value: 'FindLocation',
          label: 'FindLocation',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
     {
          value: 'InitiateCheckout',
          label: 'InitiateCheckout',
          parameters: [
               { value: 'content_category', label: 'content_category' },
               { value: 'content_ids', label: 'content_ids' },
               { value: 'contents', label: 'contents' },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
               { value: 'num_items', label: 'num_items' },
          ],
     },
     {
          value: 'Lead',
          label: 'Lead',
          parameters: [
               { value: 'content_category', label: 'content_category' },
               { value: 'content_name', label: 'content_name' },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
          ],
     },
     {
          value: 'Purchase',
          label: 'Purchase',
          parameters: [
               { value: 'content_name', label: 'content_name' },
               { value: 'content_ids', label: 'content_ids' },
               { value: 'content_type', label: 'content_type' },
               { value: 'contents', label: 'contents' },
               { value: 'currency', label: 'currency', required: true },
               { value: 'value', label: 'value', required: true },
               { value: 'num_items', label: 'num_items' },
          ],
     },
     {
          value: 'Schedule',
          label: 'Schedule',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
     {
          value: 'Search',
          label: 'Search',
          parameters: [
               { value: 'content_category', label: 'content_category' },
               { value: 'content_ids', label: 'content_ids', required: true },
               { value: 'content_type', label: 'content_type', required: true },
               { value: 'search_string', label: 'search_string' },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
          ],
     },
     {
          value: 'StartTrial',
          label: 'StartTrial',
          parameters: [
               { value: 'predicted_ltv', label: 'predicted_ltv' },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
          ],
     },
     {
          value: 'SubmitApplication',
          label: 'SubmitApplication',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
     {
          value: 'Subscribe',
          label: 'Subscribe',
          parameters: [
               { value: 'predicted_ltv', label: 'predicted_ltv' },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
          ],
     },
     {
          value: 'ViewContent',
          label: 'ViewContent',
          parameters: [
               { value: 'content_category', label: 'content_category' },
               { value: 'content_name', label: 'content_name' },
               { value: 'content_ids', label: 'content_ids', required: true },
               { value: 'content_type', label: 'content_type', required: true },
               { value: 'currency', label: 'currency' },
               { value: 'value', label: 'value' },
          ],
     },
     {
          value: 'custom',
          label: 'Custom',
          eventName: '',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
];

export const DEFAULT_VALUE_FACEBOOK_PIXEL = [
     { name: 'email', label: 'email', type: 'automatic', value: '{{personValues.primary.email}}', code: 'em' },
     { name: 'phone', label: 'phone', type: 'automatic', value: '{{personValues.primary.phone}}', code: 'ph' },
     // { name: 'firstName', label: 'first name', type: 'custom', value: '', code: 'fn' },
     // { name: 'lastName', label: 'last name', type: 'custom', value: '', code: 'ln' },
     // { name: 'addressPostalCode', label: 'zip code', type: 'custom', value: '', code: 'zp' },
     // { name: 'addressCountry', label: 'country', type: 'custom', value: '', code: 'country' },
     // { name: 'addressRegion', label: 'state or province', type: 'custom', value: '', code: 'st' },
     // { name: 'addressCity', label: 'city', type: 'custom', value: '', code: 'ct' },
];

export const FACEBOOK_CONVERSION_EVENTS = [
     {
          value: 'CompleteRegistration',
          label: 'CompleteRegistration',
          parameters: [
               { value: 'content_name', label: 'content_name', customValue: '', type: 'custom' },
               { value: 'currency', label: 'currency', type: 'automatic' },
               { value: 'value', label: 'value', type: 'automatic' },
               { value: 'status', label: 'status', customValue: '', type: 'custom' },
          ],
     },
     {
          value: 'Contact',
          label: 'Contact',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
     {
          value: 'FindLocation',
          label: 'FindLocation',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
     {
          value: 'Lead',
          label: 'Lead',
          parameters: [
               { value: 'content_category', label: 'content_category', customValue: '', type: 'custom' },
               { value: 'content_name', label: 'content_name', customValue: '', type: 'custom' },
               { value: 'currency', label: 'currency', type: 'automatic' },
               { value: 'value', label: 'value', type: 'automatic' },
          ],
     },
     {
          value: 'Schedule',
          label: 'Schedule',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
     {
          value: 'Search',
          label: 'Search',
          parameters: [
               { value: 'content_category', label: 'content_category', customValue: '', type: 'custom' },
               { value: 'content_ids', label: 'content_ids', customValue: '', required: true, type: 'custom' },
               { value: 'contents', label: 'contents', type: 'automatic' },
               { value: 'search_string', label: 'search_string', customValue: '', type: 'custom' },
               { value: 'currency', label: 'currency', type: 'automatic' },
               { value: 'value', label: 'value', type: 'automatic' },
          ],
     },
     {
          value: 'StartTrial',
          label: 'StartTrial',
          parameters: [
               { value: 'predicted_ltv', label: 'predicted_ltv', customValue: '', type: 'custom' },
               { value: 'currency', label: 'currency', type: 'automatic' },
               { value: 'value', label: 'value', type: 'automatic' },
          ],
     },
     {
          value: 'SubmitApplication',
          label: 'SubmitApplication',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
     {
          value: 'ViewContent',
          label: 'ViewContent',
          parameters: [
               { value: 'content_category', label: 'content_category', customValue: '', type: 'custom' },
               { value: 'content_name', label: 'content_name', customValue: '', type: 'custom' },
               { value: 'content_ids', label: 'content_ids', required: true, customValue: '', type: 'custom' },
               { value: 'content_type', label: 'content_type', required: true, customValue: '', type: 'custom' },
               { value: 'contents', label: 'contents', type: 'automatic' },
               { value: 'currency', label: 'currency', type: 'automatic' },
               { value: 'value', label: 'value', type: 'automatic' },
          ],
     },
     {
          value: 'Purchase',
          label: 'Purchase',
          parameters: [
               { value: 'content_category', label: 'content_category', customValue: '', type: 'custom' },
               { value: 'content_name', label: 'content_name', customValue: '', type: 'custom' },
               { value: 'content_ids', label: 'content_ids', customValue: '', type: 'custom' },
               { value: 'contents', label: 'contents', type: 'automatic' },
               { value: 'content_type', label: 'content_type', customValue: '', type: 'custom' },
               { value: 'num_items', label: 'num_items', type: 'automatic' },
               { value: 'currency', label: 'currency', type: 'custom', required: true },
               { value: 'value', label: 'value', type: 'custom', required: true },
          ],
     },
     {
          value: 'AddToCart',
          label: 'AddToCart',
          parameters: [
               { value: 'content_name', label: 'content_name', customValue: '', type: 'custom' },
               { value: 'content_type', label: 'content_type', customValue: '', type: 'custom' },
               { value: 'content_ids', label: 'content_ids', customValue: '', type: 'custom' },
               { value: 'contents', label: 'contents', type: 'automatic' },
               { value: 'currency', label: 'currency', type: 'custom' },
               { value: 'value', label: 'value', type: 'custom' },
          ],
     },
     {
          value: 'InitiateCheckout',
          label: 'InitiateCheckout',
          parameters: [
               { value: 'content_category', label: 'content_category', customValue: '', type: 'custom' },
               { value: 'content_ids', label: 'content_ids', customValue: '', type: 'custom' },
               { value: 'contents', label: 'contents', type: 'automatic' },
               { value: 'num_items', label: 'num_items', type: 'custom' },
               { value: 'currency', label: 'currency', type: 'custom' },
               { value: 'value', label: 'value', type: 'custom' },
          ],
     },
     {
          value: 'ViewCart',
          label: 'ViewCart',
          parameters: [
               { value: 'content_category', label: 'content_category', customValue: '', type: 'custom' },
               { value: 'content_name', label: 'content_name', customValue: '', type: 'custom' },
               { value: 'content_type', label: 'content_type', customValue: '', type: 'custom' },
               { value: 'content_ids', label: 'content_ids', customValue: '', type: 'custom' },
               { value: 'contents', label: 'contents', type: 'automatic' },
               { value: 'currency', label: 'currency', type: 'custom' },
               { value: 'value', label: 'value', type: 'custom' },
          ],
     },
     {
          value: 'ViewProduct',
          label: 'ViewProduct',
          parameters: [
               { value: 'content_category', label: 'content_category', customValue: '', type: 'custom' },
               { value: 'content_name', label: 'content_name', customValue: '', type: 'custom' },
               { value: 'content_type', label: 'content_type', customValue: '', type: 'custom' },
               { value: 'content_ids', label: 'content_ids', customValue: '', type: 'custom' },
               { value: 'contents', label: 'contents', type: 'automatic' },
               { value: 'currency', label: 'currency', type: 'custom' },
               { value: 'value', label: 'value', type: 'custom' },
          ],
     },
     {
          value: 'Send Custom Event',
          label: 'Send Custom Event',
          parameters: [{ value: 'custom', label: 'custom', customValue: { parameterName: '', parameterValue: '' } }],
     },
];

export const FACEBOOK_CONVERSION_PARAMETERS = [
     { value: 'content_name', label: 'content_name' },
     { value: 'content_type', label: 'content_type' },
     { value: 'currency', label: 'currency' },
     { value: 'value', label: 'value' },
     { value: 'status', label: 'status' },
     { value: 'content_category', label: 'content_category' },
     { value: 'predicted_ltv', label: 'predicted_ltv' },
     { value: 'content_ids', label: 'content_ids' },
     { value: 'contents', label: 'contents' },
     { value: 'search_string', label: 'search_string' },
     { value: 'num_items', label: 'num_items' },
];
export const MICROSOFT_CONVERSION_PARAMETERS = [
     { value: '', label: 'select' },
     { value: 'event_category', label: 'event_category' },
     { value: 'event_label', label: 'event_label' },
     { value: 'event_value', label: 'event_value' },
     { value: 'currency', label: 'currency' },
];

export const FACEBOOK_TYPE_VALUE = [
     { value: 'automatic', label: 'automatic' },
     { value: 'custom', label: 'custom' },
];
export const FACEBOOK_VALUE = [
     { value: '', label: 'select', code: '' },
     { value: 'email', label: 'email', code: 'em' },
     { value: 'phone', label: 'phone', code: 'ph' },
     { value: 'firstName', label: 'first name', code: 'fn' },
     { value: 'lastName', label: 'last name', code: 'ln' },
     { value: 'gender', label: 'gender', code: 'ge' },
     { value: 'birthdate', label: 'birthdate', code: 'db' },
     { value: 'addressPostalCode', label: 'zip code', code: 'zp' },
     { value: 'addressCountry', label: 'country', code: 'country' },
     { value: 'addressRegion', label: 'state or province', code: 'st' },
     { value: 'addressCity', label: 'city', code: 'ct' },
];
export const MICROSOFT_PARAMETER_OPTIONS = [
     { label: 'select', value: '' },
     { label: 'event_category', value: 'event_category' },
     { label: 'event_label', value: 'event_label' },
];
export const MICROSOFT_PARAMETER_OPTIONS_HAVE_PURCHASE = [
     { label: 'select', value: '' },
     { label: 'event_category', value: 'event_category' },
     { label: 'event_label', value: 'event_label' },
     { label: 'revenue_value', value: 'revenue_value' },
];
export const DEFAULT_CURRENCIES_AND_CODES = [
     { value: 'USD', label: 'USD - US Dollars' },
     { value: 'AED', label: 'AED - United Arab Emirates Dirham' },
     { value: 'ARS', label: 'ARS - Argentine Pesos' },
     { value: 'AUD', label: 'AUD - Australian Dollars' },
     { value: 'BGN', label: 'BGN - Bulgarian Lev' },
     { value: 'BOB', label: 'BOB - Bolivian Boliviano' },
     { value: 'BRL', label: 'BRL - Brazilian Real' },
     { value: 'CAD', label: 'CAD - Canadian Dollars' },
     { value: 'CHF', label: 'CHF - Swiss Francs' },
     { value: 'CLP', label: 'CLP - Chilean Peso' },
     { value: 'CNY', label: 'CNY - Yuan Renminbi' },
     { value: 'COP', label: 'COP - Colombian Peso' },
     { value: 'CZK', label: 'CZK - Czech Koruna' },
     { value: 'DKK', label: 'DKK - Denmark Kroner' },
     { value: 'EGP', label: 'EGP - Egyptian Pound' },
     { value: 'EUR', label: 'EUR - Euros' },
     { value: 'FRF', label: 'FRF - French Francs' },
     { value: 'GBP', label: 'GBP - British Pounds' },
     { value: 'HKD', label: 'HKD - Hong Kong Dollars' },
     { value: 'HRK', label: 'HRK - Croatian Kuna' },
     { value: 'HUF', label: 'HUF - Hungarian Forint' },
     { value: 'IDR', label: 'IDR - Indonesian Rupiah' },
     { value: 'ILS', label: 'ILS - Israeli Shekel' },
     { value: 'INR', label: 'INR - Indian Rupee' },
     { value: 'JPY', label: 'JPY - Japanese Yen' },
     { value: 'KRW', label: 'KRW - South Korean Won' },
     { value: 'LTL', label: 'LTL - Lithuanian Litas' },
     { value: 'MAD', label: 'MAD - Moroccan Dirham' },
     { value: 'MXN', label: 'MXN - Mexican Peso' },
     { value: 'MYR', label: 'MYR - Malaysian Ringgit' },
     { value: 'NOK', label: 'NOK - Norway Kroner' },
     { value: 'NZD', label: 'NZD - New Zealand Dollars' },
     { value: 'PEN', label: 'PEN - Peruvian Nuevo Sol' },
     { value: 'PHP', label: 'PHP - Philippine Peso' },
     { value: 'PKR', label: 'PKR - Pakistan Rupee' },
     { value: 'PLN', label: 'PLN - Polish New Zloty' },
     { value: 'RON', label: 'RON - New Romanian Leu' },
     { value: 'RSD', label: 'RSD - Serbian Dinar' },
     { value: 'RUB', label: 'RUB - Russian Ruble' },
     { value: 'SAR', label: 'SAR - Saudi Riyal' },
     { value: 'SEK', label: 'SEK - Sweden Kronor' },
     { value: 'SGD', label: 'SGD - Singapore Dollars' },
     { value: 'THB', label: 'THB - Thai Baht' },
     { value: 'TRY', label: 'TRY - Turkish Lira' },
     { value: 'TWD', label: 'TWD - New Taiwan Dollar' },
     { value: 'UAH', label: 'UAH - Ukrainian Hryvnia' },
     { value: 'VEF', label: 'VEF - Venezuela Bolivar Fuerte' },
     { value: 'VND', label: 'VND - Vietnamese Dong' },
     { value: 'ZAR', label: 'ZAR - South African Rand' },
];

export const CONVERSION_TYPE = [
     { value: 'primary', label: 'Primary' },
     { value: 'secondary', label: 'Secondary' },
];

export const RULE_GROUP = {
     DATA_LAYER: 'rule',
     BROWSER_STORAGE: 'browser',
     MONITORING: 'monitoring',
     ENGAGEMENT: 'engagement',
     FORM_CATEGORIES: 'formRule',
};

export const NOTICE_TYPE = {
     LISTENER_WARNING: 'listenerWarning',
     LISTENER_COMPLETED: 'listenerCompleted',
     DOMAIN_WARNING: 'domainWarning',
     SCRIPT_WARNING: 'scriptWarning',
};

export const GTM_STATUSES_IN_LL = {
     REGISTERED: 'Registered',
     MODIFIED_IN_LL: 'Modified in LL',
     UNPUBLISHED: 'Unpublished',
     REMOVE_FROM_GTM: 'Remove from GTM',
     PAUSE_FROM_GTM: 'Pause from GTM',
};

export const GTM_ACTIONS = { VERIFY_CHANGES: 'Verify Changes', MAKE_CHANGES: 'Make Changes' };

export const RULE_TYPE = {
     SIMPLE_RULE: 'Simple Rule',
     LOOKUP_RULE: 'Lookup Rule',
};

export const LISTENER_CODE = {
     NATIVE_DATA_LAYERS: 'nativeDataLayers',
     NATIVE_BROWSER_STORAGE: 'nativeBrowserStorage',
     PARDOT_FORM: 'pardotForms',
     ACUITY_SCHEDULING: 'acuityScheduling',
     OUTBOUND_CLICKS: 'outboundClicks',
     INTERNAL_CLICKS: 'internalClicks',
     PDF_CLICKS: 'pdfClicks',
     WOOCOMMERCE: 'woocommerce',
     SHOPIFY: 'shopify',
     CUSTOM_FORMS: 'customForms',
     CLICK_IDS: 'clickIDs',
     GRAVITY_FORMS: 'gravityForms',
     PAGE_VIEW: 'pageView',
     USER_SOURCE: 'userSource',
     HUBSPOT_CTA: 'hubspotCTA',
     URL_PARAMETERS: 'urlParameters',
     USER_GEOLOCATION: 'userGeolocation',
     USER_WEATHER: 'userWeather',
     SESSION_ENGAGEMENT: 'sessionEngagement',
     ELEMENT_CLICK: 'elementClick',
};

export const DESTINATION_CODE = {
     LINKEDIN_ADS: 'linkedInAds',
     GOOGLE_ADS: 'googleAds',
     GA4: 'googleAnalytics4',
     FACEBOOK_ADS: 'facebookAds',
     MICROSOFT_ADS: 'microsoftAds',
};

export const IMAGE_ORDER_STATUS_PAGE = [
     {
          name: 'Step1 Order Status Page',
          url: '/assets/images/Step1OrderStatusPage.png',
     },
     {
          name: 'Step2 Order Status Page',
          url: '/assets/images/Step2OrderStatusPage.png',
     },
     {
          name: 'Step3 Order Status Page',
          url: '/assets/images/Step3OrderStatusPage.png',
     },
];

export const ALLOW_OPTIONS = [
     { label: 'allowed', value: true },
     { label: 'not allowed', value: false },
];

export const VIEW_ALL = 'viewAll';

export const CUSTOM_EVENT_NAMES = {
     //woocommerce
     wooAddPaymentGA4: 'add_payment_info',
     wooAddShippingGA4: 'add_shipping_info',
     wooViewProductPageGA4: 'view_item',
     wooClickProductGA4: 'select_item',
     wooViewProductListGA4: 'view_item_list',
     wooRemoveFromCartGA4: 'remove_from_cart',
     wooBeginCheckoutGA4: 'begin_checkout',
     wooPurchaseGA4: 'purchase',
     wooAddToCartGA4: 'add_to_cart',
     wooViewCartGA4: 'view_cart',
     //shopify
     shopifyAddPaymentGA4: 'add_payment_info',
     shopifyAddShippingGA4: 'add_shipping_info',
     shopifyViewProductPageGA4: 'view_item',
     shopifyClickProductGA4: 'select_item',
     shopifyViewProductListGA4: 'view_item_list',
     shopifyRemoveFromCartGA4: 'remove_from_cart',
     shopifyBeginCheckoutGA4: 'begin_checkout',
     shopifyPurchaseGA4: 'purchase',
     shopifyAddToCartGA4: 'add_to_cart',
     shopifyViewCartGA4: 'view_cart',
};

export const CUSTOM_EVENT_NAMES_V2 = {
     //woocommerce
     wooRemoveFromCart: 'remove_from_cart',
     wooViewItemPage: 'view_item',
     wooAddShipping: 'add_shipping_info',
     wooViewItemList: 'view_item_list',
     wooAddPayment: 'add_payment_info',
     wooViewCart: 'view_cart',
     wooAddToCart: 'add_to_cart',
     wooClickItem: 'select_item',
     wooBeginCheckout: 'begin_checkout',
     wooPurchase: 'purchase',
     //shopify
     shopifyRemoveFromCart: 'remove_from_cart',
     shopifyViewItemPage: 'view_item',
     shopifyAddShipping: 'add_shipping_info',
     shopifyViewItemList: 'view_item_list',
     shopifyAddPayment: 'add_payment_info',
     shopifyViewCart: 'view_cart',
     shopifyAddToCart: 'add_to_cart',
     shopifyClickItem: 'select_item',
     shopifyPurchase: 'purchase',
     shopifyBeginCheckout: 'begin_checkout',
};

export const E_COMMERCE_LISTENER_CODES = ['woocommerce', 'shopify'];

export const E_COMMERCE_EVENT_NATIVE = [
     { label: 'View Product List', value: 'view_item_list' },
     { label: 'Select Product', value: 'select_item' },
     { label: 'View Product', value: 'view_item' },
     { label: 'Add to Cart', value: 'add_to_cart' },
     { label: 'View Cart', value: 'view_cart' },
     { label: 'Remove from Cart', value: 'remove_from_cart' },
     { label: 'Begin Checkout', value: 'begin_checkout' },
     { label: 'Add Shipping Info', value: 'add_shipping_info' },
     { label: 'Add Payment Info', value: 'add_payment_info' },
     { label: 'Purchase', value: 'purchase' },
]

export const SESSION_ENGAGEMENT_DEFAULT_RULES = [
     {
          name: 'Conversions',
          description: 'Session with 1 or more conversions are considered engaged.',
          conditions: [
               {
                    conjunction: '',
                    g0: {
                         conjunction: '',
                         type: 'Conversion Count',
                         operator: 'gt',
                         value: 0,
                         key: '',
                    },
               },
          ],
     },
     {
          name: 'Page Views',
          description: 'Sessions with more than 1 pageView are considered engaged.',
          conditions: [
               {
                    conjunction: '',
                    g0: {
                         conjunction: '',
                         type: 'Page View Count',
                         operator: 'gt',
                         value: 1,
                         key: '',
                    },
               },
          ],
     },
     {
          name: 'Page Engagement',
          description: 'Sessions scroll depth of 20% or more and a visible tab of 30 seconds or more are considered engaged.',
          conditions: [
               {
                    conjunction: '',
                    g0: {
                         conjunction: 'and',
                         type: 'Tab Visible',
                         operator: 'gt',
                         value: 29,
                         key: '',
                    },
                    g1: {
                         conjunction: '',
                         type: 'Scroll Depth',
                         operator: 'gt',
                         value: 19,
                         key: '',
                    },
               },
          ],
     },
];

export const PURCHASE_ECOMMERCE_GA4_FEATURES = ['wooPurchaseGA4', 'shopifyPurchaseGA4'];
export const PURCHASE_ECOMMERCE_GA4_FEATURES_V2 = ['wooPurchase', 'shopifyPurchase'];
export const PERSONAL_DATA_SETTING_OPTIONS = [
     { label: 'cookie', value: 'cookie' },
     { label: 'session storage', value: 'session storage' },
     { label: 'local storage', value: 'local storage' },
];

export const PERSONAL_DATA_SETTINGS = [
     {
          label: 'External ID',
          name: 'externalID',
          hasListening: false,
          hasProvideManually: true,
     },
     {
          label: 'Email Address',
          name: 'email',
          hasListening: true,
          hasProvideManually: true,
     },
     {
          label: 'Phone Number',
          name: 'phone',
          hasListening: true,
          hasProvideManually: false,
     },
     {
          label: 'First Name',
          name: 'firstName',
          hasListening: false,
          hasProvideManually: false,
     },
     {
          label: 'Last Name',
          name: 'lastName',
          hasListening: false,
          hasProvideManually: false,
     },
     {
          label: 'Address: Street',
          name: 'addressStreet',
          hasListening: false,
          hasProvideManually: false,
     },
     {
          label: 'Address: City',
          name: 'addressCity',
          hasListening: false,
          hasProvideManually: false,
     },
     {
          label: 'Address: Region',
          name: 'addressRegion',
          hasListening: false,
          hasProvideManually: false,
     },
     {
          label: 'Address: Country',
          name: 'addressCountry',
          hasListening: false,
          hasProvideManually: false,
     },
     {
          label: 'Address: Postal Code',
          name: 'addressPostalCode',
          hasListening: false,
          hasProvideManually: false,
     },
];

export const FACEBOOK_PARAMETER_OPTIONS = [
     { label: 'select', value: '' },
     { label: 'content_category', value: 'content_category' },
     { label: 'content_ids', value: 'content_ids' },
     { label: 'contents', value: 'contents' },
     { label: 'currency', value: 'currency' },
     { label: 'value', value: 'value' },
     { label: 'content_name', value: 'content_name' },
     { label: 'content_type', value: 'content_type' },
     { label: 'status', value: 'status' },
     { label: 'num_items', value: 'num_items' },
     { label: 'search_string', value: 'search_string' },
     { label: 'predicted_ltv', value: 'predicted_ltv' },
     { label: 'custom', value: 'custom', customValue: { parameterName: '', parameterValue: '' } },
];

export const CUSTOM_DESTINATION_MODAL_STEPS = {
     INTRO: 'Intro',
     NAME_DESTINATION: 'Name Destination',
};

export const DESTINATION_TYPES = ['Ads', 'Analytics', 'Cloud', 'Chat', 'Other'];

export const INIT_PERSONAL_DATA_DEFAULT = {
     status: false,
     hashedData: false,
     events: {
          identifyPerson: {
               customEventName: '',
               pushEveryTime: false,
          },
     },
     settings: {
          externalID: {
               listening: undefined,
               provideManually: false,
               type: '',
               key: '',
          },
          email: {
               listening: true,
               provideManually: false,
               type: '',
               key: '',
          },
          phone: {
               listening: true,
               provideManually: undefined,
               type: '',
               key: '',
          },
          firstName: {
               listening: undefined,
               provideManually: undefined,
               type: '',
               key: '',
          },
          lastName: {
               listening: undefined,
               provideManually: undefined,
               type: '',
               key: '',
          },
          addressStreet: {
               listening: undefined,
               provideManually: undefined,
               type: '',
               key: '',
          },
          addressCity: {
               listening: undefined,
               provideManually: undefined,
               type: '',
               key: '',
          },
          addressRegion: {
               listening: undefined,
               provideManually: undefined,
               type: '',
               key: '',
          },
          addressCountry: {
               listening: undefined,
               provideManually: undefined,
               type: '',
               key: '',
          },
          addressPostalCode: {
               listening: undefined,
               provideManually: undefined,
               type: '',
               key: '',
          },
     },
};

export const RULE_GROUPS = {
     TRIGGERS: 'triggers',
     CONVERSIONS: 'conversions',
     DATA_ACTION: 'dataAction',
};

export const PLATFORM_ACCOUNT_STATUSES = {
     DRAFT: 'Draft',
     SANDBOX: 'Sandbox',
     IN_TRIAL: 'In Trial',
     PAID: 'Paid',
     DORMANT_PERMANENT: 'Dormant (permanent)',
     DORMANT_TEMPORARY: 'Dormant (temporary)',
     CANCELED: 'Canceled',
     CONTRACT_PLAN: 'Contract Plan',
};

export const DORMANT_STATUSES = [PLATFORM_ACCOUNT_STATUSES.DORMANT_PERMANENT, PLATFORM_ACCOUNT_STATUSES.DORMANT_TEMPORARY];

export const FILTER_OPTIONS = {
     SHOW_ALL: 'Show All',
     SHOW_ENABLED: 'Show Enabled',
};

export const DROPDOWN_TOOLTIPS = {
     'Custom Web Data': 'Write rules to generate custom data in your website’s data layer and browser storage.',
     Clicks: 'Automatically track outbound, internal, document, and phone clicks.',
     Content: 'Track page views, routes, and overall user engagement.',
     'Native Data': 'Use custom data and browser storage values inside ListenLayer.',
     Scheduling: 'Track apps that are used for scheduling events and meetings.',
     'Users & Sources': 'Collect data about your website users (traffic source, geo, device, weather, and click ID like gclid and fbclid)',
};

export const COMPONENT_NAME = {
     LISTENER_LIBRARY: 'listener-library',
     DESTINATION_LIBRARY: 'destination-library',
     DATA_ACTION: 'data-action',
     MONITORING_RULE: 'monitoring-rule',
     CUSTOM_DATA_LAYER_RULE: 'custom-data-layer-rule',
     CUSTOM_DATA_LOOKUP_TABLE: 'custom-data-lookup-table',
     CUSTOM_DATA_LOOKUP_SETTING: 'custom-data-lookup-setting',
     CUSTOM_REPORTS: 'custom-reports',
     BROWSER_STORAGE_RULE: 'browser-storage-rule',
     TRIGGER_RULE: 'trigger-rule',
     CONVERSION_RULE: 'conversion-rule',
     CONSENT_RULES: 'consent-rules',
     FORM_REQUIRING_ACTION: 'form-requiring-action',
     FORM_INVENTORY: 'form-inventory',
     FORM_RULE: 'form-rule',
     FORM_RULE_SETTING: 'form-rule-setting',
     FORM_RULE_TABLE: 'form-rule-table',
     USER_SOURCE_RULE: 'user-source-rule',
     USER_SOURCE_RULE_CUSTOM_SETTING: 'user-source-rule-custom-setting',
     USER_SOURCE_RULE_CUSTOM_TABLE: 'user-source-rule-custom-table',
     POPULATE_FIELDS_RULE: 'populate-fields-rule',
     SESSION_ENGAGEMENT_RULE: 'session-engagement-rule',
     CLICK_SETTING_RULE: 'click-setting-rule',
     CLICK_LOOKUP_TABLE: 'click-lookup-table',
     TRIGGER_LOOKUP_TABLE: 'trigger-lookup-table',
     TRIGGER_LOOKUP_SETTING: 'trigger-lookup-setting',
     CONVERSION_LOOKUP_TABLE: 'conversion-lookup-table',
     CONVERSION_LOOKUP_SETTING: 'conversion-lookup-setting',
     RECORD_PROFILE_USER: 'record-profile-user',
     RECORD_PROFILE_EVENT: 'record-profile-event',
     RECORD_PROFILE_EVENT_E_COMMERCE: 'record-profile-event-ecommerce',
     RECORD_PROFILE_SESSION: 'record-profile-session',
     RECORD_PROFILE_PERSON: 'record-profile-person',
     RECORD_PROFILE_COMPANY: 'record-profile-company',
     RECORD_PROFILE_TARGET_ACCOUNT: 'record-profile-target-account',
     GOAL_MANAGE_TYPES: 'goals-manage-types',
     RECORD_PROFILE_FORM: 'record-profile-form',
     DIMENSION_DETAIL: 'dimension-detail',
     METRIC_DETAIL: 'metric-detail',
     CHANNELS: 'channels',
     SEGMENT_DETAIL: 'segment-detail',
     TARGET_ACCOUNTS_FULL_LIST: 'target_accounts_full_list',
     TARGET_ACCOUNTS_FIND_ACCOUNT: 'target_accounts_find_account',
     TARGET_ACCOUNTS_GROUPS: 'target_accounts_group_rule',
};
export const TYPE_SHOW_UNSAVE_CHANGE = {
     CREATE_SIMPLE: 'create-simple',
     EDIT_SIMPLE: 'edit-simple',
     CREATE_LOOKUP: 'create-lookup',
     EDIT_LOOKUP: 'edit-lookup',
     EDIT_TABLE: 'edit-table',
};
export const INVALID_MATCH_BRACKETS_REGEX = /{{([^]*)}/g;

export const ENABLE_SERVER_SIDE = [DESTINATION_CODE.LINKEDIN_ADS, DESTINATION_CODE.GA4, DESTINATION_CODE.FACEBOOK_ADS];

export const HTMLHINT_RULE_SETS = {
     'tagname-lowercase': true,
     'attr-lowercase': true,
     'tag-pair': true,
     'spec-char-escape': true,
     'id-unique': true,
     'src-not-empty': true,
     'attr-no-duplication': true,
     'title-require': true,
     // "jshint": {}
};
export const TYPE_RULE = {
     CONVERSION_RULE: 'conversion-rule',
     USER_SOURCE_RULE: 'user-source-rule',
     CUSTOM_RULE: 'custom-rule',
};

export const STEPS_WIZARD_CATEGORIES = {
     defineYourFormCategories: 'DefineYourFormCategories',
     categorizingYourForms: 'CategorizingYourForms',
     chooseYourCategories: 'ChooseYourCategories',
     chooseYourFallback: 'ChooseYourFallback',
     selectYourFormListener: 'selectYourFormListener',
     identifyYourForms: 'identifyYourForms',
     categorizeAFewForms: 'CategorizeAFewForms',
     manageFormCategories: 'ManageFormCategories',
     yourDone: 'YourDone',
};

export const STEPS_CONFIG_CONVERSION = {
     startedConfigConversion: 'StartedConfigConversion',
     startConfigConversion: 'StartConfigConversion',
     configListenerChat: 'ConfigListenerChat',
     configListenereComnerce: 'ConfigListenereComnerce',
     selectConversions: 'SelectConversions',
     createConversion: 'CreateConversion',
     buildingConversion: 'BuildingConversion',
     completeCreation: 'CompleteCreation',
     configConversionDone: 'ConfigConversionDone',
};

export const STEPS_CUSTOM_FORM = {
     configureCustomForms: 'ConfigureCustomForms',
     identifyYourForms: 'IdentifyYourForms',
     identifyYourFormsTwo: 'IdentifyYourFormsTwo',
     identifyYourFormsThree: 'IdentifyYourFormsThree',
     identifyYourFormsFour: 'IdentifyYourFormsFour',
     identifyTargetForms: 'IdentifyTargetForm',
     IframeForms: 'IframeForms',
     letTest: 'LetTest',
     waitUp: 'WaitUp',
     waitingForData: 'WaitingForData',
     yourAreAlmostDone: 'YouAreAlmostDone',
     congratulations: 'Congratulations',
     manageCustomForms: 'ManageCustomForms',
};

export const IS_LAYOUT_FORM_CATEGORIES = 'isLayoutFormCategories';

export const CUSTOM_PRICING_PACKAGE = {
     id: null,
     friendlyName: 'Business & Enterprise',
     friendlyNameNew: 'Business',
     pricePerYear: 'Custom Pricing',
     pricePerMonth: 348.75,
     shortDescription: `For organizations serious about better insights, attribution, and outcomes.`,
     shortDescriptionDes: `Server-side tagging, supercharged analytics, and attribution.`,
     customPlan: true,
};

export const dataPackage = [
     {
          id: null,
          friendlyName: 'Sandbox',
          pricePerYear: 0,
          pricePerMonth: 0,
          shortDescription: `Perfect for proofs of concept, learning, and testing.`,
     },
     {
          id: null,
          friendlyName: 'Pro 10K',
          pricePerYear: 468,
          pricePerMonth: 39,
          shortDescription: `Take control of your data and distribute from a central source of truth.`,
          numberOfVisitors: 10000,
     },
     {
          id: null,
          friendlyName: 'Pro 25K',
          pricePerYear: 708,
          pricePerMonth: 73.75,
          shortDescription: `Take control of your data and distribute from a central source of truth.`,
          numberOfVisitors: 25000,
     },
     {
          id: null,
          friendlyName: 'Pro 50K',
          pricePerYear: 1188,
          pricePerMonth: 123.75,
          shortDescription: `Take control of your data and distribute from a central source of truth.`,
          numberOfVisitors: 50000,
     },
     {
          id: null,
          friendlyName: 'Pro 75K',
          pricePerYear: 1668,
          pricePerMonth: 173.75,
          shortDescription: `Take control of your data and distribute from a central source of truth.`,
          numberOfVisitors: 75000,
     },
     {
          id: null,
          friendlyName: 'Pro 100K',
          pricePerYear: 2148,
          pricePerMonth: 223.75,
          shortDescription: `Take control of your data and distribute from a central source of truth.`,
          numberOfVisitors: 100000,
     },
     {
          id: null,
          friendlyName: 'Pro 150K',
          pricePerYear: 2988,
          pricePerMonth: 311.25,
          shortDescription: `Take control of your data and distribute from a central source of truth.`,
          numberOfVisitors: 150000,
     },
     {
          id: null,
          friendlyName: 'Pro 200K',
          pricePerYear: 3948,
          pricePerMonth: 411.25,
          shortDescription: `Take control of your data and distribute from a central source of truth.`,
          numberOfVisitors: 200000,
     },
];

export const SWITCH_TYPE_WIZARD_STEP = {
     getStarted: 'Get Started',
     migrate: 'Migrate',
     migrateMakeChanges: 'Migrate Make Changes',
     migrateSuccess: 'Migrate Success',
     gtmConnection: 'GTM Connection',
     gtmSelectAccount: 'GTM Select Account',
     gtmSelectContainer: 'GTM Select Container',
     gtmVerifyChanges: 'GTM Verify Changes',
     gtmSendObject: 'GTM Send Object',
     gtmRemoveObject: 'GTM Remove Object',
     eachMakeChanges: 'Each Make Changes',
     gtmMakeChanges: 'GTM Make Changes',
     gtmUnknownFailure: 'GTM Unknown Failure',
     gtmNoPublishingRights: 'GTM No Publishing Rights',
     gtmSuccess: 'GTM Success',
     finally: 'Finally',
};

export const SETUP_GUIDE_STEP = [
     'guideGetStarted',
     'installAndLearn',
     'customImplementation',
     'forCategorization',
     'dataEnrichment',
     'sendingData',
     // 'destination',
     'endRoadmap',
];

export const ROADMAP_GUIDESTEPS = {
     guideGetStarted: {
          orderNumber: 0,
          name: 'guideGetStarted',
     },
     installAndLearn: {
          orderNumber: 1,
          name: 'installAndLearn',
     },
     customImplementation: {
          orderNumber: 2,
          name: 'customImplementation',
     },
     forCategorization: {
          orderNumber: 3,
          name: 'forCategorization',
     },
     dataEnrichment: {
          orderNumber: 4,
          name: 'dataEnrichment',
     },
     sendingData: {
          orderNumber: 5,
          name: 'sendingData',
     },
     // destination: {
     //     orderNumber: 6,
     //     name: 'destination'
     // },
};
export const PAYMENT_CYCLE = {
     ANNUALLY: 'annually',
     EVERY_6_MONTHS: 'every 6 months',
     QUARTERLY: 'quarterly',
};

export const PAYMENT_TERMS = {
     DUE_UPON_RECEIPT: 'Due upon receipt',
     NET_15: 'net 15',
     NET_30: 'net 30',
};

export const UNIQUE_ID_DIMENSION = {
     EVENT_ID: 'Event ID',
     SESSION_ID: 'Session ID',
     USER_ID: 'User ID',
     PERSON_ID: 'Person ID',
     REVEALED_COMPANY_ID: 'Company ID',
};

export const UNIQUE_ID_PROPERTIES = {
     'Event ID': REPORT_DATA_TYPES[REPORT_NAMES.EVENT_DETAILS].properties.filter((item) => item.key !== 'viewDetail'),
     'Event Conversion': REPORT_DATA_TYPES[REPORT_NAMES.CONVERSION_EVENTS].properties.filter((item) => item.key !== 'viewDetail'),
     'Session ID': REPORT_DATA_TYPES[REPORT_NAMES.SESSION_DETAILS].properties.filter((item) => item.key !== 'viewDetail'),
     'User ID': REPORT_DATA_TYPES[REPORT_NAMES.USER_DETAILS].properties.filter((item) => item.key !== 'viewDetail'),
     'Person ID': REPORT_DATA_TYPES[REPORT_NAMES.PERSON_DETAILS].properties.filter((item) => item.key !== 'viewDetail'),
     'Company ID': REPORT_DATA_TYPES[REPORT_NAMES.COMPANY_DETAILS].properties.filter((item) => item.key !== 'viewDetail'),
};

export const PROPERTIES_WEB_CONVERSION = [
     { key: 'conversionName', label: 'Conversion Name', isDefault: true },
     { key: 'userSourceFirst', label: 'User Source - First (conversion)', isDefault: true },
     { key: 'userSourcePath', label: 'User Source - Path (conversion)', isDefault: true },
     { key: 'userSourceLast', label: 'User Source - Last (conversion)', isDefault: true },
     { key: 'externalId', label: 'External ID', isDefault: true },
     { key: 'email', label: 'Email', isDefault: true },
     { key: 'phone', label: 'Phone', isDefault: true },
     { key: 'firstName', label: 'First Name', isDefault: true },
     { key: 'lastName', label: 'Last Name', isDefault: true },
     { key: 'conversionValue', label: 'Conversion Value', isDefault: true },
     { key: 'conversionCurrency', label: 'Conversion Currency', isDefault: true }
];

export const UNIQUE_ID_SCOPED = {
     'Event ID': SCOPED.EVENT,
     'Session ID': SCOPED.SESSION,
     'User ID': SCOPED.USER,
     'Person ID': SCOPED.PEOPLE,
     'Company ID': SCOPED.COMPANY,
};

export const LIST_PROPERTIES = [
     ...REPORT_DATA_TYPES[REPORT_NAMES.SESSION_DETAILS].properties,
     ...REPORT_DATA_TYPES[REPORT_NAMES.USER_DETAILS].properties,
     ...REPORT_DATA_TYPES[REPORT_NAMES.PERSON_DETAILS].properties,
     ...REPORT_DATA_TYPES[REPORT_NAMES.COMPANY_DETAILS].properties,
     ...REPORT_DATA_TYPES[REPORT_NAMES.EVENT_DETAILS].properties,
];

export const UNIQUE_ID_DIMENSIONS = Object.entries(UNIQUE_ID_DIMENSION).map(([_, value]) => value);

export const HIDE_CONTRACT_PLANS = [
     '61a32c39-c888-4be5-a5ba-59acbd416b9f',
     '31b074b1-a5db-4dd6-bbb2-ad8056e3270a',
     '63ed3638-e2c0-4032-943b-6918148e8d4e',
];

export const JOB_STATUS = {
     RUNNING: 'running',
     PAUSED: 'paused',
     CANCELED: 'canceled',
     DONE: 'done',
};

export const BQ_JOB_STATUS = {
     IN_PROGRESS: 'in-progress',
     FAILED: 'failed',
     CANCELED: 'canceled',
     DONE: 'done',
};

export const BQ_JOB_ACTION = {
     QUERY_REPORT: 'query-report',
     QUERY_REPORT_WITH_SEGMENT: 'query-report-with-segment',
     CREATE_SEGMENT: 'create-segment',
};

export const SHOW_SAVED_VIEW = 'show-saved-view';

export const CRAWLER_PREFIX = 'screen1-crawler';
