import cookie from 'js-cookie';
import { LOCAL_SHOW_SETUP_ACCOUNTS, LOCAL_DIRECT_PATH, SESSION_STORAGE_SESSION_EXPIRED } from '../constants';
import { clearLocal, getLocalItem, removeTokenCookie } from '../utils';

// authenticate user by passing data to cookie and localstorage during signin
export const authenticate = (response, next = f => f) => {
     const { token, user } = response.data;

     let tokenOptions = { expires: 1 };

     if (window.location.hostname !== 'localhost') {
          tokenOptions.domain = 'listenlayer.com';
     }

     cookie.set('token', token, tokenOptions);
     if (user) {
          localStorage.setItem('user', JSON.stringify(user));
     }
     next();
};

export const authenticateLoginAs = (response, next = f => f) => {
     const { token, user } = response.data;
     var inFiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);

     let tokenOptions = { expires: inFiveMinutes };

     if (window.location.hostname !== 'localhost') {
          tokenOptions.domain = 'listenlayer.com';
     }

     cookie.set('token', token, tokenOptions);
     if (user) {
          localStorage.setItem('user', JSON.stringify(user));
     }
     next();
};
// access user info from localstorage
export const isAuth = () => {
     if (window !== 'undefined') {
          const cookieChecked = cookie.get('token');
          if (cookieChecked) {
               return true;
          }
     }
};

export const signout = (sessionExpired) => {
     removeTokenCookie();
     clearLocal();
     localStorage.setItem(LOCAL_DIRECT_PATH, '/data-layer');

     if (sessionExpired === true) {
          sessionStorage.setItem(SESSION_STORAGE_SESSION_EXPIRED, JSON.stringify(true));
     }

     window.location.replace("/login");
};

export const updateUser = (response, next) => {
     if (typeof window !== 'undefined') {
          let auth = JSON.parse(localStorage.getItem('user'));
          auth = response.data.user;
          localStorage.setItem('user', JSON.stringify(auth));
     }
     next();
};

export const handleInvitedUser = (userId) => {
     let localShowSetupAccounts = getLocalItem(LOCAL_SHOW_SETUP_ACCOUNTS);

     if (localShowSetupAccounts && localShowSetupAccounts.length > 0) {
          // localShowSetupAccounts must be an array with length > 0
          localShowSetupAccounts.push({ userId, isInvited: 'yes' })
          localStorage.setItem(LOCAL_SHOW_SETUP_ACCOUNTS, JSON.stringify(localShowSetupAccounts));
     } else {
          // localShowSetupAccounts === null || localShowSetupAccounts === []
          localStorage.setItem(LOCAL_SHOW_SETUP_ACCOUNTS, JSON.stringify([{ userId, isInvited: 'yes' }]));
     }
}