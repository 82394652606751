import React, { useState } from 'react';
import {
    CModal,
    CModalBody
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { DATE_FUNCTION_VARIABLES, NO_OPTION_MESSAGE_TYPE } from '../../../../../../constants';
import { NoOptionsMessage } from '../../../../../general/rules'
import { CInput } from '../../../../../migration/CInput';

const InsertVariableModal = (props) => {
    const {
        showVariableModal,
        closeVariableModal,
        handleChangeRowData,
        variableOptions,
        variablePositionToInsert,
        browserVariableShortCodes,
        cursorPosition,
        tableRow
    } = props;
    const [activeBoxType, setActiveBoxType] = useState('');
    const [variableBoxFilter, setVariableBoxFilter] = useState(''); // Current text in the insert data layer variable box filter

    let variableBoxFilterLowerCase = variableBoxFilter.toLowerCase();

    // Automatic variable options which are displayed in insert data layer variable box
    let variableOptionsFound = variableOptions.filter(option => (option.fullName.toLowerCase().includes(variableBoxFilterLowerCase) || (option.friendlyName !== '' && option.friendlyName.toLowerCase().includes(variableBoxFilterLowerCase))));

    // Date function variable options which are displayed in insert a function box
    let functionOptionsFound = DATE_FUNCTION_VARIABLES.filter(option => option.value.toLowerCase().includes(variableBoxFilterLowerCase));

    let browserVariableOptionsFound = browserVariableShortCodes.filter(option => option.toLowerCase().includes(variableBoxFilterLowerCase));

    const handleCloseVariableModal = () => {
        closeVariableModal();
        setVariableBoxFilter('');
    }

    // Add [automatic variable] to value input
    const handleInsertVariable = ({ textToInsert, rowIndex, colIndex }) => {
        let valueToChange = tableRow[rowIndex].customVariables[colIndex].value; // [automatic variable] will be inserted to this value

        if (cursorPosition === 0) {
            // Cursor position currently is at the start of the text area
            valueToChange = `{{${textToInsert}}}${valueToChange}`;
        } else if (cursorPosition === valueToChange.length || cursorPosition === -1) {
            // Cursor position currently is at the end of the text area
            // or there's no cursor position
            valueToChange += `{{${textToInsert}}}`;
        } else {
            // Cursor position currently is somewhere in the middle of the text area
            let beforeCursorValue = valueToChange.substring(0, cursorPosition);
            let afterCursorValue = valueToChange.substring(cursorPosition);
            valueToChange = `${beforeCursorValue}{{${textToInsert}}}${afterCursorValue}`; // Insert [automatic variable] between beforeCursorValue and afterCursorValue
        }

        handleCloseVariableModal();
        setActiveBoxType('');
        handleChangeRowData(valueToChange, 'customVariables', rowIndex, colIndex)
    }

    return (
        <div className="insert-variable-modal">
            <CModal
                visible={showVariableModal}
                onClose={handleCloseVariableModal}
                portal={false}
            >
                <CModalBody>
                    <CIcon icon="cil-x" onClick={handleCloseVariableModal} className="icon-close-popup" />
                    <div className="insert-automatic-variables no-options">
                        {
                            activeBoxType ? (
                                <>
                                    <button
                                        onClick={() => setActiveBoxType('')}
                                        className="btn-back"
                                    >
                                        <i className="fal fa-angle-left"></i>
                                        <span>Back</span>
                                    </button>
                                    {
                                        (activeBoxType === 'automatic') && (
                                            (variableOptions.length > 0) ? (
                                                <>
                                                    <div className="variable-filter">
                                                        <h6>Insert Data Layer Variable</h6>
                                                        <CInput
                                                            type="text"
                                                            value={variableBoxFilter}
                                                            onChange={e => setVariableBoxFilter(e.target.value)}
                                                            data-lpignore="true"
                                                            placeholder="Type here to filter the list…"
                                                        />
                                                    </div>
                                                    <ul>
                                                        {
                                                            variableOptionsFound.length > 0 ? (
                                                                variableOptionsFound.map(option => (
                                                                    <li
                                                                        key={option.id}
                                                                        onClick={e => handleInsertVariable({
                                                                            textToInsert: option.fullName,
                                                                            rowIndex: variablePositionToInsert.rowIndex,
                                                                            colIndex: variablePositionToInsert.colIndex
                                                                        })}
                                                                    >
                                                                        {option.friendlyName !== '' ? option.friendlyName : option.fullName}
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <li className="no-options">No options found.</li>
                                                            )
                                                        }
                                                    </ul>
                                                </>
                                            ) : (
                                                <>
                                                    <br />
                                                    <NoOptionsMessage type={NO_OPTION_MESSAGE_TYPE.VARIABLES_TRIGGERS} />
                                                </>
                                            )
                                        )
                                    }
                                    {
                                        (activeBoxType === 'function') && (
                                            (DATE_FUNCTION_VARIABLES.length > 0) ? (
                                                <>
                                                    <div className="variable-filter">
                                                        <h6>Insert a Function</h6>
                                                        <CInput
                                                            type="text"
                                                            value={variableBoxFilter}
                                                            onChange={e => setVariableBoxFilter(e.target.value)}
                                                            data-lpignore="true"
                                                            placeholder="Type here to filter the list…"
                                                        />
                                                    </div>
                                                    <ul>
                                                        {
                                                            functionOptionsFound.length > 0 ? (
                                                                functionOptionsFound.map(option => (
                                                                    <li
                                                                        key={option.value}
                                                                        onClick={e => handleInsertVariable({
                                                                            textToInsert: option.value,
                                                                            rowIndex: variablePositionToInsert.rowIndex,
                                                                            colIndex: variablePositionToInsert.colIndex
                                                                        })}
                                                                    >
                                                                        {option.value}
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <li className="no-options">No options found.</li>
                                                            )
                                                        }
                                                    </ul>
                                                </>
                                            ) : (
                                                <>
                                                    <br />
                                                    <span className="no-options">No options available.</span>
                                                </>
                                            )
                                        )
                                    }
                                    {
                                        (activeBoxType === 'browser storage') && (
                                            (browserVariableShortCodes.length > 0) ? (
                                                <>
                                                    <div className="variable-filter">
                                                        <h6>Insert Browser Storage Variable</h6>
                                                        <CInput
                                                            type="text"
                                                            value={variableBoxFilter}
                                                            onChange={e => setVariableBoxFilter(e.target.value)}
                                                            data-lpignore="true"
                                                            placeholder="Type here to filter the list…"
                                                        />
                                                    </div>
                                                    <ul>
                                                        {
                                                            browserVariableOptionsFound.length > 0 ? (
                                                                browserVariableOptionsFound.map(option => (
                                                                    <li
                                                                        key={option}
                                                                        onClick={e => handleInsertVariable({
                                                                            textToInsert: option,
                                                                            rowIndex: variablePositionToInsert.rowIndex,
                                                                            colIndex: variablePositionToInsert.colIndex
                                                                        })}
                                                                    >
                                                                        {option}
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <li className="no-options">No options found.</li>
                                                            )
                                                        }
                                                    </ul>
                                                </>
                                            ) : (
                                                <>
                                                    <br />
                                                    <NoOptionsMessage type={NO_OPTION_MESSAGE_TYPE.CUSTOM_BROWSER_VARIABLE} />
                                                </>
                                            )
                                        )
                                    }
                                </>
                            ) : (
                                <ul>
                                    <li onClick={() => setActiveBoxType('automatic')}>
                                        Insert Data Layer Variable
                                    </li>
                                    <li onClick={() => setActiveBoxType('function')}>
                                        Insert a Function
                                    </li>
                                    <li onClick={() => setActiveBoxType('browser storage')}>
                                        Insert Browser Storage Variable
                                    </li>
                                </ul>
                            )
                        }
                    </div>
                </CModalBody>
            </CModal>
        </div>
    )
}

export default InsertVariableModal
