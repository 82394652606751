import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'; // useSelector & useDispatch can only be used in functional component

import { API_CLIENT_INVOICES } from '../../../../constants';
import { replaceText } from '../../../../helpers/cms/subscriber';
import { setEstimatedTotalEvents, setInvoices } from '../../../../actions/subscriber';
import dayjs from 'dayjs';
import { callTokenApi } from '../../../../apiCaller';
import { useIsLatestPlan, useMainInvoiceItems } from '../../../../helpers/customHooks';
import LegacyPlanRecommendation from './LegacyPlanRecommendation';

const EventLimitationHit = () => {
     const dispatch = useDispatch();
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const packages = useSelector(state => state.subscriber.packages);
     const nextPackageIndex = useSelector(state => state.subscriber.nextPackageIndex);
     const invoices = useSelector(state => state.subscriber.invoices);
     const estimatedTotalEvents = useSelector(state => state.subscriber.estimatedTotalEvents);
     const [isInvoicesFetched, setIsInvoicesFetched] = useState(false);
     const { accountNumberOfInvoiceItemsPerMonth, packageNumberOfInvoiceItemsPerMonth } = useMainInvoiceItems();
     const isLatestPlan = useIsLatestPlan();
     const nextPackage = packages[nextPackageIndex];

     const fetchInvoices = () => {
          if (!isInvoicesFetched) {
               callTokenApi(`${API_CLIENT_INVOICES}${activeAccount.id}`, 'GET', null)
                    .then(response => {
                         if (response.status === 200) {
                              setIsInvoicesFetched(true);
                              dispatch(setInvoices(response.data.invoices));
                         }
                    })
          }
     }

     useEffect(fetchInvoices);

     const getRemainingDays = (dueDate) => {
          let now = activeAccount.timeZone ? dayjs().tz(activeAccount.timeZone) : dayjs();
          dueDate = activeAccount.timeZone ? dayjs(dueDate).tz(activeAccount.timeZone) : dayjs(dueDate);
          let remainingTime = dueDate.diff(now, 'day', true).toFixed(4);
          if (remainingTime < 0) return 0;
          return remainingTime;
     }

     const getInvoiceUsedTime = (beginDate) => {
          let now = activeAccount.timeZone ? dayjs().tz(activeAccount.timeZone) : dayjs();
          beginDate = activeAccount.timeZone ? dayjs(beginDate).tz(activeAccount.timeZone) : dayjs(beginDate);
          let usedTime = now.diff(beginDate, 'day', true).toFixed(4);
          if (usedTime < 0) return 0;
          return usedTime;
     }

     const estimateTotalUse = (beginDate, dueDate) => {
          let usedTime = getInvoiceUsedTime(beginDate);
          let remainingDays = getRemainingDays(dueDate);
          let rateOfUse = (accountNumberOfInvoiceItemsPerMonth / usedTime).toFixed(2);
          let estimatedUsages = accountNumberOfInvoiceItemsPerMonth + Math.round(rateOfUse * remainingDays);
          dispatch(setEstimatedTotalEvents(estimatedUsages));
          return estimatedUsages;
     }

     const estimateExtraEvents = (beginDate, dueDate) => {
          let estimatedTotalEvents = estimateTotalUse(beginDate, dueDate);
          let estimatedExtraEvent = estimatedTotalEvents - packageNumberOfInvoiceItemsPerMonth > 0 ? estimatedTotalEvents - packageNumberOfInvoiceItemsPerMonth : 0;
          return estimatedExtraEvent;
     }

     const estimateExtraCost = () => {
          let estimatedExtraEvent = estimatedTotalEvents - packageNumberOfInvoiceItemsPerMonth > 0 ? estimatedTotalEvents - packageNumberOfInvoiceItemsPerMonth : 0;
          let estimatedExtraCost = 0;
          if (estimatedExtraEvent > 0) {
               estimatedExtraCost = estimatedExtraEvent * activeAccount.packageIncrementalCostOfEvents;
               estimatedExtraCost = estimatedExtraCost.toFixed(2);
          }
          let strReturn = `$${estimatedExtraCost.toLocaleString('en-US')}`;
          return strReturn;
     }

     const {
          packageNumberOfEventsPerMonth: currentPackageNumberOfEventsPerMonth,
          packageIncrementalCostOfEvents: currentPackageIncrementalCostOfEvents,
          packageNumberOfVisitors: currentPackageNumberOfVisitors,
          packageLowEventInterfaceNotification,
          packageLowEventInterfaceRecommendation,
     } = activeAccount;

     const {
          friendlyName: nextPackageFriendlyName,
          pricePerMonth: nextPackagePricePerMonth,
          numberOfEventsPerMonth: nextPackageNumberOfEventsPerMonth,
          numberOfVisitors: nextPackageNumberOfVisitorsPerMonth
     } = nextPackage;

     const lowEventNotificationArr = [
          { searchvalue: '[MONTHLY EVENTS]', newValue: currentPackageNumberOfEventsPerMonth.toLocaleString('en-US') },
          { searchvalue: '[MONTHLY VISITORS]', newValue: currentPackageNumberOfVisitors.toLocaleString('en-US') },
          { searchvalue: '[METERED EVENT PRICE]', newValue: currentPackageIncrementalCostOfEvents },
          { searchvalue: '[ESTIMATED NUMBER OF EXTRA EVENTS]', newValue: invoices.length > 0 ? estimateExtraEvents(invoices[0].from, invoices[0].to).toLocaleString('en-US') : '' },
          { searchvalue: '[ESTIMATED NUMBER OF EXTRA VISITORS]', newValue: invoices.length > 0 ? estimateExtraEvents(invoices[0].from, invoices[0].to).toLocaleString('en-US') : '' },
          { searchvalue: '[ESTIMATED EXTRA EVENTS COST]', newValue: invoices.length > 0 ? estimateExtraCost() : 0 },
          { searchvalue: '[ESTIMATED EXTRA VISITORS COST]', newValue: invoices.length > 0 ? estimateExtraCost() : 0 },
     ]

     const lowEventRecommendationArr = [
          { searchvalue: '[next plan friendly name]', newValue: nextPackageFriendlyName },
          { searchvalue: '[next plan 12 month monthly cost]', newValue: nextPackagePricePerMonth.toLocaleString('en-US') },
          { searchvalue: '[next plan number of events per month]', newValue: nextPackageNumberOfEventsPerMonth.toLocaleString('en-US') },
          { searchvalue: '[next plan number of users per month]', newValue: nextPackageNumberOfVisitorsPerMonth.toLocaleString('en-US') }
     ]

     let lowEventNotificationHTML = replaceText(packageLowEventInterfaceNotification, lowEventNotificationArr);
     let lowEventRecommendationHTML = replaceText(packageLowEventInterfaceRecommendation, lowEventRecommendationArr);

     return (
          <>
               <div className="description mb-5">
                    <p
                         className="text-center fw-4 f-14"
                         dangerouslySetInnerHTML={{ __html: lowEventNotificationHTML }}
                    >
                    </p>
               </div>
               <div className="sidebar-body">
                    <p className="text-success text-center recommendation">Recommendation</p>
                    {
                         isLatestPlan ? (
                              <p
                                   className="text-center recommendation-text"
                                   dangerouslySetInnerHTML={{ __html: lowEventRecommendationHTML }}
                              >
                              </p>
                         ) : <LegacyPlanRecommendation />
                    }
               </div>
          </>
     )
}

export default EventLimitationHit;