import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import Headline from '../../../../../../general/Headline';
import { useDispatch, useSelector } from 'react-redux';
import CenterSpinner from '../../../../../../general/Loadings/CenterSpinner';
import { callTokenApi } from '../../../../../../../apiCaller';
import { API_CLIENT_CHANNELS_DETAIL, TYPE_SHOW_UNSAVE_CHANGE } from '../../../../../../../constants';
import { toastError } from '../../../../../../../utils';
import { setRuleHaveEditting } from '../../../../../../../actions/common';

const Step1 = React.lazy(() => import('./steps/step1'));
const Step2 = React.lazy(() => import('./steps/step2'));

export const ChannelDetailContext = React.createContext({});

const DEFAULT_CHANNEL_DATA = {
     name: '',
     description: '',
     sourcesAvailable: [],
     sourcesInside: [],
};

const ChannelDetail = ({ id }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountId = activeAccount.id;
     const [activeStep, setActiveStep] = useState(1);
     const [isLoading, setIsLoading] = useState(false);
     const [stepsData, setStepsData] = useState(cloneDeep(DEFAULT_CHANNEL_DATA));
     const [initStepsData, setInitStepsData] = useState(cloneDeep(DEFAULT_CHANNEL_DATA));

     const isChannelOther = id && id.includes('other_');

     const fetchChannelDetail = () => {
          if (isChannelOther) {
               setActiveStep(2);
          }

          if (!id || isChannelOther) return;

          setIsLoading(true);
          callTokenApi(API_CLIENT_CHANNELS_DETAIL.replace(':accountId', accountId).replace(':id', id), 'GET')
               .then((response) => {
                    if (response.status === 200) {
                         const { data } = response.data;

                         if (data) {
                              setStepsData(data);
                              setInitStepsData(data);
                         }

                         if (data && !data.isCustom && data.isDefault) {
                              setActiveStep(2);
                         }
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     };
     useEffect(fetchChannelDetail, []); // eslint-disable-line react-hooks/exhaustive-deps

     const handleHasChange = () => {
          if (isChannelOther) return;

          dispatch(setRuleHaveEditting({ show: true, type: TYPE_SHOW_UNSAVE_CHANGE.EDIT_SIMPLE }));
     };

     return (
          <React.Suspense fallback={<CenterSpinner />}>
               <div className="rule-detail traffic-channel-detail">
                    <Headline title={`${id && !isChannelOther ? 'Edit' : isChannelOther ? 'View' : 'Create New'} Traffic Channel`}>
                         <p>
                              Use the steps below to {id && !isChannelOther ? 'edit' : isChannelOther ? 'view' : 'create a new'} group of User Sources
                              that define a traffic Channel.
                         </p>
                    </Headline>

                    {isLoading ? (
                         <CenterSpinner />
                    ) : (
                         <div className="cvr-create-new custom-rule">
                              <ChannelDetailContext.Provider
                                   value={{
                                        id,
                                        activeStep,
                                        setActiveStep,
                                        initStepsData,
                                        setInitStepsData,
                                        stepsData,
                                        setStepsData,
                                        handleHasChange,
                                   }}
                              >
                                   <Step1 />
                                   <Step2 />
                              </ChannelDetailContext.Provider>
                         </div>
                    )}
               </div>
          </React.Suspense>
     );
};

export default ChannelDetail;
