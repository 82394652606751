import React, { useEffect, useLayoutEffect, useState } from 'react';
import CIcon from '@coreui/icons-react';
import { CButton } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { setFlexibleModal, setUnsavedChanges } from '../../../actions/common';
import CenterSpinner from '../../../components/general/Loadings/CenterSpinner';
import { COMPONENT_NAME } from '../../../constants';
import { resizeSidebarModal } from '../../../utils';
import { setShowGetHelpLabel } from '../../../actions/subscriber';

const SidebarModal = ({ componentLv1, componentLv2 }) => {
     const dispatch = useDispatch();
     const flexibleModal = useSelector((state) => state.theme.flexibleModal);
     const edittingRule = useSelector((state) => state.theme.edittingRule);
     const accountsLoading = useSelector((state) => state.subscriber.loadings.accounts);
     const removeScroll = [COMPONENT_NAME.LISTENER_LIBRARY, COMPONENT_NAME.DESTINATION_LIBRARY, COMPONENT_NAME.GOAL_MANAGE_TYPES].includes(flexibleModal.component);

     const getOffsetWidth = (osWidth) => {
          return (osWidth / 5) * 3.5;
     };

     const [isFullScreen, setIsFullScreen] = useState();
     const [isLv2FullScreen, setLv2FullScreen] = useState();
     const [offsetWidth, setOffsetWidth] = useState(window.innerWidth);
     const [styleCss, setStyleCss] = useState({
          maxWidth: getOffsetWidth(offsetWidth) + 'px',
          transform: `translate(${offsetWidth}px, 0px)`,
     });
     const [styleCssLv2, setStyleCssLv2] = useState({
          maxWidth: getOffsetWidth(offsetWidth) + 'px',
          transform: `translate(${offsetWidth}px, 0px)`,
     });

     const handleChangeOffset = (offset) => {
          setStyleCss({ maxWidth: offset.width + 'px' });
     };

     const handleChangeOffset2 = (offset) => {
          setStyleCssLv2({ maxWidth: offset.width + 'px' });
     };

     useLayoutEffect(() => {
          function updateSize() {
               setOffsetWidth(window.innerWidth);
               if (offsetWidth !== window.innerWidth) {
                    if (flexibleModal.show) {
                         if (flexibleModal.showLv2) {
                              handleChangeOffset({ width: window.innerWidth });
                              handleChangeOffset2({ width: isLv2FullScreen ? window.innerWidth : getOffsetWidth(window.innerWidth) });
                         } else {
                              handleChangeOffset({ width: isFullScreen ? window.innerWidth : getOffsetWidth(window.innerWidth) });
                              handleChangeOffset2({ width: getOffsetWidth(window.innerWidth) });
                         }
                    } else {
                         handleChangeOffset({ width: getOffsetWidth(window.innerWidth) });
                    }
               }
          }
          window.addEventListener('resize', updateSize);
          updateSize();
          return () => window.removeEventListener('resize', updateSize);
     }, [flexibleModal.show, flexibleModal.showLv2, window.innerWidth]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (flexibleModal.show) {
               if (flexibleModal.showLv2) {
                    setIsFullScreen(true);
                    handleChangeOffset({ width: window.innerWidth });
                    handleChangeOffset2({ width: getOffsetWidth(window.innerWidth) });
               } else {
                    setIsFullScreen(false);
                    handleChangeOffset({ width: getOffsetWidth(window.innerWidth) });
               }

               document.body.classList.add('ll-disable-scroll');
          } else {
               handleChangeOffset({ width: getOffsetWidth(window.innerWidth) });
               document.body.classList.remove('ll-disable-scroll');
          }
     }, [flexibleModal.show, flexibleModal.showLv2]);

     const toggleModal = () => {
          if (edittingRule.show) {
               dispatch(setUnsavedChanges(true));
               dispatch(setShowGetHelpLabel(false));
          } else {
               dispatch(
                    setFlexibleModal({
                         show: false,
                         component: '',
                         category: '',
                    })
               );
          }
     };

     const toggleModalLevel2 = () => {
          if (edittingRule.showLv2) {
               dispatch(setUnsavedChanges(true));
               dispatch(setShowGetHelpLabel(false));
          } else {
               dispatch(setFlexibleModal({ showLv2: false }));
               setLv2FullScreen(false);
          }
     };

     const handleClickFullScreenButton = () => {
          if (isFullScreen) {
               handleChangeOffset({ width: getOffsetWidth(window.innerWidth) });
          } else {
               handleChangeOffset({ width: window.innerWidth });
          }
          setIsFullScreen((isFullScreen) => !isFullScreen);
     };

     const handleClickLv2FullScreenButton = () => {
          if (isLv2FullScreen) {
               handleChangeOffset2({ width: getOffsetWidth(window.innerWidth) });
          } else {
               handleChangeOffset2({ width: window.innerWidth });
          }
          setLv2FullScreen((isLv2FullScreen) => !isLv2FullScreen);
     };

     useEffect(() => {
          const defaultSize = !isFullScreen ? getOffsetWidth(window.innerWidth) : null; // Don't reset component lv1 size to default size while it is full screen
          resizeSidebarModal(defaultSize);
          setLv2FullScreen(false); // Reset full screen lv2 to default
     }, [flexibleModal.show, flexibleModal.showLv2, isFullScreen]);

     return (
          <div className={`sidebar-modal ll-flexible-modal--animated`}>
               <div className='ll-flexible-modal-overlay' onClick={toggleModal}></div>
               <div style={styleCss} className={`ll-flexible-modal ${isFullScreen ? 'full-screen' : ''}`}>
                    <div className='flexible-resize'></div>
                    <div className='ll-sheet-wrapper'>
                         <div className='flexible-modal-header d-flex align-items-center justify-content-between'>
                              {isFullScreen ? (
                                   <div className='d-flex align-items-center justify-content-between'>
                                        <CIcon className='sidebar-brand-full' icon='logo-dark' height={35} />
                                        <p className='full-screen-message'>
                                             You are viewing this page on full screen. Exit full screen anytime to return back to normal view.
                                        </p>
                                   </div>
                              ) : (
                                   <CButton className='d-flex align-items-center' onClick={toggleModal} style={{gap: '10px', fontSize: '13px', fontWeight: 700, padding: 0}}>
                                        <CIcon icon='icon-close' width={8} style={{ width: '8.93px', height: '8.65px' }} />
                                        CLOSE
                                   </CButton>
                              )}
                              <CButton className='btn-full-screen d-flex align-items-center' onClick={handleClickFullScreenButton}>
                                   {isFullScreen ? (
                                        <>
                                             Exit full screen
                                             <CIcon icon='icon-exit-full-screen' />
                                        </>
                                   ) : (
                                        <>
                                             Full screen
                                             <CIcon icon='icon-full-screen' />
                                        </>
                                   )}
                              </CButton>
                         </div>
                         <div className={`flexible-modal-body ${removeScroll ? 'remove-scroll' : ''}`}>
                              <div className='flexible-modal-content'>{accountsLoading ? <CenterSpinner /> : <>{componentLv1}</>}</div>
                         </div>
                    </div>
               </div>
               {flexibleModal.show && (
                    <>
                         <div className='ll-flexible-modal-overlay' onClick={toggleModalLevel2}></div>
                         <div style={styleCssLv2} className={`ll-flexible-modal ${isLv2FullScreen ? 'full-screen' : ''}`}>
                              <div className='flexible-resize'></div>
                              <div className='flexible-modal-header'>
                                   <div className='d-flex align-items-center justify-content-between'>
                                        <CButton className='d-flex align-items-center' onClick={toggleModalLevel2} style={{gap: '10px', fontSize: '13px', fontWeight: 700, padding: 0}}>
                                             <CIcon icon='icon-close' width={8} style={{ width: '8.93px', height: '8.65px' }} />
                                             CLOSE
                                        </CButton>
                                        <CButton className='btn-full-screen d-flex align-items-center' onClick={handleClickLv2FullScreenButton}>
                                             {isLv2FullScreen ? (
                                                  <>
                                                       Exit full screen
                                                       <CIcon icon='icon-exit-full-screen' />
                                                  </>
                                             ) : (
                                                  <>
                                                       Full screen
                                                       <CIcon icon='icon-full-screen' />
                                                  </>
                                             )}
                                        </CButton>
                                   </div>
                              </div>
                              <div className='flexible-modal-body'>
                                   <div className='flexible-modal-content'>{accountsLoading ? <CenterSpinner /> : <>{componentLv2}</>}</div>
                              </div>
                         </div>
                    </>
               )}
               {flexibleModal.showLv2 && (
                    <>
                         <div className='ll-flexible-modal-overlay'></div>
                         <div className={`ll-flexible-modal`}></div>
                    </>
               )}
          </div>
     );
};

export default SidebarModal;
