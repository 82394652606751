import React, { useContext, useEffect, useState } from 'react';
import { CForm, CCard, CCardBody } from '@coreui/react';

import { CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
import { callTokenApi } from '../../../../../../apiCaller';
import { API_CLIENT_ACCOUNT_DOMAIN_VERIFICATION } from '../../../../../../constants';
import { toastError } from '../../../../../../utils';
import CommonLottie from '../../../../../general/lottie/CommonLottie';
import wwwAnimationData from '../../../../../../assets/lottie-files/www.json';
import { CInput } from '../../../../../migration/CInput';
import CFormGroup from '../../../../../migration/CFormGroup';

const DomainVerifying = () => {
     const { account, stepsData, setStepsData, setCurrentStep } = useContext(CreateAccountContext);
     const [showStillWorking, setShowStillWorking] = useState(false);
     const { domain } = stepsData;

     const verifyDomain = () => {
          let data = {
               accountId: account.id,
               domain
          }

          callTokenApi(API_CLIENT_ACCOUNT_DOMAIN_VERIFICATION, 'POST', data)
               .then(response => {
                    if (response) {
                         if (response.status === 200) {
                              const { domainStatus, info } = response.data;
                              let newStepData = { domainStatus: domainStatus };
                              let nextStep = '';

                              if (domainStatus <= 200 && domainStatus < 300) {
                                   nextStep = STEPS.domainSuccess;
                              } else if (domainStatus >= 300 && domainStatus < 400) {
                                   nextStep = STEPS.domainRedirect;
                                   newStepData.redirectDomain = info.redirectDomain;
                              } else {
                                   nextStep = STEPS.domainCannotAccess;
                              }

                              setStepsData(newStepData);
                              setCurrentStep(nextStep);
                         } else {
                              toastError(response);
                         }
                    } else {
                         setStepsData({ domainStatus: 502 });
                         setCurrentStep(STEPS.domainCannotAccess);
                    }
               })

          const verifyingTimeout = setTimeout(() => {
               setShowStillWorking(true);
          }, 12000);

          return () => clearTimeout(verifyingTimeout);
     };

     useEffect(verifyDomain, []); // eslint-disable-line react-hooks/exhaustive-deps

     return (
          <CreateAccountBody>
               <CCard className="account-domain checking">
                    <p className="number-of-step">1 of 3 steps</p>
                    <CCardBody>
                         <CForm>
                              <CFormGroup>
                                   <h3>Checking your domain...</h3>
                                   <p>We’re making sure your website is accessible.</p>
                                   <CommonLottie animationData={wwwAnimationData} />
                                   <span className="foot-note mb-2">The domain name you entered</span>
                                   <CInput
                                        name="domain"
                                        placeholder="Website URL"
                                        value={domain}
                                        readOnly
                                   />
                              </CFormGroup>
                         </CForm>
                         {
                              showStillWorking && (
                                   <div className="blue-notice">
                                        <h4>We're still working...</h4>
                                        <p>
                                             We’re still checking your domain to make sure it’s accessible. Please be patient!{" "}
                                             This screen will change as soon as we are done, please don’t navigate away!
                                        </p>
                                   </div>
                              )
                         }
                    </CCardBody>
               </CCard>
          </CreateAccountBody>
     );
};

export default DomainVerifying;
