import React from 'react';
import DotsLoadingListenlayer from '../../Loadings/DotsLoadingListenlayer';
import CImg from '../../../migration/CImg';
export const Message = ({ text, iconLink, animationMessage = '', animationDots = '', hasLoading = true }) => {
     return (
          <div className='message-box'>
               <DotsLoadingListenlayer className={animationDots} show={hasLoading} />
               <div className={`message ${animationMessage}`}>
                    {text}
                    {iconLink && <CImg src={iconLink} width={17} height={17} />}
               </div>
          </div>
     );
};
