import React, { useContext } from 'react';
import Select from 'react-select';
import { useFormikContext } from 'formik';
import { RegionContext } from './SingleRow';
import { CInvalidFeedback } from '../../../../../migration/CInvalidFeedback';

const RowDefault = () => {
     const { errors } = useFormikContext();
     const { index, onChangeRegionType, CONSENT_REGION_TYPES } = useContext(RegionContext);
     const regionTypeRequired = errors.geographicRegions && errors.geographicRegions[index] && errors.geographicRegions[index].regionType;

     return (
          <div className='select-type-col'>
               <div className='row-block-region'>
                    <Select classNamePrefix='react-select'
                         className={regionTypeRequired ? 'is-invalid' : null}
                         name={`regionsType.${index}`}
                         options={CONSENT_REGION_TYPES}
                         onChange={(e) => onChangeRegionType(e.value)}
                         placeholder="Select a region type"
                    />
                    <CInvalidFeedback>{regionTypeRequired}</CInvalidFeedback>
               </div>
          </div>
     );
};
export default RowDefault;
