import CIcon from '@coreui/icons-react';
import { CBadge } from '@coreui/react';
import React from 'react';

const TooltipTag = ({ name, description, type, className }) => {
     const badgeTypeData = {
          info: 'bg-info',
          primary: 'bg-primary',
          success: 'bg-success',
          secondary: 'bg-secondary',
          danger: 'bg-danger',
     };
     const badgeType = badgeTypeData[type];

     return (
          <>
               {badgeType && (
                    <div className={`tooltip-tag ${className ? className : ''}`}>
                         <CBadge className={badgeType}>{name}</CBadge>
                         {description && (
                              <div className="block-tooltip-tag">
                                   <CIcon icon="arrowTooltip" className="icon-arrow-area" />
                                   <div className="block-tooltip-text">
                                        <p>{description}</p>
                                   </div>
                              </div>
                         )}
                    </div>
               )}
          </>
     );
};

export default TooltipTag;
