import { sygnet } from './sygnet';
import { logo } from './logo';
import FingerprintIcon from './FingerprintIcon.svg';
import {
     favicon,
     logoLight,
     logoDark,
     logoColor,
     arrowPreview,
     arrowPlay,
     arrowPlayRadius,
     arrowSelect,
     arrowAccount,
     iconTealium,
     iconGTM,
     iconGoogle,
     iconAdodeAnalytics,
     iconGoogleChrome,
     iconFireFox,
     iconSafari,
     iconSave,
     iconPayment,
     iconBoltAlt,
     iconCalendar,
     iconLifeRing,
     iconLifeRingSidebar,
     iconBrightness,
     iconBrightnessLight,
     iconCopy,
     iconCopyLight,
     iconInfo,
     iconDelete,
     iconUpgrade,
     iconChevronLeft,
     iconEnvelopeClosed,
     iconHubspot,
     iconHubspotTinted,
     iconGravityForm,
     iconGravityFormTinted,
     iconCf7,
     iconCf7Tinted,
     iconMarketoForm,
     iconMarketoFormTinted,
     iconReactJS,
     iconReactJSTinted,
     iconLiveChat,
     iconLiveChatTinted,
     iconWoocomerce,
     iconWoocomerceTinted,
     iconUnbounce,
     iconUnbounceTinted,
     iconAngular,
     iconAngularTinted,
     iconPardotTinted,
     iconDriftTinted,
     iconVimeoTinted,
     iconMailChimpFormTinted,
     iconAcuitySchedulingTinted,
     iconIntercomTinted,
     iconDrupalTinted,
     iconWistiaTinted,
     iconVidyardTinted,
     iconUberflipTinted,
     coreUISolidCircle,
     coreUISolidCheckCircle,
     solidCircle,
     solidCheckCircle,
     phoneCall,
     coreUISolidXCircle,
     restorePageIcon,
     guiRestoreIcon,
     starRegular,
     starSolid,
     inboxOut,
     faEnvelope,
     faEnvelopeOpen,
     leftHamburger,
     iconVerified3,
     iconVerified2,
     iconRefresh,
     deleteIcon,
     addIcon,
     iconUpgradeBlue,
     checkIcon,
     faLeft,
     faRight,
     activeArrow,
     logoDatalayer,
     logoRight,
     iconType3verified,
     iconDropdown,
     playIcon,
     iconBack,
     iconFilterSearch,
     iconFlag,
     iconChevronRight,
     iconCheckCircleBlack,
     arrowBack,
     googleLightNormal,
     faCircleXMarkSolid,
     iconDatabase,
     iconShrink,
     iconExpand,
     iconReload,
     iconCodeRegular,
     iconClone,
     iconLock,
     arrowTooltip,
     iconDashboard,
     iconData,
     iconDestinations,
     iconMonitoring,
     iconAnalytics,
     iconBell,
     iconDoubleCaret,
     arrowArea,
     iconInfoBlue,
     iconDeleteCircle,
     iconGripLines,
     iconStraightFlag,
     iconPencil,
     iconCheck,
     iconX,
     iconPushGTM,
     iconEye,
     iconGear,
     iconGTMv2,
     iconIdeaLight,
     iconChevronLeftAlt,
     iconLinkedInTinted,
     iconGoogleAnalyticsTinted,
     iconGTMTinted,
     iconGoogleAdsTinted,
     iconMicrosoftAdsTinted,
     iconPrivacy,
     iconFullScreen,
     iconExitFullScreen,
     iconClose,
     iconSendData,
     iconcheckSendData,
     iconPlus,
     iconDeleteField,
     iconAddField,
     iconAddRowGray,
     iconInsertSortCode,
     iconInsertSortCodeArrow,
     iconInsertSortCodeActive,
     iconArrowDownStrong,
     iconPlay,
     iconEditRule,
     iconActiveCampaignTinted,
     iconZohoTinted,
     iconShopifyTinted,
     iconFluentTinted,
     iconCustomFormTinted,
     iconUserLocationTinted,
     iconUserWeatherTinted,
     iconFingerprint,
     iconRemoveMultiSelect,
     searchIcon,
     iconCalendarReport,
     iconSaveCategory,
     iconOpenWebsite,
     iconOpenWebsiteWhite,
     iconCheckbox,
     iconSuccess,
     iconMessageQuestion,
     iconAttachFile,
     iconFiles,
     iconChart,
     iconShieldQuartered,
     iconCircleStart,
     iconCircleStartSolid,
     iconEyeRegular,
     iconEyeRegularSlash,
     iconAddUser,
     iconStart,
     iconDollarSign,
     iconSquareCheck,
     iconFilterList,
     iconArrowRightLong,
     iconPeopleGroup,
     iconUserSolid,
     iconBuildingsSolid,
     iconTriangleExclamation,
     iconObjectColumn,
     iconCalendarExclamation,
     iconPlayCircle,
     iconFilter,
     iconLoadingRotate,
     iconInfoCircle,
     iconLinkedin,
     iconLinkedinSalesNavigator,
     iconFacebook,
     iconInstagram,
     iconTwitter,
     iconYoutube,
     iconPinterest,
     iconUsers,
     iconSackDollar,
     iconAnalyticsV2,
     iconIndustry,
     iconLandmark,
     iconLandmarkAlt,
     iconGlassCheers,
     iconHotel,
     iconMapSigns,
     iconMapMarkedAlt,
     iconGlobeStand,
     iconGlobe,
     iconPhone,
     iconAlexa,
     iconLocation,
     iconPostal,
     iconRegion,
     iconLatitude,
     iconLongitude,
     iconTimezone,
     iconTimezoneUtc,
     iconDst,
     iconArrowLeft,
     iconArrowRight,
     iconProperties,
     iconDimension,
     iconMetrics,
     iconFilterReport,
     iconExport,
     iconFaCircleCheck,
     iconGoogleLogin,
     iconAlertCircle,
     iconPencilBlue,
     iconEyeBlue,
     iconChartPie,
     iconTrendingUp,
     iconTrendingDown,
     iconArrowUp,
     iconArrowDown,
     iconCilPlusBackground,
     iconEllipsisVertical,
     iconCloseGrey,
     iconExternalLinkCustom,
     iconEllipsisVerticalCustom,
     iconPin,
     iconPinned
} from './logo-negative';

import {
     cilApplicationsSettings,
     cilSpeedometer,
     cilSun,
     cilMoon,
     cilUser,
     cilLockLocked,
     cilAccountLogout,
     cilFace,
     cilBrowser,
     cilAssistiveListeningSystem,
     cilTags,
     cilPuzzle,
     cilRecycle,
     cibHubspot,
     cibWordpress,
     cilInbox,
     cilGroup,
     cilWalk,
     cilCloudDownload,
     cilSend,
     cilDelete,
     cilSave,
     cilCode,
     cilLayers,
     cilGraph,
     cilMediaPlay,
     cilBell,
     cilDollar,
     cilX,
     cilBuilding,
     cilMenu,
     cilList,
     cilCheckCircle,
     cibGoogle,
     cibTwitter,
     cibFacebook,
     cibInstagram,
     cibLinkedin,
     cibSlack,
     cilEnvelopeClosed,
     cilSearch,
     cilLifeRing,
     cilCalendar,
     cilBan,
     cilUserFollow,
     cilUserUnfollow,
     cilChartPie,
     cilBasket,
     cilTerminal,
     cilFork,
     cilFeaturedPlaylist,
     cilSettings,
     cilChevronDoubleDown,
     cilPeople,
     cilMemory,
     cilControl,
     cilCheck,
     cilCloudUpload,
     cilLoopCircular,
     cilChevronLeft,
     cilChevronRight,
     cilCircle,
     cilSoccer,
     cilGift,
     cilXCircle,
     cilStar,
     cilStorage,
     cilReload,
     cilArrowRight,
     cilExternalLink,
     cilFilter,
     cilLevelUp,
     cilHamburgerMenu,
     cilViewColumn,
     cilViewStream,
     cilNotes,
     cilArrowThickToBottom,
     cilLinkAlt,
     cilPin,
} from '@coreui/icons';

export const icons = Object.assign(
     { FingerprintIcon },
     {
          favicon,
          sygnet,
          logo,
          logoLight,
          logoDark,
          logoColor,
          arrowPreview,
          arrowPlay,
          arrowPlayRadius,
          arrowSelect,
          arrowAccount,
          iconTealium,
          iconGTM,
          iconGoogle,
          iconAdodeAnalytics,
          iconGoogleChrome,
          iconFireFox,
          iconSafari,
          iconSave,
          iconPayment,
          iconBoltAlt,
          iconCalendar,
          iconLifeRing,
          iconLifeRingSidebar,
          iconBrightness,
          iconBrightnessLight,
          iconCopy,
          iconCopyLight,
          iconInfo,
          iconDelete,
          iconUpgrade,
          iconChevronLeft,
          iconEnvelopeClosed,
          iconHubspot,
          iconHubspotTinted,
          iconGravityForm,
          iconGravityFormTinted,
          iconCf7,
          iconCf7Tinted,
          iconMarketoForm,
          iconMarketoFormTinted,
          iconReactJS,
          iconReactJSTinted,
          iconLiveChat,
          iconLiveChatTinted,
          iconWoocomerce,
          iconWoocomerceTinted,
          iconUnbounce,
          iconUnbounceTinted,
          iconAngular,
          iconAngularTinted,
          iconPardotTinted,
          iconDriftTinted,
          iconVimeoTinted,
          iconMailChimpFormTinted,
          iconAcuitySchedulingTinted,
          iconIntercomTinted,
          iconDrupalTinted,
          iconWistiaTinted,
          iconVidyardTinted,
          iconUberflipTinted,
          coreUISolidCircle,
          coreUISolidCheckCircle,
          solidCircle,
          solidCheckCircle,
          phoneCall,
          coreUISolidXCircle,
          restorePageIcon,
          guiRestoreIcon,
          starRegular,
          starSolid,
          inboxOut,
          faEnvelope,
          faEnvelopeOpen,
          leftHamburger,
          logoDatalayer,
          logoRight,
          iconType3verified,
          iconVerified3,
          iconVerified2,
          iconRefresh,
          deleteIcon,
          addIcon,
          iconUpgradeBlue,
          checkIcon,
          faLeft,
          faRight,
          activeArrow,
          iconDropdown,
          playIcon,
          iconBack,
          iconFilterSearch,
          iconFlag,
          iconChevronRight,
          iconCheckCircleBlack,
          arrowBack,
          googleLightNormal,
          faCircleXMarkSolid,
          iconDatabase,
          iconShrink,
          iconExpand,
          iconReload,
          iconCodeRegular,
          iconClone,
          iconLock,
          arrowTooltip,
          iconDashboard,
          iconData,
          iconDestinations,
          iconMonitoring,
          iconAnalytics,
          iconBell,
          iconDoubleCaret,
          arrowArea,
          iconInfoBlue,
          iconDeleteCircle,
          iconGripLines,
          iconStraightFlag,
          iconPencil,
          iconCheck,
          iconX,
          iconPushGTM,
          iconEye,
          iconGear,
          iconGTMv2,
          iconIdeaLight,
          iconChevronLeftAlt,
          iconLinkedInTinted,
          iconGoogleAnalyticsTinted,
          iconGTMTinted,
          iconGoogleAdsTinted,
          iconMicrosoftAdsTinted,
          iconPrivacy,
          iconFullScreen,
          iconExitFullScreen,
          iconClose,
          iconSendData,
          iconcheckSendData,
          iconPlus,
          iconDeleteField,
          iconAddField,
          iconAddRowGray,
          iconInsertSortCode,
          iconInsertSortCodeArrow,
          iconInsertSortCodeActive,
          iconArrowDownStrong,
          iconPlay,
          iconEditRule,
          iconActiveCampaignTinted,
          iconZohoTinted,
          iconShopifyTinted,
          iconFluentTinted,
          iconCustomFormTinted,
          iconUserLocationTinted,
          iconUserWeatherTinted,
          iconFingerprint,
          iconRemoveMultiSelect,
          searchIcon,
          iconCalendarReport,
          iconSaveCategory,
          iconCheckbox,
          iconSuccess,
          iconOpenWebsite,
          iconOpenWebsiteWhite,
          iconMessageQuestion,
          iconAttachFile,
          iconFiles,
          iconChart,
          iconShieldQuartered,
          iconCircleStart,
          iconCircleStartSolid,
          iconEyeRegular,
          iconEyeRegularSlash,
          iconAddUser,
          iconStart,
          iconDollarSign,
          iconSquareCheck,
          iconFilterList,
          iconArrowRightLong,
          iconPeopleGroup,
          iconUserSolid,
          iconBuildingsSolid,
          iconTriangleExclamation,
          iconObjectColumn,
          iconCalendarExclamation,
          iconPlayCircle,
          iconFilter,
          iconLoadingRotate,
          iconInfoCircle,
          iconLinkedin,
          iconLinkedinSalesNavigator,
          iconFacebook,
          iconInstagram,
          iconTwitter,
          iconYoutube,
          iconPinterest,
          iconUsers,
          iconSackDollar,
          iconAnalyticsV2,
          iconIndustry,
          iconLandmark,
          iconLandmarkAlt,
          iconGlassCheers,
          iconHotel,
          iconMapSigns,
          iconMapMarkedAlt,
          iconGlobeStand,
          iconGlobe,
          iconPhone,
          iconAlexa,
          iconLocation,
          iconPostal,
          iconRegion,
          iconLatitude,
          iconLongitude,
          iconTimezone,
          iconTimezoneUtc,
          iconDst,
          iconArrowLeft,
          iconArrowRight,
          iconProperties,
          iconDimension,
          iconMetrics,
          iconFilterReport,
          iconExport,
          iconFaCircleCheck,
          iconGoogleLogin,
          iconAlertCircle,
          iconPencilBlue,
          iconEyeBlue,
          iconChartPie,
          iconTrendingUp,
          iconTrendingDown,
          iconArrowUp,
          iconArrowDown,
          iconCilPlusBackground,
          iconEllipsisVertical,
          iconCloseGrey,
          iconExternalLinkCustom,
          iconEllipsisVerticalCustom,
          iconPin,
          iconPinned
     },
     {
          cilApplicationsSettings,
          cilSpeedometer,
          cilSun,
          cilMoon,
          cilUser,
          cilLockLocked,
          cilAccountLogout,
          cilFace,
          cilBrowser,
          cilAssistiveListeningSystem,
          cilTags,
          cilPuzzle,
          cilRecycle,
          cibHubspot,
          cibWordpress,
          cilInbox,
          cilGroup,
          cilWalk,
          cilCloudDownload,
          cilSend,
          cilDelete,
          cilSave,
          cilCode,
          cilLayers,
          cilGraph,
          cilMediaPlay,
          cilBell,
          cilDollar,
          cilX,
          cilBuilding,
          cilMenu,
          cilList,
          cilCheckCircle,
          cibGoogle,
          cibTwitter,
          cibFacebook,
          cibInstagram,
          cibLinkedin,
          cibSlack,
          cilEnvelopeClosed,
          cilSearch,
          cilLifeRing,
          cilCalendar,
          cilBan,
          cilUserFollow,
          cilUserUnfollow,
          cilChartPie,
          cilBasket,
          cilTerminal,
          cilFork,
          cilFeaturedPlaylist,
          cilSettings,
          cilChevronDoubleDown,
          cilPeople,
          cilMemory,
          cilControl,
          cilCheck,
          cilCloudUpload,
          cilLoopCircular,
          cilChevronLeft,
          cilChevronRight,
          cilCircle,
          cilSoccer,
          cilGift,
          cilXCircle,
          cilStar,
          cilStorage,
          cilReload,
          cilArrowRight,
          cilExternalLink,
          cilFilter,
          cilLevelUp,
          cilHamburgerMenu,
          cilViewColumn,
          cilViewStream,
          cilNotes,
          cilArrowThickToBottom,
          cilLinkAlt,
          cilPin,
     }
);
