import * as React from 'react';
import { Chart, ChartTitle, ChartLegend, ChartTooltip, ChartSeries, ChartSeriesItem, ChartSeriesLabels } from '@progress/kendo-react-charts';
import 'hammerjs';

function formatNumberWithCommas(number) {
     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function formatNumber(value) {
     if (typeof value !== 'number') {
          return String(value);
     } else {
          return formatNumberWithCommas(Number(value));
     }
}

const tooltipRender = (props) => {
     if (props.point) {
          return (
               <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
                    <span>{props.point.dataItem.stat}</span>
                    {props.point.series && props.point.series.data && props.point.series.data.length > 3 && (
                         <span>{formatNumber(props.point.dataItem.count)}</span>
                    )}
               </div>
          );
     }
};

// const itemColors = {
//      0: 'hsl(199, 80%, 27%)',
//      1: 'hsl(199, 80%, 33%)',
//      2: 'hsl(199, 80%, 38%)',
//      3: 'hsl(199, 80%, 43%)',
//      4: 'hsl(199, 80%, 48%)',
// };

const FunnelChart = ({ title = '', data = [], className = '' }) => {
     const generateColor = (numColors) => {
          const colors = [];
          for (let i = 0; i < numColors; i++) {
               let dynamicColors = 27 + i * 4;
               const newColor = `hsl(199, 80%, ${dynamicColors < 90 ? dynamicColors : 90}%)`;
               colors.push(newColor);
          }
          return colors;
     };

     const itemColors = generateColor(data.length);

     const newData = data.map((item, index) => {
          item.color = itemColors[index];
          return item;
     });

     React.useEffect(() => {
          const handleScrollModel = () => {
               const elTooltip = document.querySelector('.k-chart-tooltip');
               const elTooltipParent = document.querySelector('.k-child-animation-container');
               if (elTooltip) {
                    elTooltip.remove();
                    if (elTooltipParent) {
                         setTimeout(() => {
                              elTooltipParent.appendChild(elTooltip);
                         }, 1000);
                    }
               }
          };
          const element = document.querySelectorAll('.flexible-modal-body');

          if (element) {
               for (let index = 0; index < element.length; index++) {
                    element[index].addEventListener('scroll', handleScrollModel);
               }
          }
          return () => {
               if (element) {
                    for (let index = 0; index < element.length; index++) {
                         element[index].removeEventListener('scroll', handleScrollModel);
                    }
               }
          };
     }, []);

     return (
          <div className={`funnel-chart ${className}`}>
               <Chart
                    id="FunnelChart"
                    style={{
                         margin: '0 auto',
                         width: 300,
                    }}
               >
                    <ChartTitle text={title} />
                    <ChartSeries>
                         <ChartSeriesItem
                              type="funnel"
                              data={newData}
                              categoryField="stat"
                              field="count"
                              colorField="color"
                              dynamicSlope={false}
                              dynamicHeight={false}
                         >
                              <ChartSeriesLabels color="white" background="none" format="N0" />
                         </ChartSeriesItem>
                    </ChartSeries>
                    <ChartTooltip render={tooltipRender} background="hsl(199, 80%, 33%)" />
                    <ChartLegend visible={false} />
               </Chart>
          </div>
     );
};

export default FunnelChart;
