import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
     // CSidebar,
     CSidebarBrand,
     CSidebarNav,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';

import { setSidebarShow } from '../../actions/common';

// sidebar nav config
import { supportNav } from '../_nav';
import CCreateElement from '../../components/migration/CCreateElement';
import CSidebarNavTitle from '../../components/migration/CSidebarNavTitle';
import CSidebarNavDropdown from '../../components/migration/CSidebarNavDropdown';
import CSidebarNavItem from '../../components/migration/CSidebarNavItem';
import CSidebarNavDivider from '../../components/migration/CSidebarNavDivider';
import CSidebar from '../../components/migration/CSidebar';

const SupportSidebar = () => {
     const dispatch = useDispatch();
     const show = useSelector(state => state.theme.sidebarShow);

     return (
          <CSidebar
               show={show}
               unfoldable
               onVisibleChange={(val) => dispatch(setSidebarShow(val))}
               className="support-sidebar sidebar-light sidebar-left"
               position="fixed"
               size="lg"
          >
               <CSidebarBrand className="d-md-down-none" to="/" style={{background: '#20a8d8', padding: '10px 12px'}}>
                    <CIcon
                         className="sidebar-brand-full"
                         icon="logo-light"
                         height={35}
                    />
                    {/* <CIcon
                         className="sidebar-brand-minimized"
                         icon="sygnet"
                         height={35}
                    /> */}
               </CSidebarBrand>
               <CSidebarNav style={{ background: '#ffff' }}>
                    <CCreateElement
                         items={supportNav}
                         components={{
                              CSidebarNavDivider,
                              CSidebarNavDropdown,
                              CSidebarNavItem,
                              CSidebarNavTitle
                         }}
                    />
               </CSidebarNav>
          </CSidebar>
     )
}

export default React.memo(SupportSidebar);
