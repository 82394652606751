import React from 'react';

import { SUPPORT_PATH } from '../constants';

const Dashboard = React.lazy(() => import('../components/cms/support/dashboard/Dashboard'));
const Profile = React.lazy(() => import('../components/cms/support/profile/Profile'));
const Accounts = React.lazy(() => import('../components/cms/support/accounts/Accounts'));
const AccountDetail = React.lazy(() => import('../components/cms/support/accounts/AccountDetail'));
const Tickets = React.lazy(() => import('../components/cms/support/tickets/Tickets'));
const TicketDetail = React.lazy(() => import('../components/cms/support/ticket-details/TicketDetail'));
const AccountTickets = React.lazy(() => import('../components/cms/support/tickets/AccountTickets'));
const UserTickets = React.lazy(() => import('../components/cms/support/tickets/UserTickets'));
const DataRequests = React.lazy(() => import('../components/cms/support/data-request'));
const DataRequestDetail = React.lazy(() => import('../components/cms/support/data-request/Detail'));

const supportRoutes = [
    { path: '/', exact: true, name: 'Dashboard', component: Dashboard },
    { path: SUPPORT_PATH.PROFILE, name: 'Profile', component: Profile },
    { path: SUPPORT_PATH.ACCOUNTS, name: 'Accounts', component: Accounts, exact: true },
    { path: SUPPORT_PATH.ACCOUNTS_WITH_PARAMS_ID, name: 'Account Detail', component: AccountDetail },
    { path: SUPPORT_PATH.TICKETS, name: 'Tickets', component: Tickets, exact: true },
    { path: SUPPORT_PATH.TICKETS_DETAIL, name: 'Ticket Detail', component: TicketDetail },
    { path: SUPPORT_PATH.ACCOUNT_TICKETS, name: 'Tickets', component: Tickets, exact: true },
    { path: SUPPORT_PATH.ACCOUNT_TICKETS_WITH_ID, name: 'Lists Tickets', component: AccountTickets },
    { path: SUPPORT_PATH.USER_TICKETS, name: 'Tickets', component: Tickets, exact: true },
    { path: SUPPORT_PATH.USER_TICKETS_WITH_ID, name: 'Lists Tickets', component: UserTickets },
    { path: SUPPORT_PATH.DATA_REQUEST, name: 'Data Requests', component: DataRequests, exact: true },
    { path: SUPPORT_PATH.DATA_REQUEST_WITH_ID, name: 'Data Request Detail', component: DataRequestDetail },
];

export default supportRoutes;