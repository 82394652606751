import React, { useContext, useEffect } from 'react'
import Item from './Item';
import { PreviewFeatureContext } from './ThePreviewFeature';

const Items = ({ datas, path, show }) => {
     const { setLastLevelAccordionOpened } = useContext(PreviewFeatureContext);
     const dataParse = Object.keys(datas).map(data => {
          return { item: data, value: datas[data] };
     }).sort((a, b) => a.item.toString().toLowerCase() < b.item.toString().toLowerCase() ? -1 : 1);
     const pathSplitByDot = path.split('.');
     const lastPathItem = pathSplitByDot[pathSplitByDot.length - 1];

     useEffect(() => {
          if (show) {
               setLastLevelAccordionOpened(true);
          }
     }, [show]) // eslint-disable-line react-hooks/exhaustive-deps

     return (
          <ul>
               {dataParse.map((val, index) => {
                    let pathItem = val.item && lastPathItem !== val.item ? `${path}.${val.item}` : path;

                    return <Item key={index + val.item} props={val} path={pathItem} />
               })}
          </ul>
     )
}

export default Items;