import React from 'react';
import { CButton } from '@coreui/react';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { numberWithCommas, roundTo2DecimalPlaces } from '../../../../../utils';

const TooltipPackage = ({ text, tooltipText }) => {
     return (
          <Tippy
               className='loadingTableTooltip plan-features'
               content={
                    <div className='tooltip-content'>
                         <span> {tooltipText}</span>
                    </div>
               }
               placement='right'
               maxWidth='340px'
          >
               <span className='link'>{text}</span>
          </Tippy>
     )
}

const PackageItem = (props) => {
     const { currentPackageId, packageData, btnText, btnClick, btnDisabled, packageIsCustom, setShowPopupLargeSandbox, currentPackagePricePerMonth, btnHidden } = props
     const {
          id: packageId,
          friendlyName,
          pricePerMonth,
          pricePerYear,
          // numberOfSaveReportingValues,
          shortDescription,
          customPlan,
          shortDescriptionDes,
          friendlyNameNew
     } = packageData;
     const isCurrentPackage = (currentPackageId === packageId);
     const monthlyCostIfPayAnnual = customPlan ? pricePerYear : roundTo2DecimalPlaces(pricePerYear / 12);
     const divClasses = classNames(
          'package-item',
          { 'current-package': isCurrentPackage },
          { 'custom-plan': customPlan },
          { 'h-auto': btnHidden }
     );

     let currentPricePerMonth = pricePerMonth
     if (packageIsCustom && customPlan) {
          currentPricePerMonth = currentPackagePricePerMonth
     }

     return (
          <>
               <div className={divClasses}>
                    {(isCurrentPackage || (packageIsCustom && customPlan)) && <label className="current-label package-label">Current</label>}
                    <div className="pricing">
                         <h2 className={`${customPlan ? 'text' : ''}`}>{customPlan ? pricePerYear : `$${monthlyCostIfPayAnnual.toLocaleString('en-US')}`}</h2>
                         {
                              pricePerMonth === 0 ? (
                                   <p className="des">Free forever <br /> You're welcome!</p>
                              ) : (
                                   customPlan ?
                                        (<p className="des">Customized for you, managed services available</p>)
                                        : (<p className="des">per month, billed annually, or ${currentPricePerMonth.toLocaleString('en-US')} month-to-month</p>)
                              )
                         }
                    </div>
                    <hr></hr>
                    <div className="friendlyname">
                         <h5 className='title'>{friendlyName.includes('Pro') ? 'Pro' : friendlyName}</h5>
                         <p className="shortdescription">{shortDescription}</p>
                         <p className="shortdescription">{friendlyName === 'Sandbox' ? 'Limited to 1,000 users per month.' : (friendlyName.includes('Pro') ? 'Unlimited Listeners and Destinations' : shortDescriptionDes)}</p>
                    </div>

                    {!btnHidden && (
                         <CButton
                              className="btn-select-upgrade"
                              onClick={btnClick}
                              disabled={btnDisabled}
                         >
                              {btnText}
                         </CButton>
                    )}

               </div>
               <div className='package-item-features'>
                    <h4 className="plan-features">{customPlan ? friendlyNameNew : friendlyName.includes('Pro') ? 'Pro' : friendlyName} Plan Features</h4>
                    <ul className="plan-features-automate">
                         <h5 className='plan-features-header'>Automate Your Data</h5>
                         <li>Unlimited {friendlyName === 'Sandbox' ? (<TooltipPackage text={'Listeners'} tooltipText={'Automate your analytics and conversion tracking with powerful Listeners that generate the data you need.'} />) : 'Listeners'} </li>
                         <li>Unlimited Data Customizations</li>
                    </ul>
                    <ul className="plan-features-distribute">
                         <h5 className='plan-features-header'>Distribute Your Data</h5>
                         <li>Centralized Conversions</li>
                         <li>Unlimited <TooltipPackage text={'Destinations'} tooltipText={'Send data, like website events and conversions, to advertising and analytics platforms (GA4, Google Ads, Facebook, LinkedIn, Microsoft, and more - and custom destinations too!).'} />
                         </li>
                         {customPlan && (
                              <>
                                   <li>Server Side Tagging</li>
                                   <li>Custom Domain</li>
                              </>
                         )}
                    </ul>
                    <ul className="plan-features-compliant">
                         <h5 className='plan-features-header'>Remain Compliant</h5>
                         <li> Privacy & Data Controls </li>
                         <li>
                              <TooltipPackage text={'GDRPA & CCPA'} tooltipText={'ListenLayer supports GDPR, CCPA, and other privacy laws by giving you control over how users are identified and tracked (and we have prebuilt rules you can rely on).'} />
                         </li>
                         {customPlan && (
                              <div className='coming-soon'>
                                   <span>Coming Soon</span>
                                   <li><TooltipPackage text={'Consent Management Platform'} tooltipText={'Soon, ListenLayer will have a complete Consent Management Platform built in, allowing you to centralize privacy compliance into your data strategy (and save by remove your current cookie-popup tool).'} /></li>
                                   <li>HIPAA <TooltipPackage text={'(available add-on)'} tooltipText={'We’re launching HIPAA compliant conversion tracking and analytics tools that allows Healthcare organizations to get the data they need for marketing. '} /></li>
                              </div>
                         )}
                    </ul>
                    <ul className="plan-features-insights">
                         <h5 className='plan-features-header'>Gain Insights</h5>
                         <li> Event Data & Dashboard </li>
                         {!customPlan && (<li> Analytics Preview Preview </li>)}
                         {customPlan && (
                              <>
                                   <li>Behavioral Analytics</li>
                                   <li><TooltipPackage text={'Reveal People'} tooltipText={'Identify real people, leads, and customers directly inside ListenLayer’s analytics & reporting.'} /></li>
                                   <li><TooltipPackage text={'Reveal Companies'} tooltipText={'Turn your anonymous traffic into known companies to learn about who is interested in your offerings. Requires additional subscription.'} /><span style={{ color: 'red' }}>*</span> </li>
                                   <li>Target Accounts & Cohorts</li>
                                   <div className='coming-soon'>
                                        <span>Coming Soon</span>
                                        <li>Session Replays & Heatmaps</li>
                                        <li>eCommerce Analytics</li>
                                        <li>External Data (CRM & other)</li>
                                        <li>Revenue & Lead Attribution</li>
                                        <li>Pipeline Analytics</li>
                                   </div>
                              </>
                         )}
                    </ul>
                    <ul className="plan-features-support">
                         <h5 className='plan-features-header'>Support & Consulting</h5>
                         <li> World-class Support </li>
                         <li> Self-guide onboarding </li>
                         {friendlyName.includes('Pro') && (<li>1 <TooltipPackage text={'success sessions'} tooltipText={'Success sessions allow you to meet with our team to review strategy and implementation in more detail. We also provide standard platform support directly inside ListenLayer.'} /> <span>**</span></li>)}
                         {customPlan && (
                              <li>2 <TooltipPackage text={'success sessions'} tooltipText={'Success sessions allow you to meet with our team to review strategy and implementation in more detail. We also provide standard platform support directly inside ListenLayer.'} /><span>**</span></li>
                         )}
                    </ul>
                    <ul className="plan-features-user">
                         {friendlyName === 'Sandbox' && (
                              <>
                                   <p>1,000 Monthly Users</p>
                                   <span className='link' onClick={() => !packageIsCustom && !btnDisabled ? setShowPopupLargeSandbox(true) : null}>Need a larger sandbox?</span>
                              </>
                         )}
                         {friendlyName.includes('Pro') && (
                              <>
                                   <p>{numberWithCommas(packageData.numberOfVisitors)} Monthly Users</p>
                                   <p>$15 per 10,000 Extra Users</p>
                              </>
                         )}

                    </ul>
               </div>
          </>
     )
}

export default PackageItem;