import React, { useContext } from "react";
import { useSelector } from "react-redux";
import CIcon from "@coreui/icons-react";
import {
    SupportTicketPopupContext,
    ISSUE_LISTENER,
    CREATE_SUPPORT_TICKET
} from "../GetHelpLabel";
import { TESTING_YOUR_LISTENER, STEP_PREVIOUS_CREATE_TICKET } from "../../../../../constants";

import TicketVideoIframe from "../TicketVideoIframe";

const IssueListener3 = () => {
    const listenerInTickets = useSelector((state) => state.subscriber.listenerInTickets);

    const { listeners, listenerFeatures } = listenerInTickets;

    const { setCurrentStep, listenerFeature, setDataShowVideo } = useContext(SupportTicketPopupContext);

    const dataVideoIframe = {
        title: `We're Here to Help!`,
        description: `Please watch this video to make sure that the basic configuration of your Listener is correct.`,
        urlIframe: TESTING_YOUR_LISTENER
    }

    const setLoadVideoOverview = () => {
        const { listenerId, featureId } = listenerFeature;

        if (featureId) {
            const featureData = listenerFeatures[listenerId].find(feature => feature.fId === featureId);
            const listenerData = listeners.find(listener => listener.id === listenerId);

            let videoFeature = '';

            if (featureData.fspecialInstructions.isOn) {
                videoFeature = featureData.fspecialInstructions.videoEmbed;
            } else if (featureData.fLearnVideo.isOn) {
                videoFeature = featureData.fLearnVideo.videoEmbed;
            }
            if (videoFeature) {
                setDataShowVideo({listenerName: listenerData.name, featureName: featureData.fName, videoEmbed: videoFeature});
                setCurrentStep(ISSUE_LISTENER.step4)
            } else {
                let videoListener = '';

                if (listenerData.specialInstructions.isOn) {
                    videoListener = listenerData.specialInstructions.videoEmbed;
                } else if (listenerData.videoOverview.isOn) {
                    videoListener = listenerData.videoOverview.videoEmbed;
                }

                if (videoListener) {
                    setDataShowVideo({listenerName: listenerData.name, videoEmbed: videoListener});
                    setCurrentStep(ISSUE_LISTENER.step4)
                } else {
                    setCurrentStep(CREATE_SUPPORT_TICKET.step1)
                    localStorage.setItem(STEP_PREVIOUS_CREATE_TICKET, ISSUE_LISTENER.step3)
                }
            }
        } else if (listenerId) {
            const listenerData = listeners.find(listener => listener.id === listenerId);

            let videoListener = '';

            if (listenerData.specialInstructions.isOn) {
                videoListener = listenerData.specialInstructions.videoEmbed;
            } else if (listenerData.videoOverview.isOn) {
                videoListener = listenerData.videoOverview.videoEmbed;
            }

            if (videoListener) {
                setDataShowVideo({listenerName: listenerData.name, videoEmbed: videoListener});
                setCurrentStep(ISSUE_LISTENER.step4)
            } else {
                setCurrentStep(CREATE_SUPPORT_TICKET.step1)
                localStorage.setItem(STEP_PREVIOUS_CREATE_TICKET, ISSUE_LISTENER.step3)
            }
        }
    };

    return (
        <div className="ticket-popup-content ticket-video-content">
            <div className="btn-back-ticket">
                <CIcon icon="icon-chevron-left" width={6} />
                <span onClick={() => setCurrentStep(ISSUE_LISTENER.step2)}>
                    Back
                </span>
            </div>
            <TicketVideoIframe
                title={dataVideoIframe.title}
                description={dataVideoIframe.description}
                urlIframe={dataVideoIframe.urlIframe}
                buttonText={'Yes, i need more help'}
                buttonVideo={setLoadVideoOverview}
            />
        </div>
    );
};

export default IssueListener3;
