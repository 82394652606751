import React, { useState, useContext, useEffect } from 'react';
import { CCard, CCardBody, CButton, CCardHeader, CRow, CCol } from '@coreui/react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import CIcon from '@coreui/icons-react';
import cloneDeep from 'lodash/cloneDeep';
import { RuleContext } from '../EditRule';
import { CONSENT_IDENTIFICATION_BUTTON, CONSENT_IDENTIFICATION_LEVEL, TYPE_SHOW_UNSAVE_CHANGE } from '../../../../../../constants';
import { ConfirmSaveChange } from '../../../../../general/popup';
import { callTokenApi } from '../../../../../../apiCaller';
import { API_CONSENT_RULE } from '../../../../../../constants';
import { toastError } from '../../../../../../utils';
import { setConsentRules } from '../../../../../../actions/subscriber';
import { toast } from 'react-toastify';
import findIndex from 'lodash/findIndex';
import { setFlexibleModal, setRuleHaveEditting } from '../../../../../../actions/common';
import Select from 'react-select';
import { CInput } from '../../../../../migration/CInput';
import CLabel from '../../../../../migration/CLabel';

const EditStep3 = ({ stepsData, setStepsData }) => {
     const dispatch = useDispatch();
     const { activeStep, setActiveStep, changeConsentMethod, setChangeConsentMethod } = useContext(RuleContext);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const [dataIdentification, setDataIdentification] = useState('');
     const [saveChangeModal, setSaveChangeModal] = useState(false);
     const [saveLoading, setSaveLoading] = useState(false);
     const [showLevel, setShowLevel] = useState(false);
     const rules = useSelector((state) => state.subscriber.consentRules);
     // const accountFree = activeAccount.isFree;
     const initialValues = stepsData.visitorIdentification;
     const setVisitorIdentification = () => {
          if (activeStep === 3) {
               const visitorIdentification = cloneDeep(stepsData.visitorIdentification);

               if (visitorIdentification.notAllow === 'identifyVisitors' || visitorIdentification.allow === 'identifyVisitors') {
                    setShowLevel(true);
               } else {
                    setShowLevel(false);
               }

               if(changeConsentMethod) {
                    if (stepsData.consentMethod === 'explicitConsent') {
                         visitorIdentification.allow = 'anonymizeVisitors';
                         visitorIdentification.notAllow = 'anonymizeVisitors';
                         visitorIdentification.level = '';
                         setShowLevel(false);
                    } else if (stepsData.consentMethod === 'impliedConsent') {
                         visitorIdentification.allow = 'identifyVisitors';
                         visitorIdentification.notAllow = 'anonymizeVisitors';
                         visitorIdentification.level = 'identifyStrong';
                         setShowLevel(true);
                    } else if (stepsData.consentMethod === 'openConsent') {
                         visitorIdentification.allow = 'identifyVisitors';
                         visitorIdentification.level = 'identifyStrong';
                         visitorIdentification.notAllow = '';
                         setShowLevel(true);
                    }
                    setChangeConsentMethod(false)
               }
               setDataIdentification(visitorIdentification);
          }
     };

     useEffect(setVisitorIdentification, [activeStep]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleSave = () => {
          if (JSON.stringify(initialValues) === JSON.stringify(dataIdentification)) {
               dispatch(setFlexibleModal({ show: false, component: '' }));
          } else {
               setSaveChangeModal(true);
          }
     };

     const handleSelectedIdentification = (value, key) => {
          const newDataIdentification = { ...dataIdentification };

          newDataIdentification[key] = value;

          if (newDataIdentification.notAllow === 'identifyVisitors' || newDataIdentification.allow === 'identifyVisitors') {
               setShowLevel(true);
               if(key !== 'level') {
                    newDataIdentification['level'] = !dataIdentification['level'] ? initialValues.level || 'identifyStrong' : dataIdentification['level'];
               }
          } else {
               if(key !== 'level') {
                    newDataIdentification['level'] = '';
               }
               setShowLevel(false);
          }

          // method = openConsent && analytics_storage = anonymize || don't track
          if (stepsData.consentMethod === 'openConsent') {
               if (newDataIdentification.allow !== 'identifyVisitors') {
                    newDataIdentification['level'] = '';
                    setShowLevel(false);
               }
          }

          setDataIdentification(newDataIdentification);
          handleHasChange();
     };

     const checkStep3Passed = () => {
          let passed = true;

          if (stepsData.geographicRegions && !stepsData.geographicRegions[0].regionType) {
               passed = false;
          }

          return passed;
     };

     const onAccept = () => {
          setSaveLoading(true);
          const newStepsData = {
               id: stepsData.id,
               accountId: activeAccount.id,
               data: {
                    ...stepsData,
                    visitorIdentification: dataIdentification,
               },
          };

          callTokenApi(`${API_CONSENT_RULE}update`, 'POST', newStepsData).then((response) => {
               setSaveLoading(false);
               setSaveChangeModal(false);

               if (response.status === 200) {
                    setStepsData(newStepsData.data);
                    toast.success('Successfully update rule');
                    let newRules = [...rules];
                    let editedRuleIndex = findIndex(rules, { id: newStepsData.id });

                    if (newRules[editedRuleIndex].type === 'default') {
                         newRules[editedRuleIndex].hasOldVersion = true;
                    }
                    dispatch(setFlexibleModal({ show: false, component: '' }));
                    dispatch(setRuleHaveEditting({ show: false, type: '' }));
                    dispatch(setConsentRules(newRules));
                    setActiveStep(5);
               } else {
                    toastError(response);
               }
          });
     };

     const handleHasChange = () => {
          // if (accountFree) return;
          dispatch(setRuleHaveEditting({ show: true, type: TYPE_SHOW_UNSAVE_CHANGE.EDIT_SIMPLE }));
     };

     const CustomOption = ({ title, tooltip }) => {
          return <>
               {/* <CIcon icon='icon-info' className='hover-show-tooltip mr-3' height={14}></CIcon>
               <div className='block-tooltip'>
                    <CIcon icon='arrow-preview' className='arrow-preview'></CIcon>
                    <div className='block-tooltip-text'>
                         <p>{tooltip}</p>
                    </div>
               </div> */}
               {title}
          </>;
     };

     return (
          <CCard className={`cvr-step-2 ${activeStep !== 3 ? 'difference-step' : 'cvr-step-card'}`}>
               <CCardHeader>
                    {activeStep !== 3 ? (
                         <div className='rule-step d-inline-flex justify-content-between w-100'>
                              <h5 className='mb-0 inactive title'>Step 3: Identification</h5>
                              <p className='description mr-auto'>Determine how ListenLayer identifies user and people from the region.</p>
                              {checkStep3Passed() && (
                                   <div className='d-inline-flex align-items-center'>
                                        <CButton className='btn-edit' onClick={() => setActiveStep(3)}>
                                             {/* {accountFree ? 'View' : 'Edit'} */}
                                             Edit
                                        </CButton>
                                   </div>
                              )}
                         </div>
                    ) : (
                         <div className='d-flex justify-content-start align-center'>
                              <h5 className='title'>Step 3: Identification</h5>
                              <p className='description mr-auto'>Determine how ListenLayer identifies user and people from the region.</p>
                         </div>
                    )}
               </CCardHeader>
               {activeStep === 3 && (
                    <>
                         <CCardBody>
                              <p>ListenLayer must idenfity individual ”users” on your website in order to aggregate the event data for measurement and insights.</p>
                              <CCard className='table-inden consent-type '>
                                   <CCardHeader className='table-inden-header'>
                                        <p>Tell ListenLayer how to indentify "users" in the region based on the Consent Type analystics_storage.</p>
                                   </CCardHeader>
                                   <CCardBody>
                                        <CRow className='table-inden-consent'>
                                             <CCol md='4' className='table-inden-col'>
                                                  <p>When this Consent Type</p>
                                             </CCol>
                                             <CCol md='4' className='table-inden-allow'>
                                                  <p>Is ALLOWED</p>
                                             </CCol>
                                             {(stepsData.consentMethod !== 'openConsent') && (
                                                  <CCol md='4' className='table-inden-notallow'>
                                                       <p>Is NOT ALLOWED</p>
                                                  </CCol>
                                             )}

                                        </CRow>
                                        <CRow className='table-inden-row'>
                                             <CCol md='4' className='table-inden-title'>
                                                  <p>analytics_storage</p>
                                             </CCol>
                                             <CCol md='4'>
                                                  <Select classNamePrefix='react-select'
                                                       options={CONSENT_IDENTIFICATION_BUTTON}
                                                       className='table-inden-select'
                                                       component={Option}
                                                       formatOptionLabel={CustomOption}
                                                       disabled={(item) => dataIdentification.allow === item.value}
                                                       value={CONSENT_IDENTIFICATION_BUTTON.filter((option) => option.value === dataIdentification.allow)}
                                                       onChange={(item) => handleSelectedIdentification(item.value, 'allow')}
                                                  />
                                             </CCol>
                                             {(stepsData.consentMethod !== 'openConsent') && (
                                                  <CCol md='4'>
                                                       <Select classNamePrefix='react-select'
                                                            options={CONSENT_IDENTIFICATION_BUTTON}
                                                            className='table-inden-select'
                                                            component={Option}
                                                            formatOptionLabel={CustomOption}
                                                            disabled={(item) => dataIdentification.notAllow === item.value}
                                                            value={CONSENT_IDENTIFICATION_BUTTON.filter((option) => option.value === dataIdentification.notAllow)}
                                                            onChange={(item) => handleSelectedIdentification(item.value, 'notAllow')}
                                                       />
                                                  </CCol>
                                             )}

                                        </CRow>
                                   </CCardBody>
                              </CCard>
                              {showLevel && (
                                   <CCard className='table-inden bg-gray'>
                                        <CCardHeader className='table-inden-header'>
                                             <p>What level of identification do you want to use when we identify users in this region?</p>
                                        </CCardHeader>
                                        <CCardBody>
                                             <CRow className='table-region'>
                                                  {CONSENT_IDENTIFICATION_LEVEL.map((item, index) => {
                                                       return (
                                                            <CCol key={index} md='2' className='d-flex align-items-center'>
                                                                 <CInput type='radio' name='level-identify' id={`StrongBox${index}`} checked={dataIdentification && dataIdentification.level === item.value} className='mb-0 customize-radio' onClick={() => handleSelectedIdentification(item.value, 'level')} />
                                                                 <CLabel htmlFor={`StrongBox${index}`} className='mb-0'>
                                                                      {item.title}
                                                                 </CLabel>
                                                                 <CIcon icon='icon-info' className='hover-show-tooltip ml-2' height={12}></CIcon>
                                                                 <div className='block-tooltip'>
                                                                      <CIcon icon='arrow-preview' className='arrow-preview'></CIcon>
                                                                      <div className='block-tooltip-text'>
                                                                           <p>{item.tooltip}</p>
                                                                      </div>
                                                                 </div>
                                                            </CCol>
                                                       );
                                                  })}
                                             </CRow>
                                        </CCardBody>
                                   </CCard>
                              )}
                              <CButton className='px-4' color='success' onClick={handleSave}>
                                   SAVE RULE
                              </CButton>
                         </CCardBody>
                    </>
               )}
               <ConfirmSaveChange show={saveChangeModal} onClose={() => setSaveChangeModal(false)} onAccept={onAccept} title='Are You Sure You Want to Save This Change?' isLoading={saveLoading}>
                    You are about to update the Geographic Regions of this rule. This will not effect any other rule settings.
               </ConfirmSaveChange>
          </CCard>
     );
};

EditStep3.propTypes = {
     stepsData: PropTypes.object,
     setStepsData: PropTypes.func,
};

export default EditStep3;
