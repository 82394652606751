import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSidebarBrand, CSidebarNav } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Link } from 'react-router-dom';
import { setSidebarShow } from '../../actions/common';
// sidebar nav config
import { adminNav } from '../_nav';
import CCreateElement from '../../components/migration/CCreateElement';
import CSidebarNavTitle from '../../components/migration/CSidebarNavTitle';
import CSidebarNavDropdown from '../../components/migration/CSidebarNavDropdown';
import CSidebarNavItem from '../../components/migration/CSidebarNavItem';
import CSidebarNavDivider from '../../components/migration/CSidebarNavDivider';
import CSidebar from '../../components/migration/CSidebar';

const AdminSidebar = () => {
     const dispatch = useDispatch();
     const show = useSelector((state) => state.theme.sidebarShow);

     return (
          <CSidebar
               show={show}
               unfoldable
               onShowChange={(val) => dispatch(setSidebarShow(val))}
               className="sidebar-left"
               position="fixed"
               size="lg"
          >
               <CSidebarBrand className="d-flex align-items-center" style={{ flex: '0 0 56px', background: 'rgba(0, 0, 0, .2)'}} to="/" as={Link}>
                    <CIcon className="sidebar-brand-full" icon="logo-light" height={35} />
               </CSidebarBrand>
               <CSidebarNav>
                    <CCreateElement
                         items={adminNav}
                         components={{
                              CSidebarNavDivider,
                              CSidebarNavDropdown,
                              CSidebarNavItem,
                              CSidebarNavTitle,
                         }}
                    />
               </CSidebarNav>
               {/* <CSidebarMinimizer className="c-d-md-down-none" /> */}
          </CSidebar>
     );
};

export default React.memo(AdminSidebar);
