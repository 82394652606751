import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import Select from 'react-select';
import { DATAS_CONTINENT } from '../../../../../../constants';
import { RegionContext } from './SingleRow';
import { CInvalidFeedback } from '../../../../../migration/CInvalidFeedback';

const RowContinent = () => {
     const { errors, setFieldValue } = useFormikContext();
     const { CONSENT_REGION_TYPES, row, index, onChangeRegionType, handleHasChange } = useContext(RegionContext);

     const regionTypeRequired = errors.geographicRegions && errors.geographicRegions[index] && errors.geographicRegions[index].regionType;
     const regionRequired = errors.geographicRegions && errors.geographicRegions[index] && errors.geographicRegions[index].continent;

     const valueRegionType = CONSENT_REGION_TYPES.filter(option => option.value === row.regionType);
     const valueContinent = row.regionType ? DATAS_CONTINENT.filter(option => option.value === row.continent) : null;

     const onChangeContinent = (value) => {
          setFieldValue(`geographicRegions.${index}.continent`, value);
          handleHasChange();
     };

     return (
          <>
               <div className='region-type'>
                    <Select classNamePrefix='react-select'
                         className={(regionTypeRequired) ? 'is-invalid' : null}
                         value={valueRegionType}
                         name={`regionsType.${index}`}
                         options={CONSENT_REGION_TYPES}
                         onChange={(e) => onChangeRegionType(e.value)}
                         placeholder="Select a region type"
                    />
                    <CInvalidFeedback>{regionTypeRequired}</CInvalidFeedback>
               </div>

               <div className='region-type'>
                    <Select classNamePrefix='react-select'
                         className={(regionRequired) ? ' is-invalid' : ''}
                         value={valueContinent}
                         isDisabled={!row.regionType}
                         name={`continent.${index}`}
                         options={DATAS_CONTINENT}
                         onChange={(type) => onChangeContinent(type.value)}
                         placeholder="Select a Continent"
                    />
                    <CInvalidFeedback>{regionRequired}</CInvalidFeedback>
               </div>
          </>
     )
}
export default RowContinent;