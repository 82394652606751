import { CRow } from '@coreui/react';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { callTokenApi } from '../../../../../apiCaller';
import { useDispatch, useSelector } from 'react-redux';
import sum from 'lodash/sum';
import { API_CLIENT_RECORD_PROFILE_TABLE_GROUP, COMPONENT_NAME } from '../../../../../constants';
import { Skeleton } from '@progress/kendo-react-indicators';
import { setFlexibleMultiModal } from '../../../../../actions/common';
import Accordion from '../../../../general/accordion';
import { convertReportDuration } from '../../../../../utils';
import CIcon from '@coreui/icons-react';
import { CSmartPagination } from '@coreui/react-pro';

const useFetchData = (props) => {
     const {
          id,
          locationForm,
          accountId,
          reportType,
          activePage,
          itemsPerPage,
          setInitialValue,
          setTotalPage,
          tableType,
          setIsLoading,
          maxStepForm,
          sort,
     } = props;

     const bodyData = {
          id,
          locationForm,
          accountId,
          limit: itemsPerPage,
          offset: (activePage - 1) * itemsPerPage,
          reportType: reportType,
          maxStepForm,
          sort,
     };

     const fetchData = () => {
          setIsLoading(true);
          callTokenApi(API_CLIENT_RECORD_PROFILE_TABLE_GROUP.replace(':tableType', tableType), 'POST', bodyData)
               .then((response) => {
                    if (response && response.status === 200) {
                         const { listData, overallRowCount } = response.data;
                         setInitialValue(listData);
                         setTotalPage(overallRowCount);
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     };
     const dependencies = [id, reportType, activePage, itemsPerPage, sort];

     useEffect(fetchData, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};

const TableRecordProfile = ({
     id,
     locationForm,
     header,
     reportType,
     tableType,
     title,
     accordionType,
     accordionShow,
     setAccordionShow,
     maxStepForm,
}) => {
     const dispatch = useDispatch();
     const [initialValue, setInitialValue] = useState([]);
     const [isLoading, setIsLoading] = useState(true);
     const [activePage, setActivePage] = useState(1);
     const [itemsPerPage, setItemsPerPage] = useState(5);
     const [totalPage, setTotalPage] = useState(0);
     const totalPages = Math.ceil(totalPage / itemsPerPage) || 1;
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountId = activeAccount.id;
     const [headerTable, setHeaderTable] = useState(header);
     const [arrWidth, setArrWidth] = useState([]);
     const [sort, setSort] = useState({});

     useFetchData({
          id,
          locationForm,
          accountId,
          reportType,
          activePage,
          itemsPerPage,
          setInitialValue,
          setTotalPage,
          tableType,
          setIsLoading,
          maxStepForm,
          sort,
     });

     useEffect(() => {
          const hasStep2Value = header.some((item) => item.value.startsWith('step2'));
          const checkStepIsZero = !hasStep2Value && checkStep1ValuesAreZero(initialValue);
          setHeaderTable(header);
          if (checkStepIsZero) {
               setHeaderTable((prev) => prev.filter((item) => !item.value.startsWith('step1')));
          }
     }, [header]); //eslint-disable-line

     function checkStep1ValuesAreZero(array) {
          return array.every((item) => {
               return Object.keys(item).every((key) => !key.startsWith('step1') || item[key] === 0);
          });
     }

     const handleClickSort = (field) => {
          if (sort.fieldName !== field) {
               setSort({ fieldName: field, isDesc: true });
          } else {
               if (sort.isDesc) setSort({ fieldName: field, isDesc: false });
               else {
                    setSort({});
               }
          }
     };

     const customHeaderCell = (props) => {
          return (
               <span className="k-cell-inner table-head">
                    <span className="k-link">
                         <span className="k-column-title" onClick={props.title !== 'View' ? () => handleClickSort(props.field) : null}>
                              {props.title}
                         </span>
                         {sort.fieldName === props.field && (
                              <CIcon icon="iconDropdown" className={`icon-sort ${sort.isDesc ? 'desc' : 'asc'}`}></CIcon>
                         )}
                    </span>
               </span>
          );
     };

     const handleNavigateCompany = (id) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: id },
                    component: COMPONENT_NAME.RECORD_PROFILE_COMPANY,
               })
          );
     };

     const loadingPanel = (
          <div className="k-loading-mask">
               <span className="k-loading-text">Loading</span>
               <div className="k-loading-color"></div>
          </div>
     );

     // const changeDataLoading = (
     //      <div className="filter-loading">
     //           <CProgress color="dark" variant="striped" animated value={100} />
     //      </div>
     // );

     function formatNumberWithCommas(number) {
          return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
     }

     function formatNumber(value) {
          if (typeof value !== 'number') {
               return String(value);
          } else {
               return formatNumberWithCommas(Number(value));
          }
     }

     function CustomCell(props) {
          const value = props.dataItem[props.field];
          const phone = props.dataItem['phone'];
          const { style, className, id } = props;

          if (value === undefined) {
               return (
                    <td>
                         <Skeleton />
                    </td>
               );
          }

          switch (props.field) {
               case 'pageViewCount':
                    return (
                         <td style={style} className={`${className} view`} tabIndex={0} data-keyboardnavid={id}>
                              {value}
                         </td>
                    );
               case 'email':
                    return (
                         <td style={style} className={`${className} view`} tabIndex={0} data-keyboardnavid={id}>
                             {value && value !== 'None' ? value : phone}
                         </td>
                    );
               case 'timeOnPage':
                    return (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              {convertReportDuration(value)}
                         </td>
                    );
               case 'conversions':
                    return (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              {value}
                         </td>
                    );
               case 'sessions':
                    return (
                         <td style={style} className={`${className} sessions`} tabIndex={0} data-keyboardnavid={id}>
                              {value}
                         </td>
                    );
               case 'users':
                    return (
                         <td style={style} className={`${className} users`} tabIndex={0} data-keyboardnavid={id}>
                              {value}
                         </td>
                    );
               case 'conversionEvents':
                    return (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              {value}
                         </td>
                    );
               case 'companyId':
                    return (
                         <td style={style} className={`${className} companyId`} tabIndex={0} data-keyboardnavid={id}>
                              <span className="link-data" onClick={() => handleNavigateCompany(value)}>
                                   View
                              </span>
                         </td>
                    );
               case 'path':
                    return (
                         <td style={style} className={`${className} link-path`} tabIndex={0} data-keyboardnavid={id}>
                              {value}
                         </td>
                    );
               case 'formLocation':
                    return (
                         <td style={style} className={`form-location-path`} tabIndex={0} data-keyboardnavid={id}>
                              {value}
                              <a href={value} target="_blank" rel="noopener noreferrer">
                                   <CIcon icon="icon-open-website" />
                              </a>
                         </td>
                    );
               default:
                    return (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              {formatNumber(value)}
                         </td>
                    );
          }
     }

     const onPaginationChange = (i) => {
          setIsLoading(true);
          setActivePage(i);
     };

     const onChangeItemsPerPage = (e) => {
          setIsLoading(true);
          setActivePage(1);
          setItemsPerPage(+e.target.value);
     };

     // function findMaxLengthObject(arr, key) {
     //      let maxLength = 0;
     //      let resultObject = null;

     //      for (const obj of arr) {
     //           if (obj[key] && obj[key].length > maxLength) {
     //                maxLength = obj[key].length;
     //                resultObject = obj;
     //           }
     //      }

     //      return resultObject;
     // }

     // const getWidth = (value) => {
     //      const maxLengthStr = findMaxLengthObject(initialValue, value);
     //      const width = maxLengthStr && maxLengthStr[value] && maxLengthStr[value].length * 8;
     //      return width || 150;
     // };

     useEffect(() => {
          let cloneArrWidth = [];
          let arrWidthHeader = [];
          headerTable.forEach((item) => {
               arrWidthHeader.push(item.label.length);
          });
          let arrWidthValue = [];
          initialValue.forEach((item) => {
               Object.keys(item).forEach((i, index) => {
                    if (reportType === 'formCategorySource') {
                         if (index !== 0) {
                              String(item[i]).length > (arrWidthValue[index - 1] !== undefined ? arrWidthValue[index - 1] : 0) &&
                                   (arrWidthValue[index - 1] = item[i].length || 0);
                         }
                    } else {
                         String(item[i]).length > (arrWidthValue[index] !== undefined ? arrWidthValue[index] : 0) &&
                              (arrWidthValue[index] = item[i].length || 0);
                    }
               });
          });
          cloneArrWidth = arrWidthHeader.map((item, index) =>
               Math.max(index === 0 ? item * 9.5 + 24 : item * 8 + 24, arrWidthValue[index] * 7.5 + 24)
          );
          setArrWidth(cloneArrWidth);
     }, [initialValue]); //eslint-disable-line

     const [checkMaxWidth, setCheckMaxWidth] = useState(false);
     const element = document.querySelectorAll('.flexible-modal-body');
     
     useLayoutEffect(() => {
          let id = setTimeout(() => {
               if (element) {
                    setCheckMaxWidth(sum(arrWidth) > element[0].clientWidth - 120);
               }
          }, 500);
          return () => {
               clearTimeout(id);
          };
     }, [arrWidth]); //eslint-disable-line

     useEffect(() => {
          if (headerTable) {
               headerTable.forEach((header) => {
                    if (header.sort) {
                         setSort({ fieldName: header.value, isDesc: true });
                    }
               });
          }
     }, []); //eslint-disable-line

     if (totalPage === 0 && !isLoading) {
          return;
     }

     return (
          <div className="accordion">
               <Accordion
                    accordionType={accordionType}
                    title={`${title} (${totalPage})`}
                    accordionShow={accordionShow}
                    setAccordionShow={setAccordionShow}
                    className={'table-data-profile'}
               >
                    {isLoading && loadingPanel}
                    {/* {isLoading && changeDataLoading} */}
                    <Grid
                         style={{ width: 'fit-content', maxWidth: '100%', maxHeight: '800px' }}
                         data={initialValue.length === 0 && isLoading ? Array(5).fill({}) : initialValue}
                         resizable={true}
                         reorderable={false}
                         navigatable={true}
                         scrollable="scrollable"
                         className={tableType}
                    >
                         <GridNoRecords>No results found</GridNoRecords>
                         {headerTable.length > 0 &&
                              headerTable.map((item, index) => {
                                   const widthCell = item.minWidth
                                             ? item.minWidth
                                             : 
                                             (checkMaxWidth && arrWidth[index] && sum(arrWidth) > element[0].clientWidth - 120 ? arrWidth[index] : '')
                                   return (
                                        <GridColumn
                                             orderIndex={index}
                                             title={item.label}
                                             field={item.value}
                                             resizable={true}
                                             cell={(props) => CustomCell(props)}
                                             key={index}
                                             headerCell={(props) => customHeaderCell({ ...props })}
                                             width={widthCell}
                                        ></GridColumn>
                                   );
                              })}
                    </Grid>
                    {totalPage > 5 && (
                         <CRow className="report-table-bottom">
                              <div className="d-flex px-0">
                                   <CSmartPagination
                                        activePage={activePage}
                                        pages={totalPages}
                                        onActivePageChange={onPaginationChange}
                                        className="report-table-page"
                                   ></CSmartPagination>
                                   <div className="form-inline justify-content-sm-end ml-4">
                                        <select className="form-control" onChange={onChangeItemsPerPage} defaultValue={itemsPerPage}>
                                             <option value="5">5</option>
                                             <option value="10">10</option>
                                             <option value="20">20</option>
                                             <option value="50">50</option>
                                        </select>
                                        <label className="ml-2">Item per page</label>
                                   </div>
                              </div>
                         </CRow>
                    )}
               </Accordion>
          </div>
     );
};

export default TableRecordProfile;
