import React, { useContext, useEffect } from 'react';
import { CHANGE_STEP_TYPE, CreateAccountBody, CreateAccountContext, STEPS } from './CreateAccount';
import { CCol } from '@coreui/react';
import PackageItem from '../upgrade-account/PackageItem';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { CUSTOM_PRICING_PACKAGE, dataPackage } from '../../../../../constants';

const SeePricing = () => {
     const { setCurrentStep, userValue, setUserValue } = useContext(CreateAccountContext);
     const packages = [...dataPackage.filter((item) => item.numberOfVisitors === userValue || item.friendlyName === 'Sandbox' || (userValue < 10000 && item.numberOfVisitors === 10000) || (userValue > 200000 && item.numberOfVisitors === 200000)), CUSTOM_PRICING_PACKAGE]
     const onBackBtnClicked = () => {
          setCurrentStep(STEPS.seeHowItWorks, CHANGE_STEP_TYPE.subStepNext);
     }

     const marks = {
          0: '< 10,000',
          10000: '10,000',
          25000: '25,000',
          50000: '50,000',
          75000: '75,000',
          100000: '100,000',
          150000: '150,000',
          200000: '200,000',
          210000: '> 200,000',
     }

     const handleSliderChange = newValue => {
          setUserValue(newValue)
     }

     useEffect(() => {
          const elements = document.querySelectorAll('.account-see-pricing .rc-slider-mark-text-active')
          const classText = document.querySelectorAll('.account-see-pricing .rc-slider-mark-text')
          if (elements) {
               for (let i = 0; i < elements.length; i++) {
                    elements[i].style.display = 'none'
               }

               const activeElementIndex = Math.min(userValue, elements.length - 1)
               elements[activeElementIndex].style.display = 'block'

               for (let i = 0; i < classText.length; i++) {
                    if (i === activeElementIndex) {
                         classText[i].style.display = 'block'
                    } else {
                         classText[i].style.display = 'none'
                    }
               }
          }
     }) //eslint-disable-line

     return (
          <CreateAccountBody onBackBtnClicked={onBackBtnClicked} hideStepProgress={true} mainContentClassName='full-width' accountPackage={true}>
               <div className='subscriber'>
                    <div className="full-screen-wrapper account-see-pricing" style={{ background: 'transparent' }}>
                         <h3>What's the value of better data?</h3>
                         <p className="des">
                              On average, marketing teams that adopt ListenLayer increase data accuracy by 40% and gain insights what truly drives revenue.
                         </p>
                         <p className="des">
                              the ROI can be infinite
                         </p>

                         <div className="list-package" style={{ marginBottom: '50px' }}>
                              <div className='user-package'>
                                   <h4 className='title'>Pricing Scales with Your Website Traffic</h4>
                                   <div className='user-package-main'>
                                        <div className='user-package-content'>
                                             <p>Estimated Monthly Users</p>
                                             {/* <p>Check with Google Analytics</p> */}
                                        </div>
                                        <div className='user-package-form'>
                                             <Slider min={0} max={210000} step={null} marks={marks} value={userValue} onChange={handleSliderChange} />
                                        </div>
                                   </div>
                              </div>
                              <div className="list-package row">
                                   {
                                        packages.map((packageData) => {
                                             const {
                                                  id: packageId
                                             } = packageData;

                                             return (
                                                  <CCol key={packageId} lg="4" sm="6" className="package-col">
                                                       <PackageItem
                                                            packageData={packageData}
                                                            btnHidden={true}
                                                       />
                                                  </CCol>
                                             )
                                        })
                                   }
                              </div>
                         </div>
                    </div>
               </div>

          </CreateAccountBody>
     );
};

export default SeePricing;
