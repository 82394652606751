import React from 'react';
import { connect } from 'react-redux';
import {
     CHeader,
     CHeaderNav,
     // CButton
} from '@coreui/react';
import CIcon from '@coreui/icons-react';

import {
     toggleTheme,
     setAsideShow,
     setSidebarShow
} from '../../actions/common';
import SupportAccountDropdown from './TheAccountDropdown';

// routes config
import { supportRoutes } from '../../routes';
import CToggler from '../../components/migration/CToggler';
import CHeaderNavItem from '../../components/migration/CHeaderNavItem';
import CHeaderNavLink from '../../components/migration/CHeaderNavLink';
import CSubheader from '../../components/migration/CSubheader';
import CBreadcrumbRouter from '../../components/migration/CBreadcrumbRouter';

const SupportHeader = (props) => {
     const { theme, setAsideShow, setSidebarShow } = props;
     const { asideShow, sidebarShow } = theme;

     const toggleSidebar = () => {
          const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive';
          setSidebarShow(val);
     }

     const toggleSidebarMobile = () => {
          const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive';
          setSidebarShow(val);
     }

     return (
          <CHeader className={theme.overlay ? 'open-dropdown' : ''} withSubheader style={{ padding: 0 }}>
               <CToggler
                    inHeader
                    className="ml-md-3 d-lg-none"
                    onClick={toggleSidebarMobile}
               />
               <CToggler
                    inHeader
                    className="ml-3 d-md-down-none"
                    onClick={toggleSidebar}
               />
               <CHeaderNav className="mr-auto">
                    <CHeaderNavItem className="px-3" >
                         <CHeaderNavLink style={{ color: 'rgba(0,0,0,.5)' }} to="/">Dashboard</CHeaderNavLink>
                    </CHeaderNavItem>
               </CHeaderNav>
               <CHeaderNav className="px-3">
                    <SupportAccountDropdown />
                    <CToggler
                         inHeader
                         className="d-md-down-none d-none"
                         onClick={() => setAsideShow(!asideShow)}
                    >
                         <CIcon className="mr-2" size="lg" icon="cil-applications-settings" />
                    </CToggler>
               </CHeaderNav>
               <CSubheader className="px-3 justify-content-between" style={{ width: '100%', padding: '15px' }} >
                    <CBreadcrumbRouter className="border-0 c-subheader-nav m-0 px-0 px-md-3" routes={supportRoutes} />
               </CSubheader>
          </CHeader>
     )
}

const mapStateToProps = (state) => {
     return {
          theme: state.theme,
     }
}

const mapDispatchToProps = {
     toggleTheme,
     setAsideShow,
     setSidebarShow,
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(SupportHeader))
