import React from 'react'
import { components } from "react-select";

const NoOptionMenuList = (props) => {
    return (
        <components.MenuList {...props} className="no-options">
            {props.children}
        </components.MenuList>
    )
}

export default NoOptionMenuList
