import CIcon from '@coreui/icons-react';
import { CModal, CModalBody } from '@coreui/react';
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { DATE_FUNCTION_VARIABLES, TYPE_RULE } from '../../../../constants';
import { CInput } from '../../../migration/CInput';

const InsertVariableModal = (props) => {
     const {
          showVariableModal,
          closeVariableModal,
          handleChangeRowData,
          variableOptions,
          browserVariableShortCodes = [],
          variablePositionToInsert,
          cursorPosition,
          tableRow,
          typeRule,
     } = props;
     const [variableBoxFilter, setVariableBoxFilter] = useState(''); // Current text in the insert data layer variable box filter
     const [openTypeVariable, setOpenTypeVariable] = useState({});
     const filterInputRef = useRef(null);

     const valueField =
          (tableRow &&
               variablePositionToInsert &&
               tableRow[variablePositionToInsert.rowIndex] &&
               tableRow[variablePositionToInsert.rowIndex].customVariables &&
               tableRow[variablePositionToInsert.rowIndex].customVariables[variablePositionToInsert.colIndex] &&
               tableRow[variablePositionToInsert.rowIndex].customVariables[variablePositionToInsert.colIndex].value) ||
          '';

     let variableBoxFilterLowerCase = variableBoxFilter.toLowerCase();
     // Automatic variable options which are displayed in insert data layer variable box
     let variableOptionsFound = variableOptions.filter((option) => option.fullName.toLowerCase().includes(variableBoxFilterLowerCase));
     // Date function variable options which are displayed in insert a function box
     let functionOptionsFound = DATE_FUNCTION_VARIABLES.filter((option) => option.value.toLowerCase().includes(variableBoxFilterLowerCase));
     // Browser storage variable options which are displayed in insert a browser storage variable box
     let browserVariableOptionsFound = browserVariableShortCodes.filter((option) => option.toLowerCase().includes(variableBoxFilterLowerCase));
     const [variableOptionsData, setVariableOptionsData] = useState([]);
     const wrapperRef = useRef(null);
     const myCustomRef = useRef(null);
     const itemsLoadMore = 20;

     const handleCloseVariableModal = useCallback(() => {
          closeVariableModal();
          setVariableBoxFilter('');
          setOpenTypeVariable({});
          setVariableOptionsData([]);
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (showVariableModal && filterInputRef.current) {
               setTimeout(() => {
                    filterInputRef.current.focus();
               }, 0);
          }
     }, [showVariableModal]);

     // Add [automatic variable] to value input
     const handleInsertVariable = ({ textToInsert, rowIndex, colIndex }) => {
          let valueToChange = tableRow[rowIndex].customVariables[colIndex].value; // [automatic variable] will be inserted to this value

          if (cursorPosition === 0) {
               // Cursor position currently is at the start of the text area
               valueToChange = `{{${textToInsert}}}${valueToChange}`;
          } else if (cursorPosition === valueToChange.length || cursorPosition === -1) {
               // Cursor position currently is at the end of the text area
               // or there's no cursor position
               valueToChange += `{{${textToInsert}}}`;
          } else {
               // Cursor position currently is somewhere in the middle of the text area
               let beforeCursorValue = valueToChange.substring(0, cursorPosition);
               let afterCursorValue = valueToChange.substring(cursorPosition);
               valueToChange = `${beforeCursorValue}{{${textToInsert}}}${afterCursorValue}`; // Insert [automatic variable] between beforeCursorValue and afterCursorValue
          }

          handleCloseVariableModal();
          if (typeRule === TYPE_RULE.CONVERSION_RULE) {
               handleChangeRowData(valueToChange, 'value', rowIndex, colIndex);
          } else {
               handleChangeRowData(valueToChange, 'customVariables', rowIndex, colIndex);
          }
     };
     const handleOpenTypeVariable = (id) => {
          setOpenTypeVariable((prevState) => ({ ...prevState, [id]: !prevState[id] }));
          
          if (id === 'Data Layer Variable') {
               loadMoreItems();

               if (openTypeVariable['Data Layer Variable']) {
                    setVariableOptionsData([]);
               }
          }
     };

     useEffect(() => {
          if (variableBoxFilter) {
               searchItems(variableOptions, variableBoxFilter);

               return () => {
                    searchItems.cancel();
               };
          } else {
               const newVariableOptions = variableOptions.filter((item) => item.label !== '').slice(0, itemsLoadMore);
               setVariableOptionsData(newVariableOptions);
               if (wrapperRef.current) {
                    wrapperRef.current.scrollTo({ top: 0 });
               }
          }
     }, [variableBoxFilter]); // eslint-disable-line

     const searchItems = useMemo(() => {
          return debounce((items, query) => {
               if (query) {
                    const filteredItems = items.filter((item) => item.fullName.toLowerCase().includes(query.toLowerCase())).slice(0, itemsLoadMore);
                    setVariableOptionsData(filteredItems);
               }
          }, 200);
     }, []);

     const handleSearch = (e) => {
          setVariableBoxFilter(e);
          const searchObject = {
               'Data Layer Variable': true,
               'Browser Storage Variable': true,
               Function: true,
          };
          if (Object.keys(searchObject).length > 0) {
               setOpenTypeVariable(searchObject);
          }
     };

     const loadMoreItems = () => {
          const currentLength = variableOptionsData.length;
          const moreItems = variableOptionsFound && variableOptionsFound.slice(currentLength, currentLength + itemsLoadMore);

          if (variableOptionsData.length + moreItems.length <= variableOptionsFound.length) {
               setVariableOptionsData((prevItems) => [...prevItems, ...moreItems]);
          }
     };

     const handleScroll = () => {
          if (
               wrapperRef.current &&
               myCustomRef.current &&
               wrapperRef.current.scrollTop + wrapperRef.current.clientHeight >= wrapperRef.current.scrollHeight - myCustomRef.current.clientHeight
          ) {
               loadMoreItems();
          }
     };

     return (
          <div className="insert-variable-modal">
               <CModal visible={showVariableModal} onClose={handleCloseVariableModal} portal={false}>
                    <CModalBody>
                         <CIcon icon="cil-x" onClick={handleCloseVariableModal} className="icon-close-popup icon-close-popup-insert" />

                         <div className="insert-automatic-variables no-options insert-variables-lookup">
                              <>
                                   <div className="variable-filter">
                                        <h6>Insert Data Layer Variable</h6>
                                        <CInput
                                             type="text"
                                             innerRef={filterInputRef}
                                             value={variableBoxFilter}
                                             onChange={(e) => handleSearch(e.target.value)}
                                             data-lpignore="true"
                                             placeholder="Select, or type to search"
                                        />
                                   </div>
                                   <ul ref={wrapperRef} onScroll={handleScroll}>
                                        <li
                                             className="d-flex align-items-center justify-content-between border-bottom border-top type-variable"
                                             onClick={() => handleOpenTypeVariable('Data Layer Variable')}
                                        >
                                             <strong>Data Layer Variable</strong>
                                             {openTypeVariable['Data Layer Variable'] ? (
                                                  <strong>{variableOptionsFound.length}</strong>
                                             ) : (
                                                  <CIcon icon="iconArrowDownStrong" />
                                             )}
                                        </li>
                                        {openTypeVariable['Data Layer Variable'] && (
                                             <>
                                                  {variableOptionsData.length > 0 ? (
                                                       variableOptionsData.map((option) => {
                                                            const activeValue = valueField.includes(`{{${option.fullName}}}`);
                                                            const re = new RegExp(variableBoxFilter, 'gi');
                                                            let newVariable = option.friendlyName || option.fullName;
                                                            if (variableBoxFilter) {
                                                                 newVariable = newVariable.replace(re, `<strong>${variableBoxFilter}</strong>`);
                                                            }
                                                            return (
                                                                 <li
                                                                      className={`border-bottom d-flex align-items-center ${activeValue ? 'active-variable' : ''}`}
                                                                      key={option.id}
                                                                      onClick={(e) =>
                                                                           handleInsertVariable({
                                                                                textToInsert: option.fullName,
                                                                                rowIndex: variablePositionToInsert.rowIndex,
                                                                                colIndex: variablePositionToInsert.colIndex,
                                                                           })
                                                                      }
                                                                 >
                                                                      <span dangerouslySetInnerHTML={{ __html: `${newVariable}` }}></span>
                                                                 </li>
                                                            );
                                                       })
                                                  ) : (
                                                       <li className="no-options border-bottom d-flex align-items-center">No options found.</li>
                                                  )}
                                             </>
                                        )}
                                        <ol ref={myCustomRef} className="list-unstyled">
                                             {typeRule !== TYPE_RULE.USER_SOURCE_RULE && (
                                                  <>
                                                       <li
                                                            className="d-flex align-items-center justify-content-between border-bottom type-variable"
                                                            onClick={() => handleOpenTypeVariable('Browser Storage Variable')}
                                                       >
                                                            <strong>Browser Variable</strong>
                                                            {openTypeVariable['Browser Storage Variable'] ? (
                                                                 <strong>{browserVariableOptionsFound.length}</strong>
                                                            ) : (
                                                                 <CIcon icon="iconArrowDownStrong" />
                                                            )}
                                                       </li>
                                                       {openTypeVariable['Browser Storage Variable'] && (
                                                            <>
                                                                 {browserVariableOptionsFound.length > 0 ? (
                                                                      browserVariableOptionsFound.map((option) => {
                                                                           const activeValue = valueField.includes(`{{${option}}}`);
                                                                           const re = new RegExp(variableBoxFilter, 'gi');
                                                                           let newVariable = option;
                                                                           if (variableBoxFilter) {
                                                                                newVariable = newVariable.replace(
                                                                                     re,
                                                                                     `<strong>${variableBoxFilter}</strong>`,
                                                                                );
                                                                           }
                                                                           return (
                                                                                <li
                                                                                     className={`border-bottom d-flex align-items-center ${activeValue ? 'active-variable' : ''}`}
                                                                                     key={option}
                                                                                     onClick={(e) =>
                                                                                          handleInsertVariable({
                                                                                               textToInsert: option,
                                                                                               rowIndex: variablePositionToInsert.rowIndex,
                                                                                               colIndex: variablePositionToInsert.colIndex,
                                                                                          })
                                                                                     }
                                                                                >
                                                                                     <span dangerouslySetInnerHTML={{ __html: `${newVariable}` }}></span>
                                                                                </li>
                                                                           );
                                                                      })
                                                                 ) : (
                                                                      <li className="no-options border-bottom d-flex align-items-center">
                                                                           No options found.
                                                                      </li>
                                                                 )}
                                                            </>
                                                       )}
                                                  </>
                                             )}

                                             <li
                                                  className="d-flex align-items-center justify-content-between border-bottom type-variable"
                                                  onClick={() => handleOpenTypeVariable('Function')}
                                             >
                                                  <strong>Timestamp</strong>
                                                  {openTypeVariable['Function'] ? (
                                                       <strong>{functionOptionsFound.length}</strong>
                                                  ) : (
                                                       <CIcon icon="iconArrowDownStrong" />
                                                  )}
                                             </li>
                                             {openTypeVariable['Function'] && (
                                                  <>
                                                       {functionOptionsFound.length > 0 ? (
                                                            functionOptionsFound.map((option) => {
                                                                 const activeValue = valueField.includes(`{{${option.value}}}`);
                                                                 const re = new RegExp(variableBoxFilter, 'gi');
                                                                 let newVariable = option.value;
                                                                 if (variableBoxFilter) {
                                                                      newVariable = newVariable.replace(re, `<strong>${variableBoxFilter}</strong>`);
                                                                 }
                                                                 return (
                                                                      <li
                                                                           className={`border-bottom d-flex align-items-center ${activeValue ? 'active-variable' : ''}`}
                                                                           key={option.value}
                                                                           onClick={(e) =>
                                                                                handleInsertVariable({
                                                                                     textToInsert: option.value,
                                                                                     rowIndex: variablePositionToInsert.rowIndex,
                                                                                     colIndex: variablePositionToInsert.colIndex,
                                                                                })
                                                                           }
                                                                      >
                                                                           <span dangerouslySetInnerHTML={{ __html: `${newVariable}` }}></span>
                                                                      </li>
                                                                 );
                                                            })
                                                       ) : (
                                                            <li className="no-options border-bottom d-flex align-items-center">No options found.</li>
                                                       )}
                                                  </>
                                             )}
                                        </ol>
                                   </ul>
                                   {/* {
                                        (activeBoxType === 'function') && (
                                            (DATE_FUNCTION_VARIABLES.length > 0) ? (
                                                <>
                                                    <div className="variable-filter">
                                                        <h6>Insert a Function</h6>
                                                        <CInput
                                                            type="text"
                                                            value={variableBoxFilter}
                                                            onChange={e => setVariableBoxFilter(e.target.value)}
                                                            data-lpignore="true"
                                                            placeholder="Type here to filter the list…"
                                                        />
                                                    </div>
                                                    <ul>
                                                        {
                                                            functionOptionsFound.length > 0 ? (
                                                                functionOptionsFound.map(option => (
                                                                    <li
                                                                        key={option.value}
                                                                        onClick={e => handleInsertVariable({
                                                                            textToInsert: option.value,
                                                                            rowIndex: variablePositionToInsert.rowIndex,
                                                                            colIndex: variablePositionToInsert.colIndex
                                                                        })}
                                                                    >
                                                                        {option.value}
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <li className="no-options">No options found.</li>
                                                            )
                                                        }
                                                    </ul>
                                                </>
                                            ) : (
                                                <>
                                                    <br />
                                                    <span className="no-options">No options available.</span>
                                                </>
                                            )
                                        )
                                    } */}
                                   {/* {
                                        (activeBoxType === 'browser storage') && (
                                            (browserVariableShortCodes.length > 0) ? (
                                                <>
                                                    <div className="variable-filter">
                                                        <h6>Insert Browser Storage Variable</h6>
                                                        <CInput
                                                            type="text"
                                                            value={variableBoxFilter}
                                                            onChange={e => setVariableBoxFilter(e.target.value)}
                                                            data-lpignore="true"
                                                            placeholder="Type here to filter the list…"
                                                        />
                                                    </div>
                                                    <ul>
                                                        {
                                                            browserVariableOptionsFound.length > 0 ? (
                                                                browserVariableOptionsFound.map(option => (
                                                                    <li
                                                                        key={option}
                                                                        onClick={e => handleInsertVariable({
                                                                            textToInsert: option,
                                                                            rowIndex: variablePositionToInsert.rowIndex,
                                                                            colIndex: variablePositionToInsert.colIndex
                                                                        })}
                                                                    >
                                                                        {option}
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <li className="no-options">No options found.</li>
                                                            )
                                                        }
                                                    </ul>
                                                </>
                                            ) : (
                                                <>
                                                    <br />
                                                    <NoOptionsMessage type={NO_OPTION_MESSAGE_TYPE.CUSTOM_BROWSER_VARIABLE} />
                                                </>
                                            )
                                        )
                                    } */}
                              </>
                         </div>
                    </CModalBody>
               </CModal>
          </div>
     );
};

export default InsertVariableModal;
