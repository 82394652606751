import React from 'react';
import { CRow } from '@coreui/react';
import CSwitch from '../../../../../migration/CSwitch';
import { CSmartPagination } from '@coreui/react-pro';

function PaginationReport({pagination, activePage, totalPages, onPaginationChange, onChangeItemsPerPage, itemsPerPage, handleChangePagination}) {
     return (
          <CRow className={`report-table-bottom ${!pagination ? 'justify-content-end' : ''}`}>
               {pagination && (
                    <div className="d-flex px-0">
                         <CSmartPagination
                              activePage={activePage}
                              pages={totalPages}
                              onActivePageChange={onPaginationChange}
                              className="report-table-page"
                         ></CSmartPagination>
                         <div className="form-inline justify-content-sm-end ml-4">
                              <select className="form-control" onChange={onChangeItemsPerPage} defaultValue={itemsPerPage}>
                                   <option value="5">5</option>
                                   <option value="10">10</option>
                                   <option value="20">20</option>
                                   <option value="50">50</option>
                              </select>
                              <label className="ml-2">Item per page</label>
                         </div>
                    </div>
               )}
               {!pagination && (
                    <div className="toggle-bottom">
                         <CSwitch
                              className="mx-1"
                              variant="3d"
                              color="primary"
                              checked={pagination ? 'checked' : ''}
                              onChange={(e) => handleChangePagination()}
                              disabled={true}
                         />
                         <span>Paging</span>
                    </div>
               )}
          </CRow>
     );
}

export default PaginationReport;
