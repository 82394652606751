import React, { useCallback, useEffect, useRef, useState } from 'react';
import CIcon from '@coreui/icons-react';
import { CButton } from '@coreui/react';
import { useOutsideHandling } from '../../../utils';

const ClickInfoIconTooltip = ({
     children,
     showIcon = true,
     text,
     button = false,
     btnText = '',
     btnColor = '',
     showTooltip = false,
     showSelect = false,
     toggleShow,
     placement,
     showPopupCreateCategories,
     specialShow = false,
     iconName = 'icon-info',
     isIconClose = false,
     showTooltipCancel = false,
}) => {
     const [detailMessage, setDetailMessage] = useState(false);
     const clickOutside = useCallback(() => {
          if (!showTooltip) setDetailMessage(false);
          if (showPopupCreateCategories) {
               setDetailMessage(false);
          }
     }, [showTooltip, showPopupCreateCategories]);

     const wrapperRef = useRef(null);

     useOutsideHandling(wrapperRef, clickOutside, 'click', showPopupCreateCategories);

     const handleClickBubble = () => {
          setTimeout(() => {
               if (!detailMessage) {
                    setDetailMessage(true);
               }
          }, 100);
     };

     const handleClickIconClose = () => {
          setTimeout(() => {
               setDetailMessage(false);
          }, 100);
     };

     useEffect(() => {
          if (typeof toggleShow !== 'undefined') {
               setDetailMessage(() => false);
          }
     }, [toggleShow]);

     useEffect(() => {
          if (showTooltipCancel) {
               setDetailMessage((s) => !s);
          }
     }, [showTooltipCancel]);

     return (
          <>
               {button ? (
                    <CButton color={btnColor} onClick={handleClickBubble}>
                         {btnText}
                    </CButton>
               ) : (
                    <>
                         {!showIcon ? (
                              specialShow ? (
                                   <>
                                        <div className="box-content d-flex align-items-center" onClick={handleClickBubble}>
                                             <CIcon icon={iconName} className="pointer mr-2" />
                                             {text && <span>{text}</span>}
                                        </div>
                                   </>
                              ) : (
                                   text && <span onClick={handleClickBubble}>{text}</span>
                              )
                         ) : (
                              <CIcon icon={iconName} className="pointer" onClick={handleClickBubble} />
                         )}
                    </>
               )}

               {detailMessage && (
                    <div className={`${placement === 'bottom' ? 'block-tooltip-bottom type-click' : 'block-tooltip type-click'}`} ref={wrapperRef}>
                         <CIcon icon="arrow-preview" className="arrow-preview"></CIcon>
                         <div className="block-tooltip-text">
                              {isIconClose && (
                                   <div className="icon-close" onClick={handleClickIconClose}>
                                        <CIcon icon="iconClose" style={{ cursor: 'pointer', width: '9px', height: '9px', position: 'unset' }}></CIcon>
                                   </div>
                              )}
                              {children}
                         </div>
                    </div>
               )}
          </>
     );
};

export default ClickInfoIconTooltip;
