import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CCard, CCardBody, CSpinner, CButton, CBadge } from '@coreui/react';
import PropTypes from 'prop-types';
import CIcon from '@coreui/icons-react';
import { useHistory } from 'react-router-dom';
import { callTokenApi } from '../../../../apiCaller';
import { toastError } from '../../../../utils';
import { getEnabledListeners, getListenerColorIcon, getDestinationRoutes } from '../../../../helpers/cms/subscriber';
import { API_CLIENT_ACCOUNT_DESTINATIONS, FILTER_OPTIONS, SUBSCRIBER_PATH } from '../../../../constants';
import { setShowBlockAccountPopup, setSubscriberState } from '../../../../actions/subscriber';
import { InstructionPopup, SwitchPopup } from '../../../general/popup';
import { toast } from 'react-toastify';
import DestinationCustomItem from './DestinationCustomItem';
import { setFlexibleModal } from '../../../../actions/common';
import CSwitch from '../../../migration/CSwitch';

const DestinationItems = ({ destinations, filterType }) => {
     const dispatch = useDispatch();
     const history = useHistory();
     const newLayout = useSelector((state) => state.subscriber.newLayout);
     const accountRoutes = useSelector((state) => state.subscriber.routes);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountDestinations = useSelector((state) => state.subscriber.accountDestinations);

     const [isLoading, setIsLoading] = useState(false);
     const [tempDestination, setTempDestination] = useState();
     const [learnVideoIndex, setLearnVideoIndex] = useState(-1);
     const [turningOnPopup, setTurningOnPopup] = useState(false);
     const [turningOffPopup, setTurningOffPopup] = useState(false);

     const pathName = history.location.pathname;
     const learnVideoPopupAttrs = learnVideoIndex > -1 ? { ...destinations[learnVideoIndex].videoOverview.popup } : null;
     const comingSoonDestinationAlias = ['zapier', 'slack', 's3'];
     const { showNewLayout } = newLayout;
     const notComingSoonDestinationsGtm = [];
     const notComingSoonDestinations = [];
     const comingSoonDestinations = [];

     destinations.forEach((des) => {
          if (comingSoonDestinationAlias.includes(des.alias)) {
               des.comingSoon = true;
               comingSoonDestinations.push(des);
          } else {
               des.comingSoon = false;
               if (des.name === 'Google Tag Manager') {
                    notComingSoonDestinationsGtm.push(des);
               } else {
                    notComingSoonDestinations.push(des);
               }
          }
     });
     comingSoonDestinations.sort((a, b) => a.name.localeCompare(b.name));

     destinations = [...notComingSoonDestinationsGtm, ...notComingSoonDestinations, ...comingSoonDestinations];

     useEffect(() => {
          const libraryWrapper = document.querySelector('.listeners .library-wrapper');
          if (libraryWrapper) {
               libraryWrapper.scrollTo(0, 0);
          }
     }, [filterType]);

     const toggleItem = (item, status) => {
          setTempDestination(item);

          if (status === 'on') {
               setTurningOffPopup(true);
          } else {
               setTurningOnPopup(true);
          }
     };

     const acceptSwitching = () => {
          setIsLoading(true);
          const status = !tempDestination.configStatus ? true : false;

          const data = {
               accountId: activeAccount.id,
               destinationId: tempDestination.id,
               status,
          };

          callTokenApi(`${API_CLIENT_ACCOUNT_DESTINATIONS}update`, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         const { accountDestination } = response.data;
                         let newDestination = {};
                         let destinationIndex = 0;

                         accountDestinations.some((destination, index) => {
                              if (tempDestination.id === destination.id) {
                                   destinationIndex = index;
                                   newDestination = destination;
                                   newDestination.configStatus = !newDestination.configStatus;
                                   newDestination.accountDestinationId = accountDestination.id;

                                   return true;
                              }

                              return false;
                         });

                         if (!status && pathName.includes(newDestination.alias)) {
                              history.push('/');
                              dispatch(setFlexibleModal({ show: false, component: '' }));
                         }

                         let newAccountDestination = [...accountDestinations];
                         newAccountDestination[destinationIndex] = newDestination;

                         const enabledDestinations = getEnabledListeners(newAccountDestination);
                         const destinationRoutes = getDestinationRoutes(enabledDestinations, activeAccount.secondId);
                         const newAccountRoutes = [...accountRoutes].filter(
                              (route) => !destinationRoutes.some((destinationRoute) => destinationRoute.path === route.path)
                         );
                         const newRoutes = [...newAccountRoutes, ...destinationRoutes];

                         dispatch(
                              setSubscriberState({
                                   routes: newRoutes,
                                   accountDestinations: newAccountDestination,
                                   activeDestinations: enabledDestinations,
                              })
                         );

                         if (newDestination.configStatus === true) {
                              dispatch(
                                   setSubscriberState({
                                        activeDestination: newDestination,
                                   })
                              );
                         }

                         // High light destination nav in sidebar when turn on
                         if (status) {
                              dispatch(
                                   setSubscriberState({
                                        elementJustActivated: {
                                             id: newDestination.id,
                                             type: newDestination.type,
                                        },
                                   })
                              );
                              setTimeout(() => {
                                   dispatch(
                                        setSubscriberState({
                                             elementJustActivated: null,
                                        })
                                   );
                              }, 500);
                         }

                         toast.success(response.data.message);
                    } else {
                         if (response.data.accountBlocked) {
                              dispatch(setShowBlockAccountPopup(true));
                         } else {
                              toastError(response);
                         }
                    }
               })
               .finally(() => {
                    setIsLoading(false);
                    // Disable
                    setTurningOffPopup(false);
                    // Enable
                    setTurningOnPopup(false);
               });
     };

     const handleLinkToPageDetail = (alias) => {
          history.push(`${SUBSCRIBER_PATH.DESTINATIONS.replace(':secondId', activeAccount.secondId)}/${alias}`);
          dispatch(setFlexibleModal({ show: false, component: '' }));
     };

     return (
          <div className='library-wrapper'>
               <div className='listener-items row-items'>
                    {filterType === FILTER_OPTIONS.SHOW_ALL && <DestinationCustomItem />}
                    {destinations.map((destination, destinationIndex) => {
                         const { name, code, description, configStatus, videoOverview, createdAccountId } = destination;
                         let iconSrc = getListenerColorIcon(destination);
                         let twoItemsBottomRow = videoOverview && videoOverview.isOn;

                         return (
                              <div className='listener-item' key={name}>
                                   <CCard>
                                        <CCardBody>
                                             <div className='card-title d-flex justify-content-between align-items-center w-100'>
                                                  <div className='d-flex justify-content-between'>
                                                       <img src={iconSrc} alt='Icon' className='icon-20 mr-2' />
                                                       <h4>{name}</h4>
                                                  </div>
                                                  {createdAccountId && <CBadge className='label-top-card custom-badge' style={{color: '#333'}}>custom</CBadge>}
                                             </div>

                                             <div className='card-text h-100'>{description && <p className='f-14'>{description}</p>}</div>
                                             <div className={`card-icon d-flex ${twoItemsBottomRow ? 'justify-content-between' : 'justify-content-end'}`}>
                                                  {videoOverview && videoOverview.isOn && (
                                                       <CButton className='btn-learn-video btn' onClick={() => setLearnVideoIndex(destinationIndex)}>
                                                            <CIcon icon='arrow-play-radius' height={13} /> <span>Learn</span>
                                                       </CButton>
                                                  )}

                                                  <div className='card-header-actions'>
                                                       {isLoading && destination.id === tempDestination.id && <CSpinner color='primary' size='sm' className='mr-2' />}
                                                       {configStatus && showNewLayout && (
                                                            <CButton className='action-open-menu mr-2' onClick={() => handleLinkToPageDetail(destination.alias)}>
                                                                 <CIcon icon='cil-settings' height={16} />
                                                            </CButton>
                                                       )}
                                                       {destination.comingSoon && <span className='coming-soon-text'>Coming soon</span>}
                                                       <CSwitch
                                                            className='float-right mb-0'
                                                            color={configStatus ? 'success' : 'light'}
                                                            checked={!!configStatus} // !!false = false, !!undefined = false, !!true = true
                                                            value={configStatus ? 'on' : 'off'}
                                                            size='lg'
                                                            tabIndex='0'
                                                            shape='pill'
                                                            onChange={(e) => toggleItem(destination, e.target.value)}
                                                            disabled={(configStatus && code === 'googleTagManager') || destination.comingSoon || isLoading}
                                                       />
                                                  </div>
                                             </div>
                                        </CCardBody>
                                   </CCard>
                              </div>
                         );
                    })}
                    <InstructionPopup show={learnVideoIndex > -1} onClose={() => setLearnVideoIndex(-1)} {...learnVideoPopupAttrs} />
                    <SwitchPopup show={turningOnPopup} isLoading={isLoading} onAccept={acceptSwitching} onCancel={() => setTurningOnPopup(false)}>
                         <h2>Enable Destination</h2>
                         <div className='confirm-save-content'>
                              You're about to enable this destination. You will still need to publish your changes before this goes live.
                         </div>
                    </SwitchPopup>
                    <SwitchPopup show={turningOffPopup} onAccept={acceptSwitching} onCancel={() => setTurningOffPopup(false)} isLoading={isLoading}>
                         <h2>Disable Destination</h2>
                         <div className='confirm-save-content'>
                              Once a destination is disabled, all settings and rules for it will be lost and cannot be restored. Are you sure you want to make this
                              change?
                         </div>
                    </SwitchPopup>
               </div>
          </div>
     );
};

DestinationItems.propTypes = {
     destinations: PropTypes.array,
};

export default DestinationItems;
