import React, { useContext } from 'react';
import { CCard, CCardBody, CButton, CRow, CCol } from '@coreui/react';
import { SEE_HOW_IT_WORKS_VIDEO_LINK } from '../../../../../constants';
import { CreateAccountBody, CreateAccountContext, STEPS } from './CreateAccount';
import useSaveCurrentStep from './useSaveCurrentStep';
import CEmbed from '../../../../migration/CEmbed';
import CLabel from '../../../../migration/CLabel';

const SeeHowItWorks = () => {
     const { setCurrentStep, prevSteps } = useContext(CreateAccountContext);

     const onClickSeePricing = () => {
          setCurrentStep(STEPS.seePricing)
     }
     const onClickScheduleDemo = () => {
          setCurrentStep(STEPS.scheduleDemo)
     }
     const onClickCreateAccount = () => {
          setCurrentStep(STEPS.createLogin)
     }

     const onBackBtnClicked = () => {
          setCurrentStep(STEPS.userReplace);
     }

     useSaveCurrentStep()

     const backBtn = prevSteps[0] === STEPS.userReplace || prevSteps[0] === STEPS.userWork || prevSteps[0] === STEPS.userCompliant || prevSteps[0] === STEPS.userIdentify || prevSteps[0] === STEPS.userSizeCompany || prevSteps[0] === STEPS.userDesRole || prevSteps[0] === STEPS.userRevenue

     return (
          <CreateAccountBody onBackBtnClicked={backBtn ? onBackBtnClicked : null} hideStepProgress={true}>
               <div>
                    <CCard className="account-see-how-it-work">
                         <CCardBody>
                              <h3>See How It Works</h3>
                              <p className="des">Watch this 1-minute video to proceed.</p>
                              <div className="video-wrapper d-flex justify-content-center align-items-center">
                                   {/* <div className="icon-play"><CIcon icon="arrow-play" className="text-primary" height={30} /></div> */}
                                   <CEmbed ratio="16by9">
                                        <iframe
                                             src={SEE_HOW_IT_WORKS_VIDEO_LINK}
                                             // frameborder='0'
                                             allow='autoplay; fullscreen; picture-in-picture'
                                             // style='position:absolute;top:0;left:0;width:100%;height:100%;'
                                             title='MeasureSummit Video'
                                        />
                                   </CEmbed>
                              </div>
                              <div className="mt-4">
                                   <CLabel>Take action after you watch...</CLabel>
                                   <CRow>
                                        <CCol md='6'>
                                             <CButton className="btn-save h-auto" onClick={onClickScheduleDemo}>
                                                  <p>SCHEDULE A DEMO</p>
                                                  <span>One-on-one, in-depth walkthrough</span>
                                             </CButton>
                                        </CCol>
                                        <CCol md='6'>
                                             <CButton className="btn-save h-auto" onClick={onClickCreateAccount}>
                                                  <p>CREATE AN ACCOUNT</p>
                                                  <span>Get started immediately</span>
                                             </CButton>
                                        </CCol>
                                   </CRow>
                              </div>
                         </CCardBody>
                    </CCard>
                    <div className='btn-outside'>
                         <p className='foot-note'> Curious how much ListenLayer costs ?
                              <CButton className="btn-navigation-step" onClick={onClickSeePricing}>
                                   See Pricing {'>'}
                              </CButton>
                         </p>
                    </div>
               </div>
          </CreateAccountBody>
     );
};

export default SeeHowItWorks;
