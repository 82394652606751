import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CButton, CContainer, CModal, CModalBody } from '@coreui/react';
import CIcon from '@coreui/icons-react';
// import { Link } from 'react-router-dom';
import intersection from 'lodash/intersection';

import {
     fetchAnAccount,
     fetchListeners,
     resetSubscriberReducer,
     setActiveAccount,
     setSignUpStep,
     setIsFromGetStarted,
     setLoadings,
     setShowCreateAccountPopup,
     setCreatedAccount,
     setAccounts,
} from '../../../../../actions/subscriber';
import {
     ACCOUNT_INFO_STEPS_ARR,
     CREATE_ACCOUNT_MAIN_STEPS,
     CREATE_ACCOUNT_MAIN_STEPS_ARR,
     LOCAL_SETUP_INVITED_ACCOUNT,
     LOCAL_SHOW_ONLY_ENABLED_LISTENERS,
} from '../../../../../constants';
import { urlParams } from '../../../../../helpers/cms/subscriber';
import { usePrevious } from '../../../../../helpers/customHooks';
import { getSessionKeySignupCurrentStep, getSessionKeySignupDestinations, getSessionKeySignupListeners, tryParseJSON } from '../../../../../utils';

import StepProgress from './StepProgress';
import Name from './Name';
import TimeZone from './TimeZone';
import Domain from './domain/Domain';
import DomainVerifying from './domain/DomainVerifying';
import DomainSuccess from './domain/DomainSuccess';
import DomainRedirect from './domain/DomainRedirect';
import DomainCannotAccess from './domain/DomainCannotAccess';
import GTMConnection from './gtm/GTMConnection';
import GTMSelectAccount from './gtm/GTMSelectAccount';
import GTMSelectContainer from './gtm/GTMSelectContainer';
import GTMVerifyChanges from './gtm/GTMVerifyChanges';
import GTMMakeChanges from './gtm/GTMMakeChanges';
import GTMNoPublishingRights from './gtm/GTMNoPublishingRights';
import GTMPublishSuccess from './gtm/GTMPublishSuccess';
import GTMUnknownFailure from './gtm/GTMUnknownFailure';
import PlaceScriptManually from './gtm/PlaceScriptManually';
import SkipPlacingScript from './script-verification/SkipPlacingScript';
import VerifyingScript from './script-verification/VerifyingScript';
import VerificationSuccess from './script-verification/VerificationSuccess';
import VerificationFailed from './script-verification/VerificationFailed';
import CannotVerifyScript from './script-verification/CannotVerifyScript';
import VerifyWithInaccessibleDomain from './script-verification/VerifyWithInaccessibleDomain';
import AlmostDone from './AlmostDone';
import ConfigureMeasurement from './listener-configuration/ConfigureMeasurement';
import FormListener from './listener-configuration/FormListener';
import ChatListener from './listener-configuration/ChatListener';
import EcommerceListener from './listener-configuration/EcommerceListener';
import VideoListener from './listener-configuration/VideoListener';
import WeatherListener from './listener-configuration/WeatherListener';
import ConfiguringAccount from './listener-configuration/ConfiguringAccount';
import AccountPackage from './AccountPackage';
import AccountPayment from './AccountPayment';
import AccountSetup from './AccountSetup';
import SeeHowItWorks from './SeeHowItWorks';
import SeePricing from './SeePricing';
import ScheduleDemo from './ScheduleDemo';
import ChooseDestination from './destination-configuration/ChooseDestination';
import CustomDestination from './destination-configuration/CustomDestination';
import UserWork from './UserWork';
import UserSizeCompany from './UserSizeCompany';
import UserDesRole from './UserDesRole';
import UserRevenue from './UserRevenue';
import UserIdentify from './UserIdentify';
import UserCompliant from './UserCompliant';
import UserReplace from './UserReplace';
import UserAutomates from './UserAutomates';
import UserCentralize from './UserCentralize';
import UserAnalytics from './UserAnalytics';
// import UserSectionReplay from './UserSectionReplay';
// import UserConsent from './UserConsent';
import CreateLogin from './CreateLogin';
import Register from './RegistorUser';
import TalkToSales from './TalkToSales';
import ThankYouTalk from './ThankYouTalk';
import ThankYouDemo from './ThankYouDemo';
import { signout } from '../../../../../helpers/auth';

export const CreateAccountContext = React.createContext({});
export const STEPS = {
     ...CREATE_ACCOUNT_MAIN_STEPS,
     createLogin: 'Create your Login',
     seeHowItWorks: 'See How Is Works',
     seePricing: 'See Pricing',
     scheduleDemo: 'Schedule a Demo',
     scheduleDemoThankYou: 'Schedule a Demo Thank You',
     whatBringsOther: 'What Brings Other',
     domainVerifying: 'Domain Verifying',
     domainSuccess: 'Domain Success',
     domainRedirect: 'Domain Redirect',
     domainCannotAccess: 'Domain Cannot Access',
     gtmSelectAccount: 'GTM Select Account',
     gtmSelectContainer: 'GTM Select Container',
     gtmVerifyChanges: 'GTM Verify Changes',
     gtmMakeChanges: 'GTM Make Changes',
     gtmNoPublishingRights: 'GTM No Publishing Rights',
     gtmPublishSuccess: 'GTM Publish Success',
     gtmUnknownFailure: 'GTM Unknown Failure',
     placeScriptManually: 'Place Script Manually',
     verifyingScript: 'Verifying Script',
     skipPlacingScript: 'Skip Placing Script',
     cannotVerify: 'Cannot Verify', // Cannot verify script due to unable to publish GTM Container
     verificationSuccess: 'Verification Success',
     verificationFailed: 'Verification Failed',
     verifyWithInaccessibleDomain: 'Verify with Inaccessible Domain',
     almostDone: 'Almost Done',
     formListener: 'Form Listener',
     chatListener: 'Chat Listener',
     ecommerceListener: 'Ecommerce Listener',
     videoListener: 'Video Listener',
     weatherListener: 'Weather Listener',
     customDestination: 'Custom Destinations',
     configuringAccount: 'Configuring Account',
     accountPayment: 'Account Payment',
     talkToSales: 'Talk To Sales',
     talkToSalesThankYou: 'Talk To Sales Thank You',
     accountSetup: 'Account Setup',
     register: 'Register User',
};
export const CHANGE_STEP_TYPE = {
     mainStepNext: 'Main Step Next',
     mainStepPrev: 'Main Step Previous',
     subStepNext: 'Sub Step Next',
     subStepPrev: 'Sub Step Previous',
};

export const CreateAccountBody = ({
     onBackBtnClicked,
     backBtnDisabled,
     shouldHideBackBtn,
     hideStepProgress,
     children,
     mainContentClassName = '',
     accountPackage,
}) => {
     const { shouldHideStepProgress, scheduleUpgrade, setCurrentStep, showPopupLargeSandbox, setShowPopupLargeSandbox, currentStep, account } =
          useContext(CreateAccountContext);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const isScheduleDemo = (activeAccount.id === account.id && activeAccount.isScheduleUpgrade) || scheduleUpgrade;
     const accounts = useSelector((state) => state.subscriber.accounts);
     const onClickScheduleDemo = () => {
          setCurrentStep(STEPS.talkToSales, CHANGE_STEP_TYPE.subStepNext);
          setShowPopupLargeSandbox(false);
     };
     let url = process.env.REACT_APP_LISTENLAYER_DOMAIN;

     const isSeePricing = currentStep === STEPS.seePricing;
     return (
          <>
               {!accountPackage ? (
                    <CContainer className="main-container">
                         <div className="d-flex align-items-center m-0 main-header">
                              <CButton
                                   className={`btn-back m-0 ${onBackBtnClicked && !shouldHideBackBtn ? '' : 'invisible'}`}
                                   onClick={onBackBtnClicked && !shouldHideBackBtn ? onBackBtnClicked : null}
                                   disabled={backBtnDisabled}
                              >
                                   Back
                              </CButton>
                              <div className="hero-logo">
                                   <CIcon icon="logo-dark" height={23} />
                              </div>
                              <CButton
                                   className={`m-0 btn-logout ${
                                        isScheduleDemo && accounts.length <= 1 && currentStep === STEPS.accountPackage ? '' : 'invisible'
                                   }`}
                                   onClick={signout}
                              >
                                   Log Out
                              </CButton>
                         </div>
                         <div className={`main-content ${mainContentClassName}`}>
                              {children}
                              {!shouldHideStepProgress && !hideStepProgress && <StepProgress />}
                         </div>
                         <div className="footer-create-account">
                              <div className="logo-footer">
                                   <CIcon icon="logo-dark" height={23} />
                              </div>
                              <div className="nav-link-footer">
                                   <ul>
                                        <li>
                                             <a href={`${url}support/`}>Support</a>
                                        </li>
                                        <li>
                                             <a href={`${url}terms-of-use/`}>Terms of Use</a>
                                        </li>
                                        <li>
                                             <a href={`${url}privacy-policy/`}>Privacy & Data Policy</a>
                                        </li>
                                   </ul>
                              </div>
                         </div>
                         <CModal
                              className="popup-sandbox"
                              visible={showPopupLargeSandbox}
                              onClose={() => setShowPopupLargeSandbox(false)}
                              color="primary"
                              alignment="center"
                              portal={false}
                         >
                              <CModalBody>
                                   <h3>Need a large sandbox</h3>
                                   <p>Request a demo and we’ll discuss your needs for a larger sandbox for testing.</p>
                                   <CButton color="primary" disabled={isScheduleDemo} className="btn-schedule" onClick={() => onClickScheduleDemo()}>
                                        Schedule a demo
                                   </CButton>
                              </CModalBody>
                         </CModal>
                    </CContainer>
               ) : (
                    <div className={`main-container ${isSeePricing ? 'bg-pricing' : ''}`}>
                         <div className="container">
                              <div className="d-flex align-items-center m-0 main-header">
                                   <CButton
                                        className={`btn-back m-0 ${
                                             onBackBtnClicked &&
                                             !shouldHideBackBtn &&
                                             (!isScheduleDemo || accounts.length > 1 || currentStep !== STEPS.accountPackage)
                                                  ? ''
                                                  : 'invisible'
                                        }`}
                                        onClick={onBackBtnClicked && !shouldHideBackBtn ? onBackBtnClicked : null}
                                        disabled={backBtnDisabled}
                                   >
                                        Back
                                   </CButton>
                                   <div className="hero-logo">
                                        <CIcon icon="logo-dark" height={23} />
                                   </div>
                                   <CButton
                                        className={`m-0 btn-logout ${isScheduleDemo && currentStep === STEPS.accountPackage ? '' : 'invisible'}`}
                                        onClick={signout}
                                   >
                                        Log Out
                                   </CButton>
                              </div>
                         </div>
                         <div className={`main-content ${mainContentClassName}`}>
                              {children}
                              {!shouldHideStepProgress && !hideStepProgress && <StepProgress />}
                         </div>
                         <div className="container">
                              <div className="footer-create-account">
                                   <div className="logo-footer">
                                        <CIcon icon="logo-dark" height={23} />
                                   </div>
                                   <div className="nav-link-footer">
                                        <ul>
                                             <li>
                                                  <a href={`${url}support/`}>Support</a>
                                             </li>
                                             <li>
                                                  <a href={`${url}terms-of-use/`}>Terms of Use</a>
                                             </li>
                                             <li>
                                                  <a href={`${url}privacy-policy/`}>Privacy & Data Policy</a>
                                             </li>
                                        </ul>
                                   </div>
                              </div>
                         </div>
                         <CModal
                              className="popup-sandbox"
                              visible={showPopupLargeSandbox}
                              onClose={() => setShowPopupLargeSandbox(false)}
                              color="primary"
                              alignment="center"
                              portal={false}
                         >
                              <CModalBody>
                                   <h3>Need a large sandbox</h3>
                                   <p>Request a demo and we’ll discuss your needs for a larger sandbox for testing.</p>
                                   <CButton color="primary" className="btn-schedule" disabled={isScheduleDemo} onClick={() => onClickScheduleDemo()}>
                                        Schedule a demo
                                   </CButton>
                              </CModalBody>
                         </CModal>
                    </div>
               )}
          </>
     );
};

const userInfo = {
     work: null,
     sizeCompany: null,
     desRole: null,
     revenue: null,
     identify: null,
     compliant: null,
     replace: null,
     automates: null,
     centralize: null,
     analytics: null,
     sessionReplay: null,
     consent: null,
};

const dataUser = JSON.stringify(userInfo);

const initialStepsData = {
     name: '',
     country: '',
     countryTimeZones: [],
     timeZone: '',
     timeZoneLabel: '',
     whatBringsOther: '',
     selectedGTMContainer: null,
     gtmWorkspace: null,
     domain: '',
     redirectDomain: '',
     domainStatus: null,
     tempSelectedListenerTopics: [],
     selectedListenerTopics: [],
     allSelectedListeners: {},
     allSelectedDestinations: [],
     selectedPremiumListenerIds: [],
     dataUser,
};

const useGetStarted = ({ setStepsDataState, setStepsData, setCurrentStep, setIncompleteSteps, setPrevSteps, setShouldHideStepProgress }) => {
     const initialAccount = { id: '', completedSteps: [], scheduleUpgrade: false };
     const [account, setAccount] = useState(initialAccount);
     const user = useSelector((state) => state.theme.user);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const isFromGetStarted = useSelector((state) => state.subscriber.isFromGetStarted);

     const handleGetStartedCurrentStep = (currentStep) => {
          if (Array.isArray(activeAccount.completedSteps) && activeAccount.completedSteps.length > 0) {
               let completedSteps = [...activeAccount.completedSteps];
               let incompleteSteps = [];

               if (user.registerUserInfo) {
                    const dataUser = typeof user.registerUserInfo == 'object' ? { ...user.registerUserInfo } : JSON.parse(user.registerUserInfo);

                    if (dataUser.work != null) {
                         completedSteps.push(CREATE_ACCOUNT_MAIN_STEPS.userWork);
                    }
                    if (dataUser.sizeCompany != null) {
                         completedSteps.push(CREATE_ACCOUNT_MAIN_STEPS.userSizeCompany);
                    }
                    if (dataUser.desRole != null) {
                         completedSteps.push(CREATE_ACCOUNT_MAIN_STEPS.userDesRole);
                    }
                    if (dataUser.revenue != null) {
                         completedSteps.push(CREATE_ACCOUNT_MAIN_STEPS.userRevenue);
                    }
                    if (dataUser.identify != null) {
                         completedSteps.push(CREATE_ACCOUNT_MAIN_STEPS.userIdentify);
                    }
                    if (dataUser.centralize != null) {
                         completedSteps.push(CREATE_ACCOUNT_MAIN_STEPS.userCentralize);
                    }
                    if (dataUser.compliant != null) {
                         completedSteps.push(CREATE_ACCOUNT_MAIN_STEPS.userCompliant);
                    }
                    if (dataUser.replace != null) {
                         completedSteps.push(CREATE_ACCOUNT_MAIN_STEPS.userReplace);
                    }
                    if (dataUser.automates != null) {
                         completedSteps.push(CREATE_ACCOUNT_MAIN_STEPS.userAutomates);
                    }
                    if (dataUser.analytics != null) {
                         completedSteps.push(CREATE_ACCOUNT_MAIN_STEPS.userAnalytics);
                    }
                    if (dataUser.sessionReplay != null) {
                         completedSteps.push(CREATE_ACCOUNT_MAIN_STEPS.userSessionReplay);
                    }
                    if (dataUser.consent != null) {
                         completedSteps.push(CREATE_ACCOUNT_MAIN_STEPS.userConsent);
                    }
               }

               Object.entries(CREATE_ACCOUNT_MAIN_STEPS).forEach(([_, value], index) => {
                    let incomplete = true;

                    completedSteps.every((completedStep) => {
                         if (completedStep === value) {
                              incomplete = false;
                         }

                         return incomplete;
                    });

                    if (incomplete) {
                         incompleteSteps.push({ value, index });
                    }
               });

               if (incompleteSteps[0]) {
                    let minIndex = incompleteSteps[0].index;
                    let newCurrentStep = incompleteSteps[0].value;
                    incompleteSteps = incompleteSteps.sort((a, b) => a.index - b.index).map((el) => el.value); // Order ascending steps

                    // Add steps before the current step to incompleteSteps so the user can go back from the current step
                    // Don't include info steps if those steps were answered before
                    for (let i = minIndex - 1; i >= 0; i--) {
                         if (!ACCOUNT_INFO_STEPS_ARR.includes(CREATE_ACCOUNT_MAIN_STEPS_ARR[i])) {
                              incompleteSteps.unshift(CREATE_ACCOUNT_MAIN_STEPS_ARR[i]);
                         }
                    }

                    if (!activeAccount.isOwner) {
                         // Force manager of this account to answer only user info steps
                         const incompleteUserInfoSteps = intersection(ACCOUNT_INFO_STEPS_ARR, incompleteSteps);

                         if (incompleteUserInfoSteps.length > 0) {
                              newCurrentStep = incompleteUserInfoSteps[0];
                              incompleteSteps = incompleteUserInfoSteps;
                              setShouldHideStepProgress(true);
                         }
                    }

                    setCurrentStep(currentStep || newCurrentStep);
                    setIncompleteSteps(incompleteSteps);
               }
          }
     };

     const getInitialIncompleteSteps = () => {
          let incompleteSteps = { ...CREATE_ACCOUNT_MAIN_STEPS };

          if (user.registerUserInfo) {
               const dataUser = typeof user.registerUserInfo == 'object' ? { ...user.registerUserInfo } : JSON.parse(user.registerUserInfo);

               if (dataUser.work != null) {
                    delete incompleteSteps.userWork;
               }
               if (dataUser.sizeCompany != null) {
                    delete incompleteSteps.userSizeCompany;
               }
               if (dataUser.desRole != null) {
                    delete incompleteSteps.userDesRole;
               }
               if (dataUser.centralize != null) {
                    delete incompleteSteps.userCentralize;
               }
               if (dataUser.revenue != null) {
                    delete incompleteSteps.userRevenue;
               }
               if (dataUser.identify != null) {
                    delete incompleteSteps.userIdentify;
               }
               if (dataUser.compliant != null) {
                    delete incompleteSteps.userCompliant;
               }
               if (dataUser.replace != null) {
                    delete incompleteSteps.userReplace;
               }
               if (dataUser.automates != null) {
                    delete incompleteSteps.userAutomates;
               }
               if (dataUser.analytics != null) {
                    delete incompleteSteps.userAnalytics;
               }
               if (dataUser.sessionReplay != null) {
                    delete incompleteSteps.userSessionReplay;
               }
               if (dataUser.consent != null) {
                    delete incompleteSteps.userConsent;
               }
          }

          return Object.entries(incompleteSteps).map(([_, value]) => value);
     };

     const process = () => {
          const isFromGTM = window._tempGTMParams && typeof window._tempGTMParams === 'object';
          const sessionSignupListeners = tryParseJSON(sessionStorage.getItem(getSessionKeySignupListeners(activeAccount.id))) || {};
          const sessionSignupDestinations = tryParseJSON(sessionStorage.getItem(getSessionKeySignupDestinations(activeAccount.id))) || [];

          if (isFromGetStarted) {
               const domains = tryParseJSON(activeAccount.domains);
               const domainToValidate = Array.isArray(domains) ? domains.find((domain) => domain.scriptInstalled === false) : null;
               let newStepsData = {
                    name: activeAccount.name,
                    timeZone: activeAccount.timeZone,
                    whatBrings: activeAccount.whatBrings,
                    allSelectedDestinations: sessionSignupDestinations,
                    defaultPackageId: user.defaultPackageId,
                    ...sessionSignupListeners,
               };

               if (domainToValidate) {
                    newStepsData.domain = domainToValidate.name;
                    newStepsData.domainStatus = domainToValidate.status;
               }

               // Keep default package ID if connecting to GTM
               if (isFromGTM && window._tempGTMParams.defaultPackageId) {
                    newStepsData.defaultPackageId = window._tempGTMParams.defaultPackageId;
               }

               const newAccount = { ...activeAccount, domains };
               setAccount(newAccount);
               setStepsData(newStepsData);

               if (isFromGTM) {
                    if (window._tempGTMParams.isFromGetStarted) {
                         // Go to this case if connecting to GTM from Get Started
                         user && handleGetStartedCurrentStep(window._tempGTMParams.isBackFromGTM ? STEPS.gtmConnection : STEPS.gtmSelectAccount);
                    }

                    window._tempGTMParams = null;
                    return;
               }

               const sessionSignupCurrentStep = sessionStorage.getItem(getSessionKeySignupCurrentStep(activeAccount.id));
               user && handleGetStartedCurrentStep(sessionSignupCurrentStep);
          } else {
               let incompleteSteps = [];
               if (user) {
                    setIncompleteSteps(getInitialIncompleteSteps());
                    incompleteSteps = getInitialIncompleteSteps();
               }
               if (isFromGTM) {
                    // Go to this case if connecting to GTM when creating a new account
                    const domains = tryParseJSON(activeAccount.domains);
                    const newAccount = { ...activeAccount, domains };

                    setAccount(newAccount);
                    setStepsDataState({ ...window._tempGTMParams.stepsData, ...sessionSignupListeners });
                    setPrevSteps(window._tempGTMParams.prevSteps);
                    setCurrentStep(window._tempGTMParams.isBackFromGTM ? STEPS.gtmConnection : STEPS.gtmSelectAccount);
                    window._tempGTMParams = null;
                    return;
               }

               setAccount(initialAccount);

               const defaultPackageIdParam = urlParams('defaultPackageId') || (user && user.defaultPackageId);

               setStepsDataState({ ...initialStepsData, defaultPackageId: defaultPackageIdParam });

               setCurrentStep(incompleteSteps[0]);
          }
     };

     useEffect(process, [isFromGetStarted]); // eslint-disable-line react-hooks/exhaustive-deps

     return { account, setAccount };
};

// Handle remove step from completedSteps to reduce progress percentage
const useBackMainSteps = ({ prevStep, currentStep, account, setAccount }) => {
     const handleStepChange = () => {
          if (prevStep !== currentStep && Array.isArray(account.completedSteps)) {
               let newCurrentStep =
                    currentStep === STEPS.chatListener ||
                    currentStep === STEPS.formListener ||
                    currentStep === STEPS.ecommerceListener ||
                    currentStep === STEPS.videoListener ||
                    currentStep === STEPS.weatherListener
                         ? STEPS.configureMeasurement
                         : currentStep; // Cause STEPS.whatBringsOther and STEPS.whatBrings count as 1 completed step
               if (CREATE_ACCOUNT_MAIN_STEPS_ARR.indexOf(prevStep) > CREATE_ACCOUNT_MAIN_STEPS_ARR.indexOf(newCurrentStep)) {
                    // It has to be a back action
                    let foundIndex = account.completedSteps.findIndex((el) => el === newCurrentStep);

                    if (foundIndex > -1) {
                         let newCompletedSteps = [...account.completedSteps];
                         newCompletedSteps.splice(foundIndex, 1);
                         setAccount({ ...account, completedSteps: newCompletedSteps });
                    }
               }
          }
     };

     useEffect(handleStepChange, [currentStep]); // eslint-disable-line react-hooks/exhaustive-deps
};

const CreateAccount = () => {
     const dispatch = useDispatch();
     const [stepsData, setStepsDataState] = useState(initialStepsData);
     const [currentStep, setCurrentStepState] = useState(STEPS.userWork);
     const [prevSteps, setPrevSteps] = useState([]);
     const [incompleteSteps, setIncompleteSteps] = useState([]);
     const [shouldHideStepProgress, setShouldHideStepProgress] = useState(false);
     const [scheduleUpgrade, setScheduleUpgrade] = useState(false);
     const [showThank, setShowThank] = useState(false);
     const [gtmWorkspaceName, setGTMWorkspaceName] = useState('ListenLayer');
     const [userValue, setUserValue] = useState(50000);
     const user = useSelector((state) => state.theme.user);
     const showPopup = useSelector((state) => state.subscriber.showCreateAccountPopup);
     const accounts = useSelector((state) => state.subscriber.accounts);
     const packages = useSelector((state) => state.subscriber.packages);
     const isFromGetStarted = useSelector((state) => state.subscriber.isFromGetStarted);
     const signUpStep = useSelector((state) => state.subscriber.signUpStep);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const [showPopupLargeSandbox, setShowPopupLargeSandbox] = useState(false);
     const setStepsData = (values) => {
          setStepsDataState((prevStepsData) => ({ ...prevStepsData, ...values }));
     };

     const setCurrentStep = (step, type, saveToPrevSteps = true) => {
          let newCurrentStep, foundStepIndex;

          switch (type) {
               // Used for going to next step of creating account main step
               case CHANGE_STEP_TYPE.mainStepNext:
                    foundStepIndex = incompleteSteps.findIndex((incompleteStep) => incompleteStep === step || incompleteStep === currentStep);
                    newCurrentStep = incompleteSteps[foundStepIndex + 1];

                    if (newCurrentStep === STEPS.configureMeasurement) {
                         newCurrentStep = STEPS.almostDone;
                    }

                    if (!newCurrentStep) {
                         const foundPackage = packages.find((el) => el.id === stepsData.defaultPackageId);

                         if (foundPackage) {
                              newCurrentStep = foundPackage.pricePerMonth === 0 ? STEPS.accountTestPlan : STEPS.accountPaidPlan;
                              setStepsDataState((stepsDataState) => ({ ...stepsDataState, package: foundPackage }));
                         } else {
                              if (
                                   isFromGetStarted &&
                                   ((activeAccount.stripeCardType && activeAccount.stripeCardLast4) || activeAccount.packagePricePerMonth !== 0)
                              ) {
                                   newCurrentStep = STEPS.accountSetup;
                              } else {
                                   newCurrentStep = STEPS.accountTestPlan;
                              }
                         }
                    }

                    if (saveToPrevSteps) {
                         setPrevSteps((steps) => [...steps, currentStep]);
                    }

                    setCurrentStepState(newCurrentStep);
                    break;
               // Used for going to previous step of creating account main step
               case CHANGE_STEP_TYPE.mainStepPrev:
                    foundStepIndex = incompleteSteps.findIndex((incompleteStep) => incompleteStep === step || incompleteStep === currentStep);
                    newCurrentStep = incompleteSteps[foundStepIndex - 1] || currentStep;
                    setCurrentStepState(newCurrentStep);
                    break;
               // Used for going to next step and when pressing back button from that next step, you go back to screen that navigate to this next step
               case CHANGE_STEP_TYPE.subStepNext:
                    setPrevSteps((steps) => [...steps, currentStep]);
                    setCurrentStepState(step);
                    break;
               // Used for going to previous step of CHANGE_STEP_TYPE.subStepNext case
               case CHANGE_STEP_TYPE.subStepPrev:
                    let newPrevSteps = [...prevSteps];
                    newCurrentStep = newPrevSteps.pop();

                    if (newCurrentStep) {
                         setPrevSteps(newPrevSteps);
                         setCurrentStepState(newCurrentStep);
                    }
                    break;
               default:
                    setCurrentStepState(step);
          }
     };

     const { account, setAccount } = useGetStarted({
          setStepsDataState,
          setStepsData,
          setCurrentStep,
          setIncompleteSteps,
          setPrevSteps,
          setShouldHideStepProgress,
     });
     const isScheduleDemo = (activeAccount.id === account.id && activeAccount.isScheduleUpgrade) || scheduleUpgrade;
     const [firstSchedule, setFirstSchedule] = useState(isScheduleDemo ? false : true);
     useEffect(() => {
          if (!account.scheduleUpgrade || activeAccount.id === account.id) setFirstSchedule(isScheduleDemo ? false : true);
     }, [isScheduleDemo, account.scheduleUpgrade]); // eslint-disable-line
     const prevStep = usePrevious(currentStep);
     const gtmTagName = 'ListenLayer - Main';
     const gtmTagTriggerName = 'Consent Initialization - All Pages';
     const gtmFolderName = 'ListenLayer';
     useBackMainSteps({ prevStep, currentStep, account, setAccount });

     const checkIfShowSetupAccountAgain = () => {
          let localSetupInvitedAccount = localStorage.getItem(LOCAL_SETUP_INVITED_ACCOUNT);
          const defaultPackageIdParam = urlParams('defaultPackageId');
          const createAccount = urlParams('createAccount');

          // Get local unexisting key = null
          if (Array.isArray(accounts) && accounts.length > 0 && localSetupInvitedAccount !== null) {
               // Show setup account component
               dispatch(setShowCreateAccountPopup(true));
               setCurrentStep(STEPS.accountSetup);
          }

          if (defaultPackageIdParam || createAccount) {
               dispatch(setShowCreateAccountPopup(true));
          }

          // Set timeout to keep hiding dashboard for some cases need to display create account popup on first load page
          setTimeout(() => {
               dispatch(setLoadings({ wholePageOverlay: false }));
          }, 500);
     };

     useEffect(checkIfShowSetupAccountAgain, []); // eslint-disable-line react-hooks/exhaustive-deps

     const handleWhenHideAndShowPopup = () => {
          if (showPopup) {
               setTimeout(() => document.body.classList.add('no-scroll'), 0);
          } else {
               window._areAllAccountsInactive = false;
               document.body.classList.remove('no-scroll');
               dispatch(setIsFromGetStarted(false));
          }
     };

     useEffect(handleWhenHideAndShowPopup, [showPopup]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleBackBrowserBtn = () => {
          if (showPopup && account.reachTheEndOfSetup) {
               const browserBackBtnClick = () => {
                    if (activeAccount.id !== account.id) {
                         // If account has just been created, then clicking back button on the browser, switch to that account
                         dispatch(resetSubscriberReducer());
                         dispatch(setActiveAccount(account));
                         localStorage.setItem('activeAccountId', account.id);
                         localStorage.setItem('accountId', account.id);
                         localStorage.removeItem(LOCAL_SHOW_ONLY_ENABLED_LISTENERS);
                    }

                    if (stepsData.shouldFetchListeners) {
                         dispatch(fetchAnAccount(account, accounts)); // Mainly fetch new used items for selected account
                         dispatch(fetchListeners(account.id, activeAccount.secondId));
                    }

                    closeModal();
               };

               window.addEventListener('popstate', browserBackBtnClick, false); // Close create account popup on clicking back button of browser

               return () => window.removeEventListener('popstate', browserBackBtnClick);
          }
     };

     useEffect(handleBackBrowserBtn, [showPopup, activeAccount.id, account, stepsData.shouldFetchListeners]); // eslint-disable-line react-hooks/exhaustive-deps

     const closeModal = () => {
          dispatch(setShowCreateAccountPopup(false));
     };

     // Handle show step from support ticket details
     useEffect(() => {
          dispatch(
               setSignUpStep({
                    currentStep,
               })
          );

          if (signUpStep.previousStep && signUpStep.currentStep !== signUpStep.previousStep) {
               setCurrentStep(signUpStep.previousStep);
               dispatch(
                    setSignUpStep({
                         previousStep: null,
                    })
               );
          }
     }, [currentStep]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (account && account.id) {
               dispatch(setCreatedAccount(account));

               const newAccounts = [...accounts];
               const _newAccounts = newAccounts.map((acc) => {
                    if (acc.id === account.id) {
                         return { ...acc, ...account };
                    }
                    return acc;
               });
               dispatch(setAccounts(_newAccounts));
          }
     }, [account]); // eslint-disable-line react-hooks/exhaustive-deps

     const getCurrentComponent = useCallback(() => {
          switch (currentStep) {
               case STEPS.userWork:
                    return <UserWork />;
               case STEPS.userSizeCompany:
                    return <UserSizeCompany />;
               case STEPS.userDesRole:
                    return <UserDesRole />;
               case STEPS.userRevenue:
                    return <UserRevenue />;
               case STEPS.userIdentify:
                    return <UserIdentify />;
               case STEPS.userCompliant:
                    return <UserCompliant />;
               case STEPS.userReplace:
                    return <UserReplace />;
               case STEPS.userAutomates:
                    return <UserAutomates />;
               case STEPS.userCentralize:
                    return <UserCentralize />;
               case STEPS.userAnalytics:
                    return <UserAnalytics />;
               // case STEPS.userSessionReplay:
               //      return <UserSectionReplay />;
               // case STEPS.userConsent:
               //      return <UserConsent />;
               case STEPS.createLogin:
                    return <CreateLogin />;
               case STEPS.seeHowItWorks:
                    return <SeeHowItWorks />;
               case STEPS.seePricing:
                    return <SeePricing />;
               case STEPS.scheduleDemo:
                    return <ScheduleDemo />;
               case STEPS.scheduleDemoThankYou:
                    return <ThankYouDemo />;
               case STEPS.name:
                    return <Name />;
               case STEPS.timeZone:
                    return <TimeZone />;
               case STEPS.domain:
                    return <Domain />;
               case STEPS.domainVerifying:
                    return <DomainVerifying />;
               case STEPS.domainSuccess:
                    return <DomainSuccess />;
               case STEPS.domainRedirect:
                    return <DomainRedirect />;
               case STEPS.domainCannotAccess:
                    return <DomainCannotAccess />;
               case STEPS.gtmConnection:
                    return <GTMConnection />;
               case STEPS.gtmSelectAccount:
                    return <GTMSelectAccount />;
               case STEPS.gtmSelectContainer:
                    return <GTMSelectContainer />;
               case STEPS.gtmVerifyChanges:
                    return <GTMVerifyChanges />;
               case STEPS.gtmMakeChanges:
                    return <GTMMakeChanges />;
               case STEPS.gtmNoPublishingRights:
                    return <GTMNoPublishingRights />;
               case STEPS.gtmPublishSuccess:
                    return <GTMPublishSuccess />;
               case STEPS.gtmUnknownFailure:
                    return <GTMUnknownFailure />;
               case STEPS.placeScriptManually:
                    return <PlaceScriptManually />;
               case STEPS.verifyingScript:
                    return <VerifyingScript />;
               case STEPS.skipPlacingScript:
                    return <SkipPlacingScript />;
               case STEPS.verificationSuccess:
                    return <VerificationSuccess />;
               case STEPS.verificationFailed:
                    return <VerificationFailed />;
               case STEPS.cannotVerify:
                    return <CannotVerifyScript />;
               case STEPS.verifyWithInaccessibleDomain:
                    return <VerifyWithInaccessibleDomain />;
               case STEPS.almostDone:
                    return <AlmostDone />;
               case STEPS.configureMeasurement:
                    return <ConfigureMeasurement />;
               case STEPS.formListener:
                    return <FormListener />;
               case STEPS.chatListener:
                    return <ChatListener />;
               case STEPS.ecommerceListener:
                    return <EcommerceListener />;
               case STEPS.videoListener:
                    return <VideoListener />;
               case STEPS.weatherListener:
                    return <WeatherListener />;
               case STEPS.configuringAccount:
                    return <ConfiguringAccount />;
               case STEPS.chooseDestination:
                    return <ChooseDestination />;
               case STEPS.customDestination:
                    return <CustomDestination />;
               case STEPS.accountPackage:
                    return <AccountPackage />;
               case STEPS.accountPayment:
                    return <AccountPayment />;
               case STEPS.talkToSales:
                    return <TalkToSales />;
               case STEPS.talkToSalesThankYou:
                    return <ThankYouTalk />;
               case STEPS.accountSetup:
                    return <AccountSetup />;
               case STEPS.register:
                    return <Register />;
               default:
                    return <UserWork />;
          }
     }, [currentStep]);

     const updateAccountCompletedSteps = (step) => {
          setAccount((acc) => {
               let newCompletedSteps;

               if (Array.isArray(acc.completedSteps)) {
                    if (!acc.completedSteps.includes(step)) {
                         newCompletedSteps = [...acc.completedSteps, step];
                    }
               } else {
                    newCompletedSteps = [step];
               }

               if (newCompletedSteps) {
                    return { ...acc, completedSteps: newCompletedSteps };
               }

               return acc;
          });
     };

     const AnsweredAccountInfo = useCallback(
          ({ step, arr }) => {
               let incompleteAccountInfoSteps = [];

               arr.forEach((el) => {
                    if (incompleteSteps.includes(el)) {
                         incompleteAccountInfoSteps.push(el);
                    }
               });
               const answeredCount = incompleteAccountInfoSteps.findIndex((el) => el === step);
               const totalQuestionsToAnswered = incompleteAccountInfoSteps.length;

               return (
                    <>
                         {totalQuestionsToAnswered > 1 && (
                              <p className="number-of-step">
                                   {answeredCount} of {totalQuestionsToAnswered} answered
                              </p>
                         )}
                    </>
               );
          },
          [incompleteSteps]
     );

     useEffect(() => {
          setScheduleUpgrade(false);
     }, []);

     const contextValue = {
          account,
          setAccount,
          stepsData,
          setStepsData,
          currentStep,
          setCurrentStep,
          prevStep,
          gtmWorkspaceName,
          setGTMWorkspaceName,
          gtmTagName,
          gtmTagTriggerName,
          gtmFolderName,
          updateAccountCompletedSteps,
          prevSteps,
          AnsweredAccountInfo,
          incompleteSteps,
          shouldHideStepProgress,
          setShouldHideStepProgress,
          scheduleUpgrade,
          setScheduleUpgrade,
          showPopupLargeSandbox,
          setShowPopupLargeSandbox,
          userValue,
          setUserValue,
          firstSchedule,
          setFirstSchedule,
          showThank,
          setShowThank,
     };

     return (
          <div className={`create-account-popup ${currentStep === STEPS.accountSetup ? 'account-setup' : ''}`}>
               <CreateAccountContext.Provider value={contextValue}>
                    <CModal fullscreen visible={user ? showPopup : true} onClose={closeModal} id="create-account-content" backdrop="static" portal={false}>
                         <div className="modal-body" style={{ backgroundColor: '#f0f3f5' }}>{getCurrentComponent()}</div>
                    </CModal>
               </CreateAccountContext.Provider>
          </div>
     );
};

export default CreateAccount;
