import React, { useContext, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'; // useSelector & useDispatch can only be used in functional component
import {
    CButton,
    CRow,
    CCol
} from '@coreui/react';
import CIcon from '@coreui/icons-react';

import { AsideContext, ASIDE_COMPONENTS } from '../TheAside';
import { LIMITATION_TYPE } from '../../../../constants';
import { setShowGetHelpLabel } from '../../../../actions/subscriber';
import { roundTo2DecimalPlaces } from '../../../../utils';
import { setAsideShow } from '../../../../actions/common';
import { useIsLatestPlan } from '../../../../helpers/customHooks';
import EventRunOutHit from './EventRunOutHit';
import EventLimitationHit from './EventLimitationHit';
import UpgradeMonitoring from './UpgradeMonitoring';
import UpgradeReporting from './UpgradeReporting';
import SaveReportingValuesLimitationHit from './SaveReportingValuesLimitationHit';
import UpgradePopulate from './UpgradePopulate';
import OtherTypeOfLimitationHit from './OtherTypeOfLimitationHit';

const LimitationHit = () => {
    const dispatch = useDispatch();
    const { setCurrentComponents } = useContext(AsideContext);
    const limitationType = useSelector(state => state.subscriber.limitationType);
    const maxPackage = useSelector(state => state.subscriber.maxPackage);
    const packages = useSelector(state => state.subscriber.packages);
    const packagePro = packages.find(item => item.friendlyName === 'Pro');
    const isCustomerSupportLoginAs = useSelector(state => state.subscriber.isCustomerSupportLoginAs);
    const isLatestPlan = useIsLatestPlan();

    const upgradeNowBtnClicked = useCallback(() => {
        setCurrentComponents(ASIDE_COMPONENTS.SLIDE_OUT_PAYMENT);
    }, [setCurrentComponents])

    const comparePlans = useCallback(() => {
        setCurrentComponents(ASIDE_COMPONENTS.COMPARE_PLANS);
    }, [setCurrentComponents]);

    const handleClickHelpDesk = () => {
        dispatch(setAsideShow(false));
        dispatch(setShowGetHelpLabel(true));
    }

    let contentLimitationHit;
    switch (limitationType) {
        case LIMITATION_TYPE.OUT_OF_EVENT:
            contentLimitationHit = <EventRunOutHit />;
            break;
        case LIMITATION_TYPE.EVENT:
            contentLimitationHit = <EventLimitationHit />;
            break;
        case LIMITATION_TYPE.MONITORING_RULE:
            contentLimitationHit = <UpgradeMonitoring />;
            break;
        case LIMITATION_TYPE.UPGRADE_REPORTING:
            contentLimitationHit = <UpgradeReporting />;
            break;
        case LIMITATION_TYPE.SAVE_REPORTING_VALUES:
            contentLimitationHit = <SaveReportingValuesLimitationHit />;
            break;
        case LIMITATION_TYPE.POPULATE_RULE:
            contentLimitationHit = <UpgradePopulate />;
            break;
        default:
            contentLimitationHit = <OtherTypeOfLimitationHit />;
            break;
    }

    return (
        <div className="sidebar-popup" >
            <div className="sidebar-header">
                <div className="text-center">
                    <CIcon icon="icon-upgrade" width={50} height={50} />
                    <h3>Time to Upgrade</h3>
                    {contentLimitationHit}
                </div>
                <div className="group-btn">
                    {
                        (!maxPackage && !isCustomerSupportLoginAs) && (
                            isLatestPlan ? (
                                <>
                                    <CButton
                                        color="success"
                                        className="btn-success-now"
                                        onClick={upgradeNowBtnClicked}
                                    >
                                        Upgrade Now
                                    </CButton>
                                    <CButton className="align-middle compare-plan" onClick={comparePlans}>
                                        Compare Plans
                                    </CButton>
                                </>
                            ) : (
                                <CButton
                                    color="success"
                                    className="btn-success-now mb-3"
                                    onClick={comparePlans}
                                >
                                    Compare Plans
                                </CButton>
                            )
                        )
                    }
                </div>
                {isLatestPlan && limitationType === LIMITATION_TYPE.UPGRADE_REPORTING && (
                    <div className="sidebar-additional-options">
                        <h6 className="text-success text-center recommendation fw-5 f-14">Additional Options</h6>
                        <p className="text-center recommendation-text fw-4">
                            Upgrade to the {packagePro.friendlyName} plan for ${roundTo2DecimalPlaces(packagePro.pricePerYear / 12).toLocaleString('en-US')}/mo
                            (paid annually) and gain access to data layer event reporting and
                            the ability to <u>save values from your data layer</u> in your reporting data.
                            Compare plans to learn more.
                        </p>
                    </div>
                )}
            </div>
            <div className="sidebar-footer">
                <CRow>
                    <CCol md="9">
                        <div className="description">
                            <p className="f-13">
                                We're Here to Help!, Zach. Tap into our helpdesk round the clock immediate guidance.
                            </p>
                        </div>
                        <div className="help-btns">
                            <CButton className="btn-help-desk mr-3 font-weight-bold btn-warning" size="sm" onClick={handleClickHelpDesk}>
                                <CIcon icon="icon-boltAlt" height={13} /> HELP DESK
                            </CButton>

                            <CButton className="btn-help-desk btn-primary font-weight-bold">
                                <i className="fal fa-comment"></i> LIVE CHAT
                            </CButton>
                        </div>
                    </CCol>
                    <CCol md="3">
                        <div className="sidebar-upgrade-avatar">
                            <img className="c-avatar-img" src="/user-default-avatar.png" alt="avatar" />
                        </div>
                    </CCol>
                </CRow>
            </div>
        </div>
    )
}

export default LimitationHit
