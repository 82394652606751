import { useEffect } from 'react';
import { callTokenApi } from '../../../../../../apiCaller';
import { CLIENT_TARGET_ACCOUNT } from '../../../../../../constants';
import { useSelector } from 'react-redux';

const useFetchData = async (props) => {
     const { filterEvent, setCompanyData, setIsLoading, offSet, itemsPerPage, activePage, setOverallRowCount, isFetchData } = props;
     const dependencies = [
          filterEvent,
          itemsPerPage,
          activePage,
          isFetchData
     ];
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);

     const body = {
          accountId: activeAccount.id,
          filter: filterEvent,
          limit: itemsPerPage,
          offset: offSet,
     }

     useEffect(() => {
          setIsLoading(true)
          callTokenApi(`${CLIENT_TARGET_ACCOUNT}find-account`, 'POST', body).then((response) => {
               if (response && response.status === 200) {
                    const { companyData, overallRowCount } = response.data.data
                    setCompanyData(companyData)
                    setOverallRowCount(overallRowCount)
               }
          }).finally(() => {
               setIsLoading(false)
          })
     }, dependencies) // eslint-disable-line
}

export default useFetchData;
